import { createSlice } from '@reduxjs/toolkit';
import { getPreferredPartners } from './api';
import { initialState } from './api/interface';

const preferredPartnerSlice = createSlice({
  name: 'preferredPartner',
  initialState,
  reducers: {
    reset: () => initialState,
    preferredPartnerDeleted: (state, action) => {
      let array = [...(state.data?.preferredPartners ?? [])];
      let position = -1;
      array.forEach((item, index) => {
        if (item.id == action.payload.id) {
          position = index
        }
      });

      if (position !== -1 && state.data != null) {
        array.splice(position, 1);
        state.data.preferredPartners = array;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPreferredPartners.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getPreferredPartners.fulfilled, (state, action) => {
      state.data = {
        ...action.payload.data,
        preferredPartners: [
          ...(action.payload.data.data.current_page != 1 ? state.data?.preferredPartners ?? [] : []),
          ...action.payload.data.data.data,
        ],
      };
      state.isLoadMore = action.payload.data.data.data.length >= 10;
      state.page = action.payload.data.data.current_page + 1;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getPreferredPartners.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset, preferredPartnerDeleted } = preferredPartnerSlice.actions;
export default preferredPartnerSlice.reducer;
