import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { KnoCardLinkSessionNotePerUserRequestResponse, PostKnoCardLinkSessionNote } from './interface';

export const getKnoCardLinkSessionNotePerUserRequest = createAsyncThunk('knocardLinkSessionNote/getKnoCardLinkSessionNotePerUserRequest', async (data: { userId: number, page: number }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get<KnoCardLinkSessionNotePerUserRequestResponse>(`/api/knocard-link-session-note/user/${data.userId}?page=${data.page}`);
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});



export const postKnoCardLinkSessionNoteRequest = createAsyncThunk('knocardLinkSessionNote/postKnoCardLinkSessionNoteRequest', async (data: PostKnoCardLinkSessionNote, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post<KnoCardLinkSessionNotePerUserRequestResponse>(`/api/knocard-link-session-note`, data);
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});


export const patchKnoCardLinkSessionNoteRequest = createAsyncThunk('knocardLinkSessionNote/patchKnoCardLinkSessionNoteRequest', async (data: PostKnoCardLinkSessionNote, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post<KnoCardLinkSessionNotePerUserRequestResponse>(`/api/knocard-link-session-note/update/${data?.id}`, data);
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});


export const deleteKnoCardLinkSessionNoteRequest = createAsyncThunk('knocardLinkSessionNote/deleteKnoCardLinkSessionNoteRequest', async (data: { id: number }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.delete<KnoCardLinkSessionNotePerUserRequestResponse>(`/api/knocard-link-session-note/delete/${data?.id}`);
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});