import React, { FC, useEffect, useState } from 'react';
import { Avatar, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';

import styles from '../../styles/Reports.module.css';
import {
  AffiliateLandingPageWatchVideoItem,
} from '../../store/reports/api/interface';
import { dateFormate } from '../../utils/format';
import VideoIcon from '../../assets/reports/video.svg';
import { AppColors } from '../../utils/utils';

const LandingPageVideoVideItem: FC<{
  affiliateLandingPageWatchVideoItem: AffiliateLandingPageWatchVideoItem
  index: number;
}> = ({ affiliateLandingPageWatchVideoItem, index }) => {

  const dateFormatted = dateFormate(affiliateLandingPageWatchVideoItem?.created_at ?? '') ?? '';
  return (
    <Box className={styles.subitemContainer} sx={{
      pr: 0,
      background: AppColors.grayZeroColor,
      border: `solid 1px ${AppColors.strokeColor}`,

    }}>
      <Box component={'img'} src={VideoIcon} className={styles.muiIconSize} sx={{ height: "25px", width: "25px" }} />
      <Box
        className={styles.itemContainerContent}
        sx={{ ml: 1.5 }}
      >
        <Typography sx={{ fontSize: "1rem", fontWeight: 400 }}>
          {affiliateLandingPageWatchVideoItem.title || ""}
        </Typography>
        <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>Last view at {dateFormatted}</Typography>
      </Box>
      <Stack direction={'column'} sx={{
        width: "100%",
        ml: 1,
        mr: 1,
        alignItems: "end"
      }}>
        <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor, mr: 1 }}>{affiliateLandingPageWatchVideoItem.total || ""} views</Typography>
        <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor, mr: 1 }}>{affiliateLandingPageWatchVideoItem.watch_time ?? ""}</Typography>
      </Stack>

    </Box>
  );
};



export default LandingPageVideoVideItem;
