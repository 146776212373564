import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Avatar, Badge, Button, Menu, MenuItem, Paper, TextField, Grid, Stack, IconButton } from '@mui/material';
import styles from "../../styles/EngageItem.module.css";
import CloseIcon from '@mui/icons-material/Close';

import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { CreateEngageUserFollowupNote, EngageActivity, EngageActivityTarget, EngageUserFollowupNote, UpdateEngageUserFollowupNote } from '../../store/engagePipeline/api/interface';
import moment from 'moment';
import { AppColors, config } from '../../utils/utils';
import { AppDispatch, useAppSelector } from '../../store';
import { EngageInterestLevel, EngageLeadType, PiplineCalendarEventType } from '../../enum';
import { useDispatch } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { createEngageUserFollowupNote, updateEngageUserContact, updateEngageUserFollowupNote } from '../../store/engagePipeline/api';
import { isNotEmpty } from '../../utils/valid';
import { getCapitalizedString, getEngageLeadSource, getInitialEngageLeadType, getInitialInterestLevel, showErrorMessage, showSuccessMessage } from '../../utils/utilMethods';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import useToggle from '../../hooks/useToggle';
import AddPiplineNoteModal from '../modal/AddPiplineNoteModal';
import AddProspectModal from '../modal/AddProspectModal';
import { ContactModel } from '../../store/prospects/api/interface';
import { delay, set } from 'lodash';
import AddPiplineCalendarEventModal from '../modal/AddPiplineCalendarEventModal';
import ViewPiplineNoteModal from '../modal/ViewPiplineNoteModal';
import CallingIcon from '../../assets/call_calling.svg'
import SmsIcon from '../../assets/sms.svg'
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface IInitialState {
    note: string;
    date?: Date;
    check_back_date?: Date;
    occupation: string;
    interest_level?: string;
    lead_type?: string;
    email: string;
    phone: string;
    met_at: string;
}

interface IProps {
    engageActivity: EngageActivity;
    index: number;
    sortType: string;
    openEngageActivityReportSortMarker: (sortMarker?: string, totalActivities?: number, engageLeadId?: number, engageActivity?: EngageActivity) => void;
    onProspectCreateOrUpdated: (contact?: ContactModel) => void;
}

const EngagePiplineItem: React.FC<IProps> = ({
    engageActivity,
    index,
    sortType,
    openEngageActivityReportSortMarker,
    onProspectCreateOrUpdated
}) => {
    const dispatch = useDispatch<AppDispatch>();

    const [isExpanded, setExpanded] = useState<boolean>(false);
    const [isNoteEdit, setIsNoteEdit] = useState<boolean>(false);


    const [piplineCalendarEventType, setPiplineCalendarEventType] = useState<PiplineCalendarEventType | undefined>(undefined);

    const [editEngageActivityTarget, setEditEngageActivityTarget] = useState<EngageActivityTarget | undefined>(undefined);

    const [engageUserFollowupNoteList, setEngageUserFollowupNoteList] = useState<Array<EngageUserFollowupNote>>([]);

    const [editEngageUserFollowupNote, setEditEngageUserFollowupNote] = useState<EngageUserFollowupNote | undefined>(engageActivity?.engage_report_note);

    const [isConsentPending, setIsConsentPending] = useState<boolean>(engageActivity.consent_status == "pending");

    const purchasePlans = useAppSelector((state) => state?.engagePipline?.plans) ?? [];

    const [editNoteText, setEditNoteText] = useState<string | undefined>(undefined);

    const {
        toggle: toggleEditProspect,
        onOpen: onOpenEditProspect,
        onClose: onCloseEditProspect,
    } = useToggle();

    const {
        toggle: toggleViewPiplineNote,
        onOpen: onOpenViewPiplineNote,
        onClose: onCloseViewPiplineNote,
    } = useToggle();



    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const handleMenuClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const {
        toggle: toggleAddEditNote,
        onOpen: onOpenAddEditNote,
        onClose: onCloseAddEditNote,
    } = useToggle();


    const {
        toggle: toggleFollowupTask,
        onOpen: onOpenFollowupTask,
        onClose: onCloseFollowupTask,
    } = useToggle();

    useEffect(() => {

        if (editEngageActivityTarget != undefined) {
            onOpenEditProspect();
        }

    }, [editEngageActivityTarget]);




    const formik = useFormik<IInitialState>({
        initialValues: {
            note: engageActivity?.engage_report_note?.note ?? "",
            date: engageActivity?.engage_report_note?.date ? moment.utc(`${engageActivity?.engage_report_note?.date}`).toDate() : undefined,
            check_back_date: engageActivity?.engage_report_note?.check_back_date ? moment.utc(`${engageActivity?.engage_report_note?.check_back_date}`).toDate() : undefined,
            occupation: engageActivity?.engage_report_note?.occupation ?? engageActivity.target?.occupation ?? "",
            interest_level: getInitialInterestLevel(engageActivity?.engage_report_note?.interest_level),
            lead_type: getInitialEngageLeadType(engageActivity?.engage_report_note?.lead_type),
            email: engageActivity?.engage_report_note?.email ?? engageActivity.target?.email ?? "",
            phone: engageActivity?.engage_report_note?.phone ?? engageActivity.target?.phone_number ?? "",
            met_at: engageActivity?.engage_report_note?.met_at ?? engageActivity.target?.where_i_met_you ?? ""
        },
        onSubmit: async (values) => {
            try {
                if (isNotEmpty(values.note) || isNotEmpty(values.occupation) || values.interest_level != undefined || values.lead_type != undefined || isNotEmpty(values.email) || isNotEmpty(values.phone) || isNotEmpty(values.met_at)) {

                    if (editEngageUserFollowupNote != undefined && isNoteEdit) {
                        const updateNote: UpdateEngageUserFollowupNote = {
                            id: editEngageUserFollowupNote?.id ?? 0,
                            date: values.date,
                            check_back_date: values.check_back_date,
                            note: getCapitalizedString(values.note.trim()),
                            occupation: values.occupation,
                            interest_level: values.interest_level,
                            lead_type: values.lead_type,
                            email: values.email,
                            phone: values.phone,
                            met_at: values.met_at,
                        };

                        const result = await dispatch(updateEngageUserFollowupNote(updateNote)).unwrap();

                        if (result?.data?.status == "success") {
                            var list = engageUserFollowupNoteList;
                            list[list.indexOf(editEngageUserFollowupNote)] = result?.data.data;
                            setEngageUserFollowupNoteList(list);
                            //setEditEngageUserFollowupNote(result?.data.data);
                            showSuccessMessage("Follow up note updated successfully.");
                        }
                    } else {
                        const createNote: CreateEngageUserFollowupNote = {
                            engage_report_id: engageActivity.id,
                            date: values.date,
                            check_back_date: values.check_back_date,
                            followup_user_id: engageActivity.target?.id ?? 0,
                            followup_user_type: engageActivity.target_type == "App\\Models\\User" ? "user" : "contact",
                            note: getCapitalizedString(values.note.trim()),
                            occupation: values.occupation,
                            interest_level: values.interest_level,
                            email: values.email,
                            lead_type: values.lead_type,
                            phone: values.phone,
                            met_at: values.met_at,

                        };

                        const result = await dispatch(createEngageUserFollowupNote(createNote)).unwrap();

                        if (result?.data?.status == "success") {
                            setEditEngageUserFollowupNote(result.data.data);
                            setEngageUserFollowupNoteList([result?.data.data, ...engageUserFollowupNoteList]);
                            showSuccessMessage("Follow up note added successfully.");
                        }
                    }
                } else {
                    showErrorMessage("Any one is required.");
                }
            } catch (error: any) { }
        },
    });



    const getcontactProfile = () => {
        let imagePath = "/static/images/placeholder_profile.png"



        if (engageActivity.target?.profile_picture != null) {
            imagePath = engageActivity.target?.profile_picture ?? "";
        } else if (engageActivity.target?.user?.profile_picture != null && engageActivity.target?.user?.profile_picture.length > 0) {
            imagePath = engageActivity.target?.user?.profile_picture;
        }

        return imagePath;
    }


    const getReferByProfile = () => {
        let imagePath = "/static/images/placeholder_profile.png"

        if (engageActivity.refer_by?.profile_picture != null) {
            imagePath = engageActivity.refer_by?.profile_picture ?? imagePath;
        }

        return imagePath;
    }



    const getEngageShareTitle = () => {
        let engageShareTitle = "";

        if (engageActivity.share_type == "App\\Models\\CompanyFeedShare") {
            engageShareTitle = engageActivity?.share?.company_feed?.title ?? '';
        } else if (engageActivity.share_type == "App\\Models\\SharePost") {
            engageShareTitle = engageActivity?.share?.post?.description ?? '';
        }

        return (engageShareTitle.length > 0 ? <Typography
            sx={{
                color: AppColors.grayThreeColor,
                mt: 0.5,
                cursor: 'pointer',
                fontSize: "10px",

            }}
        >
            Title: {engageShareTitle}
        </Typography> : <></>);
    }

    const handleOpenActivity = () => {
        const contactUserType = engageActivity.target_type == "App\\Models\\User" ? "U" : "C";
        var contactUserId = engageActivity.target?.id ?? 0;
        openEngageActivityReportSortMarker(`${contactUserType}${contactUserId}`, engageActivity.totalActivities, engageActivity.id, engageActivity);
    }

    const handleMessageClick = () => {
        setIsNoteEdit(false);
        dispatch(updateEngageUserContact({ engageLeadId: engageActivity.id }));
        formik.setFieldValue("note", "Texted");
        delay(() => {
            formik.submitForm();
        }, 200);
        let contactNumber = (engageActivity?.target?.phone_number)?.replace(' ', '').replace('-', '')
        let smsLink = `sms:${contactNumber}`;

        window.open(`${smsLink}`, "_blank") || window.location.replace(`${smsLink}`);
    }
    const handleCallClick = () => {
        setIsNoteEdit(false);
        dispatch(updateEngageUserContact({ engageLeadId: engageActivity.id }));
        formik.setFieldValue("note", "Called");
        delay(() => {
            formik.submitForm();
        }, 200);
        let contactNumber = (engageActivity?.target?.phone_number)?.replace(' ', '').replace('-', '')
        let telLink = `tel:${contactNumber}`;
        window.open(`${telLink}`, "_blank") || window.location.replace(`${telLink}`);

    }

    useEffect(() => {
        if (editEngageUserFollowupNote) {
            formik.setFieldValue("note", editEngageUserFollowupNote?.note ?? "");
        }

    }, [editEngageUserFollowupNote]);






    useEffect(() => {
        if (!toggleEditProspect) {
            setEditEngageActivityTarget(undefined);
        }

    }, [toggleEditProspect]);


    const handleAddNote = () => {
        setIsNoteEdit(false);
        onOpenAddEditNote();
    };



    const onSaveNote = (note: string) => {
        if (editNoteText == undefined) {
            setIsNoteEdit(false);
        }
        formik.setFieldValue("note", note);
        delay((value) => {
            setEditNoteText(undefined);
            formik.submitForm();
        }, 200);

    };


    const onCancelNote = () => {
        setIsNoteEdit(false);
        setEditNoteText(undefined);
    };


    useEffect(() => {
        if (piplineCalendarEventType) {
            onOpenFollowupTask();
        }
    }, [piplineCalendarEventType]);

    useEffect(() => {
        if (!toggleFollowupTask) {
            setPiplineCalendarEventType(undefined);
        }
    }, [toggleFollowupTask]);

    const getFullName = () => {
        return (engageActivity.target?.user) ? (`${engageActivity.target?.user?.first_name ?? ""} ${engageActivity.target?.user?.last_name ?? ""}`) : (`${engageActivity.target?.first_name ?? ""} ${engageActivity.target?.last_name ?? ""}`);
    };

    const onSaveFollowupTask = (eventDate: Date, note: string, eventType: PiplineCalendarEventType) => {
        setPiplineCalendarEventType(undefined);
        formik.setFieldValue("note", note);

        setEditNoteText(undefined);
        setIsNoteEdit(false);

        if (eventType == PiplineCalendarEventType.FOLLOWUP) {
            formik.setFieldValue("date", eventDate);
        } else if (eventType == PiplineCalendarEventType.TASK) {
            formik.setFieldValue("check_back_date", eventDate);
        }

        delay((value) => {
            formik.submitForm()
        }, 200);

    }


    const handleDeletEngageUserFollowupNote = (engageUserFollowupNote?: EngageUserFollowupNote) => {
        if (engageUserFollowupNote?.id == editEngageUserFollowupNote?.id) {
            setEditNoteText(undefined);
            setIsNoteEdit(false);
            setEditEngageUserFollowupNote(undefined);
        }
    };

    const handleEditEngageUserFollowupNote = (engageUserFollowupNote?: EngageUserFollowupNote) => {
        if (engageUserFollowupNote) {
            setEditNoteText(engageUserFollowupNote?.note ?? "");
            setIsNoteEdit(true);
            setEditEngageUserFollowupNote(engageUserFollowupNote);
            onCloseViewPiplineNote();
            delay((value) => {
                onOpenAddEditNote();
            }, 200);

        }
    };


    return (
        <div className='w-100'>

            <Card
                key={index}
                sx={{
                    background: AppColors.whiteColor,
                    mb: 2,
                    border: `${AppColors.strokeColor} solid 1px`,
                    p: 1,
                    borderRadius: '10px'
                }}
                elevation={0}
                className='w-100'
            >
                <CardContent
                    sx={{
                        paddingTop: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        paddingBottom: '0px !important',
                        '&:last-child': { pb: '10px' },
                    }}
                >

                    <Stack direction={'row'} sx={{ minHeight: '60px' }}>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",

                            }}
                        >
                            <Box sx={{
                                position: 'relative',
                                cursor: 'pointer',
                                borderRadius: "100%"
                            }}
                                onClick={() => !isConsentPending && setExpanded(!isExpanded)}
                            >
                                {/*<Box sx={{ backgroundColor: (engageActivity?.status != "share") ? '#74f54d' : '#ee8e34', width: '15px', height: '15px', borderRadius: '15px', position: 'absolute', right: -5, top: -5 }}></Box>*/}
                                <Avatar src={getcontactProfile()} sx={{ height: "50px", width: "50px", '& img': { borderRadius: '100%' }, }} />
                            </Box>
                        </Box>



                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "start",
                                paddingRight: "10px",
                                paddingLeft: "10px",
                                flexDirection: 'column',
                                ml: 0.5,
                                mt: 2,
                                flexGrow: 1,
                            }}
                        >
                            <Badge
                                badgeContent={engageActivity.activities || 0}
                                color='error'
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                sx={{ pr: 1 }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        color: AppColors.grayThreeColor,
                                        mt: 0.5,
                                        cursor: 'pointer',
                                        fontWeight: '500'

                                    }}
                                    onClick={() => !isConsentPending && setExpanded(!isExpanded)}
                                >
                                    {getFullName()}
                                </Typography>
                            </Badge>
                            {
                                !isConsentPending && (<Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "start",
                                        flexDirection: 'column',
                                        ml: 0.5,
                                        width: '100%',
                                    }} >
                                    <Box flexDirection={'row'} display={'flex'}>
                                        {sortType != "no_activity" && <Stack flexDirection={'row'} alignItems="center" mt={0.5} mr={2}>
                                            <Box sx={{ backgroundColor: AppColors.greenColor, width: '10px', height: '10px', borderRadius: '10px', marginRight: '5px' }}></Box>
                                            <Typography
                                                sx={{
                                                    color: AppColors.grayThreeColor,
                                                    cursor: 'pointer',
                                                    fontSize: "10px",

                                                }}
                                            >{engageActivity.totalActivities ?? "0"}
                                            </Typography>
                                        </Stack>}
                                        {sortType != "active_leads" && <Stack flexDirection={'row'} alignItems="center" mt={0.5}>
                                            <Box sx={{ backgroundColor: AppColors.orangeColor, width: '10px', height: '10px', borderRadius: '10px', marginRight: '5px' }}></Box>
                                            <Typography
                                                sx={{
                                                    color: AppColors.grayThreeColor,

                                                    cursor: 'pointer',
                                                    fontSize: "10px",

                                                }}
                                            >{engageActivity.totalNoActivity ?? "0"}
                                            </Typography>
                                        </Stack>}
                                    </Box>

                                    <Stack flexDirection={'row'} alignItems="center" mt={0.5}>
                                        <Box sx={{ backgroundColor: AppColors.redColor, width: '10px', height: '10px', borderRadius: '10px', marginRight: '5px' }}></Box>
                                        <Typography
                                            sx={{
                                                color: AppColors.grayThreeColor,

                                                cursor: 'pointer',
                                                fontSize: "10px",

                                            }}
                                        > {getEngageLeadSource(engageActivity)}
                                        </Typography>
                                    </Stack>
                                </Box>)
                            }
                        </Box>


                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "start",
                                flexDirection: 'column',
                            }} >

                            {isConsentPending && <Box sx={{ flexDirection: 'column', flexGrow: 1 }}><Typography sx={{ color: "#45E72B", fontSize: '0.8rem', mt: 0.5, textAlign: 'center' }}> Referral sent by</Typography>
                                <Stack flexDirection={'row'}>
                                    <Box component={'img'} src={getReferByProfile()} sx={{ height: "20px", width: "20px", borderRadius: "20px" }} />
                                    <Typography sx={{ color: AppColors.grayThreeColor, fontSize: '0.8rem', textAlign: 'center', ml: 0.5 }}>{engageActivity.refer_by.name ?? ""}</Typography>
                                </Stack>
                            </Box>}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "end",
                                justifyContent: "center",
                                mr: 1,
                                ml: 1,
                            }}
                        >
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                {isConsentPending && <><Typography sx={{ borderRadius: "20px", color: AppColors.whiteColor, backgroundColor: AppColors.orangeColor, pt: 0.5, pb: 0.5, pl: 1, pr: 1, fontSize: '0.8rem', mt: 0.5, textAlign: 'center' }}>Pending Consent</Typography>


                                    <Typography
                                        sx={{
                                            color: AppColors.grayThreeColor,
                                            fontSize: '0.8rem',
                                            textAlign: 'end',
                                            mt: 1
                                        }}
                                    >{engageActivity.updated_at && moment.utc(engageActivity.updated_at).local().format("MMM DD, YYYY  hh:mm a")} </Typography></>}
                                {!isConsentPending && <>

                                    <Box>
                                        <IconButton
                                            aria-label='more'
                                            id='long-button'
                                            aria-controls={'long-menu'}
                                            aria-expanded={menuOpen}
                                            aria-haspopup='true'
                                            onClick={(event) => handleMenuClick(event)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id='long-menu'
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={menuOpen}
                                            onClose={handleMenuClose}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: 48 * 4.5,
                                                    width: '20ch',
                                                },
                                            }}
                                        >

                                            <MenuItem
                                                key={`edit-contact`}
                                                onClick={() => setEditEngageActivityTarget(engageActivity.target)}
                                            >
                                                Edit
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                </>}

                            </Box>
                        </Box>

                    </Stack>



                    {!isConsentPending && <><Box sx={{ height: "1px", backgroundColor: AppColors.strokeColor, mt: 1, mb: 1, flexGrow: 1 }}></Box><Stack direction={'row'} >
                        <Typography sx={{ color: AppColors.grayThreeColor, fontSize: '0.8rem', mt: 0.5, textAlign: 'center' }}> Last contact at {engageActivity.updated_at && moment.utc(engageActivity.updated_at).local().format("MMM DD, YYYY  hh:mm a")}</Typography>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: 'column',
                                ml: 1,
                                mr: 1,
                                flexGrow: 1
                            }} >

                            {engageActivity.refer_by && <><Typography sx={{ color: AppColors.primaryColor, fontSize: '0.8rem', mt: 0.5, textAlign: 'center' }}> Referral sent by</Typography>
                                <Stack flexDirection={'row'}>
                                    <Box component={'img'} src={getReferByProfile()} sx={{ height: "20px", width: "20px", borderRadius: "20px" }} />
                                    <Typography sx={{ color: AppColors.grayThreeColor, fontSize: '0.8rem', textAlign: 'center', ml: 0.5 }}>{engageActivity.refer_by.name ?? ""}</Typography>
                                </Stack>
                            </>}


                        </Box>
                        {!isConsentPending && <Box sx={{ flexDirection: 'row', }}>
                            {engageActivity?.target?.phone_number && <Box component={'img'} src={SmsIcon} onClick={handleMessageClick} sx={{ m: 1 }} />}
                            {engageActivity?.target?.phone_number && <Box component={'img'} src={CallingIcon} onClick={handleCallClick} sx={{ m: 1 }} />}
                        </Box>}
                    </Stack></>}

                    {isExpanded && <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{
                            mt: 2,
                            p: "3px",
                            ml: 0,

                        }}
                    >

                        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                            <FormikProvider value={formik}>

                                <Box>
                                    <Grid container justifyContent="center"
                                        alignItems="center">
                                        <Grid
                                            item
                                            xs={4.5}
                                            sm={4.5}
                                            md={6}
                                            lg={6}
                                            sx={{ padding: "10px 0 10px 0" }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: AppColors.primaryColor,
                                                    textTransform: "capitalize",
                                                    pt: { md: 0, sm: 1, xs: 1 },
                                                    pb: { md: 0, sm: 1, xs: 1 }
                                                }}
                                            >
                                                Contact disposition
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={7.5}
                                            sm={7.5}
                                            md={6}
                                            lg={6}
                                            sx={{
                                                padding: "0 0 10px 0",
                                                position: "relative"
                                            }}
                                        >
                                            <Stack
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{
                                                    textAlign: 'end',
                                                    color: "#000000",
                                                    position: 'absolute',
                                                    right: 0,
                                                    borderRadius: '5px'
                                                }}

                                            >
                                                <Box
                                                    sx={{
                                                        color: AppColors.whiteColor,
                                                        display: "inline-block",
                                                        alignItems: "center",
                                                        fontWeight: "bold",
                                                        backgroundColor: AppColors.primaryColor,
                                                        borderRadius: '5px'
                                                    }}
                                                    onClick={handleOpenActivity}
                                                >
                                                    <Typography
                                                        sx={{
                                                            pl: 1,
                                                            pr: 1,
                                                            pt: 0.8,
                                                            pb: 0.8,
                                                            textAlign: 'center',
                                                            fontSize: '0.8rem'
                                                        }}
                                                    >View Activity</Typography>

                                                </Box>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Box
                                        sx={{
                                            padding: { md: "15px", sm: "0px", xs: "0px" },
                                        }}
                                    >
                                        <Grid container>

                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                            >
                                                <Grid container gap={1} pt={2}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={5.8}
                                                        lg={5.8}
                                                        sx={{ padding: "0 0 10px 0" }}
                                                    >
                                                        <TextField variant="outlined"
                                                            id='next_follow_up'
                                                            name='next_follow_up'
                                                            placeholder="Next Follow Up"
                                                            fullWidth

                                                            onClick={() => setPiplineCalendarEventType(PiplineCalendarEventType.FOLLOWUP)}
                                                            value={formik.values.date ? moment(formik.values.date).format("MMM DD, yyyy hh:mm a") : ''}
                                                            sx={{
                                                                borderRadius: '4px',
                                                                backgroundColor: AppColors.grayZeroColor,
                                                                "& label": {
                                                                    color: AppColors.grayThreeColor
                                                                },
                                                                "& .MuiOutlinedInput-root input": {
                                                                    color: AppColors.grayThreeColor,
                                                                    "-webkit-text-fill-color": AppColors.grayThreeColor
                                                                },
                                                                "& .MuiOutlinedInput-root fieldset": {
                                                                    "border-color": `${AppColors.strokeColor} !important`
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                        borderWidth: '1px'
                                                                    },

                                                                },
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: AppColors.grayThreeColor
                                                                }
                                                            }}

                                                        />

                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={5.8}
                                                        lg={5.8}
                                                        sx={{ padding: "0 0 10px 0" }}
                                                    >
                                                        <TextField variant="outlined"
                                                            id='check_back_date'
                                                            name='check_back_date'
                                                            placeholder="Task"
                                                            fullWidth
                                                            onClick={() => setPiplineCalendarEventType(PiplineCalendarEventType.TASK)}
                                                            value={formik.values.check_back_date ? moment(formik.values.check_back_date).format("MMM DD, YYYY") : ''}
                                                            sx={{
                                                                borderRadius: '4px',
                                                                backgroundColor: AppColors.grayZeroColor,
                                                                "& label": {
                                                                    color: AppColors.grayThreeColor
                                                                },
                                                                "& .MuiOutlinedInput-root input": {
                                                                    color: AppColors.grayThreeColor,
                                                                    "-webkit-text-fill-color": AppColors.grayThreeColor
                                                                },
                                                                "& .MuiOutlinedInput-root fieldset": {
                                                                    "border-color": `${AppColors.strokeColor} !important`
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                        borderWidth: '1px'
                                                                    },

                                                                },
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: AppColors.grayThreeColor
                                                                }
                                                            }}

                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={5.8}
                                                        lg={5.8}
                                                        sx={{ padding: "0 0 10px 0" }}
                                                    >
                                                        <TextField
                                                            select
                                                            defaultValue=""
                                                            id='interest_level'
                                                            name='interest_level'
                                                            label={formik.values.interest_level ? "" : "Interest level"}
                                                            fullWidth
                                                            onChange={formik.handleChange}
                                                            value={formik.values.interest_level ?? ''}
                                                            sx={{
                                                                borderRadius: '4px',
                                                                backgroundColor: AppColors.grayZeroColor,
                                                                textTransform: 'capitalize',
                                                                "& label": {
                                                                    color: AppColors.grayThreeColor
                                                                },
                                                                "& svg": {
                                                                    color: AppColors.grayThreeColor
                                                                },
                                                                "& .MuiOutlinedInput-root div": {
                                                                    color: AppColors.grayThreeColor
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                        borderWidth: '1px'
                                                                    },

                                                                },
                                                            }}

                                                            SelectProps={{ IconComponent: formik.values.interest_level ? () => null : undefined }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                        {formik.values.interest_level && <CloseIcon sx={{ color: AppColors.grayThreeColor, cursor: 'pointer' }} onClick={() => formik.setFieldValue("interest_level", undefined)} />}
                                                                    </React.Fragment>)
                                                            }}
                                                        >
                                                            {[EngageInterestLevel.HIGH, EngageInterestLevel.MEDIUM, EngageInterestLevel.LOW, EngageInterestLevel.NO_INTEREST].map((option) => (
                                                                <MenuItem key={option} value={option} sx={{ textTransform: "uppercase" }}>
                                                                    {option.toString().replace("_", " ")}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>


                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={5.8}
                                                        lg={5.8}
                                                        sx={{ padding: "0 0 10px 0" }}
                                                    >
                                                        <TextField variant="outlined"
                                                            id='met_at'
                                                            name='met_at'
                                                            placeholder="Met at"
                                                            fullWidth
                                                            onChange={formik.handleChange}
                                                            value={formik.values.met_at ?? ''}
                                                            sx={{
                                                                borderRadius: '4px',
                                                                backgroundColor: AppColors.grayZeroColor,
                                                                "& label": {
                                                                    color: AppColors.grayThreeColor
                                                                },
                                                                "& .MuiOutlinedInput-root input": {
                                                                    color: AppColors.grayThreeColor
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                        borderWidth: '1px'
                                                                    },

                                                                },
                                                            }}

                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={5.8}
                                                        lg={5.8}
                                                        sx={{ padding: "0 0 10px 0" }}
                                                    >
                                                        <TextField variant="outlined"
                                                            id='occupation'
                                                            name='occupation'
                                                            placeholder="Occupation"
                                                            fullWidth
                                                            onChange={formik.handleChange}
                                                            value={formik.values.occupation ?? ''}
                                                            sx={{
                                                                borderRadius: '4px',
                                                                backgroundColor: AppColors.grayZeroColor,
                                                                "& label": {
                                                                    color: AppColors.grayThreeColor
                                                                },
                                                                "& .MuiOutlinedInput-root input": {
                                                                    color: AppColors.grayThreeColor
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                        borderWidth: '1px'
                                                                    },

                                                                },
                                                            }}

                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={5.8}
                                                        lg={5.8}
                                                        sx={{ padding: "0 0 10px 0" }}
                                                    >
                                                        <TextField variant="outlined"
                                                            id='phone'
                                                            name='phone'
                                                            placeholder="Phone Number"
                                                            fullWidth
                                                            onChange={formik.handleChange}
                                                            value={formik.values.phone ?? ''}
                                                            sx={{
                                                                borderRadius: '4px',
                                                                backgroundColor: AppColors.grayZeroColor,
                                                                "& label": {
                                                                    color: AppColors.grayThreeColor
                                                                },
                                                                "& .MuiOutlinedInput-root input": {
                                                                    color: AppColors.grayThreeColor
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                        borderWidth: '1px'
                                                                    },

                                                                },
                                                            }}

                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={5.8}
                                                        lg={5.8}
                                                        sx={{ padding: "0 0 10px 0" }}
                                                    >
                                                        <TextField variant="outlined"
                                                            id='email'
                                                            name='email'
                                                            placeholder="Email Address"
                                                            fullWidth
                                                            onChange={formik.handleChange}
                                                            value={formik.values.email ?? ''}
                                                            sx={{
                                                                borderRadius: '4px',
                                                                backgroundColor: AppColors.grayZeroColor,
                                                                "& label": {
                                                                    color: AppColors.grayThreeColor
                                                                },
                                                                "& .MuiOutlinedInput-root input": {
                                                                    color: AppColors.grayThreeColor
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                        borderWidth: '1px'
                                                                    },

                                                                },
                                                            }}

                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={5.8}
                                                        lg={5.8}
                                                        sx={{ padding: "0 0 10px 0" }}
                                                    >
                                                        <TextField
                                                            select
                                                            defaultValue=""
                                                            id='lead_type'
                                                            name='lead_type'
                                                            label={formik.values.lead_type ? "" : "Interest level"}
                                                            fullWidth
                                                            onChange={formik.handleChange}
                                                            value={formik.values.lead_type ?? ''}
                                                            sx={{
                                                                borderRadius: '4px',
                                                                backgroundColor: AppColors.grayZeroColor,
                                                                textTransform: 'capitalize',
                                                                "& label": {
                                                                    color: AppColors.grayThreeColor
                                                                },
                                                                "& svg": {
                                                                    color: AppColors.grayThreeColor
                                                                },
                                                                "& .MuiOutlinedInput-root div": {
                                                                    color: AppColors.grayThreeColor
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: AppColors.strokeColor,
                                                                        borderWidth: '1px'
                                                                    },

                                                                },
                                                            }}

                                                            SelectProps={{ IconComponent: formik.values.lead_type ? () => null : undefined }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                        {formik.values.lead_type && <CloseIcon sx={{ color: AppColors.grayThreeColor, cursor: 'pointer' }} onClick={() => formik.setFieldValue("lead_type", undefined)} />}
                                                                    </React.Fragment>)
                                                            }}
                                                        >
                                                            {[EngageLeadType.NEW_LEAD, EngageLeadType.NEW_PROSPECT].map((option) => (
                                                                <MenuItem key={option} value={option} sx={{ textTransform: "uppercase" }}>
                                                                    {option.toString().replace("_", " ")}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                        </Grid>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            mt: { md: 0, sm: 2, xs: 2 }
                                        }}
                                    > <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                    >
                                            <Stack direction="row" alignItems="center" onClick={() => handleAddNote()}>
                                                <AddCircleIcon sx={{ color: AppColors.greenColor }} />
                                                <Typography
                                                    sx={{
                                                        pl: 1,
                                                        pr: 1,
                                                        pt: 0.8,
                                                        pb: 0.8,
                                                        color: AppColors.grayThreeColor,
                                                        textAlign: 'center',
                                                        fontSize: '1rem'
                                                    }}
                                                >Add/Edit Notes</Typography>
                                            </Stack></Box>
                                        <LoadingButton
                                            type="button"
                                            onClick={() => {
                                                setIsNoteEdit(true);
                                                delay((value) => { formik.submitForm(); }, 200);
                                            }}
                                            loading={formik.isSubmitting}

                                            sx={{

                                                pr: 2,
                                                pl: 2,
                                                borderRadius: "5px",
                                                color: AppColors.primaryColor,
                                                fontSize: "1rem",
                                                background: AppColors.whiteColor,
                                                mr: 2,
                                                textTransform: 'inherit',
                                                "&:hover": {
                                                    background: AppColors.whiteColor,
                                                }
                                            }}
                                        >
                                            Save
                                        </LoadingButton>
                                        <Button
                                            sx={{

                                                borderRadius: "15px",
                                                color: AppColors.blackColor,
                                                fontSize: "1rem",
                                                bgcolor: AppColors.whiteColor,
                                                textTransform: 'inherit'
                                            }}
                                            onClick={() => {
                                                //setEditEngageUserFollowupNote(undefined);
                                                setExpanded(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>

                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}

                                    >

                                        <Paper
                                            elevation={4}
                                            sx={{
                                                mt: 2,
                                                background: AppColors.grayZeroColor,
                                                borderRadius: "10px",
                                                minHeight: "100px",
                                                p: 0.5,
                                                boxShadow: 'unset'
                                                // pt: 3.5,
                                                // pb: 3,
                                                // textAlign: 'center'

                                            }}
                                        // onClick={() => handleAddNote()}
                                        >
                                            <Typography
                                                sx={{
                                                    pl: 1,
                                                    pr: 1,
                                                    pt: 0.8,
                                                    pb: 0.8,
                                                    color: AppColors.grayThreeColor,

                                                    fontSize: '1rem'
                                                }}
                                            >{editEngageUserFollowupNote?.note ?? ""}</Typography>
                                            {/* <Box>
                                                <AddCircleIcon sx={{ color: '#36c38a' }} />
                                                <Typography
                                                    sx={{
                                                        pl: 1,
                                                        pr: 1,
                                                        pt: 0.8,
                                                        pb: 0.8,
                                                        color: AppColors.grayThreeColor,
                                                        textAlign: 'center',
                                                        fontSize: '1rem'
                                                    }}
                                                >Add/Edit Notes</Typography>
                                            </Box> */}

                                            {/*<TextField
                                                    id="note"
                                                    name='note'

                                                    sx={{
                                                        color: AppColors.grayThreeColor,
                                                        border: "none",
                                                        "& input": {
                                                            "&::placeholder": {
                                                                fontWeight: "bold",
                                                            },
                                                        },
                                                        "& .MuiOutlinedInput-root textarea": {
                                                            color: AppColors.grayThreeColor
                                                        },
                                                        '&:active': {
                                                            outline: 'none',
                                                        },
                                                        '& fieldset': {
                                                            border: 'none',
                                                        }
                                                    }}
                                                    placeholder="Type a Note...."
                                                    onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.note}
                                                    multiline
                                                    rows={4}
                                                    maxRows={4}
                                                    fullWidth
                                                />*/}
                                        </Paper>

                                        <Box sx={{
                                            pt: 2,
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "end",
                                        }}>
                                            <Box
                                                sx={{
                                                    display: "inline-block",
                                                    alignItems: "center",
                                                    fontWeight: "bold",
                                                    backgroundColor: AppColors.primaryColor,
                                                    borderRadius: '5px'
                                                }}
                                                onClick={onOpenViewPiplineNote}
                                            >
                                                <Typography
                                                    sx={{
                                                        pl: 1,
                                                        pr: 1,
                                                        pt: 0.8,
                                                        pb: 0.8,
                                                        textAlign: 'center',
                                                        fontSize: '0.8rem',
                                                        color: AppColors.whiteColor
                                                    }}
                                                >View Notes</Typography>

                                            </Box>
                                        </Box>
                                    </Grid>
                                </Box>
                            </FormikProvider></form>

                    </Grid>}
                </CardContent>
            </Card>


            {
                editEngageActivityTarget && <AddProspectModal
                    onClose={onCloseEditProspect}
                    toggle={toggleEditProspect}
                    onProspectCreateOrUpdated={onProspectCreateOrUpdated}
                    engageActivityTarget={editEngageActivityTarget}
                />
            }


            {
                toggleAddEditNote && <AddPiplineNoteModal
                    key={`Add-Pipline-Note-Modal-${engageActivity.id}`}
                    contactProfile={getcontactProfile()}
                    note={editNoteText ?? ""}
                    onClose={onCloseAddEditNote}
                    onSaveNote={onSaveNote}
                    onCancelNote={onCancelNote}
                    toggle={toggleAddEditNote}
                />
            }

            {
                toggleFollowupTask && piplineCalendarEventType && <AddPiplineCalendarEventModal
                    key={`Add-Pipline-Note-Modal-${engageActivity.id}`}
                    contactProfile={getcontactProfile()}
                    title={getFullName()}
                    piplineCalendarEventType={piplineCalendarEventType}
                    onClose={onCloseFollowupTask}
                    onSaveEvent={onSaveFollowupTask}
                    toggle={toggleFollowupTask}
                />
            }

            {
                toggleViewPiplineNote && <ViewPiplineNoteModal
                    onClose={onCloseViewPiplineNote}
                    toggle={toggleViewPiplineNote}
                    contactProfile={getcontactProfile()}
                    engageActivity={engageActivity}
                    onDeletEngageUserFollowupNote={handleDeletEngageUserFollowupNote}
                    onEditEngageUserFollowupNote={handleEditEngageUserFollowupNote}
                />
            }

        </div >
    );
};

export default EngagePiplineItem;
