
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/system';
import { Transition } from '../Transition';
import { useAppDispatch, useAppSelector } from '../../store';
import CancelIcon from '@mui/icons-material/Cancel';
import { AppColors } from '../../utils/utils';
import InfoComponent from '../NewTabPanels/InfoComponent';
import { IconButton } from '@mui/material';

interface IProps {
  toggle: boolean;
  onClose: () => void;
}

const ContactInfoModal: React.FC<IProps> = ({
  toggle,
  onClose,
}) => {

  return (
    <Dialog
      fullWidth
      open={toggle}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onClose();
      }}
      PaperProps={{
        sx: {
          borderRadius: '20px !important',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        },
      }}
    >
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 10,
          top: 10,
          zIndex: 100000,
          color: AppColors.grayTwoColor,
          alignSelf: 'center',
          alignItems: 'center',
          '-webkit-text-stroke-width': '1px',
          '-webkit-text-stroke-color': 'black'
        }}
      >
        <CancelIcon />
      </IconButton>
      <DialogTitle
        sx={{
          backgroundColor: AppColors.backgroundColor,
          color: 'white',
          pt: 1,
          pb: 1,
        }}
      >
        <Stack flexDirection={'row'} alignItems={'center'}>
          <InfoComponent />
        </Stack>
      </DialogTitle>
    </Dialog>
  );
};

export default ContactInfoModal;
