import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Avatar, Box, Button, Card, CardContent, CircularProgress, Container, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Radio, Select, SelectChangeEvent, Stack, TextField, Typography, useMediaQuery } from '@mui/material';

import Page from '../components/Page';
import useWindowSize from '../utils/useWindowSize';
import { getEngagePipeline } from '../store/engagePipeline/api';
import { AppDispatch, useAppSelector } from '../store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { checkIsProProOrProOneOrProPlusUser } from '../utils/checkPro';
import { useDispatch } from 'react-redux';

import InfiniteScroll from 'react-infinite-scroller';
import { isNotEmpty } from '../utils/valid';
import EngagePiplineItem from '../components/engage/EngagePiplineItem';
import { debounce } from 'lodash';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { getEngageUserActivityReport } from '../store/engage/api';
import { EngageUserActivityReportItem, ExternalLinkReportItem } from '../store/engage/api/interface';
import EngageActivityReportItem from '../components/engage/EngageActivityReportItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import useToggle from '../hooks/useToggle';
import AddProspectModal from '../components/modal/AddProspectModal';
import { ContactModel } from '../store/prospects/api/interface';
import ExternalLinkReportItemView from '../components/engage/ExternalLinkReportItemView';
import { MediaShareReportItem, VideoViewsReportItem } from '../store/reports/api/interface';
import PostFeedbackFormReportItemView from '../components/engage/PostFeedbackFormReportItemView';
import BlockOverlay from '../components/BlockOverlay';
import MediaPostVideoViewReportItemView from '../components/engage/MediaPostVideoViewReportItemView';
import { clearPipelineActivityCount } from '../store/engagePipeline/engagePipelineSlice';
import { AppColors } from '../utils/utils';
import SearchIcon from '../assets/search.svg';
import { EngageActivity } from '../store/engagePipeline/api/interface';

const EngagePipline: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { user } = useAppSelector((state) => state?.auth);

    const navigate = useNavigate();

    const [sortType, setSortType] = useState<string>("desc");

    const [followUpScheduledFilter, setFollowUpScheduledFilter] = useState<string>("all");

    const [searchName, setSearchName] = useState<string>("");
    const [isViewActivity, setViewActivity] = useState<boolean>(false);

    const userInfo = useAppSelector((state) => state?.auth?.user);
    const isProOneOrProPlusUser = checkIsProProOrProOneOrProPlusUser(userInfo);

    const [searchParams] = useSearchParams();

    const { isLoading, isLoadMore, page } = useAppSelector(
        (state) => state?.engagePipline
    );
    const engagePiplines = useAppSelector((state) => state?.engagePipline?.data) ?? [];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);



    const handleLoadMore = (pageNumber = page, isReload = false) => {
        if (!isProOneOrProPlusUser) {
            return;
        }
        if ((!isLoading && isLoadMore) || isReload) {
            dispatch(getEngagePipeline({ pageNo: pageNumber, order_by: sortType, search_name: searchName, followUpScheduleFilter: followUpScheduledFilter }));
        }
    };



    useEffect(() => {
        if (!isProOneOrProPlusUser) {
            return;
        }

        let searchText = searchParams.get('search');
        if (searchText) {
            setSearchName(searchText);
            setViewActivity(true);
        } else if (engagePiplines.length == 0 && !isLoading) {
            handleLoadMore();
        }
    }, []);

    useEffect(() => {
        if (engagePiplines.length > 0 && isViewActivity && selectedEngageActivity == undefined) {
            const engageActivity = engagePiplines[0];
            const contactUserType = engageActivity.target_type == "App\\Models\\User" ? "U" : "C";
            var contactUserId = engageActivity.target?.id ?? 0;
            openEngageActivityReportSortMarker(`${contactUserType}${contactUserId}`, engageActivity.totalActivities, engageActivity.id, engageActivity);
        }

    }, [engagePiplines]);

    useEffect(() => {
        handleLoadMore(1, true);
    }, [searchName, sortType, followUpScheduledFilter]);

    const matches = useMediaQuery('(max-width:745px)');
    const windowSize = useWindowSize();
    const [cardWidth, setCardWidth] = useState<number>(300);



    const [engageUserActivityReportItemList, setEngageUserActivityReportItemList] = useState<EngageUserActivityReportItem[]>([]);
    const [externalLinkReportItemList, setExternalLinkReportItemList] = useState<ExternalLinkReportItem[]>([]);
    const [postFeedbackFormReportItemList, setPostFeedbackFormReportItemList] = useState<MediaShareReportItem[]>([]);
    const [videoViewsReportItemList, setVideoViewsReportItemList] = useState<VideoViewsReportItem[]>([]);

    const [engageActivitySortMarker, setEngageActivitySortMarker] = useState<string | undefined>(undefined);
    const [selectedEngageActivity, setSelectedEngageActivity] = useState<EngageActivity | undefined>(undefined);
    const [contactDispositionTotalActivities, setContactDispositionTotalActivities] = useState<number | undefined>(undefined);

    const [isEngageActivityReportLoading, setEngageActivityReportLoading] = useState<boolean>(false);


    useEffect(() => {
        if (engageActivitySortMarker) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }, [engageActivitySortMarker]);

    useEffect(() => {
        const newWidth = matches ? windowSize.width * 0.90 : windowSize.width > 890 ? 745 : windowSize.width * 0.90;
        setCardWidth(newWidth);
    }, [windowSize]);

    const handleChangeSortFilter = (event: SelectChangeEvent) => {
        setSortType(event.target.value as string);
    };


    const handleFollowUpScheduledFilter = (event: SelectChangeEvent) => {
        setFollowUpScheduledFilter(event.target.value as string);
    };


    const handleSearchNameFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchName(e.target.value ?? "");
    };

    const openEngageActivityReportSortMarker = async (sortMarker?: string, totalActivities?: number, engageLeadId?: number, engageActivity?: EngageActivity) => {
        setSelectedEngageActivity(engageActivity);
        setEngageActivitySortMarker(sortMarker);
        setContactDispositionTotalActivities(totalActivities);
        setEngageActivityReportLoading(true);
        if (sortMarker != undefined) {
            try {
                setEngageUserActivityReportItemList([]);
                const result = await dispatch(getEngageUserActivityReport({ user_id: userInfo?.id ?? 0, sort_marker: sortMarker, days: 180, isFromEngage: true, elid: engageLeadId })).unwrap();
                if (result.data.status == "success") {
                    setEngageUserActivityReportItemList(result.data.data);
                    setExternalLinkReportItemList(result.data.external_link_report ?? []);
                    setPostFeedbackFormReportItemList(result.data.post_feedback_form ?? []);
                    setVideoViewsReportItemList(result.data.media_post_video_viewReport ?? []);
                    dispatch(clearPipelineActivityCount(engageLeadId));
                }
            } catch (e) { }
        } else {
            if (isViewActivity) {
                setViewActivity(false);
                setSearchName("");
                navigate(`${window.location.pathname}`, { replace: true });
            }
        }
        setEngageActivityReportLoading(false);
    };

    const getFullName = (engageActivity?: EngageActivity) => {
        return (engageActivity?.target?.user) ? (`${engageActivity?.target?.user?.first_name || ""} ${engageActivity?.target?.user?.last_name || ""}`) : (`${engageActivity?.target?.first_name || ""} ${engageActivity?.target?.last_name || ""}`);
    };

    const {
        toggle: toggleAddProspect,
        onOpen: onOpenAddProspect,
        onClose: onCloseAddProspect,
    } = useToggle();

    const handleAddProspect = () => {
        onOpenAddProspect();
    }

    const onProspectCreateOrUpdated = (contact?: ContactModel) => {
        handleLoadMore(1, true);
    }

    const debounceWithSearchName = debounce(handleSearchNameFilter, 1300);

    return (
        <Page title='Pipeline | KnoCard' showAppBar={true} sx={{ minHeight: '100vh' }} >
            <Container maxWidth='xl' sx={{ width: "100% !important", maxWidth: "none !important", marginLeft: "0px !important", marginRight: "0px !important" }}>
                <Grid container>
                    <Grid item xs={engageActivitySortMarker == null ? 6 : 12} sm={engageActivitySortMarker == null ? 6 : 12}>
                        <Stack direction={'row'} sx={{ mt: 3 }} alignItems={'center'}  >
                            {engageActivitySortMarker && <IconButton onClick={() => {
                                openEngageActivityReportSortMarker(undefined, 0, undefined, undefined);
                            }}  ><ArrowBackIosIcon sx={{ color: AppColors.grayThreeColor }} /></IconButton>}
                            <Typography sx={{ color: engageActivitySortMarker != null ? AppColors.grayThreeColor : AppColors.primaryColor, fontSize: engageActivitySortMarker != null ? "15px" : "18px" }}>
                                {engageActivitySortMarker != null ? getFullName(selectedEngageActivity) : "Contact Disposition"}
                            </Typography>
                        </Stack>
                        {engageActivitySortMarker != null && (<Typography sx={{ color: AppColors.grayThreeColor, fontSize: "18px" }}>Contact Disposition Activity</Typography>)}
                    </Grid>

                    {engageActivitySortMarker != null && <Grid item xs={12}>
                        <Divider sx={{ mt: 1.5 }}></Divider>
                        <Stack direction={'row'} sx={{ mt: 2.3 }} alignItems="center" >
                            <Typography sx={{ color: AppColors.grayThreeColor, fontSize: "18px", mr: 1, pl: 1 }}>
                                Total Activity
                            </Typography>
                            <Typography sx={{ background: AppColors.orangeColor, color: AppColors.whiteColor, fontSize: "14px", pl: 1.5, pr: 1.5, pt: 0.6, pb: 0.6, borderRadius: 10, wordBreak: 'break-word' }}>
                                {`${contactDispositionTotalActivities ?? 0}`} Views
                            </Typography>
                        </Stack>

                    </Grid>}
                    <Grid item xs={6} sm={6} sx={{ textAlign: 'end' }}>
                        {engageActivitySortMarker == null && <Box sx={{
                            display: "inline-block",
                            alignItems: "center",
                            fontWeight: "bold",

                        }} ><Stack
                            direction={'row'}
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                mt: { md: 3, sm: 4, xs: 5 },
                                color: "#121f25",
                                fontWeight: "bold",
                                borderRadius: '5px'
                            }}

                            onClick={handleAddProspect}
                        >
                                <AddCircleIcon sx={{ color: AppColors.greenColor }} />
                                <Typography
                                    sx={{
                                        pl: 1,
                                        pr: { md: 2, sm: 1, xs: 1 },
                                        textAlign: 'center',
                                        fontSize: '1.3rem',
                                        color: AppColors.grayThreeColor
                                    }}
                                >Add Prospect</Typography>

                            </Stack></Box>}
                    </Grid>
                </Grid>



                {!engageActivitySortMarker && <>
                    <Grid container>
                        <Grid item md={8} sm={8} xs={12} >
                            <Box p={1}><TextField
                                placeholder="Search"
                                InputProps={{
                                    startAdornment: (<React.Fragment>
                                        <Box component={'div'} sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',

                                        }}>
                                            <Box
                                                component={'img'}
                                                src={SearchIcon}
                                                alt='search icon'
                                                sx={{
                                                    width: '30px',
                                                    height: '30px',
                                                }}
                                            />
                                        </Box>
                                    </React.Fragment >),
                                }}

                                onChange={debounceWithSearchName}
                                variant="outlined" sx={{
                                    background: AppColors.whiteColor,
                                    width: '100%', "& .MuiOutlinedInput-root input": {
                                        color: AppColors.grayThreeColor
                                    },
                                    "& .MuiFormLabel-root": {
                                        color: AppColors.grayThreeColor
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: AppColors.strokeColor,
                                        },
                                        '&:hover fieldset': {
                                            borderColor: AppColors.strokeColor,
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: AppColors.strokeColor,
                                            borderWidth: 1
                                        },
                                    },
                                }} /></Box>
                        </Grid>
                        <Grid item md={2} sm={2} xs={12} >
                            <Box p={1}><FormControl fullWidth sx={{
                                background: AppColors.whiteColor,
                                width: '100%',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: AppColors.strokeColor,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: AppColors.strokeColor,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: AppColors.strokeColor,
                                        borderWidth: 1
                                    },
                                },
                            }}>
                                <Select
                                    labelId="sort-label"
                                    id="sort-select"
                                    value={sortType}
                                    placeholder="Sort"
                                    onChange={handleChangeSortFilter}
                                    sx={{
                                        color: AppColors.grayThreeColor,
                                        "& .MuiSvgIcon-root": {
                                            color: AppColors.grayThreeColor,
                                        }
                                    }}
                                >
                                    <MenuItem value={"desc"}>Newest</MenuItem>
                                    <MenuItem value={"asc"}>Oldest</MenuItem>
                                    <MenuItem value={"total_activity"}>Total Activity</MenuItem>
                                    <MenuItem value={"active_leads"}>Active Leads</MenuItem>
                                    <MenuItem value={"no_activity"}>No Activity</MenuItem>
                                    <MenuItem value={"highly_interested"}>Highly Interested</MenuItem>
                                    <MenuItem value={"moderately_interested"}>Moderately Interested</MenuItem>
                                    <MenuItem value={"knocard_pro_basic"}>KnoCard Pro Basic</MenuItem>
                                    <MenuItem value={"knocard_pro_pro"}>KnoCard Pro</MenuItem>
                                    <MenuItem value={"knocard_pro1"}>KnoCard Pro1</MenuItem>
                                    <MenuItem value={"knocard_pro_plus"}>KnoCard Pro Plus</MenuItem>
                                    <MenuItem value={"knocard_pro_free_trial"}>KnoCard Pro Free Trial</MenuItem>
                                    <MenuItem value={"knocard_social_connect"}>Social</MenuItem>
                                    <MenuItem value={"new_lead"}>New Lead</MenuItem>
                                    <MenuItem value={"new_prospect"}>New Prospect</MenuItem>
                                </Select>
                            </FormControl></Box>
                        </Grid>

                        <Grid item md={2} sm={2} xs={12} >

                            <Box p={1}><FormControl fullWidth sx={{
                                background: AppColors.whiteColor,
                                width: '100%',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: AppColors.strokeColor,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: AppColors.strokeColor,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: AppColors.strokeColor,
                                        borderWidth: 1
                                    }
                                },
                            }}>
                                <Select
                                    labelId="follow-up-scheduled-label"
                                    id="follow-up-scheduled-select"
                                    value={followUpScheduledFilter}
                                    placeholder="Follow up Scheduled"
                                    onChange={handleFollowUpScheduledFilter}
                                    sx={{
                                        color: AppColors.grayThreeColor,
                                        "& .MuiSvgIcon-root": {
                                            color: AppColors.grayThreeColor,
                                        }

                                    }}
                                >
                                    <MenuItem value={"all"}>All</MenuItem>
                                    <MenuItem value={"today"}>Today</MenuItem>
                                    <MenuItem value={"this_week"}>This Week</MenuItem>
                                    <MenuItem value={"this_month"}>This Month</MenuItem>
                                </Select>
                            </FormControl></Box>

                        </Grid>

                    </Grid>

                    <Grid container sx={{ pt: 2 }} flexDirection={'column'}>

                        {isLoading && page === 1 && engagePiplines.length == 0 ? (
                            <Box
                                display='flex'
                                justifyContent='center'
                                pt={4}
                            // height={'85vh'}
                            >
                                <Stack>
                                    <CircularProgress />
                                </Stack>
                            </Box>
                        ) : engagePiplines && isNotEmpty(engagePiplines) ? (
                            <Box
                                display='flex'
                                flexDirection='column'
                                alignItems='center'
                                gap={2}
                                sx={{ mt: 2 }}
                                paddingBottom={4}
                            >
                                <InfiniteScroll
                                    pageStart={1}
                                    initialLoad={false}
                                    loadMore={handleLoadMore}
                                    hasMore={isLoadMore}
                                    className='w-100'
                                    loader={
                                        <Box
                                            display='flex'
                                            justifyContent='center'
                                            mt={2}
                                        >
                                            <CircularProgress />
                                        </Box>
                                    }
                                >
                                    {engagePiplines?.map((engageActivity, index) => (
                                        <EngagePiplineItem
                                            key={`engage-pipline-item-${engageActivity.id}`}
                                            engageActivity={engageActivity} index={index} openEngageActivityReportSortMarker={openEngageActivityReportSortMarker} sortType={sortType}
                                            onProspectCreateOrUpdated={onProspectCreateOrUpdated}
                                        />
                                    ))}
                                </InfiniteScroll>

                            </Box>
                        ) : (
                            <Box
                                display='flex'
                                flexDirection='column'
                                alignItems='center'
                            // height={'85vh'}
                            >
                                {isProOneOrProPlusUser && (<Typography mt={'42vh'} sx={{ color: AppColors.blackColor }}>No data found.</Typography>)}
                            </Box>
                        )}
                    </Grid></>}

                {engageActivitySortMarker && <><Grid container sx={{ pt: 2 }} flexDirection={'column'}>

                    {isEngageActivityReportLoading && engageUserActivityReportItemList.length == 0 ? (
                        <Box
                            display='flex'
                            justifyContent='center'
                            pt={4}
                        // height={'85vh'}
                        >
                            <Stack>
                                <CircularProgress />
                            </Stack>
                        </Box>
                    ) : engageUserActivityReportItemList && isNotEmpty(engageUserActivityReportItemList) ? (
                        <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                            gap={3}
                            sx={{ mt: 2 }}
                            paddingBottom={4}
                        >
                            <InfiniteScroll
                                pageStart={1}
                                initialLoad={false}
                                loadMore={handleLoadMore}
                                hasMore={isLoadMore}
                                className="w-100"
                                loader={
                                    <Box
                                        display='flex'
                                        justifyContent='center'
                                        mt={2}
                                    >
                                        {/* <CircularProgress /> */}
                                    </Box>
                                }
                            >
                                {engageUserActivityReportItemList?.map((engageUserActivityReportItem, index) => (
                                    <EngageActivityReportItem key={`engage-user-activity-report-item-${engageUserActivityReportItem.id}`} activityReportItem={engageUserActivityReportItem} index={index} />
                                ))}

                                {postFeedbackFormReportItemList?.map((postFeedbackFormReportItem, index) => (
                                    <PostFeedbackFormReportItemView key={`engage-post-feedback-form-report-item-${postFeedbackFormReportItem.id}`} postFeedbackFormReportItem={postFeedbackFormReportItem} index={index} />
                                ))}

                                {externalLinkReportItemList?.map((externalLinkReportItem, index) => (
                                    <ExternalLinkReportItemView key={`engage-user-activity-external-link-report-item-${externalLinkReportItem.id}`} externalLinkReportItem={externalLinkReportItem} index={index} />
                                ))}


                                {videoViewsReportItemList?.map((videoViewsReportItem, index) => (
                                    <MediaPostVideoViewReportItemView key={`engage-video-views-report-item-${videoViewsReportItem.id}`} videoViewsReportItem={videoViewsReportItem} index={index} />
                                ))}



                            </InfiniteScroll>

                        </Box>
                    ) : (
                        <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                        // height={'85vh'}
                        >
                            <Typography mt={'42vh'} sx={{ color: AppColors.blackColor }}>No data found.</Typography>
                        </Box>
                    )}
                </Grid></>}
            </Container>
            <AddProspectModal
                onClose={onCloseAddProspect}
                toggle={toggleAddProspect}
                onProspectCreateOrUpdated={onProspectCreateOrUpdated}
            />
            <BlockOverlay isBlock={!isProOneOrProPlusUser} />

        </Page>
    );
};

export default EngagePipline;
