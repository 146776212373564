import { LoadingButton } from "@mui/lab";
import { Avatar, Box, CircularProgress, DialogContentText, Grid, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField } from "@mui/material";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/system";
import { Transition } from "../Transition";

import { useAppDispatch, useAppSelector } from "../../store";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { UserInfo } from "../../store/userInfo/api/interface";
import debounce from 'lodash/debounce';
import SearchIcon from '@mui/icons-material/Search';
import { getCommunityUsers } from "../../store/communityUsers/api";
import { reset as resetCommunityUsers } from "../../store/communityUsers/communityUsersSlice";
import { GroupMediaItem } from "../../store/corporateReporting/api/interface";
import { getGroupMedia } from "../../store/corporateReporting/api";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

interface IProps {
    toggle: boolean;
    onClose: () => void;
    selectedMedia: GroupMediaItem[];
    onSelect: (selectedItems: GroupMediaItem[]) => void;
}


const SelectGroupMediaModal: React.FC<IProps> = ({ toggle, onClose, onSelect, selectedMedia }) => {

    const dispatch = useAppDispatch();
    const userInfo = useAppSelector((state) => state?.auth.user);

    const [selectedMediaList, setSelectedMediaList] = useState(selectedMedia);

    const groupMedia = useAppSelector((state) => state?.corporateReporting.groupMedia) || [];
    const isGroupMediaLoading = useAppSelector((state) => state?.corporateReporting.isGroupMediaLoading) || false;
    const onCancel = () => {
        onClose();
    };


    const onDone = () => {
        onSelect(selectedMediaList);
        onClose();
    };



    useEffect(() => {
        if (toggle && !isGroupMediaLoading && groupMedia.length == 0) {
            dispatch(getGroupMedia({
                groupId: userInfo?.affiliate_group?.id || 0
            }));
        }
    }, [toggle])


    const isSelectedItem = (groupMediaItem: GroupMediaItem) => {
        return selectedMediaList.includes(groupMediaItem);
    }

    const handleOnSelect = (groupMediaItem: GroupMediaItem) => {
        if (selectedMediaList.includes(groupMediaItem)) {
            setSelectedMediaList(selectedMediaList.filter(item => item.id !== groupMediaItem.id));
        } else {
            setSelectedMediaList([...selectedMediaList, groupMediaItem]);
        }
    }


    return (
        <>
            <Dialog
                fullWidth
                open={toggle}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    onCancel();
                }}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        maxHeight: '60%'
                    }
                }}
            >
                <DialogTitle
                    sx={{
                        backgroundColor: "#088ac6",
                        color: "white",
                    }}
                >
                    Select Group Media
                </DialogTitle>

                <DialogContent sx={{ width: '100%', padding: 0 }}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                        component="span"
                    >
                        <nav>
                            <List>

                                {(groupMedia?.length ?? 0) === 0 ? (
                                    <Stack direction="row" alignItems="center" justifyContent="center">
                                        {!isGroupMediaLoading && 'No media found.'}
                                    </Stack>
                                ) : (
                                    groupMedia?.map((item: GroupMediaItem, idx: number) => {
                                        return <ListItemButton key={`group-media-${item.id}`} onClick={() => {
                                            handleOnSelect(item);
                                        }}>
                                            <ListItem
                                                secondaryAction={
                                                    isSelectedItem(item) ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />
                                                }
                                            >

                                                <ListItemText primary={item.description || ""} />


                                            </ListItem>
                                        </ListItemButton>

                                    })
                                )}
                                {isGroupMediaLoading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress />
                                </Box>}
                            </List>
                        </nav>
                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ paddingX: 3 }}>
                    <LoadingButton
                        type="button"
                        loading={false}
                        variant="contained"
                        onClick={onCancel}
                    >
                        Cancel
                    </LoadingButton>
                    <LoadingButton
                        type="button"
                        loading={false}
                        variant="contained"
                        onClick={onDone}
                    >
                        Done
                    </LoadingButton>
                </DialogActions>

            </Dialog>
        </>
    );
};

export default SelectGroupMediaModal;
