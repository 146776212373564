import * as Yup from 'yup';

const EditProfileSchema = Yup.object().shape({
  firstName: Yup.string().trim()
    // .min(2, 'Too Short!')
    // .max(50, 'Too Long!')
    .required('First name is required. Please add your first name.'),
  lastName: Yup.string().trim()
    // .min(2, 'Too Short!')
    // .max(50, 'Too Long!')
    .required('Last name is required. Please add your last name.'),
  username: Yup.string().trim().required(
    'Username is required. Please add your username.'
  ).matches(/^[a-zA-Z0-9]+$/, {
    message: 'Please choose a username containing letters and numbers only. Special characters and spaces are not allowed.'
  }),
  city: Yup.string().trim().required('City is required. Please enter city.'),
  state: Yup.string().trim().required('State is required. Please enter state.'),
  zipCode: Yup.string().trim().required('Zip Code is required. Please enter zip code.'),
});
export default EditProfileSchema;
