import * as Yup from 'yup';
import { vimeoVideoRegex } from '../constant';



const vimeoLinkPostSchema = Yup.object().shape({
    title: Yup.string().required('Title is required.'),
    link: Yup.string().required('Link is required.').matches(vimeoVideoRegex, "Link should be a valid vimeo URL")
});
export default vimeoLinkPostSchema;
