import { Timestamp } from 'firebase/firestore';
import { STATUS_TYPE } from '../../../enum';
import { UserInfo } from '../../userInfo/api/interface';
import { number } from 'yup';

export interface EngagePipelineResponse {
  status: {
    code: string;
    description: string;
  };
  data: {
    current_page: number;
    data: EngageActivity[]

  };
  plans: Plan[];
}


export interface EngageLastContactedUpdateResponse {
  status: string;
  message: string;
  data: EngageActivity;
}


export interface CreateEngageUserFollowupNote {
  followup_user_id: number;
  followup_user_type: string;
  engage_report_id: number;
  interest_level?: string;
  met_at?: string;
  occupation?: string;
  phone?: string;
  email?: string;
  lead_type?: string;
  date?: Date;
  check_back_date?: Date;
  note: string;
}

export interface ContactPiplineLeadResponse {
  data?: ContactPiplineLead,
  message?: string;
  status?: string;
}

export interface ContactPiplineLead {
  consent_at?: string;
  consent_status?: string;
  created_at?: string;
  id?: number;
  referby_id?: number;
  referby_target_id?: number;
  referby_target_type?: string;
  share_id?: number;
  share_type?: string;
  sort_marker?: string;
  status?: string;
  target_id?: number;
  target_type?: string;
  updated_at?: string;
  user_id?: number;
}


export interface UpdateEngageUserFollowupNote {
  id: number;
  interest_level?: string;
  met_at?: string;
  occupation?: string;
  phone?: string;
  email?: string;
  lead_type?: string;
  date?: Date;
  check_back_date?: Date;
  note: string;
}

export interface CreateUpdateEngageUserFollowupNoteResponse {
  status: string;
  data: EngageUserFollowupNote;
}


export interface EngageUserFollowupNoteResponse {
  status: string;
  message: string;
  data: {
    current_page?: number;
    data: EngageUserFollowupNote[];
    first_page_url?: string;
    from?: number;
    last_page?: number;
    last_page_url?: string;
    next_page_url?: string;
    path?: string;
    per_page?: number;
    prev_page_url?: string;
    to?: number;
    total?: number;
  }

}

export interface EngageUserFollowupNote {
  user_id?: number;
  followup_user_id?: number;
  followup_user_type?: string;
  engage_report_id?: number;
  occupation?: string;
  interest_level?: string;
  lead_type?: string;
  email?: string;
  phone?: string;
  met_at?: string;
  date?: string;
  check_back_date?: string;
  note?: string;
  updated_at?: string;
  created_at?: string;
  id?: number;
}

export interface Plan {
  id: number;
  product_id: number;
  code: string;
  price: string;
  bill_frequency: number;
  trial_period?: number;
  bill_cycle: string;
  status: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}



export interface EngageActivity {
  id: number;
  user_id: number;
  target_id: number;
  target_type: string;
  share_id: number;
  share_type: string;
  status: string;
  totalActiveLead?: string;
  totalNoActivity?: string;
  created_at: string;
  updated_at: string;
  sort_marker?: string;
  totalActivities?: number;
  activities?: number;
  target?: EngageActivityTarget;
  engage_report_note?: EngageUserFollowupNote
  share?: {
    id?: number;
    user_id?: number;
    share_type?: string;
    action_type?: string;
    target_id?: number;
    targetable_type?: string;
    post?: {
      id?: number;
      user_id?: number;
      description?: string;
    },
    company_feed?: {
      id?: number;
      company_id?: number;
      title?: string;
      description?: string;
    }
  };
  refer_by: UserInfo,
  referby_id?: number;
  consent_status?: string;
  consent_at?: string;
  referby_target_id?: number;
  referby_target_type?: string;
}


export interface EngageActivityTarget {

  id?: number;
  user_id?: number;
  contact_user_id?: number;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  email?: string;
  where_i_met_you?: string;
  occupation?: string;
  notes?: string;
  contact_code?: string;
  created_at?: Date;
  updated_at?: Date;
  is_share?: number;
  is_favorite?: number;
  is_saved_using_app?: string;
  profile_picture?: string;
  user?: UserInfo;
  documentUrl?: string;
  active_subscription?: ActiveSubscription;
  source_type?: string;
}

interface ActiveSubscription {
  id: number;
  user_id: number;
  plan_id: number;
  subscription_reference: any;
  trial_ends_at: any;
  ends_at: any;
  status: string;
  gateway: any;
  extras: any;
}

interface InitialState {
  data: EngageActivity[];
  plans: Plan[];
  contactPiplineLead: ContactPiplineLead | null;
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  page: number;
  isLoadMore: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

export const initialState: InitialState = {
  data: [],
  plans: [],
  contactPiplineLead: null,
  status: 'idle',
  isError: false,
  isLoading: false,
  page: 1,
  isLoadMore: true,
  isSuccess: false,
  errorMessage: '',
};
