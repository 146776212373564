import { Container, Stack, TextField, Typography, Card, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ContentStyle } from '../components/ContentStyle';
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import { getUserInfo } from '../store/userInfo/api';
import { setUserInfo } from '../store/userInfo/userInfoSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { INotFoundParams } from '../enum';

const NotFound = () => {
  const params = useParams<INotFoundParams>();
  const [username, setUsername] = useState('');
  const [affiliateUrl, setAffiliateUrl] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading } = useAppSelector((state) => state.userInfo);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!username) return;
    try {
      const res = await dispatch(getUserInfo({ username })).unwrap();
      if (res.data.data.user.length > 0) {
        dispatch(setUserInfo(res.data));
        navigate(`/${res.data.data.user[0].username}`);
      }
    } catch (error) { }
  };

  return (
    <div style={{ position: 'relative' }}>
      <Container maxWidth='sm'>
        <ContentStyle>
          <Card sx={{ padding: 3 }}>
            <form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <img src='/static/images/search_image.png' alt='search-img' />
                <Typography
                  variant='h6'
                  gutterBottom
                  color='secondary'
                  sx={{ fontWeight: 'bold' }}
                >
                  Hi, we couldn't find any username on the url !
                </Typography>
                <Typography variant='body2' gutterBottom color='black'>
                  We know how frustrating it is to see this page, still you can
                  check the details of the failure and share it to our developer
                  to make this platform bulletproof
                </Typography>
                <TextField
                  disabled={isLoading}
                  fullWidth
                  type='text'
                  label='Username'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton edge='end' type='submit'>
                          {isLoading ? (
                            <CircularProgress size='25px' color='inherit' />
                          ) : (
                            <SearchIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </form>
          </Card>
        </ContentStyle>
      </Container>
    </div>
  );
};

export default NotFound;
