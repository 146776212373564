import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  InputAdornment,
  SxProps,
  TextField,
  Theme,
  createFilterOptions,
} from '@mui/material';
import {
  findAffiliateGroup,
  findAffiliateGroupPricing,
  // searchSelectSite,
} from '../store/AffiliateGroup/api';

import { useAppDispatch, useAppSelector } from '../store';
import Loader from './Loader';
import { AffiliateGroup } from '../store/AffiliateGroup/api/interface';
import GroupIcon from '../assets/group.svg'
import ArrowDownIcon from '../assets/profile/arrow_down.svg';

interface IProps {
  sx?: SxProps<Theme>;
  defaultValue?: AffiliateGroup;
  onAffiliateGroupSelected: (ag?: AffiliateGroup) => void;
  isDisable?: boolean;
  iconPath?: string;
}
const AffiliateGroupFilter: React.FC<IProps> = ({
  sx,
  defaultValue,
  onAffiliateGroupSelected,
  isDisable,
  iconPath
}) => {
  // const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data, isLoading, status, isPricingLoading, affiliateGroupPricing } = useAppSelector(
    (state) => state.affiliateGroup
  );

  const [id, setId] = useState(-1);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(findAffiliateGroup());
    }
  }, [status]);

  useEffect(() => {
    if (isPricingLoading === false && affiliateGroupPricing.length === 0) {
      dispatch(findAffiliateGroupPricing());
    }
  }, []);

  useEffect(() => {
    if (defaultValue == null) {
      setId(-1);
    } else {
      setId(defaultValue.id ?? -1);
    }

  }, [defaultValue]);


  return (
    <Autocomplete
      disabled={isDisable}
      loading={isLoading}
      loadingText={<Loader />}
      value={data?.filter((e) => e.id === id)?.[0] || defaultValue}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
        } else if (newValue) {
          setId(newValue?.id ?? -1);
          onAffiliateGroupSelected(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {

        if (!newInputValue) {
          setId(-1);
          onAffiliateGroupSelected(undefined);
        }
      }}
      key={`select-controllable-affiliate-group-${id}`}
      id='controllable-affiliate-group'
      popupIcon={<Box component={'img'} src={ArrowDownIcon} />}
      disableClearable
      options={data}
      filterOptions={
        createFilterOptions({
          matchFrom: 'any',
          stringify: (option) => option.name + option.owner_name,
        })}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option?.name ?? '';
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder='Select Group'
          InputProps={{
            ...params.InputProps,
            type: 'search',
            sx,
            startAdornment: (
              <InputAdornment position='start'>
                <Box component={'img'} src={iconPath || GroupIcon} />
              </InputAdornment>
            ),
          }}
        />
      )}
      ListboxProps={{
        role: 'list-box',
        style: {
          maxHeight: 300,
        },
      }}
    />
  );
};

export default AffiliateGroupFilter;
