import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { CompanyFeed } from './interface';

export const getCompanyFeed = createAsyncThunk(
    'companyFeed/getCompanyFeed',
    async (companyId: number, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get<CompanyFeed>(`/api/company-feeds?company_id=${companyId}`);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);
