import React, { useCallback, useEffect, useRef } from 'react';

import { Avatar, Box, Button, Chip, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { MediaLibraryCategoryItem, MediaLibraryItem } from '../../store/mediaLibrary/api/interface';
import { useAppDispatch, useAppSelector } from '../../store';
import { getMediaLibrary, getMediaLibraryCategory } from '../../store/mediaLibrary/api';
import { resetMediaLibrary, setMediaLibraryTag, setMediaLibraryType } from '../../store/mediaLibrary/mediaLibrarySlice';
import { AppColors } from '../../utils/utils';

interface IProps {
    onSelectMedia: (mediaLibraryItem: MediaLibraryItem) => void;
}

const BackgroundMediaLibraryView: React.FC<IProps> = ({ onSelectMedia }) => {

    const dispatch = useAppDispatch();
    const { mediaLibraryCategoryList, mediaLibraryMediaModelList, isCategoryLoading, isMediaLoading, isLoadMoreCategory, isLoadMoreMedia, mediaPage, categoryPage, mediaTag, mediaLibraryType } = useAppSelector((state) => state?.mediaLibrary);

    const mediaLibraryCategoryDivRef = useRef<HTMLDivElement>(null);

    const observer = useRef<IntersectionObserver>();

    useEffect(() => {
        if (mediaLibraryCategoryList.length == 0) {
            dispatch(getMediaLibraryCategory({ page: categoryPage + 1 }));
        } else if (mediaLibraryType == undefined) {
            dispatch(setMediaLibraryType("image"));
        }

    }, []);


    useEffect(() => {
        if (mediaTag.length == 0 && mediaLibraryCategoryList.length > 0) {
            handleOnTagSelect(mediaLibraryCategoryList[0]);
        }
    }, [mediaLibraryCategoryList]);


    useEffect(() => {

        if (mediaTag.length > 0 || (mediaTag.length > 0 && mediaLibraryType != undefined)) {
            dispatch(getMediaLibrary({ page: 1, tag: mediaTag, type: "image" }));
        }
    }, [mediaTag, mediaLibraryType]);

    const handleOnTagSelect = (mediaLibraryCategoryItem: MediaLibraryCategoryItem) => {
        dispatch(setMediaLibraryTag(mediaLibraryCategoryItem.name ?? ""))
    };


    const handleMediaLibraryCategoryScroll = () => {
        const { current } = mediaLibraryCategoryDivRef;
        if (current) {
            const { scrollLeft, clientWidth, scrollWidth } = current;
            if (scrollLeft + clientWidth >= (scrollWidth - 5) && !isCategoryLoading && isLoadMoreCategory) {
                dispatch(getMediaLibraryCategory({ page: categoryPage + 1 }));
            }
        }
    };

    const mediaLibraryMediaLastElementRef = useCallback(
        // (*)
        (node: HTMLDivElement | null) => {
            if (isMediaLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && isLoadMoreMedia) {
                    dispatch(getMediaLibrary({ page: mediaPage + 1, tag: mediaTag }));
                }
            });
            if (node) observer.current.observe(node);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isMediaLoading, isLoadMoreMedia]
    );


    return (
        <Box component={'div'} sx={{ height: '100%' }}>
            <Box sx={{ background: AppColors.backgroundColor, minHeight: "200px", }}>
                <Stack
                    ref={mediaLibraryCategoryDivRef}
                    flexDirection={'row'} sx={{
                        overflowX: 'scroll', '&::-webkit-scrollbar': {
                            width: '0.2em', // Width of the scrollbar
                            height: '0.2em'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0,0,0,0)', // Hide the thumb when not hovering
                        },
                        '&:hover::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0,0,0,0.2)', // Show the thumb when hovering
                        },
                    }}
                    onScroll={handleMediaLibraryCategoryScroll}
                >

                    {mediaLibraryCategoryList.map((item, idx) => (<Chip label={`${item.name ?? ""}`} key={`media-library-tag-${idx}`} variant="outlined" sx={{ m: 1, cursor: 'pointer', color: AppColors.primaryColor, borderColor: AppColors.primaryColor, borderRadius: "6px", background: (item.name == mediaTag) ? AppColors.grayZeroColor : "#ffffff" }} onClick={() => handleOnTagSelect(item)} />))}
                    {isCategoryLoading && <Box sx={{ pl: 2, pr: 2, pt: 1 }}><CircularProgress size={30} /></Box>}
                </Stack>


                <Grid container sx={{ p: 0.375 }}>
                    {mediaLibraryMediaModelList?.map((mediaItem, idx) => {
                        return (
                            <Grid
                                ref={(idx + 1 === mediaLibraryMediaModelList?.length) ? mediaLibraryMediaLastElementRef : undefined}
                                key={`post-${idx}`}
                                item
                                onClick={() => onSelectMedia(mediaItem)}
                                xs={4}
                                md={4}
                                height='100px'
                                className={"padding-3"}
                            >
                                <Box sx={{
                                    backgroundImage: `url(${mediaItem.type == "image" ? mediaItem.link ?? "" : mediaItem.thumbnail ?? ""})`,
                                    height: "100%",
                                    width: "100%",
                                    backgroundSize: "100% auto",
                                    backgroundPosition: "center",
                                    backgroundAttachment: "local"
                                }}></Box>

                            </Grid>
                        );

                    })}
                </Grid>

                {(mediaLibraryMediaModelList.length == 0 && !isMediaLoading) && (
                    <Box display="flex" justifyContent="center" mt={6} pb={2}>
                        <Typography>Medias not found.</Typography>
                    </Box>
                )}
                {isMediaLoading && (
                    <Box display="flex" justifyContent="center" mt={2} pb={2}>
                        <CircularProgress />
                    </Box>
                )}


            </Box>


        </Box >
    );
};

export default BackgroundMediaLibraryView;
