import {
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/system';
import '@splidejs/react-splide/css';
import CancelIcon from '@mui/icons-material/Cancel';

import { AppColors, config } from '../../utils/utils';
import { useEffect, useState } from 'react';
import { setItem } from '../../utils/localStorage';
import { useAppSelector } from '../../store';

type IProps = {
  toggle: boolean;
  onClose: () => void;
};



const opts = {
  height: '100%',
  width: '100%',
};

const DownloadAppMessageModel: React.FC<IProps> = ({ toggle, onClose }) => {

  const [isStepTwo, setStepTwo] = useState(false);
  const { user } = useAppSelector((state) => state?.auth);
  useEffect(() => {
    if (!toggle) {
      setStepTwo(false);
    }
  }, [toggle])

  const handleCloseTapped = () => {
    setStepTwo(true);
  }

  const handleDontShowAgain = () => {
    setItem(config.DOWNLOAD_APP_DONT_SHOW_AGAIN, user?.username);
    onClose()
  }

  return (
    <>
      <Dialog
        open={toggle}
        onClose={() => {
          onClose();
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='lg'
        // fullScreen
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        }}
      >
        {isStepTwo ? (<>
          <DialogContent sx={{}}>
            <>
              <Typography
                sx={{ fontWeight: '500', color: AppColors.grayThreeColor, pt: 1, pb: 1.5 }}
                align='center'
              >
                Are You sure? You will not have access to essential features until you download the app...
              </Typography>

              <Box sx={{ background: AppColors.primaryColor, borderRadius: "12px", ml: 2, mr: 2 }}>
                <Typography

                  gutterBottom
                  sx={{ fontWeight: '500', color: AppColors.whiteColor, pt: 1, pb: 1, mb: 1 }}
                  align='center'
                >
                  Download the App
                </Typography>
              </Box>

              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                mt={1.5}
              >
                <a
                  href={config.APP_STORE_URL}
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <img
                    src='/static/images/app_store_image.png'
                    height={window?.innerWidth > 500 ? '55%' : '80%'}
                    width={window?.innerWidth > 500 ? '55%' : '80%'}
                    alt='apple-link'
                  />
                </a>

                <a
                  href={config.PLAY_STORE_URL}
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginLeft: '10px',
                  }}
                >
                  <img
                    src='/static/images/google_playstore_image.png'
                    height={window?.innerWidth > 500 ? '55%' : '80%'}
                    width={window?.innerWidth > 500 ? '55%' : '80%'}
                    alt='google-link'
                  />
                </a>
              </Box>
              <Box display={'flex'} justifyContent={'center'}>
                <Button variant="contained" onClick={handleDontShowAgain} endIcon={<CancelIcon sx={{ color: AppColors.redColor }} />} sx={{ color: AppColors.whiteColor, textTransform: 'unset', mt: 2, borderRadius: "12px" }}>
                  Don't show again
                </Button>
              </Box>
            </>
          </DialogContent>
        </>) : (<><DialogTitle
          sx={{
            textAlign: 'center',
            color: AppColors.grayThreeColor,
            pb: 0
          }}
        >
          1 more step...
        </DialogTitle>
          <IconButton
            aria-label='close'
            onClick={handleCloseTapped}
            sx={{
              position: 'absolute',
              right: 0,
              zIndex: 100000,
              color: AppColors.redColor,
              alignSelf: 'center',
              alignItems: 'center',
            }}
          >
            <CancelIcon />
          </IconButton>
          <DialogContent sx={{}}>
            <>
              <Box sx={{ background: AppColors.primaryColor, borderRadius: "12px", ml: 2, mr: 2 }}>
                <Typography

                  gutterBottom
                  sx={{ fontWeight: '500', color: AppColors.whiteColor, pt: 1, pb: 1, mb: 1 }}
                  align='center'
                >
                  Download the App
                </Typography>
              </Box>

              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                mt={1.5}
              >
                <a
                  href={config.APP_STORE_URL}
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <img
                    src='/static/images/app_store_image.png'
                    height={window?.innerWidth > 500 ? '55%' : '80%'}
                    width={window?.innerWidth > 500 ? '55%' : '80%'}
                    alt='apple-link'
                  />
                </a>

                <a
                  href={config.PLAY_STORE_URL}
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginLeft: '10px',
                  }}
                >
                  <img
                    src='/static/images/google_playstore_image.png'
                    height={window?.innerWidth > 500 ? '55%' : '80%'}
                    width={window?.innerWidth > 500 ? '55%' : '80%'}
                    alt='google-link'
                  />
                </a>
              </Box>
              <Typography
                sx={{ fontWeight: '500', color: AppColors.grayThreeColor, pt: 1 }}
                align='center'
              >
                Take advantage of our in-app features such as Sharing and Messaging
              </Typography>
            </>
          </DialogContent></>)}
      </Dialog>
    </>
  );
};

export default DownloadAppMessageModel;
