
import { Stack, Typography, Box, CircularProgress, Grid, useMediaQuery, Card, CardContent, TextField, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Button } from '@mui/material';

import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import style from '../styles/PreferredPartner.module.css';
import { useDispatch } from 'react-redux';
import { Fragment, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { CreateUpdatePreferredPartnerContents, PreferredPartnerContents } from '../store/preferredPartner/api/interface';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import useWindowSize from '../utils/useWindowSize';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import preferredPartnerContentSchema from '../validation/preferredPartnerContentSchema';
import { LoadingButton } from '@mui/lab';
import { CameraAltOutlined } from '@mui/icons-material';
import { acceptImageFileTypes, acceptVideoFileTypes } from '../constant';
import { INotFoundParams, IPreferredPartnerParams, MediaType } from '../enum';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import ReorderIcon from '@mui/icons-material/Reorder';
import Iconify from '../components/Iconify';
import SelectContactModal from '../components/modal/SelectContactModal';
import useToggle from '../hooks/useToggle';
import { UserInfo } from '../store/userInfo/api/interface';
import { createUpdatePreferredPartner, getPreferredPartner } from '../store/preferredPartner/api';

import { reset as resetPreferredPartner } from '../store/preferredPartner/preferredPartnerSlice';
import { decryptString } from '../utils/url';
import Loader from '../components/Loader';
import { checkIsKnoCardSocialConnectUserPlan, checkIsProProOrProOneOrProPlusUser, checkIsProUser } from '../utils/checkPro';
import { getCapitalizedString, inputTextFieldStyle, showErrorMessage, showSuccessMessage } from '../utils/utilMethods';
import SearchIcon from '../assets/preferred_partners/search.svg';
import { AppColors } from '../utils/utils';

interface IInitialState {
  items: CreateUpdatePreferredPartnerContents[];
  title: string;
  services: string;
  description: string;
}

const AddEditPreferredPartner = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const params = useParams<IPreferredPartnerParams>();
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);
  const isProOrProOneOrProPlusUser = checkIsProProOrProOneOrProPlusUser(user);
  const isKnoCardSocialConnectUser = checkIsKnoCardSocialConnectUserPlan(user);
  const navigate = useNavigate();


  const matches = useMediaQuery('(max-width:745px)');
  const windowSize = useWindowSize();
  const [cardWidth, setCardWidth] = useState(300);
  const fileUploader = useRef<any>(null);
  const [reloadMedia, setReloadMedia] = useState<boolean>(false);
  const [deleteContents, setdeleteContents] = useState<any>([]);
  const [partnerUserInfo, setPartnerUserInfo] = useState<UserInfo | undefined>(undefined);
  const [editPreferredPartnerInfo, setEditPreferredPartnerInfo] = useState<any>();
  useEffect(() => {
    const preferredPartnerId = decryptString(params?.ppid ?? "");

    try {
      if (!isProOrProOneOrProPlusUser && !isKnoCardSocialConnectUser) {
        navigate(`/dashboard/preferred-partners`);
        return;
      }


      if (preferredPartnerId != undefined && preferredPartnerId.length > 0) {
        setIsDataLoading(true);
        dispatch(getPreferredPartner({ preferredPartnerId: preferredPartnerId })).unwrap().then((result) => {
          if (result?.data?.status?.code == "success") {
            setIsDataLoading(false);
            setEditPreferredPartnerInfo(result.data.data);
            formik.values.title = result.data.data.title;
            formik.values.services = result.data.data.services;
            formik.values.description = result.data.data.description;
            result.data.data.contents?.forEach((item: any, index: number) => {
              formik.values.items.push({
                type: item.type,
                content: item
              });
            });
            setPartnerUserInfo(result?.data?.data?.partner);
          } else {
            navigate(`/dashboard/preferred-partners`);
          }
        });
      }

    } catch (error) {

    }
  }, []);


  useEffect(() => {
    const newWidth = matches ? windowSize.width > 350 ? 300 : windowSize.width * 0.80 : windowSize.width > 890 ? 745 : windowSize.width * 0.80;
    setCardWidth(newWidth);
  }, [windowSize]);

  const {
    toggle,
    onOpen,
    onClose,
  } = useToggle();

  const formik = useFormik<IInitialState>({
    initialValues: {
      items: [],
      title: '',
      services: '',
      description: ''
    },
    onSubmit: async (values) => {
      try {
        if (partnerUserInfo != undefined) {
          var result = await dispatch(createUpdatePreferredPartner({
            id: editPreferredPartnerInfo?.id,
            partner_id: partnerUserInfo?.id,
            title: values.title,
            services: values.services,
            description: values.description,
            deleteContents: deleteContents,
            contents: values.items
          })).unwrap();
          if (result?.data?.data != null) {

            dispatch(resetPreferredPartner());
            showSuccessMessage(`Preferred partner ${editPreferredPartnerInfo?.id != null ? 'saved' : 'created'} successfully.`);
            onCancel();
          }

        } else {
          showErrorMessage("Please select preferred partner.");
        }

      } catch (error: any) { }
    },
    validationSchema: preferredPartnerContentSchema,
  });

  const onCancel = () => {
    navigate('/dashboard/preferred-partners')
  }


  function selectMedia(isChangeThumNail?: boolean) {
    fileUploader?.current?.click();
  }

  const onFileChange = async (event: any) => {

    Array.from(event.target.files).forEach((file: any, index: number) => {
      const fileType = acceptVideoFileTypes.includes(file.type) ? MediaType.video : MediaType.image;
      addMediaObject({
        file: file,
        type: fileType
      }, event.target.files.length == index + 1);
    })

  };


  const addMediaObject = async (createUpdatePreferredPartnerContents: CreateUpdatePreferredPartnerContents, rerender: boolean) => {
    if (formik.values.items.length < 4) {
      formik.values.items.push(createUpdatePreferredPartnerContents);
    }
    if (rerender) {
      setReloadMedia(!reloadMedia);
    }
  }


  const onDragEnd = (result: DropResult) => {

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      formik.values.items,
      result.source.index,
      result.destination.index
    );
    formik.values.items = items;
  }

  const removeMediaObject = (createUpdatePreferredPartnerContents: CreateUpdatePreferredPartnerContents) => {

    if (createUpdatePreferredPartnerContents.content != null) {
      setdeleteContents([...deleteContents, createUpdatePreferredPartnerContents.content.id])
    }

    let array = [...formik.values.items];
    let index = array.indexOf(createUpdatePreferredPartnerContents);
    if (index !== -1) {
      array.splice(index, 1);
      formik.values.items = array;
    }
    setReloadMedia(!reloadMedia);
  }


  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };


  const onPreferredPartnerSelect = (user: UserInfo) => {
    setPartnerUserInfo(user);
  };

  return (
    <>
      <Page title='Preferred Partners | KnoCard' showAppBar={true}>
        <form onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <Stack>
              {isDataLoading ? <Loader /> : <Grid item marginTop={6}>
                <Stack direction={'row'} className={`${style['d-flex']} ${style.add}`} justifyContent={'center'}
                  alignContent={'center'}
                  alignItems={'center'}
                  onClick={() => onOpen()}
                >

                  <Box component={"img"} src={partnerUserInfo?.profile_picture ?? '/static/images/placeholder_profile.png'} sx={{ width: "40px !important", height: "40px !important", borderRadius: "40px", marginRight: 1 }} />


                  <Typography sx={{
                    color: AppColors.grayThreeColor, fontWeight: "300", width: `85%`, background: AppColors.whiteColor,
                    border: `2px solid ${AppColors.strokeColor}`,
                    p: 1,
                    borderRadius: "10px",
                  }}>{partnerUserInfo?.name ?? "Search and Add Preferred Partner"}</Typography>

                  <Box component={'img'} src={SearchIcon} className="plus-icon h5 text-primary" sx={{ marginLeft: 1 }} />
                </Stack>

                <Stack direction={'row'} className={`${style['d-flex']} ${style.add}`} justifyContent={'center'}
                  alignContent={'center'}
                  alignItems={'center'}
                  marginTop={5}
                >
                  <Card
                    key={'card-add-preferred-partner'}
                    sx={{
                      width: '90%',
                      mb: 10,
                      p: 4
                    }}
                    elevation={0}
                  >
                    <CardContent
                      sx={{
                        paddingTop: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        paddingBottom: '0px !important',
                        '&:last-child': { pb: '16px' },
                      }}
                    >
                      <Grid
                        container
                        display={'flex'}
                        direction='row'
                        alignItems='center'
                        justifyContent={'space-between'}
                      >
                        <Grid item xs={12} sm={12}>
                          <Typography color={AppColors.grayThreeColor} marginBottom={0.5}>Title</Typography>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              id="title"
                              name={`title`}
                              placeholder={'Write the person\'s title Ex: Builder'}
                              value={
                                formik.values.title ?? ""
                              }
                              InputProps={{
                                sx: inputTextFieldStyle()
                              }}
                              onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                              onChange={formik.handleChange}
                              fullWidth
                              variant="outlined"
                              error={Boolean(formik.errors.title && formik.touched.title)}
                              helperText={
                                Boolean(formik.errors.title && formik.touched.title) &&
                                formik.errors.title
                              }
                            />
                          </Grid>
                          <Typography color={AppColors.grayThreeColor} marginTop={2} marginBottom={0.5}>Services</Typography>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              id="services"
                              name={`services`}
                              placeholder={'Write services Ex: Remodels, kitchens, bathrooms'}
                              value={
                                formik.values.services ?? ""
                              }

                              InputProps={{
                                sx: inputTextFieldStyle()
                              }}
                              onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                              onChange={formik.handleChange}
                              fullWidth
                              variant="outlined"
                              error={Boolean(formik.errors.services && formik.touched.services)}
                              helperText={
                                Boolean(formik.errors.services && formik.touched.services) &&
                                formik.errors.services
                              }
                            />
                          </Grid>
                          <Typography color={AppColors.grayThreeColor} marginTop={2} marginBottom={0.5}>Reason to Recommend</Typography>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              id="description"
                              name={`description`}
                              placeholder='Mention the reason(s). Ex: High quality work, timeliness, competitive pricing'
                              value={
                                formik.values.description ?? ""
                              }
                              InputProps={{
                                sx: inputTextFieldStyle()
                              }}
                              onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                              onChange={formik.handleChange}
                              fullWidth
                              multiline
                              maxRows={6}
                              variant="outlined"
                              error={Boolean(formik.errors.description && formik.touched.description)}
                              helperText={
                                Boolean(formik.errors.description && formik.touched.description) &&
                                formik.errors.description
                              }
                            />
                          </Grid>

                          {formik.values.items.length < 4 && <Grid item xs={12} sm={12}>

                            <input type="file" id="file" ref={fileUploader} onClick={(e: any) => e.currentTarget.value = ""} onChange={onFileChange} style={{ display: "none" }} accept={[...acceptImageFileTypes].join(',')} multiple />
                            <List>
                              <ListItem disablePadding>
                                <ListItemButton onClick={() => selectMedia()}>

                                  <ListItemIcon>
                                    <CameraAltOutlined sx={{ color: AppColors.grayThreeColor }} />
                                  </ListItemIcon>
                                  <ListItemText primary="Add Photos" sx={{ color: AppColors.grayThreeColor }} />
                                </ListItemButton>
                              </ListItem>
                              <Divider
                                sx={{
                                  mt: 0,
                                  borderStyle: "dashed",
                                }}
                              />
                              <Divider
                                sx={{
                                  mt: 0,
                                  borderStyle: "dashed",
                                }}
                              />
                            </List>

                          </Grid>}


                          <Stack>
                            <DragDropContext onDragEnd={onDragEnd}>
                              <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                  <div {...provided.droppableProps}
                                    ref={provided.innerRef}>
                                    {formik.values?.items.map((item, idx) => (
                                      <Draggable key={`item-${idx}`} draggableId={`item-${idx}`} index={idx}>
                                        {(provided, snapshot) => (
                                          <div
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}

                                          >
                                            <Fragment key={idx} >
                                              <Stack alignItems="flex-end" spacing={1.5} sx={{ backgroundColor: AppColors.whiteColor }}>
                                                <Grid container spacing={3} paddingRight={'4px'}>

                                                  <Grid xs={11} sm={11} item
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center">
                                                    <img
                                                      src={(item?.file != undefined) ? URL.createObjectURL(item.file) : item.content?.link ?? ""}
                                                      alt={item?.file?.name}
                                                      height={'64px'}
                                                      width={'100%'}
                                                      style={{ objectFit: "contain", paddingTop: '8px', paddingBottom: '8px' }}
                                                    />
                                                  </Grid>
                                                  <Stack
                                                    justifyContent={'center'}
                                                    alignContent={'center'}
                                                    alignItems={'center'} >
                                                    <ReorderIcon sx={{ color: AppColors.grayThreeColor }} />
                                                  </Stack>
                                                </Grid>
                                                <Box justifyContent="flex-end">
                                                  <Button
                                                    size="small"
                                                    color="error"
                                                    sx={{ marginTop: '0px !important', marginBottom: '12px !important' }}
                                                    startIcon={
                                                      <Iconify icon="eva:trash-2-outline" />
                                                    }
                                                    onClick={() => removeMediaObject(item)}
                                                  >
                                                    remove
                                                  </Button>

                                                </Box>



                                              </Stack>
                                              <Divider
                                                sx={{
                                                  mt: 0,
                                                  borderStyle: "dashed",
                                                }}
                                              />
                                            </Fragment>
                                          </div>

                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>

                          </Stack>

                          <Stack
                            width={'100%'}
                            direction={'row'}
                            justifyContent={'end'}
                            marginTop={3}
                          >
                            <LoadingButton
                              type="button"
                              loading={false}
                              variant="text"
                              sx={{ marginRight: 2 }}
                              onClick={onCancel}
                            >
                              Cancel
                            </LoadingButton>

                            <LoadingButton
                              type="submit"
                              loading={formik.isSubmitting}
                              variant="contained"
                            >Save</LoadingButton>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Stack>
              </Grid>}


            </Stack>
          </FormikProvider>
        </form >
      </Page >
      <SelectContactModal onSelect={onPreferredPartnerSelect} onClose={onClose} toggle={toggle} />

    </>
  );
};
export default AddEditPreferredPartner;
