import {
  Stack,
  Typography,
  Box,
  CircularProgress,
  Grid,
  useMediaQuery,
  Rating,
} from '@mui/material';

import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import _ from 'lodash';

import { isNotEmpty } from '../utils/valid';
import InfiniteScroll from 'react-infinite-scroller';

import useWindowSize from '../utils/useWindowSize';
import { useNavigate } from 'react-router-dom';



import { checkIsProUser } from '../utils/checkPro';
import BlockOverlay from '../components/BlockOverlay';
import moment from 'moment';
import { getUserReviews, updateReviewStatus } from '../store/reviewForm/api';
import IOSSwitch from '../components/IOSSwitch';
import { reviewStatusUpdated } from '../store/reviewForm/reviewFormSlice';
import RenderProfilePhoto from '../components/RenderProfilePhoto';
import { UserInfo } from '../store/userInfo/api/interface';
import { showErrorMessage, showSuccessMessage } from '../utils/utilMethods';
import { AppColors } from '../utils/utils';
import ReviewItemView from '../components/review/ReviewItemView';

const Reviews = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);
  const isProUser = checkIsProUser(user);
  const navigate = useNavigate();

  const {
    data: userReviewList,
    isLoading,
    hasMore: isLoadMore,
    page,
  } = useAppSelector((state) => state?.reviewForm);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (user?.id && isProUser) {
      dispatch(getUserReviews({ pageNo: page }));
    }
  }, [dispatch]);

  const handleLoadMore = (_page: number) => {
    if (user?.id && !isLoading && isProUser) {
      dispatch(getUserReviews({ pageNo: page }));
    }
  };

  const matches = useMediaQuery('(max-width:745px)');
  const windowSize = useWindowSize();
  const [cardWidth, setCardWidth] = useState(300);

  useEffect(() => {
    const newWidth = matches
      ? windowSize.width > 350
        ? 300
        : windowSize.width * 0.8
      : windowSize.width > 890
        ? 745
        : windowSize.width * 0.8;

    setCardWidth(newWidth);
  }, [windowSize]);

  const onUpdateReviewStatus = async (_reviewId: number, _status: number) => {
    try {
      const response = await dispatch(
        updateReviewStatus({ reviewId: _reviewId, status: _status })
      ).unwrap();
      if (response && response.data && response.data.data) {
        dispatch(reviewStatusUpdated(response.data.data));
        showSuccessMessage('Review visibility changed successfully.');
      } else {
        showErrorMessage('Something went wrong please try again.');
      }
    } catch (error: any) { }
  };

  return (
    <>
      <Page
        title='Reviews | KnoCard'
        showAppBar={true}
        sx={{ minHeight: '100vh' }}
      >
        <Box sx={{ mt: 5 }} >
          <Typography
            variant='h6'

            sx={{ textAlign: 'left', pt: 0, ml: 2, mr: 2, color: AppColors.blackColor }}
          >
            Reviews
          </Typography>
        </Box>
        <Stack>
          <div style={{ height: '100vh' }}>
            {isLoading && page === 1 ? (
              <Box
                display='flex'
                justifyContent='center'
                pt={2}
              // height={'85vh'}
              >
                <Stack>
                  <CircularProgress />
                </Stack>
              </Box>
            ) : userReviewList && isNotEmpty(userReviewList) ? (
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                gap={3}
                paddingBottom={4}
              >
                <InfiniteScroll
                  pageStart={1}
                  initialLoad={false}
                  loadMore={handleLoadMore}
                  hasMore={isLoadMore}
                  loader={
                    <Box display='flex' justifyContent='center' mt={2}>
                      <CircularProgress />
                    </Box>
                  }
                >
                  <Grid container sx={{ mt: 2, p: 0, width: '100%' }}>
                    {userReviewList?.map((userReview, index) => (
                      <Grid
                        item
                        xs={12}
                        md={12}
                        key={`user-review-${index}`}
                        sx={{ p: 1 }}
                      >
                        <Stack
                          direction='column'
                          sx={{
                            position: 'relative',
                            borderRadius: '10px !important',
                            background: AppColors.whiteColor,
                            p: 1,
                          }}
                        >
                          <Stack flexDirection={'row'} sx={{ mt: 1.2, mb: 0.5 }}>
                            {userReview.rater_type === 'App\\Models\\Contact' ? (
                              <Box
                                component={'img'}
                                src={userReview.rater?.user?.profile_picture ? userReview.rater?.user?.profile_picture : '/static/images/placeholder_profile.png'}
                                sx={{
                                  height: '50px',
                                  width: '50px',
                                  borderRadius: '100px',
                                  mr: 2,
                                }}
                              ></Box>
                            ) : (
                              <RenderProfilePhoto
                                profile_picture={
                                  (userReview.rater as UserInfo | undefined)
                                    ?.profile_picture ?? ''
                                }
                                show_profile_picture={
                                  (userReview.rater as UserInfo | undefined)
                                    ?.show_profile_picture ?? 1
                                }
                                sx={{
                                  height: '50px',
                                  width: '50px',
                                  borderRadius: '100px',
                                  mr: 2
                                }}
                              />
                            )}
                            <Stack flexDirection={'row'} sx={{ width: "100%" }}>
                              <Box sx={{ flexGrow: 1, mr: 2 }}>
                                <Typography
                                  variant='subtitle1'
                                  sx={{
                                    width: '100%',
                                    color: AppColors.blackColor,
                                    overflowWrap: 'anywhere',
                                  }}
                                >
                                  {userReview?.first_name ?? ''} {userReview?.last_name ?? ''}
                                </Typography>
                                <Rating
                                  size='small'
                                  name='read-only-rating'
                                  value={Number(userReview.rating ?? '0')}
                                  precision={0.5}
                                  readOnly
                                  sx={{
                                    '& .MuiRating-iconFilled': {
                                      color: AppColors.yellowColor,
                                    },
                                  }}
                                />
                              </Box>
                              <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                                <IOSSwitch
                                  checked={userReview.status === 1}
                                  onChange={() => {
                                    onUpdateReviewStatus(
                                      userReview.id ?? -1,
                                      userReview.status == 1 ? 0 : 1
                                    );
                                  }}
                                  color={'primary'}
                                />
                                <Typography
                                  variant='subtitle2'
                                  sx={{
                                    fontSize: 'small',
                                    fontWeight: '300', mb: 1, color: AppColors.grayTwoColor,
                                    mt: 1
                                  }}
                                >
                                  {moment(userReview?.created_at).format('MMM DD, YYYY')}
                                </Typography>
                              </Box>
                            </Stack>
                          </Stack>
                          <Box display='flex' sx={{}}>
                            <Box
                              display='flex'
                              flexDirection='column'
                              alignItems='flex-start'
                              justifyContent='center'
                              flex={1}
                              sx={{

                              }}
                            >
                              <Typography
                                variant='subtitle2'
                                component='div'
                                color='inherit'
                                sx={{
                                  width: '100%',
                                  wordWrap: 'break-word',
                                  overflowWrap: 'anywhere',
                                  p: 1,
                                  fontWeight: 'unset',
                                  fontSize: '0.8rem',
                                  color: AppColors.grayTwoColor
                                }}
                              >
                                {userReview?.message}
                              </Typography>
                            </Box>
                          </Box>
                        </Stack>
                        {/* <Stack
                          direction='column'
                          sx={{
                            border: 'solid 2px #FDD330',
                            position: 'relative',
                            borderRadius: '25px !important',
                            backgroundImage:
                              'linear-gradient(0deg, #4CD4DC 0%,  #0B3A9D 100%)',
                            p: 1,
                          }}
                        >
                          <Stack
                            flexDirection={'row'}
                            sx={{ mt: 1.2, mb: 0.5 }}
                          >
                            {item.rater_type === 'App\\Models\\Contact' ? (
                              <Box
                                component={'img'}
                                src='/static/images/placeholder_profile.png'
                                sx={{
                                  height: '50px',
                                  width: '50px',
                                  borderRadius: '100px',
                                  mr: 2,
                                  border: 'solid 3px #FDD330',
                                }}
                              ></Box>
                            ) : (
                              <RenderProfilePhoto
                                profile_picture={
                                  (item.rater as UserInfo | undefined)
                                    ?.profile_picture ?? ''
                                }
                                show_profile_picture={
                                  (item.rater as UserInfo | undefined)
                                    ?.show_profile_picture ?? 1
                                }
                                sx={{
                                  height: '50px',
                                  width: '50px',
                                  borderRadius: '100px',
                                  mr: 2,
                                  border: 'solid 3px #FDD330',
                                }}
                              />
                            )}
                            <Box>
                              <Typography
                                variant='body1'
                                sx={{
                                  width: '100%',
                                  color: '#3993F0',
                                  overflowWrap: 'anywhere',
                                }}
                              >
                                {item?.first_name ?? ''} {item?.last_name ?? ''}
                              </Typography>
                              <Typography
                                variant='body2'
                                sx={{ width: '100%', mb: 1, color: '#3993F0' }}
                              >
                                {moment(item?.created_at).format(
                                  'MMM DD, YYYY'
                                )}
                              </Typography>
                            </Box>
                            <Stack
                              direction='column'
                              position={'absolute'}
                              right={0}
                              // top={-1}
                              justifyContent={'end'}
                            >
                              <IOSSwitch
                                checked={item.status === 1}
                                onChange={() => {
                                  onUpdateReviewStatus(
                                    item.id ?? -1,
                                    item.status == 1 ? 0 : 1
                                  );
                                }}
                                color={'primary'}
                              />
                              <Rating
                                size='small'
                                name='read-only-rating'
                                value={Number(item.rating ?? '0')}
                                precision={0.5}
                                readOnly
                                sx={{
                                  '& .MuiRating-iconFilled': {
                                    color: '#FDD330',
                                  },
                                  position: 'relative',
                                  right: 15,
                                  top: 5,
                                }}
                              />
                            </Stack>
                          </Stack>

                          <Box display='flex' minHeight='60px' sx={{}}>
                            <Box
                              display='flex'
                              flexDirection='column'
                              alignItems='flex-start'
                              justifyContent='center'
                              flex={1}
                              sx={{
                                p: 2,
                                border: 'solid 2px #38A0F9',
                                borderRadius: '40px !important',
                              }}
                            >
                              <Typography
                                variant='body1'
                                component='div'
                                color='inherit'
                                sx={{
                                  width: '100%',
                                  wordWrap: 'break-word',
                                  background: '#ffffff',
                                  borderRadius: '40px',
                                  overflowWrap: 'anywhere',
                                  p: 2,
                                }}
                              >
                                {item?.message}
                              </Typography>
                            </Box>
                          </Box>
                        </Stack> */}
                      </Grid>

                    ))}
                  </Grid>
                </InfiniteScroll>
              </Box>
            ) : (
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
              // height={'85vh'}
              >
                <Typography mt={'42vh'} sx={{ color: '#121f25' }}>
                  No review found.
                </Typography>
              </Box>
            )}
          </div>
          <BlockOverlay isBlock={!isProUser} />
        </Stack>
      </Page>
    </>
  );
};
export default Reviews;
