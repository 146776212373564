import { axiosInstance } from '../../../client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Values } from './interface';
import { toastHandler } from '../../../utils/toastHandler';

export const registerUser = createAsyncThunk(
  'register/registerUser',
  async (values: Values, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/api/user/register', values);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const checkUsername = createAsyncThunk(
  'register/checkUsername',
  async (values: { username: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        '/api/user/username-checker',
        values
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const checkEmailAddress = createAsyncThunk(
  'register/checkEmailAddress',
  async (values: { email: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        '/api/user/email-checker',
        values
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);
