import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { AnalyticsResponse, IShareReferState } from './interface';

export const postShareRefer = createAsyncThunk(
    'shareRefer/postShareRefer',
    async (reqBody: IShareReferState, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(`/api/share-and-refer`, reqBody);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const analytics = createAsyncThunk('shareRefer/analytics', async (value: any, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post<AnalyticsResponse>(`/api${value.route}`, value.data);
        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});
