import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Radio, styled } from '@mui/material';
import styles from "../../styles/EngageItem.module.css";

import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import moment from 'moment';
import LinkIcon from '../../assets/reports/link.svg';
import { getActivityIconAndTitle } from '../../constant/activity_icon_and_title';
import { ExternalLinkReportItem } from '../../store/engage/api/interface';
import { AppColors } from '../../utils/utils';

const RadioButton = styled(Radio)({
    color: "#fff",
    "&.Mui-checked": {
        color: "#fff",
    },
});




interface IProps {
    externalLinkReportItem: ExternalLinkReportItem;
    index: number;
}

const ExternalLinkReportItemView: React.FC<IProps> = ({
    externalLinkReportItem,
    index
}) => {

    return (
        <div className='w-100'>

            <Card
                key={index}
                sx={{
                    width: '100%',
                    background: AppColors.grayZeroColor,
                    mb: 3,
                    pr: 1,
                    padding: '18px'
                }}
            >
                <CardContent
                    sx={{
                        padding: '0px 0px 0px 0px!important'
                    }}
                >
                    <Stack direction={'row'}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Box component={"img"} src={LinkIcon} sx={{ height: '30px', width: '30px', mr: 1 }} />
                        </Box>


                        <Box flexDirection={'column'} sx={{
                            width: "100%",
                            ml: 1,
                            mr: 1
                        }}>
                            <Typography sx={{ color: AppColors.blackColor }}>{externalLinkReportItem?.externalpage?.title ?? "External Link"} &nbsp;&nbsp; {externalLinkReportItem.total ?? 0} Views</Typography>

                            <Typography sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "0.8rem",
                                pr: '5px',
                                color: AppColors.grayTwoColor
                            }}>Last View {moment(`${externalLinkReportItem.created_at}`).local().format("DD MMM, YYYY hh:mm A")} </Typography>
                        </Box>


                    </Stack>

                </CardContent>
            </Card>
        </div>
    );
};

export default ExternalLinkReportItemView;
