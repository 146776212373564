import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { CreateUpdatePreferredPartner, PreferredPartner } from './interface';
import { toastHandler } from '../../../utils/toastHandler';
import { MediaType } from '../../../enum';
import { getItem } from '../../../utils/localStorage';
import { config } from '../../../utils/utils';

export const getPreferredPartners = createAsyncThunk(
  'PreferredPartner/getPreferredPartners',
  async (
    { userId, pageNo }: { userId: number; pageNo: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<PreferredPartner>(
        `/api/preferred-partner?user_id=${userId}&page=${pageNo}`
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const createUpdatePreferredPartner = createAsyncThunk(
  "post/createUpdatePreferredPartner",
  async (data: CreateUpdatePreferredPartner,
    { rejectWithValue }
  ) => {
    try {
      const request = new FormData();

      request.append('partner_id', `${data.partner_id ?? ''}`);
      request.append('title', data?.title ?? '');
      request.append('description', data.description ?? '');
      request.append('services', data.services ?? '');

      data.deleteContents?.forEach((item, index) => {
        request.append(`delete_contents[${item}]`, `${item}`);
      });

      let imageIndex = 0;

      data.contents.forEach((contentFile, index) => {

        if (contentFile.content != null) {
          if (contentFile.type == MediaType.image && contentFile.file != null) {
            request.append(`update_content_file[${contentFile.content?.id}]`, contentFile.file);
          }

          request.append(`media_positions[${index}]`, `${contentFile.content?.id ?? ""}`);
        } else {
          if (contentFile.type == MediaType.image && contentFile.file != null) {
            var imageName = contentFile.file.name;
            request.append(`images[${imageIndex}]`, contentFile.file);
            imageIndex++;
            request.append(`media_positions[${index}]`, imageName);
          }
        }
      });


      const response = await axiosInstance.post(
        `/api/preferred-partner${data.id != undefined ? `/${data.id}` : ""}`, request, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const deletePreferredPartner = createAsyncThunk(
  "delete/deletePreferredPartner",
  async (
    data: any,
    { rejectWithValue }
  ) => {
    try {
      let userToken = getItem(config.AUTH_KEY);

      const body: any = {
        api_token: userToken
      };
      const response = await axiosInstance.delete(
        `/api/preferred-partner/${data.preferredPartnerId}`,
        body
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const getPreferredPartner = createAsyncThunk(
  "get/getPreferredPartner",
  async (
    data: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `/api/preferred-partner/${data.preferredPartnerId}`);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);