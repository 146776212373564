import { Box, Rating, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Stack } from '@mui/system';
import { Transition } from './Transition';
import { useAppDispatch, useAppSelector } from '../store';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';


interface IProps {
  toggle: boolean;
  onClose: () => void;
}

const ReviewSuccessModal: React.FC<IProps> = ({ toggle, onClose }) => {

  const userInfo = useAppSelector((state) => state.userInfo.data);


  return (
    <Dialog
      fullWidth
      open={toggle}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onClose();
      }}
      PaperProps={{
        sx: {
          background: "transparent",
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
          position: "relative",
          boxShadow: "none"
        }
      }}

      aria-describedby='alert-dialog-slide-description'
    >
      <Box sx={{
        position: "absolute", left: "50%", right: 0, top: 16, pl: 2, pr: 2, background: "#ECECEC", border: "solid 2px #FDD330",
        borderRadius: "25px !important",
        width: "fit-content",
        transform: "translate(-50%, -50%)",
        zIndex: 100
      }}><Rating name="read-only-rating" value={5} readOnly sx={{
        '& .MuiRating-iconFilled': {
          color: '#FDD330',
        }
      }} /></Box>
      <DialogContent sx={{
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        mt: 2,
        p: 2,
        border: "solid 2px #FDD330",
        borderRadius: "25px !important",
        backgroundImage: 'linear-gradient(0deg, #4CD4DC 0%,  #0B3A9D 100%)',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
      }}>
        <Typography sx={{ fontWeight: "bold", color: "#ffffff", textAlign: "center", pt: 2, fontSize: "1.5rem" }}>Thank you for your feedback!</Typography>
        <Box sx={{ textAlign: "center", mt: 1, mb: 1 }}>
          <ThumbUpIcon sx={{ color: "#FDD330", height: "50px", width: "50px" }} />
        </Box>
        <Typography sx={{ fontSize: "0.9rem", fontWeight: "bold", }}>Your feedback is crucial to my success. I strive for 5 stars and positive reviews .If I have fallen short in any way, please allow me to make things right. Thanks again and I look forward to serving you in the future.</Typography>

        <Stack direction="row" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box component={"img"} src="/static/images/knocard_transparent.png" sx={{
            marginLeft: "-32px",
            marginBottom: "-27px",
            height: "100px",
            width: "100px",
            filter: "brightness(0) invert(1)",
            opacity: "0.4"
          }}></Box>
          <Box component={"img"} src={`${(userInfo?.profile_picture ?? "").length > 0
            ? userInfo?.profile_picture ?? '/static/images/placeholder_profile.png'
            : '/static/images/placeholder_profile.png'}`} sx={{
              height: "100px",
              width: "100px",
              borderRadius: "100px"
            }}></Box>
        </Stack>

      </DialogContent>
    </Dialog >
  );
};

export default ReviewSuccessModal;
