import { createSlice } from '@reduxjs/toolkit';
import { findAffiliateGroup, findAffiliateGroupPricing } from './api';
import { initialState } from './api/interface';

const affiliateGroupSlice = createSlice({
  name: 'affiliateGroup',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload.status;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(findAffiliateGroup.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(findAffiliateGroup.fulfilled, (state, action) => {
      if (action.payload.data.data != null) {
        state.data = [...action.payload.data.data];
      }
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(findAffiliateGroup.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });

    builder.addCase(findAffiliateGroupPricing.pending, (state, _) => {
      state.isPricingLoading = true;
    });
    builder.addCase(findAffiliateGroupPricing.fulfilled, (state, action) => {
      if (action.payload.data.data != null) {
        state.affiliateGroupPricing = [...action.payload.data.data];
      }
      state.isPricingLoading = false;
    });
    builder.addCase(findAffiliateGroupPricing.rejected, (state, _) => {
      state.isPricingLoading = false;
    });
  },
});

export const { setStatus, reset } = affiliateGroupSlice.actions;
export default affiliateGroupSlice.reducer;
