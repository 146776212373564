import { STATUS_TYPE } from '../../../enum';
import { UserInfo } from '../../userInfo/api/interface';

export interface getUserInfoRequestResponse {
  status: string;
  data: {
    user: UserInfo[];
  };
}

export interface getUserInfoFromReferenceCodeRequestResponse {
  status: string;
  data: {
    user: UserInfo;
  };
}

export interface Values {
  user_id?: number;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  password: string;
  fcm_token?: string;
  go_live_code?: string;
  referral?: string;
  affiliate_group_id?: number;
  leader_name?: string;
  group_state_id?: number;
  phone_number: string;
  phone_country_code: string;
  firebaseUid: string;
  is_preferred_partner: boolean;
  captcha_code: string;
  background_image?: string;
  selected_plan?: string;
  is_social_connect?: boolean;
}

interface InitialState {
  accessToken: string;
  user: UserInfo | undefined;
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  errorMessage: string | undefined;
}

export const initialState: InitialState = {
  accessToken: '',
  user: undefined,
  status: 'idle',
  isError: false,
  isLoading: false,
  errorMessage: undefined,
};
