import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import {
  getUserInfoFromReferenceCodeRequestResponse,
  getUserInfoRequestResponse,
} from './interface';

export const getUserInfo = createAsyncThunk(
  'userInfo/getUserInfo',
  async ({ username }: { username: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<getUserInfoRequestResponse>(
        `/api/user/vbc/${username}`
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getUserInfoFromReferenceCode = createAsyncThunk(
  'userInfo/getUserInfoFromReferenceCode',
  async ({ referenceCode }: { referenceCode: string }, { rejectWithValue }) => {
    try {
      const response =
        await axiosInstance.get<getUserInfoFromReferenceCodeRequestResponse>(
          `/api/user/referece-code/${referenceCode}`
        );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
