import { DialogContent, DialogTitle, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/system';
import '@splidejs/react-splide/css';

import { config } from '../../utils/utils';

type IProps = {
  toggle: boolean;
  onClose: () => void;
};

const opts = {
  height: '100%',
  width: '100%',
};

const RegisterSuccessModel: React.FC<IProps> = ({ toggle, onClose }) => {
  return (
    <>
      <Dialog
        open={toggle}
        onClose={() => {
          //   onClose();
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='lg'
        // fullScreen
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#088ac6',
            color: 'white',
          }}
        >
          KnoCard
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <>
            <Typography
              variant='h6'
              gutterBottom
              color='primary'
              align='center'
              my={2}
            >
              Registration completed successfully
            </Typography>

            <Typography
              variant='body1'
              gutterBottom
              color='black'
              align='center'
              my={2}
            >
              Download KnoCard app in your mobile from here
            </Typography>

            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              mt={1.5}
              mb={window?.innerWidth > 500 ? 2 : 1}
            >
              <a
                href={config.APP_STORE_URL}
                target='_blank'
                rel='noreferrer'
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <img
                  src='/static/images/app_store_image.png'
                  height={window?.innerWidth > 500 ? '55%' : '80%'}
                  width={window?.innerWidth > 500 ? '55%' : '80%'}
                  alt='apple-link'
                />
              </a>

              <a
                href={config.PLAY_STORE_URL}
                target='_blank'
                rel='noreferrer'
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginLeft: '10px',
                }}
              >
                <img
                  src='/static/images/google_playstore_image.png'
                  height={window?.innerWidth > 500 ? '55%' : '80%'}
                  width={window?.innerWidth > 500 ? '55%' : '80%'}
                  alt='google-link'
                />
              </a>
            </Box>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RegisterSuccessModel;
