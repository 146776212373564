import {
  Avatar,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/system';
import '@splidejs/react-splide/css';
import { LoadingButton } from '@mui/lab';
import { UserInfo } from '../../store/userInfo/api/interface';
import { Transition } from '../Transition';
import styles from '../../styles/Reports.module.css';

type IProps = {
  toggle: boolean;
  onClose: () => void;
  userInfo: UserInfo | undefined;
};

const FreePlanSubcriptionModel: React.FC<IProps> = ({
  toggle,
  onClose,
  userInfo,
}) => {
  return (
    <>
      <Dialog
        fullWidth
        open={toggle}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onClose();
        }}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle
          sx={{
            backgroundColor: '#088ac6',
            color: 'white',
          }}
        >
          Reminder
        </DialogTitle>

        <DialogContent sx={{ padding: 0 }}>
          <Box
            margin={2}
            alignItems='center'
            justifyContent='center'
            mb={window?.innerWidth > 500 ? 2 : 1}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={2}
                className={styles?.centerClass}
              >
                <Avatar
                  src={
                    userInfo?.profile_picture ??
                    '/static/images/placeholder_profile.png'
                  }
                  sx={{ height: '40px', width: '40px' }}
                />
              </Grid>
              <Grid
                item
                xs={8}
              >
                <Typography variant='subtitle1'>
                  {userInfo?.name} is on FREE plan , Please ask to upgrade to a
                  paid plan to participate in Knocard Link.{' '}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ paddingX: 3 }}>
          <LoadingButton
            type='button'
            variant='contained'
            onClick={onClose}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FreePlanSubcriptionModel;
