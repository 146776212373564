import {
  DialogContent,
  IconButton,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import '@splidejs/react-splide/css';

import { TutorialVideoItem } from '../../store/tutorialVideo/api/interface';
import ReactPlayer from 'react-player';
import { useEffect, useRef } from 'react';

type IProps = {
  tutorialVideoItem: TutorialVideoItem;
  toggle: boolean;
  onVideoPlay?: (videoId: number, time: number, duration: number) => void;
  onVideoPause?: (videoId: number, time: number, duration: number) => void;
  onClose: () => void;
};


const VideoTutorialModel: React.FC<IProps> = ({
  toggle, onClose, tutorialVideoItem, onVideoPlay, onVideoPause
}) => {

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!toggle) {
      if (videoRef.current && videoRef.current && !videoRef.current.paused && !videoRef.current.ended) {
        if (onVideoPause && tutorialVideoItem.id) {
          onVideoPause(tutorialVideoItem.id, videoRef.current.currentTime, videoRef.current.duration || 0);
        }
        videoRef.current.pause();
      }
    }
  }, [toggle]);

  return (
    <>
      <Dialog
        open={toggle}
        onClose={onClose}
        maxWidth='sm'
        fullWidth
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: 3000
        }}
      >
        <DialogContent sx={{ position: "relative" }}>
          <IconButton sx={{ position: "absolute", right: 0, top: 0 }} onClick={onClose}><CloseIcon /></IconButton>
          <video
            ref={videoRef}
            controls
            controlsList="nodownload"
            width="100%"
            height="100%"
            className="object-fit-contain"
            style={{ maxHeight: "80vh" }}

            onPlay={(props: any) => {
              if (onVideoPlay && tutorialVideoItem.id) {
                onVideoPlay(tutorialVideoItem.id, 0, props?.currentTarget?.duration || 0);
              }
            }}
            onPause={(props: any) => {
              if (onVideoPause && tutorialVideoItem.id) {
                onVideoPause(tutorialVideoItem.id, props.currentTarget.currentTime, props?.currentTarget?.duration || 0);
              }
            }}
          >
            <source src={tutorialVideoItem.link} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </DialogContent>

      </Dialog>
    </>
  );
};

export default VideoTutorialModel;
