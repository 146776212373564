import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import {
  IReviewFormState,
  NewReviewResponse,
  ReviewRequestDetailResponse,
  UpdateReviewStatusResponse,
  UserReviewResponse,
} from './interface';

export const postReviewForm = createAsyncThunk(
  'reviewForm/postReviewForm',
  async (reqBody: IReviewFormState, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<NewReviewResponse>(
        `api/user-ratings`,
        reqBody
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getUserReviews = createAsyncThunk(
  'reviewForm/getUserReviews',
  async (data: { userId?: number; pageNo: number }, { rejectWithValue }) => {
    try {
      const url = data.userId
        ? `api/user-ratings/${data.userId}?page=${data.pageNo}`
        : `api/user-ratings?page=${data.pageNo}`;
      const response = await axiosInstance.get<UserReviewResponse>(url);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getReviewRequestDetail = createAsyncThunk(
  'reviewForm/getReviewRequestDetail',
  async (data: { userId?: number; shareCode: string }, { rejectWithValue }) => {
    try {
      const url = `api/request-review/share?user_id=${data.userId}&share_code=${data.shareCode}`;
      const response = await axiosInstance.get<ReviewRequestDetailResponse>(
        url
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateReviewStatus = createAsyncThunk(
  'reviewForm/updateReviewStatus',
  async (
    reqBody: { reviewId: number; status: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put<UpdateReviewStatusResponse>(
        `api/user-ratings/status/${reqBody.reviewId}`,
        { status: reqBody.status }
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);