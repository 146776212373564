import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
import componentsOverride from './theme/theme';
import palette from './theme/palette';

let theme = createTheme({
  palette: palette,
  typography: {
    fontFamily: '"Poppins", sans-serif'
  },
});

theme.components = componentsOverride(theme)
theme = responsiveFontSizes(theme);

const root = createRoot(document.getElementById('root')!);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            theme='colored'
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </HelmetProvider>
);


reportWebVitals();
