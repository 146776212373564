import React from "react";
import { calculateReportPercentageDiff, formatReportCount } from "../../utils/utilMethods";
import { Box, Stack, Typography } from "@mui/material";
import { AppColors } from "../../utils/utils";


interface IProps {
    title: string;
    previousReport: number;
    currentReport: number;
    isProUser: boolean;
    reportType: string;
}

export const ReportCountItem: React.FC<IProps> = ({ title, previousReport, currentReport, isProUser, reportType }) => {

    let percentage = calculateReportPercentageDiff(previousReport, currentReport);


    return (<Box sx={{ padding: "10px 10px 5px 10px", backgroundColor: AppColors.backgroundColor, border: `${AppColors.strokeColor} 1px solid`, borderRadius: "10px", width: "100%" }}>
        <Typography sx={{ color: AppColors.blackColor, fontSize: "1rem", pl: 0.5, pr: 0.5, }}>{title}</Typography>
        <Stack flexDirection={'row'} sx={{ position: 'relative' }}>
            <Typography
                variant='h6'
                sx={{
                    color: AppColors.primaryColor,
                    fontSize: "1.6rem !important",
                    flexDirection: "column",
                    fontWeight: "400",
                    flexGrow: 1
                }}
            >
                {(!isProUser) ? 0 : formatReportCount(currentReport || 0)}
            </Typography>

            {isProUser && percentage != 0.0 && reportType != 'All Time' && (<Box sx={{ flexGrow: 1, textAlign: 'end' }}>
                <Box component={'img'} src={((percentage > 0.0) ? '/static/images/chart_up.svg' : '/static/images/chart_down.svg')} />
                <Typography sx={{ color: ((percentage > 0.0) ? AppColors.greenColor : AppColors.redColor), fontSize: "0.8rem" }}>{percentage}%</Typography>
            </Box>)}

            {!isProUser && (<Box
                component='img'
                src='/static/images/lock-yellow.svg'
                sx={{ height: '35px', width: '35px', position: 'absolute', right: 0, }}
            />)}
        </Stack>
    </Box>
    );
}