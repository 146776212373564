export interface CompanyExternalLink {
    id: number;
    company_id: number;
    title: string;
    description?: string;
    color: string;
    link: string;
    status: string;
    created_at: Date;
    updated_at: Date;
    icon_url: string | null;
    icon: number;
}



export interface CreateUpdateCompanyExternalLinkRequest {
    id?: number;
    company_id: number;
    title: string;
    description?: string;
    color: string;
    link: string;
    status: string;
    icon_url: string | null;
    icon: number;
}

export interface ViewCompanyExternalLinkRequest {
    company_external_link_id: number;
    viewer_code?: string | null;
    viewer_id?: number | null;
}

interface InitialState {
    data: CompanyExternalLink[];
    status: {
        code: string;
        description: string;
    };
    isError: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    errorMessage: string;
}

export const initialState: InitialState = {
    data: [],
    status: {
        code: '',
        description: '',
    },
    isError: false,
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
};
