import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import styles from "../../styles/ReferLandingPageTwo.module.css";
import { Link, useSearchParams } from "react-router-dom";

import React, { useEffect, useState } from "react";
import { config } from "../../utils/utils";

const ReferLandingSectionTwo = () => {

    const [searchParams] = useSearchParams();
    const [referredByLink, setReferredByLink] = useState('');



    useEffect(() => {
        var refferLink = `${config.VBC_URL}`;
        if (searchParams.has(config.REFER_KEY)) {
            refferLink += `/affiliate/${searchParams.get(config.REFER_KEY) ?? ''}`;
        } else {
            refferLink += "/register";
        }
        setReferredByLink(refferLink);
    }, []);

    const Section2List = [
        {
            img: '/static/referrel_two/Section2/icon_1.png',
            title: 'SAY GOODBYE TO COLD CALLING',
            text: 'KnoCard seamlessly integrates text messaging for your existing contacts and new prospects, making cold'
        },
        {
            img: '/static/referrel_two/Section2/icon_2.png',
            title: 'SIMPLE TURN KEY ENGAGEMENT SYSTEM',
            text: 'KnoCard seamlessly integrates text messaging for your existing contacts and new prospects, making cold'
        },
        {
            img: '/static/referrel_two/Section2/icon_3.png',
            title: 'ELIMINATE REJECTION ANXIETY',
            text: 'In-depth reporting identifies which leads are, and are not interested.'
        },
        {
            img: '/static/referrel_two/Section2/icon_4.png',
            title: 'STOP WASTING TIME',
            text: 'Start focusing your time and energy on those who have shown interest.'
        },
        {
            img: '/static/referrel_two/Section2/icon_5.png',
            title: 'END RELIANCE ON SOCIAL ALGORHYTHMS',
            text: 'Ensure maximum outreach to your targeted audience'
        },
        {
            img: '/static/referrel_two/Section2/icon_6.png',
            title: 'BREAK FREE FROM THE CROWD',
            text: 'Who do you want to brand... yourself or your leaders/company?'
        },
    ];
    const Section3List = [
        {
            icon: "/static/referrel_two/Section3/icon_1.png",
            title: "Showcase with Multimedia",
            text: "Elevate your pitch with compelling media.",
        },
        {
            icon: "/static/referrel_two/Section3/icon_2.png",
            title: "Versatile media integration",
            text: "Incorporate videos, and images seamlessly.",
        },
        {
            icon: "/static/referrel_two/Section3/icon_3.png",
            title: "Integrate Corporate videos",
            text: "Elevate your pitch with compelling media.",
        },
        {
            icon: "/static/referrel_two/Section3/icon_4.png",
            title: "Add interactive forms",
            text: "Say goodbye to guesswork when it comes to understanding what drives prospects to buy.",
        },
    ]
    const Section5List = [
        {
            img: "/static/referrel_two/Section5/icon_1.png",
            title: "Structured guidance",
            text: "Follow a 30-day blueprint to maximize engagement.",
            label: 'highlight',
        },
        {
            img: "/static/referrel_two/Section5/icon_2.png",
            title: "System for success",
            text: "User-friendly system complete with guided videos",
            label: 'normal',
        },
        {
            img: "/static/referrel_two/Section5/icon_3.png",
            title: "Effortless scalability",
            text: "Duplicate successful campaigns for new products or services.",
            label: 'highlight',
        },
        {
            img: "/static/referrel_two/Section5/icon_4.png",
            title: "Be positioned as a value-added leader",
            text: "Equip team members with success-driven tools.",
            label: 'normal',
        },
    ];
    const Section6List = [
        {
            title: 'Instant prospect evaluation',
            text: 'Determine engagement levels with color-coded cues.',
            img: '/static/referrel_two/Section6/knocard_face_red.png',
            subTitle: 'Low Engagement',
            label: 1
        },
        {
            title: 'Prioritized follow-ups',
            text: 'Focus on prospects that show genuine interest.',
            img: '/static/referrel_two/Section6/knocard_face_orange.png',
            subTitle: 'Med Engagement',
            label: 2
        },
        {
            title: 'Simplified analytics',
            text: 'Reduce guesswork with clear, actionable insights',
            img: '/static/referrel_two/Section6/knocard_face_green.png',
            subTitle: 'High Engagement',
            label: 3
        },
    ]
    const Section9List = [
        {
            title: "KnoCard Social",
            text: "Dive into our exclusive social platform tailored just for business owners and networkers. No distractions: just pure business.",
            padding: false,
        },
        {
            title: "Community Connection",
            text: "Step into a vibrant community where users can actively engage, view, and purchase directly from your knoCard.",
            padding: true,
        },
        {
            title: "Effortless Sharing",
            text: "Amplify your reach with KnoCard's easy-to-share features. Track every interaction and stay ahead.",
            padding: false,
        },
    ];
    const Section10List = [
        {
            img: '/static/referrel_two/Section10/x2.png',
            title: 'Double-dip on earnings',
            text: 'Boost your income as your team hops on board. Every KnoCard subscription can pay you.',
            label: 'horizontal',
        },
        {
            img: '/static/referrel_two/Section10/cloud_stairs.png',
            title: 'EViral Potontial',
            text: 'Imagine harnessing the power of a groundbraking digital tool that\'s sweeping the world! KnoCard\'s capacity to go viral will ensure you a tremendous additional revenue stream.',
            label: 'vertical',
        },
        {
            img: '/static/referrel_two/Section10/knocard_sun.png',
            title: 'Versatile Value',
            text: 'While your product or opportunity may not resonate with everyone, KnoCard brings value to the masses.',
            label: 'horizontal',
        },
    ];
    const Section11List = [
        {
            icon: '/static/referrel_two/Section11/icon1.png',
            title: "Rejection-proof system",
            text: "Say goodbye to the embarrassment & anxiety of approaching friends and family who aren't interested",
        },
        {
            icon: '/static/referrel_two/Section11/icon2.png',
            title: 'Confidence boosters',
            text: "Filter out the no's and celebrate the yesses, your endorphins will skyrocket!",
        },
        {
            icon: '/static/referrel_two/Section11/icon3.png',
            title: 'Quality over quantity',
            text: 'Cultivate deeper relationships with fawor, but more engaged, prospects',
        },
    ]
    const Section12List = [
        {
            icon: '/static/referrel_two/Section12/icon1.png',
            title: 'Your time for success is NOW',
            text: 'Turn your "hobby" into a solid, life-changing business.',
            label: 'right',
        },
        {
            icon: '/static/referrel_two/Section12/icon2.png',
            title: 'Get immediate access',
            text: "Dive into KnoCard Engage's array of tools immediately after joining.",
            label: 'left',
        },
        {
            icon: '/static/referrel_two/Section12/icon3.png',
            title: "Continual enhancement",
            text: "Stay informed with Maintain your business edge with regular feature enhancements and updates. feedback on message deliveries and openings.",
            label: 'right',
        },
        {
            icon: '/static/referrel_two/Section12/icon4.png',
            title: "Community support",
            text: "Join a thriving community of marketers to share success stories and tips.",
            label: 'left',
        },
    ]

    return (
        <>

            <Grid container >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box className={styles.section_1_outer}>
                        <Grid container>
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} ></Grid>
                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                <Typography variant="h2" sx={{
                                    paddingTop: "30px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    fontFamily: 'Poppins',
                                    fontSize: { xs: '25px', sm: '25px', md: '35px', lg: '35px', xl: '35px', },
                                    paddingBottom: { xs: '40px', sm: '40px', md: '50px', lg: '80px', xl: '150px', },
                                }}> Promote your product without the <span style={{ color: "#08ace1" }} >REJECTION KnoCard Engage</span> is for network marketers that HATE the <span>REJECTION</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} ></Grid>
                        </Grid>
                        <Box sx={{
                            minHeight: "50vh",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "column"
                        }} >
                            <Grid container>
                                <Grid item xs={1} sm={2} md={4} lg={4.5}></Grid>
                                <Grid item xs={10} sm={8} md={4} lg={3} sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    fontFamily: 'Poppins',
                                }} >
                                    <Box sx={{ width: "100%", display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none', }, justifyContent: 'center', paddingBottom: "15px" }}>
                                        <img src='/static/referrel_two/Section1/KnoCard.png' style={{ width: "60%", height: "auto", }} />
                                    </Box>
                                    <Paper elevation={4} sx={{
                                        width: "100%",
                                        padding: "2px",
                                        borderRadius: "35px",
                                        background: "linear-gradient(180deg, rgba(255, 255, 255, 0) 16%, rgba(255,255,255,1) 100%)",
                                    }} >

                                        <Box className={styles.sec1_get_engage} sx={{ width: "100%", padding: "50px 0px", borderRadius: "35px", }} >
                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", }} >
                                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                    <img src='/static/referrel_two/Section1/Layer_19.png' style={{ width: "60%", height: "auto", }} />
                                                </Box>
                                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                                                    <Paper sx={{
                                                        mt: 2,
                                                        width: {
                                                            xs: '45%', sm: '40%', md: '40%', lg: '40%', xl: '35%',
                                                        },
                                                        padding: {
                                                            xs: '10px 15px',
                                                            sm: '10px 15px',
                                                            md: '10px 15px',
                                                            lg: '10px 5px',
                                                            xl: '10px 20px',
                                                        },
                                                        bgcolor: '#facc32',
                                                        cursor: "pointer"
                                                    }}>
                                                        <Link to={referredByLink}>
                                                            <Typography sx={{
                                                                fontWeight: 'bold',
                                                                fontSize: { xs: '12px', sm: '12px', md: '12px', lg: '15px', xl: '15px', },
                                                                textAlign: "center",
                                                                fontFamily: 'Poppins',
                                                                color: "#000000",
                                                            }} >Get Engage</Typography>
                                                        </Link>
                                                    </Paper>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Paper>

                                </Grid>
                                <Grid item xs={1} sm={2} md={4} lg={4.5}></Grid>
                            </Grid>

                            <Box sx={{ width: "100%" }}>
                                <Typography sx={{
                                    fontSize: {
                                        xs: '20px',
                                        sm: '25px',
                                        md: '30px',
                                        lg: '30px',
                                        xl: '30px',
                                    },
                                    pt: {
                                        xs: 5,
                                        sm: 5,
                                        md: 0,
                                        lg: 0,
                                        xl: 0,
                                    },

                                    textAlign: "center",
                                    color: { xs: '#000', sm: '#000', md: '#000', lg: "#fff", xl: '#fff' },
                                    fontWeight: "bold",
                                    wordSpacing: "2px",
                                    letterSpacing: "1px",
                                    fontFamily: 'Poppins',
                                }}> Facing Network Marketing Challenges? </Typography>
                                <Grid container>
                                    <Grid item xs={0} sm={0} md={1} lg={3} xl={3}></Grid>
                                    <Grid item xs={11} sm={12} md={10} lg={6} xl={6} >
                                        <Typography sx={{
                                            color: { xs: '#000', sm: '#000', md: '#000', lg: "#fff", xl: '#fff' },
                                            padding: "10px 0 20px 0",
                                            fontFamily: 'Poppins',
                                            textAlign: 'center',
                                        }} >You're not alone! In fact, statistics show that on average, only about 1 in 100 network marketers earn over $1,000 a month. Most face the same challenging hurdles in building their businesses. It's important to understand that by identifying and overcoming these obstacles, you can position yourself for success in this industry</Typography>
                                    </Grid>
                                    <Grid item xs={0} sm={0} md={1} lg={3} xl={3}></Grid>
                                </Grid>
                            </Box>

                        </Box>

                    </Box>
                </Grid>
            </Grid>

            <Box className={styles.section_2_outer} >
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{ width: "100%", minHeight: "50vh" }} >
                            <Grid container>
                                <Grid item xs={0.5} sm={1.5} md={3.5} lg={3}></Grid>
                                <Grid item xs={11} sm={9} md={5} lg={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                    <img src="/static/referrel_two/Section2/Group221.png" style={{ width: "100%" }} />
                                </Grid>
                                <Grid item xs={0.5} sm={1.5} md={3.5} lg={3}></Grid>
                            </Grid>
                        </Box>

                        <Grid container sx={{ padding: { xs: '0', sm: '0', md: '0', lg: '30px 0 0 0', xl: '30px 0 0 0', } }} >
                            <Grid item xs={0} sm={0} md={2} lg={2} xl={2} sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }} ></Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <Grid container>
                                    {
                                        Section2List.map((x, i) => {
                                            return (
                                                <Grid item xs={12} sm={12} md={6} lg={4} key={i} sx={{ pb: { xs: 1, sm: 2, md: 4, lg: 4, xl: 4, }, }}>
                                                    <Box sx={{ p: 4 }}>
                                                        <Grid container>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <Box sx={{
                                                                    width: {
                                                                        xs: '35%',
                                                                        sm: '20%',
                                                                        md: '100%',
                                                                        lg: '100%',
                                                                        lx: '100%'
                                                                    },
                                                                }} >

                                                                    <img src={x.img} alt={`icon_${i}`} style={{ width: "100%" }} />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={9} lg={9} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <Typography sx={{
                                                                    textAlign: "center",
                                                                    color: "#06ABDE",
                                                                    fontSize: {
                                                                        xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '20px'
                                                                    },
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: "bold",
                                                                    pt: { xs: 2, sm: 2, md: 0, lg: 0, xl: 0 }
                                                                }} > {x.title}</Typography>
                                                            </Grid>
                                                            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }} ></Grid>
                                                            <Grid item xs={12} sm={12} md={11} lg={11} sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}>
                                                                <Typography sx={{
                                                                    paddingTop: "15px",
                                                                    textAlign: "center",
                                                                    color: "#4E5B69",
                                                                    letterSpacing: "1px",
                                                                    wordSpacing: "2px",
                                                                    fontFamily: 'Poppins',
                                                                }}>{x.text}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={0} sm={0} md={2} lg={2}></Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Box>

            <Box className={styles.section_3_outer} sx={{ pt: 5, pb: 4 }} >
                <Grid container>
                    <Grid item xs={0} sm={0} md={0} lg={3} xl={3} sx={{
                        display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block', }
                    }} >
                        <Box sx={{ minHeight: "100vh", display: "flex", alignItems: "center", }} >
                            <img src='/static/referrel_two/Section3/phone.png' style={{ width: "100%" }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box sx={{ width: "100%" }} >
                            <Typography sx={{
                                textAlign: "center", color: "#08ace1", fontWeight: "bold", fontFamily: "'Poppins', sans-serif",
                                fontSize: {
                                    xs: '25px',
                                    sm: '25px',
                                    md: '25px',
                                    lg: '35px',
                                    xl: '35px',
                                },
                            }}>
                                Meet KnoCard Engage: Your Solution
                            </Typography>
                            <Typography sx={{ pl: 1, pr: 1, paddingTop: "20px", textAlign: "center", fontFamily: "'Poppins', sans-serif", }}>
                                Introducing KnoCard Engage, enhancing the way we connect! This all-in-one platform centralizes all of your marketing tools in one simple yet robust app.
                            </Typography>
                            <Typography sx={{ pl: 1, pr: 1, paddingTop: "20px", textAlign: "center", fontFamily: "'Poppins', sans-serif", }}>
                                You'll love the user-friendly design and strategic built-in marketing strategies. Easily transition and invest your time in recruiting, no learning curve required.
                            </Typography>
                        </Box>
                        <Box sx={{ padding: '20px 0', width: "100%", display: "flex", justifyContent: "center", }} >
                            <Paper elevation={3} sx={{ borderRadius: "3px", padding: "10px 20px", bgcolor: '#facc32', cursor: "pointer" }}>
                                <Link to={referredByLink}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: "15px", textAlign: "center", fontFamily: "'Poppins', sans-serif", color: "#000000", textTransform: 'capitalize' }} >
                                        get engage
                                    </Typography>
                                </Link>
                            </Paper>
                        </Box>
                        <Box>
                            <Grid container>
                                {Section3List.map((x, index) => (
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ padding: "15px" }} key={index}>
                                        <Paper
                                            elevation={4}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                height: '100%',
                                                borderRadius: "30px",
                                                padding: "10px",
                                                background: index === 0
                                                    ? "linear-gradient(0deg, rgba(2, 37, 56, 1) -10%, rgb(12, 142, 202) 100%)"
                                                    : "#fff",
                                            }}
                                        >
                                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                <img src={x.icon} alt={`icon_${index}`} />
                                            </Box>
                                            <Box>
                                                <Typography sx={{
                                                    fontSize: {
                                                        xs: '18px',
                                                        sm: '18px',
                                                        md: '18px',
                                                        lg: '22px',
                                                        xl: '22px',
                                                    },
                                                    color: index === 0 ? "#fff" : "#08ace1",
                                                    fontFamily: "'Poppins', sans-serif",
                                                    letterSpacing: "1.5px",
                                                    textAlign: "center",
                                                    padding: "10px 0",
                                                    fontWeight: "bold",
                                                }}>{x.title}</Typography>
                                                <Typography sx={{
                                                    fontSize: {
                                                        xs: '14px',
                                                        sm: '14px',
                                                        md: '14px',
                                                        lg: '16px',
                                                        xl: '16px',
                                                    },
                                                    color: index === 0 ? "#fff" : "#000",
                                                    fontFamily: "'Poppins', sans-serif",
                                                    letterSpacing: "1.5px",
                                                    textAlign: "center",
                                                    paddingBottom: "10px",
                                                }}>{x.text}</Typography>
                                                <Typography sx={{ color: "#08ace1", textAlign: "center" }}>Learn More</Typography>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={0} sm={0} md={0} lg={3} xl={3} sx={{
                        display: { xs: 'none', sm: 'none', md: '', lg: 'block', xl: 'block', }
                    }} >
                        <Box sx={{
                            minHeight: "100vh",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                        }} > <img src="/static/referrel_two/Section3/section_3_right_side.png" alt="" style={{ width: "100%" }} /> </Box>
                    </Grid>

                </Grid>
            </Box>

            <Box className={styles.section_4_outer} sx={{ pt: 4, pr: 1, pl: 1 }} >
                <Grid container>
                    <Grid item xs={0} sm={0} md={0} lg={3} sx={{
                        display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block', }
                    }} >
                        <img src="/static/referrel_two/Section4/Group_20.png" style={{ width: "100%" }} alt="" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{
                        paddingLeft: { xs: 1, sm: 2, md: 0, lg: 0, xl: 0 },
                    }}>
                        <Box sx={{ textAlign: "center", pt: 2, width: "100%" }}>
                            <Box>
                                <Typography sx={{
                                    color: "#08ace1",
                                    fontSize: {
                                        xs: '25px', sm: '25px', md: '25px', lg: '35px', xl: '35px'
                                    },
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: "bold",
                                    textAlign: "center"

                                }} >Share and Connect with Ease</Typography>
                                <Typography sx={{
                                    fontWeight: "500",
                                    fontFamily: "'Poppins', sans-serif",
                                    fontSize: {
                                        xs: '17px', sm: '17px', md: '17px', lg: '19px', xl: '19px'
                                    },
                                }} >Boost your connection rates with effortless sharing.</Typography>
                            </Box>
                            <Box sx={{ pt: 3 }}>
                                <Typography sx={{
                                    color: "#08ace1",
                                    fontSize: { xs: "20px", sm: "20px", md: "21px", lg: "23px", lx: "23px" },
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: "bold",
                                }}>Instant sharing</Typography>
                                <Typography sx={{
                                    fontWeight: "500",
                                    fontFamily: "'Poppins', sans-serif",
                                    fontSize: {
                                        xs: '13px', sm: '13px', md: '13px', lg: '18px', xl: '18px'
                                    },
                                }}>Dispatch your pitch to multiple prospects with a single click</Typography>
                            </Box>
                            <Box sx={{ pt: 3 }}>
                                <Typography sx={{
                                    color: "#08ace1",
                                    fontSize: { xs: "20px", sm: "20px", md: "21px", lg: "23px", lx: "23px" },
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: "bold",
                                }}>Integrated social media</Typography>
                                <Typography sx={{
                                    fontWeight: "500",
                                    fontFamily: "'Poppins', sans-serif",
                                    fontSize: { xs: '13px', sm: '13px', md: '13px', lg: '18px', xl: '18px' },
                                }} >Expand your reach by connecting to all major platforms.</Typography>
                            </Box>
                            <Box sx={{ pt: 3 }}>
                                <Typography sx={{
                                    color: "#08ace1",
                                    fontSize: { xs: "20px", sm: "20px", md: "21px", lg: "23px", lx: "23px" },
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: "bold",
                                }}>Smart notifications</Typography>
                                <Typography sx={{
                                    fontWeight: "500",
                                    fontFamily: "'Poppins', sans-serif",
                                    fontSize: { xs: "20px", sm: "20px", md: "21px", lg: "23px", lx: "23px" },
                                }} >Stay informed with real-time feedback on message deliveries and openings.</Typography>
                            </Box>
                        </Box>
                        <Box sx={{
                            pt: 3,
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <Box sx={{
                                width: {
                                    xs: '100%',
                                    sm: '100%',
                                    md: '100%',
                                    lg: 'auto',
                                    xl: 'auto',
                                }
                            }} >
                                <img src="/static/referrel_two/Section4/Pa1_copy.png" style={{ width: "100%" }} />
                            </Box>
                        </Box>

                    </Grid>
                    <Grid item xs={0} sm={0} md={0} lg={3} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block', } }} ></Grid>
                </Grid>
            </Box>

            <Box className={styles.section_5_outer}>
                <Box className={styles.section_5}>
                    <Grid container>
                        <Grid item xs={0} sm={0} md={2} lg={3} sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block', } }} ></Grid>
                        <Grid item xs={12} sm={12} md={8} lg={6} sx={{
                            padding: {
                                xs: '20px 20px 0 20px',
                                sm: '20px 20px 0 20px',
                                md: '20px 0 0 0',
                                lg: '20px 0 0 0',
                            }
                        }} >
                            <Box sx={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }} >
                                <Typography sx={{
                                    color: "#08ace1",
                                    fontSize: {
                                        xs: '25px', sm: '25px', md: '25px', lg: '35px', xl: '35px'
                                    },
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: "bold",
                                    pt: 5
                                }} >Easy Recruiting in 30 Days</Typography>
                                <Typography sx={{
                                    pt: 2, fontFamily: "'Poppins', sans-serif",
                                    fontSize: {
                                        xs: '17px', sm: '17px', md: '17px', lg: "19px", xl: "19px"
                                    }
                                }} > Master recruiting without rejection </Typography>
                            </Box>
                            <Box sx={{ pt: 3 }}>
                                <Grid container>
                                    {
                                        Section5List.map((x, index) => {
                                            return (
                                                <>
                                                    {
                                                        x.label === 'highlight' ?
                                                            <React.Fragment>
                                                                <Grid item xs={12} sm={12} md={0} lg={0} xl={0} sx={{
                                                                    display: {
                                                                        xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none'
                                                                    },
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    pt: 4
                                                                }} >
                                                                    <img src={x.img} alt="" />
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={10} lg={10} sx={{
                                                                    display: "flex",
                                                                    justifyContent: 'center',
                                                                    alignItems: {
                                                                        xs: 'center',
                                                                        sm: 'center',
                                                                        md: 'start',
                                                                        lg: 'start',
                                                                        xl: 'start',
                                                                    },
                                                                    flexDirection: "column",
                                                                }} >
                                                                    <Typography sx={{
                                                                        pt: 1,
                                                                        fontSize: { xs: '19px', sm: '19px', md: '19px', lg: '22px', xl: '22px' },
                                                                        fontFamily: "'Poppins', sans-serif",
                                                                        fontWeight: "bold",
                                                                        color: "#08ace1",
                                                                    }} >{x.title}</Typography>
                                                                    <Typography sx={{
                                                                        pt: 1, fontFamily: "'Poppins', sans-serif",
                                                                        fontSize: {
                                                                            xs: '13px', sm: '13px', md: '13px', lg: '18px', xl: '18px'
                                                                        },
                                                                        textAlign: 'center',
                                                                    }} >{x.text}</Typography>
                                                                </Grid>
                                                                <Grid item xs={0} sm={0} md={2} lg={2} sx={{
                                                                    mb: 4,
                                                                    display: {
                                                                        xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block'
                                                                    },
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }} >
                                                                    <img src={x.img} alt="" />
                                                                </Grid>
                                                            </React.Fragment> :
                                                            <>
                                                                <Grid item xs={12} sm={12} md={0} lg={0} xl={0} sx={{
                                                                    display: {
                                                                        xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none'
                                                                    },
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    pt: 4
                                                                }} >
                                                                    <img src={x.img} alt="" />
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={10} lg={10} sx={{
                                                                    display: "flex",
                                                                    justifyContent: 'center',
                                                                    alignItems: {
                                                                        xs: 'center',
                                                                        sm: 'center',
                                                                        md: 'start',
                                                                        lg: 'start',
                                                                        xl: 'start',
                                                                    },
                                                                    flexDirection: "column",
                                                                    textAlign: 'center'
                                                                }} >
                                                                    <Typography sx={{
                                                                        pt: 1,
                                                                        fontSize: {
                                                                            xs: '15px', sm: '15px', md: '15px', lg: '20px', xl: '20px'
                                                                        },
                                                                        fontFamily: "'Poppins', sans-serif",
                                                                        fontWeight: "bold",
                                                                        textAlign: 'center',
                                                                    }} >{x.title}</Typography>
                                                                    <Typography sx={{
                                                                        pt: 1,
                                                                        fontFamily: "'Poppins', sans-serif",
                                                                        fontSize: {
                                                                            xs: '13px', sm: '13px', md: '13px', lg: '18px', xl: '18px'
                                                                        },
                                                                        textAlign: 'center',
                                                                    }} >{x.text}</Typography>
                                                                </Grid>
                                                                <Grid item xs={0} sm={0} md={2} lg={2} sx={{
                                                                    mb: 4,
                                                                    display: {
                                                                        xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block'
                                                                    },
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }} >
                                                                    <img src={x.img} alt="" />
                                                                </Grid>
                                                            </>
                                                    }
                                                </>
                                            )
                                        })
                                    }

                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={0} sm={0} md={2} lg={3} sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block', } }} ></Grid>
                    </Grid>
                    <Grid container sx={{ paddingTop: "80px" }}>
                        <Grid item xs={0.5} sm={0.5} md={2} lg={2}></Grid>
                        <Grid item xs={11} sm={11} md={8} lg={8} sx={{
                            pb: {
                                xs: 3,
                                sm: 3,
                                md: 4,
                                lg: 5,
                                xl: 5,
                            }
                        }} >
                            <img src="/static/referrel_two/Section5/section5_bar.png" alt="" style={{
                                width: "100%"
                            }} />
                        </Grid>
                        <Grid item xs={0.5} sm={0.5} md={2} lg={2}></Grid>
                    </Grid>
                </Box>
            </Box>

            <Box className={styles.section_6_outer} sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around "
            }} >
                <Grid container sx={{
                    pl: 0.5,
                    pr: 0.5
                }} >
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        pt: 5,

                    }} >
                        <Typography sx={{
                            textAlign: "center",
                            color: "#fff",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: {
                                xs: '25px', sm: '25px', md: '25px', lg: '35px', xl: '35px'
                            }
                        }} >Quick Insights with Color Codes</Typography>
                        <Typography sx={{
                            textAlign: "center",
                            color: "#fff",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: {
                                xs: "17px",
                                sm: "17px",
                                md: "17px",
                                lg: "19px",
                                xl: "19px",
                            },
                            textShadow: '2px 4px 4px #000',
                            pb: 1,
                        }} >Navigate your progress with clarity</Typography>
                    </Grid>
                    <Grid item xs={0} sm={0} md={0} lg={2} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }} ></Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ pt: 10 }} >

                        {
                            Section6List.map((x, i) => {
                                return (
                                    <>
                                        <React.Fragment key={i}>
                                            {x.label === 2 ? (
                                                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex', }, paddingBottom: "50px", justifyContent: "space-between", }} >
                                                    <Box sx={{ width: { xs: '98%', sm: '98%', md: '98%', lg: '48%', xl: '48%', }, textAlign: "center" }} >
                                                        <Typography sx={{
                                                            color: "#fff",
                                                            fontSize: { xs: "20px", sm: "20px", md: "21px", lg: "23px", lx: "23px" },
                                                            fontFamily: "'Poppins', sans-serif",
                                                            fontWeight: "bold",
                                                            pb: 1,
                                                            textShadow: '2px 2px 4px #000',
                                                            letterSpacing: '1.5px',
                                                        }} >
                                                            {x.title}
                                                        </Typography>
                                                        <Typography sx={{
                                                            fontFamily: "'Poppins', sans-serif",
                                                            fontWeight: "bold",
                                                            letterSpacing: '0.8px',
                                                            wordSpacing: "1px",
                                                            fontSize: {}
                                                        }} >
                                                            {x.text}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.sec6_face_label} sx={{
                                                        width: { xs: '98%', sm: '98%', md: '98%', lg: '48%', xl: '48%', },
                                                        textAlign: "center"
                                                    }} >
                                                        <Box>
                                                            <img src={x.img} alt="knocardface" />
                                                        </Box>
                                                        <Box className={styles.sec6_face_label_contet2} sx={{ width: '49%', }} >
                                                            <Typography sx={{
                                                                textTransform: "capitalize",
                                                                fontFamily: "'Poppins', sans-serif",
                                                                fontSize: { xs: '16px', sm: '16px', md: '15px', lg: '15px', xl: '15px' },
                                                                fontWeight: "bold", color: "#fff", textShadow: 'rgb(0 0 0 / 56%) 2px 2px 4px'
                                                            }} >
                                                                {x.subTitle}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ) : x.label === 1 ? (

                                                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex', }, paddingBottom: "50px", justifyContent: "space-between", }} >
                                                    <Box sx={{ width: { xs: '98%', sm: '98%', md: '98%', lg: '48%', xl: '48%', }, textAlign: "center" }} >
                                                        <Typography sx={{ color: "#fff", fontSize: { xs: "20px", sm: "20px", md: "21px", lg: "23px", lx: "23px" }, fontFamily: "'Poppins', sans-serif", fontWeight: "bold", pb: 1, textShadow: '1px 1px 2px #000', letterSpacing: '1.5px', }} >
                                                            {x.title}
                                                        </Typography>
                                                        <Typography sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: "bold", letterSpacing: '0.8px', wordSpacing: "1px", }} >
                                                            {x.text}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.sec6_face_label} sx={{ width: { xs: '98%', sm: '98%', md: '98%', lg: '48%', xl: '48%', }, textAlign: "center" }} >
                                                        <Box>
                                                            <img src={x.img} alt="knocardface" />
                                                        </Box>
                                                        <Box className={styles.sec6_face_label_contet1} sx={{ width: '49%', }} >
                                                            <Typography sx={{ textTransform: "capitalize", fontFamily: "'Poppins', sans-serif", fontSize: { xs: '16px', sm: '16px', md: '15px', lg: '15px', xl: '15px', }, fontWeight: "bold", color: "#fff", textShadow: 'rgb(0 0 0 / 56%) 2px 2px 4px', }} >
                                                                {x.subTitle}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex', }, paddingBottom: "50px", justifyContent: "space-between", }} >
                                                    <Box sx={{ width: { xs: '98%', sm: '98%', md: '98%', lg: '48%', xl: '48%', }, textAlign: "center" }} >
                                                        <Typography sx={{ color: "#fff", fontSize: "20px", fontFamily: "'Poppins', sans-serif", fontWeight: "bold", pb: 1, textShadow: '2px 2px 4px #000', letterSpacing: '1.5px', }} >
                                                            {x.title}
                                                        </Typography>
                                                        <Typography sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: "bold", letterSpacing: '0.8px', wordSpacing: "1px", }} >
                                                            {x.text}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.sec6_face_label} sx={{ width: { xs: '98%', sm: '98%', md: '98%', lg: '48%', xl: '48%', }, textAlign: "center" }} >
                                                        <Box>
                                                            <img src={x.img} alt="knocardface" />
                                                        </Box>
                                                        <Box className={styles.sec6_face_label_contet3} sx={{ width: '49%', }} >
                                                            <Typography sx={{ textTransform: "capitalize", fontFamily: "'Poppins', sans-serif", fontSize: { xs: '16px', sm: '16px', md: '15px', lg: '15px', xl: '15px', }, fontWeight: "bold", color: "#fff", textShadow: 'rgb(0 0 0 / 56%) 2px 2px 4px', }} >
                                                                {x.subTitle}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )}
                                        </React.Fragment>
                                    </>
                                )
                            })
                        }
                    </Grid>
                    <Grid item xs={0} sm={0} md={0} lg={3} xl={3} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }}>
                        <img src="/static/referrel_two/Section6/Section6_phone.png" style={{
                            minHeight: "75vh", width: "100%"
                        }} />
                    </Grid>
                    <Grid item xs={0} sm={0} md={0} lg={2} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }} ></Grid>
                </Grid>
            </Box>

            <Box className={styles.section_7_outer} sx={{
                pt: 5,
                minHeight: { md: '100vh' }
            }} >
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{
                        padding: { xs: '10px', sm: '10px', md: '30px', lg: '40px', xl: '50px' },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around'
                    }}  >
                        <Box>
                            <Typography sx={{
                                color: "#89EAFD",
                                fontSize: { xs: '25px', sm: '25px', md: '25px', lg: '35px', xl: '35px' },
                                fontWeight: "bold",
                                fontFamily: "'Poppins', sans-serif",
                            }} >Track Your Success</Typography>
                            <Typography sx={{
                                color: '#fff',
                                pb: { xs: 6, sm: 6, md: 6, lg: 0, xl: 0 },
                                fontSize: { xs: "15px", sm: "15px", md: "15px", lg: "17px", lx: "17px" },
                                fontFamily: "'Poppins', sans-serif",
                            }}>Deep dive into your prospects' behaviors.</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{
                                color: "#07ACE1",
                                fontSize: { xs: "20px", sm: "20px", md: "21px", lg: "23px", lx: "23px" },
                                fontFamily: "'Poppins', sans-serif",
                                mt: { md: 0, sm: 2, xs: 2 }
                            }} >Real-time analytics</Typography>
                            <Typography sx={{
                                color: "#fff",
                                fontFamily: "'Poppins', sans-serif",
                                fontSize: { xs: "15px", sm: "15px", md: "15px", lg: "17px", lx: "17px" },
                            }} >Dispatch Monitor live interactions and tweak your approach on-the-go. pitch to multiple prospects with a single click.</Typography>

                            <Typography sx={{
                                pt: 2,
                                color: "#07ACE1",
                                fontSize: { xs: "20px", sm: "20px", md: "21px", lg: "23px", lx: "23px" },
                                fontFamily: "'Poppins', sans-serif",
                                mt: { md: 0, sm: 2, xs: 2 }
                            }} >Behavioral insight</Typography>
                            <Typography sx={{
                                color: "#fff",
                                fontSize: { xs: "15px", sm: "15px", md: "15px", lg: "17px", lx: "17px" },
                                fontFamily: "'Poppins', sans-serif",
                            }} >Understand what truly captivates your prospects.</Typography>

                            <Typography sx={{
                                pt: 2,
                                color: "#07ACE1",
                                fontSize: { xs: "20px", sm: "20px", md: "21px", lg: "23px", lx: "23px" },
                                fontFamily: "'Poppins', sans-serif",
                                mt: { md: 0, sm: 2, xs: 2 }
                            }} >Historical data access</Typography>
                            <Typography sx={{
                                color: "#fff",
                                fontSize: { xs: "15px", sm: "15px", md: "15px", lg: "17px", lx: "17px" },
                                fontFamily: "'Poppins', sans-serif",
                            }} >Review past campaigns to identify which performed best.</Typography>
                        </Box>
                        <Box sx={{
                            pt: { xs: 6, sm: 6, md: 6, lg: 5, xl: 5 },
                        }} >
                            <Typography sx={{
                                color: "#89EAFD",
                                fontSize: { xs: '17px', sm: '20px', md: "21px", lg: '30px', xl: "35px", },
                                fontWeight: "bold",
                                fontFamily: "'Poppins', sans-serif",
                            }} >Hear the sound of interest</Typography>
                            <Typography sx={{
                                color: "#fff",
                                fontSize: { xs: "17px", sm: "17px", md: "17px", lg: "19px", lx: "19px", },
                                fontFamily: "'Poppins', sans-serif",
                            }} >Stay in the loop with real-time notifications when your prospects are actively engaged. Our technology filters out the noise, ensuring you're alerted when prospects are most interested in your product or opportunity, empowering you to strike while the iron is hot!</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} >
                        <Grid container>
                            <Grid item xs={5} sm={5} md={5} lg={5} sx={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "end",
                                position: 'relative',
                            }} >
                                <Box sx={{
                                    width: {
                                        xs: '100%', sm: "100%", md: "100%", lg: "100%", lx: "100%",
                                    },
                                    position: 'absolute',
                                    right: '-12%',

                                }} >
                                    <img src="/static/referrel_two/Section7/big_cel_phone.png" alt="" style={{
                                        width: "100%"
                                    }} />
                                </Box>
                            </Grid>
                            <Grid item xs={7} sm={7} md={7} lg={7}>
                                <Box sx={{
                                    width: {
                                        xs: '100%', sm: "100%", md: "100%", lg: "100%", lx: "100%",
                                    },
                                }} >
                                    <img src="/static/referrel_two/Section7/small_cel_phone.png" alt="" style={{
                                        width: "100%"
                                    }} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Grid container >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Box className={styles.section_8_future} sx={{
                        width: "100%",
                        pt: 3,
                        pb: 3,
                        pr: {
                            xs: 2,
                            sm: 2,
                            md: 3,
                            lg: 4,
                            xl: 5,
                        },
                    }}  >
                        <img src="/static/referrel_two/Section8/full_form_image.png" style={{
                            width: "100%"
                        }} />
                    </Box>
                </Grid>
            </Grid>

            <Box className={styles.section_9_outer} sx={{ padding: { xs: '10px', sm: '10px', md: '10px', lg: '0px', xl: '0px', }, minHeight: { md: '100vh' } }} >
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: "'Poppins', sans-serif",
                        padding: { xs: '10px', sm: '10px', md: '10px', lg: '0px', xl: '0px', },
                    }} >
                        <Typography sx={{
                            fontSize: { xs: '25px', sm: '25px', md: '25px', lg: '35px', xl: '35px', },
                            paddingTop: { xs: '12px', sm: '15px', md: '20px', lg: '30px', xl: '30px', },
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: "bold",
                            color: '#8AEBFE',
                            textAlign: 'center',
                        }} > Unleash the Power of KnoCard Engage: Your Ultimate Networking Dynamo </Typography>
                        <Typography sx={{
                            color: "#fff",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: { xs: '17px', sm: '17px', md: '17px', lg: '19px', xl: '19px', },
                            textAlign: 'center',
                        }} > Beyond Recruiting - A New Era of All-in-One Business Tools</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container sx={{
                            padding: {
                                xs: '10px 0px',
                                sm: '10px 0px',
                                md: '50px 0px 0px 0px',
                                lg: '50px 0px 0px 0px',
                                xl: '50px 0px 0px 0px',
                            }
                        }} >
                            <Grid item xs={0} sm={0} md={0} lg={2.4} xl={2.4} sx={{
                                display: {
                                    xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block'
                                }
                            }} ></Grid>
                            {
                                Section9List.map((x, index) => {
                                    return (
                                        <>
                                            {
                                                x.padding === true ?
                                                    <Grid item xs={12} sm={12} md={4} lg={2.4} xl={2.4} key={index} sx={{
                                                        marginBottom: {
                                                            xs: '10px',
                                                            sm: '10px',
                                                            md: '10px',
                                                            lg: '0px',
                                                            xl: '0px',
                                                        },

                                                    }}  >
                                                        <Box sx={{
                                                            padding: "10px 20px",
                                                            textAlign: "center",
                                                        }} >
                                                            <Typography sx={{
                                                                color: '#8AEBFE',
                                                                fontFamily: "'Poppins', sans-serif",
                                                                fontSize: { xs: "20px", sm: "20px", md: "21px", lg: "23px", lx: "23px" },
                                                            }} >{x.title}</Typography>
                                                            <Typography sx={{
                                                                color: "#fff",
                                                                fontSize: { xs: '16px', sm: '16px', md: '16px', lg: '18px', xl: '18px' },
                                                                fontFamily: "'Poppins', sans-serif",
                                                                paddingTop: '10px'
                                                            }} >
                                                                {x.text}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    :
                                                    <Grid item xs={12} sm={12} md={4} lg={2.4} xl={2.4} key={index} sx={{
                                                        marginBottom: {
                                                            xs: '10px',
                                                            sm: '10px',
                                                            md: '10px',
                                                            lg: '0px',
                                                            xl: '0px',
                                                        },
                                                        paddingTop: {
                                                            xs: '0px',
                                                            sm: '0px',
                                                            md: '30px',
                                                            lg: '40px',
                                                            xl: '40px',
                                                        }
                                                    }}  >
                                                        <Box sx={{
                                                            padding: "10px 20px",
                                                            textAlign: "center",
                                                        }} >
                                                            <Typography sx={{
                                                                color: '#8AEBFE',
                                                                fontFamily: "'Poppins', sans-serif",
                                                                fontSize: { xs: "20px", sm: "20px", md: "21px", lg: "23px", lx: "23px" },
                                                            }} >{x.title}</Typography>
                                                            <Typography sx={{
                                                                color: "#fff",
                                                                fontSize: { xs: '16px', sm: '16px', md: '16px', lg: '18px', xl: '18px' },
                                                                fontFamily: "'Poppins', sans-serif",
                                                                paddingTop: '10px'
                                                            }} >
                                                                {x.text}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                            }

                                        </>

                                    )
                                })
                            }

                            <Grid item xs={0} sm={0} md={0} lg={2.4} xl={2.4} sx={{
                                display: {
                                    xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block'
                                }
                            }} ></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Box className={styles.section_10_outer} sx={{
                padding: {
                    xs: '10px 15px',
                    sm: '10px 15px',
                    md: '15px 35px',
                    lg: '15px 50px',
                    xl: '15px 70px',
                }
            }} >
                <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={12}  >
                        <Typography sx={{
                            fontSize: { xs: '20px', sm: '20px', md: '20px', lg: '30px', xl: '30px' },
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: "bold",
                            textAlign: "center"
                        }} > Multiply Your Earnings with KnoCard Share & Earn </Typography>
                        <Typography sx={{
                            fontSize: { xs: '17px', sm: '17px', md: '17px', lg: '19px', xl: '19px' },
                            pt: 2,
                            textAlign: 'center',
                            pb: { xs: 4, sm: 4 }
                        }} >Unlock Endless Opportunities Beyond Just Sales!</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3.5} lg={3} xl={2} sx={{ padding: '0 20px', display: "flex", flexDirection: 'column', justifyContent: "space-around", alignItems: "center" }} >
                        {
                            Section10List.map((x, i) => {
                                return (
                                    x.label === 'vertical' ?
                                        <Box key={i} sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                            <Box sx={{ pt: { xs: 4, sm: 4 } }} >
                                                <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ display: "flex", justifyContent: "center" }} >
                                                        <img src={x.img} alt="" />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} >
                                                        <Box sx={{
                                                            display: {
                                                                xs: "block", sm: "block", md: 'flex', lg: "flex", xl: "flex"
                                                            },
                                                            alignItems: {
                                                                md: 'end', lg: "end", xl: "end"
                                                            }
                                                        }} >
                                                            <Typography sx={{
                                                                fontSize: { xs: '22px', sm: '22px', md: '22px', lg: '22px', xl: '22px' },
                                                                fontFamily: "'Poppins', sans-serif",
                                                                fontWeight: "bold",
                                                                textAlign: "center",
                                                                pt: {
                                                                    md: 8,
                                                                    lg: 8,
                                                                    xl: 8
                                                                },
                                                                pl: {
                                                                    md: 2,
                                                                    lg: 2,
                                                                    xl: 2
                                                                }
                                                            }} >{x.title}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Typography sx={{ textAlign: "center", fontFamily: "'Poppins', sans-serif" }} >
                                                {x.text}
                                            </Typography>
                                        </Box>
                                        :
                                        <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "center", pt: { xs: 4, sm: 4 } }} >

                                            <img src={x.img} alt="" />
                                            <Typography sx={{
                                                color: "#07ACDF",
                                                fontSize: { xs: '22px', sm: '22px', md: '22px', lg: '22px', xl: '22px' },
                                                fontFamily: "'Poppins', sans-serif",
                                                fontWeight: "bold",
                                                textAlign: "center"
                                            }} >{x.title}</Typography>
                                            <Typography sx={{
                                                textAlign: "center",
                                                fontFamily: "'Poppins', sans-serif",
                                            }} > {x.text}</Typography>
                                        </Box>
                                )
                            })

                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={8.5} lg={9} xl={10} >
                        <Grid container>
                            <Grid item xs={3} sm={3} md={3} lg={3} sx={{
                                display: "flex",
                                justifyContent: "end"
                            }} >
                                <img src="/static/referrel_two/Section10/dollar_line.png" alt="" style={{
                                    width: "100%"
                                }} />
                            </Grid>
                            <Grid item xs={9} sm={9} md={9} lg={9} sx={{ pt: 2 }} >
                                <img src="/static/referrel_two/Section10/section_10_side_3.png" style={{
                                    width: "100%"
                                }} />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Box>


            <Box className={styles.section_11_outer} sx={{ pt: 14 }} >
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                    }} >
                        <Typography sx={{
                            color: "#08ABDF",
                            textAlign: 'center',
                            fontSize: { xs: '25px', sm: '25px', md: '25px', lg: '35px', xl: '35px' },
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: "bold",
                        }} >Boost Your Confidence</Typography>
                        <Typography sx={{
                            textAlign: 'center',
                            fontSize: { xs: '17px', sm: '17px', md: '17px', lg: '19px', xl: '19px' },
                            fontFamily: "'Poppins', sans-serif",
                        }} >Focus exclusively on those who are genuinely interested</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ pt: 5, pb: 8 }}>
                        <Grid container>
                            <Grid item xs={0} sm={0} md={1} lg={1} sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block', } }} ></Grid>
                            {
                                Section11List.map((x, index) => {
                                    return (
                                        <>
                                            {
                                                index === 2 ?
                                                    <Grid item xs={12} sm={12} md={3.3} lg={3.3} >
                                                        <Grid container>
                                                            <Grid item xs={12} sm={12} md={4.5} lg={4.5} sx={{
                                                                display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: 'column',
                                                                pt: {
                                                                    xs: 1, sm: 1, md: 10, lg: 10, xl: 10
                                                                }
                                                            }}>
                                                                <img src={x.icon} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={7.5} lg={7.5} sx={{
                                                                padding: "10px",
                                                                display: "flex",
                                                                justifyContent: 'space-around',
                                                                alignItems: "center",
                                                                flexDirection: 'column',
                                                            }}>
                                                                <Typography sx={{
                                                                    color: "#08ABDF",
                                                                    textAlign: 'center',
                                                                    fontSize: { xs: '20px', sm: '20px', md: '14px', lg: '20px', xl: '20px' },
                                                                    letterSpacing: '1px',
                                                                    fontFamily: "'Poppins', sans-serif",
                                                                    fontWeight: "bold",
                                                                }} >{x.title}</Typography>
                                                                <Typography sx={{
                                                                    color: "#000",
                                                                    textAlign: 'center',
                                                                    fontSize: { xs: '16px', sm: '15px', md: '15px', lg: '17px', xl: '17px' },
                                                                    letterSpacing: '1px',
                                                                    fontFamily: "'Poppins', sans-serif",
                                                                }} >{x.text}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    <Grid item xs={12} sm={12} md={3.3} lg={3.3} sx={{ padding: "10px 10px 10px 10px" }}>
                                                        <Grid container>
                                                            <Grid item xs={12} sm={12} md={4.5} lg={4.5} sx={{
                                                                display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: 'column',
                                                                pt: {
                                                                    xs: 1, sm: 1, md: 10, lg: 10, xl: 10
                                                                }
                                                            }} >
                                                                <img src={x.icon} alt='' />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={7.5} lg={7.5} sx={{
                                                                padding: "10px",
                                                                display: "flex",
                                                                justifyContent: 'space-around',
                                                                alignItems: "center",
                                                                flexDirection: 'column',
                                                            }} >
                                                                <Typography sx={{
                                                                    color: "#08ABDF",
                                                                    textAlign: 'center',
                                                                    fontSize: { xs: '20px', sm: '20px', md: '14px', lg: '20px', xl: '20px' },
                                                                    letterSpacing: '1px',
                                                                    fontFamily: "'Poppins', sans-serif",
                                                                    fontWeight: "bold",
                                                                }} >{x.title}</Typography>
                                                                <Typography sx={{
                                                                    color: "#000",
                                                                    textAlign: 'center',
                                                                    fontSize: { xs: '16px', sm: '15px', md: '15px', lg: '17px', xl: '17px' },
                                                                    letterSpacing: '1px',
                                                                    fontFamily: "'Poppins', sans-serif",
                                                                }} >{x.text}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </>
                                    )
                                })
                            }
                            <Grid item xs={0} sm={0} md={1} lg={1} sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block', } }} ></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Box className={styles.section_12_outer} sx={{ pt: 4 }} >
                <Box className={styles.section_12_overlay}  >
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            pb: {
                                xs: 4,
                                sm: 4,
                                md: 6,
                                lg: 6,
                                xl: 6
                            }
                        }} >
                            <Typography sx={{
                                color: '#07ACE1',
                                fontSize: { xs: '25px', sm: '25px', md: '25px', lg: '35px', xl: '35px', },
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 'bold',
                                letterSpacing: '1px',
                                textAlign: 'center',
                            }} > Join KnoCard Engage Now </Typography>
                            <Typography sx={{
                                color: '#000',
                                fontSize: { xs: '17px', sm: '17px', md: '17px', lg: '19px', xl: '19px', },
                            }} >Elevate your network marketing game.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '0 14px'
                        }} >
                            {
                                Section12List.map((x, i) => {
                                    return (
                                        <>
                                            {
                                                x.label === 'left' ?
                                                    <Box sx={{
                                                        display: 'flex', alignItems: 'end', justifyContent: "space-between", pt: { xs: 3, sm: 3, md: 0, lg: 0 },
                                                        pb: { xs: 2, sm: 2, md: 5, lg: 5, xl: 5 }
                                                    }} >
                                                        <Grid container>
                                                            <Grid item xs={12} sm={12} md={0} lg={0} xl={0} sx={{
                                                                display: {
                                                                    xs: "block", sm: "block", md: 'none', lg: 'none', xl: 'none'
                                                                }
                                                            }} >
                                                                <Box sx={{ pl: 3, display: "flex", justifyContent: "center" }} >
                                                                    <img src={x.icon} alt={`icon - ${i + 1}`} />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                                                <Box sx={{ textAlign: "center" }} >
                                                                    <Typography sx={{
                                                                        fontSize: { xs: '20px', sm: '20px', md: '20px', lg: '22px', xl: '22px', },
                                                                        fontFamily: "'Poppins', sans-serif",
                                                                        fontWeight: 'bold',
                                                                        textAlign: 'center'
                                                                    }} >{x.title}</Typography>
                                                                    <Typography sx={{
                                                                        fontSize: { xs: '15px', sm: '15px', md: '15px', lg: '15px', xl: '15px', },
                                                                        fontFamily: "'Poppins', sans-serif",
                                                                    }} >{x.text}</Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={0} sm={0} md={3} lg={3} xl={3} sx={{
                                                                display: {
                                                                    xs: "none", sm: "none", md: 'block', lg: 'block', xl: 'block'
                                                                }
                                                            }} >
                                                                <Box sx={{ pl: 3, display: "flex", justifyContent: "center" }} >
                                                                    <img src={x.icon} alt={`icon - ${i + 1}`} />
                                                                </Box>
                                                            </Grid>
                                                        </Grid>


                                                    </Box>
                                                    :
                                                    <Box sx={{
                                                        display: 'flex', alignItems: 'end',
                                                        pt: {
                                                            xs: 3, sm: 3, md: 0, lg: 0,
                                                        }
                                                    }}  >
                                                        <Grid container>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                                                <Box sx={{ pr: 3, display: "flex", justifyContent: 'center' }} >
                                                                    <img src={x.icon} alt={`icon - ${i + 1}`} />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                                                <Box sx={{ display: "flex", justifyContent: 'center', flexDirection: "column", alignItems: "center" }} >
                                                                    <Typography sx={{
                                                                        fontSize: { xs: '20px', sm: '20px', md: '20px', lg: '22px', xl: '22px' },
                                                                        fontFamily: "'Poppins', sans-serif",
                                                                        fontWeight: 'bold',
                                                                        textAlign: 'center'
                                                                    }} >{x.title}</Typography>
                                                                    <Typography sx={{
                                                                        textAlign: 'center',
                                                                        fontSize: { xs: '15px', sm: '18px', md: '15px', lg: '15px', xl: '15px', },
                                                                        fontFamily: "'Poppins', sans-serif",
                                                                    }} >{x.text}</Typography>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>


                                                    </Box>
                                            }
                                        </>
                                    )
                                })
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 4, }} >
                            <Box sx={{ cursor: "pointer" }} >
                                <a href={referredByLink}><img src="/static/referrel_two/Section12/join_button.png" alt="" /></a>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

        </>
    );
};

export default ReferLandingSectionTwo;