import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';

import { Transition } from './Transition';
import { useAppSelector } from '../store';

import { Box, Stack, Typography } from '@mui/material';
import { config } from '../utils/utils';

interface IProps {
  toggle: boolean;
  onClose: () => void;
}

const ReferModel: React.FC<IProps> = ({ toggle, onClose }) => {
  const text2 = 'Nielson';
  const video_logo = '/static/images/video.png';
  const apple = '/static/images/apple-download.png';
  const android = '/static/images/android-download.png';
  const profile1 = '/static/images/profile1.png';
  const profile2 = '/static/images/profile2.PNG';
  const profile3 = '/static/images/profile3.png';

  const userInfo = useAppSelector((state) => state.userInfo.data);

  const handleGetKnoCard = () => {
    try {

      let refferLink = `${config.VBC_URL}`;

      if (userInfo?.username) {
        refferLink += `/affiliate/${userInfo?.username}`;
      } else {
        refferLink += "/register";
      }

      window.open(`${refferLink}`) || window.location.replace(`${refferLink}`);


    } catch (e) { }
  };

  return (
    <Dialog
      fullScreen
      open={toggle}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onClose();
      }}
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogContent sx={{ padding: 0 }}>
        <Box
          sx={{
            textAlign: 'center',
            overflow: 'auto',
            backgroundImage: "linear-gradient(#0A2633, #0F3F5A)"
          }}
        >
          <Box className='head-text' sx={{ pt: 1.2, pb: 1.2, backgroundImage: "linear-gradient(to right, #0A2633, #0F3F5A)" }}>
            <Typography variant='h3' sx={{ color: "#ffffff" }}  >
              I WANT TO
              <span className='color-change'>
                <Typography variant='h3' sx={{ color: "#4DE0F3" }} >
                  REFER
                </Typography>
              </span>
              <Typography variant='h3' >
                Your Business!
              </Typography>
            </Typography>

          </Box>

          <Box className="box" sx={{ mt: 4, borderRadius: "20px", mb: 3, backgroundImage: "linear-gradient(to right, #0A2633, #0F3F5A)", border: "solid 2px #B3CFD2" }}>
            <Box component={"h1"} sx={{ p: 3 }} >
              "92% of consumers trust referrals from people they know"
              <Box>
                <Box component={"span"} sx={{ color: "#ffffff" }}>-{text2}</Box>
              </Box>
            </Box>
          </Box>

          <div className='video'>
            <h1>
              What is a Preferred
              <div>
                <span>Partner?</span>
              </div>
            </h1>
            <img src={video_logo} alt='Watch Now' className='videoImage'></img>
          </div>
          <div className='buttons'>

            <Stack alignItems={'center'} alignContent={'center'} flexDirection={'column'} sx={{ mt: 3, mb: 3 }}>
              <Typography sx={{ borderRadius: "50px", color: "#ffffff", backgroundColor: "#FC6208", width: "fit-content", fontWeight: "500", pt: 1, pb: 1, pl: 2, pr: 2 }} onClick={handleGetKnoCard}>Get KnoCard</Typography>
            </Stack>

            <Box component={"h1"} sx={{ color: "#4DE0F3" }}>
              I am using an app that allows me to spotlight YOUR business and
              services.
            </Box>
            <Box sx={{ color: "#ffffff", textAlign: "center", fontSize: "16px", fontFamily: "sans-serif", fontWeight: "bold" }}>
              Check out the video above.
            </Box>
          </div>

          <Stack alignItems="center" sx={{ mt: 2, backgroundImage: "linear-gradient(to right, #0A2633, #0F3F5A)", borderTop: "solid 2px #B3CFD2", borderRadius: "40px", pt: 2 }}>
            <Stack className='review' alignItems="center" gap={2}>
              <div className='member'>
                <img src={profile1}></img>
                <h2>
                  The KnoCard has helped me close over a million dollars in sales.
                  Its a no brainer
                  <div>
                    <span>- Steve , California</span>
                  </div>
                </h2>
              </div>
              <div className='member'>
                <img src={profile2}></img>
                <h2>
                  “Wow, that’s the coolest thing… how can I get one?”
                  <div>
                    <span>- Karen W, Massachusetts</span>
                  </div>
                </h2>
              </div>
              <div className='member'>
                <img src={profile3}></img>
                <h2>
                  After I saw the KnoCard, my only questions was. Why didn't someone create this sooner?
                  <div>
                    <span>- Joseph P, Ohio</span>
                  </div>
                </h2>
              </div>
            </Stack>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog >
  );
};
export default ReferModel;
