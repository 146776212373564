import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Button, Divider, Fab, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/system";
import { Transition } from "../Transition";
import { useAppDispatch, useAppSelector } from "../../store";
import React, { useEffect, useRef, useState } from "react";
import { CompanyFeed, CompanyFeedComments } from '../../store/social/api/interface';
import SendIcon from '@mui/icons-material/Send';
import moment from "moment";
import CloseIcon from '@mui/icons-material/Close';
import { AppColors, config } from "../../utils/utils";
import { showSuccessMessage } from "../../utils/utilMethods";
import CancelIcon from '@mui/icons-material/Cancel';

interface IProps {
    toggle: boolean;
    onClose: () => void;
    companyFeed: CompanyFeed;
}
const CompanyFeedShareLinkModal: React.FC<IProps> = ({ toggle, onClose, companyFeed }) => {

    const loginUserInfo = useAppSelector((state) => state?.auth?.user);

    const getSocialPostShareLink = () => {
        return `${config.VBC_URL}/${companyFeed.company?.owner?.username ?? ""}/community/${companyFeed.id}${loginUserInfo && loginUserInfo.referece_code != null && loginUserInfo.referece_code.length > 0 ? `?sb=${loginUserInfo.referece_code}` : ''}`
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(getSocialPostShareLink());
        showSuccessMessage('Social post link copied successfully');
    }

    return (
        <>
            <Dialog
                fullWidth
                open={toggle}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    onClose();
                }}
                PaperProps={{
                    style: {
                        maxHeight: '50%'
                    }
                }}

            >
                <DialogTitle
                    sx={{
                        color: AppColors.blackColor,
                    }}
                >
                    Share social post link
                    <IconButton
                        aria-label='close'
                        onClick={onClose}
                        sx={{ position: 'absolute', right: 0, zIndex: 100000, mr: 1, color: AppColors.grayTwoColor }}
                    >
                        <CancelIcon />
                    </IconButton>
                </DialogTitle>


                <DialogContent sx={{ padding: '10px' }}>
                    <Stack
                        divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
                    >
                        <Grid container sx={{ paddingTop: '10px' }}>
                            <Grid item sm={9} xs={9} pr={2}>
                                <TextField id="outlined-link" value={getSocialPostShareLink()} fullWidth disabled />
                            </Grid>
                            <Grid sm={3} xs={3} container
                                direction="row"
                                justifyContent="center"
                                alignItems="center" >

                                <LoadingButton
                                    type="button"
                                    loading={false}
                                    variant="contained"
                                    onClick={handleCopyLink}
                                >
                                    Copy Link
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Stack>
                </DialogContent>


            </Dialog >
        </>
    );
};

export default CompanyFeedShareLinkModal;
