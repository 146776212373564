import * as Yup from 'yup';

const reviewFormSchema = Yup.object().shape({
  first_name: Yup.string().trim().required('First name is required.'),
  last_name: Yup.string().trim().required('Last name is required.'),
  phone_number: Yup.string().trim().matches(/^\+?[0-9\s]+$/, 'Mobile number must contain only digits')
    .min(8, 'Mobile number is too short')
    .max(15, 'Mobile number is too long').required('Mobile number is required.'),
  email: Yup.string().email('Invalid email'),
  experience: Yup.number().min(1, 'Overall Experience must be at least 1').max(10, 'Overall Experience must be at most 10').required('Overall Experience is required'),
  rating: Yup.number().min(1, 'Rating must be at least 1').max(5, 'Rating must be at most 5').required('Rating is required'),
  recommend: Yup.number().required('Please select Yes or No'),
  message: Yup.string().trim().required('Comment is required.'),
  agree_to_publish: Yup.number().required('Please agree.').oneOf([1], 'Please agree.'),
});
export default reviewFormSchema;
