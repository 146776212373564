import { STATUS_TYPE } from '../../../enum';

export interface PricingVideoRespose {
  status: string;
  data: PricingVideo[];
}

export interface PricingVideo {
  id: number;
  user_id: number;
  title: string;
  position: number;
  type: string;
  link: string;
  filename: string;
  thumb_name: string;
  thumb_link: string;
}


interface InitialState {
  data: PricingVideo[];
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

export const initialState: InitialState = {
  data: [],
  status: 'idle',
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
};
