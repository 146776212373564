import { createSlice } from '@reduxjs/toolkit';
import { getCompanyPinSocialFeeds, getSocialFeeds } from './api';
import { initialState } from './api/interface';

const socialFeedSlice = createSlice({
  name: 'social',
  initialState,
  reducers: {
    reset: () => initialState,
    socialFeedDeleted: (state, action) => {
      let array = [...(state.data ?? [])];
      let position = -1;
      array.forEach((item, index) => {
        if (item.id == action.payload.id) {
          position = index;
        }
      });

      if (position !== -1 && state.data != null) {
        array.splice(position, 1);
        state.data = array;
      }
    },
    socialFeedUpdated: (state, action) => {
      state.data.forEach((item, index) => {
        if (action.payload.id == item.id) {
          state.data[index] = action.payload;
        }
      });

      state.pinFeeds.forEach((item, index) => {
        if (action.payload.id == item.id) {
          state.pinFeeds[index] = action.payload;
        }
      });

    },
    socialFeedCreated: (state, action) => {
      state.data.unshift(action.payload);
    },
    socialFeedPinned: (state, action) => {
      state.pinFeeds.unshift(action.payload);
    },
    socialFeedUnpinned: (state, action) => {
      let array = [...(state.pinFeeds ?? [])];
      let position = -1;
      array.forEach((item, index) => {
        if (item.id == action.payload.id) {
          position = index;
        }
      });

      if (position !== -1 && state.pinFeeds != null) {
        array.splice(position, 1);
        state.pinFeeds = array;
      }
    },
    shareSocialFeedMessage: (state, action) => {
      state.shareSocialPostMessage = action.payload.message;
      state.shareSocialPost = action.payload.socialPost;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSocialFeeds.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getSocialFeeds.fulfilled, (state, action) => {
      state.data = [...((action.payload.data.data.current_page == 1) ? [] : state.data ?? []), ...action.payload.data.data.data];
      state.isLoadMore = action.payload.data.data.data.length >= 10;
      state.page = action.payload.data.data.current_page + 1;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getSocialFeeds.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });

    builder.addCase(getCompanyPinSocialFeeds.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getCompanyPinSocialFeeds.fulfilled, (state, action) => {
      state.pinFeeds = [
        ...((action.payload.data.data.current_page == 1) ? [] : state.pinFeeds ?? []),
        ...action.payload.data.data.data,
      ];
      state.pinFeedIsLoadMore = action.payload.data.data.data.length >= 10;
      state.pinFeedPage = action.payload.data.data.current_page + 1;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getCompanyPinSocialFeeds.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const {
  reset,
  socialFeedDeleted,
  socialFeedUpdated,
  socialFeedCreated,
  socialFeedPinned,
  socialFeedUnpinned,
  shareSocialFeedMessage
} = socialFeedSlice.actions;
export default socialFeedSlice.reducer;
