import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { KnoCardLinkSessionRequestResponse, PatchKnoCardLinkSessionRequest, PatchKnoCardLinkSessionResponse } from './interface';

export const getUserListKnoCardLinkSessionRequest = createAsyncThunk('knocardLinkSession/getUserListKnoCardLinkSessionRequest', async (data: { page: number }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get<KnoCardLinkSessionRequestResponse>(`/api/knocard-link-session?page=${data.page}`);
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const patchKnoCardLinkSessionRequest = createAsyncThunk('knocardLinkSession/patchKnoCardLinkSessionRequest', async (data: PatchKnoCardLinkSessionRequest, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post<PatchKnoCardLinkSessionResponse>(`/api/knocard-link-session/update/${data.id}`, data);
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});
