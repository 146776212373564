import { STATUS_TYPE } from '../../../enum';
import { UserInfo } from '../../userInfo/api/interface';

export interface FindCommunityUsersRequestData {
  from_lat: string;
  from_lng: string;
  filter_keyword: string;
  filter_distance: string;
  sort_by: string;
  page: number;
}

export interface IsFollowingResponse {
  status?: string;
  is_following?: boolean;
}

export interface FollowUnfollowResponse {
  status?: string;
  message?: string;
}


export interface CommunityUsersResponse {
  status: string;

  data: {
    current_page: number;
    data: UserInfo[]
  };
}


interface InitialState {
  data: UserInfo[];
  selectedMember?: UserInfo;
  searchKeyWord: string;
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  page: number;
  isLoadMore: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

export const initialState: InitialState = {
  data: [],
  searchKeyWord: '',
  selectedMember: undefined,
  status: 'idle',
  isError: false,
  isLoading: false,
  page: 1,
  isLoadMore: false,
  isSuccess: false,
  errorMessage: '',
};
