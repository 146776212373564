import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Radio, styled } from '@mui/material';
import styles from "../../styles/EngageItem.module.css";

import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import moment from 'moment';

import { EngageUserActivityReportItem } from '../../store/engage/api/interface';
import { getActivityIconAndTitle } from '../../constant/activity_icon_and_title';
import { AppColors } from '../../utils/utils';

const RadioButton = styled(Radio)({
    color: "#fff",
    "&.Mui-checked": {
        color: "#fff",
    },
});




interface IProps {
    activityReportItem: EngageUserActivityReportItem;
    index: number;

}

const EngageActivityReportItem: React.FC<IProps> = ({
    activityReportItem,
    index,
}) => {
    const activityIconAndTitle = getActivityIconAndTitle(activityReportItem.module ?? '');

    return (
        <div className='w-100'>

            <Card
                key={index}
                elevation={0}
                sx={{
                    width: '100%',
                    background: AppColors.grayZeroColor,
                    mb: 3,
                    border: `1px solid ${AppColors.strokeColor}`,
                    padding: "18px"
                }}
            >
                <CardContent
                    sx={{
                        padding: '0px 0px 0px 0px!important'
                    }}
                >
                    <Stack direction={'row'}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {activityIconAndTitle.actionIcon}
                        </Box>


                        <Box flexDirection={'column'} sx={{
                            width: "100%",
                            ml: 1,
                            mr: 1
                        }}>
                            <Typography sx={{ color: AppColors.blackColor }}>{activityIconAndTitle.actionTitle} &nbsp;&nbsp; {activityReportItem.total ?? 0} Views</Typography>

                            <Typography sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "0.8rem",
                                pr: '5px',
                                color: AppColors.grayTwoColor
                            }}>Last View {moment(`${activityReportItem.created_at}`).local().format("DD MMM, YYYY hh:mm A")} </Typography>
                        </Box>


                    </Stack>

                </CardContent>
            </Card>
        </div>
    );
};

export default EngageActivityReportItem;
