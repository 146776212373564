import { axiosInstance } from '../../../client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Values } from './interface';
import { toastHandler } from '../../../utils/toastHandler';

export const authenticateUser = createAsyncThunk(
  'auth/authenticateUser',
  async (values: Values, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/api/user/login', values);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const logoutUser = createAsyncThunk('auth/logoutUser', async (_, { }) => {

});

export const getUserInfo = createAsyncThunk(
  'user/singleUser',
  async (
    values: {
      keyword: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        '/api/user/single-user',
        values
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getProfile = createAsyncThunk(
  'user/getProfile',
  async (
    values: {
      user_id: number;
      viewer_id: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post('/api/user', values);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updatePassword = createAsyncThunk(
  'user/updatePassword',
  async (
    values: {
      api_token: string;
      user_id: number;
      password: string;
      confirmed_password: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        '/api/user/profile/reset-password',
        values
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const forgotPassword = createAsyncThunk(
  'user/forgotPassword',
  async (
    values: {
      username: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        '/api/user/forgot-password',
        values
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const changeForgotPassword = createAsyncThunk(
  'user/changeForgotPassword',
  async (
    values: {
      token: string;
      otp: string;
      password: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        '/api/user/forgot-password/change',
        values
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const updateNewToKnoCard = createAsyncThunk(
  'user/updateNewToKnoCard',
  async (
    values: {
      new_to_knocard: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        '/api/user/new-to-knocard',
        values
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const profileShareActionReport = createAsyncThunk(
  'user/profileShareActionReport',
  async (
    values: {
      user_id: number,
      contact_id: number,
      share_code: string
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(
        '/api/contact/share',
        values
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);



export const supportTicket = createAsyncThunk(
  'user/supportTicket',
  async (
    values: {
      description: string,
      subject: string,
      email: string,
      priority: number,
      status: number,
      type: string,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        '/api/support/ticket',
        values,
        {
          timeout: 50000
        }
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

