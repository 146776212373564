import { createSlice } from '@reduxjs/toolkit';
import { getHelpSupportVideo, getHelpSupportVideoCategory } from './api';
import { initialState } from './api/interface';

const helpSupportVideoSlice = createSlice({
  name: 'helpSupportVideo',
  initialState,
  reducers: {
    reset: () => initialState,
    updateSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    clearVideoList: (state, action) => {
      state.videoList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHelpSupportVideoCategory.pending, (state) => {
      state.isCategoryLoading = true;

    });
    builder.addCase(getHelpSupportVideoCategory.fulfilled, (state, action) => {
      state.categoryList = action.payload?.data?.data ?? [];
      state.isCategoryLoading = false;
    });
    builder.addCase(getHelpSupportVideoCategory.rejected, (state, _) => {
      state.isLoading = false;
    });

    builder.addCase(getHelpSupportVideo.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getHelpSupportVideo.fulfilled, (state, action) => {
      if ((action?.payload?.data?.data?.current_page ?? 0) == 1) {
        state.videoList = action.payload.data?.data?.data ?? [];
      } else {
        state.videoList.push(...(action.payload.data?.data?.data ?? []));
      }

      state.page = action?.payload?.data?.data?.current_page ?? 0

      state.hasMore = ((action.payload.data?.data?.last_page ?? 0) > (action.payload?.data?.data?.current_page ?? 0))

      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getHelpSupportVideo.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset, updateSearchText, clearVideoList } = helpSupportVideoSlice.actions;
export default helpSupportVideoSlice.reducer;

