import { createSlice } from '@reduxjs/toolkit';
import {
  getAllReportTotal,
  getKnoCardView,
  getLeadsView,
  getReferrals,
  getMediaSharePostReport,
  getShareSocialPosts,
  getWebAppShare,
  getMediaVideoCountView,
  getAffiliateLandingPageVideoView,
  getShareAffiliateLandingPageData,
  getEngageUserActivityCount
} from './api';
import { initialState } from './api/interface';

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload.status;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllReportTotal.pending, (state) => {
      state.allReportSummary = undefined;
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getAllReportTotal.fulfilled, (state, action) => {
      state.allReportSummary = action.payload.data.data ?? undefined;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getAllReportTotal.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });

    builder.addCase(getLeadsView.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getLeadsView.fulfilled, (state, action) => {
      state.leadViewData = {
        ...action.payload.data,
        leadViewData: [...action.payload.data.data],
      };
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getLeadsView.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });

    builder.addCase(getWebAppShare.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getWebAppShare.fulfilled, (state, action) => {
      let newData = [
        ...(action.payload.data.data.current_page == 1
          ? []
          : state.webAppShareData?.data ?? []),
        ...(action.payload.data.data.data ?? []),
      ];
      state.webAppShareData = {
        ...action.payload.data.data,
        data: newData,
      };
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getWebAppShare.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });

    builder.addCase(getReferrals.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getReferrals.fulfilled, (state, action) => {
      let newData = [
        ...(action.payload.data.data.current_page == 1
          ? []
          : state.referralsData?.data ?? []),
        ...(action.payload.data.data?.data ?? []),
      ];
      state.referralsData = {
        ...action.payload.data.data,
        data: newData,
      };
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getReferrals.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });

    builder.addCase(getShareSocialPosts.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getShareSocialPosts.fulfilled, (state, action) => {
      let newData = [
        ...(action.payload.data.data.current_page == 1
          ? []
          : state.shareSocialPostData?.data ?? []),
        ...(action.payload.data?.data?.data ?? []),
      ];
      state.shareSocialPostData = {
        ...action.payload.data.data,
        data: newData,
      };
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getShareSocialPosts.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });

    builder.addCase(getMediaVideoCountView.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getMediaVideoCountView.fulfilled, (state, action) => {
      state.videoViewReportData = {
        ...action.payload.data,
        videoViewReportData: [...action.payload.data?.data ?? []],
      };
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getMediaVideoCountView.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });




    builder.addCase(getAffiliateLandingPageVideoView.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getAffiliateLandingPageVideoView.fulfilled, (state, action) => {
      state.affiliateLandingPageVideoViewData = {
        ...action.payload.data,
        data: [...action.payload.data?.data ?? []],
      };
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getAffiliateLandingPageVideoView.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });

    builder.addCase(getMediaSharePostReport.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getMediaSharePostReport.fulfilled, (state, action) => {
      let newData = [
        ...(action.payload.data.data.current_page == 1
          ? []
          : state.mediaShareReportData?.data ?? []),
        ...(action.payload.data.data?.data ?? []),
      ];
      state.mediaShareReportData = {
        ...action.payload.data.data,
        data: newData,
      };
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getMediaSharePostReport.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });

    builder.addCase(getKnoCardView.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getKnoCardView.fulfilled, (state, action) => {
      state.knoCardViewData = {
        ...action.payload.data,
        knoCardViewData: [...action.payload.data?.data ?? []],
      };
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getKnoCardView.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });



    builder.addCase(getShareAffiliateLandingPageData.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getShareAffiliateLandingPageData.fulfilled, (state, action) => {
      let newData = [
        ...(action.payload.data.data.current_page == 1
          ? []
          : state.shareAffiliateLandingPageData?.data ?? []),
        ...(action.payload.data.data?.data ?? []),
      ];
      state.shareAffiliateLandingPageData = {
        ...action.payload.data.data,
        data: newData,
      };
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getShareAffiliateLandingPageData.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });

    builder.addCase(getEngageUserActivityCount.pending, (state) => {
      state.isEngageUserActivityCountLoading = true;
    });
    builder.addCase(getEngageUserActivityCount.fulfilled, (state, action) => {
      state.totalEngageUserActivity = action.payload.data.total_engage_user_activity || 0;

      state.isEngageUserActivityCountLoading = false;
    });
    builder.addCase(getEngageUserActivityCount.rejected, (state, _) => {
      state.isEngageUserActivityCountLoading = false;
    });



  },
});

export const { setStatus, reset } = reportsSlice.actions;
export default reportsSlice.reducer;
