import {
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useEffect, useState } from 'react';
import TabPanel from '../TabPanel';

import { useAppDispatch, useAppSelector } from '../../store';

import { getPreferredPartners } from '../../store/preferredPartner/api';

import InfiniteScroll from 'react-infinite-scroller';

import PreferredPartnerItem from '../Card/PreferredPartnerItem';
import { isNotEmpty } from '../../utils/valid';
import useToggle from '../../hooks/useToggle';
import FullscreenImageViewerDialog from '../modal/FullscreenImageViewerDialog';
import { PreferredPartnerContents } from '../../store/preferredPartner/api/interface';
import { AppColors } from '../../utils/utils';

interface IProps {
  value: number;
  index: number;
}

const PreferredPartnersTabPanel: React.FC<IProps> = ({ value, index }) => {
  const dispatch = useAppDispatch();
  const [selectMediaContents, setSelectMediaContents] = useState<any[]>([]);
  const [selectMediaIndex, setSelectMediaIndex] = useState(0);

  const {
    toggle: toggleFullscreenImageViewerDialog,
    onOpen: onOpenFullscreenImageViewerDialog,
    onClose: onCloseFullscreenImageViewerDialog,
  } = useToggle();

  const { isLoading, isLoadMore, page } = useAppSelector(
    (state) => state?.preferredPartner
  );
  const preferredPartners =
    useAppSelector(
      (state) => state?.preferredPartner?.data?.preferredPartners
    ) ?? [];

  const userId = useAppSelector((state) => state?.userInfo?.data?.id);

  useEffect(() => {
    if (userId) {
      dispatch(getPreferredPartners({ userId, pageNo: 1 }));
    }
  }, [dispatch, userId]);

  const handleLoadMore = (_page: number) => {
    if (userId && !isLoading) {
      dispatch(getPreferredPartners({ userId, pageNo: page }));
    }
  };

  return (
    <TabPanel
      value={value}
      index={index}
      p={0}
      sx={{ height: '100%' }}
    >
      <Stack sx={{ height: '100%' }}>
        <Box sx={{ zIndex: 100, pb: 2, height: '100%' }} >
          {isLoading && page === 1 ? (
            <Box
              display='flex'
              justifyContent='center' minHeight={'85vh'}
              pt={2}

            >
              <Stack>
                <CircularProgress />
              </Stack>
            </Box>
          ) : preferredPartners && isNotEmpty(preferredPartners) ? (
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              gap={3}
              sx={{
                '& > div:first-of-type': {
                  width: { md: '80%', xs: 'unset' }
                }
              }}
            >
              <InfiniteScroll
                pageStart={1}
                initialLoad={false}
                loadMore={handleLoadMore}
                hasMore={isLoadMore}
                loader={
                  <Box
                    display='flex'
                    justifyContent='center'
                    mt={2}
                  >
                    <CircularProgress />
                  </Box>
                }
              >
                {preferredPartners?.map((partner, feedIdx) => (
                  <PreferredPartnerItem
                    key={feedIdx}
                    partner={partner}
                    index={feedIdx}
                    showEditMenu={false}
                    onMediaSelect={(
                      contents: PreferredPartnerContents[],
                      index: number
                    ) => {
                      setSelectMediaContents(contents);
                      setSelectMediaIndex(index);
                      onOpenFullscreenImageViewerDialog();
                    }}
                  />
                ))}
              </InfiniteScroll>
              <FullscreenImageViewerDialog
                onClose={onCloseFullscreenImageViewerDialog}
                toggle={toggleFullscreenImageViewerDialog}
                index={selectMediaIndex}
                contents={selectMediaContents}
              />
            </Box>
          ) : (
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
            >
              <Typography mt={'50%'} sx={{ color: AppColors.blackColor }}>No preferred partners.</Typography>
            </Box>
          )}
        </Box>
      </Stack>
    </TabPanel>
  );
};

export default PreferredPartnersTabPanel;
