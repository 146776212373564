import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './api/interface';
import { getUserListKnoCardLinkSessionRequest } from './api';

const knocardLinkSessionSlice = createSlice({
  name: 'knocardLinkSession',
  initialState,
  reducers: {
    reset: () => initialState,
    sessionUpdated: (state, action) => {
      state.data.forEach((item, index) => {
        if (action.payload.id == item.id) {
          state.data[index].next_coaching = action.payload?.next_coaching;
          state.data[index].next_followup = action.payload?.next_followup;
        }
      })

    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserListKnoCardLinkSessionRequest.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getUserListKnoCardLinkSessionRequest.fulfilled, (state, action) => {
      state.data = action.payload?.data?.data?.data ?? [];
      state.page = action.payload?.data?.data?.current_page ?? 0;
      state.total = action.payload?.data?.data?.total ?? 0;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getUserListKnoCardLinkSessionRequest.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });

  },
});

export const { reset, sessionUpdated } = knocardLinkSessionSlice.actions;
export default knocardLinkSessionSlice.reducer;
