import { createSlice } from '@reduxjs/toolkit';
import { getProspectActivity } from './api';
import { initialState } from './api/interface';

const prospectActivitySlice = createSlice({
  name: 'prospectActivity',
  initialState,
  reducers: {
    reset: () => initialState,
    updateSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    clearProspectActivity: (state, action) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {


    builder.addCase(getProspectActivity.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getProspectActivity.fulfilled, (state, action) => {
      if ((action?.payload?.data?.data?.current_page ?? 0) == 1) {
        state.data = action.payload.data?.data?.data ?? [];
      } else {
        state.data.push(...(action.payload.data?.data?.data ?? []));
      }

      state.page = action?.payload?.data?.data?.current_page ?? 0

      state.hasMore = ((action.payload.data?.data?.last_page ?? 0) > (action.payload?.data?.data?.current_page ?? 0))

      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getProspectActivity.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset, updateSearchText, clearProspectActivity } = prospectActivitySlice.actions;
export default prospectActivitySlice.reducer;

