import { axiosInstance } from '../../../client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateContactRequest, CreateContactResponse } from './interface';
import { toastHandler } from '../../../utils/toastHandler';



export const createUpdateContact = createAsyncThunk(
  'contact/createUpdateContact',
  async (values: CreateContactRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<CreateContactResponse>(
        '/api/contact/save',
        values
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const deleteContact = createAsyncThunk(
  'contact/deleteContact',
  async (values: { contactId: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `/api/contact/delete/${values.contactId}`,
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);
