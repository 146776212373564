import React, { FC, useEffect, useState } from 'react';
import { Avatar, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';

import { useCollapse } from 'react-collapsed';
import styles from '../../styles/Reports.module.css';
import {
  GetMediaVideoCountViewDetailRequest,
  VideoViewsReport,
  VideoViewsReportItem,
  VideoViewsReport as VideoViewsReportModel,
} from '../../store/reports/api/interface';
import { dateFormate } from '../../utils/format';
import { AppDispatch, useAppSelector } from '../../store';

import { getDaysFromFilter } from '../../utils/daysConvertor';
import { getMediaVideoCountViewDetail } from '../../store/reports/api';
import { useDispatch } from 'react-redux';

import VideoViewsCounterItem from './VideoViewsCounterItem';
import { getItem } from '../../utils/localStorage';
import { AppColors, config } from '../../utils/utils';

interface IProps {
  shareSocialPostItemModel: VideoViewsReportItem;
  index: number;
  selectedTimeFilter: string;
  sortAs: string;
  selectedReportUser?: number;
}

const VideoViewsItem: React.FC<IProps> = ({
  shareSocialPostItemModel,
  index,
  selectedTimeFilter,
  sortAs,
  selectedReportUser,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);
  const accessToken = getItem(config.AUTH_KEY);
  const [isExpanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const [videoViewsPostCounts, setVideoViewsPostCounts] = useState<
    VideoViewsReportItem[] | null
  >(null);

  useEffect(() => {
    if (isExpanded && (videoViewsPostCounts?.length ?? 0) === 0) {
      fetchShareSocialPostDetail(false);
    }
  }, [isExpanded]);

  const fetchShareSocialPostDetail = async (isLoadMore: boolean) => {
    const data: GetMediaVideoCountViewDetailRequest = {
      user_id: selectedReportUser
        ? selectedReportUser
        : shareSocialPostItemModel?.postby_id ?? 0,
      viewer_id: shareSocialPostItemModel.user_id ?? 0,
      api_token: accessToken ?? '',
      days: getDaysFromFilter(selectedTimeFilter),
      timeline: sortAs,
      type: 'video',
    };
    try {
      setIsLoading(true);
      const response: any = await dispatch(
        getMediaVideoCountViewDetail(data)
      ).unwrap();
      if (
        response.status === 200 &&
        response.data.status &&
        response.data.status === 'success'
      ) {
        setVideoViewsPostCounts(response.data.data);
      }
    } catch (e) { }
    setIsLoading(false);
  };

  return (
    <Box style={{ width: '100%' }}>
      <Box
        className={styles.itemContainer}
        sx={{
          background: AppColors.grayZeroColor,
          border: `solid 1px ${AppColors.strokeColor}`,
          mt: 0.6, borderRadius: "6px"
        }}
        {...getToggleProps({
          onClick: () => {
            setExpanded(!isExpanded);
          },
        })}
      >
        <img
          alt='pic'
          src={
            shareSocialPostItemModel.viewable?.profile_picture ??
            shareSocialPostItemModel.viewable?.user?.profile_picture ??
            '/static/images/placeholder_profile.png'
          }
          className={styles.knoSmilie}
        />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "1rem", fontWeight: 400 }}>
            {shareSocialPostItemModel.viewable?.user?.name ??
              `${shareSocialPostItemModel.viewable?.first_name ?? ''} ${shareSocialPostItemModel.viewable?.last_name ?? ''
              }`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", color: AppColors.grayTwoColor }}>
            {`Viewed you videos  ${shareSocialPostItemModel.total ?? 0} times`}
          </Typography>
        </Box>

        {isExpanded ? (
          <img
            src='/static/images/arrow_down.svg'
            alt='chevron_down'
            className={styles.chevronDown}
            style={{ transform: `rotate(180deg)` }}
          />
        ) : (
          <img
            src='/static/images/arrow_down.svg'
            alt='chevron_down'
            className={styles.chevronDown}
          />
        )}
      </Box>
      <Box {...getCollapseProps()} sx={{ ml: 4 }}>
        {videoViewsPostCounts &&
          videoViewsPostCounts.length > 0 &&
          videoViewsPostCounts.map((item, index) => (
            <VideoViewsCounterItem
              shareSocialPostItemModel={item}
              index={index}
              selectedTimeFilter={selectedTimeFilter}
              sortAs={sortAs}
            />
          ))}
        {/* {videoViewsPostCounts &&
          (videoViewsPostCounts.current_page ?? 0) <
            (videoViewsPostCounts.last_page ?? 0) &&
          !isLoading && (
            <Stack direction={'row'} justifyContent={'center'} sx={{ m: 1 }}>
              <Button
                type='button'
                variant='contained'
                sx={{ m: 2, textTransform: 'initial' }}
                autoCapitalize={'none'}
                onClick={() => fetchShareSocialPostDetail(true)}
              >
                Load More
              </Button>
            </Stack>
          )} */}

        {isLoading && (
          <Stack
            direction={'row'}
            justifyContent={'center'}
            sx={{ m: 1 }}
          >
            {' '}
            <CircularProgress />
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default VideoViewsItem;
