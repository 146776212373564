import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import styles from '../styles/Reports.module.css';
import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { AppColors, config } from '../utils/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { checkIsProProOrProOneOrProPlusUser, checkIsProUser } from '../utils/checkPro';
import { formatReportCount, getPlanLabel, inputTextFieldStyle, showSuccessMessage } from '../utils/utilMethods';


import SearchIcon from '../assets/social/search_member.svg';
import { debounce } from 'lodash';
import { clearProspectActivity, updateSearchText } from '../store/ProspectActivity/prospectActivitySlice';
import { getProspectActivity } from '../store/ProspectActivity/api';
import { getDaysFromFilter } from '../utils/daysConvertor';
import { ProspectActivityItem } from '../store/ProspectActivity/api/interface';
import moment from 'moment';
import BlockOverlay from '../components/BlockOverlay';

const ProspectActivity = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);
  const { data, page, hasMore, isLoading, searchText } = useAppSelector((state) => state?.prospectActivity);
  const observer = useRef<IntersectionObserver>();
  const navigate = useNavigate();

  const [selectedTimeFilter, setSelectedTimeFilter] = useState('Month');
  const timeFilterList = ['Today', 'Week', 'Month', 'All Time'];

  const isProUser = checkIsProProOrProOneOrProPlusUser(user);


  const handleLoadMore = (isReload: boolean, searchKeyWord?: string) => {

    if (isProUser) {
      if (isReload) {
        dispatch(clearProspectActivity({}));
      }

      dispatch(getProspectActivity({ searchName: (searchKeyWord ?? searchText), pageNumber: isReload ? 1 : (page + 1), days: getDaysFromFilter(selectedTimeFilter) }));
    }
  };

  useEffect(() => {
    handleLoadMore(true);
  }, [selectedTimeFilter]);


  const handleChangeTimeFilter = (event: SelectChangeEvent) => {
    setSelectedTimeFilter(event.target.value as string);
  };


  const lastElementRef = useCallback(
    // (*)
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          handleLoadMore(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  const searchProspectActivity = (e: React.ChangeEvent<HTMLInputElement>) => {

    if ((e.target.value ?? "") == "" && data.length == 0) {
      handleLoadMore(true, "");
    }
    dispatch(updateSearchText(e.target.value ?? ""));


  };

  useEffect(() => {
    if (searchText) {
      handleLoadMore(true);
    }
  }, [searchText])

  const debounceWithSearch = debounce(searchProspectActivity, 700);

  const getProfileImage = (prospectActivityItem: ProspectActivityItem) => {

    var profilePicture = "";

    if (prospectActivityItem.user != null) {
      profilePicture = prospectActivityItem.user?.user?.profile_picture ?? "";
    } else if (prospectActivityItem.contact != null) {
      profilePicture = prospectActivityItem.contact?.user?.profile_picture ?? "";
    } else if (prospectActivityItem.share_to != null) {
      profilePicture = prospectActivityItem.share_to?.user?.profile_picture ?? "";
    } else if (prospectActivityItem.share_to_profile != null) {
      profilePicture = prospectActivityItem.share_to_profile?.user?.profile_picture ?? "";
    }

    if (profilePicture.trim().length == 0) {
      profilePicture = "/static/images/knocard.png";
    }

    return profilePicture;
  }


  const getUserFullName = (prospectActivityItem: ProspectActivityItem) => {
    var userName = "KnoCard";
    if (prospectActivityItem.user != null) {
      userName = `${prospectActivityItem.user?.first_name ?? ""} ${prospectActivityItem.user?.last_name ?? ""}`;
    } else if (prospectActivityItem.contact != null) {
      userName = `${prospectActivityItem.contact?.first_name ?? ""} ${prospectActivityItem.contact?.last_name ?? ""}`;
    } else if (prospectActivityItem.share_to != null) {
      userName = `${prospectActivityItem.share_to?.first_name ?? ""} ${prospectActivityItem.share_to?.last_name ?? ""}`;
    } else if (prospectActivityItem.share_to_profile != null) {
      userName = `${prospectActivityItem.share_to_profile?.first_name ?? ""} ${prospectActivityItem.share_to_profile?.last_name ?? ""}`;
    }
    return userName.trim();
  }


  const getUserFirstName = (prospectActivityItem: ProspectActivityItem) => {
    var userName = "KnoCard";
    if (prospectActivityItem.user != null) {
      userName = `${prospectActivityItem.user?.first_name ?? ""}`;
    } else if (prospectActivityItem.contact != null) {
      userName = `${prospectActivityItem.contact?.first_name ?? ""}`;
    } else if (prospectActivityItem.share_to != null) {
      userName = `${prospectActivityItem.share_to?.first_name ?? ""}`;
    } else if (prospectActivityItem.share_to_profile != null) {
      userName = `${prospectActivityItem.share_to_profile?.first_name ?? ""}`;
    }
    return userName.trim();
  }

  const handleViewActivity = (prospectActivityItem: ProspectActivityItem) => {
    navigate(`/dashboard/engage/pipeline?search=${getUserFirstName(prospectActivityItem)}`)
  }


  const getDateAndFeedbackInfo = (prospectActivityItem: ProspectActivityItem) => {
    return (<Grid container >
      <Grid item xs={4}>
        <Stack flexDirection={'column'} sx={{ textAlign: 'center' }}>
          <Typography
            sx={{ display: 'inline', fontSize: 'small', fontWeight: "500" }}
            component="span"
            variant="body2"
            color={AppColors.grayThreeColor}
          >
            Share Date
          </Typography>
          <Typography
            sx={{ display: 'inline', fontSize: 'small', pb: 0.5 }}
            component="span"
            variant="body2"
            color={AppColors.grayTwoColor}
          >
            {moment.utc(`${prospectActivityItem.created_at}`).local().format('MMM DD, YYYY  hh:mm a')}
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={4}>  <Stack flexDirection={'column'} sx={{ textAlign: 'center' }}>
        <Typography
          sx={{ display: 'inline', fontSize: 'small', fontWeight: "500" }}
          component="span"
          variant="body2"
          color={AppColors.grayThreeColor}
        >
          Engage Date
        </Typography>
        <Typography
          sx={{ display: 'inline', fontSize: 'small', pb: 0.5 }}
          component="span"
          variant="body2"
          color={AppColors.grayTwoColor}
        >
          {(prospectActivityItem.created_at != prospectActivityItem.updated_at) ? moment
            .utc(`${prospectActivityItem.updated_at}`)
            .local()
            .format('MMM DD, YYYY  hh:mm a') : "-"}
        </Typography>
      </Stack></Grid>

      <Grid item xs={4}> <Stack flexDirection={'column'} sx={{ textAlign: 'center' }}>
        <Typography
          sx={{ display: 'inline', fontSize: 'small', fontWeight: "500" }}
          component="span"
          variant="body2"
          color={AppColors.grayThreeColor}
        >
          Feedback
        </Typography>
        <Typography
          sx={{ display: 'inline', fontSize: 'small', pb: 0.5 }}
          component="span"
          variant="body2"
          color={AppColors.grayTwoColor}
        >
          {((prospectActivityItem?.form_answer ?? "").trim().length > 0) ? "Yes" : "No"}
        </Typography>
      </Stack></Grid>

    </Grid>);
  }

  return (
    <>
      <Page title='Prospect Activity | KnoCard' showAppBar={true}>
        <Container maxWidth='xl' sx={{ mt: 3, pl: { md: 2, xs: 1 }, pr: { md: 2, xs: 1 } }}>

          <Grid container>
            <Grid item xs={12}>
              <Box >
                <Typography className={styles.heading} sx={{ width: { xs: '100%', color: AppColors.blackColor } }}  >Prospect Activity</Typography>
                <Box display='flex' alignItems='center' gap={2} sx={{ mb: 2, mt: 2 }}>
                  <TextField
                    fullWidth
                    key={'search-name'}
                    placeholder={"Search Name"}
                    InputProps={{
                      sx: { ...inputTextFieldStyle(), borderRadius: '10px' },
                      type: 'search',
                      startAdornment: (<Fragment>
                        <Box component={'div'} sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',

                        }}>
                          <Box
                            component={'img'}
                            src={SearchIcon}
                            alt='search icon'
                            sx={{
                              width: '30px',
                              height: '30px',
                              mr: 1,
                              color: AppColors.primaryColor,
                            }}
                          />
                        </Box>
                      </Fragment >),
                    }}
                    defaultValue={searchText}
                    onChange={debounceWithSearch}
                  />
                  <Select
                    id='time-filter-select'
                    value={selectedTimeFilter}
                    onChange={handleChangeTimeFilter}
                    IconComponent={() => (
                      <img
                        src='/static/images/arrow_down.svg'
                        alt='arrow_down'
                        className={styles.arrowDown}
                      />
                    )}
                    className={styles.activityFilterDropDown}
                  >
                    {timeFilterList.map((val: any) => {
                      return (
                        <MenuItem value={val}>
                          <Box className={styles.activityFilterDropDownText}>
                            {val}
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>

              </Box>

              {data?.map((prospectActivityItem, idx) => {



                return (
                  <><Card
                    ref={
                      idx + 1 === data?.length
                        ? lastElementRef
                        : undefined
                    }
                    key={idx}
                    sx={{
                      width: "100%",
                      boxShadow: 'unset !important',
                      background: AppColors.grayZeroColor,
                      p: 0,
                      mt: 1,
                      border: `${AppColors.strokeColor} 2px solid`,
                      borderRadius: '10px',
                      position: "relative"
                    }}

                  >
                    <ListItem
                      alignItems="flex-start"
                      sx={{
                        '& .MuiListItemSecondaryAction-root': {
                          right: 5
                        },
                        alignItems: 'center'
                      }}
                      secondaryAction={<Button variant='contained' sx={{ borderRadius: "50px", textTransform: 'unset', fontSize: '0.7rem !important', pt: 0.3, pb: 0.3, pr: 1, pl: 1 }} onClick={() => handleViewActivity(prospectActivityItem)} >View Activity</Button>}
                    >
                      <ListItemAvatar>
                        <Box
                          component={'img'}
                          loading="lazy"
                          borderRadius={'10px'}
                          src={`${getProfileImage(prospectActivityItem)}`}
                          alt=""
                          sx={{
                            mr: 1.5, width: '50px',
                            height: '50px',
                            objectFit: 'cover',
                            borderRadius: '30px'
                          }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={

                          <Typography
                            sx={{ display: 'inline', fontSize: 'medium', fontWeight: 500 }}
                            component="span"
                            color={AppColors.blackColor}
                          >
                            {getUserFullName(prospectActivityItem)}
                          </Typography>

                        }
                        secondary={
                          <Box sx={{ display: { sm: "unset", xs: 'none' } }}>
                            {getDateAndFeedbackInfo(prospectActivityItem)}
                          </Box>
                        }
                      />

                    </ListItem>
                    <Box sx={{ display: { sm: "none", xs: "unset" } }}>
                      {getDateAndFeedbackInfo(prospectActivityItem)}
                    </Box>
                    {((prospectActivityItem.engage?.activities ?? 0) > 0) && (<Box sx={{ top: 10, position: 'absolute', right: 10, backgroundColor: AppColors.redColor, borderRadius: "10px", height: 10, width: 10 }}></Box>)

                    }
                  </Card></>
                );
              })}

              {isLoading && (
                <Box display='flex' justifyContent='center' mt={2}>
                  <CircularProgress />
                </Box>
              )
              }


              {(!isLoading && data.length == 0) && (
                <Box display='flex' justifyContent='center' mt={'20vh'}>
                  <Typography>Prospect Activity not found.</Typography>
                </Box>
              )
              }
            </Grid>

          </Grid>

        </Container >
        <BlockOverlay isBlock={!isProUser} />
      </Page >
    </>
  );
};
export default ProspectActivity;
