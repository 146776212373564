import { createSlice } from '@reduxjs/toolkit';
import { getCustomLandingPage, saveCustomLandingPage, } from './api';
import { initialState } from './api/interface';
import { getCapitalizedString } from '../../utils/utilMethods';
import { tutorialTabs } from '../../utils/utils';

const customLandingPageSlice = createSlice({
  name: 'tutorialVideo',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {

    builder.addCase(getCustomLandingPage.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getCustomLandingPage.fulfilled, (state, action) => {

      if (action.payload.data.data) {
        state.data = action.payload.data.data;
      }
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getCustomLandingPage.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });


    builder.addCase(saveCustomLandingPage.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(saveCustomLandingPage.fulfilled, (state, action) => {

      if (action.payload.data.data) {
        state.data = action.payload.data.data;
      }
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(saveCustomLandingPage.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });


  },
});

export const { reset } = customLandingPageSlice.actions;
export default customLandingPageSlice.reducer;

