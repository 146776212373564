import styled from "@emotion/styled";
import { Box, Button, SxProps, Theme, Typography } from "@mui/material";
import { AppColors } from "../../utils/utils";


interface IProps {
    headLine?: string | undefined | null;
    buttonLabel?: string | undefined | null;
    onButtonClick?: () => void;
    sx?: SxProps<Theme>;
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: 1,
}));

const VbcHeadLine: React.FC<IProps> = ({ headLine, buttonLabel, onButtonClick, sx }) => {

    return (
        <Container sx={sx}>
            <Typography
                variant='subtitle2'
                sx={{
                    color: AppColors.blackColor,
                    fontSize: "18px",
                    wordBreak: "break-word"
                }}
                className='text-decoration'

            >
                {headLine || ""}
            </Typography>
            {buttonLabel && (<Button color="primary" onClick={onButtonClick} sx={{ textTransform: "none" }}>{buttonLabel}</Button>)}
        </Container>
    );
}

export default VbcHeadLine;