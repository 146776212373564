import React, { FC, useEffect, useState } from 'react';
import { Avatar, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import { useCollapse } from 'react-collapsed';
import styles from '../../styles/Reports.module.css';
import {
  GetMediaVideoCounterDetailRequest,
  VideoViewsReportItem,
  VideoViewsReport as VideoViewsReportModel,
} from '../../store/reports/api/interface';
import { dateFormate } from '../../utils/format';
import { AppDispatch, useAppSelector } from '../../store';

import { getDaysFromFilter } from '../../utils/daysConvertor';
import {
  getMediaVideoCountViewDetail,
  getMediaVideoCounterDetail,
  getShareSocialPostDetail,
} from '../../store/reports/api';
import { useDispatch } from 'react-redux';
import { MediaType } from '../../enum';
import { getItem } from '../../utils/localStorage';
import { AppColors, config } from '../../utils/utils';
import MediaIcon from '../../assets/reports/media.svg';
import VideoIcon from '../../assets/reports/video.svg';

const ShareSocialPostUserItem: FC<{
  shareSocialPostDetailItem: VideoViewsReportItem;
  index: number;
}> = ({ shareSocialPostDetailItem, index }) => {
  return (
    <Box sx={{ width: '100%', pl: 4 }}>
      <Box
        className={styles.subitemContainer}
        sx={{
          background: AppColors.grayZeroColor,
          border: `solid 1px ${AppColors.strokeColor}`,
          mt: 0.6,
          borderRadius: "6px"
        }}
      >
        {/* <img
          alt='pic'
          src={'/static/images/placeholder_profile.png'}
          className={styles.knoSmilie}
        /> */}
        <Box component={'img'} src={VideoIcon} className={styles.muiIconSize} sx={{ height: '25px' }} />
        <Box className={styles.itemContainerContent} sx={{ ml: 1.5 }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {shareSocialPostDetailItem.content
              ? shareSocialPostDetailItem.content.title ?? 'Video'
              : 'Video'}
          </Typography>
          <Typography sx={{ fontSize: "0.7rem", color: AppColors.grayTwoColor }}>Last View at  {`${shareSocialPostDetailItem.created_at &&
            dateFormate(shareSocialPostDetailItem.created_at)
            } `}</Typography>
        </Box>
        <Box
          className={styles.itemContainerContent}
          alignItems={'end'}
          sx={{ pl: 1 }}
        >
          <Typography sx={{ fontSize: "0.7rem", color: AppColors.grayTwoColor }}>
            {`${shareSocialPostDetailItem.total ?? ''} views`}
          </Typography>
          <Typography sx={{ fontSize: "0.7rem", color: AppColors.grayTwoColor }}
          >
            {shareSocialPostDetailItem.watch_time ?? ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

interface IProps {
  shareSocialPostItemModel: VideoViewsReportItem;
  index: number;
  selectedTimeFilter: string;
  sortAs: string;
}

const VideoViewsCounterItem: React.FC<IProps> = ({
  shareSocialPostItemModel,
  index,
  selectedTimeFilter,
  sortAs,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);
  const accessToken = getItem(config.AUTH_KEY);
  const [isExpanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const [videoViewsPostCounts, setVideoViewsPostCounts] = useState<
    VideoViewsReportItem[] | null
  >(null);

  useEffect(() => {
    if (isExpanded && (videoViewsPostCounts?.length ?? 0) === 0) {
      fetchShareSocialPostDetail(false);
    }
  }, [isExpanded]);

  const fetchShareSocialPostDetail = async (isLoadMore: boolean) => {
    const data: GetMediaVideoCounterDetailRequest = {
      user_id: shareSocialPostItemModel?.postby_id ?? 0,
      viewer_id: shareSocialPostItemModel.user_id ?? 0,
      post_id: shareSocialPostItemModel.post?.id ?? 0,
      api_token: accessToken ?? '',
      days: getDaysFromFilter(selectedTimeFilter),
      timeline: sortAs,
      type: 'video',
    };
    try {
      setIsLoading(true);
      const response: any = await dispatch(
        getMediaVideoCounterDetail(data)
      ).unwrap();
      if (
        response.status === 200 &&
        response.data.status &&
        response.data.status === 'success'
      ) {
        setVideoViewsPostCounts(response.data.data);
      }
    } catch (e) { }
    setIsLoading(false);
  };

  return (
    <Box style={{ width: '100%' }}>
      <Box
        className={styles.subitemContainer}
        sx={{
          background: AppColors.grayZeroColor,
          border: `solid 1px ${AppColors.strokeColor}`,
          mt: 0.6,
        }}
        {...getToggleProps({
          onClick: () => {
            setExpanded(!isExpanded);
          },
        })}
      >
        <Box
          component={'img'}
          alt='pic'
          src={MediaIcon}
        // className={styles.knoSmilie}
        />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, pt: 1, pb: 1 }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {shareSocialPostItemModel.post &&
              shareSocialPostItemModel.post?.description != null
              ? shareSocialPostItemModel.post.description
              : ''}
          </Typography>
        </Box>

        {isExpanded ? (
          <img
            src='/static/images/arrow_down.svg'
            alt='chevron_down'
            className={styles.chevronDown}
            style={{ transform: `rotate(180deg)` }}
          />
        ) : (
          <img
            src='/static/images/arrow_down.svg'
            alt='chevron_down'
            className={styles.chevronDown}
          />
        )}
      </Box>
      <Box {...getCollapseProps()}>
        {videoViewsPostCounts &&
          videoViewsPostCounts.length > 0 &&
          videoViewsPostCounts.map((item, index) => (
            <ShareSocialPostUserItem
              shareSocialPostDetailItem={item}
              index={index}
            />
          ))}
        {/* {videoViewsPostCounts &&
          (videoViewsPostCounts.current_page ?? 0) <
            (videoViewsPostCounts.last_page ?? 0) &&
          !isLoading && (
            <Stack direction={'row'} justifyContent={'center'} sx={{ m: 1 }}>
              <Button
                type='button'
                variant='contained'
                sx={{ m: 2, textTransform: 'initial' }}
                autoCapitalize={'none'}
                onClick={() => fetchShareSocialPostDetail(true)}
              >
                Load More
              </Button>
            </Stack>
          )} */}

        {isLoading && (
          <Stack direction={'row'} justifyContent={'center'} sx={{ m: 1 }}>
            {' '}
            <CircularProgress />
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default VideoViewsCounterItem;
