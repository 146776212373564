import { STATUS_TYPE } from '../../../enum';
import { UserInfo } from '../../userInfo/api/interface';


export interface SendedKnoCardLinkRequestResponse {
  status: string;
  message?: string;
  data: {
    current_page?: number;
    data: SendKnoCardLinkRequestItem[];
    first_page_url?: string;
    from?: number;
    last_page?: number;
    last_page_url?: string;
    next_page_url?: string;
    path?: string;
    per_page?: number;
    prev_page_url?: string;
    to?: number;
    total?: number;
  };
}

export interface SendKnoCardLinkRequestItem {
  id?: number;
  user_id?: number;
  to_user_id?: number;
  new_prospects?: number;
  referrals?: number;
  media_share?: number;
  rf_lp?: number;
  personal_shares?: number;
  social_posts?: number;
  status?: string;
  created_at?: string;
  updated_at?: string;
  to_user?: UserInfo;
  user?: UserInfo;
}





export interface SendKnoCardLinkResponse {
  message?: string;
  status?: string;
}

interface InitialState {
  data: SendKnoCardLinkRequestItem[];
  viewReportUserInfo: UserInfo | null,
  page: number;
  total: number;
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

export const initialState: InitialState = {
  data: [],
  viewReportUserInfo: null,
  page: 0,
  total: 0,
  status: 'idle',
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
};
