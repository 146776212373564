import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Link,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Button,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import HCaptcha from '@hcaptcha/react-hcaptcha';
// component
import Iconify from '../../components/Iconify';
import { authenticateUser } from '../../store/auth/api';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../../store';

import Person from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import LoginSchema from '../../validation/loginSchema';
import useToggle from '../../hooks/useToggle';
import ForgotPassword from '../ForgotPassword';
import { config } from '../../utils/utils';
import { isNotEmpty } from '../../utils/valid';
import { decryptString } from '../../utils/url';
import { removeItem } from '../../utils/localStorage';
import { showErrorMessage } from '../../utils/utilMethods';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [showPassword, setShowPassword] = useState(false);

  const { toggle, onOpen, onClose } = useToggle();

  const captchaRef = useRef<HCaptcha>(null);


  useEffect(() => {
    removeItem(config.SIGNUP_REDIRECT_KEY);
  }, [])


  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      captchaCode: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      try {
        if (!isNotEmpty(values.captchaCode)) {
          showErrorMessage('Please verify CAPTCHA.');
          setSubmitting(false);
          return;
        }

        const response = await dispatch(
          authenticateUser({
            username: values.username,
            password: values.password,
            captcha_code: values.captchaCode,
          })
        ).unwrap();
        if (response.data.status === 'success') {
          let url = searchParams.get('url');
          if (url != null) {
            navigate(`/${decryptString(url)}`)
          }
        } else {
          showErrorMessage(response?.data?.message);
        }
        setSubmitting(false);
        captchaRef.current?.resetCaptcha();
      } catch (error: any) {
        showErrorMessage(error);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const onExpire = () => {
    setFieldValue('captchaCode', '');
  };

  return (
    <div>
      <FormikProvider value={formik}>
        <Form
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <Stack spacing={2}>
            <Typography sx={{ color: 'text.secondary' }}>
              Username or Email address
            </Typography>
            <TextField
              fullWidth
              autoComplete='username'
              type='email'
              {...getFieldProps('username')}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
              InputProps={{
                sx: { borderRadius: 30, backgroundColor: '#E8F0FD' },
                startAdornment: (
                  <InputAdornment position='start'>
                    <Person />
                  </InputAdornment>
                ),
              }}
            />

            <Typography sx={{ color: 'text.secondary' }}>
              Enter Password
            </Typography>
            <TextField
              fullWidth
              autoComplete='current-password'
              type={showPassword ? 'text' : 'password'}
              {...getFieldProps('password')}
              InputProps={{
                sx: { borderRadius: 30, mt: 0, backgroundColor: '#E8F0FD' },
                startAdornment: (
                  <InputAdornment position='start'>
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={handleShowPassword}
                      edge='end'
                    >
                      <Iconify
                        icon={
                          showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ my: 2, overflowX: 'auto' }}
          >
            {!toggle && <HCaptcha
              sitekey={config.H_CAPTCHA_SITEKEY}
              onVerify={(captchaCode: string) => {
                setFieldValue('captchaCode', captchaCode);
              }}
              onExpire={onExpire}
              ref={captchaRef}
            />}
          </Stack>

          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            sx={{ my: 2 }}
          >
            <Link
              // component={RouterLink}
              // to='/forgot'
              variant='subtitle2'
              underline='none'
              component={Button}
              textTransform={'none'}
              onClick={() => {
                if (getFieldProps('username').value.trim().length > 0) {
                  onOpen();
                } else {
                  showErrorMessage('Please add Username.');
                }
              }}
            >
              Forgot Password?
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={isSubmitting}
            sx={{
              backgroundColor: '#F58227',
              borderRadius: 30,
            }}
          >
            <Typography
              textTransform={'none'}
              fontWeight={'600'}
            >
              Sign In
            </Typography>
          </LoadingButton>
          <Stack
            alignItems='center'
            justifyContent='center'
            sx={{ m: 2 }}
          >
            <Link
              // component={RouterLink}
              // to={'/register'}
              variant='subtitle2'
              underline='none'
              component={Button}
              onClick={() => navigate('/register')}
              textTransform={'none'}
            >
              Create new account
            </Link>
          </Stack>
        </Form>
      </FormikProvider>

      {toggle && <ForgotPassword
        toggle={toggle}
        onClose={onClose}
        username={getFieldProps('username').value.trim()}
      />}
    </div>
  );
}
