import { STATUS_TYPE } from '../../../enum';
import { CompanyFeed } from '../../social/api/interface';

export interface CompanyFeedResponse {
  status: {
    code: string;
    description: string;
  };
  company_feed: CompanyFeed;
}

export interface Owner {
  profile_picture: string;
}

export interface Company {
  owner: Owner;
}




interface InitialState {
  data: CompanyFeedResponse | null;
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

export const initialState: InitialState = {
  data: null,
  status: 'idle',
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
};
