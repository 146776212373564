import { Box, Rating, Stack, Typography, useMediaQuery } from "@mui/material";
import RenderProfilePhoto from "../RenderProfilePhoto";
import { UserInfo } from "../../store/userInfo/api/interface";
import moment from "moment";
import { UserReviewItem } from "../../store/reviewForm/api/interface";
import { AppColors } from "../../utils/utils";



interface IProps {
    userReview: UserReviewItem;
    backgrounColor?: string;
}

const ReviewItemView: React.FC<IProps> = ({ userReview, backgrounColor }) => {

    return (
        <Stack
            direction='column'
            sx={{
                position: 'relative',
                borderRadius: '10px !important',
                background: backgrounColor ? backgrounColor : AppColors.whiteColor,
                p: 1,
            }}
        >
            <Stack flexDirection={'row'} sx={{ mt: 1.2, mb: 0.5 }}>
                {userReview.rater_type === 'App\\Models\\Contact' ? (
                    <Box
                        component={'img'}
                        src={userReview.rater?.user?.profile_picture ? userReview.rater?.user?.profile_picture : '/static/images/placeholder_profile.png'}
                        sx={{
                            height: '50px',
                            width: '50px',
                            borderRadius: '100px',
                            mr: 2,
                        }}
                    ></Box>
                ) : (
                    <RenderProfilePhoto
                        profile_picture={
                            (userReview.rater as UserInfo | undefined)
                                ?.profile_picture ?? ''
                        }
                        show_profile_picture={
                            (userReview.rater as UserInfo | undefined)
                                ?.show_profile_picture ?? 1
                        }
                        sx={{
                            height: '50px',
                            width: '50px',
                            borderRadius: '100px',
                            mr: 2
                        }}
                    />
                )}
                <Box>
                    <Typography
                        variant='subtitle1'
                        sx={{
                            width: '100%',
                            color: AppColors.blackColor,
                            overflowWrap: 'anywhere',
                        }}
                    >
                        {userReview?.first_name ?? ''} {userReview?.last_name ?? ''}
                    </Typography>
                    <Rating
                        size='small'
                        name='read-only-rating'
                        value={Number(userReview.rating ?? '0')}
                        precision={0.5}
                        readOnly
                        sx={{
                            '& .MuiRating-iconFilled': {
                                color: AppColors.yellowColor,
                            },
                        }}
                    />
                    <Typography
                        variant='subtitle2'
                        sx={{
                            fontSize: 'small',
                            fontWeight: '300', mb: 1, color: AppColors.grayTwoColor,
                            position: 'absolute',
                            right: 15,
                            top: 10,
                        }}
                    >
                        {moment(userReview?.created_at).format('MMM DD, YYYY')}
                    </Typography>
                </Box>
            </Stack>
            <Box display='flex' sx={{}}>
                <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='flex-start'
                    justifyContent='center'
                    flex={1}
                    sx={{

                    }}
                >
                    <Typography
                        variant='subtitle2'
                        component='div'
                        color='inherit'
                        sx={{
                            width: '100%',
                            wordWrap: 'break-word',
                            overflowWrap: 'anywhere',
                            p: 1,
                            fontWeight: 'unset',
                            fontSize: '0.8rem',
                            color: AppColors.grayTwoColor
                        }}
                    >
                        {userReview?.message}
                    </Typography>
                </Box>
            </Box>
        </Stack>
    );
};

export default ReviewItemView;
