import { LoadingButton } from '@mui/lab';
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Stack } from '@mui/system';
import { Transition } from './Transition';
import { useFormik } from 'formik';
import { useAppDispatch } from '../store';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AffiliateGroupFilter from './AffiliateGroupFilter';
import GroupNetworkSchema from '../validation/GroupNetworkSchema';
import { AffiliateGroup } from '../store/AffiliateGroup/api/interface';

import { removeItem, setItem } from '../utils/localStorage';
import { AppColors, config } from '../utils/utils';
import { UserInfo } from '../store/userInfo/api/interface';
import { openLinkOnNewTab, showErrorMessage } from '../utils/utilMethods';


interface IProps {
  toggle: boolean;
  onClose: () => void;
  affiliateLink: string;
  userInfo: UserInfo | undefined | null;
}

const inputStyle = {
  borderRadius: 1,
  backgroundColor: '#FFFFFF',
  boxShadow:
    '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
};


const GroupNetworkModal: React.FC<IProps> = ({ toggle, onClose, affiliateLink, userInfo }) => {
  const dispatch = useAppDispatch();


  const navigate = useNavigate();

  const [affiliateGroup, setAffiliateGroup] = useState<AffiliateGroup | undefined>();

  const handleOnselectAffiliateGroup = (selectedAffiliateGroup: AffiliateGroup | undefined) => {
    if (values.associatedWithGroupOrNetwork == "yes") {
      setAffiliateGroup(selectedAffiliateGroup);
    }
  }


  const {
    handleSubmit,
    values,
    resetForm,
    isSubmitting,
    errors,
    touched,
    handleChange,
    getFieldProps,
    setFieldValue,
    setSubmitting,
  } = useFormik<{ associatedWithGroupOrNetwork: string }>({
    initialValues: {
      associatedWithGroupOrNetwork: ''
    },
    validationSchema: GroupNetworkSchema,
    onSubmit: async (values) => {
      try {
        if (values.associatedWithGroupOrNetwork == "yes" && affiliateGroup == null) {
          showErrorMessage('Please select associated Group or Network.');
          setSubmitting(false);
          return;
        }
        if (values.associatedWithGroupOrNetwork == "yes" && affiliateGroup != null) {
          setItem(config.GROUP_NETWORK_ID_KEY, affiliateGroup?.id);
        } else {
          removeItem(config.GROUP_NETWORK_ID_KEY);
        }

        openLinkOnNewTab(affiliateLink);
        onClose();
        resetForm();
      } catch (error: any) { }
    },
  });


  const handleNoPress = () => {
    openLinkOnNewTab(affiliateLink);
    onClose();
    resetForm();
  }


  useEffect(() => {
    if (!toggle) {
      setAffiliateGroup(undefined);
    } else {
      if (userInfo && userInfo.affiliate_group) {
        setAffiliateGroup(userInfo.affiliate_group)
        setFieldValue("associatedWithGroupOrNetwork", "yes");
      }
    }
  }, [toggle])


  return (
    <Dialog
      fullWidth
      open={toggle}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        resetForm();
        onClose();
      }}

      PaperProps={{
        sx: {
          borderRadius: 4
        },
      }}

      aria-describedby='alert-dialog-slide-description'
    >

      <form onSubmit={handleSubmit} autoComplete='off'>
        <DialogContent sx={{
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          p: 0.5,
          borderRadius: 4
        }}>
          <Stack direction='column' spacing={0}  >

            <Typography sx={{ color: AppColors.blackColor, textAlign: "center", pt: 2, pl: 2, pr: 2 }}>Are you associated with a Group/ Network or Event?</Typography>
            <Stack flexDirection={"row"} sx={{ p: 2 }}>
              <Box>
                <FormControl sx={{ minWidth: 80, mr: 2, background: "#ffffff", borderRadius: "4px" }}>
                  <InputLabel id="associated-label" >*</InputLabel>
                  <Select
                    labelId="associated-label"
                    label="*"
                    {...getFieldProps('associatedWithGroupOrNetwork')}
                    error={Boolean(touched.associatedWithGroupOrNetwork && errors.associatedWithGroupOrNetwork)}
                  >
                    <MenuItem value={"yes"}>Yes</MenuItem>
                    <MenuItem value={"no"}>No</MenuItem>
                  </Select>

                </FormControl>
                <FormHelperText sx={{ color: "#FF4842" }}>{touched.associatedWithGroupOrNetwork && errors.associatedWithGroupOrNetwork}</FormHelperText>
              </Box>
              <AffiliateGroupFilter
                sx={inputStyle}
                defaultValue={affiliateGroup}
                onAffiliateGroupSelected={handleOnselectAffiliateGroup}
                isDisable={values.associatedWithGroupOrNetwork != "yes"}
              />

            </Stack>
            <Stack direction="row" spacing={2} justifyContent={{ xs: "center", sm: 'flex-end' }} sx={{
              mr: { xs: 1, sm: 2 },
              ml: { xs: 1, sm: 2 },
              mb: 1.5,
            }} >
              <LoadingButton
                sx={{
                  minWidth: '100px', textTransform: "unset", fontWeight: "600",
                  borderRadius: "10px",
                  width: { xs: "45%", sm: 'unset' },
                  border: `2px solid ${AppColors.primaryColor}`,
                  "&:hover": {
                    border: `2px solid ${AppColors.primaryColor}`,
                  },

                }}
                type='button'
                onClick={handleNoPress}
                loading={false}
                variant='outlined'

              >
                No
              </LoadingButton>

              <LoadingButton
                sx={{
                  minWidth: '100px', backgroundColor: AppColors.primaryColor, color: AppColors.whiteColor, textTransform: "unset", fontWeight: "600",
                  borderRadius: "10px",
                  width: { xs: "45%", sm: 'unset' },
                  "&:hover": {
                    backgroundColor: AppColors.primaryColor,
                    color: AppColors.whiteColor
                  },
                  "& span span": {
                    color: "#ffffff"
                  }
                }}
                type='submit'
                loading={isSubmitting}
                variant='outlined'

              >
                Next
              </LoadingButton>
            </Stack>
          </Stack>

        </DialogContent>
      </form>
    </Dialog >
  );
};

export default GroupNetworkModal;
