import { createSlice } from '@reduxjs/toolkit';
import { getCourseVideos } from './api';
import { initialState } from './api/interface';
import { getCapitalizedString } from '../../utils/utilMethods';
import { tutorialTabs } from '../../utils/utils';

const courseVideoSlice = createSlice({
  name: 'courseVideo',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getCourseVideos.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getCourseVideos.fulfilled, (state, action) => {
      state.data = action.payload.data.data.data;
      state.isLoadMore = action.payload.data.data.data.length >= 15;
      state.page = action.payload.data.data.current_page + 1;
      state.total = action.payload.data.data.total;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getCourseVideos.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset } = courseVideoSlice.actions;
export default courseVideoSlice.reducer;

