import { styled } from '@mui/material/styles';
// import Page from '../components/Page';
import OtpInput from 'react-otp-input';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useRef, useState } from 'react';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import { changeForgotPassword, forgotPassword, getUserInfo, updatePassword } from '../store/auth/api';
import { isNotEmpty } from '../utils/valid';

import { toastHandler } from '../utils/toastHandler';

import Iconify from '../components/Iconify';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ChangePasswordSchema from '../validation/changePasswordSchema';
import { showErrorMessage, showSuccessMessage } from '../utils/utilMethods';
import ForgotPasswordSchema from '../validation/forgotPasswordSchema';


interface IProps {
  username: string;
  toggle: boolean;
  onClose: () => void;
}

const ForgotPassword: React.FC<IProps> = ({ username, toggle, onClose }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [otp, setOtp] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [forgotPasswordUserName, setForgotPasswordUserName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const Ref = useRef<any>(null);
  const [timer, setTimer] = useState('00:00');

  const { forgotPasswordToken, isForgotPasswordLoading } = useAppSelector((state) => state?.auth);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (toggle) {
      clearModel();
      dispatch(getUserInfo({ keyword: username }))
        .unwrap()
        .then((response) => {
          if (response && response.data.status === 'success') {
            resetTimer();
            if (isNotEmpty(response.data.data.user.email)) {
              setForgotPasswordUserName(response.data.data.user.username);
              setUserEmail(response.data.data.user.email);
            } else {
              showErrorMessage('Invalid email or user has no email');
              onClose();
            }
          } else {
          }
        });
    }
  }, [toggle]);


  useEffect(() => {
    sendCode();
  }, [forgotPasswordUserName]);

  const formik = useFormik({
    initialValues: {
      otp: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async () => {
      try {

        const response = await dispatch(
          changeForgotPassword({
            token: forgotPasswordToken ?? "",
            otp: values.otp,
            password: values.password.trim()
          })
        ).unwrap();
        if (response.data.status === 'success') {
          showSuccessMessage('Password changed.');
          onClose();
        } else {
          showErrorMessage(response?.data?.message);
        }
        setSubmitting(false);
      } catch (error: any) {
        showErrorMessage(error);
      }
    },
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
  } = formik;

  const clearModel = () => {
    resetForm();
  };

  const sendCode = () => {
    if (timer === '00:00') {
      resetTimer();
    }
    dispatch(forgotPassword({ username: forgotPasswordUserName })).unwrap();
  };



  // The state for our timer

  const getTimeRemaining = (e: string) => {
    const total = Date.parse(e) - Date.now();
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e: string) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        // (hours > 9 ? hours : '0' + hours) +
        // ':' +
        (minutes > 9 ? minutes : '0' + minutes) +
        ':' +
        (seconds > 9 ? seconds : '0' + seconds)
      );
    }
  };

  const clearTimer = (e: string) => {
    setTimer('00:59');

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const resetTimer = () => {
    clearTimer(getDeadTime());
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 59);
    return deadline.toString();
  };



  return (
    <Dialog
      fullWidth
      open={toggle}
      keepMounted
      onClose={() => {
        onClose();
      }}
      aria-describedby='alert-dialog-slide-description'
    >
      {/* <DialogTitle>VERIFICATION</DialogTitle> */}
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{ position: 'absolute', right: 0, zIndex: 100000 }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <Stack
              direction='column'
              alignItems='center'
              justifyContent='space-between'
              spacing={2}
              my={2}
            >
              <Typography variant='h4'>CHANGE PASSWORD</Typography>
              <Typography variant='body1' align='center'>
                A OTP has been sent via email please enter it below.
              </Typography>
              <Typography variant='body1'>
                {isNotEmpty(userEmail)
                  ? userEmail!.substring(
                    0,
                    userEmail!.length > 5 ? 5 : userEmail!.length
                  ) +
                  '**************' +
                  userEmail!.substring(
                    userEmail!.length > 0 ? userEmail!.length - 3 : 0
                  )
                  : ''}
              </Typography>
              <TextField
                fullWidth
                type={'text'}
                {...getFieldProps('otp')}
                placeholder='OTP'
                InputProps={{
                  sx: { borderRadius: 30, mt: 0, backgroundColor: '#E8F0FD' },
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.otp && errors.otp)}
                helperText={touched.otp && errors.otp}
              />
              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                {...getFieldProps('password')}
                placeholder='New Password'
                InputProps={{
                  sx: { borderRadius: 30, mt: 0, backgroundColor: '#E8F0FD' },
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => setShowPassword((prev) => !prev)}
                        edge='end'
                      >
                        <Iconify
                          icon={
                            showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <TextField
                fullWidth
                type={showConfirmPassword ? 'text' : 'password'}
                {...getFieldProps('confirmPassword')}
                placeholder='Confirm Password'
                InputProps={{
                  sx: { borderRadius: 30, mt: 0, backgroundColor: '#E8F0FD' },
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() =>
                          setShowConfirmPassword((prev) => !prev)
                        }
                        edge='end'
                      >
                        <Iconify
                          icon={
                            showConfirmPassword
                              ? 'eva:eye-fill'
                              : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                helperText={touched.confirmPassword && errors.confirmPassword}
              />
              <LoadingButton
                fullWidth
                size='large'
                type='submit'
                variant='contained'
                loading={isSubmitting}
                sx={{
                  backgroundColor: '#F58227',
                  borderRadius: 30,
                }}
              >
                Change
              </LoadingButton>
              <Typography variant='body1' align='center'>
                If you don't receive a code!{' '}
                <Link
                  underline='none'
                  onClick={() => sendCode()}
                  component={Button}
                  disabled={timer !== '00:00'}
                >
                  Resend
                </Link>{' '}
                {timer !== '00:00' ? 'in ' + timer : ''}
              </Typography>
            </Stack>
          </Form>
        </FormikProvider>
      </DialogContent>



    </Dialog>
  );
};
export default ForgotPassword;
