import { DialogContent, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/system';
import '@splidejs/react-splide/css';

import YouTube from 'react-youtube';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useCallback, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ChatMessageMedia } from '../../store/chat/api/interface';

type IProps = {
  toggle: boolean;
  onClose: () => void;
  contents: ChatMessageMedia[];
  index: number;
};

const opts = {
  height: '100%',
  width: '100%',
};

const FullscreenChatMediaViewerDialog: React.FC<IProps> = ({
  toggle,
  onClose,
  contents,
  index,
}) => {
  return (
    <>
      <Dialog
        open={toggle}
        onClose={() => {
          onClose();
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='lg'
        fullScreen
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: 3000
        }}
      >
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{ position: 'absolute', right: 0, zIndex: 100000 }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent sx={{ padding: 0 }}>
          <>
            <Box>
              {contents != undefined && contents?.length > 0 && (
                <Splide
                  aria-label='Images'
                  options={{
                    perPage: 1,
                    height: '100vh',
                    start: index,
                  }}
                >
                  {contents?.map((content, idx) => {
                    return content?.type === 'image' ? (
                      <SplideSlide key={idx}>
                        <img
                          src={content?.link}
                          alt={''}
                          height='100%'
                          width='100%'
                          className='object-fit-contain'
                        />
                      </SplideSlide>
                    ) : (
                      <SplideSlide key={idx}>
                        <video
                          controls
                          width='100%'
                          height='100%'
                          className='object-fit-contain'
                          poster={content?.thumbnail ?? ''}
                        >
                          <source src={content?.link} type='video/mp4' />
                          Your browser does not support the video tag.
                        </video>
                      </SplideSlide>
                    );
                  })}
                </Splide>
              )}
            </Box>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FullscreenChatMediaViewerDialog;
