import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { ShareHistoryReponse } from './interface';




export const getShareHistory = createAsyncThunk(
  'shareHistory/getShareHistory',
  async (values: { pageNumber: number, searchName?: string, days: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<ShareHistoryReponse>(
        `/api/reports/affiliate-group/share-history?page=${values.pageNumber}&days=${values.days}&search_name=${values.searchName}`

      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


