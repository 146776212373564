import { getItem } from '../../../utils/localStorage';
import { config } from '../../../utils/utils';
import { UserInfo } from '../../userInfo/api/interface';

export interface getUserInfoRequestResponse {
  status: string;
  data: {
    user: UserInfo[];
  };
}

export interface getUserInfoFromReferenceCodeRequestResponse {
  status: string;
  data: {
    user: UserInfo;
  };
}

const accessToken = getItem(config.AUTH_KEY);
const userInfo: UserInfo | undefined = getItem(config.INFO) ?? undefined;

interface InitialState {
  accessToken: string;
  user: UserInfo | undefined;
  isError: boolean;
  isLoading: boolean;
  errorMessage: string | undefined;
  forgotPasswordToken: string | undefined;
  isForgotPasswordLoading: boolean;
}

export interface Values {
  username: string;
  password: string;
  captcha_code: string;
}

export const initialState: InitialState = {
  accessToken: accessToken || '',
  user: userInfo,
  isError: false,
  isLoading: false,
  errorMessage: undefined,
  forgotPasswordToken: undefined,
  isForgotPasswordLoading: false,
};
