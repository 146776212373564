import { AppColors } from "../utils/utils";

export default function componentsOverride(theme: any) {
    return {
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: AppColors.primaryColor,
                        fontWeight: 'bold',
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.action.selected,
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        },
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    //borderBottom: 'none',
                },
                head: {
                    color: theme.palette.text.secondary,
                    backgroundColor: theme.palette.background.neutral,

                    '&:first-of-type': {
                        paddingLeft: theme.spacing(3),
                        borderTopLeftRadius: theme.shape.borderRadius,
                        borderBottomLeftRadius: theme.shape.borderRadius,

                    },
                    '&:last-of-type': {
                        paddingRight: theme.spacing(3),
                        borderTopRightRadius: theme.shape.borderRadius,
                        borderBottomRightRadius: theme.shape.borderRadius,

                    },
                },
                stickyHeader: {
                    backgroundColor: theme.palette.background.paper,
                    backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
                },
                body: {
                    '&:first-of-type': {
                        paddingLeft: theme.spacing(3),
                        boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`,
                    },
                    '&:last-of-type': {
                        paddingRight: theme.spacing(3),
                        boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`,
                    },
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    borderTop: `dotted 1px ${theme.palette.divider}`,
                },
                toolbar: {
                    height: 60,
                },
                select: {
                    '&:focus': {
                        borderRadius: theme.shape.borderRadius,
                    },
                },
                selectIcon: {
                    width: 20,
                    height: 20,
                },
            },
        },

    };
}