import {
  Avatar,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  Menu,
  MenuItem,
  Button,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import TabPanel from '../TabPanel';

import { useAppDispatch, useAppSelector } from '../../store';
import { CompanyFeed } from '../../store/social/api/interface';
import { FaRegComment, FaRegHeart } from 'react-icons/fa';
import IosShareIcon from '@mui/icons-material/IosShare';
import useToggle from '../../hooks/useToggle';
import SocialDailog from '../modal/SocialDailog';
import YouTube from "react-youtube";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ReactReadMoreReadLess from "../ReactReadMoreReadLess";
import useWindowSize from '../../utils/useWindowSize';
import { addCompanyFeedShare, createUpdateUserCompanyFeedReaction, getCompanyPinSocialFeeds, getSocialFeeds } from '../../store/social/api';
import moment from 'moment';
import { isNotEmpty } from '../../utils/valid';
import ViewCommunityModal from '../modal/ViewCommunityModal';
import { setCommunityFeed } from '../../store/communityFeed/communityFeedSlice';
import { socialFeedUpdated } from '../../store/social/socialSlice';
import { SOCIAL_LIKE_ICONS } from '../../constant';
import CompanyFeedCommentsModal from '../modal/CompanyFeedCommentsModal';
import { SharePostType, SocialShareType } from '../../enum';
import CompanyFeedShareLinkModal from '../modal/CompanyFeedShareLinkModal';
import { checkIsProUser } from '../../utils/checkPro';
import { encryptString } from '../../utils/url';
import { shareSocialFeedMessage } from "../../store/social/socialSlice"
import { useNavigate } from 'react-router-dom';
import { AppColors, config } from '../../utils/utils';
import { openLinkOnNewTab, shareSocialPostMessage, showSuccessMessage } from '../../utils/utilMethods';
import Vimeo from '@u-wave/react-vimeo';
import EyeIcon from '../../assets/eye.svg';
import CommentIcon from '../../assets/comment.svg';
import HeartIcon from '../../assets/heart.svg';
import ShareIcon from '../../assets/share.svg';

const opts = {
  height: "100%",
  width: "100%",
  playerVars: {
    // Hide related videos after the video finishes
    rel: 0,
    // Hide the video annotations
    iv_load_policy: 3,
    // Hide the YouTube logo
    modestbranding: 1,
  },
};

const RenderCompanyMediaFeed: FC<{ companyFeed: CompanyFeed }> = ({
  companyFeed,
}) => {

  const [activeSlide, setActiveSlide] = useState<number>(0)
  const [totalSlide, setTotalSlide] = useState<number>(0)

  if (companyFeed?.contents?.length > 0) {
    return (
      <><Splide
        aria-label="Images"
        options={{
          perPage: 1,
          //fixedHeight: 390,
        }}
        onMove={(event) => {
          setActiveSlide(event.index);
        }}
        onReady={(event) => {
          setTotalSlide(event.length)
        }}
      >
        {companyFeed?.contents?.map((content) => {
          return content?.type === "image" ? (
            <SplideSlide key={content.id}>
              <img
                src={content?.link}
                alt={content?.filename ?? ''}
                height="100%"
                width="100%"
                style={{ maxHeight: '50vh' }}
                className="object-fit-contain"
              />
            </SplideSlide>
          ) : (
            <SplideSlide key={content.id}>
              {content?.platform === "youtube" ? (
                <YouTube
                  videoId={content?.link}
                  opts={opts}
                  className="object-fit-contain"
                  style={{
                    height: "100%",
                    width: "100%",
                    maxHeight: '50vh'
                  }}
                // onPlay={() => handleOnplayVideo(content.id)}
                />
              ) : content?.platform === "vimeo" ? (
                <Vimeo
                  video={content?.link}
                  className="object-fit-contain vimeo-video-contain"
                  height={"100%"}
                  width={"100%"}
                  style={{
                    height: "100%",
                    width: "100%",
                    maxHeight: '50vh'
                  }}
                // onPlay={() => handleOnplayVideo(content.id)}
                />
              ) : (
                <video
                  controls
                  width="100%"
                  height="100%"
                  className="object-fit-contain"
                  style={{ maxHeight: '50vh' }}
                  poster={content?.thumbnail ?? ""}
                //onPlay={() => handleOnplayVideo(content.id)}
                >
                  <source src={content?.link} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </SplideSlide>
          );
        })}
      </Splide>
        {companyFeed?.contents && companyFeed?.contents?.length > 0 && <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            variant='contained'
            sx={{
              backgroundColor: AppColors.primaryColor,
              textTransform: 'none',
              fontSize: "0.8rem",
              fontWeight: 400,
              zIndex: 1000,
              lineHeight: 'normal',
              mt: 0.3,
              ml: 2, mr: 1
            }}
          >

            {`${activeSlide + 1}`} of {totalSlide ?? 0}
          </Button>
        </Box>}
      </>
    );
  }

  return <></>;
};


interface IProps {
  value: number;
  index: number;
}

const WeAreLiveTabPanel: React.FC<IProps> = ({ value, index }) => {
  const navigate = useNavigate();
  const [communityPost, setCommunityPost] = useState<CompanyFeed | null>(null);
  const dispatch = useAppDispatch();
  const matches = useMediaQuery('(max-width:745px)');
  const windowSize = useWindowSize();
  const { data: userInfo } = useAppSelector((state) => state.userInfo);
  const companyId = useAppSelector((state) => state.userInfo.data?.company?.id);
  const loginUserInfo = useAppSelector((state) => state?.auth?.user);
  const comapanyTitle = useAppSelector(
    (state) => state?.userInfo?.data?.company?.title
  );
  const description = useAppSelector(
    (state) => state?.userInfo?.data?.company?.description
  );
  const promotional_video = useAppSelector(
    (state) => state?.userInfo?.data?.company?.promotional_video
  );
  const thumbnail = useAppSelector(
    (state) => state?.userInfo?.data?.company?.thumbnail
  );
  const profile_picture = useAppSelector(
    (state) => state?.userInfo?.data?.company?.profile_picture
  );

  const userName = useAppSelector((state) => state?.userInfo?.data?.username);


  const {
    toggle: toggleSocialDailog,
    onOpen: onOpenSocialDailog,
    onClose: onCloseSocialDailog,
  } = useToggle();
  const socialPosts = useAppSelector((state) => state?.socialPost.data) ?? [];
  const isLoading = useAppSelector((state) => state?.socialPost?.isLoading);
  const hasMore = useAppSelector((state) => state?.socialPost?.isLoadMore);
  const currentPage = useAppSelector((state) => state?.socialPost?.page);
  const pinnedSocialPosts = useAppSelector((state) => state?.socialPost.pinFeeds) ?? [];
  const pinnedPostCurrentPage = useAppSelector((state) => state?.socialPost?.pinFeedPage);
  const observer = useRef<IntersectionObserver>();

  const {
    toggle: toggleCommunity,
    onOpen: onOpenCommunity,
    onClose: onCloseCommunity,
  } = useToggle();


  const {
    toggle: toggleFeedComment,
    onOpen: onOpenFeedComment,
    onClose: onCloseFeedComment,
  } = useToggle();

  const {
    toggle: toggleFeedLinkShare,
    onOpen: onOpenFeedLinkShare,
    onClose: onCloseFeedLinkShare,
  } = useToggle();

  const openFeedCommentDailog = (feed: CompanyFeed) => {

    setCommunityPost(feed);
    onOpenFeedComment();
  };


  const lastElementRef = useCallback(
    // (*)
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          handleLoadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  const handleLoadMore = () => {
    if (!isLoading && companyId) {
      dispatch(
        getSocialFeeds({
          page: currentPage,
          user_id: userInfo?.id,
          companyId: companyId,
        })
      );
    }
  };

  const handleLoadMorePinPost = () => {
    if (!isLoading && companyId) {
      dispatch(
        getCompanyPinSocialFeeds({
          page: pinnedPostCurrentPage,
          user_id: userInfo?.id,
          companyId: companyId,
        })
      );
    }
  };


  useEffect(() => {
    if (socialPosts.length == 0) {
      handleLoadMore();
    }

    if (pinnedSocialPosts.length == 0) {
      handleLoadMorePinPost();
    }
  }, [companyId]);


  const openSocialDailog = (feed: CompanyFeed) => {
    setCommunityPost(feed);
    onOpenSocialDailog();
  };

  const [cardWidth, setCardWidth] = useState(300);

  useEffect(() => {
    const newWidth = matches ? windowSize.width > 350 ? windowSize.width * 0.90 : windowSize.width * 0.80 : windowSize.width > 890 ? 745 : windowSize.width * 0.80;
    setCardWidth(newWidth);
  }, [windowSize]);





  const [likeAnchorEl, setLikeAnchorEl] = useState<null | HTMLElement>(null);
  const [activeLikeMenuIndex, setActiveLikeMenuIndex] = useState<any>(0);

  const openLikeMenu = Boolean(likeAnchorEl);
  const handleLikeMenuClick = (event: React.MouseEvent<HTMLElement>, idx: number) => {
    setLikeAnchorEl(event.currentTarget);
    setActiveLikeMenuIndex(idx);
  };
  const handleLikeMenuClose = async (likeCompanyFeed?: CompanyFeed, reaction_id?: number) => {
    setLikeAnchorEl(null);
    if (likeCompanyFeed) {
      let result = await dispatch(createUpdateUserCompanyFeedReaction({ companyFeedId: likeCompanyFeed.id, reactionId: reaction_id })).unwrap();

      if (result.data.status === 'success') {
        let updatedLikeCompanyFeed = { ...likeCompanyFeed };
        if (result.data.data !== undefined) {
          updatedLikeCompanyFeed.reaction = result.data.data;
          updatedLikeCompanyFeed.reactions_count += 1;
        } else {
          updatedLikeCompanyFeed.reaction = undefined;
          if (updatedLikeCompanyFeed.reactions_count > 0) {
            updatedLikeCompanyFeed.reactions_count -= 1;
          }
        }
        dispatch(socialFeedUpdated(updatedLikeCompanyFeed));
      }
    }
  };


  const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null);
  const [activeShareMenuIndex, setActiveShareMenuIndex] = useState<any>(0);

  const openShareMenu = Boolean(shareAnchorEl);
  const handleShareMenuClick = (event: React.MouseEvent<HTMLElement>, idx: number) => {
    setShareAnchorEl(event.currentTarget);
    setActiveShareMenuIndex(idx);
  };

  const handleShareMenuClose = async (shareCompanyFeed?: CompanyFeed, socialShareType?: string) => {
    setShareAnchorEl(null);

    if (shareCompanyFeed != null) {
      switch (socialShareType) {
        case SocialShareType.link:
          setCommunityPost(shareCompanyFeed);
          onOpenFeedLinkShare();
          dispatch(addCompanyFeedShare({
            postById: shareCompanyFeed?.company.owner?.id ?? 0,
            companyFeedId: shareCompanyFeed.id,
            actionType: socialShareType,
            targetId: undefined
          })).unwrap().then((result) => {

            if (result.status == 200 && result.data.status == "success") {
              if (shareCompanyFeed.id == result.data.data.company_feed_id) {
                dispatch(socialFeedUpdated({ ...shareCompanyFeed, shares_count: shareCompanyFeed.shares_count + 1 }))
              }
            }
          });
          break;
        case SocialShareType.community:
          dispatch(shareSocialFeedMessage({ socialPost: shareCompanyFeed, message: shareSocialPostMessage(userName ?? "", shareCompanyFeed, loginUserInfo) }));
          navigate(`/dashboard/messaging?${config.SHARE_KEY}=${encryptString(SharePostType.social_post)}`);
          break;
        case SocialShareType.share_faceook:
          navigator.clipboard.writeText(shareCompanyFeed?.description ?? "");
          showSuccessMessage("Copied the social post.");
          openLinkOnNewTab(config.FACEBOOK_URL);
          break;
        case SocialShareType.share_instagram:
          navigator.clipboard.writeText(shareCompanyFeed?.description ?? "");
          showSuccessMessage("Copied the social post.");
          openLinkOnNewTab(config.INSTAGRAM_URL);
          break;
        case SocialShareType.share_linkedin:
          navigator.clipboard.writeText(shareCompanyFeed?.description ?? "");
          showSuccessMessage("Copied the social post.");
          openLinkOnNewTab(config.LINKEDIN_URL);
          break;
      }
    }
  };


  return (
    <TabPanel
      value={value}
      index={index}
      sx={{ mt: 0, p: 0, minHeight: "100vh" }}
    >
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        gap={1}
      >
        {pinnedSocialPosts && pinnedSocialPosts.length > 0 && (<Box sx={{ mt: 2, width: '100%' }}>
          <ImageList cols={3} gap={15} >
            {pinnedSocialPosts.map((postItem: CompanyFeed, index: number) => (
              <ImageListItem
                key={postItem.id}
                className='preferred-partner-image-border'
              >
                <img
                  onClick={async () => {
                    dispatch(setCommunityFeed(postItem));
                    onOpenCommunity();
                  }}
                  src={
                    isNotEmpty(postItem?.contents)
                      ? postItem?.contents[0]?.type === 'image'
                        ? postItem?.contents[0]?.link
                        : postItem?.contents[0]?.thumbnail ??
                        '/static/images/placeholder_profile.png'
                      : '/static/images/placeholder_profile.png'
                  }
                  alt={postItem?.contents[0]?.filename ?? ''}
                  loading='lazy'
                  style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '8px',
                    maxHeight: '250px'
                  }}
                // className='preferred-partner-image-border'
                />
                <ImageListItemBar
                  title={postItem.title}
                  sx={{
                    borderBottomLeftRadius: '8px',
                    borderBottomRightRadius: '8px'
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>)}


        {/* { <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          gap={1}
          sx={{ width: "100%" }}
        >
          {userInfo?.company?.title &&
            userInfo?.company.is_pagetitle_enable == 1 && (
              <Typography
                gutterBottom
                variant='h5'
                component='div'
                color='primary'
                fontWeight='bold'
                mt={{ md: 1, sm: 0.5, xs: 0.5 }}
              >
                {comapanyTitle}
              </Typography>
            )}

          {((promotional_video &&
            userInfo?.company?.is_media_enable == 1) || (profile_picture && userInfo?.company?.is_media_enable == 1) || (description &&
              userInfo?.company?.is_description_enable == 1)) && (<Card
                sx={{
                  width: "100%",
                }}
                className='we-are-live-post-video-card'
              >
                <CardContent
                  component='div'
                  sx={{
                    padding: 0,
                    '&:last-child': { pb: 0 },
                  }}
                >
                  {promotional_video &&
                    userInfo?.company?.is_media_enable == 1 && (
                      <Box
                        mb={2}
                        display='flex'
                        justifyContent='center'
                      >
                        <video
                          controls
                          width='100%'
                          height='100%'
                          poster={thumbnail}
                          style={{ maxHeight: '50vh' }}
                        >
                          <source
                            src={promotional_video}
                            type='video/mp4'
                          />
                          Your browser does not support the video tag.
                        </video>
                      </Box>
                    )}
                  {profile_picture && userInfo?.company?.is_media_enable == 1 && (
                    <Box
                      mb={2}
                      display='flex'
                      justifyContent='center'
                    >
                      <img
                        src={profile_picture}
                        alt={comapanyTitle}
                        width='100%'
                        style={{ maxHeight: '50vh', objectFit: 'contain' }}
                      />
                    </Box>
                  )}

                  <Stack
                    direction='column'
                    gap={3}
                    height='35%'
                    justifyContent='flex-start'
                  >
                    {description &&
                      userInfo?.company?.is_description_enable == 1 && (
                        <Stack
                          direction='row'
                          alignItems='center'
                          justifyContent='space-between'
                          className='we-live-border-bottom'
                        >
                          <Typography
                            gutterBottom
                            variant='h6'
                            component='div'
                            color='primary'
                            sx={{ whiteSpace: 'pre-line' }}
                          >
                            {description}
                          </Typography>
                        </Stack>
                      )}

                  </Stack>
                </CardContent>
              </Card>)}
        </Box>} */}

        {socialPosts?.map((post, idx) => {
          return (
            <Card
              ref={
                idx + 1 === socialPosts?.length
                  ? lastElementRef
                  : undefined
              }
              key={idx}
              sx={{
                width: "100%",
                boxShadow: 'unset !important',
                p: 0
              }}
              className='we-are-live-post-card'
            >
              <Stack
                direction='column'
                gap={1}
              >
                <Box
                  display='flex'
                  alignItems='center'
                  height='60px'
                  sx={{ pl: 2.5, pr: 2, pt: 1 }}
                >
                  <Box width='50px'>
                    <Avatar
                      alt='pic'
                      src={
                        post.company?.owner?.profile_picture ??
                        '/static/images/placeholder_profile.png'
                      }
                    />
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='flex-start'
                    justifyContent='center'
                    flex={1}
                  >
                    <Typography
                      variant='h6'
                      component='div'
                      color='black'
                      className='post-main-title'
                      sx={{ color: AppColors.blackColor }}
                    >
                      {post.company?.owner?.name ?? ''}
                    </Typography>

                    <Typography
                      variant='h6'
                      component='div'
                      fontSize='10px'
                      className='post-main-sub-title'
                      sx={{ color: AppColors.grayTwoColor }}
                    >
                      {post.company?.owner?.occupation ?? ''}

                      <Box component={'span'}
                        sx={{ marginLeft: '10px', color: AppColors.grayTwoColor }}
                      >
                        {moment(post?.created_at).format(
                          'MMMM DD, YYYY'
                        )}
                      </Box>
                    </Typography>
                  </Box>

                </Box>

                <RenderCompanyMediaFeed companyFeed={post} />
                <CardContent
                  sx={{
                    pl: 2.5, pr: 2,
                    pt: 0,
                    '&:last-child': { pb: '16px' },
                  }}
                >
                  <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <Typography
                        variant='body1'
                        gutterBottom
                        sx={{ color: AppColors.blackColor, fontSize: "medium", fontWeight: '500' }}
                      >
                        {post.title}
                      </Typography>
                      <Typography
                        variant='body2'
                        className='link'
                        sx={{ whiteSpace: 'pre-line', color: AppColors.grayTwoColor }}
                      >
                        <ReactReadMoreReadLess
                          charLimit={200}
                          readMoreText={'Read more'}
                          readLessText={'Read less'}
                          readMoreClassName='read-more-less-more'
                          readLessClassName='read-more-less-less'
                        >
                          {post.description}
                        </ReactReadMoreReadLess>
                      </Typography>
                    </Grid>
                  </Grid>
                  {/*   Commentout social comment share like  */}
                  <Box
                    display='flex'
                    mt={2}
                  >
                    <Stack
                      direction='row'
                      color='grey'
                      alignItems='center'
                      gap={1}
                      sx={{ mr: 3 }}
                    >
                      {loginUserInfo ? <><Box
                        aria-controls={openLikeMenu ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openLikeMenu ? 'true' : undefined}
                        onClick={(e) => post.reaction ? handleLikeMenuClose(post, undefined) : handleLikeMenuClick(e, idx)}
                      >
                        {(post.reaction && post.reaction.reaction_id >= 0 && post.reaction.reaction_id <= 5) ? <Box component={'img'} src={SOCIAL_LIKE_ICONS[post.reaction.reaction_id]} sx={{
                          height: '1.5rem',

                        }}></Box> : <Box
                          component={'img'}
                          src={HeartIcon}
                          style={{
                            height: '1.5rem',
                            width: '1.5rem',
                          }}
                        />}</Box>
                        <Menu
                          anchorEl={likeAnchorEl}
                          open={openLikeMenu && activeLikeMenuIndex == idx}
                          onClose={() => handleLikeMenuClose()}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}

                        >
                          <MenuItem onClick={() => handleLikeMenuClose(post, 0)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[0]} sx={{
                            height: '1.5rem',

                          }}></Box></MenuItem>
                          <MenuItem onClick={() => handleLikeMenuClose(post, 1)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[1]} sx={{
                            height: '1.5rem',

                          }}></Box></MenuItem>
                          <MenuItem onClick={() => handleLikeMenuClose(post, 2)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[2]} sx={{
                            height: '1.5rem',

                          }}></Box></MenuItem>
                          <MenuItem onClick={() => handleLikeMenuClose(post, 3)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[3]} sx={{
                            height: '1.5rem',

                          }}></Box></MenuItem>
                          <MenuItem onClick={() => handleLikeMenuClose(post, 4)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[4]} sx={{

                            height: '1.5rem',
                          }}></Box></MenuItem>
                          <MenuItem onClick={() => handleLikeMenuClose(post, 5)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[5]} sx={{
                            height: '1.5rem',
                          }}></Box></MenuItem>
                        </Menu>
                      </> : <Box
                        component={'img'}
                        src={HeartIcon}
                        sx={{
                          height: '1.5rem',
                          width: '1.5rem'
                        }}
                        onClick={() => openSocialDailog(post)}
                        style={{
                          height: '1.5rem',
                          width: '1.5rem',
                          color: AppColors.primaryColor,
                        }}
                      />}

                      <Typography
                        variant='body2'
                        component='div'
                        sx={{ color: AppColors.grayTwoColor }}
                      >
                        {post?.reactions_count ?? 0}
                      </Typography>
                    </Stack>

                    <Stack
                      direction='row'
                      color='grey'
                      alignItems='center'
                      gap={1}
                      sx={{ mr: 3 }}
                    >
                      <Box
                        component={'img'}
                        src={CommentIcon}
                        onClick={() => loginUserInfo ? openFeedCommentDailog(post) : openSocialDailog(post)}
                        sx={{
                          height: '1.5rem',
                          width: '1.5rem',
                        }}
                      />

                      <Typography
                        variant='body2'
                        component='div'
                        sx={{ color: AppColors.grayTwoColor }}
                      >
                        {post?.comments_count ?? 0}
                      </Typography>
                    </Stack>
                    <Stack
                      direction='row'
                      color='grey'
                      alignItems='center'
                      gap={1}
                      sx={{ mr: 3 }}
                    >
                      <Box
                        aria-controls={openLikeMenu ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openLikeMenu ? 'true' : undefined}
                        onClick={(e) => loginUserInfo ? handleShareMenuClick(e, idx) : openSocialDailog(post)}
                      >
                        <Box
                          component={'img'}
                          src={ShareIcon}
                          sx={{
                            height: '1.5rem',
                            width: '1.5rem',
                          }}
                        /></Box>
                      <Menu
                        anchorEl={shareAnchorEl}
                        open={openShareMenu && activeShareMenuIndex == idx}
                        onClose={() => handleShareMenuClose()}
                        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                      >
                        <MenuItem onClick={() => handleShareMenuClose(post, SocialShareType.link)}>Share Link</MenuItem>
                        {checkIsProUser(loginUserInfo) && (<MenuItem onClick={() => handleShareMenuClose(post, SocialShareType.community)}>Share with Community</MenuItem>)}
                        <MenuItem onClick={() => handleShareMenuClose(post, SocialShareType.share_faceook)}>Copy to Facebook</MenuItem>
                        <MenuItem onClick={() => handleShareMenuClose(post, SocialShareType.share_instagram)}>Copy to Instagram</MenuItem>
                        <MenuItem onClick={() => handleShareMenuClose(post, SocialShareType.share_linkedin)}>Copy to LinkedIn</MenuItem>
                      </Menu>
                      <Typography
                        variant='body2'
                        component='div'
                        sx={{ color: AppColors.grayTwoColor }}
                      >
                        {post?.shares_count ?? 0}
                      </Typography>
                    </Stack>
                    <Stack
                      direction='row'
                      color='grey'
                      alignItems='end'
                      justifyContent={'flex-end'}
                      gap={1}

                      sx={{ width: '100%' }}
                    >

                      <Box
                        component={'img'}
                        src={EyeIcon}
                        style={{
                          height: '1.5rem',
                          width: '1.5rem'
                        }}
                      />

                      <Typography
                        variant='body2'
                        component='div'
                        sx={{ color: AppColors.grayTwoColor }}
                      >
                        {post?.views_count ?? 0}
                      </Typography>
                    </Stack>
                  </Box>
                </CardContent>
              </Stack>
            </Card>
          );
        })}
      </Box>

      {
        isLoading ? (
          <Box
            display='flex'
            justifyContent='center'
            mt={2}
          >
            <CircularProgress />
          </Box>
        ) : <></>
      }
      <SocialDailog
        onClose={onCloseSocialDailog}
        userName={userName ?? ''}
        communityPostId={communityPost?.id ?? 0}
        toggle={toggleSocialDailog}
        deepLink={communityPost?.deep_link}
      />
      <ViewCommunityModal
        toggle={toggleCommunity}
        onClose={onCloseCommunity}
        redirectToContact={false}
      />
      {
        communityPost && <CompanyFeedCommentsModal
          toggle={toggleFeedComment}
          onClose={onCloseFeedComment}
          companyFeed={communityPost} />
      }

      {
        communityPost && <CompanyFeedShareLinkModal
          toggle={toggleFeedLinkShare}
          onClose={onCloseFeedLinkShare}
          companyFeed={communityPost} />
      }
    </TabPanel >
  );
};

export default WeAreLiveTabPanel;
