import { createSlice } from '@reduxjs/toolkit';
import { getEngageActivityCount } from './api';
import { initialState } from './api/interface';

const engageSlice = createSlice({
  name: 'engage',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getEngageActivityCount.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getEngageActivityCount.fulfilled, (state, action) => {
      state.data = action.payload.data.data;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getEngageActivityCount.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset } = engageSlice.actions;
export default engageSlice.reducer;
