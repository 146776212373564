import PropTypes from 'prop-types';
import { Box, List, ListSubheader } from '@mui/material';
import { DrawerMenuListRootView } from './DrawerRootMenuListView';
import { styled } from '@mui/material/styles';
import DownloadAppMessageModel from './modal/DownloadAppMessageModel';
import useToggle from '../hooks/useToggle';
import ExternalLinkModel from './modal/ExternalLinkModel';
import { AppDispatch, useAppSelector } from '../store';
import { checkIsProUser } from '../utils/checkPro';

import styles from "../styles/HamburgerMenuModal.module.css";
import { useNavigate } from 'react-router-dom';
import { config } from '../utils/utils';
import { encryptString } from '../utils/url';
import { getItem } from '../utils/localStorage';
import { getPlanLabel } from '../utils/utilMethods';
import UserDashboardSidebar from '../layouts/dashboard/UserDashboardSidebar';
import { logoutUser } from '../store/auth/api';
import { useDispatch } from 'react-redux';

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));
DrawerMenuListView.propTypes = {
  menuConfig: PropTypes.array,
};

export default function DrawerMenuListView({
  menuConfig,
  isCollapse = false,

  ...other
}: {
  menuConfig: any;
  isCollapse: boolean;

}) {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Box {...other}>
      <UserDashboardSidebar />
    </Box >
  );
}
