import * as Yup from 'yup';

const shareReferSchema = Yup.object().shape({
    sender_first_name: Yup.string().required('First name is required.'),
    sender_last_name: Yup.string().required('Last name is required.'),
    sender_email: Yup.string().required('Email is required.'),
    sender_phone_number: Yup.string().required('Phone number is required.'),
    recipient_first_name: Yup.string().required('First name is required.'),
    recipient_last_name: Yup.string().required('Last name is required.'),
    recipient_email: Yup.string().required('Email is required.'),
    recipient_phone_number: Yup.string().required('Phone number is required.'),
});
export default shareReferSchema;
