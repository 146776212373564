import {
  Box,
  Container,
  IconButton,
  Stack
} from '@mui/material';

import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import { config } from '../utils/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserInfo } from '../store/userInfo/api/interface';
import {
  selectMember
} from '../store/communityUsers/communityUsersSlice';
import { LoadingButton } from "@mui/lab";
import { encryptString } from '../utils/url';
import { followUserRequest, getIsFollowing, unFollowUserRequest } from '../store/communityUsers/api';
import ArrowRightIcon from '../assets/arrow_right.svg';
import {
  reset as resetSocialPostList
} from '../store/social/socialSlice';

const MemberVbc = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);

  const [selectedUser, setSelectedUser] = useState<UserInfo | undefined>(undefined);
  const [isFollowing, setIsFollowing] = useState<boolean | undefined>(undefined);

  const [isFollowUnfollowing, setIsFollowUnfollowing] = useState<boolean>(false);

  const selectedMember = useAppSelector((state) => state.communityUsers.selectedMember);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setSelectedUser(selectedMember);
    dispatch(selectMember(undefined));
    if (selectedMember == undefined) {
      navigate(`/`);
    } else {
      dispatch(getIsFollowing({ user_id: selectedMember.id })).unwrap().then((result) => {
        if (result.status == 200) {
          setIsFollowing(result.data.is_following ?? false);
        }
      });
    }
  }, []);

  const handleMessageClick = () => {
    if (selectedUser != undefined && user != undefined) {
      var chatName = (selectedUser.id < user.id) ? `${selectedUser.id}-${user.id}` : `${user.id}-${selectedUser.id}`;
      navigate(`/dashboard/messaging?cid=${encryptString(chatName)}`);
    }
  }

  const handleFollowUnFollow = () => {
    if (selectedUser != undefined) {
      try {
        setIsFollowUnfollowing(true);
        if (isFollowing == true) {
          dispatch(unFollowUserRequest({ user_id: selectedUser.id })).unwrap().then((result) => {
            setIsFollowUnfollowing(false);
            if (result.status == 200 && result.data.status == "success") {
              setIsFollowing(false);
              resetSocialFeed();
            }
          });
        } else {
          dispatch(followUserRequest({ user_id: selectedUser.id })).unwrap().then((result) => {
            setIsFollowUnfollowing(false);
            if (result.status == 200 && result.data.status == "success") {
              setIsFollowing(true);
              resetSocialFeed();
            }
          });
        }
      } catch (e) {
        setIsFollowUnfollowing(false);
      }
    }
  }


  const resetSocialFeed = () => {
    dispatch(resetSocialPostList());
  };

  const handleGoBack = () => {
    navigate(-1);
  }


  return (
    <>
      <Page title='Dashboard | KnoCard' showAppBar={true}>
        <Container sx={{ maxWidth: "100% !important", pl: "0 !important", pr: "0 !important" }}>
          {selectedUser && <Stack flexDirection={'row'} sx={{ mt: { xs: 2, lg: 1 } }}>
            <IconButton
              onClick={handleGoBack}
              sx={{ ml: 1 }}
            >
              <Box
                component={'img'}
                src={ArrowRightIcon}
                sx={{
                  transform: `rotate(180deg)`
                }}
              />
            </IconButton>
            <Box sx={{
              mt: 1, mb: 1, width: '100%',
              mr: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}>
              {isFollowing != undefined && <LoadingButton
                type="button"
                loading={isFollowUnfollowing}
                variant="contained"
                onClick={handleFollowUnFollow}
                sx={{ mr: 1.5 }}
              >
                {isFollowing}  {isFollowing ? "Following" : "Follow"}
              </LoadingButton>}

              <LoadingButton
                type="button"
                loading={false}
                variant="contained"
                onClick={handleMessageClick}
              >
                Message
              </LoadingButton> </Box></Stack>}
          <Box sx={{ height: "100vh", width: "100%" }}>
            {selectedUser && <iframe height={'100%'} width={'100%'} style={{ border: 'none' }} src={`${config.VBC_URL}/${selectedUser?.username ?? ''}`} />}
          </Box>
        </Container>
      </Page >
    </>
  );
};
export default MemberVbc;
