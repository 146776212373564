import React, { useState } from 'react';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, List, Stack, TextField, Typography } from '@mui/material';

import UserListForMessage from './UserListForMessage';
import { ChatUser } from '../../../store/chat/api/interface';
import { useAppSelector } from '../../../store';
import { AppColors } from '../../../utils/utils';
import ArrowDownIcon from '../../../assets/chat/arrow_down.svg';
import FavoriteUserItem from './FavoriteUserItem';

interface IProps {
    chatUsers: ChatUser[];
    setChatUser: (chatUser: ChatUser) => void,
    lastElementRef: any
}

//ChatUser[]
const ChatList: React.FC<IProps> = ({ chatUsers, setChatUser, lastElementRef }) => {
    const [isFavoritesExpanded, setFavoritesExpanded] = useState<boolean>(false);
    const loginUserId = useAppSelector((state) => state?.auth?.user?.id);

    const pinedMessages = chatUsers.filter((chatUser) => Array.isArray(chatUser?.pined_by) && chatUser.pined_by?.includes(loginUserId ?? 0));

    return (
        <>
            <Box
                component={'div'}
                sx={{
                    padding: '10px',
                    overflowY: 'scroll',
                    height: 'calc(100% - 80px)',
                }}
            >
                {/* Pinned Messages Text */}
                {pinedMessages.length > 0 && <Box
                    component={'div'}
                    sx={{

                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        cursor: 'pointer'
                    }}

                    onClick={() => setFavoritesExpanded(!isFavoritesExpanded)}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: "500",
                                mr: 2,
                                fontSize: '1.2rem',
                                color: AppColors.blackColor,
                            }}
                        >
                            Favorites
                        </Typography>
                    </Box>
                    <Box>
                        <Box component={'img'} src={ArrowDownIcon} sx={{ ...(isFavoritesExpanded ? { transform: `rotate(180deg)` } : {}) }} />
                    </Box>
                </Box>}

                {/* PinnedMessage UserList */}
                {(pinedMessages.length > 0 && isFavoritesExpanded) && <Box sx={{ maxWidth: "calc(100vw - 32px)", overflow: "hidden", mt: 1 }}>
                    <Stack
                        flexDirection={'row'} sx={{ overflow: "auto" }}
                    >
                        {pinedMessages.map((chatUserItem) => (
                            <FavoriteUserItem chatUser={chatUserItem} loginUserId={loginUserId} setChatUser={setChatUser} />
                        ))}
                    </Stack>
                </Box>}

                {/* All Messages Text */}
                {/* <Box
                    component={'div'}
                    sx={{
                        mt: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                    }}
                >
                    <Box
                        component={'img'}
                        src='/static/images/chatRoundBlue.svg'
                        alt='search icon'
                        sx={{
                            width: '30px',
                            height: '30px',
                            color: AppColors.primaryColor,
                        }}
                    />
                    <Box>
                        <Typography
                            variant='h6'
                            color={'primary'}
                            sx={{
                                fontWeight: '500',
                                ml: 2,
                            }}
                        >
                            All Messages
                        </Typography>
                    </Box>
                </Box> */}
                {/* AllMessage UserList */}
                <Box>
                    <List>
                        {chatUsers.map((userListItem, index) => (
                            <UserListForMessage chatUser={userListItem} loginUserId={loginUserId} setChatUser={setChatUser} lastElementRef={(index + 1) == chatUsers.length ? lastElementRef : undefined} />
                        ))}
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default ChatList;
