import React, { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import Button, { ButtonProps } from "@mui/material/Button";
import { Box, Card, CardActionArea, CardContent, Grid, Stack, useMediaQuery } from "@mui/material";

import { config } from "../../utils/utils";
import styled from "@emotion/styled";
import styles from "../../styles/AffiliateLanding.module.css";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../store";
import Loader from '../../components/Loader';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useToggle from "../../hooks/useToggle";
import { getUserInfo } from "../../store/userInfo/api";
import { UserInfo } from "../../store/userInfo/api/interface";
import useWindowSize from "../../utils/useWindowSize";
import ContactFormModal from '../../components/ContactFormModal';
import { setUserInfo } from "../../store/userInfo/userInfoSlice";
import { ContactFormSourceType } from "../../enum";
import { checkIsProProOrProOneOrProPlusUser } from "../../utils/checkPro";
import GroupNetworkModal from "../../components/GroupNetworkModal";
import { LandingPageNewNavBar } from "../../components/LandingPageNewNavBar";
import NetworkLandingPageConfirmationModal from "../../components/NetworkLandingPageConfirmationModal";
import { findAffiliateGroup } from "../../store/AffiliateGroup/api";
import { removeItem, setItem } from "../../utils/localStorage";
import { analytics } from "../../store/shareRefer/api";


const OpenButton = styled(Button)<ButtonProps>(({ theme }) => ({
  // backgroundColor: "#00cc00",
  backgroundImage: 'linear-gradient(270deg, #70F837 0%,   #1CB85D 100%)',
  color: "#000000",
  borderRadius: "30px",
  fontSize: "small",
  fontWeight: "bold",
  "&:hover": {
    background: "white",
    color: "#00cc00",
  },
  minWidth: "122px"
}));


const StopLightGoLandingPage = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();


  const authUserInfo = useAppSelector((state) => state?.auth?.user);

  const [searchParams] = useSearchParams();
  const [referredByLink, setReferredByLink] = useState('');
  const [referrUserInfo, setReferrUserInfo] = useState<UserInfo | undefined>(undefined);

  const [calendarLink, setCalendarLink] = useState('https://calendly.com/knocard/connect-with-me');


  const windowSize = useWindowSize();
  const [tabMinWidth, setTabMinWidth] = useState(90);
  const matches = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    const newWidth = matches ? windowSize.width / 5 : 90;
    setTabMinWidth(newWidth);
  }, [windowSize]);


  const productList = [
    {

      imageUrl: "/static/stop_light_go_landing_page/acal+.webp",
      title: "ACAI+",
      description: "Kickstart your day and wind down your evening with this full-spectrum, whole-body health drink. Uncover the incredible health benefits of ACAI+.",
      videoUrl: "https://storage-prod.knocard.net/stop_light_go_landing_page_video/slg_acai+.mp4"
    },
    {

      imageUrl: "/static/stop_light_go_landing_page/blaze.webp",
      title: "Blaze",
      description: "Enhance your gas mileage, reduce emissions & clean your engine with our patented, EPA-registered fuel catalyst.",
      videoUrl: "https://storage-prod.knocard.net/stop_light_go_landing_page_video/blaze.mp4"
    },
    {
      imageUrl: "/static/stop_light_go_landing_page/voyager.webp",
      title: "Voyager",
      description: "Access exclusive deals on hotels, cruises, condos, shopping, and more that are not available to the general public.",
      videoUrl: "https://storage-prod.knocard.net/stop_light_go_landing_page_video/voyager.mp4"
    }
  ];

  const productFeaturesList = [
    {

      imageUrl: "/static/stop_light_go_landing_page/spectator_waiting_list.webp",
      title: "Spectator Waiting list FREE",
      features: ["Free access to products", "Enrollment in our community"],
      buttonTitle: "Join for Free"
    },
    {

      imageUrl: "/static/stop_light_go_landing_page/test_driver_customer_affiliate.webp",
      title: "Test Driver Customer affiliate Only $9.99/mo",
      features: ["Locked racing circuit position", "Up to 25% store discount", "$200 Hotel Credits / Year", "3 ways to earn"],
      buttonTitle: "Let's Go!"
    },
    {
      imageUrl: "/static/stop_light_go_landing_page/driver_business_builder.webp",
      title: "Driver Business builder Starting from $299",
      features: ["Locked racing circuit position", "Up to 25% store discount", "Monthly product package", "7 ways to earn"],
      buttonTitle: "I'm Ready!"
    }
  ];


  const {
    toggle: toggleContactForm,
    onOpen: onOpenContactForm,
    onClose: onCloseContactForm,
  } = useToggle();

  const {
    toggle: toggleNetworkLandingPage,
    onOpen: onOpenNetworkLandingPage,
    onClose: onCloseNetworkLandingPage,
  } = useToggle();

  const {
    toggle: toggleGroup,
    onOpen: onOpenGroup,
    onClose: onCloseGroup,
  } = useToggle();


  const handleContactFormSubmitSuccess = (contactCode: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete(config.PROSPECT_KEY);
    queryParams.set('sc', contactCode);
    navigate(`/network-landing-page?${queryParams.toString()}`);
  };





  useEffect(() => {
    let refferLink = `${config.VBC_URL}`;
    let userName = "";

    if (searchParams.has(config.REFER_KEY)) {
      userName = searchParams.get(config.REFER_KEY) ?? '';
      refferLink += `/affiliate/${userName}`;
    } else {
      refferLink += "/register";
    }
    setReferredByLink(refferLink);

    if (userName.length > 0) {
      dispatch(getUserInfo({ username: userName })).unwrap().then((res) => {
        if (res.data.data.user.length > 0) {
          setReferrUserInfo(res.data.data.user[0]);
          dispatch(setUserInfo(res.data));
        }
      });
    }
    dispatch(findAffiliateGroup()).unwrap().then((result) => {
      if (result && result.status == 200 && result.data.data) {
        result.data.data.forEach((element: any) => {
          if (element.code === "stoplightdemo") {

            setItem(config.GROUP_NETWORK_ID_KEY, element?.id);
          }
        });
      }
    });


  }, []);


  useEffect(() => {
    if (referrUserInfo) {
      if (searchParams.has(config.PROSPECT_KEY) && searchParams.get(config.PROSPECT_KEY) && checkIsProProOrProOneOrProPlusUser(referrUserInfo)) {
        onOpenContactForm();
      }

      saveLadingPageViewActivity();
    }
  }, [referrUserInfo]);



  const saveLadingPageViewActivity = () => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let elid = searchParams.get('elid');
      let loginUserId = authUserInfo?.id;
      dispatch(
        analytics({
          route: '/activity/save',
          data: {
            actionType: 'view',
            sourceType: 'link_share',
            module: 'group_landing_page',
            targetId: referrUserInfo?.id,
            identifiableId: referrUserInfo?.id,
            ...(loginUserId && { viewableId: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
            ...((!loginUserId && elid) && { elid: elid })
          },
        })
      );
    }
  }


  useEffect(() => {

    if (referrUserInfo) {
      if ((referrUserInfo.calendar_link ?? "").trim().length > 0) {
        setCalendarLink(referrUserInfo.calendar_link ?? "");
      } else {
        setCalendarLink(`tel:${referrUserInfo.phone_country_code ?? ""}${referrUserInfo.phone_number}`);
      }


    }

  }, [referrUserInfo])


  const getKnoCardSubscriptionLink = () => {
    const subscriptionUrl = `${config.VBC_URL}/subscription/${referrUserInfo?.username || ""}`

    return subscriptionUrl;
  }




  const handleOpenPricing = () => {
    removeItem(config.GROUP_NETWORK_ID_KEY);
    const subscriptionUrl = `${config.VBC_URL}/subscription/${referrUserInfo?.username || ""}`
    window.open(`${subscriptionUrl}`) || window.location.replace(`${subscriptionUrl}`);
  }

  const handleStopLightGo = () => {

    const stopLightGoUrl = "https://stoplightgo.com/?ref=tomhan897";
    window.open(`${stopLightGoUrl}`) || window.location.replace(`${stopLightGoUrl}`);
  }

  const handleOnVideoPlayActivity = (videoTitle: string, second: number, duration: number) => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let loginUserId = authUserInfo?.id;

      dispatch(
        analytics({
          route: '/landing-page/video-watch',
          data: {
            target_id: referrUserInfo?.id ?? 0,
            module: "group_landing_page",
            title: videoTitle,
            target_page: "stop-light-go-landing-page",
            video_watch_seconds: second,
            video_duration_seconds: duration,
            ...(loginUserId && { viewable_id: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
          },
        })
      );
    }
  };


  const handleOnPauseOrCompleteVideoActivity = (videoTitle: string, second: number, duration: number) => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let loginUserId = authUserInfo?.id;

      dispatch(
        analytics({
          route: '/landing-page/video-watch-time',
          data: {
            target_id: referrUserInfo?.id ?? 0,
            module: "group_landing_page",
            title: videoTitle,
            target_page: "stop-light-go-landing-page",
            video_watch_seconds: second,
            video_duration_seconds: duration,
            ...(loginUserId && { viewable_id: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
          },
        })
      );
    }
  };

  const handleOnVideoPlay = (videoTitle: string, time: number, duration: number) => {

    if (time == 0) {
      handleOnVideoPlayActivity(videoTitle, time, duration);
    } else {
      handleOnPauseOrCompleteVideoActivity(videoTitle, time, duration);
    }
  }


  return (
    <React.Fragment>
      <LandingPageNewNavBar userInfo={referrUserInfo} affiliateUserName={referrUserInfo?.username} getKnoCardLink={getKnoCardSubscriptionLink()} />



      {/* SECTION 1  */}
      <section className={styles.section_1}>
        <Box

          sx={{ pb: 2, width: "100%", background: 'url("/static/stop_light_go_landing_page/section_one_bg.webp")', backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
        // sx={{
        //   minHeight: { xs: 'calc(100vh - 77px)', md: "unset" }
        // }}
        // flexDirection={'column'}
        // display='flex'
        // justifyContent='center'
        >
          <Stack flexDirection={"column"} sx={{
            pt: 2,
            pb: 1, alignContent: { xs: "flex-start", sm: "center" }, alignItems: { xs: "start", sm: "center" }
          }}>
            <Box sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FEFF09",
              width: "fit-content",
              mr: 2,
              pb: "10px",
              pt: "10px",
              borderRadius: "10px",
              borderTopLeftRadius: { xs: "0px", sm: "10px" },
              borderBottomLeftRadius: { xs: "0px", sm: "10px" }
            }}>
              <Typography
                component='p'
                sx={{
                  fontSize: '1.2rem',
                  color: '#000000',
                  textAlign: 'center',
                  pl: 4,
                  pr: 4,
                  pt: 0.5,
                  pb: 0.5,
                  lineHeight: 1,
                  borderRadius: "10px",
                  fontWeight: "bold",
                  '& span': {
                    fontSize: '1.5rem',
                  }
                }}
              >
                <span>J</span>oin the <span>R</span>evolution....

              </Typography>
            </Box>

          </Stack>
          <Typography
            component='p'
            sx={{
              fontSize: '1.6rem',
              color: '#4A5460',
              textAlign: 'center',
              pl: 1,
              pr: 1,
              pt: 2,
              lineHeight: 1.3,
              pb: 0.5,
              borderRadius: "10px",
              '& span': {
                fontWeight: "bold",
                fontSize: '1.5rem',
                color: "#000000"
              }
            }}
          >
            <span>Get Huge</span> savings on great products...
          </Typography>

          <Typography
            component='p'
            sx={{
              fontSize: '1.6rem',
              color: '#4A5460',
              textAlign: 'center',
              pl: 1,
              pr: 1,
              pb: 2,
              lineHeight: 1.3,

              borderRadius: "10px",
              '& span': {
                fontWeight: "bold",
                fontSize: '1.5rem',
                color: "#000000"
              },
              '& :last-child': {
                borderBottom: "22px solid transparent",
                borderImage: "url('/static/stop_light_go_landing_page/under_line.webp') 42 stretch"
              },

            }}
          >
            Earn <span>BIG</span> through <span>Simple Sharing!</span>
          </Typography>

          <Stack flexDirection={"column"} sx={{
            mb: 2, pt: 2,
            pb: 1, alignContent: { xs: "flex-end", sm: "center" }, alignItems: { xs: "start", sm: "center" }
          }}>
            <Box sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              background: 'linear-gradient(270deg,#61EE3D 0%,#1AB45E 100%)',
              width: "fit-content",
              ml: 2,
              px: 1,
              py: 0.5,
              borderRadius: "10px",
              borderTopRightRadius: { xs: "0px", sm: "10px" },
              borderBottomRightRadius: { xs: "0px", sm: "10px" }
            }}>
              <Typography
                component='p'
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: "bold",
                  color: '#ffffff',
                  textAlign: 'center',
                  pl: 1,
                  pr: 1,
                  pt: 0.5,
                  lineHeight: 1,
                  pb: 0.5,
                  borderRadius: "10px",
                  '& span': {
                    color: "#000000"
                  }
                }}
              >
                When you shop - <span>You SAVE</span>
              </Typography>
              <Typography
                component='p'
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: "bold",
                  color: '#ffffff',
                  textAlign: 'center',
                  pl: 1,
                  pr: 1,
                  pt: 0.5,
                  lineHeight: 1,
                  pb: 0.5,
                  borderRadius: "10px",
                  '& span': {
                    color: "#000000"
                  }
                }}
              >
                When others shop - <span>You EARN</span>
              </Typography>
            </Box>

          </Stack>
        </Box>
      </section>

      <section>
        <Box
          sx={{
            width: '100%',
            background: 'linear-gradient(0deg,#0F6299 0%,#051B27 100%)',
            backgroundSize: "cover",
            pt: 2,
            pb: 2,
            position: 'relative',
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Box component={"img"} src={"/static/stop_light_go_landing_page/three_dots.webp"} sx={{ height: 30, position: "absolute", left: 30, top: -15 }}></Box>
          <Box
            component={'img'}
            sx={{ width: '100%', maxWidth: "200px", position: "absolute", top: "30%", opacity: 0.1 }}
            src='/static/Affiliate_landing/dots_left.png'
            alt=''
          />

          <Box sx={{
            zIndex: 1,
            textAlign: "center", width: "100%", display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pr: 2
          }}>
            <Box component={"img"} src={"/static/rejection_free/white_line.png"}></Box>
            <Typography
              component='p'
              className="architects-daughter-regular"
              sx={{
                fontSize: '1.3rem',
                color: '#00000',
                backgroundColor: "#FEFF09",
                textAlign: 'center',
                mt: 1,
                mb: 2,
                p: 2,
                width: 'fit-content',
                borderRadius: "10px",

              }}
            >
              As a stop Light Go member you'll enjoy exclusive benefits & savings on products including those displayed below.
            </Typography>

          </Box>
          <Grid
            container
            spacing={2}

            justifyContent={"center"}
            sx={{
              position: "relative",
              textAlign: "center",
              pl: 2,
              pr: 2,
              mt: 1.5,
              ml: 0,
              overflow: "hidden",
              background: "#19A05A",
              width: "fit-content",
              border: "2px solid #23C5D6",
              borderRadius: 5
            }}
          >

            {productList && productList.map((item, index) => {
              return (<Grid item sx={{ zIndex: 1, px: 1, pb: 2 }} >
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{
                    overflow: 'hidden',
                    display: '-webkit-box',
                    '-webkit-line-clamp': '1',
                    '-webkit-box-orient': 'vertical',
                    color: '#FFFFFF',
                    fontSize: "large",
                    fontWeight: "bold",
                    mb: 1
                  }}
                >
                  {item.title}
                </Typography>
                <Card
                  sx={{
                    maxWidth: 345,
                    backgroundColor: "transparent",
                    boxShadow: "none"
                  }}

                >

                  <Box component={'div'} sx={{
                    p: 2,
                    border: "2px solid #ffffff",
                    borderRadius: "15px",
                    backgroundColor: "#042C42"
                  }}>
                    <Box
                      component='div'
                      sx={{
                        background: "#E0E1E1",
                        mt: 2,
                        border: "2px solid #ffffff",
                        borderRadius: "15px"
                      }}>

                      {
                        item.videoUrl.length > 0 ? (<Box
                          component={'video'}
                          controlsList="nodownload"
                          autoPlay={false}
                          id='video1'
                          controls
                          poster={`${item.imageUrl}`}
                          sx={{
                            height: '160px',
                            mt: { sm: 1, xs: 2 },
                            position: "relative",
                            width: '95%',
                            ...(index == 2 ? { objectFit: 'cover' } : {})
                          }}
                          onPlay={(props: any) => {
                            if (props.currentTarget.currentTime == 0) {
                              handleOnVideoPlay(item.title, 0, props.currentTarget.duration || 0);
                            }
                          }}
                          onPause={(props: any) => {
                            handleOnVideoPlay(item.title, props.currentTarget.currentTime, props.currentTarget.duration || 0);
                          }}
                        >
                          <source src={`${item.videoUrl}`} type='video/mp4' />
                        </Box>) : (<Box
                          component='div'
                          height='160px'
                          sx={{

                            background: `url(${item.imageUrl})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: (index == 2 ? "cover" : "contain"),

                            borderRadius: "15px"
                          }}
                        />)

                      }


                    </Box>

                    <CardActionArea
                    >
                      <CardContent
                        sx={{
                          p: 0.5,
                          '&:last-child': { p: 1 },
                          minHeight: '36px',
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box>

                          <Typography
                            variant='body2'
                            color='text.secondary'
                            sx={{
                              pt: 1.5,
                              fontSize: "medium",
                              overflow: 'hidden',
                              display: '-webkit-box',
                              // '-webkit-line-clamp': '3',
                              // '-webkit-box-orient': 'vertical',
                              color: '#ffffff',

                            }}
                          >
                            {item.description}

                          </Typography>
                          {(index != 0) && <br />}
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Box>
                </Card>
              </Grid>);
            })}
          </Grid>
        </Box>
      </section>


      <section>
        <Box
          sx={{
            width: '100%',
            background: 'url("/static/stop_light_go_landing_page/section_three_bg.webp")',
            backgroundSize: "cover",
            pt: 2,
            pb: 2,
            position: 'relative',
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >


          <Box sx={{ textAlign: 'center', mt: 2, pb: 2 }}>
            <Typography
              sx={{

                color: "#000000",
                fontSize: "xx-large",
                fontWeight: "bold",

                pl: 4,
                pr: 4,
                pt: 1.5,
                pb: 1.5,
                textTransform: 'unset'
              }}
            >
              Welcome to Stop Light Go
            </Typography>
          </Box>



          <Box sx={{ textAlign: 'center', position: "relative", width: "fit-content" }}>
            <Box sx={{ height: { xs: "150px", md: "200px" }, width: "100%", position: "absolute", backgroundColor: "#4469B1" }}></Box>
            <Box
              component={'video'}
              controlsList="nodownload"
              autoPlay={false}
              id='video1'
              controls
              poster={"/static/stop_light_go_landing_page/welcome_to_stop_light_go.webp"}
              sx={{
                mt: { sm: 1, xs: 2 },
                position: "relative",
                maxWidth: '95%',
                height: { xs: 'auto', md: '400px' },
              }}
              onPlay={(props: any) => {
                if (props.currentTarget.currentTime == 0) {
                  handleOnVideoPlay("StopLightGO Intro", 0, props.currentTarget.duration || 0);
                }
              }}
              onPause={(props: any) => {
                handleOnVideoPlay("StopLightGO Intro", props.currentTarget.currentTime, props.currentTarget.duration || 0);
              }}
            >
              <source src={"https://storage-prod.knocard.net/stop_light_go_landing_page_video%2Fvideo1.mp4"} type='video/mp4' />
            </Box>
          </Box>
        </Box>
      </section>

      <section>
        <Box
          sx={{
            width: '100%',
            background: '#051D28',
            pb: { md: 1, xs: 2 },
            pt: { md: 1, xs: 2 },
            textAlign: "center",
            position: "relative",

          }}
        >
          <Grid container

            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <Grid item xs={12} md={3}>
              <Box component={"img"} src={"/static/stop_light_go_landing_page/three_dots.webp"} sx={{ height: 15 }}></Box>
              <Box component={"img"} src={"/static/stop_light_go_landing_page/three_dots.webp"} sx={{ height: 15 }}></Box>
              <Box component={"img"} src={"/static/stop_light_go_landing_page/three_dots.webp"} sx={{ height: 15 }}></Box>
            </Grid>
            <Grid item xs={12} md={6}> <Typography
              component='p'
              sx={{
                fontSize: "xx-large",
                fontFamily: "'Lato', sans-serif",
                color: '#FFFFFF',
                textAlign: 'center',
                fontWeight: "bold",
                mb: 1,
                p: 1,
                ml: 2,
                pt: 2
              }}
            >
              Membership Plans
            </Typography></Grid>
            <Grid item xs={12} md={3}>

              <Box component={"img"} src={"/static/stop_light_go_landing_page/three_dots.webp"} sx={{ height: 15 }}></Box>
              <Box component={"img"} src={"/static/stop_light_go_landing_page/three_dots.webp"} sx={{ height: 15 }}></Box>
              <Box component={"img"} src={"/static/stop_light_go_landing_page/three_dots.webp"} sx={{ height: 15 }}></Box>

            </Grid>
          </Grid>
          <Box component={"img"} src={"/static/stop_light_go_landing_page/dots.webp"} sx={{ position: "absolute", top: 0, height: "100%", right: 0, opacity: 0.03 }}></Box>

        </Box>
      </section>

      <section>
        <Box
          sx={{
            width: '100%',
            background: '#FFFFFF',
            pb: { md: 1, xs: 2 },
            pt: { md: 1, xs: 2 },
            textAlign: "center",
            position: "relative",
            boxShadow: "0px 3px 20px 6px #8B8B8B"
          }}
        >
          <Grid container

            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <Grid item xs={0} md={1}>

            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                component={'video'}
                controlsList="nodownload"
                autoPlay={false}
                id='video2'
                controls
                poster={"/static/stop_light_go_landing_page/test_driver.webp"}
                sx={{
                  mt: { sm: 1, xs: 2 },
                  position: "relative",
                  maxWidth: '95%',
                  height: { xs: 'auto', md: '400px' },
                }}
                onPlay={(props: any) => {
                  if (props.currentTarget.currentTime == 0) {
                    handleOnVideoPlay("Test Driver", 0, props.currentTarget.duration || 0);
                  }
                }}
                onPause={(props: any) => {
                  handleOnVideoPlay("Test Driver", props.currentTarget.currentTime, props.currentTarget.duration || 0);
                }}
              >
                <source src={"https://storage-prod.knocard.net/stop_light_go_landing_page_video%2Fvideo2.mp4"} type='video/mp4' />
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                component={'video'}
                controlsList="nodownload"
                autoPlay={false}
                id='video3'
                controls
                poster={"/static/stop_light_go_landing_page/driver.webp"}
                sx={{
                  mt: { sm: 1, xs: 2 },
                  position: "relative",
                  maxWidth: '95%',
                  height: { xs: 'auto', md: '400px' },
                }}
                onPlay={(props: any) => {
                  if (props.currentTarget.currentTime == 0) {
                    handleOnVideoPlay("Driver", 0, props.currentTarget.duration || 0);
                  }
                }}
                onPause={(props: any) => {
                  handleOnVideoPlay("Driver", props.currentTarget.currentTime, props.currentTarget.duration || 0);
                }}

              >
                <source src={"https://storage-prod.knocard.net/stop_light_go_landing_page_video%2Fvideo3.mp4"} type='video/mp4' />
              </Box>
            </Grid>
            <Grid item xs={0} md={1}>


            </Grid>
          </Grid>

        </Box ></section >



      <section>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 2
        }}>
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            sx={{
              position: "relative",
              textAlign: "center",
              pl: 2,
              pr: 2,
              mt: 1.5,
              ml: 0,
              overflow: "hidden",
              width: "fit-content",

            }}
          >

            {productFeaturesList && productFeaturesList.map((item, index) => {
              return (<Grid item sx={{ zIndex: 1, px: 1, pb: 2 }} >
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{
                    //overflow: 'hidden',
                    // display: '-webkit-box',
                    // '-webkit-line-clamp': '1',
                    // '-webkit-box-orient': 'vertical',
                    color: '#000000',
                    fontSize: "large",
                    fontWeight: "bold",
                    mb: 1,
                    width: "335px"
                  }}
                >

                  {item.title}

                </Typography>
                {index == 0 && <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{
                    color: '#000000',
                    fontSize: "large",
                    fontWeight: "bold",
                    width: "335px"
                  }}
                >
                  &nbsp;
                </Typography>}
                <Card
                  sx={{
                    maxWidth: 345,
                    backgroundColor: "transparent",
                    boxShadow: "none"
                  }}

                >

                  <Box component={'div'} sx={{
                    p: 2,

                    borderRadius: "15px",
                  }}>
                    <Box
                      component='div'
                      sx={{
                        mt: 2,
                        borderRadius: "15px"
                      }}>
                      <Box
                        component='div'
                        height='350px'
                        sx={{

                          background: `url(${item.imageUrl})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          borderRadius: "15px"
                        }}
                      /></Box>


                    <CardContent
                      sx={{
                        p: 0.5,
                        '&:last-child': { p: 1 },
                        minHeight: '36px',
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Box>
                        <Typography>
                          <Box component={"ul"} sx={{
                            textAlign: "left",
                            "& li": {
                              listStyle: "none",
                              fontWeight: "bold"
                            },
                            "& li::before": {
                              display: "inline-block",
                              content: '""',
                              backgroundImage: "url(/static/stop_light_go_landing_page/mark.webp)",
                              width: "20px",
                              height: "20px",
                              backgroundPosition: "bottom",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              mr: 0.7
                            }
                          }}>
                            {item.features.map((title, idx) => {
                              return (<Box component={"li"} key={`${index}-item-${idx}`}>{title}</Box>);
                            })}

                          </Box></Typography>
                        <OpenButton
                          sx={{
                            mt: 2,
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            width: 'fit-content',
                            textTransform: 'unset',
                            fontWeight: "bold",
                          }}
                          onClick={handleStopLightGo}
                        >
                          {item.buttonTitle}
                        </OpenButton>
                      </Box>
                    </CardContent>

                  </Box>
                </Card>
              </Grid>);
            })}
          </Grid>
        </Box>
      </section>




      <section>
        <Box
          sx={{
            width: '100%',
            background: 'linear-gradient(to top, #082A3E, #0B4265)',
            pt: 5,
            pb: 5,
            position: 'relative',
            overflow: "hidden"

          }}
        >

          <Typography
            sx={{
              fontSize: '2.3rem',
              textAlign: 'center',
              fontFamily: "'Lato', sans-serif",
              fontWeight: 'bold',
              pl: 1,
              pr: 1,
              color: "#FFFFFF"
            }}
          >
            So What's next?
          </Typography>

          <Box sx={{
            background: 'linear-gradient(270deg, #50DADE 0%, #104BA6 100%)',
            p: 1.5,
            mt: 2,
            mr: { sm: 5, xs: 2 },
            ml: { sm: 0, xs: 2 },
            borderRadius: "16px",
            borderTopLeftRadius: { sm: 0, xs: "16px" },
            borderBottomLeftRadius: { sm: 0, xs: "16px" }


          }}>
            <Typography sx={{ textAlign: "center", color: "#ffffff" }}>
              Ready to Get Started? Choose the option that works best for you and let’s get you on the road! Still have questions? Book a call on my calendar link to connect with me!
            </Typography>
          </Box>

          <Stack alignContent={"center"} alignItems={"center"} sx={{ mt: { sm: 0, xs: 0, md: 3 }, width: "100%", textAlign: "center" }} >
            <OpenButton
              sx={{
                mt: 2,
                mb: 2,
                paddingLeft: '20px',
                paddingRight: '20px',
                width: 'fit-content',
                textTransform: 'unset',
                fontWeight: "bold",
              }}
              onClick={handleStopLightGo}
            >
              Get Started
            </OpenButton>
          </Stack>

          <Box sx={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", width: "calc(100% - 96px)", position: "absolute", top: 296, display: { xs: "none", md: "block" }, height: "140px", border: "2px solid #FEFF09", ml: 12, borderRightWidth: "0px" }}></Box>
          <Stack
            flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
            sx={{ p: { sm: 2, xs: 2, md: 5 } }}


          >
            <Box
              component={'img'}
              src='/static/stop_light_go_landing_page/calendar.webp'
              sx={{
                height: '100px',
                objectFit: 'contain',
                mr: { sm: 0, xs: 0, md: '-5px' },
                zIndex: 1,
                mt: { sm: 0, xs: 0, md: 3 },
                borderRadius: "10px"
              }}
            />

            <Stack alignContent={"center"} alignItems={"center"} sx={{ mt: { sm: 0, xs: 0, md: 3 }, width: "100%", textAlign: "center" }} >
              <Typography
                sx={{
                  color: '#FEFF09',
                  fontWeight: "bold",
                  fontSize: '1.5rem',
                  pt: 1,
                  textAlign: 'center',
                }}
              >
                Questions? We've got answers..
              </Typography>
              <Button
                sx={{
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  width: 'fit-content',
                  textTransform: 'unset',
                  fontWeight: "bold",
                  backgroundImage: 'linear-gradient(0deg, #ffffff 0%, #FCC83A 100%)',
                  color: "#000000",
                  borderRadius: "30px",
                  fontSize: "small",
                  mt: 1.5,
                  "&:hover": {
                    background: "#ffffff",
                  },
                  minWidth: "122px"
                }}
                onClick={onOpenNetworkLandingPage}
              >
                Let's Chat
              </Button>


            </Stack>


            {referrUserInfo && (
              <Box
                sx={{
                  textAlign: 'center',
                  mt: { md: 0.2, xs: 4 },
                  zIndex: 100
                }}
                flexDirection={'column'}
              >
                <Box
                  component={'img'}
                  src={
                    referrUserInfo?.profile_picture
                      ? referrUserInfo?.profile_picture
                      : '/static/images/placeholder_profile.png'
                  }
                  sx={{
                    height: '150px',
                    width: '150px',
                    borderRadius: '75px',
                    border: '4px solid #FEFF09',
                  }}
                />
                <Typography
                  sx={{
                    mt: 1,
                    fontWeight: 'bold',
                    color: '#ffffff',

                  }}
                >
                  {referrUserInfo ? referrUserInfo.name : ''}
                </Typography>
                <Typography
                  sx={{
                    mb: 0.5,
                    color: "#ffffff",

                  }}
                >
                  {referrUserInfo ? referrUserInfo.occupation : ''}
                </Typography>
              </Box>
            )}

          </Stack>
        </Box>
      </section>


      <ContactFormModal
        onClose={onCloseContactForm}
        toggle={toggleContactForm}
        onSubmitSuccessRedirect={false}
        onSubmitSuccess={handleContactFormSubmitSuccess}
        source={ContactFormSourceType.WHAT_IS_KNOCARD}
      />

      <GroupNetworkModal
        onClose={onCloseGroup}
        toggle={toggleGroup}
        affiliateLink={referredByLink}
        userInfo={referrUserInfo}
      />

      <NetworkLandingPageConfirmationModal
        onClose={onCloseNetworkLandingPage}
        toggle={toggleNetworkLandingPage}
        link={calendarLink}
        userInfo={referrUserInfo}
      />
    </React.Fragment >
  );
};

export default StopLightGoLandingPage;

