import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { toastHandler } from '../../../utils/toastHandler';
import { CorporateGroupLandingPageVideoViewResponse, CorporateGroupMediaVideoViewResponse, GroupMediaResponse, MediaFeedbackFormCountResponse, ShareActivityCountReponse, SpecificGroupMediaShareActivityCountReponse, SpecificGroupMediaViewedActivityCountReponse, ViewedActivityCountReponse } from './interface';


export const getShareActivityCount = createAsyncThunk(
  'corporateReporting/shareActivityCount',
  async (data: { days: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<ShareActivityCountReponse>(`/api/reports/corporate/share-activity`, data);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const getSpecificGroupMediaShareActivityCount = createAsyncThunk(
  'corporateReporting/specificGroupMediaShareActivityCount',
  async (data: { days: number, media_ids: number[] }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<SpecificGroupMediaShareActivityCountReponse>(`/api/reports/corporate/selected-group-media-share-activity`, data);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const getViewActivityCount = createAsyncThunk(
  'corporateReporting/viewActivityCount',
  async (data: { days: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<ViewedActivityCountReponse>(`/api/reports/corporate/view-activity`, data);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const getSpecificGroupMediaViewdActivityCount = createAsyncThunk(
  'corporateReporting/specificGroupMediaViewdActivityCount',
  async (data: { days: number, media_ids: number[] }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<SpecificGroupMediaViewedActivityCountReponse>(`/api/reports/corporate/selected-group-media-view-activity`, data);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getMediaFeedbackFormCount = createAsyncThunk(
  'corporateReporting/mediaFeedbackFormCount',
  async (data: { days: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<MediaFeedbackFormCountResponse>(`/api/reports/corporate/media-feedback`, data);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const getGroupMedia = createAsyncThunk(
  'corporateReporting/groupMedia',
  async (data: { groupId: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<GroupMediaResponse>(`/api/share-affiliate-group-media/media/${data.groupId}`);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getCorporateGroupMediaVideoView = createAsyncThunk(
  'corporateReporting/corporateGroupMediaVideoView',
  async (data: { days: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<CorporateGroupMediaVideoViewResponse>(`/api/reports/corporate/media-video-view`, data);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const getCorporateGroupLandingPageVideoView = createAsyncThunk(
  'corporateReporting/corporatGroupLandingPageVideoView',
  async (data: { days: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<CorporateGroupLandingPageVideoViewResponse>(`/api/reports/corporate/landing-page-video-view`, data);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

