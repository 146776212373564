import React, { useEffect, useRef } from 'react';
import grapesjs, { Editor } from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import grapesjsBlocksBasic from 'grapesjs-blocks-basic';
import grapesjsBlocksFlexbox from 'grapesjs-blocks-flexbox';
import grapesjsPresetWebpage from 'grapesjs-preset-webpage';

interface IProps {
    onPageSave: (pageData: string) => void;
    pageData: string;
}

const GrapesJSEditor: React.FC<IProps> = ({ pageData, onPageSave }) => {
    const editorRef = useRef<Editor | null>(null);

    useEffect(() => {
        if (!editorRef.current) {
            editorRef.current = grapesjs.init({
                container: '#gjs',
                plugins: [grapesjsBlocksBasic, grapesjsBlocksFlexbox, grapesjsPresetWebpage],
                storageManager: { type: 'local', autosave: true, autoload: true, stepsBeforeSave: 1 },
                assetManager: {
                    assets: [], // Initially empty or populate with your assets

                    noAssets: 'No images available',
                    // Override the select function to add custom behavior
                    beforeUpload(asset) {
                        return false;
                        // Your custom logic for selecting an asset
                    },
                }
            });
        }

        try {
            editorRef.current?.loadProjectData(JSON.parse(pageData))
        } catch (e) { }

        editorRef.current.on('asset:open', () => {
            const modal = editorRef?.current?.Modal?.getContentEl();
            const uploadSection: any = modal?.querySelector('.gjs-am-upload');
            if (uploadSection) {
                uploadSection.style.display = 'none';
            }
        });

        editorRef.current.Panels.removeButton('options', 'canvas-clear');
        editorRef.current.Panels.removeButton('options', 'export-template');


        editorRef.current.Panels.addButton('options', [{
            id: 'save', className: 'fa fa-floppy-o icon-blank',
            command: function (editor: Editor, sender: any) {

                editor?.store().then((projectData) => {
                    const jsonStr = JSON.stringify(projectData);
                    onPageSave(jsonStr);
                });

            }, attributes: { title: 'Save Landing Page' }
        },]);
    }, []);

    useEffect(() => {
        if (editorRef.current) {
            try {
                editorRef.current?.loadProjectData(JSON.parse(pageData))
            } catch (e) {

            }
        }
    }, [pageData]);


    return (<div id="gjs"></div>);
};

export default GrapesJSEditor;
