import React, { ChangeEvent } from 'react';

import { Stack, Box, Typography } from '@mui/material';

import ChatList from './ChatList';
import SearchChat from './SearchChat';
import { ChatUser } from '../../../store/chat/api/interface';
import { AppColors } from '../../../utils/utils';

interface IProps {
    chatUsers: ChatUser[];
    chatUser: ChatUser | undefined,
    setChatUser: (chatUser: ChatUser) => void,
    onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void,
    lastElementRef: any

}

const ChatSidebar: React.FC<IProps> = ({ chatUser, chatUsers, setChatUser, onSearchChange, lastElementRef }) => {
    return (
        <>
            <Stack sx={{ height: '100%', background: AppColors.whiteColor, p: 2, borderRadius: "16px" }}>
                <Box >
                    <Typography
                        variant='h6'

                        sx={{ textAlign: 'left', mb: 2, ml: 2, mr: 2, color: AppColors.blackColor }}
                    >
                        Inbox
                    </Typography>
                </Box>
                {/* Search */}
                <Box sx={{ mr: (chatUser != undefined) ? 2 : 0 }}><SearchChat onSearchChange={onSearchChange} /></Box>
                {/* User Chat List */}
                <ChatList chatUsers={chatUsers} setChatUser={setChatUser} lastElementRef={lastElementRef} />
            </Stack>
        </>
    );
};

export default ChatSidebar;
