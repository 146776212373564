import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Button, Grid, Paper, TextField, Typography, IconButton, Divider, ListItem, ListItemAvatar, ListItemText, Switch } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/system";
import { Transition } from "../Transition";
import CancelIcon from '@mui/icons-material/Cancel';
import { useAppDispatch } from "../../store";
import React, { useEffect, useState } from "react";
import { FormikProvider, useFormik } from "formik";

import moment from 'moment';
import { getCapitalizedString } from "../../utils/utilMethods";
import { isNotEmpty } from "../../utils/valid";
import { PiplineCalendarEventType } from "../../enum";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { AppColors } from "../../utils/utils";

interface IProps {
    title: string;
    piplineCalendarEventType: PiplineCalendarEventType,
    contactProfile: string;
    toggle: boolean;
    onClose: () => void;
    onSaveEvent: (eventDate: Date, note: string, eventType: PiplineCalendarEventType) => void;
}


interface IInitialState {
    title: string;
    startDate: Date;
    endDate: Date;
    note: string;
}

const AddPiplineCalendarEventModal: React.FC<IProps> = ({ title, piplineCalendarEventType, toggle, onClose, onSaveEvent, contactProfile }) => {

    const dispatch = useAppDispatch();

    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [isAllDay, setIsAllDay] = useState<boolean>(false);


    useEffect(() => {
        if (toggle) {
            const newDate = new Date();
            setCurrentDate(newDate);
            let endDate = new Date();
            endDate.setHours(endDate.getHours() + 1);
            setIsAllDay(false);
            formik.setFieldValue("title", `${piplineCalendarEventType == PiplineCalendarEventType.FOLLOWUP ? "Follow up - " : "Task - "}${title}`);
            formik.setFieldValue("startDate", newDate);
            formik.setFieldValue("endDate", endDate);
            formik.setFieldValue("note", "");

        }
    }, [toggle]);


    const formik = useFormik<IInitialState>({
        initialValues: {
            title: "",
            startDate: new Date(),
            endDate: new Date(),
            note: ""
        },
        onSubmit: async (values) => {
            try {

                var eventNote = values.note;
                if (piplineCalendarEventType == PiplineCalendarEventType.FOLLOWUP && eventNote.trim().length == 0) {
                    eventNote = `Followup`;
                } else if (piplineCalendarEventType == PiplineCalendarEventType.TASK && eventNote.trim().length == 0) {
                    eventNote = `Task`;
                }
                var selectedEventDate = values.startDate;
                if (isAllDay) {
                    selectedEventDate.setHours(0, 0, 0, 0);
                }
                onSaveEvent(selectedEventDate, eventNote, piplineCalendarEventType);
                onClose();
                formik.resetForm();

            } catch (error: any) { }
        },
    });

    const onCancel = () => {
        formik.resetForm({});
        onClose();
    };


    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'sm'}
                open={toggle}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    onCancel();
                }}
                aria-describedby="alert-dialog-slide-description"
                sx={{
                    mt: { md: "5%", sm: "25%", xs: "25%" },
                }}
            >
                <DialogTitle
                    sx={{
                        color: AppColors.blackColor,
                    }}
                >
                    Add {piplineCalendarEventType == PiplineCalendarEventType.FOLLOWUP ? "Follow up" : "Task"}
                </DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                        zIndex: 100000,
                        color: 'white',
                        alignSelf: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CancelIcon sx={{ color: AppColors.grayTwoColor }} />
                </IconButton>
                <form onSubmit={formik.handleSubmit}>
                    <FormikProvider value={formik}>

                        <DialogContent sx={{ padding: '15px', minWidth: "100%" }} >

                            <Stack>

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                >
                                    <Paper
                                        elevation={0}
                                        sx={{

                                            p: 0
                                        }}
                                    >

                                        <TextField
                                            id="title"
                                            name='title'

                                            sx={{

                                                color: AppColors.grayThreeColor,
                                                background: AppColors.grayZeroColor,
                                                borderRadius: "12px",
                                                p: 1.5,
                                                "& .MuiOutlinedInput-root": {
                                                    p: 0
                                                },
                                                "& input": {
                                                    "&::placeholder": {
                                                        fontWeight: "bold",
                                                    },
                                                },
                                                "& .MuiOutlinedInput-root textarea": {
                                                    color: AppColors.grayThreeColor
                                                },
                                                '&:active': {
                                                    outline: 'none',
                                                },
                                                '& fieldset': {
                                                    border: 'none',
                                                }
                                            }}
                                            placeholder="Add Title"
                                            onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                                            onChange={formik.handleChange}
                                            value={formik.values.title}
                                            multiline
                                            rows={2}
                                            maxRows={3}
                                            fullWidth
                                        />
                                    </Paper>
                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                    <Stack
                                        flexDirection={'column'}
                                        sx={{
                                            width: "100%",
                                            position: 'relative',
                                            mb: 2
                                        }}
                                    >
                                        <ListItem
                                            secondaryAction={
                                                <Switch value={isAllDay} onChange={() => setIsAllDay(!isAllDay)} />
                                            }
                                            sx={{ pl: 0, pr: 0 }}
                                        >
                                            <ListItemAvatar>
                                                <AccessTimeIcon sx={{ color: AppColors.primaryColor }} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                sx={{ color: AppColors.grayThreeColor }}
                                                primary="All Day"
                                            />
                                        </ListItem>



                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <MobileDateTimePicker
                                                key={"tfStartDate"}
                                                inputFormat="MMM dd, yyyy hh:mm a"

                                                renderInput={(params: any) => {

                                                    return (<ListItem
                                                        onClick={(e) => {
                                                            try {
                                                                params?.onClick(e);
                                                            } catch (e) { }
                                                        }}
                                                        secondaryAction={
                                                            <Typography sx={{ color: AppColors.grayThreeColor }}>{isAllDay ? '' : `${moment(formik.values.startDate).format("hh:mm a")}`}</Typography>
                                                        }
                                                        sx={{ pl: 0, pr: 0 }}
                                                    >
                                                        <ListItemAvatar></ListItemAvatar>
                                                        <ListItemText
                                                            sx={{ color: AppColors.grayThreeColor }}
                                                            primary={`${moment(formik.values.startDate).format("dddd, MMM D")}`}
                                                        />
                                                    </ListItem>)
                                                }}
                                                value={formik.values.startDate}
                                                onChange={(value, inputValue) => {
                                                    formik.setFieldValue("startDate", value);
                                                }}
                                            />
                                        </LocalizationProvider>



                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <MobileDateTimePicker
                                                key={"tfEndDate"}
                                                inputFormat="MMM dd, yyyy hh:mm a"

                                                renderInput={(params: any) => {

                                                    return (<ListItem
                                                        onClick={(e) => {
                                                            try {
                                                                params?.onClick(e);
                                                            } catch (e) { }
                                                        }}
                                                        secondaryAction={
                                                            <Typography sx={{ color: AppColors.grayThreeColor }}>{isAllDay ? '' : `${moment(formik.values.endDate).format("hh:mm a")}`}</Typography>
                                                        }
                                                        sx={{ pl: 0, pr: 0 }}
                                                    >
                                                        <ListItemAvatar></ListItemAvatar>
                                                        <ListItemText
                                                            sx={{ color: AppColors.grayThreeColor }}
                                                            primary={`${moment(formik.values.endDate).format("dddd, MMM D")}`}
                                                        />
                                                    </ListItem>)
                                                }}
                                                value={formik.values.endDate}
                                                onChange={(value, inputValue) => {
                                                    formik.setFieldValue("endDate", value);
                                                }}
                                            />
                                        </LocalizationProvider>




                                    </Stack>

                                    <Divider sx={{ mt: 1, mb: 1 }} />

                                    <Avatar src={contactProfile} sx={{ height: "50px", width: "50px", '& img': { borderRadius: '100%' }, }} />
                                    <Typography
                                        sx={{
                                            color: AppColors.grayTwoColor,
                                            textTransform: "capitalize",
                                            fontSize: '0.9rem',
                                            fontWeight: '500',
                                            mt: 0.5,
                                            mb: 0.5
                                        }}
                                    >
                                        Follow-up Notes
                                    </Typography>
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            mt: 0,
                                            p: 0
                                        }}
                                    >

                                        <TextField
                                            id="note"
                                            name='note'

                                            sx={{
                                                color: AppColors.grayThreeColor,
                                                background: AppColors.grayZeroColor,
                                                borderRadius: "12px",
                                                p: 1.5,
                                                "& .MuiOutlinedInput-root": {
                                                    p: 0
                                                },
                                                "& input": {
                                                    "&::placeholder": {
                                                        fontWeight: "bold",
                                                    },
                                                },
                                                "& .MuiOutlinedInput-root textarea": {
                                                    color: AppColors.grayThreeColor
                                                },
                                                '&:active': {
                                                    outline: 'none',
                                                },
                                                '& fieldset': {
                                                    border: 'none',
                                                }
                                            }}
                                            placeholder="Type a Note...."
                                            onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                                            onChange={formik.handleChange}
                                            value={formik.values.note}
                                            multiline
                                            rows={5}
                                            maxRows={10}
                                            fullWidth
                                        />
                                    </Paper>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "end",
                                            mt: 2
                                        }}
                                    >
                                        <LoadingButton
                                            type="submit"
                                            loading={formik.isSubmitting}
                                            sx={{

                                                border: `1px solid ${AppColors.whiteColor}`,
                                                borderWidth: "1px",
                                                pr: 2,
                                                pl: 2,
                                                borderRadius: "15px",
                                                color: AppColors.whiteColor,
                                                fontSize: "1rem",
                                                background: AppColors.primaryColor,
                                                mr: 2,
                                                textTransform: 'inherit',
                                                "&:hover": {
                                                    background: AppColors.primaryColor,
                                                }
                                            }}
                                        >
                                            Save
                                        </LoadingButton>
                                        <Button
                                            sx={{

                                                borderRadius: "15px",
                                                color: AppColors.blackColor,
                                                fontSize: "1rem",

                                                textTransform: 'inherit'
                                            }}
                                            onClick={onCancel}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>

                                </Grid>
                            </Stack>

                        </DialogContent>
                    </FormikProvider>
                </form>
            </Dialog >
        </>
    );
};

export default AddPiplineCalendarEventModal;
