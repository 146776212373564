import React, { useCallback, useEffect } from 'react';
import { useRef } from 'react';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { alpha } from '@mui/material/styles';
import {
  Box,
  List,
  Badge,
  Avatar,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  CircularProgress,
  Stack,
} from '@mui/material';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import {
  getNotifications,
  markAsSeenNotificationThread,
  unreadNotificationCount,
} from '../../store/notification/api';

import { useAppDispatch, useAppSelector } from '../../store';
import Loader from '../../components/Loader';
import useToggle from '../../hooks/useToggle';
import { AppColors, config } from '../../utils/utils';

import { Notification } from '../../store/notification/api/interface';

import { useNavigate } from 'react-router-dom';
import { encryptString } from '../../utils/url';

const NotificationsPopover = () => {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { toggle, onOpen, onClose } = useToggle();
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { data, isLoading, page, hasMore, totalUnread } = useAppSelector(
    (state) => state.notification
  );




  const loadMoreNotifications = (isReload = false) => {
    if (!isLoading) {
      const params = {
        page: isReload ? 1 : (page + 1)
      };
      dispatch(getNotifications(params));
      if (params.page == 1) {
        dispatch(unreadNotificationCount({ receiver_id: user?.id ?? 0 }));
      }
    }
  };


  useEffect(() => {
    loadMoreNotifications();
  }, []);




  const observer = useRef<IntersectionObserver>();


  const lastElementRef = useCallback(
    (node: any) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreNotifications();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore,]
  );

  const handleMarkAsSeenNotification = async (notification: Notification) => {
    onClose();
    if (notification.notifiable_type == "community") {
      var chatName = (notification.sender_id < notification.receiver_id) ? `${notification.sender_id}-${notification.receiver_id}` : `${notification.receiver_id}-${notification.sender_id}`;
      navigate(`/dashboard/messaging?cid=${encryptString(chatName)}`);
    }

    await dispatch(
      markAsSeenNotificationThread({
        receiver_id: notification.receiver_id,
        sender_id: notification.sender_id
      })
    );
    loadMoreNotifications(true);
  };



  return (
    <React.Fragment>
      <IconButton
        ref={anchorRef}
        size='large'
        color={toggle ? 'primary' : 'default'}
        onClick={onOpen}
        sx={{
          bgcolor: AppColors.whiteColor, '&:hover': { bgcolor: AppColors.whiteColor }
        }}
      >
        <Badge
          badgeContent={totalUnread}
          color='error'
        >
          <Iconify
            icon='eva:bell-outline'
            width={25}
            height={25}
            sx={{ color: AppColors.primaryColor }}
          />
        </Badge>
      </IconButton>

      <MenuPopover
        open={toggle}
        onClose={onClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='subtitle1'>
              Notifications
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: 350 } }}>
          {data.length === 0 && isLoading ? (
            <Loader />
          ) : (data.length === 0) ? (
            <List
              disablePadding
              subheader={
                <ListSubheader
                  disableSticky
                  sx={{
                    py: 1,
                    px: 2.5,
                    typography: 'overline',
                    textAlign: 'center',
                    textTransform: 'capitalize',
                  }}
                >
                  Notifications not found
                </ListSubheader>
              }
            ></List>
          ) : (
            <List disablePadding sx={{ height: { xs: 340, sm: 350 }, overflowY: 'scroll' }}>
              {data?.map((notification, idx) => (
                <React.Fragment key={notification?.id}>
                  <div ref={idx + 1 == data.length ? lastElementRef : undefined}>

                    <ListItem
                      sx={{
                        '&:hover': {
                          background: 'rgb(224, 235, 244)',
                        },
                        background: (notification.is_seen == 0) ? AppColors.grayZeroColor : AppColors.whiteColor
                      }}
                      button
                      onClick={() => handleMarkAsSeenNotification(notification)

                      }
                    >
                      <ListItemAvatar>
                        <Avatar src={notification.sender?.profile_picture ?? '/static/images/placeholder_profile.png'}>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${notification?.sender?.first_name ?? ""} ${notification?.sender?.last_name ?? ""}`}
                        secondary={``}
                      />
                    </ListItem>
                    <Divider
                      variant='inset'
                      component='li'
                      sx={{
                        marginLeft: 0
                      }}
                    />



                  </div>
                </React.Fragment>
              ))}
              {
                data.length !== 0 && isLoading && (
                  <Stack direction={'row'} justifyContent={'center'} sx={{ mt: 0.5, mb: 0.5 }}>
                    <CircularProgress />
                  </Stack>
                )
              }
            </List >
          )}
        </Scrollbar >

        <Divider />

        <Box sx={{ p: 1.5 }}></Box>
      </MenuPopover >
    </React.Fragment >
  );
};
export default NotificationsPopover;
