import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { CommunityUsersResponse, FindCommunityUsersRequestData, FollowUnfollowResponse, IsFollowingResponse } from './interface';

export const getCommunityUsers = createAsyncThunk(
  'communityUsers/getCommunityUsers',
  async (data: FindCommunityUsersRequestData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<CommunityUsersResponse>(`/api/user/community-users`, { params: data });
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const getIsFollowing = createAsyncThunk(
  'communityUsers/getIsFollowing',
  async (values: { user_id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<IsFollowingResponse>(`/api/follow/is-following`, values);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const followUserRequest = createAsyncThunk(
  'communityUsers/followUserRequest',
  async (values: { user_id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<FollowUnfollowResponse>(`/api/follow`, values);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const unFollowUserRequest = createAsyncThunk(
  'communityUsers/unFollowUserRequest',
  async (values: { user_id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<FollowUnfollowResponse>(`/api/follow/unfollow`, values);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
