import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as timeago from 'timeago.js';

// import it first.
import vi from 'timeago.js/lib/lang/vi';
import { AppDispatch, useAppSelector } from '../../store';
import styles from '../../styles/Reports.module.css';
import { Box, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { getDaysFromFilter } from '../../utils/daysConvertor';
import { getLeadsView } from '../../store/reports/api';
import NewProspectsItem from '../Card/NewProspectsItem';
import { getItem } from '../../utils/localStorage';
import { config } from '../../utils/utils';

interface IProps {
  selectedTimeFilter: string;
  isFromKnoCardLink?: boolean;
  selectedReportUser?: number;
}

const NewProspects: React.FC<IProps> = ({
  selectedTimeFilter,
  isFromKnoCardLink,
  selectedReportUser,
}) => {
  timeago.register('vi', vi);

  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);
  const accessToken = getItem(config.AUTH_KEY);
  const leadViewData =
    useAppSelector((state) => state?.reports?.leadViewData?.leadViewData) ?? [];

  const [selectedSortFilter, setSelectedSortFilter] = useState('all');
  const reportSortList = [
    { label: 'All', value: 'all' },
    { label: 'First Name', value: 'first_name' },
    { label: 'Last Name', value: 'last_name' },
    { label: 'Most Recent', value: 'most_recent' },
  ];

  useEffect(() => {
    dispatch(
      getLeadsView({
        user_id: selectedReportUser ? selectedReportUser : user?.id ?? 0,
        api_token: accessToken ?? '',
        timeline: selectedSortFilter,
        days: getDaysFromFilter(selectedTimeFilter),
        ...(isFromKnoCardLink && { isFromKnoCardLink: true }),
      })
    ).unwrap();
  }, [selectedTimeFilter]);

  const handleChangeSortFilter = (event: SelectChangeEvent) => {
    setSelectedSortFilter(event.target.value as string);

    dispatch(
      getLeadsView({
        user_id: selectedReportUser ? selectedReportUser : user?.id ?? 0,
        api_token: accessToken ?? '',
        timeline: event.target.value,
        days: getDaysFromFilter(selectedTimeFilter),
        ...(isFromKnoCardLink && { isFromKnoCardLink: true }),
      })
    ).unwrap();
  };

  return (
    <>
      <Box className={styles.resultsContainerHeader}>
        <Typography className={styles.activityBtn}>New Prospects</Typography>
        <Select
          id='time-filter-select'
          value={selectedSortFilter}
          onChange={handleChangeSortFilter}
          IconComponent={() => (
            <img
              src='/static/images/arrow_down.svg'
              alt='arrow_down'
              className={styles.arrowDown}
            />
          )}
          className={styles.activityFilterDropDown}
        >
          {reportSortList.map((item: any) => {
            return (
              <MenuItem value={item.value}>
                <Box className={styles.activityFilterDropDownText}>
                  {item.label}
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </Box>

      <Box className={styles.resultsListContainer}>
        {leadViewData &&
          leadViewData.map((data, index: number) => (
            <NewProspectsItem
              key={index}
              leadView={data}
              index={index}
            />
          ))}
      </Box>
    </>
  );
};

export default NewProspects;
