import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { AppDispatch, useAppSelector } from "../store";
import React, { useEffect, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import { createArray } from "../constant";
import { CompanyExternalLink } from "../store/companyExternalLinks/api/interface";
import externalLinkSchema from "../validation/externalLinkSchema";
import { createUpdateCompanyExternalLinks } from "../store/companyExternalLinks/api";
import { useDispatch } from "react-redux";
import { companyExternalLinkCreated, companyExternalLinkUpdated } from "../store/companyExternalLinks/companyExternalLinksSlice";

import { getCapitalizedString, inputTextFieldStyle, showSuccessMessage } from "../utils/utilMethods";
import { AppColors } from "../utils/utils";

interface IProps {
    companyExternalLink?: CompanyExternalLink;
    onCancel: () => void;
}



interface IInitialState {
    title: string;
    description: string;
    link: string;
    icon: string;
    color: string;
}

const AddEditExternalLink: React.FC<IProps> = ({ onCancel, companyExternalLink }) => {
    const dispatch = useDispatch<AppDispatch>();
    const userInfo = useAppSelector((state) => state?.auth?.user);
    const [reloadView, setReloadView] = useState<boolean>(false);
    const formik = useFormik<IInitialState>({
        initialValues: {
            title: '',
            description: '',
            link: '',
            icon: '93',
            color: AppColors.primaryColor
        },
        validationSchema: externalLinkSchema,
        onSubmit: async (values) => {
            try {
                let externalLink = {
                    id: companyExternalLink?.id,
                    company_id: (companyExternalLink?.company_id) ?? (userInfo?.company.id) ?? 0,
                    title: values.title,
                    description: values.description,
                    color: values.color.replace("#", "0xff"),
                    link: values.link,
                    status: 'ACT',
                    icon_url: null,
                    icon: Number(values.icon) ?? 93
                };

                const result = await dispatch(createUpdateCompanyExternalLinks(externalLink)).unwrap();

                if (result?.data?.status == "success") {
                    if (externalLink.id == undefined) {
                        dispatch(companyExternalLinkCreated(result.data.data.company_external_link));
                        showSuccessMessage("External link created successfully.");
                    } else {
                        dispatch(companyExternalLinkUpdated(result.data.data.company_external_link));
                        showSuccessMessage("External link edited successfully.");
                    }
                }

                onCancel();

            } catch (error: any) { }
        },
    });


    useEffect(() => {

        if (companyExternalLink != null) {
            try {
                formik.values.title = companyExternalLink.title;
                formik.values.description = companyExternalLink.description ?? '';
                formik.values.link = companyExternalLink.link;
                formik.values.icon = `${companyExternalLink.icon}`;
                formik.values.color = companyExternalLink.color.replace("0xff", "#");
            } catch (e) { }
        } else {
            formik.values.title = '';
            formik.values.description = '';
            formik.values.link = '';
            formik.values.icon = '93';
            formik.values.color = AppColors.primaryColor;

        }
        setReloadView(!reloadView);
    }, [companyExternalLink])

    const handleTextLink = () => {
        try {
            if (formik.values.link.length > 0) {
                window.open(`${formik.values.link}`, "_blank") || window.location.replace(`${formik.values.link}`);
            }
        } catch (e) { }
    }

    return (
        <Box>
            <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                <FormikProvider value={formik}>
                    <Grid container gap={2} sx={{ m: 0, width: '100%' }}>
                        <Grid item xs={12}>
                            <Typography
                                variant='h6'
                                sx={{ textAlign: { xs: 'center', sm: 'left' }, color: AppColors.blackColor }}
                            > {companyExternalLink != null ? 'Edit Link' : 'Add a Link'}</Typography></Grid>

                        <Grid xs={12} item >
                            <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>Link Title</Typography>
                            <TextField
                                id="title"
                                name={`title`}
                                placeholder="Title"
                                value={
                                    formik.values.title ?? ""
                                }
                                fullWidth
                                InputProps={{
                                    sx: inputTextFieldStyle()
                                }}
                                onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                                onChange={formik.handleChange}
                                label=""
                                variant="outlined"
                                error={Boolean(formik.errors.title && formik.touched.title)}
                                helperText={
                                    Boolean(formik.errors.title && formik.touched.title) &&
                                    formik.errors.title
                                }
                            />
                        </Grid>
                        <Grid xs={12} item >
                            <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>Link</Typography>
                            <TextField
                                id="link"
                                name={`link`}
                                value={
                                    formik.values.link ?? ""
                                }
                                onChange={formik.handleChange}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    sx: inputTextFieldStyle()
                                }}
                                error={Boolean(formik.errors.link && formik.touched.link)}
                                helperText={
                                    Boolean(formik.errors.link && formik.touched.link) &&
                                    formik.errors.link
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ mr: 1, float: 'left' }}>
                                <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>Icon</Typography>
                                <Select
                                    labelId="icon"
                                    id="icon"
                                    name="icon"
                                    value={formik.values.icon}
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    sx={{ ...inputTextFieldStyle(), minWidth: "100px" }}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 400
                                        },
                                        PaperProps: {
                                            style: {
                                                width: 250,
                                                paddingLeft: '10px',
                                                paddingRight: '10px'
                                            }
                                        }
                                    }}
                                >
                                    {createArray(111).map((item) => (
                                        <MenuItem
                                            key={`icon-data-${item}`}
                                            value={`${item}`}
                                            sx={{ display: "inline-flex", width: "25%", pl: '10px', pr: '10px' }}
                                        >
                                            <span
                                                className={`icon-${item}`}
                                                style={{
                                                    color: AppColors.primaryColor,
                                                    fontSize: '22px',
                                                }}
                                            ></span>
                                        </MenuItem>
                                    ))}
                                </Select>

                            </Box>

                            <Box  >
                                <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>Color</Typography>
                                <TextField
                                    id="color"
                                    name={`color`}
                                    value={
                                        formik.values.color ?? ""
                                    }
                                    sx={{ minWidth: '100px', ...inputTextFieldStyle() }}
                                    type={"color"}

                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    error={Boolean(formik.errors.color && formik.touched.color)}
                                    helperText={
                                        Boolean(formik.errors.color && formik.touched.color) &&
                                        formik.errors.color
                                    }
                                />
                            </Box>
                        </Grid>

                        <Grid xs={12} item  >
                            <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>Description</Typography>
                            <TextField
                                id="description"
                                name={`description`}
                                value={
                                    formik.values.description ?? ""
                                }
                                onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                                onChange={formik.handleChange}
                                fullWidth
                                InputProps={{
                                    sx: inputTextFieldStyle()
                                }}
                                variant="outlined"
                                multiline={true}
                                maxRows={5}
                                error={Boolean(formik.errors.description && formik.touched.description)}
                                helperText={
                                    Boolean(formik.errors.description && formik.touched.description) &&
                                    formik.errors.description
                                }
                            />
                        </Grid>

                        {/* <Grid container sm={6} xs={12}>
                            <Box sx={{ m: 2 }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="100%"
                                width={'100%'}
                            >
                                <LoadingButton
                                    type="button"
                                    loading={false}
                                    variant="contained"
                                    onClick={handleTextLink}
                                    sx={{ mr: 2 }}
                                >
                                    Test Link
                                </LoadingButton>
                            </Box>
                        </Grid> */}
                    </Grid>
                </FormikProvider>
                <Box sx={{ width: '100%', mt: 2 }} display="flex" justifyContent="end" alignItems="end"
                >
                    <LoadingButton
                        type="button"
                        loading={false}
                        variant="contained"
                        onClick={onCancel}
                        sx={{ mr: 2 }}
                    >Cancel</LoadingButton>
                    <LoadingButton
                        type="submit"
                        loading={formik.isSubmitting}
                        variant="contained"
                    >
                        {companyExternalLink == undefined ? 'Add' : 'Save'}
                    </LoadingButton>
                </Box>
            </form>
        </Box >

    );
};

export default AddEditExternalLink;
