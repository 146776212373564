import { STATUS_TYPE } from '../../../enum';
import { CompanyFeed } from '../../companyFeed/api/interface';
import { ContactInfo } from '../../contacts/api/interface';
import { PostObject } from '../../post/api/interface';
import { UserInfo } from '../../userInfo/api/interface';



export interface ShareHistoryReponse {
  status: {
    code: string,
  },
  data?: {
    current_page?: number,
    data: ShareHistoryItem[];
    first_page_url?: string;
    from?: number;
    last_page?: number;
    last_page_url?: string;
    next_page_url?: string;
    path?: string;
    per_page?: number;
    prev_page_url?: string;
    to?: number;
    total?: number;
  }
}


export interface ShareHistoryItem {
  id?: number;
  user_id?: number;
  post_id?: number;
  user?: UserInfo,
  contact?: ContactInfo;
  share_to?: ContactInfo;
  share_to_profile?: ContactInfo;
  target_id?: number;
  targetable_type?: string;
  share_type?: string;
  action_type?: string;
  activity_type?: string;
  form_answer?: string;
  form_number?: number;
  feedback_form_id?: number;
  form_code?: string;
  post?: PostObject;
  company_feed?: CompanyFeed;
  created_at?: string;
  updated_at?: string;
}


interface InitialState {
  data: ShareHistoryItem[];
  searchText: string,
  status: STATUS_TYPE;
  page: number;
  hasMore: boolean;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}


export const initialState: InitialState = {
  data: [],
  searchText: '',
  status: 'idle',
  page: 0,
  hasMore: true,
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
};
