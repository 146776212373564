import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './api/interface';
import { getSentKnoCardLinkRequest } from './api';

const knocardLinkSlice = createSlice({
  name: 'knocardLink',
  initialState,
  reducers: {
    reset: () => initialState,
    setKnoCardLinkViewReportUserInfo: (state, action) => {
      state.viewReportUserInfo = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getSentKnoCardLinkRequest.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getSentKnoCardLinkRequest.fulfilled, (state, action) => {
      state.data = action.payload?.data?.data?.data ?? [];
      state.page = action.payload?.data?.data?.current_page ?? 0;
      state.total = action.payload?.data?.data?.total ?? 0;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getSentKnoCardLinkRequest.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset, setKnoCardLinkViewReportUserInfo } = knocardLinkSlice.actions;
export default knocardLinkSlice.reducer;
