import { createSlice } from '@reduxjs/toolkit';
import { getCompanyFeed } from './api';
import { initialState } from './api/interface';

const companyFeedSlice = createSlice({
    name: 'companyFeed',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getCompanyFeed.pending, (state) => {
            state.isLoading = true;
            state.status = 'pending';
        });
        builder.addCase(getCompanyFeed.fulfilled, (state, action) => {
            state.data = { ...action.payload.data, company_feed: action.payload.data.company_feed.reverse() };
            state.isError = false;
            state.isSuccess = true;
            state.isLoading = false;
            state.status = 'succeeded';
        });
        builder.addCase(getCompanyFeed.rejected, (state, _) => {
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = false;
            state.status = 'failed';
        });
    },
});

export const { reset } = companyFeedSlice.actions;
export default companyFeedSlice.reducer;
