import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { toastHandler } from '../../../utils/toastHandler';
import { NotificationReponse, UnreadNotificationCountReponse } from './interface';



export const getNotifications = createAsyncThunk(
  'notification/getNotifications',
  async ({ page }: { page: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<NotificationReponse>(`/api/notifications/paginatedNotifications?page=${page}`);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const markAsSeenNotification = createAsyncThunk(
  'notification/markAsReadeNotification',
  async (data: { notification_id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/api/notifications/seen`, data);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const markAsSeenNotificationThread = createAsyncThunk(
  'notification/markAsReadeNotification',
  async (data: { receiver_id: number, sender_id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/api/notifications/seen-thread`, data);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);



export const unreadNotificationCount = createAsyncThunk(
  'notification/unreadNotificationCount',
  async (data: { receiver_id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<UnreadNotificationCountReponse>(`/api/notifications/count`, data);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

