import {
  Badge,
  Box,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import styles from '../styles/Reports.module.css';
import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { AppColors } from '../utils/utils';
import { useNavigate } from 'react-router-dom';

import ActivityReportItem from '../components/corporateReport/ActivityReportItem';
import FeedbackFormReportItem from '../components/corporateReport/FeedbackFormReportItem';
import { getDaysFromFilter, timeFilterList } from '../utils/daysConvertor';
import { getCorporateGroupLandingPageVideoView, getCorporateGroupMediaVideoView, getMediaFeedbackFormCount, getShareActivityCount, getSpecificGroupMediaShareActivityCount, getSpecificGroupMediaViewdActivityCount, getViewActivityCount } from '../store/corporateReporting/api';
import { GroupMediaItem } from '../store/corporateReporting/api/interface';
import SelectGroupMediaModal from '../components/modal/SelectGroupMediaModal';
import useToggle from '../hooks/useToggle';


const CorporateReport = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);
  const navigate = useNavigate();

  const [selectedTimeFilter, setSelectedTimeFilter] = useState('Today');

  enum MediaSelectionType {
    SHARE = 'share',
    VIEW = 'view',
    NONE = 'none'
  }

  const [groupMediaSelectionType, setGroupMediaSelectionType] = useState(MediaSelectionType.NONE);


  const [selectedShareGroupMedia, setSelectedShareGroupMedia] = useState<GroupMediaItem[]>([]);
  const [selectedViewGroupMedia, setSelectedViewGroupMedia] = useState<GroupMediaItem[]>([]);

  const corporateReporting = useAppSelector((state) => state?.corporateReporting);

  const {
    toggle: toggleGroupMedia,
    onOpen: onOpenGroupMedia,
    onClose: onCloseGroupMedia,
  } = useToggle();

  useEffect(() => {
    if (user?.affiliate_group == null || user?.username !== user?.affiliate_group?.owner_name) {
      navigate(`/dashboard`)
    } else {
      loadShareActivityCount();
      viewActivityCount();
      mediaFeedbackFormCount();
      corporatGroupMediaVideoView();
      corporatGroupLandingPageVideoView();
    }
  }, []);

  const handleChangeTimeFilter = (event: SelectChangeEvent) => {
    setSelectedTimeFilter(event.target.value as string);
  };

  const loadShareActivityCount = () => {
    dispatch(getShareActivityCount({ days: getDaysFromFilter(selectedTimeFilter) }));

  }

  const specificGroupMediaShareActivityCount = () => {
    const mediaIds = selectedShareGroupMedia.map((item) => item.id);
    dispatch(getSpecificGroupMediaShareActivityCount({ days: getDaysFromFilter(selectedTimeFilter), media_ids: mediaIds }));


  }

  const viewActivityCount = () => {
    dispatch(getViewActivityCount({ days: getDaysFromFilter(selectedTimeFilter) }));
  }

  const specificGroupMediaViewdActivityCount = () => {
    const mediaIds = selectedViewGroupMedia.map((item) => item.id);
    dispatch(getSpecificGroupMediaViewdActivityCount({ days: getDaysFromFilter(selectedTimeFilter), media_ids: mediaIds }));

  }

  const mediaFeedbackFormCount = () => {
    dispatch(getMediaFeedbackFormCount({ days: getDaysFromFilter(selectedTimeFilter) }));
  }

  const corporatGroupMediaVideoView = () => {
    dispatch(getCorporateGroupMediaVideoView({ days: getDaysFromFilter(selectedTimeFilter) }));
  }

  const corporatGroupLandingPageVideoView = () => {
    dispatch(getCorporateGroupLandingPageVideoView({ days: getDaysFromFilter(selectedTimeFilter) }));
  }


  const handleOnMediaSelectionChange = (itemList: GroupMediaItem[]) => {
    if (groupMediaSelectionType == MediaSelectionType.SHARE) {
      setSelectedShareGroupMedia(itemList);
    } else {
      setSelectedViewGroupMedia(itemList);
    }
  }

  useEffect(() => {
    loadShareActivityCount();
    specificGroupMediaShareActivityCount();
    viewActivityCount();
    specificGroupMediaViewdActivityCount();
    mediaFeedbackFormCount();
    corporatGroupMediaVideoView();
    corporatGroupLandingPageVideoView();
  }, [selectedTimeFilter]);


  const handleSelectGroupMediaForShareActivity = () => {
    setGroupMediaSelectionType(MediaSelectionType.SHARE);
  }

  const handleSelectGroupMediaForViewActivity = () => {
    setGroupMediaSelectionType(MediaSelectionType.VIEW);
  }

  useEffect(() => {
    specificGroupMediaShareActivityCount();
  }, [selectedShareGroupMedia]);

  useEffect(() => {
    specificGroupMediaViewdActivityCount();
  }, [selectedViewGroupMedia]);


  useEffect(() => {
    if (groupMediaSelectionType !== MediaSelectionType.NONE) {
      onOpenGroupMedia();
    }
  }, [groupMediaSelectionType]);

  useEffect(() => {
    if (!toggleGroupMedia) {
      setGroupMediaSelectionType(MediaSelectionType.NONE);
    }
  }, [toggleGroupMedia]);



  return (
    <>
      <Page title='Corporate  Report | KnoCard' showAppBar={true}>
        <Container maxWidth='xl'>
          <Box className={styles.mainContent} marginTop={5}>

            < Box className={styles.mainContentBody} pb={3}>
              <Grid container>
                <Grid item xs={12}>
                  <Box sx={{ width: "100%", textAlign: "end" }}>
                    <Select
                      id='time-filter-select'
                      value={selectedTimeFilter}
                      onChange={handleChangeTimeFilter}
                      sx={{ mb: 2 }}
                      IconComponent={() => (
                        <img
                          src='/static/images/arrow_down.svg'
                          alt='arrow_down'
                          className={styles.arrowDown}
                        />
                      )}
                      // sx={{
                      //   boxShadow: 'none',
                      //   '.MuiOutlinedInput-notchedOutline': {
                      //     border: 0,
                      //   },
                      // }}
                      className={styles.activityFilterDropDown}
                    >
                      {timeFilterList.map((val: any) => {
                        return (
                          <MenuItem value={val}>
                            <Box className={styles.activityFilterDropDownText}>
                              {val}
                            </Box>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                  <Box sx={{ pb: 2 }} >
                    <Box sx={{
                      display: "flex",
                      justifyContent: 'start',
                      mr: 1.1,
                      textAlign: 'center',
                    }}>
                      <Box sx={{ background: AppColors.blackColor, borderTopLeftRadius: 15, borderTopRightRadius: 15, paddingTop: "2px", paddingLeft: "2px", paddingRight: "2px", marginLeft: "20px" }}>
                        <Typography
                          sx={{
                            borderTopLeftRadius: 15, borderTopRightRadius: 15, px: 2, py: 0.4, width: "fit-content", background: `${AppColors.backgroundColor}`, color: AppColors.blackColor, fontSize: "0.8rem",
                          }}>Share Activity</Typography></Box>
                    </Box>
                    <Box sx={{ borderRadius: "10px" }}>
                      <Grid container spacing={1}>
                        <ActivityReportItem index={0} isSelection={false} title='Web App Shares' existingContactCount={corporateReporting.shareActivityCount?.web_app_share_existing_contact || 0} newContactCount={corporateReporting.shareActivityCount?.web_app_share_new_contact || 0} isLoading={corporateReporting.isShareActivityCountLoading} />
                        <ActivityReportItem index={1} isSelection={false} title='Landing Page Shares' existingContactCount={corporateReporting.shareActivityCount?.landing_page_share_existing_contact || 0} newContactCount={corporateReporting.shareActivityCount?.landing_page_share_new_contact || 0} isLoading={corporateReporting.isShareActivityCountLoading} />
                        <ActivityReportItem index={2} isSelection={false} title='Social Post Shares' existingContactCount={corporateReporting.shareActivityCount?.social_post_share_existing_contact || 0} newContactCount={corporateReporting.shareActivityCount?.social_post_share_new_contact || 0} isLoading={corporateReporting.isShareActivityCountLoading} />
                        <ActivityReportItem index={3} isSelection={false} title='Review  Request Shares' existingContactCount={corporateReporting.shareActivityCount?.review_request_share_existing_contact || 0} newContactCount={corporateReporting.shareActivityCount?.review_request_share_new_contact || 0} isLoading={corporateReporting.isShareActivityCountLoading} />
                        <ActivityReportItem index={4} isSelection={false} title='Media Files - All' existingContactCount={corporateReporting.shareActivityCount?.media_file_all_share_existing_contact || 0} newContactCount={corporateReporting.shareActivityCount?.media_file_all_share_new_contact || 0} isLoading={corporateReporting.isShareActivityCountLoading} />
                        <ActivityReportItem index={5} isSelection={true} title='Select Media File From List' existingContactCount={corporateReporting.specificGroupMediaShareActivityCount?.media_file_share_existing_contact || 0} newContactCount={corporateReporting.specificGroupMediaShareActivityCount?.media_file_share_new_contact || 0} handleSelectioClick={handleSelectGroupMediaForShareActivity} isLoading={corporateReporting.isSpecificGroupMediaShareActivityCountLoading} />
                      </Grid>
                    </Box>
                  </Box>

                </Grid>

                <Grid item xs={12}>
                  <Box >
                    <Box sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: 'start',
                      mr: 1.1,
                      textAlign: 'center',
                    }}>
                      <Box sx={{ background: AppColors.blackColor, borderTopLeftRadius: 15, borderTopRightRadius: 15, paddingTop: "2px", paddingLeft: "2px", paddingRight: "2px", marginLeft: "20px" }}>
                        <Typography
                          sx={{
                            borderTopLeftRadius: 15, borderTopRightRadius: 15, px: 2, py: 0.4, width: "fit-content", background: `${AppColors.backgroundColor}`, color: AppColors.blackColor, fontSize: "0.8rem",
                          }}>Viewing Activity</Typography></Box>
                    </Box>
                    <Box sx={{ borderRadius: "10px" }}>
                      <Grid container spacing={1}>
                        <ActivityReportItem index={6} isSelection={false} title='Web App' existingContactCount={corporateReporting.viewedActivityCount?.web_app_view_existing_contact || 0} newContactCount={corporateReporting.viewedActivityCount?.web_app_view_new_contact || 0} isLoading={corporateReporting.isViewedActivityCountLoading} />
                        <ActivityReportItem index={7} isSelection={false} title='Landing Page' existingContactCount={corporateReporting.viewedActivityCount?.landing_page_viewed_existing_contact || 0} newContactCount={corporateReporting.viewedActivityCount?.web_app_view_new_contact || 0} isLoading={corporateReporting.isViewedActivityCountLoading} />
                        <ActivityReportItem index={8} isSelection={false} title='Media Files - All' existingContactCount={corporateReporting.viewedActivityCount?.media_file_all_viewed_existing_contact || 0} newContactCount={corporateReporting.viewedActivityCount?.media_file_all_viewed_new_contact || 0} isLoading={corporateReporting.isViewedActivityCountLoading} />
                        <ActivityReportItem index={9} isSelection={true} title='Select Media File From List' existingContactCount={corporateReporting.specificGroupMediaViewedActivityCount?.media_file_viewed_existing_contact || 0} newContactCount={corporateReporting.specificGroupMediaViewedActivityCount?.media_file_viewed_new_contact || 0} handleSelectioClick={handleSelectGroupMediaForViewActivity} isLoading={corporateReporting.isSpecificGroupMediaViewedActivityCountLoading} />
                      </Grid>

                    </Box>
                  </Box>

                </Grid>

                <Grid item xs={12}>
                  <Box >
                    <Box sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: 'start',
                      mr: 1.1,
                      textAlign: 'center',
                    }}>
                      <Box sx={{ background: AppColors.blackColor, borderTopLeftRadius: 15, borderTopRightRadius: 15, paddingTop: "2px", paddingLeft: "2px", paddingRight: "2px", marginLeft: "20px" }}>
                        <Typography
                          sx={{
                            borderTopLeftRadius: 15, borderTopRightRadius: 15, px: 2, py: 0.4, width: "fit-content", background: `${AppColors.backgroundColor}`, color: AppColors.blackColor, fontSize: "0.8rem",
                          }}>Feedback - Media</Typography></Box>
                    </Box>
                    <Box sx={{ borderRadius: "10px" }}>
                      <Grid container spacing={1}>

                        <FeedbackFormReportItem title='Feedback Form 1' question='Do you like the Product, Opportunity or Both?' index={0} answers={corporateReporting.mediaFeedbackFormCount?.form_one_feedbacks || []} isLoading={corporateReporting.isMediaFeedbackFormCountLoading} />
                        <FeedbackFormReportItem title='Feedback Form 2' question='Would this product be a benefit to you?' index={1} answers={corporateReporting.mediaFeedbackFormCount?.form_two_feedbacks || []} isLoading={corporateReporting.isMediaFeedbackFormCountLoading} />

                      </Grid>

                    </Box>
                  </Box>

                </Grid>

                <Grid item xs={12}>
                  <Box >
                    <Box sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: 'start',
                      mr: 1.1,
                      textAlign: 'center',
                    }}>
                      <Box sx={{ background: AppColors.blackColor, pb: 1, borderTopLeftRadius: 15, borderTopRightRadius: 15, paddingTop: "2px", paddingLeft: "2px", paddingRight: "2px", marginLeft: "20px" }}>
                        <Typography
                          sx={{
                            borderTopLeftRadius: 15, borderTopRightRadius: 15, px: 2, py: 0.4, width: "fit-content", background: `${AppColors.backgroundColor}`, color: AppColors.blackColor, fontSize: "0.8rem",
                          }}>Duration Video Views</Typography></Box>
                    </Box>
                    <Box sx={{ borderRadius: "10px" }}>
                      <Grid container spacing={1}>
                        <Grid xs={12}>

                          <Box sx={{ background: `${AppColors.primaryColor}`, borderRadius: "10px", p: 1 }}>
                            <TableContainer>
                              <Table stickyHeader aria-label='sticky table'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align={'left'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>Media Videos % Viewed</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>Duration</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>10</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>20</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>30</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>40</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>50</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>60</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>70</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>80</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>90</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>100</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody sx={{ background: `${AppColors.primaryColor}` }}>
                                  {corporateReporting.isCorporateGroupMediaVideoViewLoading && <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={`gmvReport-progress`}
                                    sx={{ ml: 0.5, mr: 0.5 }}
                                  >
                                    <TableCell
                                      colSpan={12}
                                      align={'center'}
                                      sx={{
                                        background: AppColors.whiteColor,
                                        boxShadow: 'none !important',
                                      }}
                                    >
                                      <CircularProgress
                                        color='inherit'
                                        size={25}
                                        sx={{

                                        }}
                                      />
                                    </TableCell></TableRow>}
                                  {!corporateReporting.isCorporateGroupMediaVideoViewLoading && (corporateReporting.corporateGroupMediaVideoView || []).map((item, idx) => {
                                    return (<TableRow
                                      hover
                                      tabIndex={-1}
                                      key={`gmvReport-${idx}`}
                                      sx={{ ml: 0.5, mr: 0.5 }}
                                    >
                                      <TableCell
                                        align={'left'}
                                        sx={{
                                          ml: 0.5,
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.content_title || ""}</TableCell>
                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.video_duration || ""}</TableCell>

                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.ten || 0}</TableCell>

                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.twenty || 0}</TableCell>
                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.thirty || 0}</TableCell>
                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.forty || 0}</TableCell>
                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.fifty || 0}</TableCell>

                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.sixty || 0}</TableCell>
                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.seventy || 0}</TableCell>
                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.eighty || 0}</TableCell>

                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.ninety || 0}</TableCell>
                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.hundred || 0}</TableCell>

                                    </TableRow>);
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Grid>
                      </Grid>

                    </Box>
                  </Box>

                </Grid>


                <Grid item xs={12}>
                  <Box >
                    <Box sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: 'start',
                      mr: 1.1,
                      textAlign: 'center',
                    }}>
                      <Box sx={{ background: AppColors.blackColor, pb: 1, borderTopLeftRadius: 15, borderTopRightRadius: 15, paddingTop: "2px", paddingLeft: "2px", paddingRight: "2px", marginLeft: "20px" }}>
                        <Typography
                          sx={{
                            borderTopLeftRadius: 15, borderTopRightRadius: 15, px: 2, py: 0.4, width: "fit-content", background: `${AppColors.backgroundColor}`, color: AppColors.blackColor, fontSize: "0.8rem",
                          }}>Duration Landing Page Video Views</Typography></Box>
                    </Box>
                    <Box sx={{ borderRadius: "10px" }}>
                      <Grid container spacing={1}>
                        <Grid xs={12}>

                          <Box sx={{ background: `${AppColors.primaryColor}`, borderRadius: "10px", p: 1 }}>
                            <TableContainer>
                              <Table stickyHeader aria-label='sticky table'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align={'left'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>Landing Page Videos % Viewed</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>Duration</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>10</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>20</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>30</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>40</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>50</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>60</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>70</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>80</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>90</TableCell>
                                    <TableCell align={'center'} sx={{ background: `${AppColors.primaryColor}`, color: AppColors.whiteColor }}>100</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody sx={{ background: `${AppColors.primaryColor}` }}>
                                  {corporateReporting.isCorporateGroupLandingPageVideoViewLoading && <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={`gmvReport-progress`}
                                    sx={{ ml: 0.5, mr: 0.5 }}
                                  >
                                    <TableCell
                                      colSpan={12}
                                      align={'center'}
                                      sx={{
                                        background: AppColors.whiteColor,
                                        boxShadow: 'none !important',
                                      }}
                                    >
                                      <CircularProgress
                                        color='inherit'
                                        size={25}
                                        sx={{

                                        }}
                                      />
                                    </TableCell></TableRow>}

                                  {!corporateReporting.isCorporateGroupLandingPageVideoViewLoading && (corporateReporting.corporateGroupLandingPageVideoView || []).map((item, idx) => {
                                    return (<TableRow
                                      hover
                                      tabIndex={-1}
                                      key={`gmvReport-${idx}`}
                                      sx={{ ml: 0.5, mr: 0.5 }}
                                    >
                                      <TableCell
                                        align={'left'}
                                        sx={{
                                          ml: 0.5,
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.title || ""}</TableCell>
                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.video_duration || ""}</TableCell>
                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.ten || 0}</TableCell>

                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.twenty || 0}</TableCell>
                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.thirty || 0}</TableCell>
                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.forty || 0}</TableCell>
                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.fifty || 0}</TableCell>

                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.sixty || 0}</TableCell>
                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.seventy || 0}</TableCell>
                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.eighty || 0}</TableCell>

                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.ninety || 0}</TableCell>
                                      <TableCell
                                        align={'center'}
                                        sx={{
                                          background: AppColors.whiteColor,
                                          borderRight: `${AppColors.primaryColor} 1px solid`,
                                          boxShadow: 'none !important',
                                        }}
                                      >{item.hundred || 0}</TableCell>

                                    </TableRow>);
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Grid>
                      </Grid>

                    </Box>
                  </Box>

                </Grid>

              </Grid>

            </Box >
          </Box >
          {toggleGroupMedia && <SelectGroupMediaModal
            onClose={onCloseGroupMedia}
            selectedMedia={groupMediaSelectionType == MediaSelectionType.SHARE ? selectedShareGroupMedia : selectedViewGroupMedia}
            onSelect={handleOnMediaSelectionChange}
            toggle={toggleGroupMedia}
          />}
        </Container >
      </Page >
    </>
  );
};
export default CorporateReport;
