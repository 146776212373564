import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/system';
import '@splidejs/react-splide/css';
import { LoadingButton } from '@mui/lab';
import { AppColors } from '../../utils/utils';

type IProps = {
  toggle: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  confirmButtonText: string;
  cancelButtonText: string;
};

const opts = {
  height: '100%',
  width: '100%',
};

const ConfirmationModel: React.FC<IProps> = ({
  toggle, onClose, onConfirm, title,
  message,
  confirmButtonText,
  cancelButtonText
}) => {



  return (
    <>
      <Dialog
        open={toggle}
        onClose={() => {

        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle
          sx={{
            backgroundColor: AppColors.primaryColor,
            color: 'white',
          }}
        >
          {title}
        </DialogTitle>

        <DialogContent sx={{ padding: 0 }}>

          <Box
            margin={2}
            alignItems='center'
            justifyContent='center'
            mb={window?.innerWidth > 500 ? 2 : 1}
          >
            <Typography>{message}</Typography>
          </Box>

        </DialogContent>
        <DialogActions sx={{ paddingX: 3 }}>
          <LoadingButton
            type="button"
            variant="contained"
            onClick={onClose}
          >
            {cancelButtonText}
          </LoadingButton>
          <LoadingButton
            type="submit"
            onClick={onConfirm}
            variant="contained"
          >
            {confirmButtonText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationModel;
