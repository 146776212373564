import React, { useState } from 'react';

import { Avatar, Box, Grid, ImageList, ImageListItem, Stack, Typography } from '@mui/material';
import { ChatMessage, ChatUser } from '../../../store/chat/api/interface';
import moment from 'moment';
import Linkify from 'react-linkify';
import useToggle from '../../../hooks/useToggle';
import FullscreenChatMediaViewerDialog from '../../modal/FullscreenChatMediaViewerDialog';
import { MediaType } from '../../../enum';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useAppSelector } from '../../../store';
import { AppColors } from '../../../utils/utils';

interface IProps {
    chatUser: ChatUser;
    chatMessage: ChatMessage;
}

const Message: React.FC<IProps> = ({ chatMessage, chatUser }) => {

    const [selectMediaIndex, setSelectMediaIndex] = useState(0);
    const loginUser = useAppSelector((state) => state?.auth?.user);
    const {
        toggle: toggleFullscreenChatMediaViewerDialog,
        onOpen: onOpenFullscreenChatMediaViewerDialog,
        onClose: onCloseFullscreenChatMediaViewerDialog,
    } = useToggle();

    const hendleViewMedia = (position: number) => {
        setSelectMediaIndex(position);
        onOpenFullscreenChatMediaViewerDialog();
    };

    const isSendByMe = (chatUser.id == (loginUser?.id ?? 0));

    return (
        <>
            {isSendByMe ? <Box component={'div'} sx={{ padding: '10px', width: "100%" }} justifyContent={'end'} alignContent={'flex-end'}>
                <Stack direction={'row-reverse'}>


                    <Stack direction={'column'} sx={{ maxWidth: '80%', alignItems: 'flex-end' }}>
                        {chatMessage.isShared && <Typography variant='caption'
                            sx={{
                                wordBreak: 'break-word',
                                whiteSpace: 'pre-wrap',
                                textAlign: 'end',
                                color: 'gray',
                                lineHeight: 'inherit'
                            }}>{(chatMessage.text ?? '').includes("/community/") ? "Shared social post" : "Shared media"}</Typography>}
                        <Typography
                            sx={{ textAlign: 'end', fontSize: "0.7rem", color: AppColors.grayThreeColor }}

                        >
                            {moment(chatMessage.time.toDate()).format("DD MMM, YYYY HH:mm")}
                        </Typography>
                        <Box
                            component={'div'}
                            display={'flex'}
                            alignItems={'start'}
                            justifyContent={'flex-start'}
                            sx={{
                                backgroundColor: AppColors.primaryColor,

                                borderRadius: '10px 0px 10px 10px',
                                width: "fit-content"
                            }}
                        >
                            <Typography variant='body2' sx={{ p: 2, wordBreak: 'break-word', whiteSpace: 'pre-wrap', color: isSendByMe ? AppColors.whiteColor : AppColors.grayThreeColor }} className='chatMessage white-link'>
                                <Linkify >{chatMessage.text ?? ''}</Linkify>
                            </Typography>
                        </Box>
                        <Stack alignItems={'flex-end'}>
                            {chatMessage.media && chatMessage.media.length > 0 && <ImageList sx={{ width: (chatMessage.media.length > 1 ? 210 : 105), pt: 1 }} cols={chatMessage.media.length > 1 ? 2 : 1}  >
                                {chatMessage.media.map((mediaItem, index) => (

                                    index < 4 ? <ImageListItem key={`media-${index}`} >
                                        <Box
                                            component={'img'}
                                            src={`${mediaItem.thumbnail && mediaItem.thumbnail.length > 0 ? mediaItem.thumbnail : mediaItem.link}`}
                                            alt={''}
                                            onClick={() => {
                                                hendleViewMedia(index);
                                            }}
                                            loading="lazy"
                                            sx={{ height: 100, width: 100, objectFit: 'cover' }}
                                        />
                                        {(chatMessage.media.length > 4 && index == 3) ? <Stack justifyContent="center" sx={{ height: '100%', width: '100%', backgroundColor: '#0000009e', position: 'absolute' }} onClick={() => {
                                            hendleViewMedia(index);
                                        }}><Typography sx={{ color: '#ffffff', textAlign: 'center' }}> {chatMessage.media.length - 4} More</Typography> </Stack> : (mediaItem.type == MediaType.video) && <Stack justifyContent="center" alignContent={'center'} alignItems={'center'} sx={{ height: '100%', width: '100%', position: 'absolute' }} onClick={() => hendleViewMedia(index)}>
                                            <Box sx={{ p: 0, borderRadius: '50%', textAlign: 'center', backgroundColor: '#0000009e', color: '#ffffff', height: '25px', width: '25px' }}><PlayArrowIcon /></Box></Stack>}
                                    </ImageListItem> : <></>

                                ))}
                            </ImageList>}</Stack>
                    </Stack>
                </Stack>
            </Box > : <Box component={'div'} sx={{ padding: '10px' }}>
                <Stack direction={'row'} >

                    <Avatar
                        sizes='small'
                        alt={chatUser.name ?? ''}
                        src={chatUser.profile_picture ?? '/static/images/placeholder_profile.png'}
                        sx={{ width: 40, height: 40, mr: 2 }}
                    />
                    <Stack direction={'column'} sx={{ maxWidth: '80%' }}>

                        <Stack >
                            <Typography sx={{ fontWeight: '400', fontSize: '1.0rem' }}>{chatUser.first_name ?? ''} {chatUser.last_name ?? ''}</Typography>
                        </Stack>
                        {chatMessage.isShared && <Typography variant='caption'
                            sx={{
                                wordBreak: 'break-word',
                                whiteSpace: 'pre-wrap',
                                textAlign: 'start',
                                color: 'gray',
                                lineHeight: 'inherit'
                            }}>{(chatMessage.text ?? '').includes("/community/") ? "Shared social post" : "Shared media"}</Typography>}

                        <Box
                            component={'div'}
                            display={'flex'}
                            alignItems={'start'}
                            sx={{
                                flexDirection: 'column'
                            }}
                        >

                            <Typography
                                sx={{ textAlign: 'end', fontSize: "0.7rem", color: AppColors.grayThreeColor, width: "100%" }}
                            >
                                {moment(chatMessage.time.toDate()).format("DD MMM, YYYY HH:mm")}
                            </Typography>
                            <Box sx={{
                                backgroundColor: AppColors.whiteColor,
                                width: "fit-content",
                                minWidth: "100px",
                                borderRadius: '0px 10px 10px 10px',
                            }}>
                                <Typography variant='body2' sx={{ p: 2, wordBreak: 'break-word', whiteSpace: 'pre-wrap', color: isSendByMe ? AppColors.whiteColor : AppColors.grayThreeColor }} className='chatMessage link'>
                                    <Linkify >{chatMessage.text ?? ''}</Linkify>
                                </Typography>
                            </Box>
                        </Box>
                        <Stack alignItems={'flex-end'}>
                            {chatMessage.media && chatMessage.media.length > 0 && <ImageList sx={{ width: (chatMessage.media.length > 1 ? 210 : 105), pt: 1 }} cols={chatMessage.media.length > 1 ? 2 : 1}  >
                                {chatMessage.media.map((mediaItem, index) => (

                                    index < 4 ? <ImageListItem key={`media-${index}`} >
                                        <Box
                                            component={'img'}
                                            src={`${mediaItem.thumbnail && mediaItem.thumbnail.length > 0 ? mediaItem.thumbnail : mediaItem.link}`}
                                            alt={''}
                                            onClick={() => {
                                                hendleViewMedia(index);
                                            }}
                                            loading="lazy"
                                            sx={{ height: 100, width: 100, objectFit: 'cover' }}
                                        />
                                        {(chatMessage.media.length > 4 && index == 3) ? <Stack justifyContent="center" sx={{ height: '100%', width: '100%', backgroundColor: '#0000009e', position: 'absolute' }} onClick={() => {
                                            hendleViewMedia(index);
                                        }}><Typography sx={{ color: '#ffffff', textAlign: 'center' }}> {chatMessage.media.length - 4} More</Typography> </Stack> : (mediaItem.type == MediaType.video) && <Stack justifyContent="center" alignContent={'center'} alignItems={'center'} sx={{ height: '100%', width: '100%', position: 'absolute' }} onClick={() => hendleViewMedia(index)}>
                                            <Box sx={{ p: 0, borderRadius: '50%', textAlign: 'center', backgroundColor: '#0000009e', color: '#ffffff', height: '25px', width: '25px' }}><PlayArrowIcon /></Box></Stack>}
                                    </ImageListItem> : <></>

                                ))}
                            </ImageList>}</Stack>
                    </Stack>
                </Stack>
            </Box >}


            <FullscreenChatMediaViewerDialog
                onClose={onCloseFullscreenChatMediaViewerDialog}
                toggle={toggleFullscreenChatMediaViewerDialog}
                index={selectMediaIndex}
                contents={chatMessage.media ?? []}
            />
        </>
    );
};

export default Message;
