import Dialog from '@mui/material/Dialog';
import {
  Avatar,
  Box,
  DialogActions,
  // Button,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { config } from '../../utils/utils';
import {
  getCommunityMediaSignInLink,
  getOSLink,
  isMobile,
} from '../../utils/getMobileOperatingSystem';
import CloseIcon from '@mui/icons-material/Close';
import { encryptString } from '../../utils/url';
import { useAppSelector } from '../../store';
import { LoadingButton } from '@mui/lab';

interface IProps {
  toggle: boolean;
  communityPostId: number;
  userName: string;
  deepLink: string | undefined | null;
  onClose: () => void;
}

const SocialDailog: React.FC<IProps> = ({
  toggle,
  communityPostId,
  userName,
  deepLink,
  onClose,
}) => {

  const referenceUserName = useAppSelector((state) => state.userInfo.sbUser?.username ?? state.userInfo.data?.username) ?? '';


  return (
    <Dialog
      open={toggle}
      onClose={() => {
        onClose();
      }}
      aria-labelledby='alert-dialog-title-2'
      aria-describedby='alert-dialog-description-2'
      maxWidth='md'
      fullWidth
      fullScreen={(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 500}
    >
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{ position: 'absolute', right: 0, zIndex: 100000 }}
      >
        <CloseIcon sx={{ color: "#FFFFFF" }} />
      </IconButton>
      <DialogContent
        sx={{
          padding: 0,
          overflowX: 'hidden',
          //overflowY: window?.innerWidth > 380 ? 'hidden' : 'auto',
          backgroundImage: "linear-gradient(0deg, #058ac5,  #032534)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Box
            component={'img'}
            src='/static/images/knocard_transparent.png'
            alt='logo'
            sx={{ maxWidth: "30%", width: "100px", mt: 3 }}
          />
        </Box>
        <Box
          display='flex'
          flexDirection={'column'}
          alignItems='center'
          justifyContent='center'
          sx={{ padding: '0px 24px' }}
          mt={1.5}
        >
          <Typography
            style={{
              width: "100%",

              fontWeight: 'bold',
              fontSize: '1.5rem',
              color: '#FFFFFF',
              textAlign: 'start'
            }}
          >
            Join KnoCard
          </Typography>
          <Box
            component={'img'}
            src='/static/images/joinknocard/joinknocard.webp'
            alt='join-business-logo'
            sx={{
              width: "100%",
              mt: 2,
              mb: 2,
              borderRadius: "10px"
            }}
          />

          <Typography
            style={{
              width: "100%",

              fontSize: window?.innerWidth > 500 ? '1.3rem' : '1.2rem',
              color: '#FFFFFF',
              textAlign: 'start'
            }}
          >
            Create a FREE KnoCard Social Connect account to interact with business professionals who share your interests.
          </Typography>
        </Box>




        <Box

          display='flex' alignItems='center' justifyContent='center'
          mt={1.5}
        >

          <Box
            component={'a'} href={`${config.VBC_URL}/register${referenceUserName ? `/${referenceUserName}` : ''}?url=${encryptString(`${userName}/community/${communityPostId}`)}&${config.IS_SOCIAL_REGISTER}=true`}
            sx={{
              width: "90%",
              pt: 1,
              pb: 1,
              borderRadius: "50px",
              textAlign: "center", backgroundImage: "linear-gradient(90deg, #F1C642,  #F67837)",
            }}
          >
            <Typography
              style={{
                fontSize: '1rem',
                color: '#ffffff',
              }}
            >
              Get Started
            </Typography>
          </Box>
        </Box>

        <Box display='flex' alignItems='center' justifyContent='center' mt={1}>
          <Box
            component={'a'}
            href={`${config.VBC_URL}/login?url=${encryptString(`${userName}/community/${communityPostId}`)}`}
            sx={{
              width: "90%",
              pt: 1,
              pb: 1,
              textAlign: "center",
              border: "solid 2px #F1C642",
              borderRadius: "50px",
              mb: 2,
            }}
          >
            <Typography
              style={{
                fontSize: '1rem',
                color: '#ffffff',
              }}
            >
              I already have an account
            </Typography></Box>
        </Box>

      </DialogContent>
      <DialogActions>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          sx={{
            width: "100%"
          }}
        >
          <img
            src='/static/images/joinknocard/knocard.png'
            alt='logo'
            height={"30px"}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SocialDailog;
