import { config } from './utils';

export function getOSLink() {
    var userAgent = window.navigator.userAgent;
    var platform = window.navigator?.platform || window.navigator.platform;
    var macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    var windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    var iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    let url = config.APP_STORE_URL;

    if (macosPlatforms.indexOf(platform) !== -1) {
    } else if (iosPlatforms.indexOf(platform) !== -1) {
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        url = config.PLAY_STORE_URL;
    } else if (/Android/.test(userAgent)) {
        url = config.PLAY_STORE_URL;
    } else if (/Linux/.test(platform)) {
        url = config.PLAY_STORE_URL;
    }
    return url;
}

export function isMobile() {
  var userAgent = window.navigator.userAgent;
  var platform = window.navigator?.platform || window.navigator.platform;
  var macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  var windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  var iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  return iosPlatforms.indexOf(platform) !== -1 || /Android/.test(userAgent);
}


export function getCommunityMediaSignInLink(userName: string, communityPostId: number) {


    let url = `${config.VBC_URL}/${userName}/community/${communityPostId}/comment`;


    return url;
}
