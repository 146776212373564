import {
    Box,
    Grid,
    Typography,
} from '@mui/material';

import styles from '../../styles/ReferLandingPageOne.module.css';
import Page from '../../components/Page';
import { Fragment, useEffect, useState } from 'react';
import { config } from '../../utils/utils';
import { useSearchParams } from 'react-router-dom';

const ReferLandingPageOne = () => {

    const [searchParams] = useSearchParams();
    const [referredByLink, setReferredByLink] = useState('');



    useEffect(() => {
        var refferLink = `${config.VBC_URL}`;
        if (searchParams.has(config.REFER_KEY)) {
            refferLink += `/affiliate/${searchParams.get(config.REFER_KEY) ?? ''}`;
        } else {
            refferLink += "/register";
        }
        setReferredByLink(refferLink);
    }, []);



    const steps = [
        { stepitem: 'Add your contact details, profile picture, and background' },
        { stepitem: 'Include links to your shopping page, replicate the site, and more' },
        { stepitem: 'Upload photos, videos, and media stacks' },
        { stepitem: 'Create your first social post' },
        { stepitem: 'Share your KnoCard, targeted media file, or social post' },
    ]

    const reportingdata = [
        { title: "MEDIA SHARES", description: "KnoCard enables you to send a feedback form with your media files and displays viewers responses", img: "/static/referrel_one/Page5/icons/2.png" },
        { title: "VIDEO VIEWS", description: "Detalled analysis of video views gives you a clear indication of what each prospect is most interested in", img: "/static/referrel_one/Page5/icons/3.png" },
        { title: "PAGE VIEWS", description: "Every single visit to your KnoCard is tracked, right down to the page name, visitor name, number of views, and date of last view", img: "/static/referrel_one/Page5/icons/3.png" },
        { title: "SOCIAL POSTS", description: "Simple reporting that shows who's been sharing your content", img: "/static/referrel_one/Page5/icons/4.png" },
        { title: "NEW PROSPECTS", description: "Lite CRM displays new prospects and enables file editing", img: "/static/referrel_one/Page5/icons/1.png" },
        { title: "REFERRALS", description: "Receive notice and details each time you receive a community based referral", img: "/static/referrel_one/Page5/icons/5.png" },
        { title: "PERSONAL SHARES", description: "Track your own sharing activity, or share your results with team leaders", img: "/static/referrel_one/Page5/icons/6.png" },
    ]
    const sec7Data = [
        { title: "DIGITAL SOLUTION", description: "A digital alternative to traditional business cards, but that's just scratching the surface" },
        { title: "PROVEN MARKETING ", description: "A singular hub for showcasing your photos and videos- the most potent tools in today's marketing arsenal" },
        { title: "SIMPLE SHARING", description: "Engage with both your existing contacts and new prospects through an array of versatile sharing options" },
        { title: "SUPER CLEAN SOCIAL MEDIA", description: "Step out of the 'dirty water' and engage in social media designed exclusively for business networking" },
        { title: "REJECTION FREE MARKETING", description: "Take back your time, bid farewell to rejection, and supercharge your productivity with KnoCard Reporting" },
    ]

    const sec9 = [
        { discription: "1 time payment; never pay again" },
        { discription: "Residual income on every personally sponsored Pro customer" },
        { discription: "Residual income on directly sponsored affiliates" },
        { discription: "Team bonuses" },
        { discription: "No cost back office, complete with sales tools and training" },
    ]


    return (
        <>
            <Page title='KnoCard' showAppBar={true}>
                <Box>
                    <section className={styles.sec_1_outer}>
                        <Box className={styles.sec_1_title_outer}>
                            <Box className={styles.sec_1_title1} sx={{
                                fontSize: {
                                    xs: '20px',
                                    sm: '28px',
                                    md: '28px',
                                    lg: '28px',
                                    xl: '35px',
                                }
                            }}>
                                <p>ATTENTION NETWORKERS, ENTREPRENEURS, AND SMALL BUSINESS OWNERS</p>
                            </Box>
                            <Box className={styles.sec_1_title2} sx={{
                                fontSize: {
                                    xs: '20px',
                                    sm: '28px',
                                    md: '28px',
                                    lg: '28px',
                                    xl: '35px',
                                }
                            }}>
                                <p>
                                    DON'T MISS OUT ON KNOCARD'S GAME-CHANGING <span>ALL-IN-ONE BUSINESS PLATFORM FOR REJECTION-FREE MARKETING!</span>
                                </p>
                            </Box>
                        </Box>

                        <Box className={styles.sec1_main}>
                            <Grid container pt={2} pb={2} >

                                <Grid item xs={1} sm={1} md={1} lg={2} container
                                    sx={{
                                        display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' }
                                    }}
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center">
                                    <Box className={styles.sec1_side1} pl={0.5}>
                                        <p>ROBERT</p>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={10} lg={8} container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center">
                                    <Box className={styles.sec1_center}>
                                        <Box className={styles.sec1_center_heaading} sx={{
                                            fontSize: {
                                                xs: '25px',
                                                sm: '50px',
                                                md: '65px',
                                                lg: '85px',
                                            }
                                        }} >
                                            <p>SUPERCHARGE YOUR SUCCESS WITH<br />  <span>THIS FIRST OF IT'S KIND</span> MARKETING TOOL</p>
                                        </Box>
                                        <Box className={styles.sec1_center_video} mt={2}>
                                            <video ref={(video: any) => {
                                                video?.setAttribute("controlsList", "nodownload");

                                            }} controls poster="https://knocard.com/assets_new/images/affiliate/mbx/placeholder.jpeg">
                                                <source src="https://knocard.com/assets_new/images/affiliate/mbx/video1.mp4" type="video/mp4" />Your browser does not support HTML video.
                                            </video>
                                        </Box>
                                        <Box className={styles.sec_1_video_title} mt={2}>
                                            <Box>
                                                <p>LET KNOCARD HELP YOU BREAK FREE FROM <br /><span>THE SOCIAL MEDIA GRIND AND FAMILY-FRIEND CHASE !</span></p>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xs={0} sm={0} md={1} lg={2} container
                                    sx={{
                                        display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' }
                                    }}
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center">
                                    <Box className={styles.sec1_side2} pr={0.5}>
                                        <p>PICKERING</p>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>

                    </section>

                    <section className={styles.sec2_outer}>
                        <Grid container>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box className={styles.sec2_title} pl={2} pr={2} sx={{
                                    fontSize: {
                                        xs: '18px',
                                        sm: '27px',
                                        md: '30px',
                                        lg: '40px',
                                        xl: '50px',
                                    },
                                }} >
                                    <p>
                                        IMAGINE UNLEASHING
                                        <span>THE ULTIMATE MARKETING INNOVATION</span>
                                        - A SINGLE, CUSTOMIZABLE POWERHOUSE THAT SEAMLESSLY INTEGRATES ALL OF TODAY'S PROVEN BUT FRAGMENTED MARKETING TECHNIQUES, COUNTLESS APPLICATIONS, ALL AT YOUR FINGERTIPS!
                                    </p>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} mt={3} mb={3}>
                                <Box className={styles.sec2_sub_title} sx={{
                                    fontSize: {
                                        xs: '25px',
                                        sm: '35px',
                                        md: '45px',
                                        lg: '55px',
                                        xl: '55px',
                                    },
                                }} >
                                    <p>Unlock the key to business success by catapulting your products and services into the spotlight of a vast audience.</p>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box className={styles.sec2_sub_content} sx={{
                                    fontSize: {
                                        xs: '20px',
                                        sm: '20px',
                                        md: '20px',
                                        lg: '30px',
                                        xl: '45px',
                                    }
                                }}>
                                    <p>
                                        If you're following the crowd, relying on word-of-mouth marketing, daily social media posts, and traditional networking with business cards, it's time to supercharge your strategy.
                                    </p> <br />
                                    <p>
                                        Embrace the revolutionary technology that empowers you to effortlessly display your contact information, captivating photos, engaging videos, and essential external links, all conveniently housed in one central hub. Elevate your networking game by adding fellow KnoCard users to your Preferred Partner list, and streamline your business conversations within a unified in-app messaging platform
                                    </p> <br />
                                    <p>
                                        You'll be amazed by the incredible affordability of KnoCard! There's even a free version that lets you leave those antiquated business cards behind while enjoying a host of essential features. It's time to upgrade your networking game!
                                    </p> <br />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} className={styles.sec2_footer_outer}>

                                <Grid container>

                                    <Grid xs={12} sm={6} md={6} lg={6} className={styles.sec2_footer_part_1_outer}>
                                        <Grid container className={styles.sec2_footer_part_1}>
                                            <Grid item xs={6} sm={6} md={6} lg={6} className={styles.sec2_line} >
                                                <Box></Box>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={6} >
                                                <Box className={styles.sec2_button}>
                                                    <Box>
                                                        <a href={referredByLink}>
                                                            <p>GET KNOCARD</p>
                                                        </a>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid xs={12} sm={6} md={6} lg={6} className={styles.sec2_footer_part_3} sx={{ width: '95%' }} >
                                        <Box className={styles.sec2_footer_arrow_img}>
                                            <img src={'/static/referrel_one/sec_2_arrow.png'} alt="" />

                                        </Box>
                                        <Box className={styles.sec2_footer_part_3_content}>
                                            <span>GUARANTEED</span>
                                            <br />
                                            <p>SUCCESS!</p>
                                        </Box>
                                        <Box className={styles.sec2_footer_part_3_img}>
                                            <img src={'/static/referrel_one/sec_2_eco_friendly_bage.png'} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </section>

                    <section className={styles.sec3}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={6} className={styles.sec3_part_1}>
                                <Box className={styles.sec3_part_1_content} sx={{
                                    fontSize: {
                                        xs: '30px',
                                        sm: '40px',
                                        md: '40px',
                                        lg: '46px',
                                        xl: '56px',
                                    },
                                }} >
                                    <p>BOOST BUSINESS SUCCESS WITH EXCEPTIONAL <br /> <span>EXPOSURE!</span></p>
                                </Box>
                                <Box className={styles.sec3_part_1_img}>

                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className={styles.sec3_part_2}>
                                <Box className={styles.sec3_part_2_title} sx={{
                                    fontSize: {
                                        xs: '30px',
                                        sm: '40px',
                                        md: '40px',
                                        lg: '46px',
                                        xl: '56px',
                                    },
                                }} >
                                    <p>UNLOCK LIMITLESS OUTREACH: SHARE YOUR <br /> MESSAGE WITH EASE!</p>
                                </Box>
                                <Box className={styles.sec3_part_2_content} mt={3} sx={{
                                    fontSize: {
                                        xs: '20px',
                                        sm: '30px',
                                        md: '25px',
                                        lg: '30px',
                                        xl: '35px',
                                    },
                                }}>
                                    <p>
                                        What does success look like to you? Be it a 7-figure income, the luxury of time freedom, or making a significant impact on others' lives, the ultimate path to achieving it begins with your ability to expose your products and services to a vast audience. It's a simple numbers game; the greater your exposure, the greater your rewards!
                                    </p> <br />
                                    <p>
                                        KnoCard is the only tool you'll need to seamlessly share your vital information with existing connections and promising prospects - all at the speed of success!
                                    </p> <br />
                                    <p>
                                        A multitude of sharing options empower you to effortlessly send your full array of information or focus on delivering precisely targeted photos and videos to existing contacts or potential new leads. Forget about [dead] Emails... this technology incorporates your personal mobile carrier and number to ensure optimal open rates!
                                    </p>
                                </Box>

                                <Box className={styles.sec3_button_outer} pt={4}>
                                    <Grid container>
                                        <Grid item xs={6} sm={6} md={6} lg={6} className={styles.sec3_button_part_1}>
                                            <Box></Box>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} className={styles.sec3_button_part_2}>
                                            <Box className={styles.sec3_button}>
                                                <Box>
                                                    <a href={referredByLink} >
                                                        <p>GET KNOCARD</p>
                                                    </a>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Grid>
                        </Grid>
                    </section>

                    <section className={styles.sec4}>
                        <Box mt={2} pb={4} pl={2} pr={2}>
                            <Box sx={{ fontSize: { xs: '30px', sm: '40px', md: '40px', lg: '46px', xl: '56px', }, }} >
                                <p className={styles.sec4_bold_1} style={{ paddingBottom: "20px" }}> <span>KNOCARD SOCIAL</span>: NO KIDS, NO CHAOS, NO POLITICS</p>
                            </Box>
                            <Box sx={{ fontSize: { xs: '25px', sm: '35px', md: '45px', lg: '55px', xl: '55px', }, }} >
                                <p className={styles.sec4_bold_3} style={{ paddingBottom: "30px" }}>If you ve had enough of pouring time and energy into social media platforms that yield underwhelming results, prepare to embrace KnoCard Social.</p>
                            </Box>
                            <Box sx={{ fontSize: { xs: '20px', sm: '30px', md: '25px', lg: '30px', xl: '35px', }, }} >
                                <p className={styles.sec4_bold_2} style={{ paddingBottom: "40px" }}>This revolutionary new approach eliminates the distractions that typically Boxert potential customers away from your posts, ensuring your message takes center stage. Created exclusively for business, this is a total game-changer for your brand.</p>
                            </Box>
                            <Box sx={{ fontSize: { xs: '30px', sm: '40px', md: '40px', lg: '46px', xl: '56px', }, }} >
                                <p className={styles.sec4_bold_1}>THE CHERRY ON TOP? <span> REJECTION-FREE MARKETING?</span></p>
                            </Box>
                            <Box sx={{ fontSize: { xs: '25px', sm: '35px', md: '45px', lg: '55px', xl: '55px', }, }} >
                                <p className={styles.sec4_bold_3} style={{ paddingBottom: "20px" }}>There are 2 things that keep most marketers and networkers from achieving ultimate success</p>
                            </Box>
                            <Box sx={{ fontSize: { xs: '30px', sm: '40px', md: '40px', lg: '46px', xl: '56px', }, }} >
                                <p className={styles.sec4_bold_1} style={{ paddingBottom: "50px" }}><span>1.</span> FEAR OF REJECTION AND <span>2.</span> FEAR OF REJECTION!</p>
                            </Box>
                            <Box sx={{ fontSize: { xs: '20px', sm: '30px', md: '25px', lg: '30px', xl: '35px', }, }} >
                                <p className={styles.sec4_bold_2} style={{ paddingBottom: "30px" }}>With this in mind, KnoCard has provided advanced data analytics and reporting designed to eliminate rejection</p>
                            </Box>
                            <Box sx={{ fontSize: { xs: '20px', sm: '30px', md: '25px', lg: '30px', xl: '35px', }, }} >
                                <p className={styles.sec4_bold_2}>Big corporations have experienced overwhelming growth leveraging the potential of data. Now, it's your opportunity to access these advantages without breaking the bank. For those committed to business success, your KnoCard Pro subscription unlocks the ability to see in real time</p>
                            </Box>
                        </Box>
                    </section>

                    <section className={styles.sec5}>
                        <Box className={`${styles.sec_non_res} ${styles.sec5_get_knocard_bg}`}>
                            <Box className={styles.sec5_img}><img src={'/static/referrel_one/Page5/sec5_background.png'} /></Box>
                            <Box className={`${styles.sec_footer} ${styles.sec5_get_knocard_bg}`} pb={2}>
                                <Grid container>
                                    <Grid item className={styles.comman} xs={0} sm={0} md={1} lg={1.5}></Grid>
                                    <Grid item className={styles.comman} xs={0} sm={0} md={4} lg={3} sx={{ display: "flex", justifyContent: "end", pr: 2 }}>
                                        <img src={'/static/referrel_one/Page5/sec5_left_arrow.png'} />
                                    </Grid>
                                    <Grid item className={styles.comman} xs={0} sm={0} md={4} lg={3} sx={{ padding: "0 10px" }}>
                                        <Box className={styles.sec5_button}>
                                            <Box>
                                                <a href={referredByLink}>
                                                    <p>GET KNOCARD</p>
                                                </a>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item className={styles.comman} xs={0} sm={0} md={4} lg={3} sx={{ pl: 2 }}>
                                        <img src={'/static/referrel_one/Page5/sec5_right_arrow.png'} />
                                    </Grid>
                                    <Grid item className={styles.comman} xs={0} sm={0} md={0} lg={1.5}></Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Box className={styles.sec5_responsive}>
                            <Box className={styles.hero_knocard_logo}>
                                <img src={'/static/referrel_one/Page5/knocard_logo.png'} alt="" />
                            </Box>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "center" }}>
                                    <h1 className={styles.sec5_responsive_title}>REPORTING</h1>
                                    <Box sx={{ fontSize: { xs: '20px', sm: '30px', md: '25px', lg: '30px', xl: '35px', }, }} >
                                        <p className={styles.sec5_responsive_des1} style={{ marginTop: "2rem" }}>Just imagine having this information at your fingertips! KnoCard Reporting is a transformative tool, sifting through the noise and providing precise insights to help you focus your time and efforts exclusively on engaged prospects.</p>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                {
                                    reportingdata.map((x, i) => {
                                        return (
                                            <Fragment key={i}>
                                                <Grid item xs={12} sm={6} md={4} lg={0} sx={{ p: 1 }} >
                                                    <Box className={styles.sec5_res_box_outer}>
                                                        <Box>
                                                            <p className={styles.sec_res_subtitle}>{x.title}</p>
                                                        </Box>
                                                        <Typography sx={{
                                                            fontFamily: 'BebasNeueThin',
                                                            letterSpacing: "1px",
                                                            fontSize: {
                                                                xs: '1.5rem',
                                                                sm: '1.5rem',
                                                                md: '1.5rem',
                                                                lg: '1.5rem',
                                                                xl: '1.5rem',
                                                            },
                                                            fontWeight: "700",
                                                            color: 'white',
                                                        }} className={styles.sec_res_sub_des}>{x.description}</Typography>
                                                        <Box className={styles.sec_res_img_outer}>
                                                            < Box className={styles.sec_res_img}>
                                                                <img src={x.img} alt={x.title} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Fragment >
                                        )
                                    })
                                }
                            </Grid >
                            <Grid container pb={2} pt={2}>
                                <Grid item xs={2} sm={2} md={3} lg={0} sx={{ display: "flex", alignItems: 'center' }}>
                                    <img src={'/static/referrel_one/Page5/sec5_left_arrow.png'} style={{ width: "100%" }} />
                                </Grid>
                                <Grid item xs={8} sm={8} md={6} lg={0}>
                                    <Box className={styles.sec5_button}>
                                        <Box>
                                            <a href={referredByLink}>
                                                <p>GET KNOCARD</p>
                                            </a>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={2} sm={2} md={3} lg={0} sx={{ display: "flex", alignItems: 'center' }}>
                                    <img src={'/static/referrel_one/Page5/sec5_right_arrow.png'} style={{ width: "100%" }} />
                                </Grid>
                            </Grid >
                        </Box >
                    </section >


                    <section className={styles.sec6}>
                        < Box >
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12} ml={3} mr={3}>
                                    <Box sx={{ fontWeight: '600', fontSize: { xs: "30px", sm: "30px", md: "40px", lg: "40px", xl: "56px", }, }} >
                                        <p className={styles.sec6_title}>
                                            Unlike being isolated on a stand-alone website, KnoCard uses meta-tagging to link you to other business-minded inBoxiduals, ensuring your profile is discoverable
                                        </p> <br />
                                    </Box>

                                    <Box sx={{ fontSize: { xs: '20px', sm: '20px', md: '25px', lg: '30px', xl: '35px', } }} >
                                        <p className={styles.sec6_content} >
                                            <span className={styles.sec6_bold}>In-app messaging</span> empowers you to connect with fellow community members or groups seamlessly, keeping your personal inbox free from clutter.
                                        </p> <br />
                                    </Box>

                                    <Box sx={{ fontSize: { xs: '20px', sm: '20px', md: '25px', lg: '30px', xl: '35px', } }} >
                                        <p className={styles.sec6_content}>
                                            Effortlessly < span className={styles.sec6_bold}>find the products and services</span> you require and refine your choices by exploring providers' KnoCards.
                                        </p > <br />
                                    </Box>

                                    <Box sx={{ fontSize: { xs: '20px', sm: '20px', md: '25px', lg: '30px', xl: '35px', } }} >
                                        <p className={styles.sec6_content}>
                                            Send and receive seamless < span className={styles.sec6_bold}>referrals</span> within the community, and receive instant notifications when a user has recommended you
                                        </p >
                                    </Box>

                                    <Box className={styles.sec6_footer} sx={{ fontSize: { xs: '30px', sm: '30px', md: '40px', lg: '40px', xl: '56px', } }} >
                                        < p className={styles.sec6_footer_title_1}>EXPERIENCE ALL THIS <br /> <span className={styles.sec6_footer_bold}> WITHIN A DEDICATED COMMUNITY OF USERS WITH A SINGULAR FOCUS ON BUSINESS.</span></p >
                                    </Box > <br />
                                </Grid >
                            </Grid >

                            <Box className={styles.sec6_button_outer} pb={3}>
                                <Grid container pb={2} pt={2} >
                                    <Grid item xs={2} sm={2} md={3.5} lg={0} sx={{ display: "flex", alignItems: 'center' }}>
                                        <img src={'/static/referrel_one/Page6/sec6_left_arrow.png'} style={{ width: "100%" }} />
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={5} lg={0} sx={{ display: "flex", alignItems: "center" }}>
                                        <Box className={styles.sec6_button}>
                                            <Box>
                                                <a href={referredByLink}>
                                                    <p>GET KNOCARD</p>
                                                </a>
                                            </Box>
                                        </Box>
                                    </Grid >
                                    <Grid item xs={2} sm={2} md={3.5} lg={0} sx={{ display: "flex", alignItems: 'center' }}>
                                        <img src={'/static/referrel_one/Page6/sec6_right_arrow.png'} style={{ width: "100%" }} />
                                    </Grid>
                                </Grid >
                            </Box >

                        </Box >
                    </section >

                    <section className={styles.sec7_outer}>

                        < Box className={
                            styles.sec7} pt={2} pb={2}>
                            <Box className={styles.sec7_contet} sx={{
                                fontSize: {
                                    xs: '30px',
                                    sm: '30px',
                                    md: '40px',
                                    lg: '40px',
                                    xl: '55px',
                                },
                                fontFamily: 'BebasNeueRegular'
                            }} >
                                WE CHALLENGE YOU TO FIND ANOTHER TOOL THAT PROVIDES<span>ALL OF THIS</span>, CONSOLIDATED ON A < span > SINGLE</span > <span>PLATFORM</span>, AT AN INCREDIBLY AFFORDABLE RATE
                            </Box >
                        </Box >
                        <Box className={styles.sec7_res} pb={2}>
                            < Box className={styles.sec7_responsive_contet}>
                                WE CHALLENGE YOU TO FIND ANOTHER TOOL THAT PROVIDES<span>ALL OF THIS</span>, CONSOLIDATED ON A < span > SINGLE</span > <span>PLATFORM</span>, AT AN INCREDIBLY AFFORDABLE RATE
                            </Box >
                            <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60%" }}>
                                {
                                    sec7Data.map((x, i) => {
                                        return (
                                            <Grid item xs={12} sm={12} md={6} lg={0} className={styles.sec7_box_outer} key={i}>
                                                <Box className={styles.sec7_box}>
                                                    <p className={styles.sec7_box_title}>
                                                        {x.title}
                                                    </p>
                                                    <Typography sx={{
                                                        fontFamily: 'BebasNeueThin',
                                                        fontWeight: '600',
                                                        color: "#fff",
                                                        letterSpacing: "1px",
                                                        fontSize: {
                                                            xs: '20px',
                                                            sm: '20px',
                                                            md: '20px',
                                                            lg: '20px',
                                                            xl: '20px',
                                                        },
                                                    }} >
                                                        {x.description}
                                                    </Typography>
                                                </Box >
                                            </Grid >
                                        )
                                    })
                                }
                            </Grid >
                        </Box >
                    </section >

                    <section className={styles.sec8}>
                        < Grid container >
                            <Grid item xs={12} sm={12} md={6} lg={6} className={styles.sec8_side_1}>
                                < Box component={'img'} src={'/static/referrel_one/Page8/sec8_side1.png'} sx={{ objectFit: 'contain' }} />
                            </Grid >
                            <Grid item xs={12} sm={12} md={6} lg={6} className={styles.sec8_side_2}>
                                < Box className={styles.sec8_side_2_title}>
                                    <Box sx={{
                                        fontSize: {
                                            xs: '1.5rem',
                                            sm: '1.5rem',
                                            md: '1.5rem',
                                            lg: '1.5rem',
                                            xl: '1.5rem',
                                        },
                                    }} >
                                        <p>ROB PICKERING</p>
                                    </Box>
                                    <span>KnoCard Founder and CEO</span> <br />
                                    <span>ROB BID {"-"} Knocard Story</span> <br />
                                </Box >
                                <Box className={styles.sec8_side_2_des}>
                                    <p>
                                        Allocate a mere 5 minutes of your time, and experience the transformative potential of crafting a tailored profile that sets you miles ahead of the competition!
                                    </p >
                                </Box >
                                <Box className={styles.sec8_side_2_step_title}>
                                    < p > Your Knobard can be activated in a flash, and ready to share with the world in a few quick steps:</p >
                                </Box >
                                <Box className={styles.sec8_side_2_steps}>
                                    {
                                        steps.map((x, i) => {
                                            return (
                                                <>
                                                    <Typography sx={{
                                                        fontFamily: 'BebasNeueRegular',
                                                        fontSize: {
                                                            xs: '18px',
                                                            sm: '18px',
                                                            md: '25px',
                                                            lg: '30px',
                                                            xl: '40px',
                                                        }
                                                    }} ><p>{i + 1} {" "} {x.stepitem} </p></Typography>

                                                </>
                                            )
                                        })
                                    }
                                </Box >
                                <Box className={styles.sec8_button_outer}>
                                    < Grid container >
                                        <Grid item xs={6} sm={6} md={6} lg={6} className={styles.sec8_button_part_1}>
                                            <Box></Box >
                                        </Grid >
                                        <Grid item xs={6} sm={6} md={6} lg={6} className={styles.sec8_button_part_2}>
                                            < Box className={
                                                styles.sec8_button}>
                                                <Box>
                                                    < a href={referredByLink} >
                                                        <p>GET KNOCARD</p>
                                                    </a >
                                                </Box >
                                            </Box >
                                        </Grid >
                                    </Grid >
                                </Box >

                            </Grid >
                        </Grid >
                    </section >

                    <section className={styles.sec9}>
                        < Grid container pb={2} >
                            <Grid item xs={12} sm={12} md={6} lg={6} className={styles.sec9_part_1}>
                                < Box component={'img'} src={'/static/referrel_one/Page8_1/Superman_KnoCard_-_V.png'} sx={{ objectFit: 'contain', width: '100%' }} />
                            </Grid >
                            <Grid item xs={12} sm={12} md={6} lg={6} className={styles.sec_part_2}>
                                < Box className={
                                    styles.sec9_part_2_title}>
                                    <p>< span > EXCLUSIVE INVITATION:</span > RESERVED FOR THE MOST AMBITIOUS ENTREPRENEURS...</p >
                                </Box >
                                <Box className={styles.sec9_side_2_des}>
                                    <Box sx={{
                                        fontFamily: "BebasNeueBold",
                                        fontSize: {
                                            xs: '18px',
                                            sm: '18px',
                                            md: '25px',
                                            lg: '30px',
                                            xl: '40px',
                                        },
                                    }}>
                                        <p>
                                            Join us as an affiliate and claim your slice of the KnoCard pie! This is your golden opportunity to earn a substantial income every time you introduce a contact who
                                        </p >
                                    </Box>
                                </Box >
                                <Box className={styles.sec9_side_2_subtitle}>
                                    <p>
                                        For a strictly limited time, KnoCard is offering Pro - 1, a bundled package that provides a feature - rich KnoCard along with an affiliate position.Seize your chance today and earn $$$ on a digital product that appeals to the masses and is experiencing viral growth! Pro - 1 offers:
                                    </p >
                                </Box >
                                <Box className={styles.sec9_side2_steps}>
                                    {
                                        sec9.map((x, i) => {
                                            return (
                                                <Typography key={i} sx={{
                                                    fontFamily: 'BebasNeueRegular',
                                                    fontSize: {
                                                        xs: '14px',
                                                        sm: '14px',
                                                        md: '20px',
                                                        lg: '20px',
                                                        xl: '30px',
                                                    },
                                                }} >
                                                    {x.discription}
                                                </Typography>
                                            )
                                        })
                                    }
                                </Box >
                                <Box className={styles.sec9_button_outer}>
                                    < Grid container >
                                        <Grid item xs={6} sm={6} md={6} lg={6} className={styles.sec9_button_part_1}>
                                            <Box></Box >
                                        </Grid >
                                        <Grid item xs={6} sm={6} md={6} lg={6} className={styles.sec9_button_part_2}>
                                            < Box className={
                                                styles.sec9_button}>
                                                <Box>
                                                    < a href={referredByLink} >
                                                        <p>GET KNOCARD</p>
                                                    </a >
                                                </Box >
                                            </Box >
                                        </Grid >
                                    </Grid >
                                </Box >
                            </Grid >
                        </Grid >
                    </section >
                </Box >
            </Page >
        </>
    );
};

export default ReferLandingPageOne;