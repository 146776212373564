import { createSlice } from '@reduxjs/toolkit';
import { getTutorialVideos } from './api';
import { TutorialVideoItem, TutorialVideoSearchResult, initialState } from './api/interface';
import { getCapitalizedString } from '../../utils/utilMethods';
import { tutorialTabs } from '../../utils/utils';

const tutorialVideoSlice = createSlice({
  name: 'tutorialVideo',
  initialState,
  reducers: {
    reset: () => initialState,
    updateSearchText: (state, action) => {
      state.searchText = action.payload;
      state.searchResult = filterVideos(state.searchText, state.data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTutorialVideos.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getTutorialVideos.fulfilled, (state, action) => {
      state.data = action.payload.data.data;
      state.searchResult = filterVideos(state.searchText, state.data);
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getTutorialVideos.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset, updateSearchText } = tutorialVideoSlice.actions;
export default tutorialVideoSlice.reducer;

const filterVideos = (searchText: string, videoList: TutorialVideoItem[]) => {

  let videoItems: TutorialVideoItem[] = [];
  //state.videoList.where((element) => ((element.title??"").toLowerCase().contains(keyword.toString()) || (element.tag??"").toLowerCase().contains(keyword.toString()))).toList());
  let searchContent = searchText.trim().toLocaleLowerCase();
  if (searchContent.trim().length > 0) {
    videoItems = videoList.filter(obj => obj.title.toLocaleLowerCase().includes(searchContent) || obj.tag.toLocaleLowerCase().includes(searchContent));
  } else {
    videoItems = videoList;
  }

  let sectionList = [...new Set(videoItems.map((item) => item.type))].sort()
  let videoTutorialSectionList: TutorialVideoSearchResult[] = [];

  sectionList.forEach((item) => {
    const sectionItemList: TutorialVideoItem[] = videoItems.filter(obj => obj.type == item).sort((a, b) => a.position - b.position);

    videoTutorialSectionList.push({ title: getCapitalizedString(item.replaceAll("_", " ")), code: item, data: [...sectionItemList] });
  });



  const customComparator = (sectionOne: TutorialVideoSearchResult, sectionTwo: TutorialVideoSearchResult) => {
    const indexOne = tutorialTabs.findIndex((element) => element.code === sectionOne.code);
    const indexTwo = tutorialTabs.findIndex((element) => element.code === sectionTwo.code);
    return indexOne - indexTwo;
  };

  return videoTutorialSectionList.sort(customComparator);




}