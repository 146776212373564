import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from '../../styles/Reports.module.css';
import {
  ReferralsItem as ReferralsItemModel,
} from '../../store/reports/api/interface';
import { dateFormate } from '../../utils/format';
import { AppColors } from '../../utils/utils';


interface IProps {
  referralItemModel: ReferralsItemModel;
  index: number;
}

const ReferralItem: React.FC<IProps> = ({
  referralItemModel,
  index
}) => {

  return (
    <Box style={{ width: '100%' }}>
      <Box
        className={styles.itemContainer}
        sx={{
          background: AppColors.grayZeroColor,
          border: `solid 1px ${AppColors.strokeColor}`,
          mt: 0.6, borderRadius: "6px"
        }}
      >

        <img
          alt='pic'
          src={`${referralItemModel.user?.profile_picture ?? '/static/images/placeholder_profile.png'}`}
          className={styles.knoSmilie}
        />
        <Box className={styles.itemContainerContent} sx={{ ml: 1, flexGrow: 1, }} >
          <Typography sx={{ fontSize: "1rem", fontWeight: 400 }}>
            {referralItemModel.user?.first_name ?? ''}{' '}
            {referralItemModel.user?.last_name ?? ''}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", color: AppColors.grayTwoColor }}>
            {`${referralItemModel.share_to_profile != null ? `Referred ${`${referralItemModel.share_to_profile?.first_name ?? ''} ${referralItemModel.share_to_profile?.last_name ?? ''}`}` : "Share link"}`}
          </Typography>
        </Box>
        <Box className={styles.itemContainerContent} alignItems={'end'} sx={{ pl: 1 }}>
          <Typography sx={{ fontSize: "0.8rem", color: AppColors.grayTwoColor }}>
            {`${referralItemModel.created_at && dateFormate(referralItemModel.created_at)} `}
          </Typography>
          <Box className={styles.itemContainerContentBody} sx={{ fontWeight: 'inherit' }}>
            &nbsp;
          </Box>
        </Box>
      </Box>

    </Box>
  );
};

export default ReferralItem;
