import { createAsyncThunk } from '@reduxjs/toolkit';
import { toastHandler } from '../../../utils/toastHandler';
import { axiosInstance } from '../../../client';
import {
  GetKnoCardViewCountRequest,
  GetKnoCardViewRequest,
  GetLeadViewRequest,
  GetReferralsRequest,
  GetMediaSharePostReportRequest,
  GetMediaSharePostCountRequest,
  GetShareSocialPostsDetailRequest,
  GetShareSocialPostsRequest,
  GetWebAppShareRequest,
  GetMediaSharePostReportDetailRequest,
  GetMediaVideoCountViewRequest,
  GetMediaVideoCountViewDetailRequest,
  GetMediaVideoCounterDetailRequest,
  GetAffiliateLandingPageVideoCountViewRequest,
  GetAffiliateLandingPageVideoDetailRequest,
  AffiliateLandingPageVideoViewsReport,
  AffiliateLandingPageVideoViewDataReport,
  ReportResponse,
  GetShareAffiliateLandingPageReportRequest,
  AffiliateLandingPageWatchVideoResponse,
  AffiliateLandingPageWatchVideoReportRequest,
  EngageUserActivityCountResponse
} from './interface';

export const getAllReportTotal = createAsyncThunk(
  'reports/getAllReportTotal',
  async (data: { userId: number, isFromKnoCardLink?: boolean }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<ReportResponse>(
        `/api/reports/all-view-count`,
        data
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getLeadsView = createAsyncThunk(
  'reports/getLeadsView',
  async (data: GetLeadViewRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/api/reports/contact-view/details`,
        data
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getKnoCardView = createAsyncThunk(
  'reports/getKnoCardView',
  async (data: GetKnoCardViewRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/api/reports/knocard-view/details`,
        data
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getKnoCardViewDetail = createAsyncThunk(
  'reports/getKnoCardViewDetail',
  async (data: GetKnoCardViewCountRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/api/reports/knocard-view/user-details-count`,
        data
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getWebAppShare = createAsyncThunk(
  'reports/getWebAppShare',
  async (
    { data, page }: { data: GetWebAppShareRequest; page: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/profile/webappshare-report?page=${page}`,
        data
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getReferrals = createAsyncThunk(
  'reports/getReferrals',
  async (
    { data, page }: { data: GetReferralsRequest; page: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/profile/referrals-report?page=${page}`,
        data
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getShareSocialPosts = createAsyncThunk(
  'reports/getShareSocialPosts',
  async (
    { data, page }: { data: GetShareSocialPostsRequest; page: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/reports/company/feed-share-report?page=${page}`,
        data
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getShareSocialPostDetail = createAsyncThunk(
  'reports/getShareSocialPostDetail',
  async (
    {
      data,
      feedId,
      page,
    }: { data: GetShareSocialPostsDetailRequest; feedId: number; page: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/reports/company/feed-share-report/detail/${feedId}?page=${page}`,
        data
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getMediaSharePostCounts = createAsyncThunk(
  'reports/getMediaSharePostCounts',
  async (
    { data }: { data: GetMediaSharePostCountRequest },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `/api/post/share-report/count?user_id=${data.user_id}&days=${data.days}`
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getMediaSharePostReport = createAsyncThunk(
  'reports/getMediaSharePostReport',
  async (
    { data, page }: { data: GetMediaSharePostReportRequest; page: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/post/share-report?page=${page}`,
        data
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const getShareAffiliateLandingPageData = createAsyncThunk(
  'reports/getShareAffiliateLandingPageData',
  async (
    { data, page }: { data: GetShareAffiliateLandingPageReportRequest; page: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/reports/landing-page/share/details?page=${page}`,
        data
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const getMediaSharePostReportDetail = createAsyncThunk(
  'reports/getShareSocialPostDetail',
  async (
    {
      data,
      postId,
      page,
    }: {
      data: GetMediaSharePostReportDetailRequest;
      postId: number;
      page: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/post/share-report/detail/${postId}?page=${page}`,
        data
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getMediaVideoCountView = createAsyncThunk(
  'reports/getMediaVideoCountView',
  async (data: GetMediaVideoCountViewRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/api/reports/media-count/viewer/details`,
        data
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getMediaVideoCountViewDetail = createAsyncThunk(
  'reports/getMediaVideoCountViewDetail',
  async (data: GetMediaVideoCountViewDetailRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/api/reports/media-count/post/details`,
        data
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const getAffiliateLandingPageVideoView = createAsyncThunk(
  'reports/getAffiliateLandingPageVideoView',
  async (data: GetAffiliateLandingPageVideoCountViewRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<AffiliateLandingPageVideoViewDataReport>(
        `/api/reports/landing-page-video-count/viewer/details`,
        data
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const getAffiliateLandingPageVideoDetail = createAsyncThunk(
  'reports/getAffiliateLandingPageVideoDetail',
  async (data: GetAffiliateLandingPageVideoDetailRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<AffiliateLandingPageVideoViewsReport>(
        `/api/reports/landing-page-video-count/content/details`,
        data
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getMediaVideoCounterDetail = createAsyncThunk(
  'reports/getMediaVideoCounterDetail',
  async (data: GetMediaVideoCounterDetailRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/api/reports/media-count/content/details`,
        data
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);



export const getAffiliateLandingPageWatchVideoReport = createAsyncThunk(
  'reports/getAffiliateLandingPageWatchVideoReport',
  async (data: AffiliateLandingPageWatchVideoReportRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<AffiliateLandingPageWatchVideoResponse>(
        `/api/reports/landing-page/video-watch/details`,
        data
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const getEngageUserActivityCount = createAsyncThunk('reports/getEngageUserActivityCount', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get<EngageUserActivityCountResponse>(
      `/api/reports/engage_user_activity_count`
    );

    return response;
  } catch (error: any) {
    toastHandler(error);
    return rejectWithValue(error.message);
  }
}
);