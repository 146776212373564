import { PayPalButtons } from "@paypal/react-paypal-js";
import { AppDispatch, useAppSelector } from '../../store';
import { useDispatch } from 'react-redux';
interface IProps {
    planId: string;
    onApprove: (data: any, actions: any) => Promise<void>;
}

const PaypalSubscriptionButton: React.FC<IProps> = ({ planId, onApprove }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { user } = useAppSelector((state) => state?.auth);

    const createSubscription = async (data: any, action: any) => {
        return action.subscription.create({
            'plan_id': planId
        });
    }

    return (
        <PayPalButtons createSubscription={createSubscription} onApprove={onApprove} onError={(error) => { console.log(error); }} />
    );

}

export default PaypalSubscriptionButton;