import { createSlice } from '@reduxjs/toolkit';
import { getPricingVideos } from './api';
import { initialState } from './api/interface';

const pricingVideoSlice = createSlice({
  name: 'pricingVideos',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPricingVideos.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getPricingVideos.fulfilled, (state, action) => {
      state.data = action.payload.data.data;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getPricingVideos.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset } = pricingVideoSlice.actions;
export default pricingVideoSlice.reducer;
