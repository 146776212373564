import { axiosInstance } from '../../../client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateProOneOrderRequest, CreateStripeOrderRequest, SubscriptionPurchaseSuccessRequest, StripeSubscriptionModifyLinkResponse } from './interface';
import { toastHandler } from '../../../utils/toastHandler';



export const createProOneOrder = createAsyncThunk(
  'user/createProOneOrder',
  async (values: CreateProOneOrderRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        '/api/subscriptions/create-paypal-order',
        values
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const pubscriptionPurchaseSuccess = createAsyncThunk(
  'user/subscriptionPurchaseSuccess',
  async (values: SubscriptionPurchaseSuccessRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        '/api/subscriptions',
        values
      );
      return response;
    } catch (error: any) {
      console.log(error);
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const createStripeOrder = createAsyncThunk(
  'user/createStripeOrder',
  async (values: CreateStripeOrderRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        '/api/subscriptions/create-stripe-order',
        values
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const getStripeSubscriptionModifyLink = createAsyncThunk(
  'user/getStripeSubscriptionModifyLink',
  async (values: {}, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<StripeSubscriptionModifyLinkResponse>(
        '/api/subscriptions/stripe-subscription-modify-link'
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const modifyKnocardLinkStripeSubscription = createAsyncThunk(
  'user/createStripeOrder',
  async (values: {}, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        '/api/subscriptions/modify-knocard-link-stripe-subscription',
        values
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);