import { STATUS_TYPE } from '../../../enum';

export interface PreferredPartner {
  status: {
    code: string;
    description: string;
  };
  data: {
    current_page: number;
    data: PreferredPartner[]

  };
  preferredPartners: PreferredPartner[];
}

export interface PreferredPartner {
  id: number;
  partner_id: number;
  title: string;
  services: string;
  description: string;
  created_at: string;
  partner: Partner;
  contents: PreferredPartnerContents[];
}


export interface CreateUpdatePreferredPartner {
  id?: number;
  partner_id?: number;
  title?: string;
  services?: string;
  description?: string;
  deleteContents?: number[];
  contents: CreateUpdatePreferredPartnerContents[];
}

export interface CreateUpdatePreferredPartnerContents {
  file?: File;
  type: string;
  content?: PreferredPartnerContents;
}

export interface Partner {
  id: number;
  name: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_country_code: string;
  mobile_number: null;
  phone_number: string;
  show_phone_number: number;
  street1: string | null;
  street2: string | null;
  city: string;
  state: string;
  zip_code: string;
  profile_picture: string;
  show_profile_picture: number;
}

export interface PreferredPartnerContents {
  id: number;
  company_feed_id: number;
  user_id: number;
  type: string;
  link: string;
  thumbnail: null | string;
  status: number;
  created_at: string;
  updated_at: string;
  filename: null | string;
  thumb_filename: null | string;
  title: string;
  platform: string;
  position: number;
  description: string;
}

interface InitialState {
  data: PreferredPartner | null;
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  page: number;
  isLoadMore: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

export const initialState: InitialState = {
  data: null,
  status: 'idle',
  isError: false,
  isLoading: false,
  page: 1,
  isLoadMore: false,
  isSuccess: false,
  errorMessage: '',
};
