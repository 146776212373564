import { createSlice } from '@reduxjs/toolkit';
import { getNotifications, unreadNotificationCount } from './api';
import { initialState } from './api/interface';

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {

      if ((action.payload.data.data.current_page ?? 0) == 1) {
        state.data = action.payload.data.data.data;
      } else {
        state.data.push(...action.payload.data.data.data);
      }

      state.hasMore = ((action.payload.data.data.current_page ?? 0) <= (action.payload.data.data.last_page ?? 0))
      state.page = action.payload.data.data.current_page ?? 1;
      state.totalRecords = action.payload.data.data.total ?? 0;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getNotifications.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });


    builder.addCase(unreadNotificationCount.fulfilled, (state, action) => {

      state.totalUnread = action.payload.data.data.count ?? 0;

    });
  },
});

export const { reset } = notificationSlice.actions;
export default notificationSlice.reducer;
