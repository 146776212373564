import { STATUS_TYPE } from '../../../enum';
import { UserInfo } from '../../userInfo/api/interface';

export interface NotificationReponse {
  status: {
    code: string,
  },
  data: {
    current_page?: number,
    data: Notification[];
    first_page_url?: string;
    from?: number;
    last_page?: number;
    last_page_url?: string;
    next_page_url?: string;
    path?: string;
    per_page?: number;
    prev_page_url?: string;
    to?: number;
    total?: number;
  }
}



export interface UnreadNotificationCountReponse {
  status: string,
  message: string,
  data: {
    count?: number;
  }
}

export interface Notification {
  id: number;
  sender_id: number;
  receiver_id: number;
  notifiable_id: number;
  notifiable_type?: string;
  created_at: string;
  updated_at: string;
  is_seen: number;
  status: string;
  target_id: string;
  sender?: UserInfo
  receiver?: UserInfo;
}

interface InitialState {
  data: Notification[];
  totalUnread: number;
  totalRecords: number;
  page: number;
  status: STATUS_TYPE;
  hasMore: boolean;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

export const initialState: InitialState = {
  data: [],
  totalUnread: 0,
  totalRecords: 0,
  page: 0,
  status: 'idle',
  hasMore: true,
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
};
