import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../../store';
import styles from '../../styles/Reports.module.css';
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { getDaysFromFilter } from '../../utils/daysConvertor';
import { getKnoCardView } from '../../store/reports/api';
import PageViewsItem from '../Card/PageViewsItem';
import { getItem } from '../../utils/localStorage';
import { config } from '../../utils/utils';

interface IProps {
  selectedTimeFilter: string;
  selectedReportUser?: number;
}

const PageViews: React.FC<IProps> = ({
  selectedTimeFilter,
  selectedReportUser,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);
  const accessToken = getItem(config.AUTH_KEY);
  const knoCardViewData =
    useAppSelector(
      (state) => state?.reports?.knoCardViewData?.knoCardViewData
    ) ?? [];

  const [selectedSortFilter, setSelectedSortFilter] = useState('all');
  const reportSortList = [
    { label: 'All', value: 'all' },
    { label: 'Most Views', value: 'most_views' },
    { label: 'Most Recent', value: 'most_recent' },
  ];

  useEffect(() => {
    dispatch(
      getKnoCardView({
        user_id: selectedReportUser ? selectedReportUser : user?.id ?? 0,
        api_token: accessToken ?? '',
        timeline: getSortAs(),
        days: getDaysFromFilter(selectedTimeFilter),
      })
    ).unwrap();
  }, [selectedTimeFilter, selectedSortFilter]);

  const handleChangeSortFilter = (event: SelectChangeEvent) => {
    setSelectedSortFilter(event.target.value as string);
  };

  const getSortAs = () => {
    var sortAs = 'most_recent';
    if (selectedSortFilter === 'all') {
      sortAs = 'most_recent';
    } else if (selectedSortFilter === 'most_recent') {
      sortAs = 'most_recent';
    } else if (selectedSortFilter === 'most_views') {
      sortAs = 'most_views';
    } else if (selectedSortFilter === 'most_share') {
      sortAs = 'most_share';
    } else if (selectedSortFilter === 'most_referral') {
      sortAs = 'most_referral';
    } else {
      sortAs = 'most_recent';
    }
    return sortAs;
  };

  return (
    <>
      <div className={styles.resultsContainerHeader}>
        <Typography className={styles.activityBtn}>Page Views</Typography>
        <Select
          id='time-filter-select'
          value={selectedSortFilter}
          onChange={handleChangeSortFilter}
          IconComponent={() => (
            <img
              src='/static/images/arrow_down.svg'
              alt='arrow_down'
              className={styles.arrowDown}
            />
          )}
          className={styles.activityFilterDropDown}
        >
          {reportSortList.map((item: any) => {
            return (
              <MenuItem value={item.value}>
                <div className={styles.activityFilterDropDownText}>
                  {item.label}
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </div>

      <div className={styles.resultsListContainer}>
        {knoCardViewData &&
          knoCardViewData.map((data, index: number) => (
            <PageViewsItem
              key={index}
              knoCardView={data}
              index={index}
              selectedTimeFilter={selectedTimeFilter}
              selectedReportUser={selectedReportUser}
            />
          ))}
      </div>
    </>
  );
};

export default PageViews;
