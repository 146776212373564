import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { ProspectActivityReponse } from './interface';




export const getProspectActivity = createAsyncThunk(
  'prospectActivity/getProspectActivity',
  async (values: { pageNumber: number, searchName?: string, days: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<ProspectActivityReponse>(
        `/api/reports/prospect-activity?page=${values.pageNumber}&days=${values.days}&search_name=${values.searchName}`
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


