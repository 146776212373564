import { Box, IconButton, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { CompanyExternalLink } from "../store/companyExternalLinks/api/interface";
import { AppColors } from "../utils/utils";
import ExternalLinkButtonIcon from "./ExternalLinkButtonIcon";
import ExternalLinkIcon from '../assets/external_links/external_link.svg'
import { useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface IProps {
    companyExternalLink: CompanyExternalLink;
    handleExternalLinkVisibility: (companyExternalLink: CompanyExternalLink) => void;
    handleEditExternalLink: (companyExternalLink: CompanyExternalLink) => void;
    handleDeleteExternalLink: (companyExternalLink: CompanyExternalLink) => void;
}

const ExternalLinkItem: React.FC<IProps> = ({
    companyExternalLink,
    handleExternalLinkVisibility,
    handleEditExternalLink,
    handleDeleteExternalLink
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const onEditExternalLink = () => {
        handleEditExternalLink(companyExternalLink);
        handleMenuClose();
    };

    const onDeleteExternalLink = () => {
        handleDeleteExternalLink(companyExternalLink);
        handleMenuClose();
    };


    return (<>  <ListItem
        secondaryAction={
            <Box>
                <IconButton aria-label="edit" onClick={() => handleExternalLinkVisibility(companyExternalLink)}>
                    {companyExternalLink.status == "ACT" ? <RemoveRedEyeIcon sx={{ color: AppColors.primaryColor }} /> : <VisibilityOffIcon sx={{ color: AppColors.primaryColor }} />}
                </IconButton>
                <IconButton
                    aria-label='more'
                    id='long-button'
                    aria-controls={
                        menuOpen
                            ? 'long-menu'
                            : undefined
                    }
                    aria-expanded={
                        menuOpen
                            ? 'true'
                            : undefined
                    }
                    aria-haspopup='true'
                    onClick={(event) => handleMenuClick(event)}
                >
                    <MoreVertIcon sx={{ color: AppColors.blackColor }} />
                </IconButton>
                <Menu
                    id='long-menu'
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={handleMenuClose}
                    PaperProps={{
                        style: {
                            maxHeight: 48 * 4.5,
                            width: '20ch',
                        },
                    }}
                >
                    <MenuItem
                        key={`edit-media-`}
                        onClick={() => onEditExternalLink()}
                    >
                        Edit
                    </MenuItem>
                    <MenuItem
                        key={`delete-media`}
                        onClick={() => onDeleteExternalLink()}
                    >
                        Delete
                    </MenuItem>
                </Menu>
            </Box>
        }
    >
        <ListItemAvatar>
            <ExternalLinkButtonIcon companyExternalLink={companyExternalLink} />
        </ListItemAvatar>
        <ListItemText
            primary={companyExternalLink.title}
            sx={{ fontWeight: '300 !important' }}
        />
    </ListItem>
        <ListItem sx={{
            background: AppColors.grayZeroColor,
            borderColor: `2px solid ${AppColors.strokeColor} !important`,
            borderRadius: "12px"
        }}>
            <ListItemAvatar sx={{ minWidth: '35px' }}>
                <Box component={'img'} src={ExternalLinkIcon} />
            </ListItemAvatar>
            <Typography
                sx={{ fontWeight: '300', fontSize: '0.9rem', pr: 1, pt: 1, pb: 1 }}
            >{companyExternalLink.link}</Typography>
        </ListItem>

        <ListItem sx={{
            background: AppColors.grayZeroColor,
            borderColor: `2px solid ${AppColors.strokeColor} !important`,
            mt: 2,
            borderRadius: "12px"
        }}>
            <Typography
                sx={{ fontWeight: '300', fontSize: '0.9rem', p: 1 }}
            >{companyExternalLink.description}</Typography>
        </ListItem></>);

}

export default ExternalLinkItem;