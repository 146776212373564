import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styles from "../../styles/EngageItem.module.css";
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import moment from 'moment';
import { VideoViewsReportItem } from '../../store/reports/api/interface';
import { isNotEmpty } from '../../utils/valid';
import { AppColors } from '../../utils/utils';





interface IProps {
    videoViewsReportItem: VideoViewsReportItem;
    index: number;
}

const MediaPostVideoViewReportItemView: React.FC<IProps> = ({
    videoViewsReportItem,
    index
}) => {



    const getMediaImage = () => {
        return (
            (isNotEmpty(videoViewsReportItem.content)
                ? isNotEmpty(videoViewsReportItem?.content?.thumbnail) &&
                    !videoViewsReportItem?.content?.thumbnail?.endsWith('/')
                    ? videoViewsReportItem?.content?.thumbnail
                    : (videoViewsReportItem.content?.link ?? '').length > 15
                        ? videoViewsReportItem.content?.link
                        : '/static/images/placeholder.png'
                : '/static/images/placeholder.png') ?? '/static/images/placeholder.png'
        );
    };

    return (
        <div className='w-100'>

            <Card
                key={index}
                sx={{
                    width: '100%',
                    background: AppColors.grayZeroColor,
                    mb: 3,
                    pr: 1,
                    padding: '18px'
                }}
            >
                <CardContent
                    sx={{
                        padding: '0px 0px 0px 0px!important'
                    }}
                >
                    <Stack direction={'row'}>
                        <Box
                            sx={{
                                display: "flex",
                                //alignItems: "center",
                            }}
                        >
                            <Box component={'img'} alt='pic' src={getMediaImage()} className={styles.knoSmilie} sx={{
                                height: "35px",
                                borderRadius: "30px",
                                width: "35px",
                                objectFit: "cover",
                                mr: 1
                            }} />

                        </Box>


                        <Box flexDirection={'column'} sx={{
                            width: "100%",
                            ml: 1,
                            mr: 1
                        }}>
                            <Typography sx={{ color: AppColors.blackColor }}>{videoViewsReportItem?.content?.title ?? "KnoCard"} &nbsp;&nbsp; {videoViewsReportItem.total} Views</Typography>



                            <Stack sx={{
                            }} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                <Typography sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "0.8rem",
                                    pr: '5px',
                                    color: AppColors.grayTwoColor
                                }}>Last watch at {moment(`${videoViewsReportItem.created_at}`).local().format("DD MMM, YYYY hh:mm A")} </Typography>
                                <Typography sx={{ color: AppColors.grayTwoColor, fontSize: "0.8rem", ml: 1 }}>{videoViewsReportItem.watch_time ?? ""}</Typography>
                            </Stack>
                        </Box>
                    </Stack>

                </CardContent>
            </Card>
        </div>
    );
};

export default MediaPostVideoViewReportItemView;
