import { Container } from '@mui/material';

import { ContentStyle } from '../components/ContentStyle';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { INotFoundParams } from '../enum';
import Loader from '../components/Loader';
import { removeItem } from '../utils/localStorage';
import { config } from '../utils/utils';

const HandleAffiliateLink = () => {
  const params = useParams<INotFoundParams>();


  useEffect(() => {
    removeItem(config.SLECTED_PLAN_KEY);
    const _username = params?.username;

    // window.location.href = `/register/${_username}`;
    window.location.href = `/subscription/${_username}`;

  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <Container maxWidth='sm'>
        <ContentStyle>
          <Loader />
        </ContentStyle>
      </Container>
    </div>
  );
};

export default HandleAffiliateLink;
