import React, { FC, useEffect, useState } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import styles from '../../styles/Reports.module.css';
import {
  WebAppShareItem as WebAppShareItemModel,
} from '../../store/reports/api/interface';
import { dateFormate } from '../../utils/format';
import { useAppSelector } from '../../store';
import { AppColors } from '../../utils/utils';


interface IProps {
  webAppShareItemModel: WebAppShareItemModel;
  index: number;
}

const WebAppShareItem: React.FC<IProps> = ({
  webAppShareItemModel,
  index
}) => {

  return (
    <Box style={{ width: '100%' }}>
      <Box
        className={styles.itemContainer}
        sx={{ background: AppColors.grayZeroColor, border: `solid 1px ${AppColors.strokeColor}`, mt: 0.6, borderRadius: "6px" }}
      >

        <img
          alt='pic'
          src={webAppShareItemModel?.share_to_profile?.profile_picture ?? webAppShareItemModel?.share_to_profile?.user?.profile_picture ?? '/static/images/placeholder_profile.png'}
          className={styles.knoSmilie}
        />
        <Box className={styles.itemContainerContent} sx={{ ml: 1 }} >
          <Typography sx={{ fontSize: "1rem", fontWeight: 400 }}>
            {webAppShareItemModel.share_to_profile?.first_name ?? ''}{' '}
            {webAppShareItemModel.share_to_profile?.last_name ?? ''}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", color: AppColors.grayTwoColor }}>
            {`${webAppShareItemModel.total ?? 0} Shares`}
          </Typography>
        </Box>
        <Box className={styles.itemContainerContent} alignItems={'end'} sx={{ pl: 1 }}>
          <Typography sx={{ fontSize: "0.8rem", color: AppColors.blackColor }}>
            Last Shared
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", color: AppColors.grayTwoColor }}>
            {`${webAppShareItemModel.created_at && dateFormate(webAppShareItemModel.created_at)} `}
          </Typography>
        </Box>
      </Box>

    </Box>
  );
};

export default WebAppShareItem;
