import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import styles from '../styles/Reports.module.css';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../store';
import { checkIsProUser } from '../utils/checkPro';
import NewProspects from '../components/Reports/NewProspects';
import PageViews from '../components/Reports/PageViews';
import WebAppShare from '../components/Reports/WebAppShare';
import Referrals from '../components/Reports/Referrals';
import ShareSocialPosts from '../components/Reports/ShareSocialPosts';
import MediaShares from '../components/Reports/MediaShares';
import VideoViews from '../components/Reports/VideoViews';
import AffiliateLandingPage from '../components/Reports/AffiliateLandingPage';
import { useNavigate } from 'react-router-dom';
import { activityCards } from '../constant';
import { UserInfo } from '../store/userInfo/api/interface';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { formatReportCount } from '../utils/utilMethods';
import { timeFilterList } from '../utils/daysConvertor';
import { AppColors } from '../utils/utils';
import { ReportCountItem } from './Reports/ReportContItem';

interface IProps {
  selectedReportUser: UserInfo | null | undefined;
  setShowReportTab: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardTab: React.FC<IProps> = ({
  selectedReportUser,
  setShowReportTab,
}) => {
  const { user } = useAppSelector((state) => state?.auth);
  const isProUser = checkIsProUser(user);
  const navigate = useNavigate();
  const [activeBtn, setActiveBtn] = useState('lead');
  const [selectedTimeFilter, setSelectedTimeFilter] = useState('Month');

  const { allReportSummary } = useAppSelector((state) => state?.reports);
  const reportDetailRef = useRef<null | HTMLDivElement>(null);

  const handleChangeTimeFilter = (event: SelectChangeEvent) => {
    setSelectedTimeFilter(event.target.value as string);
  };

  const handleOpenSubscription = () => {
    navigate('/subscription');
  };

  useEffect(() => {
    scrollToReportDetail();
  }, [activeBtn]);

  const scrollToReportDetail = () => {
    reportDetailRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getReportSummaryCount = (title: string, index: number): { count: number, previousCount: number } => {
    if (title === 'New Prospects') {
      return getReportLeadData(index);
    }

    if (title === 'Page Views') {
      return getReportPageViewData(index);
    }

    if (title === 'Video Views') {
      return getReportMediaVideoViewData(index);
    }

    if (title === 'Media Shares') {
      return getReportMediaShareData(index);
    }

    if (title === 'Personal Shares') {
      return getWebAppShareData(index);
    }

    if (title === 'Social Posts') {
      return getShareSocialPostsData(index);
    }

    if (title === 'Referrals') {
      return getReferralsData(index);
    }

    if (title === 'Landing Page') {
      return getLandingPageData(index);
    }

    return { count: 0, previousCount: 0 };
  };

  const getReportLeadData = (index: number): { count: number, previousCount: number } => {

    switch (index) {
      case 0:
        return { count: allReportSummary?.contactToday || 0, previousCount: allReportSummary?.contactYesterday || 0 };
      case 1:
        return { count: allReportSummary?.contactWeek || 0, previousCount: allReportSummary?.contactPreviousWeek || 0 };
      case 2:
        return { count: allReportSummary?.contactMonth || 0, previousCount: allReportSummary?.contactPreviousMonth || 0 };
      case 3:
        return { count: allReportSummary?.contactAllTime || 0, previousCount: 0 };
    }
    return { count: 0, previousCount: 0 };
  };

  const getReportPageViewData = (index: number): { count: number, previousCount: number } => {
    switch (index) {
      case 0:
        return { count: allReportSummary?.viewsToday || 0, previousCount: allReportSummary?.viewsYesterday || 0 };
      case 1:
        return { count: allReportSummary?.viewsWeek || 0, previousCount: allReportSummary?.viewsPreviousWeek || 0 };
      case 2:
        return { count: allReportSummary?.viewsMonth || 0, previousCount: allReportSummary?.viewsPreviousMonth || 0 };
      case 3:
        return { count: allReportSummary?.viewsAllTime || 0, previousCount: 0 };
    }
    return { count: 0, previousCount: 0 };
  };

  const getReportMediaShareData = (index: number): { count: number, previousCount: number } => {
    switch (index) {
      case 0:
        return { count: allReportSummary?.shareMediaToday || 0, previousCount: allReportSummary?.shareMediaYesterday || 0 };
      case 1:
        return { count: allReportSummary?.shareMediaWeek || 0, previousCount: allReportSummary?.shareMediaPreviousWeek || 0 };
      case 2:
        return { count: allReportSummary?.shareMediaMonth || 0, previousCount: allReportSummary?.shareMediaPreviousMonth || 0 };
      case 3:
        return { count: allReportSummary?.shareMediaAllTime || 0, previousCount: 0 };
    }
    return { count: 0, previousCount: 0 };
  };

  const getReportMediaVideoViewData = (index: number): { count: number, previousCount: number } => {
    switch (index) {
      case 0:
        return { count: allReportSummary?.viewVideoMediaPostToday || 0, previousCount: allReportSummary?.viewVideoMediaPostYesterday || 0 };
      case 1:
        return { count: allReportSummary?.viewVideoMediaPostWeek || 0, previousCount: allReportSummary?.viewVideoMediaPostPreviousWeek || 0 };
      case 2:
        return { count: allReportSummary?.viewVideoMediaPostMonth || 0, previousCount: allReportSummary?.viewVideoMediaPostPreviousMonth || 0 };
      case 3:
        return { count: allReportSummary?.viewVideoMediaPostAllTime || 0, previousCount: 0 };
    }
    return { count: 0, previousCount: 0 };
  };

  const getWebAppShareData = (index: number): { count: number, previousCount: number } => {
    switch (index) {
      case 0:
        return { count: allReportSummary?.webAppShareToday || 0, previousCount: allReportSummary?.webAppShareYesterday || 0 };
      case 1:
        return { count: allReportSummary?.webAppShareWeek || 0, previousCount: allReportSummary?.webAppSharePreviousWeek || 0 };
      case 2:
        return { count: allReportSummary?.webAppShareMonth || 0, previousCount: allReportSummary?.webAppSharePreviousMonth || 0 };
      case 3:
        return { count: allReportSummary?.webAppShareAllTime || 0, previousCount: 0 };
    }
    return { count: 0, previousCount: 0 };
  };

  const getReferralsData = (index: number): { count: number, previousCount: number } => {
    switch (index) {
      case 0:
        return { count: allReportSummary?.referralsToday || 0, previousCount: allReportSummary?.referralsYesterday || 0 };
      case 1:
        return { count: allReportSummary?.referralsWeek || 0, previousCount: allReportSummary?.referralsPreviousWeek || 0 };
      case 2:
        return { count: allReportSummary?.referralsMonth || 0, previousCount: allReportSummary?.referralsPreviousMonth || 0 };
      case 3:
        return { count: allReportSummary?.referralsAllTime || 0, previousCount: 0 };
    }
    return { count: 0, previousCount: 0 };
  };

  const getLandingPageData = (index: number): { count: number, previousCount: number } => {
    switch (index) {
      case 0:
        return { count: allReportSummary?.shareAffiliateLandingPageToday || 0, previousCount: allReportSummary?.shareAffiliateLandingPageYesterday || 0 };
      case 1:
        return { count: allReportSummary?.shareAffiliateLandingPageWeek || 0, previousCount: allReportSummary?.shareAffiliateLandingPagePreviousWeek || 0 };
      case 2:
        return { count: allReportSummary?.shareAffiliateLandingPageMonth || 0, previousCount: allReportSummary?.shareAffiliateLandingPagePreviousMonth || 0 };
      case 3:
        return { count: allReportSummary?.shareAffiliateLandingPageAllTime || 0, previousCount: 0 };
    }
    return { count: 0, previousCount: 0 };
  };

  const getShareSocialPostsData = (index: number): { count: number, previousCount: number } => {
    switch (index) {
      case 0:
        return { count: allReportSummary?.shareSocialPostsToday || 0, previousCount: allReportSummary?.shareSocialPostsYesterday || 0 };
      case 1:
        return { count: allReportSummary?.shareSocialPostsWeek || 0, previousCount: allReportSummary?.shareSocialPostsPreviousWeek || 0 };
      case 2:
        return { count: allReportSummary?.shareSocialPostsMonth || 0, previousCount: allReportSummary?.shareSocialPostsPreviousMonth || 0 };
      case 3:
        return { count: allReportSummary?.shareSocialPostsAllTime || 0, previousCount: 0 };
    }
    return { count: 0, previousCount: 0 };
  };

  return (
    <Container maxWidth='xl'>
      <Box
        className={styles.mainContent}
        marginTop={5}
      >

        <Box className={styles.mainContentHeader}>

          <Box
            sx={{
              width: '100%',
              background:
                `linear-gradient(0deg, ${AppColors.primaryColor} 0%, ${AppColors.secondryColor} 100%)`,
              p: 2,
              borderRadius: "10px"
            }}
          >
            <Stack
              direction={'row'}
              spacing={2}
              alignContent={'center'}
              alignItems={'center'}
            >
              <IconButton
                onClick={() => setShowReportTab(false)}
              >
                <ArrowBackIcon sx={{ color: AppColors.whiteColor }} />
              </IconButton>
              <Avatar
                src={
                  selectedReportUser?.profile_picture ??
                  '/static/images/placeholder_profile.png'
                }
                sx={{ height: '40px', width: '40px' }}
              />
              <Box
              >
                <Typography sx={{ color: AppColors.whiteColor, fontWeight: "500", fontSize: "1.3rem" }}
                >{selectedReportUser?.first_name ?? ''}{' '}
                  {selectedReportUser?.last_name ?? ''}</Typography>
              </Box>
            </Stack>
          </Box>
        </Box>


        <Box className={styles.mainContentBody}>
          <Box className={styles.activityContainer}>
            <Box className={styles.activityContainerHeader}>
              <Typography
                sx={{
                  fontWeight: '500',
                  color: AppColors.blackColor,
                  fontSize: '1.3rem',
                  flexGrow: 1
                }}
              >
                View Activity
              </Typography>

              <Box  >

                <Select
                  id='time-filter-select'
                  value={selectedTimeFilter}
                  onChange={handleChangeTimeFilter}
                  IconComponent={() => (
                    <Box
                      component={'img'}
                      src='/static/images/arrow_down.svg'
                      alt='arrow_down'
                      className={styles.arrowDown}
                    />
                  )}
                  // sx={{
                  //   boxShadow: 'none',
                  //   '.MuiOutlinedInput-notchedOutline': {
                  //     border: 0,
                  //   },
                  // }}
                  className={styles.activityFilterDropDown}
                  sx={{ backgroundColor: AppColors.whiteColor }}
                >
                  {timeFilterList.map((val: any) => {
                    return (
                      <MenuItem value={val}>
                        <Box className={styles.activityFilterDropDownText}>
                          {val}
                        </Box>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Box>
            <Box className={styles.activityCardsGrid} sx={{ backgroundColor: AppColors.whiteColor, p: 2, borderRadius: '10px', }}>
              {activityCards?.length &&
                activityCards.map((card) => {
                  let reportData = getReportSummaryCount(card.title, timeFilterList.indexOf(selectedTimeFilter));
                  return (<Box onClick={() => (isProUser) ? setActiveBtn(card.code) : handleOpenSubscription()}>
                    <ReportCountItem title={card.title} previousReport={reportData.previousCount || 0} currentReport={(reportData.count || 0)} isProUser={isProUser} reportType={selectedTimeFilter} />
                  </Box>);
                })}
            </Box>
          </Box>

          <Box
            className={styles.resultscontainer}
            sx={{ gap: 0 }}
            ref={reportDetailRef}
          >
            {activeBtn === 'lead' && (
              <NewProspects
                selectedTimeFilter={selectedTimeFilter}
                isFromKnoCardLink={true}
                selectedReportUser={selectedReportUser?.id}
              />
            )}

            {activeBtn === 'knocard' && (
              <PageViews
                selectedTimeFilter={selectedTimeFilter}
                selectedReportUser={selectedReportUser?.id}
              />
            )}

            {activeBtn === 'webAppShare' && (
              <WebAppShare
                selectedTimeFilter={selectedTimeFilter}
                isFromKnoCardLink={true}
                selectedReportUser={selectedReportUser?.id}
              />
            )}

            {activeBtn === 'referrals' && (
              <Referrals
                selectedTimeFilter={selectedTimeFilter}
                isFromKnoCardLink={true}
                selectedReportUser={selectedReportUser?.id}
              />
            )}

            {activeBtn === 'shareSocialPosts' && (
              <ShareSocialPosts
                selectedTimeFilter={selectedTimeFilter}
                isFromKnoCardLink={true}
                selectedReportUser={selectedReportUser?.id}
              />
            )}

            {activeBtn === 'mediaVideoViewCount' && (
              <VideoViews
                selectedTimeFilter={selectedTimeFilter}
                selectedReportUser={selectedReportUser?.id}
              />
            )}

            {activeBtn === 'mediaShare' && (
              <MediaShares
                selectedTimeFilter={selectedTimeFilter}
                isFromKnoCardLink={true}
                selectedReportUser={selectedReportUser?.id}
              />
            )}

            {activeBtn === 'group_landing_page' && (
              <AffiliateLandingPage
                selectedTimeFilter={selectedTimeFilter}
                isFromKnoCardLink={true}
                selectedReportUser={selectedReportUser?.id}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default DashboardTab;
