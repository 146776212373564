import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import {
  ContactRequest,
  ContactResponse,
} from './interface';
import { toastHandler } from '../../../utils/toastHandler';

export const getContacts = createAsyncThunk(
  'contacts/getContacts',
  async (data: ContactRequest, { rejectWithValue }) => {
    try {

      const response = await axiosInstance.post<ContactResponse>(`api/contact/contacts?page=${data.page}`, { 'user_id': data.userId });
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);
