import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogContentText, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/system";
import { Transition } from "../Transition";
import { useAppDispatch } from "../../store";
import React from "react";
import { updateNewToKnoCard } from "../../store/auth/api";
import { AppColors } from "../../utils/utils";
import CancelIcon from '@mui/icons-material/Cancel';

interface IProps {
    toggle: boolean;
    onClose: () => void;
}



const NewToKnoCardModal: React.FC<IProps> = ({ toggle, onClose }) => {

    const dispatch = useAppDispatch();
    const onCancel = () => {

        onClose();
    };

    const handleDoNotShowAgain = () => {
        dispatch(updateNewToKnoCard({ new_to_knocard: false }));
        onCancel();
    };


    return (
        <>
            <Dialog
                fullWidth
                open={toggle}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    onCancel();
                }}
                aria-describedby="alert-dialog-slide-description"
                sx={{
                    mt: { md: "5%", sm: "25%", xs: "25%" },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <DialogContent sx={{ padding: '20px', background: AppColors.backgroundColor }}>
                    <DialogTitle
                        sx={{
                            p: 0,
                            pb: 0.5,
                            color: AppColors.blackColor
                        }}
                    >
                        New to KnoCard?
                    </DialogTitle>
                    <IconButton
                        aria-label='close'
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: '6px',
                            zIndex: 100000,
                            color: AppColors.grayThreeColor,
                            alignSelf: 'center',
                            alignItems: 'center',
                        }}

                    >
                        <CancelIcon sx={{ color: AppColors.grayTwoColor }} />
                    </IconButton>
                    <DialogContentText sx={{ color: AppColors.grayThreeColor }}>Check out our quick setup tips & trainings in our How To section.</DialogContentText>


                    <Box
                        sx={{
                            pt: 2,
                            textAlign: 'end'
                        }}
                    >

                        <Button
                            variant={'text'}
                            onClick={handleDoNotShowAgain}
                            sx={{
                                pl: 1,
                                pr: 1,
                                fontSize: "0.9rem",
                                textTransform: 'unset',

                            }}
                        >
                            Don't Show Again
                        </Button>
                    </Box>

                </DialogContent>
            </Dialog>
        </>
    );
};

export default NewToKnoCardModal;
