import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { TutorialVideoResponse } from './interface';

export const getTutorialVideos = createAsyncThunk(
  'tutorialVideo/getTutorialVideos',
  async (_,
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<TutorialVideoResponse>(
        `/api/tutorial-videos`

      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


