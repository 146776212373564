import React from 'react';
import Linkify from 'react-linkify';


interface IProps {
    charLimit: number,
    ellipsis: string,
    readMoreText: string,
    readLessText: string,
    readMoreClassName: string,
    readLessClassName: string,
    readMoreStyle: object,
    readLessStyle: object,
    children: string
};

const ReactReadMoreReadLess = (props: IProps) => {

    const [showMore, setShowMore] = React.useState(false);



    const { children, ellipsis, readMoreText, readLessText, readMoreClassName, readLessClassName, readMoreStyle, readLessStyle, charLimit } = props;

    const shortText = children.substr(0, charLimit).replace(/[\s\n]+$/, '').replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]+$/, "") + (charLimit >= children.length ? '' : ellipsis);

    function ReadMore() {
        return ((charLimit >= children.length) || !readMoreText) ? null :
            <span
                className={readMoreClassName}
                role="presentation"
                style={readMoreStyle}
                onClick={() => { setShowMore(true) }} >
                {readMoreText}
            </span>
    }

    function ReadLess() {
        return (((charLimit >= children.length) || !readLessText) ? null : <span className={readLessClassName} role="presentation" style={readLessStyle} onClick={() => { setShowMore(false) }}> {readLessText} </span>);
    }
    return (
        <React.Fragment>{showMore ? <Linkify>{children}</Linkify> : <Linkify>{shortText}</Linkify>} {showMore ? ReadLess() : ReadMore()} </React.Fragment>
    );

}


ReactReadMoreReadLess.defaultProps = {
    charLimit: 150,
    ellipsis: '…',
    readMoreText: 'Read more',
    readLessText: 'Read less',
    readMoreClassName: 'react-read-more-read-less react-read-more-read-less-more',
    readLessClassName: 'react-read-more-read-less react-read-more-read-less-less',
    readMoreStyle: { whiteSpace: "nowrap", cursor: "pointer" },
    readLessStyle: { whiteSpace: "nowrap", cursor: "pointer" }
};
export default ReactReadMoreReadLess;