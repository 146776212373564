import { createSlice } from '@reduxjs/toolkit';
import { uploadMediaCloudFlare } from './api';
import { initialState } from './api/interface';

const mediaUploadSlice = createSlice({
  name: 'mediaUpload',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload.status;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(uploadMediaCloudFlare.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(uploadMediaCloudFlare.fulfilled, (state, action) => {
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(uploadMediaCloudFlare.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { setStatus, reset } = mediaUploadSlice.actions;
export default mediaUploadSlice.reducer;
