import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserInfo } from './api';
import { getUserInfoRequestResponse, initialState, UserInfo } from './api/interface';

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<getUserInfoRequestResponse>) => {
      state.data = action.payload.data.user[0];
    },
    setSc: (state, action: PayloadAction<{ sc: string }>) => {
      state.sc = action.payload.sc;
    },
    setSbAffiliateLink: (
      state,
      action: PayloadAction<{ sbUser?: UserInfo }>
    ) => {
      state.sbUser = action.payload.sbUser ?? null;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getUserInfo.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset, setUserInfo, setSc, setSbAffiliateLink } =
  userInfoSlice.actions;
export default userInfoSlice.reducer;
