import { createAsyncThunk } from '@reduxjs/toolkit';
import { toastHandler } from '../../../utils/toastHandler';
import { axiosInstance } from '../../../client';
import { UploadMediaRequest } from './interface';

export const uploadMediaCloudFlare = createAsyncThunk(
  'mediaUpload/uploadMediaCloudFlare',
  async (data: UploadMediaRequest, { rejectWithValue }) => {
    try {
      const request = new FormData();
      request.append('resource_type', data.resourceType);

      if (data.image) {
        request.append('image', data.image);
      }
      if (data.video) {
        request.append('video', data.video);
      }
      if (data.thumbnail) {
        request.append('thumbnail', data.thumbnail);
      }

      if (data.resourceId) {
        request.append('resource_id', data.resourceId.toString());
      }
      if (data.knocardPosition) {
        request.append('knocard_position', data.knocardPosition.toString());
      }
      if (data.knocardType) {
        request.append('knocard_type', data.knocardType.toString());
      }

      const response = await axiosInstance.post('/api/manage/file', request, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response;
    } catch (error: any) {

      // toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);
