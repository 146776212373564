import { STATUS_TYPE } from '../../../enum';
import { Content, PostObject } from '../../post/api/interface';
import { UserInfo } from '../../userInfo/api/interface';


export interface ReportResponse {
  status?: string;
  data?: ReportResponseItem;
}

export interface ReportResponseItem {
  contactToday: number;
  viewsToday: number;
  shareMediaToday: number;
  viewVideoMediaPostToday: number;
  webAppShareToday: number;
  referralsToday: number;
  shareSocialPostsToday: number;
  landingPageVideoToday: number;

  contactWeek: number;
  viewsWeek: number;
  shareMediaWeek: number;
  viewVideoMediaPostWeek: number;
  webAppShareWeek: number;
  referralsWeek: number;
  shareSocialPostsWeek: number;
  landingPageVideoWeek: number;

  contactMonth: number;
  viewsMonth: number;
  shareMediaMonth: number;
  viewVideoMediaPostMonth: number;
  webAppShareMonth: number;
  referralsMonth: number;
  shareSocialPostsMonth: number;
  landingPageVideoMonth: number;

  contactAllTime: number;
  viewsAllTime: number;
  shareMediaAllTime: number;
  viewVideoMediaPostAllTime: number;
  webAppShareAllTime: number;
  referralsAllTime: number;
  shareSocialPostsAllTime: number;
  landingPageVideoAllTime: number;



  contactPreviousMonth: number;
  contactPreviousWeek: number;
  contactYesterday: number;
  knoCardShareAllTime: number;
  knoCardShareMonth: number;
  knoCardSharePreviousMonth: number;
  knoCardSharePreviousWeek: number;
  knoCardShareToday: number;
  knoCardShareWeek: number;
  knoCardShareYesterday: number;
  landingPageVideoPreviousMonth: number;
  landingPageVideoPreviousWeek: number;
  landingPageVideoYesterday: number;
  referralsPreviousMonth: number;
  referralsPreviousWeek: number;
  referralsYesterday: number;
  shareAffiliateLandingPageAllTime: number;
  shareAffiliateLandingPageMonth: number;
  shareAffiliateLandingPagePreviousMonth: number;
  shareAffiliateLandingPagePreviousWeek: number;
  shareAffiliateLandingPageToday: number;
  shareAffiliateLandingPageWeek: number;
  shareAffiliateLandingPageYesterday: number;
  shareMediaPreviousMonth: number;
  shareMediaPreviousWeek: number;
  shareMediaYesterday: number;
  shareSocialPostsPreviousMonth: number;
  shareSocialPostsPreviousWeek: number;
  shareSocialPostsYesterday: number;
  viewVideoMediaPostPreviousMonth: number;
  viewVideoMediaPostPreviousWeek: number;
  viewVideoMediaPostYesterday: number;
  viewsPreviousMonth: number;
  viewsPreviousWeek: number;
  viewsYesterday: number;
  webAppSharePreviousMonth: number;
  webAppSharePreviousWeek: number;
  webAppShareYesterday: number;


}

export interface GetLeadViewRequest {
  user_id?: number;
  timeline?: string;
  api_token?: string;
  days?: number;
  isFromKnoCardLink?: boolean;
}

export interface LeadView {
  status: string;
  leadViewData: LeadView[];
}

export interface LeadView {
  id: number;
  user_id: number;
  contact_user_id?: any;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  email?: string;
  where_i_met_you?: string;
  occupation?: any;
  notes?: any;
  contact_code?: string;
  created_at?: string;
  updated_at?: string;
  is_share?: number;
  is_favorite?: number;
  is_saved_using_app?: string;
  user?: UserInfo;
}

export interface GetKnoCardViewRequest {
  user_id?: number;
  timeline?: string;
  api_token?: string;
  days?: number;
}

export interface KnoCardView {
  status: string;
  knoCardViewData: KnoCardView[];
}

export interface KnoCardView {
  id?: number;
  module?: string;
  sub_module?: string;
  action_type?: string;
  source_type?: string;
  identifiable_id?: string;
  identifiable_type?: string;
  viewable_id?: number;
  viewable_type?: string;
  target_id?: number;
  sort_marker?: string;
  created_at?: string;
  updated_at?: string;
  total?: 77;
  createdAt?: string;
  viewable?: UserInfo;
}

export interface GetKnoCardViewCountRequest {
  user_id?: number;
  api_token?: string;
  sort_marker?: string;
  days?: number;
}

export interface KnoCardViewCount {
  module: string;
  id: number;
  total: number;
  created_at: string;
}

export interface GetWebAppShareRequest {
  user_id?: number;
  timeline?: string;
  api_token?: string;
  days?: number;
  isFromKnoCardLink?: boolean;
}

export interface WebAppShare {
  data: WebAppShareItem[];
  current_page: number;
  last_page: number;
}

export interface WebAppShareItem {
  action_type?: string;
  created_at?: string;
  id?: number;
  targetable_id?: number;
  targetable_type?: string;
  total?: number;
  updated_at?: string;
  user_id?: number;
  share_to_profile?: WebAppShareToProfile;
}

export interface WebAppShareToProfile {
  id?: number;
  first_name?: string;
  last_name?: string;
  profile_picture?: string;
  user?: UserInfo;
}

export interface GetReferralsRequest {
  user_id?: number;
  timeline?: string;
  api_token?: string;
  days?: number;
  isFromKnoCardLink?: boolean;
}

export interface Referrals {
  data: ReferralsItem[];
  current_page: number;
  last_page: number;
}

export interface ReferralsItem {
  action_type?: string;
  created_at?: string;
  id?: number;
  targetable_id?: number;
  targetable_type?: string;
  total?: number;
  updated_at?: string;
  user_id?: number;
  user?: UserInfo;
  share_to_profile?: ReferralsShareToProfile;
}

export interface ReferralsShareToProfile {
  first_name?: string;
  last_name?: string;
}

export interface GetShareSocialPostsRequest {
  user_id?: number;
  timeline?: string;
  api_token?: string;
  days?: number;
  isFromKnoCardLink?: boolean;
}

export interface ShareSocialPost {
  data: ShareSocialPostItem[];
  current_page: number;
  last_page: number;
}


export interface ShareSocialPostItem {
  action_type?: string;
  company_feed?: ShareSocialPostCompanyFeed;
  company_feed_id?: number;
  createdAt?: string;
  created_at?: string;
  id?: number;
  postby_id?: number;
  target_id?: number;
  targetable_type?: string;
  total_share_count?: number;
  updated_at?: string;
  user_id?: number;
}


export interface ShareAffiliateLandingPageDataReport {
  data: ShareAffiliateLandingPageItem[];
  current_page: number;
  last_page: number;
}

export interface ShareAffiliateLandingPageItem {
  id: number;
  user_id: number;
  share_type: string;
  action_type: string;
  target_id?: number;
  targetable_type?: string;
  created_at: string;
  updated_at: string;
  user?: UserInfo;
}

export interface AffiliateLandingPageWatchVideoReportRequest {
  module: string;
  days: number;
  sort_marker: string;
  user_id: number;
}

export interface AffiliateLandingPageWatchVideoResponse {
  status?: string;
  message?: string;
  data?: AffiliateLandingPageWatchVideoItem[];
}

export interface AffiliateLandingPageWatchVideoItem {
  id?: number;
  module?: string;
  title?: string;
  target_page?: string;
  target_id?: number;
  viewable_id?: number;
  viewable_type?: string;
  sort_marker?: string;
  updated_at?: string;
  total?: number;
  watch_time?: string;
  created_at?: string;
}


export interface GetShareAffiliateLandingPageReportRequest {
  user_id: number;
  days: number;
  api_token: string;
  isFromKnoCardLink?: boolean;
}

export interface ShareSocialPostCompanyFeed {
  company_id?: number;
  contents?: ShareSocialPostCompanyFeedItem[];
  created_at?: string;
  deep_link?: string;
  description?: string;
  id?: number;
  image?: string;
  title?: string;
  updated_at?: string;
  video?: string;
}

export interface ShareSocialPostCompanyFeedItem {
  company_feed_id?: number;
  created_at?: string;
  description?: string;
  filename?: string;
  id?: number;
  link?: string;
  migrate_s3?: number;
  platform?: string;
  position?: number;
  status?: number;
  thumb_filename?: string;
  thumbnail?: string;
  title?: string;
  type?: string;
  updated_at?: string;
  user_id?: number;
}

export interface GetShareSocialPostsDetailRequest {
  user_id?: number;
  api_token?: string;
  days?: number;
}

export interface ShareSocialPostDetail {
  data: ShareSocialPostDetailItem[];
  current_page: number;
  last_page: number;
}

export interface ShareSocialPostDetailItem {
  action_type?: string;
  company_feed_id?: number;
  created_at?: string;
  id?: number;
  postby_id?: number;
  share_to_feed?: ShareSocialPostDetailItemShareToProfile;
  target_id?: number;
  targetable_type?: string;
  updated_at?: string;
  user?: UserInfo;
  user_id?: number;
}

export interface ShareSocialPostDetailItemShareToProfile {
  first_name?: string;
  last_name?: string;
}
export interface GetMediaSharePostCountRequest {
  user_id: number;
  days: number;
}
export interface GetMediaSharePostReportRequest {
  user_id: number;
  days: number;
  api_token: string;
}

export interface MediaSharePostReportCount {
  totalCommunity: number;
  totalKnocardContact: number;
  totalLink: number;
  totalNewContact: number;
}

export interface MediaShareReport {
  data: MediaShareReportItem[];
  current_page: number;
  last_page: number;
}

export interface MediaShareReportItem {
  id?: number;
  user_id?: number;
  post_id?: number;
  created_at?: string;
  updated_at?: string;
  action_type?: string;
  target_id?: number;
  postby_id?: number;
  targetable_type?: string;
  form_number?: number;
  feedback_form_id?: number;
  form_answer?: any;
  form_code?: string;
  total_share_count?: number;
  createdAt?: string;
  post?: PostObject;
  user?: UserInfo;
  feedback_form?: {
    id?: number;
    user_id?: number;
    answer_one?: string;
    answer_two?: string;
    answer_three?: string;
    answer_four?: string;
    question?: string;
    title?: string;
    created_at?: Date;
    updated_at?: Date;
  }
}

export interface GetMediaSharePostReportDetailRequest {
  user_id: number;
  days: number;
  feedback_answer_filter: string;
}

export interface MediaSharePostReportDetail {
  data: MediaShareReportItem[];
  current_page: number;
  last_page: number;
}

export interface GetMediaVideoCountViewRequest {
  user_id: number;
  days: number;
  timeline: string;
  type: string;
  api_token: string;
}


export interface GetAffiliateLandingPageVideoCountViewRequest {
  user_id: number;
  days: number;
  timeline: string;
  api_token: string;
  isFromKnoCardLink?: boolean;
}

export interface VideoViewsReport {
  status: string;
  videoViewReportData: VideoViewsReportItem[];
}

export interface VideoViewsReportItem {
  id?: number;
  user_id?: number;
  post_id?: number;
  created_at?: string;
  updated_at?: string;
  postby_id?: number;
  viewable_id?: number;
  viewable_type?: string;
  view_type?: string;
  watch_time?: string;
  content_id?: number;
  total?: number;
  viewable?: UserInfo;
  post?: PostObject;
  content?: Content;
}




export interface AffiliateLandingPageVideoViewsReport {
  status: string;
  data: AffiliateLandingPageVideoViewsItem[];
}

export interface AffiliateLandingPageVideoViewsItem {
  id?: number;
  module?: string;
  sub_module?: string;
  action_type?: string;
  source_type?: string;
  identifiable_id?: number;
  identifiable_type?: string;
  viewable_id?: number;
  viewable_type?: string;
  target_id?: number;
  sort_marker?: string;
  created_at?: string;
  updated_at?: string;
  total?: number;
  identifiable?: {
    id?: number;
    title?: string;
    position?: number;
    type?: string;
    link?: string;
    user_id?: number;
    filename?: string;
    thumb_name?: string;
    thumb_link?: string;
    created_at?: string;
    updated_at?: string;
  }
}

export interface GetMediaVideoCountViewDetailRequest {
  user_id: number;
  viewer_id: number;
  days: number;
  timeline: string;
  type: string;
  api_token: string;
}


export interface EngageUserActivityCountResponse {
  status?: string;
  message?: string;
  total_engage_user_activity?: number;
}


export interface AffiliateLandingPageVideoViewDataReport {
  status: string;
  data: AffiliateLandingPageVideoViewItem[];
}

export interface AffiliateLandingPageVideoViewItem {
  action_type?: string;
  created_at?: string;
  id?: number;
  identifiable_id?: number;
  identifiable_type?: string;
  module?: string;
  sort_marker?: string;
  source_type?: string;
  sub_module?: string;
  target_id?: number;
  total?: number;
  updated_at?: string;
  viewable: {
    id?: number;
    profile_picture?: string;
    first_name?: string;
    last_name?: string;
    user?: {
      id?: number;
      profile_picture?: string;
      first_name?: string;
      last_name?: string;
      name?: string;
    }
  }
  viewable_id?: number;
  viewable_type?: string;
}


export interface GetAffiliateLandingPageVideoDetailRequest {
  user_id: number;
  sort_marker: string;
  api_token: string;
}



export interface GetMediaVideoCounterDetailRequest {
  user_id: number;
  viewer_id: number;
  post_id: number;
  days: number;
  timeline: string;
  type: string;
  api_token: string;
}

interface InitialState {
  allReportSummary?: ReportResponseItem;
  leadViewData: LeadView | null;
  knoCardViewData: KnoCardView | null;
  webAppShareData: WebAppShare | null;
  referralsData: Referrals | null;
  shareSocialPostData: ShareSocialPost | null;
  mediaShareReportData: MediaShareReport | null;
  videoViewReportData: VideoViewsReport | null;
  affiliateLandingPageVideoViewData: AffiliateLandingPageVideoViewDataReport | null;
  shareAffiliateLandingPageData: ShareAffiliateLandingPageDataReport | null;
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
  totalEngageUserActivity: number;
  isEngageUserActivityCountLoading: boolean;
}

export const initialState: InitialState = {
  allReportSummary: undefined,
  leadViewData: null,
  knoCardViewData: null,
  webAppShareData: null,
  referralsData: null,
  shareSocialPostData: null,
  mediaShareReportData: null,
  videoViewReportData: null,
  affiliateLandingPageVideoViewData: null,
  shareAffiliateLandingPageData: null,
  totalEngageUserActivity: 0,
  status: 'idle',
  isError: false,
  isLoading: false,
  isSuccess: false,
  isEngageUserActivityCountLoading: false,
  errorMessage: '',
};
