import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button, { ButtonProps } from '@mui/material/Button';
import { Box, useMediaQuery } from '@mui/material';
import Nav from '../assets/blue_smile.png';
import { RiStarSFill } from 'react-icons/ri';
import { AppColors, config } from '../utils/utils';
import styled from '@emotion/styled';
import useToggle from '../hooks/useToggle';
import GroupNetworkModal from './GroupNetworkModal';
import { UserInfo } from '../store/userInfo/api/interface';

interface IProps {
  affiliateUserName: string | undefined | null;
  userInfo: UserInfo | undefined | null;
  getKnoCardLink?: string | undefined;
}

const OpenButton = styled(Button)<ButtonProps>(({ theme }) => ({
  background: AppColors.whiteColor,
  borderRadius: '12px',
  fontSize: 'small',
  fontWeight: 'bold',
  '&:hover': {
    background: AppColors.whiteColor,
  }
}));

export const LandingPageNewNavBar: React.FC<IProps> = ({ affiliateUserName, userInfo, getKnoCardLink }) => {

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const matches = useMediaQuery('(max-width:745px)');

  const [affiliateLink, setAffiliateLink] = useState<string>('');

  const {
    toggle: toggleGroup,
    onOpen: onOpenGroup,
    onClose: onCloseGroup,
  } = useToggle();

  useEffect(() => {
    setAffiliateLink(`${config.VBC_URL}/affiliate/${affiliateUserName ?? ''}`);
  }, [affiliateUserName]);

  let handleGetKnoCard = () => {
    if (getKnoCardLink) {
      window.open(getKnoCardLink, '_blank') || window.location.replace(getKnoCardLink);
    } else {
      onOpenGroup();
    }
  };

  const ratingInfo = () => {
    return <><Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Typography variant='caption' sx={{ color: AppColors.whiteColor }}>
        {/*userInfo?.affiliate_group != null ? "Powered by KnoCard" :*/ "Global business referral platform"}
      </Typography>
    </Box>
      <Box sx={{ flexDirection: 'row' }}>
        <RiStarSFill style={{ color: AppColors.yellowColor, height: "20px", width: "20px" }} />
        <RiStarSFill style={{ color: AppColors.yellowColor, height: "20px", width: "20px" }} />
        <RiStarSFill style={{ color: AppColors.yellowColor, height: "20px", width: "20px" }} />
        <RiStarSFill style={{ color: AppColors.yellowColor, height: "20px", width: "20px" }} />
        <RiStarSFill style={{ color: AppColors.yellowColor, height: "20px", width: "20px" }} />
      </Box></>
  }


  return (
    <AppBar position='static' sx={{
      backgroundImage: 'url("/static/images/header_bg.svg")',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }}>
      <Toolbar sx={{ padding: matches ? '5px' : '5px 30px  ' }}>

        <Box
          sx={{
            flexGrow: 1,
            flexDirection: 'column'
          }}
        >
          <Box component={'a'} href={config.KNOCARD_URL} sx={{ zIndex: 1000, mt: 1 }}>
            {' '}
            <img
              src={"/static/images/white_logo.svg"}
              height='30px'
              alt='navbar'
            />
          </Box>
          {isSmallScreen && ratingInfo()}
        </Box>

        <Box textAlign={'end'}>
          <OpenButton onClick={handleGetKnoCard}>Get KnoCard</OpenButton>
          {!isSmallScreen && ratingInfo()}
        </Box>
      </Toolbar>
      <GroupNetworkModal
        onClose={onCloseGroup}
        toggle={toggleGroup}
        affiliateLink={affiliateLink}
        userInfo={userInfo}
      />
    </AppBar>
  );
};
