import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styles from "../../styles/EngageItem.module.css";
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import moment from 'moment';
import { MediaShareReportItem } from '../../store/reports/api/interface';
import { isNotEmpty } from '../../utils/valid';
import { AppColors } from '../../utils/utils';





interface IProps {
    postFeedbackFormReportItem: MediaShareReportItem;
    index: number;
}

const PostFeedbackFormReportItemView: React.FC<IProps> = ({
    postFeedbackFormReportItem,
    index,
}) => {



    const getMediaImage = () => {
        return (
            (isNotEmpty(postFeedbackFormReportItem.post?.contents)
                ? isNotEmpty(postFeedbackFormReportItem.post?.contents[0].thumbnail) &&
                    !postFeedbackFormReportItem.post?.contents[0].thumbnail?.endsWith('/')
                    ? postFeedbackFormReportItem.post?.contents[0].thumbnail
                    : (postFeedbackFormReportItem.post?.contents[0]?.link ?? '').length > 15
                        ? postFeedbackFormReportItem.post?.contents[0].link
                        : '/static/images/placeholder.png'
                : '/static/images/placeholder.png') ?? '/static/images/placeholder.png'
        );
    };

    return (
        <div className='w-100'>

            <Card
                key={index}
                elevation={0}
                sx={{
                    width: '100%',
                    background: AppColors.grayZeroColor,
                    mb: 3,
                    pr: 1,
                    padding: '18px'
                }}

            >
                <CardContent
                    sx={{
                        padding: '0px 0px 0px 0px!important'
                    }}
                >
                    <Stack direction={'row'}>
                        <Box
                            sx={{
                                display: "flex",
                                //alignItems: "center",
                            }}
                        >
                            <Box component={'img'} alt='pic' src={getMediaImage()} className={styles.knoSmilie} sx={{
                                height: "35px",
                                borderRadius: "30px",
                                width: "35px",
                                objectFit: "cover",
                                mr: 1
                            }} />

                        </Box>


                        <Box flexDirection={'column'} sx={{
                            width: "100%",
                            ml: 1,
                            mr: 1
                        }}>
                            <Typography sx={{ color: AppColors.blackColor }}>{postFeedbackFormReportItem.post?.description ?? "KnoCard"}</Typography>

                            <Typography sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "0.8rem",
                                pr: '5px',
                                color: AppColors.grayTwoColor
                            }}>{moment(`${postFeedbackFormReportItem.created_at}`).local().format("DD MMM, YYYY hh:mm A")} </Typography>
                        </Box>
                        <Stack direction={'column'} sx={{
                            width: "100%",
                            ml: 1,
                            mr: 1,
                            alignItems: "end"
                        }}>

                            {postFeedbackFormReportItem?.feedback_form?.question && (<><Typography sx={{ color: AppColors.blackColor, fontSize: "0.6rem" }}>Question</Typography><Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: AppColors.primaryColor,
                                    borderRadius: "5px",
                                    width: 'fit-content',
                                    pt: 0.5,
                                    pb: 0.5
                                }}
                            >
                                <Typography sx={{ color: AppColors.whiteColor, ml: 2, mr: 2, fontSize: "0.8rem" }}>{postFeedbackFormReportItem?.feedback_form?.question ?? ""}</Typography>
                            </Box></>)}
                            {postFeedbackFormReportItem.form_answer && (<><Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: AppColors.secondryColor,
                                    borderRadius: "5px",
                                    width: 'fit-content',
                                    pt: 0.5,
                                    pb: 0.5
                                }}
                            >
                                <Typography sx={{ color: AppColors.blackColor, ml: 2, mr: 2, fontSize: "0.8rem" }}>{postFeedbackFormReportItem.form_answer ?? ""}</Typography>
                            </Box><Typography sx={{ color: AppColors.blackColor, fontSize: "0.6rem" }}>Answer</Typography></>)}

                        </Stack>

                    </Stack>

                </CardContent>
            </Card>
        </div>
    );
};

export default PostFeedbackFormReportItemView;
