import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Avatar, Box, Chip, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { MediaLibraryCategoryItem, MediaLibraryItem } from '../../store/mediaLibrary/api/interface';
import CollectionsIcon from '@mui/icons-material/Collections';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useAppDispatch, useAppSelector } from '../../store';
import { getMediaLibrary, getMediaLibraryCategory } from '../../store/mediaLibrary/api';
import { setMediaLibraryTag } from '../../store/mediaLibrary/mediaLibrarySlice';
import { AppColors } from '../../utils/utils';

interface IProps {
    isExpanded: boolean;
    setExpanded: (isExpand: boolean) => void;
    isShowTitle: boolean;
    onSelectMedia: (mediaLibraryItem: MediaLibraryItem) => void;
}

const MediaLibraryView: React.FC<IProps> = ({ isExpanded, setExpanded, isShowTitle, onSelectMedia }) => {

    const dispatch = useAppDispatch();
    const { mediaLibraryCategoryList, mediaLibraryMediaModelList, isCategoryLoading, isMediaLoading, isLoadMoreCategory, isLoadMoreMedia, mediaPage, categoryPage, mediaTag, mediaLibraryType } = useAppSelector((state) => state?.mediaLibrary);

    const mediaLibraryCategoryDivRef = useRef<HTMLDivElement>(null);

    const observer = useRef<IntersectionObserver>();

    useEffect(() => {

        if (isExpanded) {
            if (mediaLibraryCategoryList.length == 0) {
                dispatch(getMediaLibraryCategory({ page: categoryPage + 1 }));
            }
        }
    }, [isExpanded]);


    useEffect(() => {

        if (isExpanded) {
            if (mediaTag.length == 0 && mediaLibraryCategoryList.length > 0) {
                handleOnTagSelect(mediaLibraryCategoryList[0]);
            }
        }
    }, [mediaLibraryCategoryList]);


    useEffect(() => {

        if (mediaTag.length > 0 || (mediaTag.length > 0 && mediaLibraryType == undefined)) {
            dispatch(getMediaLibrary({ page: 1, tag: mediaTag }));
        }
    }, [mediaTag, mediaLibraryType]);

    const handleOnTagSelect = (mediaLibraryCategoryItem: MediaLibraryCategoryItem) => {
        dispatch(setMediaLibraryTag(mediaLibraryCategoryItem.name ?? ""))
    };


    const handleMediaLibraryCategoryScroll = () => {
        const { current } = mediaLibraryCategoryDivRef;
        if (current) {
            const { scrollLeft, clientWidth, scrollWidth } = current;
            if (scrollLeft + clientWidth >= (scrollWidth - 5) && !isCategoryLoading && isLoadMoreCategory) {
                dispatch(getMediaLibraryCategory({ page: categoryPage + 1 }));
            }
        }
    };

    const mediaLibraryMediaLastElementRef = useCallback(
        // (*)
        (node: HTMLDivElement | null) => {
            if (isMediaLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && isLoadMoreMedia) {
                    dispatch(getMediaLibrary({ page: mediaPage + 1, tag: mediaTag }));
                }
            });
            if (node) observer.current.observe(node);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isMediaLoading, isLoadMoreMedia]
    );


    return (
        <Box component={'div'} sx={{ height: '100%' }}>
            {isShowTitle && <Typography sx={{ textAlign: 'center', mb: 1, color: AppColors.grayThreeColor }} >Need Some medias?</Typography>}

            <Box display="flex" justifyContent="center">
                <Stack flexDirection={'row'} sx={{ border: `2px solid ${AppColors.strokeColor}`, borderRadius: '10px', width: 'fit-content', pl: 2, pr: 2, pt: 1, pb: 1, cursor: 'pointer' }} onClick={() => setExpanded(!isExpanded)}>
                    <CollectionsIcon sx={{ transform: 'scaleX(-1)', color: AppColors.primaryColor }} />
                    <Typography sx={{ ml: 2, mr: 2, color: AppColors.primaryColor }}> Media Library</Typography>
                    {isExpanded ? (<ExpandLessIcon sx={{ color: AppColors.primaryColor }} />) : (<ExpandMoreIcon sx={{ color: AppColors.primaryColor }} />)}
                </Stack>
            </Box>
            {isExpanded && <Box sx={{ background: "#ffffff", mt: 2, minHeight: "200px", }}>
                <Stack
                    ref={mediaLibraryCategoryDivRef}
                    flexDirection={'row'} sx={{
                        overflowX: 'scroll', '&::-webkit-scrollbar': {
                            width: '0.2em', // Width of the scrollbar
                            height: '0.2em'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0,0,0,0)', // Hide the thumb when not hovering
                        },
                        '&:hover::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0,0,0,0.2)', // Show the thumb when hovering
                        },
                    }}
                    onScroll={handleMediaLibraryCategoryScroll}
                >

                    {mediaLibraryCategoryList.map((item, idx) => (<Chip label={`${item.name ?? ""}`} key={`media-library-tag-${idx}`} variant="outlined" sx={{ m: 1, cursor: 'pointer', color: AppColors.primaryColor, borderColor: AppColors.primaryColor, borderRadius: "6px", background: (item.name == mediaTag) ? AppColors.grayZeroColor : "#ffffff" }} onClick={() => handleOnTagSelect(item)} />))}
                    {isCategoryLoading && <Box sx={{ pl: 2, pr: 2, pt: 1 }}><CircularProgress size={30} /></Box>}
                </Stack>


                <Grid container sx={{ p: 0.375 }}>
                    {mediaLibraryMediaModelList?.map((mediaItem, idx) => {
                        return (
                            <Grid
                                ref={(idx + 1 === mediaLibraryMediaModelList?.length) ? mediaLibraryMediaLastElementRef : undefined}
                                key={`post-${idx}`}
                                item
                                onClick={() => onSelectMedia(mediaItem)}
                                xs={4}
                                md={4}
                                height='100px'
                                className={"padding-3"}
                            >
                                <Box sx={{
                                    backgroundImage: `url(${mediaItem.type == "image" ? mediaItem.link ?? "" : mediaItem.thumbnail ?? ""})`,
                                    height: "100%",
                                    width: "100%",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "100% auto",
                                    backgroundPosition: "center",
                                    backgroundAttachment: "local",
                                    position: 'relative'
                                }}>
                                    {(mediaItem.title ?? "").trim().length > 0 && <Box
                                        sx={{
                                            position: 'absolute',
                                            bottom: "0",
                                            left: "0",
                                            padding: "5px",
                                            width: "100%",
                                            background: "#00000070"
                                        }}>
                                        <Typography sx={{
                                            maxHeight: "calc(1.2em * 1)",
                                            overflow: "hidden",
                                            lineHeight: "1.2em",
                                            color: "#ffffff",
                                            textShadow: "1px 1px #000000"
                                        }}>{mediaItem.title}</Typography></Box>}
                                    {mediaItem.type === "video" && (
                                        <>
                                            <Box
                                                component={'img'}
                                                sx={{
                                                    position: 'absolute',
                                                    height: "50%",
                                                    width: "50%",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)"
                                                }}
                                                src="/static/images/video-play.svg"
                                                alt="play"

                                            /></>
                                    )}
                                </Box>

                            </Grid>
                        );


                    })}
                </Grid>

                {(mediaLibraryMediaModelList.length == 0 && !isMediaLoading) && (
                    <Box display="flex" justifyContent="center" mt={6} pb={2}>
                        <Typography>Medias not found.</Typography>
                    </Box>
                )}
                {isMediaLoading && (
                    <Box display="flex" justifyContent="center" mt={2} pb={2}>
                        <CircularProgress />
                    </Box>
                )}


            </Box>}


        </Box >
    );
};

export default MediaLibraryView;
