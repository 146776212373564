import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Typography, Grid, Box } from '@mui/material';
import LoginForm from './LoginForm';
// layouts
// import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../Page';
import Scrollbar from '../Scrollbar';
import { useEffect } from 'react';
import { AppColors, config } from '../../utils/utils';
import { removeItem } from '../../utils/localStorage';


// import AuthSocial from "../sections/authentication/AuthSocial";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));


const ContentStyle = styled('div')(({ theme }) => ({
  // width: '100vw',
  // maxWidth: 500,
  // width: { xs: '175%', md: '100vw' },
  [theme.breakpoints.up('xs')]: {
    width: '175%',
  },
  [theme.breakpoints.up('md')]: {
    width: '100vw',
  },
  maxWidth: 500,
  margin: 'auto',
  display: 'flex',
  // minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(0, 2),
}));

// ----------------------------------------------------------------------

export default function Login() {


  useEffect(() => {
    removeItem(config.SLECTED_PLAN_KEY);
  }, []);

  return (
    <RootStyle title='Login | KnoCard'>
      <Grid
        container
        sx={{ minHeight: '100vh' }}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Grid
          item
          sx={{ maxWidth: '95vw' }}
        >
          {/* <Grid
            container
            alignItems={'center'}
            justifyContent={'center'}
            position={'relative'}
            display={{ sm: 'flex' }}
            marginBottom={'-10px'}
            flexWrap={'nowrap'}
          >
            <Grid
              item
              px={3}
              py={1}
              sx={{
                backgroundColor: '#088AC7',
                borderRadius: '40px 0 0 0',
                // minWidth: 200,
                minWidth: { xs: 120, sm: 150, md: 200 },
              }}
            >
              <Typography
                variant={'h6'}
                sx={{ color: 'white' }}
                textAlign={'center'}
              >
                AFFILIATE
              </Typography>
            </Grid>
            <Grid
              item
              px={3}
              py={1}
              sx={{
                backgroundColor: '#F58227',
                borderRadius: '0 40px 0 0',
                minWidth: { xs: 120, sm: 150, md: 200 },
              }}
            >
              <Typography
                variant={'h6'}
                sx={{ color: 'white' }}
                textAlign={'center'}
              >
                LOGIN
              </Typography>
            </Grid>
          </Grid>*/}
          <Card
            sx={{
              border: '10px solid #F6F7F9',
            }}
          >
            <Grid
              container
              display={'flex'}
              direction={'row'}
            >
              <Grid
                item
                xs={6}
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  // visibility: { xs: 'hidden', md: 'inherit' },
                }}
              >
                <Box component={'img'} src="static/images/illustration_login.png" sx={{ width: '100%' }}></Box>
              </Grid>

              <Grid
                item
                xs={6}
                alignItems={'center'}
              >
                <ContentStyle>
                  <Stack sx={{ my: 2 }}>
                    <Typography
                      variant='h4'
                      gutterBottom
                      sx={{ color: AppColors.primaryColor }}
                    >
                      LOGIN
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                      Please enter your Username/KnoCard Email address and
                      password
                    </Typography>
                  </Stack>
                  {/* <AuthSocial /> */}

                  <LoginForm />
                </ContentStyle>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </RootStyle>
  );
}
