import CryptoJS from 'crypto-js';
import { config } from './utils';
const decryptString = (value: string) => {
  let decryptedString = '';
  const dataString = value
    .replace(/p1L2u3S/g, '+')
    .replace(/s1L2a3S4h/g, '/')
    .replace(/e1Q2u3A4l/g, '=');
  try {
    decryptedString = CryptoJS.AES.decrypt(
      dataString,
      config.SECRET_KEY
    ).toString(CryptoJS.enc.Utf8);
  } catch (error) { }
  return decryptedString;
};

const encryptString = (value: string) => {
  const ciphertext = CryptoJS.AES.encrypt(
    String(value),
    config.SECRET_KEY
  ).toString();
  return ciphertext
    .replace(/\+/g, 'p1L2u3S')
    .replace(/\//g, 's1L2a3S4h')
    .replace(/=/g, 'e1Q2u3A4l');

};

export { encryptString, decryptString };
