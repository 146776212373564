import { createSlice } from '@reduxjs/toolkit';
import { getCorporateGroupLandingPageVideoView, getCorporateGroupMediaVideoView, getGroupMedia, getMediaFeedbackFormCount, getShareActivityCount, getSpecificGroupMediaShareActivityCount, getSpecificGroupMediaViewdActivityCount, getViewActivityCount } from './api';
import { initialState } from './api/interface';

const corporateReportingSlice = createSlice({
  name: 'corporateReporting',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getShareActivityCount.pending, (state) => {
      state.isShareActivityCountLoading = true;

    });
    builder.addCase(getShareActivityCount.fulfilled, (state, action) => {
      state.shareActivityCount = action?.payload?.data?.data
      state.isShareActivityCountLoading = false;

    });
    builder.addCase(getShareActivityCount.rejected, (state, _) => {
      state.isShareActivityCountLoading = false;
    });


    builder.addCase(getSpecificGroupMediaShareActivityCount.pending, (state) => {
      state.isSpecificGroupMediaShareActivityCountLoading = true;

    });
    builder.addCase(getSpecificGroupMediaShareActivityCount.fulfilled, (state, action) => {
      state.specificGroupMediaShareActivityCount = action?.payload?.data?.data
      state.isSpecificGroupMediaShareActivityCountLoading = false;

    });
    builder.addCase(getSpecificGroupMediaShareActivityCount.rejected, (state, _) => {
      state.isSpecificGroupMediaShareActivityCountLoading = false;
    });


    builder.addCase(getViewActivityCount.pending, (state) => {
      state.isViewedActivityCountLoading = true;

    });
    builder.addCase(getViewActivityCount.fulfilled, (state, action) => {
      state.viewedActivityCount = action?.payload?.data?.data
      state.isViewedActivityCountLoading = false;

    });
    builder.addCase(getViewActivityCount.rejected, (state, _) => {
      state.isViewedActivityCountLoading = false;
    });


    builder.addCase(getSpecificGroupMediaViewdActivityCount.pending, (state) => {
      state.isSpecificGroupMediaViewedActivityCountLoading = true;

    });
    builder.addCase(getSpecificGroupMediaViewdActivityCount.fulfilled, (state, action) => {
      state.specificGroupMediaViewedActivityCount = action?.payload?.data?.data
      state.isSpecificGroupMediaViewedActivityCountLoading = false;

    });
    builder.addCase(getSpecificGroupMediaViewdActivityCount.rejected, (state, _) => {
      state.isSpecificGroupMediaViewedActivityCountLoading = false;
    });


    builder.addCase(getMediaFeedbackFormCount.pending, (state) => {
      state.isMediaFeedbackFormCountLoading = true;
    });
    builder.addCase(getMediaFeedbackFormCount.fulfilled, (state, action) => {
      state.mediaFeedbackFormCount = action?.payload?.data?.data
      state.isMediaFeedbackFormCountLoading = false;

    });
    builder.addCase(getMediaFeedbackFormCount.rejected, (state, _) => {
      state.isMediaFeedbackFormCountLoading = false;
    });

    builder.addCase(getGroupMedia.pending, (state) => {
      state.isGroupMediaLoading = true;
    });
    builder.addCase(getGroupMedia.fulfilled, (state, action) => {
      state.groupMedia = action?.payload?.data?.data || [];
      state.isGroupMediaLoading = false;

    });
    builder.addCase(getGroupMedia.rejected, (state, _) => {
      state.isGroupMediaLoading = false;
    });

    builder.addCase(getCorporateGroupMediaVideoView.pending, (state) => {
      state.isCorporateGroupMediaVideoViewLoading = true;
    });
    builder.addCase(getCorporateGroupMediaVideoView.fulfilled, (state, action) => {
      state.corporateGroupMediaVideoView = action?.payload?.data?.data || [];
      state.isCorporateGroupMediaVideoViewLoading = false;

    });
    builder.addCase(getCorporateGroupMediaVideoView.rejected, (state, _) => {
      state.isCorporateGroupMediaVideoViewLoading = false;
    });




    builder.addCase(getCorporateGroupLandingPageVideoView.pending, (state) => {
      state.isCorporateGroupLandingPageVideoViewLoading = true;
    });
    builder.addCase(getCorporateGroupLandingPageVideoView.fulfilled, (state, action) => {
      state.corporateGroupLandingPageVideoView = action?.payload?.data?.data || [];
      state.isCorporateGroupLandingPageVideoViewLoading = false;

    });
    builder.addCase(getCorporateGroupLandingPageVideoView.rejected, (state, _) => {
      state.isCorporateGroupLandingPageVideoViewLoading = false;
    });


  },
});

export const { reset } = corporateReportingSlice.actions;
export default corporateReportingSlice.reducer;
