
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import { AppColors } from '../../utils/utils';
import { formatReportCount } from '../../utils/utilMethods';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


interface IProps {
    title: string;
    index: number;
    existingContactCount: number;
    newContactCount: number;
    isSelection: boolean;
    handleSelectioClick?: () => void;
    isLoading: boolean;
}

const ActivityReportItem: React.FC<IProps> = ({
    title,
    index,
    existingContactCount,
    newContactCount,
    isSelection,
    handleSelectioClick,
    isLoading
}) => {


    const onClickSelection = () => {
        handleSelectioClick && handleSelectioClick();
    }



    return (
        <Grid key={`activity-report-${index}`} item sm={4} xs={12}>
            <Box sx={{ background: `${AppColors.primaryColor}`, borderRadius: "10px", }} >
                <Box sx={{ display: 'flex' }} onClick={onClickSelection}>
                    <Box sx={{ width: "100%" }}> <Typography sx={{ color: "#ffffff", textAlign: "center", pl: 0.5, pr: 0.5, pt: 1, pb: 1 }}>{title}</Typography></Box>
                    {isSelection && <Box sx={{ flexShrink: 0, mr: 2 }}><IconButton aria-label="arrowDown" color="primary" onClick={onClickSelection}>
                        <ArrowDropDownIcon sx={{ color: "#ffffff" }} />
                    </IconButton></Box>}
                </Box>
                <Box sx={{ borderRadius: "10px", padding: "2px", display: "flex", justifyContent: 'center', flexDirection: "column" }}>
                    <Grid container justifyContent="center"  >
                        <Grid item xs={5.8} sx={{ background: `${AppColors.backgroundColor}`, borderRadius: 2, mr: 0.2, ml: 0.2, mb: 0.2 }}>
                            <Box flex={1}><Typography sx={{ color: `${AppColors.primaryColor}`, textAlign: "center", pl: 0.5, pr: 0.5, pt: 1, pb: 1, minHeight: "64px" }}>Shared With Existing Contacts</Typography></Box>
                            {isLoading ? <Box flex={1} textAlign={'center'} sx={{ mb: 1 }}><CircularProgress color='inherit' size={25} /></Box> : <Typography sx={{ color: '#000000', textAlign: "center", pl: 0.5, pr: 0.5, pb: 1 }}>{formatReportCount(existingContactCount)}</Typography>}
                        </Grid>
                        <Grid item xs={5.8} sx={{ background: `${AppColors.backgroundColor}`, borderRadius: 2, mr: 0.2, ml: 0.2, mb: 0.2 }}>
                            <Box flex={1}><Typography sx={{ color: `${AppColors.primaryColor}`, textAlign: "center", pl: 0.5, pr: 0.5, pt: 1, pb: 1, minHeight: "64px" }}>Shared With New Leads</Typography></Box>
                            {isLoading ? <Box flex={1} textAlign={'center'} sx={{ mb: 1 }}><CircularProgress color='inherit' size={25} /></Box> : <Typography sx={{ color: '#000000', textAlign: "center", pl: 0.5, pr: 0.5, pb: 1 }}>{formatReportCount(newContactCount)}</Typography>}
                        </Grid>
                    </Grid>

                </Box>
            </Box>
        </Grid>
    );
};

export default ActivityReportItem;
