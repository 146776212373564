import React, { ChangeEvent, ChangeEventHandler } from 'react';

import { Box, CircularProgress, TextField, Typography } from '@mui/material';
import { useAppSelector } from '../../../store';
import { AppColors } from '../../../utils/utils';
import SearchIcon from '../../../assets/chat/search.svg'
import { inputTextFieldStyle } from '../../../utils/utilMethods';




interface IProps {
    onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const SearchChat: React.FC<IProps> = ({ onSearchChange }) => {
    const { isLoading } = useAppSelector((state) => state?.communityUsers);
    return (
        <>
            {/* Search */}
            <TextField
                name='searchChat'
                fullWidth
                placeholder='Search'
                onChange={onSearchChange}

                InputProps={{
                    sx: {
                        ...inputTextFieldStyle(), borderRadius: '12px',
                        backgroundColor: AppColors.whiteColor,
                        color: AppColors.grayThreeColor,
                        mb: 2
                    },
                    startAdornment: (
                        <React.Fragment>
                            <Box
                                component={'div'}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    component={'img'}
                                    src={SearchIcon}
                                    alt='search'
                                    sx={{
                                        width: '30px',
                                        height: '30px',
                                        color: AppColors.primaryColor,
                                    }}
                                />
                                <Box>
                                    <Typography
                                        variant='body1'
                                        color={'primary'}
                                        sx={{
                                            fontWeight: '500',
                                            ml: 3,
                                        }}
                                    ></Typography>
                                </Box>
                            </Box>
                        </React.Fragment>
                    ),
                    endAdornment: (<React.Fragment>{isLoading ? <CircularProgress size={28} sx={{ mr: 1 }} /> : null}</React.Fragment>)
                }}

            ></TextField>

        </>
    );
};

export default SearchChat;
