import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { SendedKnoCardLinkRequestResponse, SendKnoCardLinkResponse } from './interface';
import { showErrorMessage } from '../../../utils/utilMethods';





export const sendKnoCardLinkRequest = createAsyncThunk('knocardLink/sendKnoCardLinkRequest', async (data: { toUserId: number }, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post<SendKnoCardLinkResponse>(`/api/knocard-link-request`, data);
        return response;
    } catch (error: any) {
        if (error.response.data.message) {
            showErrorMessage(error.response.data.message);
        }
        return rejectWithValue(error.message);
    }
});


export const getSentKnoCardLinkRequest = createAsyncThunk('knocardLink/getSentKnoCardLinkRequest', async (data: { page: number }, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get<SendedKnoCardLinkRequestResponse>(`/api/knocard-link-request/send?page=${data.page}`);
        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});
