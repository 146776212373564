import { FC } from "react";
import { PlaylistAddCheckCircleOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";

const ExternalLinkButtonIcon: FC<{ companyExternalLink: any, sx?: any }> = ({
  companyExternalLink, sx
}) => {
  if (companyExternalLink?.icon_url) {
    return (
      <Box
        component={'img'}
        src={companyExternalLink?.icon_url ?? ''}
        alt={companyExternalLink?.title}

        sx={{
          width: '24px',
          height: '24px',
          ...(sx ? sx : {})
        }}

      />
    );
  }

  if (
    companyExternalLink?.icon !== null &&
    companyExternalLink?.icon !== undefined &&
    companyExternalLink?.icon === 97
  ) {
    return (
      <PlaylistAddCheckCircleOutlined
        style={{
          color: `#${companyExternalLink.color.substring(4)}`,
          fontSize: '22px',
        }}
        sx={{
          ...(sx ? sx : {})
        }}
      />
    );
  }

  if (
    companyExternalLink?.icon !== null &&
    companyExternalLink?.icon !== undefined
  ) {
    return (
      <Box
        component={'span'}
        className={`icon-${companyExternalLink?.icon}`}
        sx={{
          color: `#${companyExternalLink.color.substring(4)}`,
          fontSize: '22px',
          ...(sx ? sx : {})
        }}
      ></Box>
    );
  }
  return (
    <Box
      component={'span'}
      className={`icon-110`}
      sx={{
        color: `#${companyExternalLink.color.substring(4)}`,
        fontSize: '22px',
        ...(sx ? sx : {})
      }}
    ></Box>
  );
};

export default ExternalLinkButtonIcon;