import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { toastHandler } from '../../../utils/toastHandler';
import { MediaLibraryCategoryReponse, MediaLibraryReponse } from './interface';


export const getMediaLibrary = createAsyncThunk(
  'mediaLibrary/getMediaLibrary',
  async ({ tag, page, type }: { tag: string, page: number, type?: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<MediaLibraryReponse>(`/api/media-library/media/${tag}?page=${page}${type ? `&type=${type}` : ''}`);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const getMediaLibraryCategory = createAsyncThunk(
  'notification/getMediaLibraryCategory',
  async ({ page }: { page: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<MediaLibraryCategoryReponse>(`/api/media-library/category?page=${page}`);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

