import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { CourseVideoResponse } from './interface';

export const getCourseVideos = createAsyncThunk(
  'courseVideo/getCourseVideos',
  async (data: { courseType: string, page: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<CourseVideoResponse>(
        `/api/course-videos?page=${data.page}&type=${data.courseType.replaceAll("&", "%26")}`

      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const saveCourseVideoView = createAsyncThunk(
  'courseVideo/saveCourseVideoView',
  async (data: {
    video_id: number,
    complete: number,
    watch_duration: string
  }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/api/course-videos/view/save`,
        data
      );

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);




