import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import styles from '../../styles/Appbar.module.css';
import { styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import PersonIcon from '@mui/icons-material/Person';
// import Logo from "./Logo";

import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../store";
import { Icon, IconButton, Stack, Tab, Tabs, Toolbar, Typography, Badge } from "@mui/material";
import Iconify from "../../components/Iconify";
import { logoutUser } from "../../store/auth/api";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import sidebarConfig, { knoCardSocialConnectSidebarConfig } from "./SidebarConfig";
import useWindowSize from "../../utils/useWindowSize";
import NotificationsPopover from "./NotificationsPopover";
import { getUserAllChats } from '../../client/FireStoreChat';
import { updateChatChannels } from '../../store/chat/chatSlice';
import { logoutUserFromLocal } from '../../store/auth/authSlice';
import { checkIsKnoCardSocialConnectUserPlan, checkIsProUser } from '../../utils/checkPro';
import { AppColors } from '../../utils/utils';
import AccountPopover from './AccountPopover';


const DashboardNavbar = ({
  onOpenSidebar,
  isCollapse,
}: {
  onOpenSidebar: any;
  isCollapse: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();


  const navigate = useNavigate();
  const location = useLocation();

  const userInfo = useAppSelector((state) => state?.auth?.user);
  const isProUser = checkIsProUser(userInfo);
  const isKnoCardSocialConnectUser = checkIsKnoCardSocialConnectUserPlan(userInfo);

  const windowSize = useWindowSize();
  const [tabMinWidth, setTabMinWidth] = useState(100);
  const [activeMenu, setActiveMenu] = useState(0);
  const chatChannels = useAppSelector((state) => state?.chat?.chatChannels);


  // const handleSignOut = async (_: React.MouseEvent<HTMLElement>) => {
  //   await dispatch(logoutUser());
  //   localStorage.clear();
  //   await dispatch(logoutUserFromLocal());
  //   navigate(`/`);
  // };

  useEffect(() => {
    const newWidth = windowSize.width / (isKnoCardSocialConnectUser ? knoCardSocialConnectSidebarConfig : sidebarConfig).length;
    setTabMinWidth(newWidth);
  }, [windowSize]);

  function getByTitle() {
    let pageTitle = "";
    (isKnoCardSocialConnectUser ? knoCardSocialConnectSidebarConfig : sidebarConfig).forEach((item) => {
      if (item.path == location.pathname) {
        pageTitle = item.title;
      }
    });


    if ("/dashboard/add-preferred-partner" == location.pathname) {
      pageTitle = "Preferred Partners";
    } else if (location.pathname.startsWith('/dashboard/edit-preferred-partner/')) {
      pageTitle = "Preferred Partners";
    } else if ("/dashboard/add-business-social" == location.pathname) {
      pageTitle = "KnoCard Social";
    } else if (location.pathname.startsWith('/dashboard/edit-business-social/')) {
      pageTitle = "KnoCard Social";
    } else if (location.pathname.startsWith('/dashboard/settings')) {
      pageTitle = "Settings";
    } else if (location.pathname.startsWith('/dashboard/promoter-dash')) {
      pageTitle = "Promoter Dash";
    } else if (location.pathname.startsWith('/dashboard/corporate-report')) {
      pageTitle = "Corporate Report";
    } else if (location.pathname.startsWith("/dashboard/engage")) {
      return "Pipeline"
    } else if (location.pathname.startsWith('/dashboard/vbc')) {
      pageTitle = "KnoCard";
    } else if (location.pathname.startsWith('/dashboard/support')) {
      pageTitle = "Help & Support";
    }


    return pageTitle;
  }

  const handleChange = (event: React.SyntheticEvent, position: number) => {
    setActiveMenu(position);
    navigate((isKnoCardSocialConnectUser ? knoCardSocialConnectSidebarConfig : sidebarConfig)[position].path);
  };


  useEffect(() => {
    (isKnoCardSocialConnectUser ? knoCardSocialConnectSidebarConfig : sidebarConfig).forEach((item, index) => {
      if (item.path == location.pathname) {
        setActiveMenu(index);
      } else {
        if (("/dashboard/add-preferred-partner" == location.pathname || location.pathname.startsWith('/dashboard/edit-preferred-partner/')) && item.path == '/dashboard/preferred-partners') {
          setActiveMenu(index);
        } else if (("/dashboard/add-business-social" == location.pathname || location.pathname.startsWith('/dashboard/edit-business-social/')) && item.path == '/dashboard/business-social') {
          setActiveMenu(index);
        }
      }
    });
  }, [windowSize]);

  useEffect(() => {
    const unSubscribe = getUserAllChats(userInfo?.id ?? 0, (data: any[]) => {
      dispatch(updateChatChannels(data));
    });
    return () => {
      if (unSubscribe != null) {
        unSubscribe();
      }
    };
  }, []);

  const getUnreadMessageCount = () => {
    return chatChannels.reduce((total, item) => total += ((item?.last_message?.from != userInfo?.id) ? (item?.last_message?.unread ?? 0) : 0), 0);
  }


  return (
    <AppBar id="header" position="fixed" sx={{
      backgroundImage: 'url("/static/images/header_bg.svg")',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      zIndex: 2000,
      verticalAlign: 'center'
    }}>
      <Box className={styles.navbarContainer} sx={{ ...(isProUser ? {} : { height: "90px" }) }}>
        <Box className={styles.navbarContent}>
          <IconButton
            onClick={onOpenSidebar}
            sx={{
              mr: 1, color: "#ffffff",
              display: { lg: "none" }
            }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
          <Box className={styles.navbarLogoContainer}>
            <Box component={"img"} src={userInfo?.affiliate_group?.brand_logo_link ?? "/static/images/white_logo.svg"} className={styles.logo} alt="logo" sx={{ maxHeight: "56px", maxWidth: { sm: '200px', xs: "120px" } }} />
          </Box>
          <Box>
            <Stack
              direction='row'
              alignItems='center'
              spacing={{ xs: 0.5, sm: 1.5 }}
            >
              <Box>
                <NotificationsPopover />
              </Box>
              <Box>
                {/* <IconButton

                  size='large'
                  color={'default'}
                  onClick={() => navigate('/dashboard/messaging')}

                >
                  <Badge
                    badgeContent={getUnreadMessageCount()}
                    color='error'
                  >
                    <Box component={'img'}
                      src={"/static/images/chat-white.png"}
                      sx={{
                        height: '20px',
                      }}></Box>
                  </Badge>
                </IconButton> */}
                <IconButton
                  size='large'
                  color={'default'}
                  onClick={() => navigate('/dashboard/settings')}
                  sx={{ bgcolor: AppColors.whiteColor, '&:hover': { bgcolor: AppColors.whiteColor } }}
                >
                  <Iconify
                    icon='lets-icons:setting-fill'
                    width={25}
                    height={25}
                    sx={{ color: AppColors.primaryColor }}
                  />
                </IconButton>
              </Box>
              <Box >
                <AccountPopover />
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box
          className={styles.pageNameContainer}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "flex",
              xl: "flex",
            }
          }}
        >
          <Box className={styles.pageName}>{getByTitle()}</Box>

        </Box>
      </Box>
    </AppBar >
  );
};
export default DashboardNavbar;
