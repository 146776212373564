import React, { useEffect, useRef, useState } from "react";

import Typography from "@mui/material/Typography";
import Button, { ButtonProps } from "@mui/material/Button";
import { Box, Card, CardActionArea, CardContent, Grid, Stack, useMediaQuery } from "@mui/material";

import { config } from "../../utils/utils";
import styled from "@emotion/styled";
import styles from "../../styles/AffiliateLanding.module.css";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../store";
import Loader from '../../components/Loader';
import { getNetworkLandingPageVideos } from "../../store/networkLandingPageVideo/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import YoutubeVideoTutorialModel from "../../components/modal/YoutubeVideoTutorialModel";
import useToggle from "../../hooks/useToggle";
import { getUserInfo } from "../../store/userInfo/api";
import { UserInfo } from "../../store/userInfo/api/interface";
import useWindowSize from "../../utils/useWindowSize";
import { analytics } from "../../store/shareRefer/api";
import ContactFormModal from '../../components/ContactFormModal';
import { setUserInfo } from "../../store/userInfo/userInfoSlice";
import { ContactFormSourceType } from "../../enum";
import { checkIsProProOrProOneOrProPlusUser } from "../../utils/checkPro";
import GroupNetworkModal from "../../components/GroupNetworkModal";
import { LandingPageNewNavBar } from "../../components/LandingPageNewNavBar";
import { userInfo } from "os";
import NetworkLandingPageConfirmationModal from "../../components/NetworkLandingPageConfirmationModal";
import YouTube from 'react-youtube';
import zIndex from "@mui/material/styles/zIndex";
import { findAffiliateGroup } from "../../store/AffiliateGroup/api";
import { setItem } from "../../utils/localStorage";

const OpenButton = styled(Button)<ButtonProps>(({ theme }) => ({
  // backgroundColor: "#00cc00",
  backgroundImage: 'linear-gradient(270deg, #70F837 0%,   #1CB85D 100%)',
  color: "#000000",
  borderRadius: "30px",
  fontSize: "small",
  fontWeight: "bold",
  "&:hover": {
    background: "white",
    color: "#00cc00",
  },
  minWidth: "122px"
}));

const youtubeOpts = {
  height: '100%',
  width: '100%',
  playerVars: {
    // Hide related videos after the video finishes
    rel: 0,
    // Hide the video annotations
    iv_load_policy: 3,
    // Hide the YouTube logo
    modestbranding: 1,
  },
};

const DriveLandingPage = () => {
  const toolbarMatches = useMediaQuery("(max-width:745px)");
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useAppSelector((state) => state.tutorialVideo.isLoading);
  const authUserInfo = useAppSelector((state) => state?.auth?.user);

  const [searchParams] = useSearchParams();
  const [referredByLink, setReferredByLink] = useState('');
  const [referrUserInfo, setReferrUserInfo] = useState<UserInfo | undefined>(undefined);

  const [calendarLink, setCalendarLink] = useState('https://calendly.com/knocard/connect-with-me');


  const windowSize = useWindowSize();
  const [tabMinWidth, setTabMinWidth] = useState(90);
  const matches = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    const newWidth = matches ? windowSize.width / 5 : 90;
    setTabMinWidth(newWidth);
  }, [windowSize]);


  const videoList = [
    {
      videoId: undefined,
      imageUrl: "/static/drive_landing_page/what_is_drive_success.webp",
      title: "What is the DRIVE Success Program?",
      description: "Catapult your career, improve your relationships, and see your self esteem soar when you master the 5 essential Communication Styles. Learn how to be heard and eliminate miscommunication with DRIVE."
    },
    {
      videoId: undefined,
      imageUrl: "/static/drive_landing_page/drive_success_business.webp",
      title: "DRIVE Success Business Presentation",
      description: "When you understand what motivates others, and learn how to speak their language, success will follow! DRIVE is a game changing program that offers a huge income opportunity... watch to learn more!"
    }
  ];


  const {
    toggle: toggleContactForm,
    onOpen: onOpenContactForm,
    onClose: onCloseContactForm,
  } = useToggle();

  const {
    toggle: toggleNetworkLandingPage,
    onOpen: onOpenNetworkLandingPage,
    onClose: onCloseNetworkLandingPage,
  } = useToggle();

  const {
    toggle: toggleGroup,
    onOpen: onOpenGroup,
    onClose: onCloseGroup,
  } = useToggle();

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleContactFormSubmitSuccess = (contactCode: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete(config.PROSPECT_KEY);
    queryParams.set('sc', contactCode);
    navigate(`/network-landing-page?${queryParams.toString()}`);
  };



  useEffect(() => {
    let refferLink = `${config.VBC_URL}`;
    let userName = "";

    if (searchParams.has(config.REFER_KEY)) {
      userName = searchParams.get(config.REFER_KEY) ?? '';
      refferLink += `/affiliate/${userName}`;
    } else {
      refferLink += "/register";
    }
    setReferredByLink(refferLink);

    if (!isLoading) {
      dispatch(getNetworkLandingPageVideos());
    }

    if (userName.length > 0) {
      dispatch(getUserInfo({ username: userName })).unwrap().then((res) => {
        if (res.data.data.user.length > 0) {
          setReferrUserInfo(res.data.data.user[0]);
          dispatch(setUserInfo(res.data));
        }
      });
    }
    dispatch(findAffiliateGroup()).unwrap().then((result) => {
      if (result && result.status == 200 && result.data.data) {
        result.data.data.forEach((element: any) => {
          if (element.code === "sgregg") {

            setItem(config.GROUP_NETWORK_ID_KEY, element?.id);
          }
        });
      }
    });


  }, []);


  useEffect(() => {
    if (referrUserInfo) {
      if (searchParams.has(config.PROSPECT_KEY) && searchParams.get(config.PROSPECT_KEY) && checkIsProProOrProOneOrProPlusUser(referrUserInfo)) {
        onOpenContactForm();
      }
      saveLadingPageViewActivity();
    }
  }, [referrUserInfo]);


  const saveLadingPageViewActivity = () => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let elid = searchParams.get('elid');
      let loginUserId = authUserInfo?.id;

      dispatch(
        analytics({
          route: '/activity/save',
          data: {
            actionType: 'view',
            sourceType: 'link_share',
            module: 'group_landing_page',
            targetId: referrUserInfo?.id,
            identifiableId: referrUserInfo?.id,
            ...(loginUserId && { viewableId: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
            ...((!loginUserId && elid) && { elid: elid })
          },
        })
      );
    }
  }

  useEffect(() => {

    if (referrUserInfo) {
      if ((referrUserInfo.calendar_link ?? "").trim().length > 0) {
        setCalendarLink(referrUserInfo.calendar_link ?? "");
      } else {
        setCalendarLink(`tel:${referrUserInfo.phone_country_code ?? ""}${referrUserInfo.phone_number}`);
      }


    }

  }, [referrUserInfo])


  const handleVisitMySite = () => {
    if (referrUserInfo?.landing_page_link) {
      window.open(`${referrUserInfo?.landing_page_link}`) || window.location.replace(`${referrUserInfo?.landing_page_link}`);
    } else {
      handleUserProfile();
    }
  }

  const getKnoCardSubscriptionLink = () => {
    const subscriptionUrl = `${config.VBC_URL}/subscription/${referrUserInfo?.username || ""}`

    return subscriptionUrl;
  }


  const handleUserProfile = () => {
    let userProfileLink = `${config.VBC_URL}`;
    let userName = "";

    if (searchParams.has(config.REFER_KEY)) {
      userName = searchParams.get(config.REFER_KEY) ?? '';
      userProfileLink += `/${userName}`;
    } else {
      userProfileLink += "/register";
    }

    window.open(`${userProfileLink}`) || window.location.replace(`${userProfileLink}`);
  }

  const [openYoutubeVideo, setOpenYoutubeVideo] = useState<string | undefined>(undefined);

  const {
    toggle: togglenTutorialVideo,
    onOpen: onOpenTutorialVideo,
    onClose: onCloseTutorialVideo,
  } = useToggle();


  useEffect(() => {
    if (openYoutubeVideo != undefined) {
      onOpenTutorialVideo();
    }
  }, [openYoutubeVideo]);

  useEffect(() => {
    if (!togglenTutorialVideo) {
      setOpenYoutubeVideo(undefined);
    }
  }, [togglenTutorialVideo]);


  const handleOpenPricing = () => {
    const subscriptionUrl = `${config.VBC_URL}/subscription/${referrUserInfo?.username || ""}`
    window.open(`${subscriptionUrl}`) || window.location.replace(`${subscriptionUrl}`);
  }



  const handleOnVideoPlayActivity = (videoTitle: string, second: number, duration: number) => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let loginUserId = authUserInfo?.id;

      dispatch(
        analytics({
          route: '/landing-page/video-watch',
          data: {
            target_id: referrUserInfo?.id ?? 0,
            module: "group_landing_page",
            title: videoTitle,
            target_page: "drive-landing-page",
            video_watch_seconds: second,
            video_duration_seconds: duration,
            ...(loginUserId && { viewable_id: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
          },
        })
      );
    }
  };


  const handleOnPauseOrCompleteVideoActivity = (videoTitle: string, second: number, duration: number) => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let loginUserId = authUserInfo?.id;

      dispatch(
        analytics({
          route: '/landing-page/video-watch-time',
          data: {
            target_id: referrUserInfo?.id ?? 0,
            module: "group_landing_page",
            title: videoTitle,
            target_page: "drive-landing-page",
            video_watch_seconds: second,
            video_duration_seconds: duration,
            ...(loginUserId && { viewable_id: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
          },
        })
      );
    }
  };


  const handleOnVideoPlay = (videoTitle: string, time: number, duration: number) => {

    if (time == 0) {
      handleOnVideoPlayActivity(videoTitle, time, duration);
    } else {
      handleOnPauseOrCompleteVideoActivity(videoTitle, time, duration);
    }
  }


  if (isLoading) return <Loader />;

  return (
    <React.Fragment>
      <LandingPageNewNavBar userInfo={referrUserInfo} affiliateUserName={referrUserInfo?.username} getKnoCardLink={getKnoCardSubscriptionLink()} />



      {/* SECTION 1  */}
      <section className={styles.section_1}>
        <Box

          sx={{ pb: 2, width: "100%", background: 'linear-gradient(90deg,#929292 0%,#FEFEFE 100%)', backgroundPosition: "bottom", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
        // sx={{
        //   minHeight: { xs: 'calc(100vh - 77px)', md: "unset" }
        // }}
        // flexDirection={'column'}
        // display='flex'
        // justifyContent='center'
        >
          <Stack flexDirection={"column"} sx={{
            pt: 2,
            pb: 1, alignContent: { xs: "flex-start", sm: "center" }, alignItems: { xs: "start", sm: "center" },
            position: "relative",
            zIndex: 1
          }}>
            <Box
              component='img'
              src={'/static/drive_landing_page/logo.webp'}
              sx={{ width: { sm: "50%", xs: "26%" }, maxWidth: "150px", position: "absolute", top: 10, right: 10 }}
            />
            <Box sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#6a0000",
              width: "fit-content",
              mr: 2,
              pb: "10px",
              pt: "10px",
              borderRadius: "10px",
              borderTopLeftRadius: { xs: "0px", sm: "10px" },
              borderBottomLeftRadius: { xs: "0px", sm: "10px" },
              zIndex: 1,
              position: 'static'
            }}>
              <Typography
                component='p'
                sx={{
                  fontSize: '1.5rem',
                  color: '#FFFFFF',
                  textAlign: 'center',
                  pl: 2,
                  pr: 2,
                  pt: 0.5,
                  lineHeight: 1,
                  pb: 0.5,
                  borderRadius: "10px",
                  fontWeight: "bold",
                  '& span': {
                    fontSize: '1.6rem',
                  }

                }}
              >
                <span>A</span>ttention <span>L</span>eaders...

              </Typography>
            </Box>

          </Stack>
          <Typography
            component='p'
            sx={{
              fontSize: '1.6rem',
              color: '#6a0000',
              textAlign: 'center',
              pl: 1,
              pr: 1,
              pt: 2,
              lineHeight: 1.3,
              pb: 0.5,
              borderRadius: "10px",
              fontWeight: "bold",
              zIndex: 1,
              position: 'static'
            }}
          >
            Act quickly to get a TOP RANKING position BEFORE we go LIVE!!!

          </Typography>
          <Stack flexDirection={"column"} sx={{
            mb: 2, pt: 2,
            pb: 1, alignContent: { xs: "flex-end", sm: "center" }, alignItems: { xs: "start", sm: "center" },

          }}>
            <Box sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              background: 'linear-gradient(90deg,#FCC731 0%,#EAE7BA 100%)',
              width: "fit-content",
              ml: 2,
              pb: "10px",
              pt: "10px",
              borderRadius: "10px",
              borderTopRightRadius: { xs: "0px", sm: "10px" },
              borderBottomRightRadius: { xs: "0px", sm: "10px" },
              zIndex: 1,
              position: 'static'
            }}>
              <Typography
                component='p'
                sx={{
                  fontSize: '1.5rem',
                  color: '#28090F',
                  textAlign: 'center',
                  pl: 1,
                  pr: 1,
                  pt: 0.5,
                  lineHeight: 1,
                  pb: 0.5,
                  borderRadius: "10px"
                }}
              >
                Discover how to Earn BIG with a program that Every business person needs for success

              </Typography>
            </Box>
          </Stack>

          <Stack flexDirection={"row"} justifyContent={'center'} sx={{
            mt: 2, mb: 2,
            ml: 1, mr: 1,

          }}>
            <Box sx={{
              textAlign: 'center',
              border: "solid 4px #6a0000",
              borderRadius: "15px",
              width: { xs: '90%', md: '60%' },
              //height: { xs: 'auto', md: '400px' },
              zIndex: 1,
              position: 'static'
            }}>
              <Box
                sx={{
                  p: { xs: 1, md: 1.5 },
                  maxWidth: '100%',
                  //height: { xs: 'auto', md: '392px' },
                }}
              >
                <Box
                  component={"img"}
                  src="/static/drive_landing_page/intro.webp"
                  sx={{
                    p: { xs: 1, md: 1.5 },
                    maxWidth: '100%',
                    height: '100%',
                    width: '100%',
                    borderRadius: "20px"
                  }}
                ></Box>

                {/* <YouTube
                  videoId={"B4bgLbavGpw"}
                  opts={{ youtubeOpts }}
                  className='object-fit-contain'
                  style={{
                    height: '100%',
                    width: '100%'
                  }}
                  iframeClassName="full-height-width"

                /> */}
              </Box>
            </Box>
          </Stack>
          <Box

            sx={{ backgroundImage: "url('/static/drive_landing_page/bg_dots.webp')", backgroundSize: "cover", width: { md: "15%", sm: "20%", xs: "30%" }, height: "100%", position: "absolute", top: 0, left: 0, zIndex: 0, opacity: 0.1 }}
          />
        </Box>
      </section>

      <section>
        <Box
          sx={{
            width: '100%',
            background: '#6a0000',
            backgroundSize: "cover",
            pt: 2,
            pb: 2,
            position: 'relative',
          }}
        >
          <Box
            component={'img'}
            sx={{ width: '100%', maxWidth: "200px", position: "absolute", top: "30%", opacity: 0.1 }}
            src='/static/Affiliate_landing/dots_left.png'
            alt=''
          />

          <Box
            sx={{ backgroundImage: "url('/static/drive_landing_page/bg_dots.webp')", backgroundSize: "cover", width: { md: "15%", sm: "20%", xs: "30%" }, height: "100%", position: "absolute", top: 0, right: 0, zIndex: 0, opacity: 0.1 }}
          />

          <Typography
            component='p'

            sx={{
              zIndex: 1,
              fontSize: '1.5rem',
              color: '#ffffff',
              textAlign: 'center',
              mb: 2,
              p: 2,
              width: '100%',
              borderRadius: "10px",

            }}
          >
            Are you a leader looking for an opportunity to create wealth? Would you like to help others in B2B?
          </Typography>
          <Box sx={{
            zIndex: 1,
            textAlign: "center", width: "100%", display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pr: 2
          }}>
            <Box component={"img"} src={"/static/rejection_free/white_line.png"}></Box>
            <Typography
              component='p'

              sx={{
                fontSize: '1.3rem',
                color: '#00000',
                backgroundColor: "#EDC213",
                textAlign: 'center',
                mt: 1,
                mb: 2,
                p: 2,
                width: 'fit-content',
                borderRadius: "10px",
                zIndex: 1,
                position: "static"
              }}
            >
              Learn about a GLOBAL business that can transform your life & the lives of others...
            </Typography>
          </Box>
        </Box>
      </section>

      <section>
        <Box
          sx={{
            width: '100%',
            background: 'linear-gradient(90deg,#929292 0%,#FEFEFE 100%)',
            backgroundSize: "cover",
            pt: 5,
            pb: 2,
            position: 'relative',
          }}
        >

          <Grid
            container
            spacing={2}

            justifyContent={"center"}
            sx={{
              position: "relative",
              textAlign: "center",
              pl: 2,
              pr: 2,
              overflow: "hidden"
            }}
          >

            {videoList && videoList.map((item, index) => {
              return (<Grid item sx={{ zIndex: 1 }} >
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{
                    overflow: 'hidden',
                    display: '-webkit-box',
                    '-webkit-line-clamp': '1',
                    '-webkit-box-orient': 'vertical',
                    color: '#6a0000',
                    fontSize: "large",
                    fontWeight: "bold",
                    mb: 1
                  }}
                >
                  {item.title}
                </Typography>
                <Card
                  sx={{
                    maxWidth: { sm: 345 },
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  }}

                >

                  <Box component={'div'} sx={{
                  }}>

                    <Box sx={{
                      border: "2px solid #E9AB24",
                      borderRadius: "15px",
                      backgroundColor: "#6a0000",
                      p: 2
                    }}>
                      <Box
                        component='div'
                        height='170px'
                        sx={{
                          background: `url(${item.imageUrl})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          borderRadius: "15px"
                        }}
                        onClick={() => {
                          setOpenYoutubeVideo(item.videoId);
                        }}
                      /></Box>

                    <CardActionArea
                      onClick={() => {
                        setOpenYoutubeVideo(item.videoId);
                      }}
                    >
                      <CardContent
                        sx={{
                          p: 0.5,
                          '&:last-child': { p: 1 },
                          minHeight: '36px',
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant='body2'
                          color='text.secondary'
                          sx={{
                            pt: 1.5,
                            fontSize: "medium",
                            overflow: 'hidden',
                            display: '-webkit-box',
                            // '-webkit-line-clamp': '3',
                            // '-webkit-box-orient': 'vertical',
                            color: "#000000"
                          }}
                        >
                          {item.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>

                  </Box>
                </Card>

              </Grid>);
            })}



          </Grid>


        </Box>
      </section>

      <section>
        <Box
          sx={{
            width: '100%',
            background: '#6a0000',
            backgroundSize: "cover",
            pt: 2,
            pb: 2,
            position: 'relative',
          }}
        >

          <Box sx={{
            zIndex: 1,
            textAlign: "center", width: "100%", display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pr: 2
          }}>
            <Box component={"img"} src={"/static/rejection_free/white_line.png"}></Box>
            <Typography
              component='p'

              sx={{
                fontSize: '1.3rem',
                color: '#00000',
                backgroundColor: "#f1cb15",
                textAlign: 'center',
                mt: 1,
                mb: 2,
                p: 2,
                width: 'fit-content',
                borderRadius: "10px",
                zIndex: 1,
                position: "static"

              }}
            >
              Now that you see the opportunity, let us show you the simple system we’re using to duplicate efforts in marketing this business so we all win!!
            </Typography>
          </Box>





          <Box
            component={'img'}
            sx={{ width: '100%', maxWidth: "200px", position: "absolute", bottom: 0, right: 0, opacity: 0.1 }}
            src='/static/Affiliate_landing/dots_right.png'
            alt=''
          />
        </Box>
      </section>


      <section>
        <Box
          sx={{
            width: '100%',
            background: '#171717',

            pb: 2,
            textAlign: "center"
          }}
        >

          <Typography
            component='p'
            sx={{
              fontSize: "1.5rem",
              fontFamily: "'Lato', sans-serif",
              color: '#FDFF0B',
              textAlign: { xs: 'start', md: 'center' },
              fontWeight: "bold",
              mb: 1,
              p: 1,
              ml: 2,
              pt: 2
            }}
          >
            The System
          </Typography>

          <Box sx={{ justifyContent: "center", display: "flex" }}>
            <Box sx={{
              border: "solid 2px #658491",
              borderRadius: "20px",
              backgroundColor: "#022D42",
              width: "fit-content",
              ml: 2,
              mr: 2
            }}>
              <Box
                component={'video'}
                autoPlay={false}
                id='intro_video'
                controls
                poster={"https://storage-prod.knocard.net/rejection_free_page_video/thumbnail/1712703156_1707358330.jpg"}
                sx={{
                  pt: { xs: 1, md: 3 },
                  pl: { xs: 1, md: 5 },
                  pr: { xs: 1, md: 5 },
                  mb: 2,
                  maxWidth: '100%',
                  height: { xs: 'auto', md: '400px' },
                }}
                onPlay={(props: any) => {
                  if (props.currentTarget.currentTime == 0) {
                    handleOnVideoPlay("The System", 0, props.currentTarget.duration || 0);
                  }
                }}
                onPause={(props: any) => {
                  handleOnVideoPlay("The System", props.currentTarget.currentTime, props.currentTarget.duration || 0);
                }}
              >
                <source src={"https://storage-prod.knocard.net/rejection_free_page_video/1712703158_2067936189.mp4"} type='video/mp4' />
              </Box>
            </Box>
          </Box>
          <Grid container>
            {/* <Grid item xs={6}>
              <Typography
                component='p'
                sx={{
                  fontSize: "1.5rem",
                  fontFamily: "'Lato', sans-serif",
                  color: '#FDFF0B',
                  textAlign: 'center',
                  fontWeight: "bold",
                  mb: 1,
                  p: 1
                }}
              >
                Starts as low as
              </Typography>
              <Stack flexDirection={"row"} justifyContent={'center'}>
                <Box sx={{
                  background: 'linear-gradient(142deg,#F8CE4B 0%,#E9E5B9 100%)',
                  p: { xs: 2, sm: 3 },
                  mr: 0.5,
                  borderRadius: "100%"
                }}>
                  <Typography
                    sx={{
                      color: "#6a0000",
                      fontWeight: "bold",
                      fontSize: "1.5rem"
                    }}>
                    $34
                  </Typography>
                </Box>
                <Box sx={{
                  background: 'linear-gradient(142deg,#F8CE4B 0%,#E9E5B9 100%)',
                  p: { xs: 2, sm: 3 },
                  ml: 0.5,
                  borderRadius: "100%"
                }}>
                  <Typography
                    sx={{
                      color: "#6a0000",
                      fontWeight: "bold",
                      fontSize: "1.5rem"
                    }}>
                    $69
                  </Typography>
                </Box>
              </Stack>
            </Grid> */}
            <Grid item xs={12}>
              <Typography
                component='p'
                sx={{
                  mt: 2,
                  fontSize: "1.3rem",
                  fontFamily: "'Lato', sans-serif",
                  color: '#ffffff',
                  textAlign: 'center',
                  fontWeight: "bold",
                  mb: 1,
                  p: 1
                }}
              >
                Check out our Promotions
              </Typography>

              <OpenButton
                sx={{
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  width: 'fit-content',
                  textTransform: 'unset',
                  fontWeight: "bold",
                }}
                onClick={handleOpenPricing}
              >
                Pricing
              </OpenButton>
            </Grid>
          </Grid>

        </Box>
      </section>


      <section>
        <Box
          sx={{
            width: '100%',
            background: '#6a0000',
            pt: 5,
            pb: 5,
            position: 'relative',
            overflow: "hidden"

          }}
        >
          <Box
            component='img'
            src={'/static/custom_landing_page/network.png'}
            sx={{ width: { sm: "50%", xs: "80%" }, position: "absolute", top: 0, right: "-10%", opacity: 0.1 }}
          />
          <Typography
            sx={{
              fontSize: '2.3rem',
              textAlign: 'center',
              fontFamily: "'Lato', sans-serif",
              fontWeight: 'bold',
              pl: 1,
              pr: 1,
              color: "#ffffff"
            }}
          >
            So What's next?
          </Typography>

          <Box sx={{
            background: 'linear-gradient(90deg, #EAE7BA 0%, #EFBD30 100%)',
            p: 1.5,
            mt: 2,
            mr: { sm: 5, xs: 2 },
            borderRadius: "16px",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}>
            <Typography sx={{ textAlign: "center", zIndex: 1, position: "static" }}>
              Click on the link below to Join our Team and take your FREE DRIVE Assessment to help you create the future you Desire and Deserve!!!
            </Typography>
          </Box>

          <Box sx={{ textAlign: 'center', mt: 4, pb: 2 }}>
            <OpenButton
              sx={{
                pl: 3,
                pr: 3,
                width: 'fit-content',
                textTransform: 'unset',
                fontWeight: "bold",

              }}
              onClick={handleVisitMySite}
            >
              Visit My Site
            </OpenButton>
          </Box>

          <Box sx={{ borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px", width: "calc(100% - 96px)", position: "absolute", top: 288, display: { xs: "none", md: "block" }, height: "140px", border: "2px solid #ffffff", ml: 12, borderRightWidth: "0px" }}></Box>
          <Stack
            flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
            sx={{ p: { sm: 2, xs: 2, md: 5 } }}


          >
            <Box
              component={'img'}
              src='/static/drive_landing_page/calendar.webp'
              sx={{
                height: '100px',
                objectFit: 'contain',
                ml: { sm: 0, xs: 0, md: '80px' },
                zIndex: 1,
                mt: { sm: 0, xs: 0, md: 3 },
                borderRadius: "10px"
              }}
            />

            <Stack alignContent={"center"} alignItems={"center"} sx={{ mt: { sm: 0, xs: 0, md: 3 }, width: "100%", textAlign: "center" }} >
              <Typography
                sx={{
                  color: '#ffcd34',
                  fontWeight: "bold",
                  fontSize: '1.5rem',
                  pt: 1,
                  textAlign: 'center',
                }}
              >
                Questions? We've got answers.
              </Typography>
              <Button
                sx={{
                  mt: 1.5,
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  width: 'fit-content',
                  textTransform: 'unset',
                  fontWeight: "bold",
                  backgroundImage: 'linear-gradient(0deg, #ffffff 0%, #FCC83A 100%)',
                  color: "#000000",
                  borderRadius: "30px",
                  fontSize: "small",
                  "&:hover": {
                    background: "#ffffff",
                  },
                  minWidth: "122px"
                }}
                onClick={onOpenNetworkLandingPage}
              >
                Let's Chat
              </Button>


            </Stack>


            {referrUserInfo && (
              <Box
                sx={{
                  textAlign: 'center',
                  mt: { md: 0.2, xs: 4 },
                  zIndex: 100
                }}
                flexDirection={'column'}
              >
                <Box
                  component={'img'}
                  src={
                    referrUserInfo?.profile_picture
                      ? referrUserInfo?.profile_picture
                      : '/static/images/placeholder_profile.png'
                  }
                  sx={{
                    height: '150px',
                    width: '150px',
                    borderRadius: '75px',
                  }}
                />
                <Typography
                  sx={{
                    mt: 1,
                    fontWeight: 'bold',
                    color: '#ffffff',

                  }}
                >
                  {referrUserInfo ? referrUserInfo.name : ''}
                </Typography>
                <Typography
                  sx={{
                    mb: 0.5,
                    color: "#ffffff",

                  }}
                >
                  {referrUserInfo ? referrUserInfo.occupation : ''}
                </Typography>
              </Box>
            )}

          </Stack>
          <Box sx={{ width: "100%", textAlign: "center", mt: { xs: 2, sm: 0 } }}>
            <Box
              component='img'
              src={'/static/drive_landing_page/logo.webp'}
              sx={{ width: "50%", maxWidth: "150px" }}
            />
          </Box>
        </Box>
      </section>


      {
        openYoutubeVideo && (
          <YoutubeVideoTutorialModel
            key={`open-youtube-video-model-${openYoutubeVideo}`}
            onClose={onCloseTutorialVideo}
            toggle={togglenTutorialVideo}
            youtubeVideoId={openYoutubeVideo}
          />
        )
      }

      <ContactFormModal
        onClose={onCloseContactForm}
        toggle={toggleContactForm}
        onSubmitSuccessRedirect={false}
        onSubmitSuccess={handleContactFormSubmitSuccess}
        source={ContactFormSourceType.WHAT_IS_KNOCARD}
      />

      <GroupNetworkModal
        onClose={onCloseGroup}
        toggle={toggleGroup}
        affiliateLink={referredByLink}
        userInfo={referrUserInfo}
      />

      <NetworkLandingPageConfirmationModal
        onClose={onCloseNetworkLandingPage}
        toggle={toggleNetworkLandingPage}
        link={calendarLink}
        userInfo={referrUserInfo}
      />
    </React.Fragment >
  );
};

export default DriveLandingPage;
