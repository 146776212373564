
import { collapseClasses } from "@mui/material";
import { ChatChannel, ChatMessage, ChatMessageMedia, ChatUser, SelectedChatMessageMedia } from "../store/chat/api/interface";
import { config } from "../utils/utils";
import { db } from "./FirebaseClient"
import { query, collection, where, onSnapshot, Unsubscribe, orderBy, doc, updateDoc, addDoc, Timestamp, setDoc } from "firebase/firestore";


export const getUserAllChats = (userId: number, onDataUpdate: (data: any[]) => void): Unsubscribe | null => {
    let chatCollection = collection(db, config.CHANNEL);
    let q = query(chatCollection, where('users', 'array-contains', userId));
    const unsubscribe = onSnapshot(q, (snapshot) => {
        const newData: any[] = [];
        snapshot.forEach((doc) => {
            newData.push({ id: doc.id, ...doc.data() });
        });
        onDataUpdate(newData);
    });
    return unsubscribe;
};


export const getChatMessages = (chatId: string, onDataUpdate: (data: any[]) => void): Unsubscribe | null => {

    if (chatId.length <= 0) {
        return null;
    }

    let chatCollection = collection(db, `${config.CHANNEL}/${chatId}/${config.MESSAGES_COLLECTION}`);
    let q = query(chatCollection, orderBy('time', 'asc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {

        const newData: any[] = [];
        snapshot.forEach((doc) => {
            newData.push({ id: doc.id, ...doc.data() });
        });
        onDataUpdate(newData);
    });
    return unsubscribe;
};

export const markAsAllMessageRead = (chatUser: ChatUser, userId: number) => {
    if (chatUser.chatId != null && chatUser?.chatChannelItem?.last_message?.from != userId) {
        updateUnreadMessageCount(chatUser, userId);
    }
};

const updateUnreadMessageCount = (chatUser: ChatUser, userId: number, unreadMessageCount = 0) => {
    if (chatUser.chatId != null && chatUser?.chatChannelItem?.last_message?.unread != null) {
        let chatCollection = collection(db, config.CHANNEL);
        let chatDoc = doc(chatCollection, chatUser.chatId);
        let updateChatUser = { ...chatUser.chatChannelItem, updated_at: Timestamp.now(), last_message: { ...chatUser.chatChannelItem.last_message, unread: unreadMessageCount } };
        updateDoc(chatDoc, updateChatUser);
    }
};

const updateLastMessage = (chatUser: ChatUser, userId: number, lastMessage: ChatMessage) => {
    if (chatUser.chatId != null) {
        let chatCollection = collection(db, config.CHANNEL);
        let chatDoc = doc(chatCollection, chatUser.chatId);
        let unreadmessageCount = 1;
        if (chatUser?.chatChannelItem?.last_message?.from == userId) {
            unreadmessageCount = unreadmessageCount + (chatUser?.chatChannelItem?.last_message?.unread ?? 0)
        }

        let updateChatUser = {
            updated_at: Timestamp.now(),
            ...chatUser.chatChannelItem, last_message: {
                message: lastMessage.text,
                from: userId,
                unread: unreadmessageCount,
                time: lastMessage.time
            }
        };
        updateDoc(chatDoc, updateChatUser);
    }
};


export const pinUnpinChat = async (chatUser: ChatUser, isPin: boolean, userId: number) => {
    let updateUserChat: any = null;
    let chatCollection = collection(db, config.CHANNEL);

    if (chatUser.chatId == undefined) {
        let newChatName = await createNewChat(chatUser, userId);
        if (newChatName != null) {
            updateUserChat = { ...newChatName };
        } else {
            return;
        }
    } else {
        if (chatUser.chatId != null && chatUser?.chatChannelItem?.last_message?.unread != null) {
            updateUserChat = { ...chatUser.chatChannelItem, updated_at: Timestamp.now() };
        }
    }

    if (updateUserChat.id == null) {
        return;
    }

    let chatDoc = doc(chatCollection, updateUserChat.id);

    if (isPin) {
        let pinList = Array.isArray(updateUserChat?.pined_by) ? [...(updateUserChat.pined_by ?? [])] : [];
        pinList.push(userId);
        updateUserChat.pined_by = pinList;
    } else {
        updateUserChat.pined_by = [...(Array.isArray(updateUserChat?.pined_by) ? updateUserChat.pined_by ?? [] : [])].filter((item) => item != userId);
    }

    if (updateUserChat != null) {
        updateDoc(chatDoc, updateUserChat);
    }

};


export const createNewChat = async (chatUser: ChatUser, userId: number): Promise<any> => {
    if (userId == 0) {
        return;
    }
    let newChatName: any = null;
    if (chatUser.id != null) {
        newChatName = (userId < (chatUser.id ?? 0)) ? `${userId}-${chatUser.id}` : `${chatUser.id}-${userId}`;
        let chatCollection = collection(db, config.CHANNEL);
        let chatDoc = doc(chatCollection, newChatName);
        let newChannel = {
            created_at: Timestamp.now(),
            updated_at: Timestamp.now(),
            users: [userId, chatUser.id],
            pined_by: [],
            id: newChatName,
            last_message: {
                message: '',
                from: 0,
                unread: 0,
                time: Timestamp.now()
            }
        }
        await setDoc(chatDoc, newChannel);
    }
    return newChatName;
};



export const sendNewChatMessage = async (chatUser: ChatUser, userId: number, message: string, chatMessageMedia: ChatMessageMedia[], isShared = false): Promise<ChatMessage> => {


    if (chatUser.chatId == undefined) {
        let newChatName = await createNewChat(chatUser, userId);
        if (newChatName != null) {
            chatUser.chatId = newChatName;
        }
    }

    let newMessage: ChatMessage = {
        time: Timestamp.now(),
        media: chatMessageMedia,
        from: userId,
        to: chatUser.id ?? 0,
        text: message,
        isShared: isShared
    }

    let chatCollection = collection(db, `${config.CHANNEL}/${chatUser.chatId}/${config.MESSAGES_COLLECTION}`);

    await addDoc(chatCollection, newMessage);
    updateLastMessage(chatUser, userId, newMessage);
    return newMessage;
}



export const sendKnoCardLinkRequestMessage = async (userId: number, toUserId: number, message: string): Promise<ChatMessage> => {

    let newChatName = (userId < (toUserId ?? 0)) ? `${userId}-${toUserId}` : `${toUserId}-${userId}`;
    try {
        let chatCollection = collection(db, `${config.CHANNEL}/${newChatName}`);
    } catch (e) {
        newChatName = await createNewChat({ id: toUserId }, userId);
    }

    let newMessage: ChatMessage = {
        time: Timestamp.now(),
        media: [],
        from: userId,
        to: toUserId,
        text: message,
        isShared: false,
        type: 'KnoCardLinkRequest'
    }

    let chatCollection = collection(db, `${config.CHANNEL}/${newChatName}/${config.MESSAGES_COLLECTION}`);

    await addDoc(chatCollection, newMessage);
    updateLastMessage({ chatId: newChatName }, userId, newMessage);
    return newMessage;

}
