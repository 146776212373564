import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { ChatUserResponse } from './interface';
import { toastHandler } from '../../../utils/toastHandler';

export const getChatUsers = createAsyncThunk(
  'chat/getChatUsers',
  async (data: { user_ids: number[] },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post<ChatUserResponse>(`/api/user/contacts`, data);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const sendChatMessageNotification = createAsyncThunk(
  'chat/sendChatMessageNotification',
  async (data: {
    sender_id: number,
    receiver_id: number[],
    notification_type: string,
    message: string,
  },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`/api/notifications/message`, data);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);
