import { Avatar, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

const RenderProfilePhoto: React.FC<{ profile_picture: string; show_profile_picture: number; sx: SxProps<Theme> }> = ({
    profile_picture,
    show_profile_picture,
    sx,
}) => {
    if (show_profile_picture !== 0)
      return (
        <Avatar
          alt='Profile photo'
          src={
            profile_picture
              ? profile_picture
              : '/static/images/placeholder_profile.png'
          }
          sx={sx}
        />
      );

    return <></>;
    // return <Avatar alt="Profile photo" src="/static/images/placeholder_profile.png" sx={sx} />;
};

export default RenderProfilePhoto;
