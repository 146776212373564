import { createSlice } from '@reduxjs/toolkit';
import { getMediaLibrary, getMediaLibraryCategory } from './api';
import { initialState } from './api/interface';

const mediaLibrarySlice = createSlice({
  name: 'mediaLibrary',
  initialState,
  reducers: {
    reset: () => initialState,
    setMediaLibraryTag: (state, action) => {
      state.mediaTag = action.payload;
    },
    resetMediaLibrary: (state, action) => {
      state.mediaLibraryMediaModelList = [];
      state.mediaPage = 0;
      state.isLoadMoreMedia = true;
      state.mediaLibraryType = undefined;
    },
    setMediaLibraryType: (state, action) => {
      state.mediaLibraryType = action.payload;
      state.mediaLibraryMediaModelList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMediaLibraryCategory.pending, (state) => {
      state.isCategoryLoading = true;
    });
    builder.addCase(getMediaLibraryCategory.fulfilled, (state, action) => {

      if ((action.payload.data.data.current_page ?? 0) == 1) {
        state.mediaLibraryCategoryList = action.payload.data?.data?.data ?? [];
      } else {
        state.mediaLibraryCategoryList.push(...(action.payload.data?.data?.data ?? []));
      }

      state.isLoadMoreCategory = ((action.payload.data.data.last_page ?? 0) > (action.payload.data.data.current_page ?? 0))
      state.categoryPage = action.payload.data.data.current_page ?? 1;
      state.isCategoryLoading = false;
    });
    builder.addCase(getMediaLibraryCategory.rejected, (state, _) => {
      state.isCategoryLoading = false;
    });



    builder.addCase(getMediaLibrary.pending, (state) => {
      state.isMediaLoading = true;
    });
    builder.addCase(getMediaLibrary.fulfilled, (state, action) => {

      if ((action.payload.data.data.current_page ?? 0) == 1) {
        state.mediaLibraryMediaModelList = action.payload.data.data?.data ?? [];
      } else {
        state.mediaLibraryMediaModelList.push(...(action.payload.data?.data?.data ?? []));
      }

      state.isLoadMoreMedia = ((action.payload.data.data.last_page ?? 0) > (action.payload.data.data.current_page ?? 0))
      state.mediaPage = action.payload.data.data.current_page ?? 1;
      state.isMediaLoading = false;
    });
    builder.addCase(getMediaLibrary.rejected, (state, _) => {
      state.isMediaLoading = false;
    });



  },
});

export const { reset, setMediaLibraryTag, resetMediaLibrary, setMediaLibraryType } = mediaLibrarySlice.actions;
export default mediaLibrarySlice.reducer;
