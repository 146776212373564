import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Stack } from '@mui/system';
import { Transition } from './Transition';
import { useAppDispatch, useAppSelector } from '../store';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserInfo } from '../store/userInfo/api/interface';
import { Box, Button, Typography } from '@mui/material';

interface IProps {
  toggle: boolean;
  onClose: () => void;
  userInfo: UserInfo | undefined;
  link: string;
}

const NetworkLandingPageConfirmationModal: React.FC<IProps> = ({ toggle, onClose, userInfo, link }) => {

  const [isWatched, setIsWatched] = useState<Boolean | undefined>();
  useEffect(() => {
    setIsWatched(undefined);
  }, [toggle]);

  const handleBookNow = () => {
    window.open(`${link}`) || window.location.replace(`${link}`);
    onClose()
  }

  return (
    <Dialog
      fullWidth
      open={toggle}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onClose();
      }}

      PaperProps={{
        sx: {
          background: 'linear-gradient(0deg, #0A3594 0%, #52D9DE 100%)',
          borderRadius: '25px !important',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        },
      }}

    >

      <DialogContent sx={{
        background: 'linear-gradient(0deg, #0F5B8F 0%, #052332 100%)',
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        m: 0.5,
        borderRadius: '25px !important',
      }}>
        <Stack direction='column' spacing={2}>


          <Box
            component={'img'}
            src={
              userInfo?.profile_picture
                ? userInfo?.profile_picture
                : '/static/images/placeholder_profile.png'
            }
            sx={{
              height: '100px',
              width: '100px',
              borderRadius: '75px',
            }}
          />

          <Box sx={{ background: 'linear-gradient(270deg, #0A3594 0%, #52D9DE 100%)', borderRadius: '25px !important', p: 0.4 }}>
            <Box sx={{ background: "#082637", borderRadius: '25px !important', }}>
              <Typography sx={{ color: "#ffffff", fontSize: "larger", textAlign: "center", p: 1 }}>Have you watched the videos on the previous page?</Typography>
              <Box sx={{ textAlign: "center" }}>
                <Button
                  sx={{
                    textTransform: 'unset',
                    fontWeight: "bold",
                    backgroundImage: 'linear-gradient(0deg, #0C3CA1 0%, #44C0CF 100%)',
                    color: "#000000",
                    borderRadius: "30px",
                    fontSize: "small",
                    "&:hover": {
                      background: "#ffffff",
                    },
                    width: "80%",
                    m: 1
                  }}
                  onClick={() => setIsWatched(true)}
                >
                  Yes, I watched the videos
                </Button>
                <Button
                  sx={{

                    textTransform: 'unset',
                    fontWeight: "bold",
                    backgroundImage: 'linear-gradient(0deg, #0C3CA1 0%, #44C0CF 100%)',
                    color: "#000000",
                    borderRadius: "30px",
                    fontSize: "small",
                    "&:hover": {
                      background: "#ffffff",
                    },
                    width: "80%",
                    m: 1,
                    mt: 0,
                    mb: 2
                  }}
                  onClick={onClose}
                >
                  No. not as of yet
                </Button>
              </Box>
            </Box>
          </Box>

          {isWatched && <Box sx={{ textAlign: "center" }}>
            <Typography sx={{ color: "#ffffff", fontWeight: "bold", fontSize: "larger", textAlign: "center", p: 0.8, pt: 1 }}>Let's have a chat and get you on my calendar!</Typography>
            <Button
              sx={{

                textTransform: 'unset',
                fontWeight: "bold",
                backgroundImage: 'linear-gradient(0deg, #ffffff 0%, #EFBE24 100%)',
                color: "#000000",
                borderRadius: "30px",
                fontSize: "small",
                "&:hover": {
                  background: "#ffffff",
                },
                width: "80%",
                m: 1,
                mt: 0,
                mb: 2
              }}
              onClick={handleBookNow}
            >
              Book Now
            </Button>
          </Box>}

        </Stack>

      </DialogContent>
    </Dialog>
  );
};

export default NetworkLandingPageConfirmationModal;
