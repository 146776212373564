import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Avatar, Box, IconButton, useMediaQuery } from '@mui/material';
import { ContactPiplineLead } from '../store/engagePipeline/api/interface';
import { UserInfo } from '../store/userInfo/api/interface';
import Iconify from './Iconify';
import { useAppDispatch } from '../store';
import { clearContactPiplineLead } from '../store/engagePipeline/engagePipelineSlice';
import { approveContactPiplineLeadConsent } from '../store/engagePipeline/api';


interface IProps {
  contactPiplineLead: ContactPiplineLead;
  userInfo: UserInfo;
}



export const ConsentNavBar: React.FC<IProps> = ({ contactPiplineLead, userInfo }) => {
  const matches = useMediaQuery('(max-width:745px)');
  const dispatch = useAppDispatch();
  const handleConnectAction = (isApprove: boolean) => {
    if (isApprove) {
      dispatch(approveContactPiplineLeadConsent({
        contact_id: contactPiplineLead.target_id,
        elid: contactPiplineLead.id
      }))
    } else {
      dispatch(clearContactPiplineLead({}));
    }
  }


  return (
    <AppBar position='static' sx={{
      backgroundImage: 'url("/static/images/header_bg.svg")',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }}>
      <Toolbar sx={{ padding: matches ? '5px' : '5px 30px  ', }}>

        <Avatar
          alt="profile picture"
          sx={{
            width: "50px",
            height: "50px",
            background: '#c4cdd500',
            margin: '10px 10px  10px  10px',
          }}
        >
          <img
            src={userInfo.profile_picture.length > 0 ? userInfo.profile_picture : '/static/images/placeholder_profile.png'}
            alt='profile pic'
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '50%',
              border: '2px solid white'
            }}
          />
        </Avatar>

        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          justifyContent='center'
          flex={1}
        >
          <Typography variant='body2' sx={{ fontSize: "1.0rem" }}>Would you like to connect to {userInfo.first_name}?</Typography>
        </Box>
        <Box
          flexDirection='row'
        >
          <IconButton
            onClick={() => handleConnectAction(true)}
            edge='end'
            sx={{
              color: "#419043", backgroundColor: "#ffffff",
              '&:focus, &:hover': {
                backgroundColor: "#ffffff"
              }
              , mr: 1
            }}
          >
            <Iconify
              icon={
                'game-icons:check-mark'
              }
            />
          </IconButton>

          <IconButton
            onClick={() => handleConnectAction(false)}
            edge='end'
            sx={{
              color: "#df2f21", backgroundColor: "#ffffff",
              '&:focus, &:hover': {
                backgroundColor: "#ffffff"
              },
              mr: 1
            }}
          >
            <Iconify
              icon={
                'entypo:cross'
              }
            />
          </IconButton>

        </Box>
      </Toolbar>
    </AppBar>
  );
};
