import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import styles from '../styles/Reports.module.css';
import { UserInfo } from '../store/userInfo/api/interface';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useCallback, useEffect, useRef, useState } from 'react';
import Iconify from './Iconify';
import useToggle from '../hooks/useToggle';
import AddSessionNoteModal from './AddSessionNoteModal';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../store';
import {
  deleteKnoCardLinkSessionNoteRequest,
  getKnoCardLinkSessionNotePerUserRequest,
} from '../store/knocardLinkSessionNote/api';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { KnoCardLinkSessionNoteItem } from '../store/knocardLinkSessionNote/api/interface';
import ConfirmationModel from './modal/ConfirmationModel';

import { noteDeleted } from '../store/knocardLinkSessionNote/knocardLinkSessionNoteSlice';
import moment from 'moment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { showSuccessMessage } from '../utils/utilMethods';
import { AppColors } from '../utils/utils';

interface IProps {
  selectedSessionUser: UserInfo | null | undefined;
  setShowSessionNotesTab: React.Dispatch<React.SetStateAction<boolean>>;
}

const SessionNoteTab: React.FC<IProps> = ({
  selectedSessionUser,
  setShowSessionNotesTab,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const observer = useRef<IntersectionObserver>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeMenuIndex, setActiveMenuIndex] = useState<any>(0);
  const menuOpen = Boolean(anchorEl);
  const { toggle, onOpen, onClose } = useToggle();
  const {
    toggle: toggleDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useToggle();
  const [selectedNoteDetails, setSelectedNoteDetails] =
    useState<KnoCardLinkSessionNoteItem>();
  const [selectedNoteDelete, setSelectedNoteDelete] =
    useState<KnoCardLinkSessionNoteItem>();

  const { data, isLoading, hasMore, page } = useAppSelector(
    (state) => state?.knocardLinkSessionNote
  );

  const handleSessionNotes = (isLoadMore: boolean) => {
    dispatch(
      getKnoCardLinkSessionNotePerUserRequest({
        userId: selectedSessionUser?.id ?? 0,
        page: page + 1,
      })
    ).unwrap();
  };

  useEffect(() => {
    handleSessionNotes(false);
  }, []);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  const lastElementRef = useCallback(
    // (*)
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          dispatch(
            getKnoCardLinkSessionNotePerUserRequest({
              userId: selectedSessionUser?.id ?? 0,
              page: page + 1,
            })
          );
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    idx: number
  ) => {
    setAnchorEl(event.currentTarget);
    setActiveMenuIndex(idx);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditMenu = (item: KnoCardLinkSessionNoteItem) => {
    setSelectedNoteDetails(item);
    setAnchorEl(null);
    onOpen();
  };

  const handleDeleteMenu = (item: KnoCardLinkSessionNoteItem) => {
    setSelectedNoteDelete(item);
    setAnchorEl(null);
    onOpenDelete();
  };

  const onDeleteNoteConfirm = async () => {
    onCloseDelete();
    if (selectedNoteDelete != undefined) {
      try {
        var result = await dispatch(
          deleteKnoCardLinkSessionNoteRequest({ id: selectedNoteDelete.id })
        ).unwrap();
        if (result.data.status == 'Success') {
          dispatch(noteDeleted(selectedNoteDelete));
          showSuccessMessage('Note deleted successfully.');
          setSelectedNoteDelete(undefined);
        }
      } catch (error: any) { }
    }
  };

  useEffect(() => {
    if (!toggle) {
      setSelectedNoteDetails(undefined);
    }
  }, [toggle]);

  return (
    <Container maxWidth='xl'>
      <Box
        className={styles.mainContent}
        marginTop={3}
      >
        <Box className={styles.mainContentHeader}>

          <Box
            sx={{
              width: '100%',
              background:
                `linear-gradient(0deg, ${AppColors.primaryColor} 0%, ${AppColors.secondryColor} 100%)`,
              p: 2,
              borderRadius: "10px"
            }}
          >
            <Stack
              direction={'row'}
              spacing={2}
              alignContent={'center'}
              alignItems={'center'}
            >
              <IconButton
                onClick={() => setShowSessionNotesTab(false)}
              >
                <ArrowBackIcon sx={{ color: AppColors.whiteColor }} />
              </IconButton>
              <Avatar
                src={
                  selectedSessionUser?.profile_picture ??
                  '/static/images/placeholder_profile.png'
                }
                sx={{ height: '40px', width: '40px' }}
              />
              <Box
              >
                <Typography sx={{ color: AppColors.whiteColor, fontWeight: "500", fontSize: "1.3rem" }}
                >{selectedSessionUser?.first_name ?? ''}{' '}
                  {selectedSessionUser?.last_name ?? ''}</Typography>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Grid
          xs={12}
          md={12}
          container
          direction='row'
          sx={{
            width: '100%',
            pl: 2,
            pr: 2,
          }}
        >
          <Grid
            sm={12}
            md={3}
            container
            direction='column'
            sx={{
              mt: 2,
              width: '100%',
              textAlign: 'start',
              pr: 0.5,
              pl: 0.5,
            }}
          >
            <Box
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            >
              <List
                component='nav'
                aria-label='main mailbox folders'
              >
                <ListItemButton
                  selected={selectedIndex === 0}
                  onClick={(event) => handleListItemClick(event, 0)}
                  sx={{
                    backgroundColor: `${AppColors.primaryColor} !important`,
                    color: AppColors.whiteColor,
                    borderRadius: "10px"
                  }}
                >
                  <ListItemText primary='Session Notes' sx={{ textAlign: "center" }} />
                </ListItemButton>
                <Divider />
              </List>
            </Box>
          </Grid>
          <Grid
            sm={12}
            md={9}
            container
            direction='column'
            sx={{
              mt: 2,
              width: '100%',
              textAlign: 'start',
              pr: 0.5,
              pl: 0.5,
              mb: 2.5,
            }}
          >
            <Stack
              direction={'row'}
              spacing={2}
              justifyContent={'flex-end'}
              alignContent={'center'}
              alignItems={'center'}
            >
              <Button
                variant='contained'
                sx={{
                  background: AppColors.primaryColor,
                  "&:hover": {
                    background: AppColors.primaryColor
                  },
                  borderRadius: "50px",
                  pl: 0.5,
                  pr: 2,
                  pt: 0,
                  pb: 0
                }}
                startIcon={<AddCircleIcon sx={{ color: "#ffffff", height: 35, width: 35 }} />}
                onClick={() => onOpen()}
              >
                Note
              </Button>
            </Stack>
            <Box sx={{
              mt: 2,
              mb: 1,
              pt: 1,
              pb: 1,
              border: `solid 2px ${AppColors.strokeColor}`,
              borderRadius: "10px",
              backgroundColor: AppColors.grayZeroColor,
              minHeight: "60vh"
            }}>

              {data &&
                data.map((item, index: number) => (
                  <><Box
                    sx={{ mt: 0.5, mb: 0.5 }}
                    ref={index + 1 === data?.length ? lastElementRef : undefined}
                  >
                    <Stack
                      direction='column'
                      gap={1}
                    >
                      <Box
                        display='flex'
                        minHeight='60px'
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          alignItems='flex-start'
                          justifyContent='center'
                          flex={1}
                          ml={3}
                        >
                          <Typography
                            variant='body1'
                            component='div'
                            color='inherit'
                            sx={{
                              mt: 1,
                              color: AppColors.blackColor
                            }}
                          >
                            {item?.note}
                          </Typography>
                          <Typography
                            variant='body2'
                            component='div'
                            display={'flex'}
                            justifyContent={'flex-end'}
                            sx={{ width: '100%', mb: 1, color: AppColors.grayThreeColor }}
                          >
                            {moment(item?.created_at).format('MMM DD, YYYY')}
                          </Typography>
                        </Box>

                        <Box width='50px'>
                          <IconButton
                            aria-label='more'
                            id='long-button'
                            aria-controls={
                              menuOpen && activeMenuIndex == index
                                ? 'long-menu'
                                : undefined
                            }
                            aria-expanded={
                              menuOpen && activeMenuIndex == index
                                ? 'true'
                                : undefined
                            }
                            aria-haspopup='true'
                            onClick={(event) => handleMenuClick(event, index)}
                          >
                            <MoreVertIcon sx={{ color: AppColors.blackColor }} />
                          </IconButton>
                          <Menu
                            id='long-menu'
                            MenuListProps={{
                              'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={menuOpen && activeMenuIndex == index}
                            onClose={handleMenuClose}
                            PaperProps={{
                              style: {
                                maxHeight: 48 * 4.5,
                                width: '20ch',
                              },
                            }}
                          >
                            <MenuItem
                              key={`edit-knocard-session-note-${index}`}
                              onClick={() => handleEditMenu(item)}
                            >
                              Edit
                            </MenuItem>
                            <MenuItem
                              key={`delete-knocard-session-note-${index}`}
                              onClick={() => handleDeleteMenu(item)}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </Box>
                      </Box>
                    </Stack>
                  </Box> <Divider></Divider></>
                ))}
              {isLoading && (
                <Stack
                  direction={'row'}
                  justifyContent={'center'}
                  sx={{ m: 1 }}
                >
                  <CircularProgress />
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <AddSessionNoteModal
        selectedSessionUser={selectedSessionUser}
        onClose={onClose}
        toggle={toggle}
        selectedNoteDetails={selectedNoteDetails}
      />

      <ConfirmationModel
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        title='Delete Note'
        message='Are you sure! Do you want to delete this note?'
        onConfirm={onDeleteNoteConfirm}
        onClose={onCloseDelete}
        toggle={toggleDelete}
      />
    </Container>
  );
};

export default SessionNoteTab;
