import * as Yup from 'yup';
import { urlRegex, youTubeVideoRegex } from '../constant';



const externalLinkSchema = Yup.object().shape({
    title: Yup.string().required('Title is required.'),
    description: Yup.string().required('Description is required.'),
    link: Yup.string().required('Link is required.').matches(urlRegex, "Link should be a valid URL"),
    icon: Yup.string().required('Icon is required.'),
    color: Yup.string().required('Color is required.'),
});
export default externalLinkSchema;
