import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './api/interface';
import { getKnoCardLinkSessionNotePerUserRequest, postKnoCardLinkSessionNoteRequest, patchKnoCardLinkSessionNoteRequest } from './api';

const knocardLinkSessionNoteSlice = createSlice({
  name: 'knocardLinkSessionNote',
  initialState,
  reducers: {
    reset: () => initialState,
    noteDeleted: (state, action) => {
      let array = [...(state.data ?? [])];
      let position = -1;
      array.forEach((item, index) => {
        if (item.id == action.payload.id) {
          position = index;
        }
      });

      if (position !== -1 && state.data != null) {
        array.splice(position, 1);
        state.data = array;
      }
    },
    newNoteCreated: (state, action) => {
      state.data.unshift(action.payload);
    },
    noteUpdated: (state, action) => {
      state.data.forEach((item, index) => {
        if (action.payload.id == item.id) {
          state.data[index] = action.payload;
        }
      })

    },
  },
  extraReducers: (builder) => {
    builder.addCase(getKnoCardLinkSessionNotePerUserRequest.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getKnoCardLinkSessionNotePerUserRequest.fulfilled, (state, action) => {
      state.data = (action?.payload?.data?.data?.current_page != 1) ? [...(state?.data ?? []), ...(action?.payload?.data?.data?.data ?? [])] : action?.payload?.data?.data?.data ?? [];
      state.hasMore = action?.payload?.data?.data?.data?.length >= 10;
      state.page = action?.payload?.data?.data?.current_page ?? 0;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getKnoCardLinkSessionNotePerUserRequest.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset, noteDeleted, newNoteCreated, noteUpdated } = knocardLinkSessionNoteSlice.actions;
export default knocardLinkSessionNoteSlice.reducer;
