import React, { useEffect, useState } from 'react';
import Phone from '../../assets/phone.svg';
import { Box, Grid, ListItem, ListItemIcon, ListItemText, Paper, Rating, Stack, Typography } from '@mui/material';
import Mail from '../../assets/mail.svg';
import Location from '../../assets/location.svg';
import Brief from '../../assets/briefcase.svg';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { useAppSelector } from '../../store';
import { checkPhoneNumber } from '../../utils/checkPhoneNumber';
import moment from 'moment';
import { checkIsProProOrProOneOrProPlusUser } from '../../utils/checkPro';
import { AppColors } from '../../utils/utils';
import { openLinkOnNewTab } from '../../utils/utilMethods';
import VbcHeadLine from '../VbcComponents/VbxHeadLine';

interface InfoComponentProps { }


export default function InfoComponent(props: InfoComponentProps) {
  const { ...other } = props;
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { data: userInfo } = useAppSelector((state) => state.userInfo);
  const contactNumber = checkPhoneNumber(
    userInfo?.phone_number,
    userInfo?.phone_country_code,
    userInfo?.show_phone_number,
    userInfo?.company?.business_phone
  );
  const mergedAddress = `${(userInfo?.street1 ?? '').length > 0 ? userInfo?.street1 + ', ' : ''
    }${(userInfo?.street2 ?? '').length > 0 ? userInfo?.street2 + ', ' : ''}${userInfo?.city ?? ''
    }, ${userInfo?.state ?? ''}, ${userInfo?.zip_code ?? ''}`;
  const occupation =
    useAppSelector((state) => state.userInfo.data?.occupation) ?? '';

  return (
    <>
      {isSmallScreen && (
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            marginTop: '10px',
          }}
        >
          <VbcHeadLine headLine={"Contact Info"} />

          {contactNumber && (<ListItem sx={{ pl: "10px" }} onClick={() => openLinkOnNewTab(`tel:${contactNumber}`)}>
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <img
                src={Phone}
                alt='Phone'
                height='25px'
                width='25px'
              />
            </ListItemIcon>
            <ListItemText primary={contactNumber} sx={{
              color: AppColors.grayThreeColor,
              marginLeft: '3px',
              marginTop: '3px',
              fontSize: 'medium',
              wordWrap: 'break-word'
            }}
            />
          </ListItem>)}


          <ListItem
            sx={{ pl: "10px" }}
            onClick={() => openLinkOnNewTab(`mailto: ${userInfo?.email}`)}
          >
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <img
                src={Mail}
                alt='Mail'
                height='25px'
                width='25px'
              />
            </ListItemIcon>
            <ListItemText primary={userInfo?.email} sx={{
              color: AppColors.grayThreeColor,
              marginLeft: '3px',
              marginTop: '3px',
              fontSize: 'medium',
              wordWrap: 'break-word'
            }}
            />
          </ListItem>


          <ListItem
            sx={{ pl: "10px" }}
            onClick={() => openLinkOnNewTab(userInfo?.latitude != null &&
              userInfo?.longitude != null &&
              Number(userInfo?.latitude) !== 0 &&
              Number(userInfo?.longitude) !== 0
              ? `https://www.google.com/maps/@${userInfo?.latitude ?? ''
              },${userInfo?.longitude}`
              : `https://maps.google.com/?q=${mergedAddress}`)}
          >
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <img
                src={Location}
                alt='Location'
                height='25px'
                width='25px'
              />
            </ListItemIcon>
            <ListItemText primary={mergedAddress} sx={{
              color: AppColors.grayThreeColor,
              marginLeft: '3px',
              marginTop: '3px',
              fontSize: 'medium',
              wordWrap: 'break-word'
            }}
            />
          </ListItem>


          <ListItem
            sx={{ pl: "10px" }}>
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <img
                src={Brief}
                alt='Brief'
                height='25px'
                width='25px'
              />
            </ListItemIcon>
            <ListItemText primary={occupation} sx={{
              color: AppColors.grayThreeColor,
              marginLeft: '3px',
              marginTop: '3px',
              fontSize: 'medium',
              wordWrap: 'break-word'
            }}
            />
          </ListItem>
        </Grid>
      )}
    </>
  );
}
