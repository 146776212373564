import { createAsyncThunk } from '@reduxjs/toolkit';
import { toastHandler } from '../../../utils/toastHandler';
import { axiosInstance } from '../../../client';

export const findAffiliateGroup = createAsyncThunk(
  'affiliateGroup/findAffiliateGroup',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/api/user/affiliate-group');

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const findAffiliateGroupPricing = createAsyncThunk(
  'affiliateGroup/findAffiliateGroupPricing',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/api/user/affiliate-group-pricing');

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);
