import React, { FC, useEffect, useState } from 'react';
import { Avatar, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';

import { useCollapse } from 'react-collapsed';
import styles from '../../styles/Reports.module.css';
import {
  GetShareSocialPostsRequest,
  ShareSocialPostDetail,
  ShareSocialPostDetailItem,
  ShareSocialPostItem as ShareSocialPostItemModel,
} from '../../store/reports/api/interface';
import { dateFormate } from '../../utils/format';
import { AppDispatch, useAppSelector } from '../../store';

import { getDaysFromFilter } from '../../utils/daysConvertor';
import { getShareSocialPostDetail } from '../../store/reports/api';
import { useDispatch } from 'react-redux';
import { MediaType } from '../../enum';
import { getItem } from '../../utils/localStorage';
import { AppColors, config } from '../../utils/utils';

const ShareSocialPostUserItem: FC<{
  shareSocialPostDetailItem: ShareSocialPostDetailItem;
  index: number;
}> = ({ shareSocialPostDetailItem, index }) => {
  const dateFormatted =
    dateFormate(shareSocialPostDetailItem.created_at ?? '') ?? '';
  return (
    <Box className={styles.subitemContainer} sx={{
      background: AppColors.grayZeroColor,
      border: `solid 1px ${AppColors.strokeColor}`
    }}>
      <Box
        component={'img'}
        alt='pic'
        src={`${shareSocialPostDetailItem.user?.profile_picture ??
          '/static/images/placeholder_profile.png'
          }`}
        className={styles.knoSmilie}
        sx={{ height: '40px', width: '40px' }}
      />

      <Box
        className={styles.itemContainerContent}
        sx={{ ml: 1, }}
      >
        <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
          {shareSocialPostDetailItem.user?.first_name ?? ''}{' '}
          {shareSocialPostDetailItem.user?.last_name ?? ''}
        </Typography>
        <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>
          {`${shareSocialPostDetailItem.share_to_feed != null
            ? `Referred ${`${shareSocialPostDetailItem.share_to_feed?.first_name ?? ''
            } ${shareSocialPostDetailItem.share_to_feed?.last_name ?? ''}`}`
            : 'Share via link'
            }`}
        </Typography>
      </Box>
      <Box
        className={styles.itemContainerContent}
        alignItems={'end'}
        sx={{ pl: 1 }}
      >
        <Box
          sx={{
            fontSize: '12px',
            textAlign: 'right',
            color: 'gray',
            fontFamily: 'Lato',
          }}
        >
          {dateFormatted}
        </Box>
      </Box>
    </Box>
  );
};

interface IProps {
  shareSocialPostItemModel: ShareSocialPostItemModel;
  index: number;
  selectedTimeFilter: string;
  selectedReportUser?: number;
}

const ShareSocialPostItem: React.FC<IProps> = ({
  shareSocialPostItemModel,
  index,
  selectedTimeFilter,
  selectedReportUser,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);
  const [isExpanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const [shareSocialPostCounts, setShareSocialPostCounts] =
    useState<ShareSocialPostDetail | null>(null);
  const accessToken = getItem(config.AUTH_KEY);
  useEffect(() => {
    if (isExpanded && (shareSocialPostCounts?.data?.length ?? 0) === 0) {
      fetchShareSocialPostDetail(false);
    }
  }, [isExpanded]);

  const fetchShareSocialPostDetail = async (isLoadMore: boolean) => {
    const data: GetShareSocialPostsRequest = {
      user_id: selectedReportUser ? selectedReportUser : user?.id ?? 0,
      api_token: accessToken ?? '',
      days: getDaysFromFilter(selectedTimeFilter),
    };
    try {
      setIsLoading(true);
      const response: any = await dispatch(
        getShareSocialPostDetail({
          data: data,
          feedId: shareSocialPostItemModel.company_feed_id ?? 0,
          page: (shareSocialPostCounts?.current_page ?? 0) + 1,
        })
      ).unwrap();
      if (
        response.status === 200 &&
        response.data.status &&
        response.data.status === 'success'
      ) {
        let oldShareSocialPostDetailItems = [];
        if (response.data.data.current_page == 1) {
          oldShareSocialPostDetailItems = response.data.data.data;
        } else {
          oldShareSocialPostDetailItems = [
            ...(shareSocialPostCounts?.data ?? []),
            ...response.data.data.data,
          ];
        }
        setShareSocialPostCounts({
          ...response.data.data,
          data: oldShareSocialPostDetailItems,
        });
      }
    } catch (e) { }
    setIsLoading(false);
  };

  const getMediaImage = () => {
    let imageUrl = '/static/images/placeholder_profile.png';

    if (shareSocialPostItemModel.company_feed?.image != null) {
      imageUrl = shareSocialPostItemModel.company_feed?.image ?? '';
    } else {
      let content =
        shareSocialPostItemModel?.company_feed?.contents &&
          (shareSocialPostItemModel?.company_feed?.contents?.length ?? 0) > 0
          ? shareSocialPostItemModel?.company_feed?.contents[0]
          : null;

      if (content != null) {
        imageUrl =
          content.type == MediaType.image && content.link != null
            ? content.link
            : content.thumbnail ?? '/static/images/placeholder_profile.png';
      }
    }
    return imageUrl;
  };

  return (
    <Box style={{ width: '100%' }}>
      <Box
        className={styles.itemContainer}
        sx={{
          background: AppColors.grayZeroColor,
          border: `solid 1px ${AppColors.strokeColor}`, mt: 0.6, borderRadius: "6px"
        }}
        {...getToggleProps({
          onClick: () => {
            setExpanded(!isExpanded);
          },
        })}
      >
        {/* <img
          src='/static/images/placeholder_profile.png'
          alt='knoImage'
          className={styles.knoSmilie}
        /> */}
        <img
          alt='pic'
          src={getMediaImage()}
          className={styles.knoSmilie}
        />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "1rem", fontWeight: 400 }}>
            {shareSocialPostItemModel.company_feed?.title ?? ''}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", color: AppColors.grayTwoColor }}>
            {`Shares ${shareSocialPostItemModel.total_share_count ?? 0}`}
          </Typography>
        </Box>

        {isExpanded ? (
          <img
            src='/static/images/arrow_down.svg'
            alt='chevron_down'
            className={styles.chevronDown}
            style={{ transform: `rotate(180deg)` }}
          />
        ) : (
          <img
            src='/static/images/arrow_down.svg'
            alt='chevron_down'
            className={styles.chevronDown}
          />
        )}
      </Box>
      <Box {...getCollapseProps()} sx={{ ml: 4 }}>
        {shareSocialPostCounts &&
          shareSocialPostCounts?.data.length > 0 &&
          shareSocialPostCounts.data.map((item, index) => (
            <ShareSocialPostUserItem
              shareSocialPostDetailItem={item}
              index={index}
            />
          ))}
        {shareSocialPostCounts &&
          (shareSocialPostCounts.current_page ?? 0) <
          (shareSocialPostCounts.last_page ?? 0) &&
          !isLoading && (
            <Stack
              direction={'row'}
              justifyContent={'center'}
              sx={{ m: 1 }}
            >
              <Button
                type='button'
                variant='contained'
                sx={{ m: 2, textTransform: 'initial' }}
                autoCapitalize={'none'}
                onClick={() => fetchShareSocialPostDetail(true)}
              >
                Load More
              </Button>
            </Stack>
          )}

        {isLoading && (
          <Stack
            direction={'row'}
            justifyContent={'center'}
            sx={{ m: 1 }}
          >
            {' '}
            <CircularProgress />
          </Stack>
        )}
      </Box>
    </Box >
  );
};

export default ShareSocialPostItem;
