import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Button, Grid, Paper, TextField, Typography, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/system";
import { Transition } from "../Transition";
import { useAppDispatch } from "../../store";
import React, { useEffect, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import { getCapitalizedString, showErrorMessage } from "../../utils/utilMethods";
import { isNotEmpty } from "../../utils/valid";
import { AppColors } from "../../utils/utils";

interface IProps {
    note: string;
    contactProfile: string;
    toggle: boolean;
    onClose: () => void;
    onSaveNote: (note: string) => void;
    onCancelNote: () => void;
}


interface IInitialState {
    note: string;
}

const AddPiplineNoteModal: React.FC<IProps> = ({ note, toggle, onClose, onSaveNote, onCancelNote, contactProfile }) => {

    const dispatch = useAppDispatch();

    const [currentDate, setCurrentDate] = useState<Date>(new Date());


    useEffect(() => {
        if (toggle) {
            const newDate = new Date();
            setCurrentDate(newDate);

            formik.setFieldValue("note", note.trim().length > 0 ? note : `${moment(currentDate).format("MMM DD, YYYY  hh:mm a")}`);

        }
    }, [toggle]);


    const formik = useFormik<IInitialState>({
        initialValues: {
            note: ""
        },
        onSubmit: async (values) => {
            try {
                if (isNotEmpty(values.note)) {
                    onSaveNote(values.note);
                    onClose();
                    formik.resetForm();
                } else {
                    showErrorMessage("Note is required.");
                }
            } catch (error: any) { }
        },
    });

    const onCancel = () => {
        formik.resetForm({});
        onCancelNote();
        onClose();
    };


    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'sm'}
                open={toggle}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    onCancel();
                }}
                aria-describedby="alert-dialog-slide-description"
                sx={{
                    mt: { md: "5%", sm: "25%", xs: "25%" },
                }}
                PaperProps={{
                    sx: {
                        background: AppColors.backgroundColor,
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        color: AppColors.blackColor,
                    }}
                >
                    Add/Edit Notes
                </DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                        zIndex: 100000,
                        color: 'white',
                        alignSelf: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CancelIcon sx={{ color: AppColors.grayTwoColor }} />
                </IconButton>
                <form onSubmit={formik.handleSubmit}>
                    <FormikProvider value={formik}>

                        <DialogContent sx={{ padding: '15px', minWidth: "100%" }} >
                            <Stack>

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                >
                                    <Stack
                                        flexDirection={'column'}
                                        sx={{
                                            width: "100%",
                                            position: 'relative',
                                            mb: 2,
                                            mt: 2
                                        }}
                                    >
                                        <Avatar src={contactProfile} sx={{ height: "50px", width: "50px", '& img': { borderRadius: '100%' }, }} />
                                        <Typography
                                            sx={{
                                                pt: 1,
                                                color: AppColors.grayThreeColor,
                                                textTransform: "capitalize",
                                                fontSize: '0.9rem',
                                                fontWeight: '400'
                                            }}
                                        >
                                            {`${moment(currentDate).format("MMMM DD")}`}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: AppColors.primaryColor,
                                                textTransform: "capitalize",
                                                fontSize: '0.9rem',
                                                fontWeight: '400'
                                            }}
                                        >
                                            {`${moment(currentDate).format("hh:mm a")}`}
                                        </Typography>
                                    </Stack>



                                    <Paper
                                        elevation={0}
                                        sx={{
                                            mt: 0,
                                            p: 0
                                        }}
                                    >

                                        <TextField
                                            id="note"
                                            name='note'

                                            sx={{

                                                color: AppColors.grayThreeColor,
                                                background: AppColors.grayZeroColor,
                                                borderRadius: "12px",
                                                p: 1.5,
                                                border: "none",
                                                "& .MuiOutlinedInput-root": {
                                                    p: 0
                                                },
                                                "& input": {
                                                    "&::placeholder": {
                                                        fontWeight: "bold",
                                                    },
                                                },
                                                "& .MuiOutlinedInput-root textarea": {
                                                    color: AppColors.grayThreeColor,
                                                },
                                                '&:active': {
                                                    outline: 'none',
                                                },
                                                '& fieldset': {
                                                    border: 'none',
                                                }
                                            }}
                                            placeholder="Type a Note...."
                                            onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                                            onChange={formik.handleChange}
                                            value={formik.values.note}
                                            multiline
                                            rows={4}
                                            maxRows={10}
                                            fullWidth
                                        />
                                    </Paper>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "end",
                                            mt: 2
                                        }}
                                    >
                                        <LoadingButton
                                            type="submit"
                                            loading={formik.isSubmitting}
                                            sx={{
                                                pr: 2,
                                                pl: 2,
                                                borderRadius: "15px",
                                                color: AppColors.whiteColor,
                                                fontSize: "1rem",
                                                background: AppColors.primaryColor,
                                                mr: 2,
                                                textTransform: 'inherit',
                                                "&:hover": {
                                                    background: AppColors.primaryColor,
                                                }
                                            }}
                                        >
                                            Save
                                        </LoadingButton>
                                        <Button
                                            sx={{

                                                borderRadius: "15px",
                                                color: AppColors.grayThreeColor,
                                                fontSize: "1rem",

                                                textTransform: 'inherit'
                                            }}
                                            onClick={onCancel}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>

                                </Grid>
                            </Stack>

                        </DialogContent>
                    </FormikProvider>
                </form>
            </Dialog >
        </>
    );
};

export default AddPiplineNoteModal;
