import { createSlice } from '@reduxjs/toolkit';
import { getCommunityFeed } from './api';
import { initialState } from './api/interface';

const communtiyFeedSlice = createSlice({
  name: 'communtiyFeed',
  initialState,
  reducers: {
    reset: () => initialState,
    setCommunityFeed: (state, action) => {
      state.data = {
        status: { code: 'success', description: '' },
        company_feed: action.payload,
      } as any;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCommunityFeed.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getCommunityFeed.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getCommunityFeed.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset, setCommunityFeed } = communtiyFeedSlice.actions;
export default communtiyFeedSlice.reducer;
