import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../../store';
import styles from '../../styles/Reports.module.css';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getDaysFromFilter } from '../../utils/daysConvertor';
import {
  getMediaSharePostCounts,
  getMediaSharePostReport,
} from '../../store/reports/api';
import MediaSharesItem from '../Card/MediaSharesItem';
import { MediaSharePostReportCount } from '../../store/reports/api/interface';
import { getItem } from '../../utils/localStorage';
import { config } from '../../utils/utils';

const CustomBoxider = styled(Divider)(({ theme }) => ({
  borderWidth: 2,
  borderColor: '#ff6e1d',
  width: '40%',
  marginRight: '25px',
}));

interface IProps {
  selectedTimeFilter: string;
  isFromKnoCardLink?: boolean;
  selectedReportUser?: number;
}

const MediaShares: React.FC<IProps> = ({
  selectedTimeFilter,
  isFromKnoCardLink,
  selectedReportUser,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);

  // const [mediaSharesPostCounts, setMediaSharesPostCounts] =
  //   useState<MediaSharePostReportCount | null>(null);

  const mediaShareReportData =
    useAppSelector((state) => state?.reports?.mediaShareReportData?.data) ?? [];
  const page =
    useAppSelector(
      (state) => state?.reports?.mediaShareReportData?.current_page
    ) ?? 0;
  const lastPage =
    useAppSelector(
      (state) => state?.reports?.mediaShareReportData?.last_page
    ) ?? 0;
  const isLoading = useAppSelector((state) => state?.reports?.isLoading) ?? 0;
  const accessToken = getItem(config.AUTH_KEY);

  const handleMediaShares = (isLoadMore: boolean) => {
    // dispatch(
    //   getMediaSharePostCounts({
    //     data: {
    //       user_id: selectedReportUser ? selectedReportUser : user?.id ?? 0,
    //       days: getDaysFromFilter(selectedTimeFilter),
    //       ...(isFromKnoCardLink && { isFromKnoCardLink: true }),
    //     },
    //   })
    // )
    //   .unwrap()
    //   .then((response) => {
    //     // try {
    //     //   if (
    //     //     response.status === 200 &&
    //     //     response.data.status &&
    //     //     response.data.status === 'success'
    //     //   ) {
    //     //     setMediaSharesPostCounts(response.data.data);
    //     //   }
    //     // } catch (e) { }
    //   });

    dispatch(
      getMediaSharePostReport({
        data: {
          user_id: selectedReportUser ? selectedReportUser : user?.id ?? 0,
          days: getDaysFromFilter(selectedTimeFilter),
          api_token: accessToken,
          ...(isFromKnoCardLink && { isFromKnoCardLink: true }),
        },
        page: isLoadMore ? page + 1 : 1,
      })
    ).unwrap();
  };

  useEffect(() => {
    handleMediaShares(false);
  }, [selectedTimeFilter]);

  return (
    <>
      <Box className={styles.resultsContainerHeader}>
        <Typography className={styles.activityBtn}>Media Shares</Typography>
      </Box>

      {/* {mediaSharesPostCounts && (
        <Stack
          width={'100%'}
          my={1}
        >
          <Typography
            variant='h6'
            align='center'
            sx={{ color: "#ffffff" }}
          >
            How you shared media
          </Typography>
          <Grid
            container
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid
              item
              xs
              md
            >
              <Typography variant='body1'>Community</Typography>
            </Grid>
            <CustomBoxider />
            <Grid
              item
              xs={1}
              md={1}
            >
              <Typography variant='body1'>
                {mediaSharesPostCounts.totalCommunity}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid
              item
              xs
              md
            >
              <Typography variant='body1'>KnoCard contact</Typography>
            </Grid>
            <CustomBoxider />
            <Grid
              item
              xs={1}
              md={1}
            >
              <Typography variant='body1'>
                {mediaSharesPostCounts.totalKnocardContact}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid
              item
              xs
              md
            >
              <Typography variant='body1'>New contact</Typography>
            </Grid>
            <CustomBoxider />
            <Grid
              item
              xs={1}
              md={1}
            >
              <Typography variant='body1'>
                {mediaSharesPostCounts.totalNewContact}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid
              item
              xs
              md
            >
              <Typography variant='body1'>Media link</Typography>
            </Grid>
            <CustomBoxider />
            <Grid
              item
              xs={1}
              md={1}
            >
              <Typography variant='body1'>
                {mediaSharesPostCounts.totalLink}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      )} */}

      <Box className={styles.resultsListContainer}>
        {mediaShareReportData &&
          mediaShareReportData.map((data, index: number) => (
            <MediaSharesItem
              key={index}
              mediaSharesPostItemModel={data}
              index={index}
              selectedTimeFilter={selectedTimeFilter}
              selectedReportUser={selectedReportUser}
            />
          ))}

        {page < lastPage && !isLoading && (
          <Box>
            <Button
              type='button'
              variant='contained'
              sx={{ m: 2, textTransform: 'initial' }}
              autoCapitalize={'none'}
              onClick={() => handleMediaShares(true)}
            >
              Load More
            </Button>
          </Box>
        )}

        {isLoading && (
          <Box>
            {' '}
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default MediaShares;
