import React, { FC, useEffect, useState } from 'react';
import { Avatar, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';

import { useCollapse } from 'react-collapsed';
import styles from '../../styles/Reports.module.css';
import {
  MediaSharePostReportDetail,
  MediaShareReportItem as MediaShareReportItemModel,
  GetMediaSharePostReportDetailRequest,
  MediaShareReportItem,
} from '../../store/reports/api/interface';
import { dateFormate } from '../../utils/format';
import { AppDispatch, useAppSelector } from '../../store';

import { getDaysFromFilter } from '../../utils/daysConvertor';
import { getMediaSharePostReportDetail } from '../../store/reports/api';
import { useDispatch } from 'react-redux';
import { MediaType } from '../../enum';
import { isNotEmpty } from '../../utils/valid';
import { AppColors } from '../../utils/utils';

const MediaSharePostUserItem: FC<{
  mediaSharePostDetailItem: MediaShareReportItem;
  index: number;
}> = ({ mediaSharePostDetailItem, index }) => {
  const userName = `${mediaSharePostDetailItem.user?.first_name ?? ''} ${mediaSharePostDetailItem.user?.last_name ?? ''}`;
  const dateFormatted =
    dateFormate(mediaSharePostDetailItem.created_at ?? '') ?? '';
  return (
    <Box className={styles.subitemContainer} sx={{
      pr: 0, background: AppColors.grayZeroColor,
      border: `solid 1px ${AppColors.strokeColor}`
    }}>
      <Box
        component={'img'}
        alt='pic'
        src={`${mediaSharePostDetailItem.user?.profile_picture ??
          mediaSharePostDetailItem.user?.user?.profile_picture ??
          '/static/images/placeholder_profile.png'
          }`}
        className={styles.knoSmilie}
        sx={{ height: "40px", width: "40px" }}
      />
      <Box
        className={styles.itemContainerContent}
        sx={{ ml: 1, flexGrow: 1, }}
      >
        <Typography sx={{ fontSize: "1rem", fontWeight: 400 }}>
          {isNotEmpty(userName.trim()) ? userName.trim() : 'KnoCard User'}
        </Typography>
        <Typography sx={{ fontSize: "0.8rem", color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
      </Box>
      <Stack direction={'column'} sx={{
        width: "100%",
        ml: 1,
        mr: 1,
        alignItems: "end"
      }}>

        {mediaSharePostDetailItem?.feedback_form?.question && (<><Typography sx={{ color: "#000000", fontSize: "0.6rem" }}>Question</Typography><Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#022737",
            borderRadius: "5px",
            width: 'fit-content',
            pt: 0.5,
            pb: 0.5
          }}
        >
          <Typography sx={{ color: "#ffffff", ml: 2, mr: 2, fontSize: "0.8rem" }}>{mediaSharePostDetailItem?.feedback_form?.question ?? ""}</Typography>
        </Box></>)}
        {mediaSharePostDetailItem.form_answer && (<><Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#1AD7FC",
            borderRadius: "5px",
            width: 'fit-content',
            pt: 0.5,
            pb: 0.5
          }}
        >
          <Typography sx={{ color: "#ffffff", ml: 2, mr: 2, fontSize: "0.8rem" }}>{mediaSharePostDetailItem.form_answer ?? ""}</Typography>
        </Box><Typography sx={{ color: "#000000", fontSize: "0.6rem" }}>Answer</Typography></>)}

      </Stack>

    </Box>
  );
};

interface IProps {
  mediaSharesPostItemModel: MediaShareReportItemModel;
  index: number;
  selectedTimeFilter: string;
  selectedReportUser?: number;
}

const MediaSharesItem: React.FC<IProps> = ({
  mediaSharesPostItemModel,
  index,
  selectedTimeFilter,
  selectedReportUser,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);
  const [isExpanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const [mediaSharesPostCounts, setMediaSharesPostCounts] =
    useState<MediaSharePostReportDetail | null>(null);

  useEffect(() => {
    if (isExpanded && (mediaSharesPostCounts?.data?.length ?? 0) === 0) {
      fetchMediaSharesPostDetail(false);
    }
  }, [isExpanded]);

  const fetchMediaSharesPostDetail = async (isLoadMore: boolean) => {
    const data: GetMediaSharePostReportDetailRequest = {
      user_id: selectedReportUser ? selectedReportUser : user?.id ?? 0,
      days: getDaysFromFilter(selectedTimeFilter),
      feedback_answer_filter: 'all',
    };
    try {
      setIsLoading(true);
      const response: any = await dispatch(
        getMediaSharePostReportDetail({
          data: data,
          postId: mediaSharesPostItemModel.post?.id ?? 0,
          page: (mediaSharesPostCounts?.current_page ?? 0) + 1,
        })
      ).unwrap();
      if (
        response.status === 200 &&
        response.data.status &&
        response.data.status === 'success'
      ) {
        let oldMediaSharePostDetailItems = [];
        if (response.data.data.current_page == 1) {
          oldMediaSharePostDetailItems = response.data.data.data;
        } else {
          oldMediaSharePostDetailItems = [
            ...(mediaSharesPostCounts?.data ?? []),
            ...response.data.data.data,
          ];
        }
        setMediaSharesPostCounts({
          ...response.data.data,
          data: oldMediaSharePostDetailItems,
        });
      }
    } catch (e) { }
    setIsLoading(false);
  };

  const getMediaImage = () => {
    return (
      (isNotEmpty(mediaSharesPostItemModel.post?.contents)
        ? isNotEmpty(mediaSharesPostItemModel.post?.contents[0].thumbnail) &&
          !mediaSharesPostItemModel.post?.contents[0].thumbnail?.endsWith('/')
          ? mediaSharesPostItemModel.post?.contents[0].thumbnail
          : (mediaSharesPostItemModel.post?.contents[0]?.link ?? '').length > 15
            ? mediaSharesPostItemModel.post?.contents[0].link
            : '/static/images/placeholder.png'
        : '/static/images/placeholder.png') ?? '/static/images/placeholder.png'
    );
  };

  return (
    <Box style={{ width: '100%' }}>
      <Box
        className={styles.itemContainer}
        sx={{
          background: AppColors.grayZeroColor,
          border: `solid 1px ${AppColors.strokeColor}`,
          mt: 0.6, borderRadius: "6px"
        }}
        {...getToggleProps({
          onClick: () => {
            setExpanded(!isExpanded);
          },
        })}
      >
        <img
          alt='pic'
          src={getMediaImage()}
          className={styles.knoSmilie}
        />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "1rem", fontWeight: 400 }}>
            {mediaSharesPostItemModel?.post?.description ?? ''}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", color: AppColors.grayTwoColor }}>
            {`Shares ${mediaSharesPostItemModel.total_share_count ?? 0}`}
          </Typography>
        </Box>

        {isExpanded ? (
          <img
            src='/static/images/arrow_down.svg'
            alt='chevron_down'
            className={styles.chevronDown}
            style={{ transform: `rotate(180deg)` }}
          />
        ) : (
          <img
            src='/static/images/arrow_down.svg'
            alt='chevron_down'
            className={styles.chevronDown}
          />
        )}
      </Box>
      <Box {...getCollapseProps()} sx={{ ml: 4 }}>
        {mediaSharesPostCounts &&
          mediaSharesPostCounts?.data.length > 0 &&
          mediaSharesPostCounts.data.map((item, index) => (
            <MediaSharePostUserItem
              mediaSharePostDetailItem={item}
              index={index}
            />
          ))}
        {mediaSharesPostCounts &&
          (mediaSharesPostCounts.current_page ?? 0) <
          (mediaSharesPostCounts.last_page ?? 0) &&
          !isLoading && (
            <Stack
              direction={'row'}
              justifyContent={'center'}
              sx={{ m: 1 }}
            >
              <Button
                type='button'
                variant='contained'
                sx={{ m: 2, textTransform: 'initial' }}
                autoCapitalize={'none'}
                onClick={() => fetchMediaSharesPostDetail(true)}
              >
                Load More
              </Button>
            </Stack>
          )}

        {isLoading && (
          <Stack
            direction={'row'}
            justifyContent={'center'}
            sx={{ m: 1 }}
          >
            {' '}
            <CircularProgress />
          </Stack>
        )}
      </Box>
    </Box >
  );
};

export default MediaSharesItem;
