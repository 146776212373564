import {
  DialogContent,
  IconButton,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import '@splidejs/react-splide/css';

import { TrainingVideoItem } from '../../store/trainingVideo/api/interface';
import ReactPlayer from 'react-player';

type IProps = {
  trainingVideoItem: TrainingVideoItem;
  toggle: boolean;
  onVideoPlay?: (videoId: number) => void;
  onClose: () => void;
};


const VideoTrainingModel: React.FC<IProps> = ({
  toggle, onClose, trainingVideoItem, onVideoPlay
}) => {


  return (
    <>
      <Dialog
        open={toggle}
        onClose={onClose}
        maxWidth='sm'
        fullWidth
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: 3000
        }}
      >
        <DialogContent sx={{ position: "relative" }}>
          <IconButton sx={{ position: "absolute", right: 0, top: 0 }} onClick={onClose}><CloseIcon /></IconButton>
          <video
            controls
            controlsList="nodownload"
            width="100%"
            height="100%"
            className="object-fit-contain"
            style={{ maxHeight: "80vh" }}

            onPlay={() => {
              if (onVideoPlay && trainingVideoItem.id) {
                onVideoPlay(trainingVideoItem.id);
              }
            }}
          >
            <source src={trainingVideoItem.link} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </DialogContent>

      </Dialog>
    </>
  );
};

export default VideoTrainingModel;
