import { STATUS_TYPE } from '../../../enum';
import { CompanyExternalLink } from '../../companyExternalLinks/api/interface';
import { EngageActivity } from '../../engagePipeline/api/interface';
import { MediaShareReportItem, VideoViewsReportItem } from '../../reports/api/interface';


export interface EngagePipelineResponse {
  status: string;
  data: EngageActivity[];
}

export interface EngageUserActivityResponse {
  status: string;
  data: EngageUserActivity[];
}

export interface EngageUserActivity {
  module?: string;
  id?: number;
  total?: number;
  created_at?: Date;
}

export interface EngageCountResponse {
  status?: string;
  data?: EngageCountItem
}

export interface EngageCountItem {
  totalTodayShares?: number;
  knocard?: number;
  mediaShare?: number;
  shareAffiliateLandingPage?: number;
  socialShare?: number;
  engageSetting?: EngageSettingItem;

}

export interface EngageSettingItem {
  id?: number;
  user_id?: number;
  status?: number;
  share_goal?: number;
  duration?: number;
  get_started?: number;
  date?: Date;
  created_at?: Date;
  updated_at?: Date;
}


export interface EngageActivityResponse {
  status: string;
  data: EngageActivityCount;
}


interface EngageActivityCount {
  knocard?: number;
  newProspects?: number;
  mediaFiles?: number;
  socialPosts?: number;
  activeLeads?: number;
  noActivity?: number;
}


export interface GetEngageUserActivityReportRequest {
  user_id: number;
  sort_marker: string;
  days: number;
  isFromEngage: boolean;
  elid?: number;
}

export interface EngageUserActivityReportResponse {
  status: string;
  data: EngageUserActivityReportItem[];
  external_link_report?: ExternalLinkReportItem[];
  post_feedback_form?: MediaShareReportItem[];
  media_post_video_viewReport?: VideoViewsReportItem[];
}

export interface EngageUserActivityReportItem {
  module: string;
  id: number;
  total: number;
  created_at: string;
}

export interface ExternalLinkReportItem {
  company_external_link_id?: number
  created_at?: string;
  id?: number;
  total?: number;
  externalpage?: CompanyExternalLink;
}





interface InitialState {
  data?: EngageActivityCount;
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

export const initialState: InitialState = {
  data: undefined,
  status: 'idle',
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
};
