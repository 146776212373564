import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { forwardRef } from "react";
// material
import { Box } from "@mui/material";
import ResponsiveAppBar from "./ResponsiveAppBar";

// ----------------------------------------------------------------------

const Page = forwardRef(
  (
    {
      children,
      title = "",
      description = undefined,
      image = undefined,
      showAppBar = false,
      sx = {},
      ...other
    }: {
      children: any; title: any,
      description?: string,
      image?: string,
      showAppBar?: any, sx?: any
    },
    ref
  ) => (
    <Box ref={ref} {...other} sx={sx}>
      <Helmet>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        {image && <meta property="og:image" content={image} />}
      </Helmet>
      {/* {showAppBar && <ResponsiveAppBar />} */}
      {children}
    </Box>
  )
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  showAppBar: PropTypes.bool,
};

export default Page;
