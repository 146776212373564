import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Button, Divider, Fab, Grid, IconButton, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/system";
import { Transition } from "../Transition";
import { useAppDispatch, useAppSelector } from "../../store";
import React, { useEffect, useRef, useState } from "react";
import { CompanyFeed, CompanyFeedComments } from '../../store/social/api/interface';
import SendIcon from '@mui/icons-material/Send';
import { addCompanyFeedComment } from "../../store/social/api";
import { socialFeedUpdated } from "../../store/social/socialSlice";
import moment from "moment";
import { getCapitalizedString } from "../../utils/utilMethods";
import { AppColors } from "../../utils/utils";
import CancelIcon from '@mui/icons-material/Cancel';

interface IProps {
    toggle: boolean;
    onClose: () => void;
    companyFeed: CompanyFeed;
}
const CompanyFeedCommentsModal: React.FC<IProps> = ({ toggle, onClose, companyFeed }) => {
    const commentListRef = useRef<any | null>(null);
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state?.auth?.user);

    const [commentMessage, setCommentMessage] = useState<string>("");
    const [commentList, setCommentList] = useState<CompanyFeedComments[]>([]);


    useEffect(() => {
        setCommentList(companyFeed.comments ?? []);
    }, [companyFeed])

    const onCancel = () => {
        setCommentMessage("");
        onClose();
    };

    const handleCommentChange = (e: any) => {
        setCommentMessage(e.target.value ?? '');
    }


    const handleAddComment = async () => {
        if (commentMessage.trim().length > 0) {
            dispatch(addCompanyFeedComment({ comment: commentMessage.trim(), companyFeedId: companyFeed.id })).unwrap().then((result) => {
                if (result.data.status === 'success') {
                    let updatedLikeCompanyFeed = { ...companyFeed };
                    if (result.data.data !== undefined) {
                        let newComment = { ...result.data.data, user: user };
                        updatedLikeCompanyFeed.comments = [...commentList, newComment];
                        updatedLikeCompanyFeed.comments_count += 1;
                        dispatch(socialFeedUpdated(updatedLikeCompanyFeed));
                        setCommentList(updatedLikeCompanyFeed.comments);
                        setTimeout(() => {
                            commentListRef?.current?.scrollIntoView(false);
                        }, 100)
                    }
                }
            });
        }
        setCommentMessage('');
    }

    return (
        <>
            <Dialog
                fullWidth
                open={toggle}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    onCancel();
                }}
                PaperProps={{
                    style: {
                        maxHeight: '50%'
                    }
                }}

            >
                <DialogTitle
                    sx={{
                        color: AppColors.blackColor
                    }}
                >
                    Comments
                    <IconButton
                        aria-label='close'
                        onClick={onClose}
                        sx={{ position: 'absolute', right: 0, zIndex: 100000, mr: 1, color: AppColors.grayTwoColor }}
                    >
                        <CancelIcon />
                    </IconButton>
                </DialogTitle>


                <DialogContent sx={{ padding: '20px' }}>
                    <Stack
                        divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
                        spacing={3}

                    >
                        <Grid item xs={12} sm={12} ref={commentListRef}>
                            {
                                commentList.map((commentItem: CompanyFeedComments, idx: number) => {
                                    return (<Box display='flex' alignItems='center' mt={1} >
                                        <Box width='50px'>
                                            <Avatar
                                                alt='pic'
                                                src={
                                                    commentItem?.user?.profile_picture ??
                                                    '/static/images/placeholder_profile.png'
                                                }
                                            />
                                        </Box>
                                        <Box
                                            display='flex'
                                            flexDirection='column'
                                            alignItems='flex-start'
                                            justifyContent='center'
                                            flex={1}
                                            mt={1}
                                        >
                                            <Typography
                                                component='div'
                                                color='black'
                                                className='post-main-title'
                                                sx={{ color: AppColors.blackColor, fontWeight: '500' }}
                                            >
                                                {commentItem?.user?.name ?? ''}
                                            </Typography>
                                            <Typography
                                                variant='h6'
                                                component='div'
                                                color='primary'
                                                fontSize='10px'
                                                className='post-main-sub-title'
                                                sx={{ color: AppColors.grayTwoColor }}

                                            >

                                                {commentItem?.comment ?? ''}

                                            </Typography>
                                            <Typography
                                                component='div'
                                                fontSize='12px'
                                                sx={{ color: AppColors.grayTwoColor }}
                                            >
                                                {moment(commentItem?.created_at).format('DD MMM, YYYY')}
                                            </Typography>
                                        </Box>


                                    </Box>);
                                })
                            }

                            {commentList.length == 0 ? <Typography mt={5} textAlign={'center'}>No comments yet</Typography> : <></>}
                        </Grid>
                    </Stack>
                </DialogContent>

                <DialogActions  >
                    <Grid container pb={1} pt={1} pl={1} pr={1.5}>
                        <Grid item sm={11} xs={9} pr={2}>
                            <TextField id="outlined-basic-email" placeholder="Write comment..." onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }} fullWidth value={commentMessage} onChange={handleCommentChange} />
                        </Grid>
                        <Grid item sm={1} xs={3} >
                            <Fab color="primary" aria-label="add comment" onClick={handleAddComment}><SendIcon /></Fab>
                        </Grid>
                    </Grid>
                </DialogActions>

            </Dialog >
        </>
    );
};

export default CompanyFeedCommentsModal;
