import React, { FC, useEffect, useState } from 'react';
import { Avatar, Box, CircularProgress, Stack, Typography } from '@mui/material';

import { useCollapse } from 'react-collapsed';
import styles from '../../styles/Reports.module.css';
import {
  GetKnoCardViewCountRequest,
  KnoCardView,
  KnoCardViewCount,
} from '../../store/reports/api/interface';

import { AppDispatch, useAppSelector } from '../../store';

import { getDaysFromFilter } from '../../utils/daysConvertor';
import { getKnoCardViewDetail } from '../../store/reports/api';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getItem } from '../../utils/localStorage';
import { AppColors, config } from '../../utils/utils';
import MailIcon from '../../assets/reports/mail.svg';
import CallIcon from '../../assets/reports/call.svg';
import BusinessIcon from '../../assets/reports/business.svg';
import PersonIcon from '../../assets/reports/person.svg';
import VideoIcon from '../../assets/reports/video.svg';
import LinkIcon from '../../assets/reports/link.svg';
import MediaIcon from '../../assets/reports/media.svg';

const PageViewsCountItem: FC<{
  countItem: KnoCardViewCount;
  index: number;
}> = ({ countItem, index }) => {
  const dateFormatted = moment
    .utc(`${countItem.created_at}`)
    .local()
    .format('MMM DD, YYYY  hh:mm a');

  if (countItem.module === 'profile') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }} >
        <Box component={'img'} src={PersonIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`Contact page ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>
      </Box>
    );
  } else if (countItem.module === 'contact') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>
        <Box component={'img'} src={CallIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`Contact page ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  } else if (countItem.module === 'playlist') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>
        <Box component={'img'} src={VideoIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`Playlist page ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  } else if (countItem.module === 'business_page') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>
        <Box component={'img'} src={BusinessIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`Social page ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  } else if (countItem.module === 'external_page') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>
        <Box component={'img'} src={LinkIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`External page ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  } else if (countItem.module === 'external_link') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>
        <Box component={'img'} src={LinkIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`External link ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  } else if (
    countItem.module === 'images' ||
    countItem.module === 'collage' ||
    countItem.module === 'photo_gallery'
  ) {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>

        <Box component={'img'} src={MediaIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`Media Page ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  } else if (countItem.module === 'business_email') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>
        <Box component={'img'} src={MailIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`Copied your business email ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  } else if (countItem.module === 'business_phone') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>
        <Box component={'img'} src={MediaIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`Copied your business phone ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  } else if (countItem.module === 'phone_number') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>
        <Box component={'img'} src={MediaIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`Copied your phone ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  } else if (countItem.module === 'business_mobile_phone') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>
        <Box component={'img'} src={MediaIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`Copied your business mobile phone ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  } else if (countItem.module === 'fax') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>
        <Box component={'img'} src={MediaIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`Copied your fax number ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  } else if (countItem.module === 'personal_email') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>
        <Box component={'img'} src={MailIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`Copied your personal email ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  } else if (countItem.module === 'location') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>
        <Box component={'img'} src={MailIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`Copied your location ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  } else if (countItem.module === 'affiliate_landing_page') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>
        <Box component={'img'} src={LinkIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`Affiliate landing page ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  } else if (countItem.module === 'rejection_free_landing_page') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>
        <Box component={'img'} src={LinkIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`Rejection free landing page ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  } else if (countItem.module === 'network_landing_page') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>
        <Box component={'img'} src={LinkIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`Network landing page ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  } else if (countItem.module === 'group_landing_page') {
    return (
      <Box className={styles.subitemContainer} sx={{
        mt: 0.5,
        background: AppColors.grayZeroColor,
        border: `solid 1px ${AppColors.strokeColor}`
      }}>
        <Box component={'img'} src={LinkIcon} className={styles.muiIconSize} />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
            {`Group landing page ${countItem.total ?? 0} Views`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>{dateFormatted}</Typography>
        </Box>

      </Box>
    );
  }

  return <></>;
};

interface IProps {
  knoCardView: KnoCardView;
  index: number;
  selectedTimeFilter: string;
  selectedReportUser?: number;
}

const PageViewsItem: React.FC<IProps> = ({
  knoCardView,
  index,
  selectedTimeFilter,
  selectedReportUser,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);
  const accessToken = getItem(config.AUTH_KEY)
  const [isExpanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const [pageViewCounts, setPageViewCounts] = useState([]);

  useEffect(() => {
    setPageViewCounts([]);
  }, []);

  useEffect(() => {
    if (isExpanded && pageViewCounts.length === 0) {
      fetchPageViewCounts();
    }
  }, [isExpanded]);

  const fetchPageViewCounts = async () => {
    const data: GetKnoCardViewCountRequest = {
      user_id: selectedReportUser ? selectedReportUser : user?.id ?? 0,
      api_token: accessToken ?? '',
      sort_marker: knoCardView.sort_marker,
      days: getDaysFromFilter(selectedTimeFilter),
    };
    try {
      setIsLoading(true);
      const response = await dispatch(getKnoCardViewDetail(data)).unwrap();
      if (
        response.status === 200 &&
        response.data.status &&
        response.data.status === 'success'
      ) {
        setPageViewCounts(response.data.data);
      }
    } catch (e) { }
    setIsLoading(false);
  };

  return (
    <Box style={{ width: '100%' }}>
      <Box
        className={styles.itemContainer}
        {...getToggleProps({
          onClick: () => {
            setExpanded(!isExpanded);
          },
        })}
        sx={{ background: AppColors.grayZeroColor, border: `solid 1px ${AppColors.strokeColor}`, mt: 0.6, borderRadius: "6px" }}
      >
        {/* <img
          src='/static/images/placeholder_profile.png'
          alt='knoImage'
          className={styles.knoSmilie}
        /> */}
        <img
          alt='pic'
          src={
            knoCardView.viewable?.profile_picture ??
            knoCardView.viewable?.user?.profile_picture ??
            '/static/images/placeholder_profile.png'
          }
          className={styles.knoSmilie}
        />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "1rem", fontWeight: 400 }}>
            {knoCardView.viewable?.user?.name ??
              `${knoCardView.viewable?.first_name ?? ''} ${knoCardView.viewable?.last_name ?? ''
              }`}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", color: AppColors.grayTwoColor }}>
            {`Viewed your KnoCard ${knoCardView.total ?? 0} times`}
          </Typography>
        </Box>

        {isExpanded ? (
          <img
            src='/static/images/arrow_down.svg'
            alt='chevron_down'
            className={styles.chevronDown}
            style={{ transform: `rotate(180deg)` }}
          />
        ) : (
          <img
            src='/static/images/arrow_down.svg'
            alt='chevron_down'
            className={styles.chevronDown}
          />
        )}
      </Box>
      <Box {...getCollapseProps()} sx={{ ml: 4 }}>
        {pageViewCounts.length > 0 &&
          pageViewCounts.map((countItem, index) => (
            <PageViewsCountItem
              countItem={countItem}
              index={index}
            />
          ))}
        {isLoading && (
          <Stack
            direction={'row'}
            justifyContent={'center'}
            sx={{ m: 1 }}
          >
            {' '}
            <CircularProgress />
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default PageViewsItem;
