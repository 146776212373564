export const timeFilterList = ['Today', 'Week', 'Month', 'All Time'];

export const getDaysFromFilter = (newValue?: string) => {
  switch (newValue) {
    case 'Today':
      return 0;
    case 'Week':
      return 7;
    case 'Month':
      return 30;
  }
  return -1;
};

export const getFilterFromDays = (value?: number) => {
  switch (value) {
    case 0:
      return 'Today';
    case 7:
      return 'Week';
    case 30:
      return 'Month';
  }
  return 'All Time';
};
