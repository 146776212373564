import * as Yup from 'yup';
import { youTubeVideoRegex } from '../constant';



const youtubeLinkPostSchema = Yup.object().shape({
    title: Yup.string().required('Title is required.'),
    link: Yup.string().required('Link is required.').matches(youTubeVideoRegex, "Link should be a valid youtube URL")
});
export default youtubeLinkPostSchema;
