import React, { useEffect, useRef, useState } from "react";

import Typography from "@mui/material/Typography";
import Button, { ButtonProps } from "@mui/material/Button";
import { Box, Card, CardActionArea, CardContent, Grid, Stack, useMediaQuery } from "@mui/material";

import { config } from "../../utils/utils";
import styled from "@emotion/styled";
import styles from "../../styles/AffiliateLanding.module.css";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../store";
import Loader from '../../components/Loader';
import { getNetworkLandingPageVideos } from "../../store/networkLandingPageVideo/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import VideoTutorialModel from "../../components/modal/VideoTutorialModel";
import useToggle from "../../hooks/useToggle";
import { NetworkLandingPageVideoItem } from "../../store/networkLandingPageVideo/api/interface";
import { getUserInfo } from "../../store/userInfo/api";
import { UserInfo } from "../../store/userInfo/api/interface";
import useWindowSize from "../../utils/useWindowSize";
import { analytics } from "../../store/shareRefer/api";
import ContactFormModal from '../../components/ContactFormModal';
import { setUserInfo } from "../../store/userInfo/userInfoSlice";
import { ContactFormSourceType } from "../../enum";
import { checkIsProProOrProOneOrProPlusUser } from "../../utils/checkPro";
import GroupNetworkModal from "../../components/GroupNetworkModal";
import { LandingPageNewNavBar } from "../../components/LandingPageNewNavBar";
import { userInfo } from "os";
import NetworkLandingPageConfirmationModal from "../../components/NetworkLandingPageConfirmationModal";
import { removeItem } from "../../utils/localStorage";

const OpenButton = styled(Button)<ButtonProps>(({ theme }) => ({
  // backgroundColor: "#00cc00",
  backgroundImage: 'linear-gradient(270deg, #70F837 0%,   #1CB85D 100%)',
  color: "#000000",
  borderRadius: "30px",
  fontSize: "small",
  fontWeight: "bold",
  "&:hover": {
    background: "white",
    color: "#00cc00",
  },
  minWidth: "122px"
}));

const NetworkLandingPage = () => {
  const toolbarMatches = useMediaQuery("(max-width:745px)");
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();
  const networkLandingPageVideoResult = useAppSelector((state) => state.networkLandingPageVideo.data);
  const overviewVideo = useAppSelector((state) => state.networkLandingPageVideo.overview);
  const isLoading = useAppSelector((state) => state.tutorialVideo.isLoading);
  const authUserInfo = useAppSelector((state) => state?.auth?.user);

  const [isOverviewVideoPlayed, setOverviewVideoPlayed] = useState(false);

  const [lastPlatVideoId, setLastPlatVideoId] = useState<number | undefined>(undefined);

  const [searchParams] = useSearchParams();
  const [referredByLink, setReferredByLink] = useState('');
  const [referrUserInfo, setReferrUserInfo] = useState<UserInfo | undefined>(undefined);

  const [calendarLink, setCalendarLink] = useState('https://calendly.com/knocard/connect-with-me');

  const windowSize = useWindowSize();
  const [tabMinWidth, setTabMinWidth] = useState(90);
  const matches = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    const newWidth = matches ? windowSize.width / 5 : 90;
    setTabMinWidth(newWidth);
  }, [windowSize]);


  const {
    toggle: toggleContactForm,
    onOpen: onOpenContactForm,
    onClose: onCloseContactForm,
  } = useToggle();

  const {
    toggle: toggleNetworkLandingPage,
    onOpen: onOpenNetworkLandingPage,
    onClose: onCloseNetworkLandingPage,
  } = useToggle();

  const {
    toggle: toggleGroup,
    onOpen: onOpenGroup,
    onClose: onCloseGroup,
  } = useToggle();

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleContactFormSubmitSuccess = (contactCode: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete(config.PROSPECT_KEY);
    queryParams.set('sc', contactCode);
    navigate(`/network-landing-page?${queryParams.toString()}`);
  };


  useEffect(() => {
    let refferLink = `${config.VBC_URL}`;
    let userName = "";

    if (searchParams.has(config.REFER_KEY)) {
      userName = searchParams.get(config.REFER_KEY) ?? '';
      refferLink += `/affiliate/${userName}`;
    } else {
      refferLink += "/register";
    }
    setReferredByLink(refferLink);

    if (!isLoading) {
      dispatch(getNetworkLandingPageVideos());
    }

    if (userName.length > 0) {
      dispatch(getUserInfo({ username: userName })).unwrap().then((res) => {
        if (res.data.data.user.length > 0) {
          setReferrUserInfo(res.data.data.user[0]);
          dispatch(setUserInfo(res.data));
        }
      });
    }
  }, []);


  useEffect(() => {
    if (referrUserInfo) {
      if (searchParams.has(config.PROSPECT_KEY) && searchParams.get(config.PROSPECT_KEY) && checkIsProProOrProOneOrProPlusUser(referrUserInfo)) {
        onOpenContactForm();
      }
      saveLadingPageViewActivity();
    }
  }, [referrUserInfo]);


  const saveLadingPageViewActivity = () => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let elid = searchParams.get('elid');
      let loginUserId = authUserInfo?.id;
      dispatch(
        analytics({
          route: '/activity/save',
          data: {
            actionType: 'view',
            sourceType: 'link_share',
            module: 'network_landing_page',
            targetId: referrUserInfo?.id,
            identifiableId: referrUserInfo?.id,
            ...(loginUserId && { viewableId: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
            ...((!loginUserId && elid) && { elid: elid })
          },
        })
      );
    }
  }



  useEffect(() => {

    if (referrUserInfo) {
      if ((referrUserInfo.calendar_link ?? "").trim().length > 0) {
        setCalendarLink(referrUserInfo.calendar_link ?? "");
      } else {
        setCalendarLink(`tel:${referrUserInfo.phone_country_code ?? ""}${referrUserInfo.phone_number}`);
      }
    }

  }, [referrUserInfo])


  const handleGetKnoCard = () => {
    onOpenGroup();
    //window.open(`${referredByLink}`) || window.location.replace(`${referredByLink}`);

  };

  const [openTutorialVideo, setOpenTutorialVideo] = useState<NetworkLandingPageVideoItem | undefined>(undefined);
  const [showAllVideo, setShowAllVideo] = useState<string | undefined>(undefined);

  const {
    toggle: togglenTutorialVideo,
    onOpen: onOpenTutorialVideo,
    onClose: onCloseTutorialVideo,
  } = useToggle();


  useEffect(() => {
    if (openTutorialVideo != undefined) {
      onOpenTutorialVideo();
    }
  }, [openTutorialVideo]);

  useEffect(() => {
    if (!togglenTutorialVideo) {
      setOpenTutorialVideo(undefined);
    }
  }, [togglenTutorialVideo]);



  const getParamDelimiter = (params: string) => {
    return (params.trim().length > 0) ? "&" : "?";
  };




  const handleOnVideoPlayActivity = (videoTitle: string, second: number, duration: number) => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let loginUserId = authUserInfo?.id;

      dispatch(
        analytics({
          route: '/landing-page/video-watch',
          data: {
            target_id: referrUserInfo?.id ?? 0,
            module: "network_landing_page",
            title: videoTitle,
            target_page: "network-landing-page",
            video_watch_seconds: second,
            video_duration_seconds: duration,
            ...(loginUserId && { viewable_id: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
          },
        })
      );
    }
  };


  const handleOnPauseOrCompleteVideoActivity = (videoTitle: string, second: number, duration: number) => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let loginUserId = authUserInfo?.id;

      dispatch(
        analytics({
          route: '/landing-page/video-watch-time',
          data: {
            target_id: referrUserInfo?.id ?? 0,
            module: "network_landing_page",
            title: videoTitle,
            target_page: "network-landing-page",
            video_watch_seconds: second,
            video_duration_seconds: duration,
            ...(loginUserId && { viewable_id: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
          },
        })
      );
    }
  };


  const handleOnVideoPlay = (videoId: number, time: number, duration: number) => {
    var videoTitle = getVideoTitleFromVideoId(videoId)
    if (lastPlatVideoId !== videoId) {
      setLastPlatVideoId(videoId);
      handleOnVideoPlayActivity(videoTitle, time, duration);
    } else if (time !== 0) {
      handleOnPauseOrCompleteVideoActivity(videoTitle, time, duration);
    }
  }


  const getVideoTitleFromVideoId = (videoId: number) => {

    var videoTitle = "";

    if (overviewVideo?.id == videoId) {
      videoTitle = overviewVideo.title;
    } else {
      networkLandingPageVideoResult.map((item) => {
        if (item.id == videoId) {
          videoTitle = item.title;
        }
      });
    }

    return videoTitle;
  }


  const handleOpenPricing = () => {
    removeItem(config.GROUP_NETWORK_ID_KEY);
    const subscriptionUrl = `${config.VBC_URL}/subscription/${referrUserInfo?.username || ""}`
    window.open(`${subscriptionUrl}`) || window.location.replace(`${subscriptionUrl}`);
  }

  if (isLoading) return <Loader />;

  return (
    <React.Fragment>
      <LandingPageNewNavBar userInfo={referrUserInfo} affiliateUserName={referrUserInfo?.username} />



      {/* SECTION 1  */}
      <section className={styles.section_1}>
        <Box

          sx={{ pb: 2, width: "100%", background: `url("/static/network_landing_page/section_one_bg.png")`, backgroundPosition: "bottom", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
        // sx={{
        //   minHeight: { xs: 'calc(100vh - 77px)', md: "unset" }
        // }}
        // flexDirection={'column'}
        // display='flex'
        // justifyContent='center'
        >
          <Stack flexDirection={"column"} sx={{
            pt: 2,
            pb: 1, alignContent: { xs: "flex-start", sm: "center" }, alignItems: { xs: "start", sm: "center" }
          }}>
            <Box sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FEFF09",
              width: "fit-content",
              mr: 2,
              pb: "10px",
              pt: "10px",
              borderRadius: "10px",
              borderTopLeftRadius: { xs: "0px", sm: "10px" },
              borderBottomLeftRadius: { xs: "0px", sm: "10px" }
            }}>
              <Typography
                component='p'
                sx={{
                  fontSize: '1.2rem',
                  color: '#000000',
                  textAlign: 'center',
                  pl: 2,
                  pr: 2,
                  pt: 0.5,
                  lineHeight: 1,
                  pb: 0.5,
                  borderRadius: "10px",
                  fontWeight: "bold",
                  '& span': {
                    fontSize: '1.8rem',
                  }
                }}
              >
                <span>A</span>TTENTION <span>N</span>ETWORKERS

              </Typography>
            </Box>

          </Stack>
          <Typography
            component='p'
            sx={{
              fontSize: '1.6rem',
              color: '#4E545D',
              textAlign: 'center',
              pl: 1,
              pr: 1,
              pt: 2,
              lineHeight: 1.3,
              pb: 0.5,
              borderRadius: "10px",
              '& span': {
                color: "#000000",
                fontWeight: "bold"
              }
            }}
          >
            <span>Boost</span> Your Networking Engagement by <span>62%</span>: Discover the <span>Secret Strategy!</span>

          </Typography>
          <Stack flexDirection={"column"} sx={{
            mb: 2, pt: 2,
            pb: 1, alignContent: { xs: "flex-end", sm: "center" }, alignItems: { xs: "start", sm: "center" }
          }}>
            <Box sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              background: 'linear-gradient(270deg,#0E47A2 0%,#4ED9DD 100%)',
              width: "fit-content",
              ml: 2,
              pb: "10px",
              pt: "10px",
              borderRadius: "10px",
              borderTopRightRadius: { xs: "0px", sm: "10px" },
              borderBottomRightRadius: { xs: "0px", sm: "10px" }
            }}>
              <Typography
                component='p'
                sx={{
                  fontSize: '1.5rem',
                  color: '#ffffff',
                  textAlign: 'center',
                  pl: 1,
                  pr: 1,
                  pt: 0.5,
                  lineHeight: 1,
                  pb: 0.5,
                  borderRadius: "10px",

                  '& span': {
                    fontSize: '1.6rem',
                  }
                }}
              >
                <span>W</span>atch this video if you are serious about your business

              </Typography>
            </Box>

          </Stack>
          {overviewVideo && (
            <Stack flexDirection={"row"} justifyContent={'center'} sx={{
              mt: 2, mb: 2,
              ml: 1, mr: 1
            }}>
              <Box sx={{
                textAlign: 'center', background: 'linear-gradient(270deg,#4E80C3 0%,#68BBCA 100%)', width: 'fit-content',
                mb: 8,

              }}>
                <Box
                  component={'video'}
                  autoPlay={false}
                  id='intro_video'
                  controls
                  poster={overviewVideo.thumb_link}
                  sx={{
                    pt: { xs: 1, md: 3 },
                    pl: { xs: 1, md: 5 },
                    pr: { xs: 1, md: 5 },
                    mb: -8,
                    maxWidth: '100%',
                    height: { xs: 'auto', md: '400px' },
                  }}
                  onPlay={(props: any) => {
                    if (!isOverviewVideoPlayed && props.currentTarget.currentTime < 1) {
                      handleOnVideoPlay(overviewVideo.id, 0, props.currentTarget.duration || 0);
                      setOverviewVideoPlayed(true);
                    }
                  }}
                  onPause={(props: any) => {
                    handleOnVideoPlay(overviewVideo.id, props.currentTarget.currentTime, props.currentTarget.duration || 0);
                  }}
                >
                  <source src={overviewVideo.link} type='video/mp4' />
                </Box>
              </Box>
            </Stack>
          )}
        </Box>
      </section>


      <section>
        <Box
          sx={{
            background: 'linear-gradient(to top,#BBBBBB 0%, #ffffff 50% ,#BBBBBB 100%)',
            paddingBottom: "30px"
          }}
        >
          <Typography
            component='p'
            sx={{
              fontSize: '1.6rem',
              color: '#1A66B3',
              textAlign: 'center',
              pl: 1,
              pr: 1,
              pt: 2,
              lineHeight: 1.3,
              pb: 1,
              borderRadius: "10px",
              '& span': {
                color: "#000000",
                fontWeight: "bold"
              }
            }}
          >
            <span>UNLOCK</span> the tool that <span>KEEPS</span> the conversations flowing: <span>SAY GOODBYE</span>  to empty-handed virtual meetings

          </Typography>
        </Box>
      </section>



      <section>
        <Box
          sx={{
            width: '100%',
            background: 'linear-gradient(to top,#105A90 0%, #051B27 100%)',
            backgroundSize: "cover",
            pt: 5,
            pb: 2,
            position: 'relative',
          }}
        >
          <Box position={"absolute"} justifyContent={"center"} sx={{ textAlign: "center", right: 0, left: 0, top: -20 }}>
            <Box component={"img"} src={"/static/images/knocard.png"} sx={{ width: "50px", pl: 0.5, pr: 0.5 }}></Box>
            <Box component={"img"} src={"/static/images/knocard.png"} sx={{ width: "50px", pl: 0.5, pr: 0.5 }}></Box>
            <Box component={"img"} src={"/static/images/knocard.png"} sx={{ width: "50px", pl: 0.5, pr: 0.5 }}></Box>
            <Box component={"img"} src={"/static/images/knocard.png"} sx={{ width: "50px", pl: 0.5, pr: 0.5 }}></Box>
          </Box>

          <Box sx={{
            textAlign: "center", width: "100%", display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pr: 2
          }}>
            <Box component={"img"} src={"/static/rejection_free/white_line.png"}></Box>
            <Typography
              component='p'
              className="architects-daughter-regular"
              sx={{
                fontSize: { xs: '1.0rem', md: '1.3rem' },
                color: '#00000',
                backgroundColor: "#ECDC3D",
                textAlign: 'center',
                mt: 1,
                mb: 2,
                p: 2,
                width: 'fit-content',
                borderRadius: "10px",

              }}
            >
              YouR ANSWER IS HERE: LET's DIVE in EXPERIENCE THE MAGIC FOR YOURSELF
            </Typography>
          </Box>


          {networkLandingPageVideoResult && networkLandingPageVideoResult.length > 0 && <Box sx={{
            background: "#0B65A6",
            border: "2px solid #25CFE5",
            ml: 2, mr: 2,
            p: 2,
            borderRadius: "25px"
          }}>
            <Grid
              container
              spacing={2}

              justifyContent={"center"}
              sx={{
                position: "relative",
                textAlign: "center",
                pl: 2,
                pr: 2,
                overflow: "hidden"
              }}
            >
              {/* <Box component={"img"} src={"/static/images/number_bg.png"} sx={{ opacity: 0.06 }}></Box> */}
              {
                networkLandingPageVideoResult.map((item, index) => {
                  return (<Grid item >
                    <Card
                      key={`tutorial-item-${index}`}
                      sx={{
                        maxWidth: 345,
                        backgroundColor: "transparent",
                        boxShadow: "none"
                      }}

                    >

                      <Box><Typography
                        variant='body2'
                        color='text.secondary'
                        sx={{
                          overflow: 'hidden',
                          display: '-webkit-box',
                          '-webkit-line-clamp': '1',
                          '-webkit-box-orient': 'vertical',
                          color: '#FFFF0B',
                          fontSize: "large",
                          fontWeight: "bold",
                          mb: 1
                        }}
                      >
                        {item.title}
                      </Typography></Box>
                      <Box component={'div'} sx={{
                        p: 2,
                        border: "2px solid #61808F",
                        borderRadius: "15px",
                        backgroundColor: "#042C42"
                      }}>

                        <Box
                          component='div'
                          height='130px'

                          sx={{
                            background: `url("${item.thumb_link}")`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            mt: 2,
                            borderRadius: "15px"
                          }}
                          onClick={() => { setOpenTutorialVideo(item); }}
                        />

                        <CardActionArea
                          onClick={() => {
                            setOpenTutorialVideo(item);
                          }}
                        >
                          <CardContent
                            sx={{
                              p: 0.5,
                              '&:last-child': { p: 1 },
                              minHeight: '36px',
                              position: 'relative',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Box>

                              <Typography
                                variant='body2'
                                color='text.secondary'
                                sx={{
                                  pt: 1.5,
                                  fontSize: "medium",
                                  overflow: 'hidden',
                                  display: '-webkit-box',
                                  // '-webkit-line-clamp': '3',
                                  // '-webkit-box-orient': 'vertical',
                                  color: '#ffffff',

                                }}
                              >
                                {item.description}
                              </Typography>
                            </Box>
                          </CardContent>
                        </CardActionArea>
                      </Box>
                    </Card>
                  </Grid>);
                })
              }

            </Grid>

          </Box>}
        </Box>
      </section>



      <section>
        <Box
          sx={{
            width: '100%',
            background: '#ffffff',

            pb: 2,
            textAlign: "center"
          }}
        >

          <Typography
            component='p'
            sx={{
              fontSize: {
                xs: '2rem',
                sm: '2rem',
                md: '2rem',
                lg: '3rem',
                xl: '3rem',
              },
              fontFamily: "'Lato', sans-serif",
              color: '#000000',
              textAlign: 'center',
              mt: 1,
              mb: 1,
              p: 1
            }}
          >
            Check out our Spring Promotions
          </Typography>

          <OpenButton
            sx={{
              paddingLeft: '20px',
              paddingRight: '20px',
              width: 'fit-content',
              textTransform: 'unset',
              fontWeight: "bold",
            }}
            onClick={handleOpenPricing}
          >
            Pricing
          </OpenButton>

        </Box>
      </section>


      <section>
        <Box
          sx={{
            width: '100%',
            background: 'linear-gradient(to top, #082A3E, #0B4265)',
            pt: 5,
            pb: 5,
            position: 'relative',
            overflow: "hidden"

          }}
        >
          <Box
            component='img'
            src={'/static/custom_landing_page/network.png'}
            sx={{ width: { sm: "50%", xs: "80%" }, position: "absolute", bottom: 0, right: "-10%", opacity: 0.1 }}
          />
          <Typography
            sx={{
              fontSize: '2.3rem',
              textAlign: 'center',
              fontFamily: "'Lato', sans-serif",
              fontWeight: 'bold',
              pl: 1,
              pr: 1,
              color: "#29D9F0"
            }}
          >
            So What's next?
          </Typography>

          <Box sx={{
            background: 'linear-gradient(270deg, #50DADE 0%, #104BA6 100%)',
            p: 1.5,
            mt: 2,
            mr: { sm: 5, xs: 2 },
            ml: { sm: 0, xs: 2 },
            borderRadius: "16px",
            borderTopLeftRadius: { sm: 0, xs: "16px" },
            borderBottomLeftRadius: { sm: 0, xs: "16px" }


          }}>
            <Typography sx={{ textAlign: "center" }}>
              "Ready to Get Started? Click on Pricing and Take Advantage of Huge Spring Savings: Only $34 a Month with No Contract, Everything You Need!"
            </Typography>
          </Box>

          <Box sx={{ textAlign: 'center', mt: 4, pb: 2 }}>
            <OpenButton
              sx={{
                paddingLeft: '20px',
                paddingRight: '20px',
                width: 'fit-content',
                textTransform: 'unset',
                fontWeight: "bold",

              }}
              onClick={handleOpenPricing}
            >
              Get Started
            </OpenButton>
          </Box>
          <Box sx={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", width: "calc(100% - 96px)", position: "absolute", top: 288, display: { xs: "none", md: "block" }, height: "140px", border: "2px solid #1677AE", ml: 12, borderRightWidth: "0px" }}></Box>
          <Stack
            flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
            sx={{ p: { sm: 2, xs: 2, md: 5 } }}


          >
            <Box
              component={'img'}
              src='/static/rejection_free/calendar.png'
              sx={{
                height: '100px',
                objectFit: 'contain',
                mr: { sm: 0, xs: 0, md: '-5px' },
                zIndex: 1,
                mt: { sm: 0, xs: 0, md: 3 },
                backgroundColor: { sm: "unset", md: "#074766" },
                borderRadius: "10px"
              }}
            />

            <Stack alignContent={"center"} alignItems={"center"} sx={{ mt: { sm: 0, xs: 0, md: 3 }, width: "100%", textAlign: "center" }} >
              <Typography
                sx={{
                  color: '#26DDE9',
                  fontWeight: "bold",
                  fontSize: '1.5rem',
                  pt: 1,
                  textAlign: 'center',
                }}
              >
                Questions? We've got answers..
              </Typography>
              <Button
                sx={{
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  width: 'fit-content',
                  textTransform: 'unset',
                  fontWeight: "bold",
                  backgroundImage: 'linear-gradient(0deg, #ffffff 0%, #FCC83A 100%)',
                  color: "#000000",
                  borderRadius: "30px",
                  fontSize: "small",
                  "&:hover": {
                    background: "#ffffff",
                  },
                  minWidth: "122px"
                }}
                onClick={onOpenNetworkLandingPage}
              >
                Let's Chat
              </Button>


            </Stack>


            {referrUserInfo && (
              <Box
                sx={{
                  textAlign: 'center',
                  mt: { md: 0.2, xs: 4 },
                  zIndex: 100
                }}
                flexDirection={'column'}
              >
                <Box
                  component={'img'}
                  src={
                    referrUserInfo?.profile_picture
                      ? referrUserInfo?.profile_picture
                      : '/static/images/placeholder_profile.png'
                  }
                  sx={{
                    height: '150px',
                    width: '150px',
                    borderRadius: '75px',
                    border: '4px solid #228FBE',
                  }}
                />
                <Typography
                  sx={{
                    mt: 1,
                    fontWeight: 'bold',
                    color: '#ffffff',

                  }}
                >
                  {referrUserInfo ? referrUserInfo.name : ''}
                </Typography>
                <Typography
                  sx={{
                    mb: 0.5,
                    color: "#ffffff",

                  }}
                >
                  {referrUserInfo ? referrUserInfo.occupation : ''}
                </Typography>
              </Box>
            )}

          </Stack>
        </Box>
      </section>


      {
        openTutorialVideo && (
          <VideoTutorialModel
            key={`open-tutorial-video-model-${openTutorialVideo.id}`}
            onClose={onCloseTutorialVideo}
            toggle={togglenTutorialVideo}
            tutorialVideoItem={{
              ...openTutorialVideo,
              description: '',
              tag: '',
              status: 1,
              migrate_s3: 1,
            }}
            onVideoPlay={handleOnVideoPlay}
            onVideoPause={handleOnVideoPlay}
          />
        )
      }

      <ContactFormModal
        onClose={onCloseContactForm}
        toggle={toggleContactForm}
        onSubmitSuccessRedirect={false}
        onSubmitSuccess={handleContactFormSubmitSuccess}
        source={ContactFormSourceType.WHAT_IS_KNOCARD}
      />

      <GroupNetworkModal
        onClose={onCloseGroup}
        toggle={toggleGroup}
        affiliateLink={referredByLink}
        userInfo={referrUserInfo}
      />

      <NetworkLandingPageConfirmationModal
        onClose={onCloseNetworkLandingPage}
        toggle={toggleNetworkLandingPage}
        link={calendarLink}
        userInfo={referrUserInfo}
      />
    </React.Fragment >
  );
};

export default NetworkLandingPage;
