import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { AffiliateLandingPageVideoResponse } from './interface';
import { toastHandler } from '../../../utils/toastHandler';


export const getAffiliateLandingPageVideos = createAsyncThunk(
  'affiliateLandingPageVideo/getAffiliateLandingPageVideos',
  async (_,
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<AffiliateLandingPageVideoResponse>(
        `/api/affiliate-landing-page-videos`

      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


