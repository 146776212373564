import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';

import styles from '../../styles/Reports.module.css';
import {
  AffiliateLandingPageWatchVideoItem,
  ShareAffiliateLandingPageItem,
} from '../../store/reports/api/interface';
import { dateFormate } from '../../utils/format';
import { useCollapse } from 'react-collapsed';
import { getAffiliateLandingPageWatchVideoReport } from '../../store/reports/api';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../../store';
import { isLandingPageShareItem } from '../../utils/utilMethods';
import LandingPageVideoVideItem from './LandingPageVideoVideItem';
import { AppColors } from '../../utils/utils';



interface IProps {
  shareAffiliateLandingPageItem: ShareAffiliateLandingPageItem;
  index: number;
  selectedTimeFilter: string;
  selectedReportUser?: number;
}

const AffiliateLandingPageItem: React.FC<IProps> = ({
  shareAffiliateLandingPageItem,
  selectedReportUser
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isExpanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const [affiliateLandingPageWatchVideoReportList, setAffiliateLandingPageWatchVideoReportList] = useState<AffiliateLandingPageWatchVideoItem[]>([]);
  const { user } = useAppSelector((state) => state?.auth);

  useEffect(() => {
    if (isExpanded && affiliateLandingPageWatchVideoReportList.length === 0 && !isLoading) {
      fetchMediaSharesPostDetail(false);
    }
  }, [isExpanded]);

  const fetchMediaSharesPostDetail = async (isLoadMore: boolean) => {

    try {


      setIsLoading(true);
      const response = await dispatch(
        getAffiliateLandingPageWatchVideoReport({
          user_id: selectedReportUser ? selectedReportUser : user?.id ?? 0,
          days: 180,
          module: shareAffiliateLandingPageItem.share_type,
          sort_marker: (shareAffiliateLandingPageItem.targetable_type == "App\\Models\\User") ? `U${shareAffiliateLandingPageItem.target_id ?? 0}` : `C${shareAffiliateLandingPageItem.target_id ?? 0}`
        })
      ).unwrap();
      if (
        response.status === 200 &&
        response.data.status &&
        response.data.status === 'success'
      ) {

        setAffiliateLandingPageWatchVideoReportList(response.data.data || []);
      }
    } catch (e) { }
    setIsLoading(false);
  };


  return (
    <Box style={{ width: '100%' }}>
      <Box
        className={styles.itemContainer}
        sx={{
          background: AppColors.grayZeroColor,
          border: `solid 1px ${AppColors.strokeColor}`,
          mt: 0.6, borderRadius: "6px"
        }}
        {...getToggleProps({
          onClick: () => {
            setExpanded(!isExpanded);
          },
        })}
      >
        <img
          alt='pic'
          src={
            shareAffiliateLandingPageItem.user?.profile_picture ??
            shareAffiliateLandingPageItem.user?.user?.profile_picture ??
            '/static/images/placeholder_profile.png'
          }
          className={styles.knoSmilie}
        />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "1rem", fontWeight: 400 }}>
            {
              shareAffiliateLandingPageItem.user ? `${shareAffiliateLandingPageItem.user?.first_name ?? ''} ${shareAffiliateLandingPageItem.user?.last_name ?? ''}` : "KnoCard"}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", color: AppColors.grayTwoColor }}>
            {(shareAffiliateLandingPageItem.share_type == "network_landing_page") ? "Share Grow your virtual business" : (shareAffiliateLandingPageItem.share_type == "group_landing_page") ? "Share Group landing page" : "Share What is KnoCard"}
          </Typography>
        </Box>

        <Box className={styles.itemContainerContent} alignItems={'end'} sx={{ pl: 1 }}>
          <Box sx={{ fontSize: '12px', textAlign: 'right', color: 'gray', fontFamily: "Lato" }}>
            {`${shareAffiliateLandingPageItem.created_at && dateFormate(shareAffiliateLandingPageItem.created_at)} `}
          </Box>
          <Box className={styles.itemContainerContentBody} sx={{ fontWeight: 'inherit' }}>
            &nbsp;
          </Box>
        </Box>


        {isLandingPageShareItem(shareAffiliateLandingPageItem.share_type) && (isExpanded ? (
          <Box
            component={"img"}
            sx={{ mt: 0.5 }}
            src='/static/images/arrow_down.svg'
            alt='chevron_down'
            className={styles.chevronDown}
            style={{ transform: `rotate(180deg)` }}
          />
        ) : (
          <Box
            component={"img"}
            sx={{ mt: 0.5 }}
            src='/static/images/arrow_down.svg'
            alt='chevron_down'
            className={styles.chevronDown}
          />
        ))}
      </Box>
      <Box {...getCollapseProps()} sx={{ ml: 4 }}>
        {affiliateLandingPageWatchVideoReportList &&
          affiliateLandingPageWatchVideoReportList.length > 0 &&
          affiliateLandingPageWatchVideoReportList.map((item, index) => (
            <LandingPageVideoVideItem
              affiliateLandingPageWatchVideoItem={item}
              index={index}
            />
          ))}


        {isLoading && (
          <Stack
            direction={'row'}
            justifyContent={'center'}
            sx={{ m: 1 }}
          >
            {' '}
            <CircularProgress />
          </Stack>
        )}
      </Box>

    </Box>
  );
};

export default AffiliateLandingPageItem;
