import React, { useEffect, useRef, useState } from 'react';

import { Avatar, Box, Button, CircularProgress, IconButton, ImageList, ImageListItem, Menu, MenuItem, Stack, Typography } from '@mui/material';

import InputMessage from './InputMessage';

import { ChatMessage, ChatMessageMedia, ChatUser, SelectedChatMessageMedia } from '../../../store/chat/api/interface';
import { useAppDispatch, useAppSelector } from '../../../store';
import Message from './Message';
import { markAsAllMessageRead, pinUnpinChat, sendNewChatMessage } from '../../../client/FireStoreChat';
import { sendChatMessageNotification } from '../../../store/chat/api';
import { MediaType, NotificationType } from '../../../enum';
import { acceptImageFileTypes, acceptVideoFileTypes } from '../../../constant';
import CloseIcon from '@mui/icons-material/Close';
import { uploadMediaCloudFlare } from '../../../store/mediaUpload/api';
import Compressor from 'compressorjs';
import { AppColors, config } from '../../../utils/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MessageSending from './MessageSending';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { markAsSeenNotificationThread, unreadNotificationCount } from '../../../store/notification/api';
import { showErrorMessage } from '../../../utils/utilMethods';
import ArrowDownIcon from '../../../assets/chat/arrow_down.svg';


interface IProps {
    chatUser: ChatUser;
    chatMessages: ChatMessage[];
    setChatUser: (chatUser?: ChatUser) => void,
}

const ChatSection: React.FC<IProps> = ({ chatUser, chatMessages, setChatUser }) => {
    const dispatch = useAppDispatch();
    const loginUser = useAppSelector((state) => state?.auth?.user);
    const messafeListRef = useRef<null | HTMLDivElement>(null);
    const [message, setMessage] = useState<string>('');
    const [sendingMessage, setSendingMessage] = useState<{ message: string, chatMessageMedia: SelectedChatMessageMedia[] } | undefined>(undefined);
    const [messageMedia, setMessageMedia] = useState<SelectedChatMessageMedia[]>([]);
    const [selectMediaType, setSelectMediaType] = useState<string>('');
    const [isVideoLoading, setVideoLoading] = useState<boolean>(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePinUnpinChat = () => {
        pinUnpinChat(chatUser, Array.isArray(chatUser?.pined_by) && !chatUser?.pined_by?.includes(loginUser?.id ?? 0), loginUser?.id ?? 0);
        handleClose();
    }

    useEffect(() => {
        setMessageMedia([]);
    }, [chatUser?.id]);

    const fileUploader = useRef<any>(null);

    useEffect(() => {
        messafeListRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
        });
    }, [chatMessages]);


    useEffect(() => {
        if (chatUser.chatChannelItem != null && chatUser.chatChannelItem.last_message.from != loginUser?.id && (chatUser.chatChannelItem.last_message.unread ?? 0) > 0) {
            markAsAllMessageRead(chatUser, loginUser?.id ?? 0);
            dispatch(
                markAsSeenNotificationThread({
                    receiver_id: loginUser?.id ?? 0,
                    sender_id: chatUser.id ?? 0
                })
            ).then(() => {
                dispatch(unreadNotificationCount({ receiver_id: loginUser?.id ?? 0 }));
            });
        }
    }, [chatUser.chatChannelItem]);


    const handleSendMessage = async () => {
        if (message.length > 0) {

            let chatMessage = message;
            let messageMediaList = messageMedia;
            setMessage('');
            setMessageMedia([]);
            let chatMessageMedia: ChatMessageMedia[] = []
            setSendingMessage({ message: chatMessage, chatMessageMedia: messageMediaList });
            for (let i = 0; i < messageMediaList.length; i++) {
                let messageMediaItem = messageMediaList[i];

                var mediaResult = await dispatch(uploadMediaCloudFlare({
                    resourceType: 'MESSAGE',
                    ...(messageMediaItem.type == MediaType.video ? { video: messageMediaItem.video } : { image: messageMediaItem.image })
                })).unwrap()
                if (
                    mediaResult.data &&
                    mediaResult.data.status === 'success'
                ) {

                    let tmpMessageMedia: ChatMessageMedia = { link: mediaResult.data.data.link, type: messageMediaItem.type };
                    if (messageMediaItem.type == MediaType.video && messageMediaItem.thumbnail != undefined) {
                        var mediaResult = await dispatch(uploadMediaCloudFlare({
                            resourceType: 'MESSAGE',
                            thumbnail: messageMediaItem.thumbnail
                        })).unwrap()
                        if (
                            mediaResult.data &&
                            mediaResult.data.status === 'success'
                        ) {
                            tmpMessageMedia.thumbnail = mediaResult.data.data.link;
                        }
                    }
                    chatMessageMedia.push(tmpMessageMedia);
                }
            }


            sendNewChatMessage(chatUser, loginUser?.id ?? 0, chatMessage, chatMessageMedia).then((result: ChatMessage) => {
                setSendingMessage(undefined);
                dispatch(sendChatMessageNotification({ message: result.text, notification_type: NotificationType.community, receiver_id: [result.to], sender_id: result.from }))
            });

        }
    }


    const onFileSelect = async (event: any) => {
        var selectedMediaCount = messageMedia.length;
        Array.from(event.target.files).forEach((file: any, index: number) => {
            const fileType = acceptVideoFileTypes.includes(file.type) ? MediaType.video : MediaType.image;
            if (selectedMediaCount < 3) {

                if (fileType == MediaType.image) {
                    //  if (config.CHAT_IMAGE_MAX_SIZE >= (file.size / (1024 * 1024))) {
                    selectedMediaCount += 1;
                    new Compressor(file, {
                        quality: 0.5,
                        success(compressedFile) {
                            setMessageMedia([...messageMedia, {
                                image: compressedFile as File,
                                type: fileType
                            }]);
                        },
                        error(error) {
                            setMessageMedia([...messageMedia, {
                                image: file,
                                type: fileType
                            }]);
                        },
                    })
                    /*} else {
                        showErrorMessage(`Image size exceeds the maximum limit is ${config.CHAT_IMAGE_MAX_SIZE}MB`);
                    }*/
                } else if (fileType == MediaType.video) {
                    if (config.CHAT_VIDEO_MAX_SIZE >= (file.size / (1024 * 1024))) {
                        setVideoLoading(true);
                        selectedMediaCount += 1;
                        const videoURL = URL.createObjectURL(file);

                        // Create a video element to capture a frame for the thumbnail
                        const videoElement = document.createElement('video');
                        videoElement.src = videoURL;

                        // Wait for metadata to load, then capture a frame
                        videoElement.onloadedmetadata = () => {
                            videoElement.currentTime = 5; // Seek to 5 seconds (you can adjust this)
                            videoElement.onseeked = () => {
                                const canvas = document.createElement('canvas');
                                canvas.width = videoElement.videoWidth;
                                canvas.height = videoElement.videoHeight;
                                const context = canvas.getContext('2d');
                                context?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

                                canvas.toBlob((blob) => {

                                    if (blob != null) {
                                        const thumbnailBlob: any = new Blob([blob], { type: 'image/jpeg' }); // You can adjust the type as needed
                                        thumbnailBlob.lastModifiedDate = new Date();
                                        thumbnailBlob.name = `${Date.now()}.jpeg`;

                                        setMessageMedia([...messageMedia, {
                                            video: file,
                                            thumbnail: thumbnailBlob,
                                            type: fileType
                                        }]);
                                    }
                                    // Clean up
                                    URL.revokeObjectURL(videoURL);
                                    videoElement.remove();
                                    setVideoLoading(false);
                                }, 'image/jpeg', 0.6);

                            }
                        }
                    } else {
                        showErrorMessage(`Video size exceeds the maximum limit is ${config.CHAT_VIDEO_MAX_SIZE}MB`);
                    }
                }
            }
        });
    };


    const openMediaSelector = () => {
        fileUploader?.current?.click();
    };

    useEffect(() => {
        if (selectMediaType == MediaType.image || selectMediaType == MediaType.video) {
            openMediaSelector();
        }
    }, [selectMediaType]);


    function onSelectMediaTapped(mediaType: string) {
        if (selectMediaType == mediaType) {
            openMediaSelector();
        } else {
            setSelectMediaType(mediaType);
        }
    }

    const handleRemoveSelectedMedia = (selectedChatMessageMedia: SelectedChatMessageMedia) => {
        setMessageMedia(messageMedia.filter((item) => item != selectedChatMessageMedia));
    }

    return (
        <Box component={'div'} sx={{
            height: '100%',
            maxHeight: "calc(100vh - 120px)",
            overflowY: 'auto'
        }}>
            <Box
                display={'flex'}
                alignItems={'center'}
                sx={{
                    padding: '10px',
                    background: AppColors.whiteColor
                }}
            >
                <IconButton onClick={() => setChatUser()}><Box component={'img'} src={ArrowDownIcon} sx={{ transform: `rotate(90deg)` }} /></IconButton>
                <Avatar
                    alt={chatUser.name}
                    src={chatUser.profile_picture ?? '/static/images/placeholder_profile.png'}
                    sx={{ width: 40, height: 40, mr: 1.5 }}
                />
                <Box flexDirection={'row'} display={'flex'} sx={{ width: "100%" }}>
                    <Stack justifyContent={'center'} sx={{ flexGrow: 1 }}>
                        <Typography

                            sx={{
                                fontWeight: '400',
                                width: '100%',
                                textAlign: 'left',
                            }}
                        >
                            {chatUser?.first_name ?? ''} {chatUser?.last_name ?? ''}
                        </Typography>
                    </Stack>
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem key={"menu-pin-unpin"} selected={false} onClick={handlePinUnpinChat}>
                            {Array.isArray(chatUser?.pined_by) && chatUser?.pined_by?.includes(loginUser?.id ?? 0) ? "Unpin" : "Pin"}
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
            {
                chatMessages.length == 0 && <Box sx={{
                    padding: '10px',
                    height: `calc(100% - 150px - ${(messageMedia.length > 0 || isVideoLoading) ? '105px' : '0px'})`,
                    overflowY: 'scroll',
                }}  >
                    <Stack ref={messafeListRef} alignItems={'center'} >
                        <Avatar
                            alt={chatUser.name}
                            src={chatUser.profile_picture ?? '/static/images/placeholder_profile.png'}
                            sx={{ width: 100, height: 100 }}
                        />
                        <Typography
                            sx={{
                                fontWeight: '500',
                                display: 'inline',
                                color: AppColors.primaryColor,
                            }}
                            component='span'
                        >
                            {chatUser.first_name ?? ''} {chatUser.last_name ?? ''}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '13px',
                                color: AppColors.grayTwoColor,
                            }}
                            component='span'
                        >
                            {chatUser.occupation ?? ''}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: '500',
                                display: 'inline',
                                color: AppColors.grayTwoColor,
                                mt: 5
                            }}
                            component='span'
                        >
                            Say hi to {chatUser.first_name ?? ''}
                        </Typography>
                    </Stack>
                </Box>
            }

            {
                chatMessages.length > 0 && <Box sx={{
                    padding: '10px',
                    height: `calc(100% - 150px - ${(messageMedia.length > 0 || isVideoLoading) ? '105px' : '0px'})`,
                    overflowY: 'scroll',
                }}  >
                    <Stack ref={messafeListRef} >
                        {chatMessages.map((chatMessageItem) => (<Message chatMessage={chatMessageItem} chatUser={chatMessageItem.from == chatUser.id ? chatUser : { ...loginUser }} />))}
                        {sendingMessage && <MessageSending chatMessageMedia={sendingMessage?.chatMessageMedia ?? []} chatUser={loginUser} message={sendingMessage?.message ?? ''} key={'lastmessage'} />}
                    </Stack>
                </Box>
            }
            <Stack  >
                {(messageMedia.length > 0 || isVideoLoading) && <ImageList sx={{ width: '320px', pt: 1 }} cols={3}  >
                    {messageMedia.map((mediaItem, index) => (

                        index < 3 ? <ImageListItem key={`media-${index}`} >
                            <Box
                                component={'img'}
                                src={(mediaItem?.thumbnail != undefined || mediaItem?.image != undefined) ? URL.createObjectURL(mediaItem?.thumbnail ?? (mediaItem.image ?? new Blob())) : ""}
                                alt={''}
                                loading="lazy"
                                sx={{ height: 100, width: 100, objectFit: 'cover' }}
                            />
                            <Stack justifyContent="center" alignContent={'center'} alignItems={'center'} sx={{ height: '100%', width: '100%', position: 'absolute' }}>
                                <Box sx={{ p: 0, borderRadius: '50%', textAlign: 'center', backgroundColor: '#0000009e', color: '#ffffff', height: '25px', width: '25px' }} onClick={() => handleRemoveSelectedMedia(mediaItem)}><CloseIcon /></Box></Stack>

                        </ImageListItem> : <></>

                    ))}

                    {isVideoLoading && <ImageListItem key={`media-loading`} >

                        <VideoLibraryIcon sx={{ height: 100, width: 100, objectFit: 'cover', color: '#088ac6' }} />
                        <Stack justifyContent="center" alignContent={'center'} alignItems={'center'} sx={{ height: '100%', width: '100%', position: 'absolute' }}>
                            <Box sx={{ p: 0, borderRadius: '50%', textAlign: 'center', backgroundColor: '#0000009e', color: '#ffffff', height: '40px', width: '40px' }}><CircularProgress /></Box></Stack>

                    </ImageListItem>}
                </ImageList>}</Stack>
            <Box
                component={'div'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <InputMessage message={message} setMessage={setMessage} sendMessage={handleSendMessage} selectMedia={onSelectMediaTapped} isMediaSelectionEnable={messageMedia.length < 3} />
                <input type="file" id="file" ref={fileUploader} onClick={(e: any) => e.currentTarget.value = ""} onChange={onFileSelect} style={{ display: "none" }} accept={[...(selectMediaType == MediaType.video ? acceptVideoFileTypes : acceptImageFileTypes)].join(',')} />
            </Box>
        </Box >
    );
};

export default ChatSection;
