import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { TrainingVideoResponse } from './interface';


export const getTrainingVideos = createAsyncThunk(
  'trainingVideo/getTrainingVideos',
  async (_,
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<TrainingVideoResponse>(
        `/api/training-videos`

      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


