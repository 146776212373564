import {
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';

import styles from '../styles/Reports.module.css';
import Page from '../components/Page';
import { useEffect, useRef, useState } from 'react';
import useWindowSize from '../utils/useWindowSize';
import { courseVideoTypeTabs } from '../utils/utils';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../store';
import { getCourseVideos, saveCourseVideoView } from '../store/courseVideo/api';
import moment from 'moment';
import { CourseVideoItem } from '../store/courseVideo/api/interface';
import { secondsToHMS } from '../utils/utilMethods';
const PromoterDash = () => {

  const dispatch = useDispatch<AppDispatch>();
  const { data: courseVideos, isLoading, isLoadMore, total } = useAppSelector((state) => state?.courseVideo);

  const handleTabChange = (event: React.SyntheticEvent, position: number) => {
    try {
      setCourseTypePosition(position);
    } catch (e) { }
  };

  const handleLoadMore = (pageNumber = page, isReload = false) => {
    if (!isLoading || isReload) {
      dispatch(getCourseVideos({ page: isReload ? 1 : pageNumber, courseType: courseVideoTypeTabs[courseTypePosition].code }));
    }
  };


  useEffect(() => {
    handleLoadMore(1, true);
  }, []);

  const [page, setPage] = useState(1);
  const [courseTypePosition, setCourseTypePosition] = useState(0);
  const [selectedCourseVideoItem, setSelectedCourseVideoItem] = useState<CourseVideoItem | undefined>();
  const videoRef = useRef<any>(null);
  const windowSize = useWindowSize();
  const [tabMinWidth, setTabMinWidth] = useState(90);
  const matches = useMediaQuery('(max-width:600px)');

  const [activeVideoPlayTime, setActiveVideoPlayTime] = useState(0);
  const [activeVideoTime, setActiveVideoTime] = useState(0);

  useEffect(() => {
    const newWidth = matches ? windowSize.width / 5 : 90;
    setTabMinWidth(newWidth);
  }, [windowSize]);

  useEffect(() => {
    handleLoadMore(1, true);
  }, [courseTypePosition]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  useEffect(() => {
    handleLoadMore(page, false);
  }, [page]);

  const onVideoSelect = (courseVideoItem: CourseVideoItem) => {

    if (activeVideoPlayTime > 0) {
      saveCouseVideoView();
    }
    setSelectedCourseVideoItem(courseVideoItem);
  }

  useEffect(() => {
    if (selectedCourseVideoItem == undefined && courseVideos.length > 0) {
      setSelectedCourseVideoItem(courseVideos[0]);
    }
  }, [courseVideos]);


  const saveCouseVideoView = () => {
    if (activeVideoTime > 0 && selectedCourseVideoItem?.id) {
      const currentPlayTime = activeVideoPlayTime;
      const totalPlayTime = activeVideoTime;
      const courseVideoId = selectedCourseVideoItem.id;


      console.log(currentPlayTime);
      console.log(totalPlayTime);

      setActiveVideoTime(0);
      setActiveVideoPlayTime(0);
      const completePercentage = (Math.floor(currentPlayTime) === Math.floor(totalPlayTime)) ? 100 : Number(((currentPlayTime / totalPlayTime) * 100).toFixed(2));
      dispatch(saveCourseVideoView({
        complete: completePercentage,
        video_id: courseVideoId,
        watch_duration: secondsToHMS(currentPlayTime)
      }));

    }
  }


  const handleVideoTimeUpdate = () => {
    if (activeVideoPlayTime != videoRef.current.duration) {
      setActiveVideoTime(videoRef.current.duration ?? 0);
    }
    setActiveVideoPlayTime(videoRef?.current?.currentTime ?? 0);
  };





  return (
    <>
      <Page title='Dashboard | KnoCard' showAppBar={true}>
        <Container maxWidth='xl'>
          <Box className={styles.mainContent} marginTop={5}>

            <Grid container >
              <Grid item sm={9} xs={12}  >
                {selectedCourseVideoItem && <Grid container >
                  <Grid item sm={6} xs={12} >
                    <video
                      key={`course-video-${selectedCourseVideoItem?.id ?? 0}`}
                      controls
                      ref={videoRef}
                      controlsList="nodownload"
                      width="100%"
                      height="100%"
                      className="object-fit-contain course-video"
                      poster={selectedCourseVideoItem.thumb_link ?? ""}
                      //onPlay={() => handleOnplayVideo(content.id)}
                      onTimeUpdate={handleVideoTimeUpdate}
                      onEnded={saveCouseVideoView}
                      onPause={saveCouseVideoView}
                    >
                      <source src={selectedCourseVideoItem.link ?? ""} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>

                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ p: 2 }}>
                    <Typography sx={{ fontSize: "1.3rem" }}>{selectedCourseVideoItem.title ?? ""}</Typography>
                    <Card>
                      <Typography sx={{ fontSize: "medium", p: 1.5 }}>{selectedCourseVideoItem.description ?? ""}</Typography>
                    </Card>
                  </Grid>
                </Grid>}

                <Grid item xs={12} sx={{ backgroundColor: "#22DFA5" }}>
                  <Tabs
                    value={courseTypePosition}
                    onChange={handleTabChange}
                    TabIndicatorProps={{ sx: { display: 'none' } }}
                    variant='scrollable'
                    TabScrollButtonProps={{ sx: { color: '#000000' } }}
                  >
                    {
                      courseVideoTypeTabs.map((item, idx) => (<Tab
                        key={`course-type-${idx}`}
                        label={item.title}
                        className='tab-class'
                        value={idx}
                        sx={{
                          minWidth: { xs: `${tabMinWidth}px`, sm: '90px' },
                          paddingLeft: { xs: 1 },
                          paddingRight: { xs: 1 },
                          width: {
                            md: '20%',
                            xs: '25%',
                          },
                          color: "#ffffff",
                          '&.Mui-selected': {
                            backgroundColor: '#17AD7F',
                            color: "#ffffff"
                          },
                        }}
                      />))
                    }
                  </Tabs>
                </Grid>

                <Grid item xs={12}>
                  <TableContainer>
                    <Table  >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold", color: "#042C42", p: 1 }}>Name</TableCell>
                          <TableCell sx={{ fontWeight: "bold", color: "#042C42", p: 1 }} >Length</TableCell>
                          <TableCell sx={{ fontWeight: "bold", color: "#042C42", p: 1 }}  >Views</TableCell>
                          <TableCell sx={{ fontWeight: "bold", color: "#042C42", p: 1 }}  >Completed</TableCell>
                          <TableCell sx={{ fontWeight: "bold", color: "#042C42", p: 1 }} >Last Viewed</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {isLoading ? (<TableRow sx={{ background: "#ffffff", borderRadius: "5px" }}>
                          <TableCell colSpan={5} ><Box display='flex' justifyContent='center'>
                            <CircularProgress />
                          </Box></TableCell>

                        </TableRow>) : courseVideos.map((item, index) => {
                          return (<TableRow sx={{ background: "#ffffff", borderRadius: "5px" }} onClick={() => { onVideoSelect(item) }}>
                            <TableCell sx={{ p: 1 }} >{item.title}</TableCell>
                            <TableCell sx={{ p: 1 }} >{item.duration}</TableCell>
                            <TableCell sx={{ p: 1 }} >{item.total_views ?? 0}</TableCell>
                            <TableCell sx={{ p: 1 }} >{((item.watchPercentage ?? 0) >= 100) ? (<Box component={"img"} src="/static/images/promoter_dash/completed.png" sx={{ height: "40px" }} />) : (<Box sx={{ backgroundImage: "url('/static/images/promoter_dash/pending.png')", height: "50px", width: "50px", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} >{item.watchPercentage}%</Box>)}</TableCell>
                            <TableCell sx={{ p: 1 }} >{item.lastWatchAt ? moment(`${item.lastWatchAt}`).local().format("MM/DD/YYYY  hh:mm a") : ""}</TableCell>
                          </TableRow>);
                        })}
                      </TableBody>
                      <TablePagination
                        rowsPerPageOptions={[15]}
                        count={total}
                        rowsPerPage={15}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        sx={{
                          '.MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-selectIcon': {
                            display: 'none',
                          },
                        }}
                      />
                    </Table>
                  </TableContainer>
                </Grid>

              </Grid>
              <Grid item sm={3} xs={12} sx={{ gap: 0 }}>
                <Box sx={{ width: "100%", display: "grid", justifyItems: "center", background: "#ffffff", borderRadius: "10px", pt: 1, pb: 2, mb: 2 }}>

                  <Box component={"img"} sx={{ width: { sm: "90%", xs: "95%" }, mt: 1.5 }} src="/static/images/promoter_dash/ultimate_coach.png" />
                  <Box component={"img"} sx={{ width: { sm: "90%", xs: "95%" }, mt: 1.5 }} src="/static/images/promoter_dash/ceo_chat.png" />
                  <Box sx={{ width: { sm: "90%", xs: "95%" }, backgroundColor: "#22DFA5", textAlign: "center", borderRadius: "10px", mt: 1.5, pt: 2, pb: 1 }} >
                    <Box component={"img"} sx={{ height: { sm: "100px", xs: "88px" } }} src="/static/images/promoter_dash/commissions.png" />
                    <Typography sx={{ color: "#02245F", fontWeight: "bold", fontSize: "large" }}>Commissions</Typography>
                  </Box>
                  <Box component={"img"} sx={{ width: { sm: "90%", xs: "95%" }, mt: 1.5 }} src="/static/images/promoter_dash/sales_tools.png" />


                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Page >
    </>
  );
};
export default PromoterDash;
