import { Timestamp } from 'firebase/firestore';
import { STATUS_TYPE } from '../../../enum';
import { UserInfo } from '../../userInfo/api/interface';


export interface ChatUserResponse {
  status: string;
  data: {
    users: UserInfo[]
  };
}


export interface ChatUser {
  id?: number;
  chatId?: string;
  name?: string;
  username?: string;
  first_name?: string;
  last_name?: string;
  lastMessageTime?: Timestamp;
  email?: string;
  profile_picture?: string;
  occupation?: string;
  pined_by?: number[];
  lastMessage?: LastMessage;
  metaTags?: string;
  chatChannelItem?: ChatChannel;
}



export interface ChatChannel {
  created_at?: Timestamp;
  updated_at?: Timestamp;
  users?: number[];
  pined_by?: number[];
  id?: string;
  last_message: LastMessage;
}

export interface LastMessage {
  message?: string;
  from?: number;
  unread?: number;
  time?: Timestamp;
}

export interface ChatMessage {
  time: Timestamp;
  media: ChatMessageMedia[];
  from: number;
  to: number;
  text: string;
  type?: string;
  isShared: boolean;
}

export interface ChatMessageMedia {
  link?: string;
  thumbnail?: string;
  type?: string;
}


export interface SelectedChatMessageMedia {
  video?: File;
  thumbnail?: File;
  image?: File;
  type: string;
}

interface InitialState {
  userList: UserInfo[];
  chatChannels: ChatChannel[];
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  page: number;
  isLoadMore: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

export const initialState: InitialState = {
  userList: [],
  chatChannels: [],
  status: 'idle',
  isError: false,
  isLoading: false,
  page: 1,
  isLoadMore: false,
  isSuccess: false,
  errorMessage: '',
};
