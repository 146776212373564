import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { CreateUpdateCompanyExternalLinkRequest, ViewCompanyExternalLinkRequest } from './interface';

export const getCompanyExternalLinks = createAsyncThunk(
    'companyExternalLinks/getCompanyExternalLinks',
    async (companyId: number, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/api/company-external-links?company_id=${companyId}`);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);



export const removeCompanyExternalLinks = createAsyncThunk(
    'companyExternalLinks/removeCompanyExternalLinks',
    async (linkId: number, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.delete(`/api/company-external-links/${linkId}`);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);


export const createUpdateCompanyExternalLinks = createAsyncThunk(
    'companyExternalLinks/createUpdateCompanyExternalLinks',
    async (data: CreateUpdateCompanyExternalLinkRequest, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(`/api/company-external-links${data.id != undefined ? `/${data.id}` : ""}`, data);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);



export const viewCompanyExternalLink = createAsyncThunk(
    'companyExternalLinks/viewCompanyExternalLink',
    async (data: ViewCompanyExternalLinkRequest, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(`/api/company-external-links/view-link`, data);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);