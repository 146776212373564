import { ResourceType, STATUS_TYPE } from '../../../enum';

export interface UploadMediaRequest {
  resourceType: ResourceType;
  image?: File;
  video?: File;
  thumbnail?: File;
  resourceId?: number;
  knocardPosition?: number;
  knocardType?: String;
}

interface InitialState {
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

export const initialState: InitialState = {
  status: 'idle',
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
};
