import { Box } from '@mui/material';
import { AppColors } from '../utils/utils';

import BusinessIcon from '../assets/reports/business.svg';
import CallIcon from '../assets/reports/call.svg';
import LinkIcon from '../assets/reports/link.svg';
import MailIcon from '../assets/reports/mail.svg';
import MediaIcon from '../assets/reports/media.svg';
import PersonIcon from '../assets/reports/person.svg';
import VideoIcon from '../assets/reports/video.svg';



export function getActivityIconAndTitle(module: string) {
    const iconColor = AppColors.primaryColor;

    const iconStyle = { color: iconColor, height: '30px', width: '30px', mr: 1 };

    let actionIcon = <Box component={'img'} src={PersonIcon} sx={{ ...iconStyle }} />;;
    let actionTitle = "";

    if (module == 'profile') {
        // If action is copied number
        actionIcon = <Box component={'img'} src={PersonIcon} sx={{ ...iconStyle }} />;;
        actionTitle = 'Contact page';
    } else if (module == 'contact') {
        // If action is copied number
        actionIcon = <Box component={'img'} src={CallIcon} sx={{ ...iconStyle }} />;;
        actionTitle = 'Contact page';
    } else if (module == 'playlist') {
        // If action is copied number
        actionIcon = <Box component={'img'} src={VideoIcon} sx={{ ...iconStyle }} />;;
        actionTitle = 'Playlist page';
    } else if (module == 'business_page') {
        // If action is copied number
        actionIcon = <Box component={'img'} src={BusinessIcon} sx={{ ...iconStyle }} />;;
        actionTitle = 'Social page';
    } else if (module == 'external_page' || module == 'external_link') {
        // If action is copied number
        actionIcon = <Box component={'img'} src={LinkIcon} sx={{ ...iconStyle }} />;
        actionTitle = 'External page';
    } else if (module == 'profile') {
        // If action is copied number
        actionIcon = <Box component={'img'} src={PersonIcon} sx={{ ...iconStyle }} />;;
        actionTitle = 'Contact page';
    } else if (module == 'images' ||
        module == 'collage' ||
        module == 'photo_gallery') {
        // If action is copied number
        actionIcon = <Box component={'img'} src={MediaIcon} sx={{ ...iconStyle }} />;;
        actionTitle = 'Media Page';
    } else if (module == 'business_email') {
        // If action is copied number
        actionIcon = <Box component={'img'} src={MailIcon} sx={{ ...iconStyle }} />;;
        actionTitle = 'Copied your business email';
    } else if (module == 'business_phone') {
        // If action is copied number
        actionIcon = <Box component={'img'} src={CallIcon} sx={{ ...iconStyle }} />;;
        actionTitle = 'Copied your business phone';
    } else if (module == 'phone_number') {
        // If action is copied number
        actionIcon = <Box component={'img'} src={CallIcon} sx={{ ...iconStyle }} />;;
        actionTitle = 'Copied your phone';
    } else if (module == 'business_mobile_phone') {
        // If action is copied number
        actionIcon = <Box component={'img'} src={CallIcon} sx={{ ...iconStyle }} />;;
        actionTitle = 'Copied your business mobile phone';
    } else if (module == 'fax') {
        // If action is copied number
        actionIcon = <Box component={'img'} src={CallIcon} sx={{ ...iconStyle }} />;;
        actionTitle = 'Copied your fax number';
    } else if (module == 'personal_email') {
        // If action is copied number
        actionIcon = <Box component={'img'} src={MailIcon} sx={{ ...iconStyle }} />;
        actionTitle = 'Copied your personal email';
    } else if (module == 'location') {
        // If action is copied number
        actionIcon = <Box component={'img'} src={MailIcon} sx={{ ...iconStyle }} />;
        actionTitle = 'Copied your location';
    } else if (module == 'lead') {
        actionIcon = <Box component={'img'} src={MailIcon} sx={{ ...iconStyle }} />;
        actionTitle = 'Lead';
    } else if (module == 'videos') {
        actionIcon = <Box component={'img'} src={VideoIcon} sx={{ ...iconStyle }} />;
        actionTitle = 'Video Page';
    } else if (module == 'affiliate_landing_page_video') {
        actionIcon = <Box component={'img'} src={VideoIcon} sx={{ ...iconStyle }} />;
        actionTitle = 'What is KnoCard';
    } else if (module == 'network_landing_page_video') {
        actionIcon = <Box component={'img'} src={VideoIcon} sx={{ ...iconStyle }} />;
        actionTitle = 'Grow your virtual business';
    } else if (module == 'affiliate_landing_page') {
        actionIcon = <Box component={'img'} src={LinkIcon} sx={{ ...iconStyle }} />;
        actionTitle = 'Affiliate landing page';
    } else if (module == 'rejection_free_landing_page') {
        actionIcon = <Box component={'img'} src={LinkIcon} sx={{ ...iconStyle }} />;
        actionTitle = 'Rejection free landing page';
    } else if (module == 'network_landing_page') {
        actionIcon = <Box component={'img'} src={LinkIcon} sx={{ ...iconStyle }} />;
        actionTitle = 'Network landing page';
    } else if (module == 'group_landing_page') {
        actionIcon = <Box component={'img'} src={LinkIcon} sx={{ ...iconStyle }} />;
        actionTitle = 'Group landing page';
    }

    return { actionIcon, actionTitle };
}