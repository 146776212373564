import { LoadingButton } from '@mui/lab';
import {
  FilledTextFieldProps,
  Grid,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextField,
  TextFieldVariants,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Transition } from './Transition';
import { useFormik } from 'formik';
import { useAppDispatch } from '../store';
import { KnoCardLinkSessionRequestItem } from '../store/knocardLinkSession/api/interface';
import { patchKnoCardLinkSessionRequest } from '../store/knocardLinkSession/api';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { JSX } from 'react/jsx-runtime';
import { useEffect } from 'react';
import { sessionUpdated } from '../store/knocardLinkSession/knocardLinkSessionSlice';
import { showSuccessMessage } from '../utils/utilMethods';
import { AppColors } from '../utils/utils';

interface IProps {
  toggle: boolean;
  onClose: () => void;
  selectedSessionItem: KnoCardLinkSessionRequestItem | null | undefined;
}

const AddSessionDatesModal: React.FC<IProps> = ({
  toggle,
  onClose,
  selectedSessionItem,
}) => {
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    values,
    resetForm,
    isSubmitting,
    errors,
    touched,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: {
      next_coaching: new Date(),
      next_followup: new Date(),
    },
    onSubmit: async (values) => {
      try {
        var result = await dispatch(
          patchKnoCardLinkSessionRequest({
            next_coaching: values?.next_coaching,
            next_followup: values?.next_followup,
            id: selectedSessionItem?.id ?? 0,
          })
        ).unwrap();
        if (result.data.data != null) {
          showSuccessMessage('Dates Updated Successfully');
          dispatch(sessionUpdated(result.data.data));
        }
        onClose();
        resetForm();
      } catch (error: any) { }
    },
  });

  useEffect(() => {
    if (toggle) {
      if (selectedSessionItem?.next_coaching) {
        setFieldValue('next_coaching', selectedSessionItem?.next_coaching);
      }
      if (selectedSessionItem?.next_followup) {
        setFieldValue('next_followup', selectedSessionItem?.next_followup);
      }
    }
  }, [toggle]);

  return (
    <Dialog
      fullWidth
      open={toggle}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        resetForm();
        onClose();
      }}
      aria-describedby='alert-dialog-slide-description'
      key={`note${selectedSessionItem?.id ?? 0}`}
    >
      <DialogTitle
        sx={{
          backgroundColor: AppColors.primaryColor,
          color: 'white',
        }}
      >
        Add Session
      </DialogTitle>
      <form
        onSubmit={handleSubmit}
        autoComplete='off'
      >
        <DialogContent>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={12}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={'Next Coaching'}
                  value={values.next_coaching ? values.next_coaching : null}
                  onChange={(newValue: Date | null) => {
                    // newValue?.setHours(23, 59, 59, 999);
                    setFieldValue('next_coaching', newValue);
                  }}
                  inputFormat='MMM dd, yyyy'
                  renderInput={(
                    params: JSX.IntrinsicAttributes & {
                      variant?: TextFieldVariants | undefined;
                    } & Omit<
                      | OutlinedTextFieldProps
                      | FilledTextFieldProps
                      | StandardTextFieldProps,
                      'variant'
                    >
                  ) => (
                    <TextField
                      {...params}
                      fullWidth
                      name='next_coaching'
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={'Next Follow up'}
                  value={values.next_followup ? values.next_followup : null}
                  onChange={(newValue: Date | null) => {
                    // newValue?.setHours(23, 59, 59, 999);
                    setFieldValue('next_followup', newValue);
                  }}
                  inputFormat='MMM dd, yyyy'
                  renderInput={(
                    params: JSX.IntrinsicAttributes & {
                      variant?: TextFieldVariants | undefined;
                    } & Omit<
                      | OutlinedTextFieldProps
                      | FilledTextFieldProps
                      | StandardTextFieldProps,
                      'variant'
                    >
                  ) => (
                    <TextField
                      {...params}
                      fullWidth
                      name='next_followup'
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ paddingX: 3 }}>
          <LoadingButton
            type='submit'
            loading={isSubmitting}
            variant='contained'
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddSessionDatesModal;
