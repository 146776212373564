import { LoadingButton } from "@mui/lab";
import { Avatar, Box, CircularProgress, Button, Grid, Paper, TextField, Typography, IconButton, Menu, MenuItem, Divider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/system";
import { Transition } from "../Transition";
import CancelIcon from '@mui/icons-material/Cancel';
import { AppDispatch, useAppDispatch } from "../../store";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormikProvider, useFormik } from "formik";

import moment from 'moment';
import { getCapitalizedString, showSuccessMessage } from "../../utils/utilMethods";
import { isNotEmpty } from "../../utils/valid";
import { EngageActivity, EngageUserFollowupNote } from "../../store/engagePipeline/api/interface";
import { useDispatch } from "react-redux";
import { deleteUserFollowupNote, getEngageUserFollowupNote } from "../../store/engagePipeline/api";
import useToggle from "../../hooks/useToggle";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmationModel from "./ConfirmationModel";
import { reload } from "firebase/auth";
import { AppColors } from "../../utils/utils";

interface IPropsPiplineNoteItem {
    contactProfile: string,
    idx: number;
    userFollowUpNote: EngageUserFollowupNote;
    onEditEngageUserFollowupNote: (engageUserFollowupNote?: EngageUserFollowupNote) => void;
    setDeleteEngageUserFollowupNote: (engageUserFollowupNote?: EngageUserFollowupNote) => void;
}




const PiplineNoteItem: React.FC<IPropsPiplineNoteItem> = ({ idx, userFollowUpNote, contactProfile, onEditEngageUserFollowupNote, setDeleteEngageUserFollowupNote }) => {
    const [followupNoteMenuAnchorEl, setFollowupNoteMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const followupNoteMenuOpen = Boolean(followupNoteMenuAnchorEl);
    const handleFollowupNoteMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setFollowupNoteMenuAnchorEl(event.currentTarget);
    };

    const handleFollowupNoteMenuClose = (userFollowUpNote?: EngageUserFollowupNote, isDeleteNote?: Boolean) => {

        if (isDeleteNote == false) {
            onEditEngageUserFollowupNote(userFollowUpNote);
        } else if (isDeleteNote == true) {
            setDeleteEngageUserFollowupNote(userFollowUpNote);
        }
        setFollowupNoteMenuAnchorEl(null);
    };

    return (<React.Fragment key={`pipline-note-item-${idx}`}>
        <Box

            sx={{ p: "10px", position: 'relative', }}
        >

            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={followupNoteMenuOpen ? 'long-menu' : undefined}
                aria-expanded={followupNoteMenuOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleFollowupNoteMenuClick}
                sx={{ position: 'absolute', right: 10, top: 10 }}
            >
                <MoreVertIcon sx={{ color: AppColors.blackColor }} />
            </IconButton>
            <Menu
                id={`long-menu-${userFollowUpNote.id}`}
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={followupNoteMenuAnchorEl}
                open={followupNoteMenuOpen}
                onClose={() => handleFollowupNoteMenuClose()}
                PaperProps={{
                    style: {
                        width: '20ch',
                    },
                }}
            >

                <MenuItem key={'followup-note-edit'} onClick={() => {
                    handleFollowupNoteMenuClose(userFollowUpNote, false)
                }

                }>
                    Edit
                </MenuItem>
                <MenuItem key={'followup-note-delete'} onClick={() => handleFollowupNoteMenuClose(userFollowUpNote, true)} sx={{ color: "red" }}>
                    Delete
                </MenuItem>

            </Menu>

            <Box sx={{ display: "flex", mt: 0.5 }}>
                <Box >
                    <Avatar
                        src={contactProfile}
                        sx={{ height: "50px", width: "50px" }}
                    />
                </Box>
                <Box>
                    <Typography
                        sx={{
                            color: AppColors.grayThreeColor,
                            pl: 1,
                            pr: 3.5,
                            fontSize: "0.9rem",
                        }}
                    >

                        {userFollowUpNote.note && `Note: ${userFollowUpNote.note}`}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "end",
                    color: AppColors.grayTwoColor,
                }}
            >
                <Typography sx={{ pr: 3, fontSize: '0.8rem' }}>
                    {userFollowUpNote.created_at && moment.utc(`${userFollowUpNote.created_at}`).local().format("MMM DD, YYYY  hh:mm a")}
                </Typography>
            </Box>

            <Divider sx={{ mt: 1 }} />
        </Box>
    </React.Fragment>);
}


interface IProps {
    engageActivity: EngageActivity;
    contactProfile: string,
    toggle: boolean;
    onClose: () => void;
    onEditEngageUserFollowupNote: (engageUserFollowupNote?: EngageUserFollowupNote) => void;
    onDeletEngageUserFollowupNote: (engageUserFollowupNote?: EngageUserFollowupNote) => void;
}




const ViewPiplineNoteModal: React.FC<IProps> = ({ engageActivity, contactProfile, toggle, onClose, onEditEngageUserFollowupNote, onDeletEngageUserFollowupNote }) => {

    const dispatch = useDispatch<AppDispatch>();
    const [activePage, setActivePage] = useState<number>(1);

    const [engageUserFollowupNoteList, setEngageUserFollowupNoteList] = useState<Array<EngageUserFollowupNote>>([]);
    const [isEngageUserFollowupNoteLoading, setIsEngageUserFollowupNoteLoading] = useState<boolean>(false);
    const [engageUserFollowupNoteHaseLoadMore, setEngageUserFollowupNoteHaseLoadMore] = useState<boolean>(true);
    const [deleteEngageUserFollowupNote, setDeleteEngageUserFollowupNote] = useState<EngageUserFollowupNote | undefined>(undefined);

    const {
        toggle: togglenDeleteFollowupNote,
        onOpen: onOpenDeleteFollowupNote,
        onClose: onCloseDeleteFollowupNote,
    } = useToggle();

    useEffect(() => {
        if (deleteEngageUserFollowupNote) {
            onOpenDeleteFollowupNote();
        }
    }, [deleteEngageUserFollowupNote]);

    const handleLoadMore = (pageNumber = activePage, isReload = false) => {
        if (isEngageUserFollowupNoteLoading && !isReload) {
            return;
        }


        const contactUserType = engageActivity.target_type == "App\\Models\\User" ? "user" : "contact";
        var contactUserId = engageActivity.target?.id ?? 0;
        setIsEngageUserFollowupNoteLoading(true);
        if (isReload) {
            setActivePage(1);
            setEngageUserFollowupNoteHaseLoadMore(true);
        }
        dispatch(getEngageUserFollowupNote({
            page: isReload ? 1 : activePage,
            contactUserId: contactUserId, contactUserType: contactUserType, engageReportId: undefined
            /*engageReportId: engageActivity.id*/
        })).unwrap().then((result) => {
            setIsEngageUserFollowupNoteLoading(false);
            if (result?.data?.status == "success") {

                setEngageUserFollowupNoteList(activePage == 1 ? result?.data?.data?.data ?? [] : [...engageUserFollowupNoteList, ...(result?.data?.data?.data ?? [])]);
                setActivePage((result?.data?.data?.current_page ?? 0) + 1);
                if ((result?.data?.data?.current_page ?? 0) >= (result?.data?.data?.last_page ?? 0)) {
                    setEngageUserFollowupNoteHaseLoadMore(false);
                }

            }
        }).catch((s) => {
            setIsEngageUserFollowupNoteLoading(false);
        });

    };

    const observer = useRef<IntersectionObserver>();
    const lastElementRef = useCallback(
        // (*)
        (node: HTMLDivElement | null) => {
            if (isEngageUserFollowupNoteLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && engageUserFollowupNoteHaseLoadMore) {
                    handleLoadMore();
                }
            });
            if (node) observer.current.observe(node);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isEngageUserFollowupNoteLoading, engageUserFollowupNoteHaseLoadMore]
    );



    const onCancel = () => {
        onClose();
    };


    useEffect(() => {

        if (toggle) {
            handleLoadMore(1, true);
        }

    }, [toggle]);


    const onDeleteFollowupNoteConfirm = () => {
        if (deleteEngageUserFollowupNote != undefined) {
            const noteId = deleteEngageUserFollowupNote?.id ?? 0;
            dispatch(deleteUserFollowupNote({ id: noteId })).unwrap().then((result) => {
                if (result.status == 200) {
                    /*if (noteId == editEngageUserFollowupNote?.id) {
                        setEditEngageUserFollowupNote(undefined);
                    }*/
                    showSuccessMessage("Follow up note deleted successfully.");
                    const filteredList = engageUserFollowupNoteList.filter((item) => item.id != deleteEngageUserFollowupNote.id);
                    setEngageUserFollowupNoteList(filteredList);
                    onDeletEngageUserFollowupNote(deleteEngageUserFollowupNote)
                }
                setDeleteEngageUserFollowupNote(undefined);
            });
        }
        onCloseDeleteFollowupNote();
    };

    useEffect(() => {
        if (!togglenDeleteFollowupNote && deleteEngageUserFollowupNote) {
            setDeleteEngageUserFollowupNote(undefined);
        }
    }, [togglenDeleteFollowupNote]);


    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'sm'}
                open={toggle}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    onCancel();
                }}
                aria-describedby="alert-dialog-slide-description"
                sx={{
                    mt: { md: "5%", sm: "25%", xs: "25%" },

                }}
            >
                <DialogTitle
                    sx={{

                        color: AppColors.blackColor,
                    }}
                >
                    Notes
                </DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: '10px',
                        top: '6px',
                        zIndex: 100000,
                        color: 'white',
                        alignSelf: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CancelIcon sx={{ color: AppColors.grayTwoColor }} />
                </IconButton>
                <DialogContent sx={{ minWidth: "100%", p: 1 }} >

                    <Stack>

                        <Grid
                            container
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            flexDirection={'column'}

                        >

                            {engageUserFollowupNoteList.map((userFollowUpNote, idx) => {

                                return (
                                    <Box
                                        key={`pni-${idx}`}
                                        ref={(idx + 1 === engageUserFollowupNoteList?.length) ? lastElementRef : undefined}>
                                        <PiplineNoteItem
                                            contactProfile={contactProfile}
                                            idx={idx}
                                            onEditEngageUserFollowupNote={onEditEngageUserFollowupNote}
                                            setDeleteEngageUserFollowupNote={setDeleteEngageUserFollowupNote}
                                            userFollowUpNote={userFollowUpNote}

                                        /></Box>
                                );
                            })}
                            <Stack direction="row"
                                justifyContent="center"
                                alignItems="center"  >
                                {isEngageUserFollowupNoteLoading ? <CircularProgress sx={{ mt: 2, mb: 2 }} /> : engageUserFollowupNoteList.length <= 0 ? <Typography sx={{ marginTop: '50px', marginBottom: '100px' }}>Notes not found.</Typography> : <></>}</Stack>
                        </Grid>

                    </Stack>

                </DialogContent>
            </Dialog >
            {deleteEngageUserFollowupNote && <ConfirmationModel
                cancelButtonText='No'
                confirmButtonText='Yes'
                title='We are going to delete your note.'
                message='Are you sure you want to proceed?'
                onConfirm={onDeleteFollowupNoteConfirm}
                onClose={onCloseDeleteFollowupNote}
                toggle={togglenDeleteFollowupNote}
            />
            }

        </>
    );
};

export default ViewPiplineNoteModal;
