import { STATUS_TYPE } from '../../../enum';

export interface TutorialVideoResponse {
  status: string;
  message: string;
  data: TutorialVideoItem[];
}

export interface TutorialVideoItem {
  id: number,
  title: string,
  description: string,
  position: number,
  type: string,
  link: string,
  status: number,
  created_at: string,
  updated_at: string,
  user_id: number,
  filename: string,
  thumb_name: string,
  thumb_link: string,
  tag: string,
  migrate_s3: number
}

export interface TutorialVideoSearchResult {
  title: string;
  code: string;
  data: TutorialVideoItem[];
}

interface InitialState {
  data: TutorialVideoItem[];
  searchResult: TutorialVideoSearchResult[];
  searchText: string,
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}


export const initialState: InitialState = {
  data: [],
  searchResult: [],
  searchText: '',
  status: 'idle',
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
};
