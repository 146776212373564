import { STATUS_TYPE } from '../../../enum';

export interface CourseVideoResponse {
  status: string;
  message: string;
  data: {
    current_page: number;
    per_page: number;
    last_page: number;
    total: number;
    data: CourseVideoItem[];
  }
}

export interface CourseVideoItem {
  id: number;
  user_id: number;
  title: string;
  description: string;
  duration: string;
  position: number;
  type: string;
  link: string;
  filename: string;
  thumb_name: string;
  thumb_link: string;
  status: number;
  created_at: string;
  updated_at: string;
  total_views?: number;
  watchPercentage?: number;
  lastWatchAt?: Date;
}



interface InitialState {
  data: CourseVideoItem[];
  status: STATUS_TYPE;
  page: number;
  total: number;
  isLoadMore: boolean;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}


export const initialState: InitialState = {
  data: [],
  status: 'idle',
  page: 1,
  total: 0,
  isLoadMore: true,
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
};
