import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  ClickAwayListener,
  Container, Grid, InputAdornment, Stack, Tab, Tabs, TextField, Tooltip, TooltipProps, Typography, styled, tooltipClasses,
} from '@mui/material';
import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import '@splidejs/react-splide/css';
import { trainingTabs } from '../utils/utils';
import { Fragment, useEffect, useState } from 'react';
import useWindowSize from '../utils/useWindowSize';
import SearchIcon from '@mui/icons-material/Search';
import { updateSearchText } from '../store/trainingVideo/trainingVideoSlice';
import { getTrainingVideos } from '../store/trainingVideo/api';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TrainingVideoItem } from '../store/trainingVideo/api/interface';
import VideoTrainingModel from '../components/modal/VideoTrainingModel';
import useToggle from '../hooks/useToggle';



const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',

    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const VideoTraining = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const searchText = useAppSelector((state) => state.trainingVideo.searchText);
  const trainingVideo = useAppSelector((state) => state.trainingVideo.data);
  const searchResult = useAppSelector((state) => state.trainingVideo.searchResult);
  const isLoading = useAppSelector((state) => state.trainingVideo.isLoading);

  useEffect(() => {
    if (trainingVideo?.length == 0 && !isLoading) {
      dispatch(getTrainingVideos())
    }
  }, []);

  const {
    toggle: togglenTrainingVideo,
    onOpen: onOpenTrainingVideo,
    onClose: onCloseTrainingVideo,
  } = useToggle();


  const [activeTab, setActiveTab] = useState<number>(0);
  const [openTrainingVideo, setOpenTrainingVideo] = useState<TrainingVideoItem | undefined>(undefined);


  const [tootltipView, setTootltipView] = useState<number | undefined>(undefined);
  const [showAllVideo, setShowAllVideo] = useState<string | undefined>(undefined);

  const windowSize = useWindowSize();
  const [tabMinWidth, setTabMinWidth] = useState(100);
  useEffect(() => {
    const newWidth = windowSize.width / trainingTabs.length;
    setTabMinWidth(newWidth);
  }, [windowSize]);

  useEffect(() => {

    console.log(tootltipView);

  }, [tootltipView]);

  const inputStyle = {
    borderRadius: 1,
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    try {
      scrolltoVideo(trainingTabs[newValue].code);
    } catch (e) { }
  };

  const handleSearchText = (e: any) => {
    dispatch(updateSearchText(e.target.value));
  };

  const scrolltoVideo = (code: string) => {
    try {
      const section = document.querySelector(`#${code}`) as HTMLElement;
      if (section) {
        const headerHeight = (document.getElementById('header') as any).offsetHeight || 0;
        console.log((section?.offsetTop ?? 0) - headerHeight - (headerHeight * 0.10));
        window.scrollTo({
          top: (section?.offsetTop ?? 0) - headerHeight - (headerHeight * 0.10),
          behavior: 'smooth',
        });
      }
    } catch (e) { }
  };


  useEffect(() => {
    if (openTrainingVideo != undefined) {
      onOpenTrainingVideo();
    }
  }, [openTrainingVideo]);

  useEffect(() => {
    if (!togglenTrainingVideo) {
      setOpenTrainingVideo(undefined);
    }
  }, [togglenTrainingVideo]);

  return (
    <>
      <Page
        title='Training | KnoCard'
        showAppBar={true}
        sx={{ backgroundColor: "#002f41", minHeight: '100vh' }}
      >
        <Container maxWidth='xl' sx={{ mt: 2, backgroundColor: "#002f41", width: "100% !important", maxWidth: "none !important", marginLeft: "0px !important", marginRight: "0px !important" }}>
          <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth"  >
            {trainingTabs.map((item, idx) => {
              return (<Tab label={item.title} key={`training-tabs-${idx}`} sx={{
                color: "#ffffff",
                textTransform: 'none',
                minWidth: { md: '90px', sm: tabMinWidth, xs: tabMinWidth },
                '&.Mui-selected': {
                  color: '#ffffff', // Set the text color of the active tab
                }
              }} />)
            })}
          </Tabs>
          <Box>
            <TextField
              fullWidth
              autoComplete="off"
              name="search_training_video"
              placeholder='Search Topic'
              value={searchText}
              onChange={handleSearchText}
              InputProps={{
                autoComplete: 'off',
                sx: inputStyle,
                endAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon sx={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
              sx={{ mt: 1 }}
            />
          </Box>

          {searchResult.map((trainingItem, idx) => {
            return (
              <><Grid key={`trainingItem-title-${idx}`} container spacing={1}>
                <Grid item id={trainingItem.code} xs={12} sx={{ position: 'relative', mt: 1, mb: 1 }}>
                  <Typography sx={{ color: "#ffffff", mt: 1, mb: 0.5, fontWeight: 'bold' }}>{trainingItem.title}</Typography>
                  <Typography sx={{ position: "absolute", right: 0, top: 16, color: "#088AC7" }} onClick={() => (trainingItem.code != showAllVideo) ? setShowAllVideo(trainingItem.code) : setShowAllVideo(undefined)} >{(trainingItem.code == showAllVideo) ? "Show Less" : "Show All"} </Typography>
                </Grid>
              </Grid>
                <Grid container spacing={1}>

                  {((trainingItem.data.length > 6) ? (trainingItem.data.slice(0, (trainingItem.code == showAllVideo) ? trainingItem.data.length : 4)) : trainingItem.data).map((trainingVideo, index) => {
                    return (<Grid item xs={6} md={3} >
                      <Card key={`training-item-${index}`} sx={{ maxWidth: 345 }}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={trainingVideo.thumb_link}
                          alt={trainingVideo.thumb_name}
                          onClick={() => setOpenTrainingVideo(trainingVideo)}
                        />
                        <ClickAwayListener
                          key={`training-item-${idx}-click-away`}
                          onClickAway={() => (tootltipView == trainingVideo.id) && setTootltipView(undefined)}>
                          <Box component={"div"}>
                            <HtmlTooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              open={tootltipView == trainingVideo.id}
                              onClose={() => setTootltipView(undefined)}
                              title={
                                <Fragment>
                                  <Typography sx={{ color: "#F58227" }}>Description</Typography>
                                  <Typography sx={{ fontSize: "0.8rem", mb: 0.5 }}>{trainingVideo.description}</Typography>
                                </Fragment>
                              }
                            >
                              <CardActionArea
                                onClick={() => { setTootltipView(trainingVideo.id) }}
                              >
                                <CardContent
                                  sx={{
                                    p: 0.5, '&:last-child': { p: 1 }, minHeight: '36px',
                                    backgroundColor: "#4fc1e8",
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}>
                                  <Typography variant="body2" color="text.secondary"
                                    sx={{
                                      overflow: 'hidden',
                                      display: '-webkit-box',
                                      '-webkit-line-clamp': "1",
                                      '-webkit-box-orient': 'vertical',
                                      color: '#000000',
                                      mr: 2.2
                                    }}>
                                    {trainingVideo.title}
                                  </Typography>
                                  <ArrowDropDownIcon sx={{ color: "#F58227", position: "absolute", right: 5, top: 5 }} />
                                </CardContent>
                              </CardActionArea>
                            </HtmlTooltip>
                          </Box>
                        </ClickAwayListener>
                      </Card>
                    </Grid>);
                  })}
                </Grid ></>);
          })}
          <Box
            sx={{ m: 1 }}
          ></Box>
          {isLoading && (
            <Stack direction={'row'} justifyContent={'center'} sx={{ mt: 2 }}>
              <CircularProgress />
            </Stack>
          )}
        </Container>
        {openTrainingVideo && <VideoTrainingModel
          key={`open-training-video-model-${openTrainingVideo.id}`}
          onClose={onCloseTrainingVideo}
          toggle={togglenTrainingVideo}
          trainingVideoItem={openTrainingVideo}
        />}
      </Page >
    </>
  );
};


export default VideoTraining;
