import { FEATURES_LIST } from '../constant';
import { UserFeature } from '../store/userInfo/api/interface';

export const getActiveIndex = (homePageIndex: number, userFeatures: UserFeature[]) => {
    const features = userFeatures.map((userFeature) => userFeature.lookup_id);
    if (![6, 7, 9, 10, 12].includes(homePageIndex)) return 0;
    if (features.includes(homePageIndex)) {
        const allFeatures = FEATURES_LIST.map((feature) => feature.key);
        const index = allFeatures.indexOf(homePageIndex);
        return index >= 0 ? index : 0;
    }
    return 0;
};
