import { Box } from '@mui/system';
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    backgroundColor?: string;
    p?: string | number;
    sx?: any
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, backgroundColor, p = 3, sx = {}, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={sx}
        >
            {value === index && <Box sx={{ p, ...sx, ...(backgroundColor && { backgroundColor }) }}>{children}</Box>}
        </div>
    );
}

export default TabPanel;
