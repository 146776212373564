import {
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import styles from '../styles/Reports.module.css';
import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { AppColors, config } from '../utils/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { checkIsProProOrProOneOrProPlusUser, checkIsProUser } from '../utils/checkPro';
import { formatReportCount, getPlanLabel, inputTextFieldStyle, showSuccessMessage } from '../utils/utilMethods';


import SearchIcon from '../assets/social/search_member.svg';
import { debounce } from 'lodash';
import { clearShareHistory, updateSearchText } from '../store/ShareHistory/shareHistorySlice';
import { getShareHistory } from '../store/ShareHistory/api';
import { getDaysFromFilter } from '../utils/daysConvertor';
import { ShareHistoryItem } from '../store/ShareHistory/api/interface';
import moment from 'moment';
import BlockOverlay from '../components/BlockOverlay';

const ShareHistory = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);
  const { data, page, hasMore, isLoading, searchText } = useAppSelector((state) => state?.shareHistory);
  const observer = useRef<IntersectionObserver>();
  const navigate = useNavigate();

  const [selectedTimeFilter, setSelectedTimeFilter] = useState('Month');
  const timeFilterList = ['Today', 'Week', 'Month', 'All Time'];

  const isProUser = checkIsProProOrProOneOrProPlusUser(user);


  const handleLoadMore = (isReload: boolean, searchKeyWord?: string) => {
    if (isProUser) {
      if (isReload) {
        dispatch(clearShareHistory({}));
      }

      dispatch(getShareHistory({ searchName: (searchKeyWord ?? searchText), pageNumber: isReload ? 1 : (page + 1), days: getDaysFromFilter(selectedTimeFilter) }));
    }
  };

  useEffect(() => {
    handleLoadMore(true);
  }, [selectedTimeFilter]);


  const handleChangeTimeFilter = (event: SelectChangeEvent) => {
    setSelectedTimeFilter(event.target.value as string);
  };


  const lastElementRef = useCallback(
    // (*)
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          handleLoadMore(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  const searchShareHistory = (e: React.ChangeEvent<HTMLInputElement>) => {

    if ((e.target.value ?? "") == "" && data.length == 0) {
      handleLoadMore(true, "");
    }
    dispatch(updateSearchText(e.target.value ?? ""));


  };

  useEffect(() => {
    if (searchText) {
      handleLoadMore(true);
    }
  }, [searchText])

  const debounceWithSearch = debounce(searchShareHistory, 700);



  const getShareDetail = (shareHistoryItem: ShareHistoryItem) => {

    var shareDetail = "";

    if (shareHistoryItem.activity_type == "landing_page_share") {
      shareDetail = `${shareHistoryItem.share_type == "group_landing_page" ? "Group" : ""} Landing Page`.trim();
    } else if (shareHistoryItem.activity_type == "post_share") {
      shareDetail = shareHistoryItem.post?.description ?? "";
    } else if (shareHistoryItem.activity_type == "social_feed_share") {
      shareDetail = shareHistoryItem.company_feed?.title ?? "";
    } else if (shareHistoryItem.activity_type == 'knocard_share') {
      shareDetail = "Share KnoCard";
    } else if (shareHistoryItem.activity_type == 'request_review') {
      shareDetail = "Request Review";
    } else {
      shareDetail = "Share KnoCard";
    }

    return shareDetail;
  }


  const getShareUserName = (shareHistoryItem: ShareHistoryItem) => {

    var userName = "KnoCard";
    if (shareHistoryItem.user != null) {
      userName = `${shareHistoryItem.user?.first_name ?? ""} ${shareHistoryItem.user?.last_name ?? ""}`;
    } else if (shareHistoryItem.contact != null) {
      userName = `${shareHistoryItem.contact?.first_name ?? ""} ${shareHistoryItem.contact?.last_name ?? ""}`;
    } else if (shareHistoryItem.share_to != null) {
      userName = `${shareHistoryItem.share_to?.first_name ?? ""} ${shareHistoryItem.share_to?.last_name ?? ""}`;
    } else if (shareHistoryItem.share_to_profile != null) {
      userName = `${shareHistoryItem.share_to_profile?.first_name ?? ""} ${shareHistoryItem.share_to_profile?.last_name ?? ""}`;
    }
    return userName.trim();
  }

  const getShareHistoryItemImage = (shareHistoryItem: ShareHistoryItem) => {

    var imageUrl = "";
    if (shareHistoryItem.activity_type != "landing_page_share") {

      if (shareHistoryItem.post?.contents != null) {
        imageUrl = ((shareHistoryItem.post?.contents[0].type ?? "")
          .toLowerCase() ==
          "image" &&
          (shareHistoryItem.post?.contents[0].link ?? "")
            .trim().length > 0)
          ? shareHistoryItem.post?.contents[0].link ?? ""
          : shareHistoryItem.post?.contents[0].thumbnail ?? "";
      } else if ((shareHistoryItem.company_feed?.contents ?? []).length > 0) {
        imageUrl =
          ((shareHistoryItem.company_feed?.contents[0].type ?? "")
            .toLowerCase() == "image" &&
            (shareHistoryItem.company_feed?.contents[0].link ?? "")
              .trim().length > 0) ? shareHistoryItem.company_feed?.contents[0]
                .link ?? "" : shareHistoryItem.company_feed?.contents[0]
                  .thumbnail ?? "";
      } else {
        if (shareHistoryItem.user?.profile_picture != null || shareHistoryItem.user?.user?.profile_picture != null) {
          imageUrl = (shareHistoryItem.user?.profile_picture ?? shareHistoryItem.user?.user?.profile_picture ?? "").trim();
        } else if (shareHistoryItem.contact?.profile_picture != null || shareHistoryItem.contact?.user?.profile_picture != null) {
          imageUrl = (shareHistoryItem.contact?.profile_picture ?? shareHistoryItem.contact?.user?.profile_picture ?? "").trim();
        } else if (shareHistoryItem.share_to?.profile_picture != null || shareHistoryItem.share_to?.user?.profile_picture != null) {
          imageUrl = (shareHistoryItem.share_to?.profile_picture ?? shareHistoryItem.share_to?.user?.profile_picture ?? "").trim();
        } else if (shareHistoryItem.share_to_profile?.profile_picture != null || shareHistoryItem.share_to_profile?.user?.profile_picture != null) {
          imageUrl = (shareHistoryItem.share_to_profile?.profile_picture ?? shareHistoryItem.share_to_profile?.user?.profile_picture ?? "").trim();
        }
      }

      if (imageUrl.trim().length == 0) {
        imageUrl = "/static/images/knocard.png";
      }

    } else {
      imageUrl = "/static/images/group_landing_page.png";
    }


    return imageUrl;



  }


  return (
    <>
      <Page title='Share History | KnoCard' showAppBar={true}>
        <Container maxWidth='xl' sx={{ mt: 3, pl: { md: 2, xs: 1 }, pr: { md: 2, xs: 1 } }} >

          <Grid container>
            <Grid item xs={12}>
              <Box >
                <Typography className={styles.heading} sx={{ width: { xs: '100%', color: AppColors.blackColor } }}  >Share History</Typography>
                <Box display='flex' alignItems='center' gap={2} sx={{ mb: 2, mt: 2 }}>
                  <TextField
                    fullWidth
                    key={'search-name'}
                    placeholder={"Search Name"}
                    InputProps={{
                      sx: { ...inputTextFieldStyle(), borderRadius: '10px' },
                      type: 'search',
                      startAdornment: (<Fragment>
                        <Box component={'div'} sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',

                        }}>
                          <Box
                            component={'img'}
                            src={SearchIcon}
                            alt='search icon'
                            sx={{
                              width: '30px',
                              height: '30px',
                              mr: 1,
                              color: AppColors.primaryColor,
                            }}
                          />
                        </Box>
                      </Fragment >),
                    }}
                    defaultValue={searchText}
                    onChange={debounceWithSearch}
                  />
                  <Select
                    id='time-filter-select'
                    value={selectedTimeFilter}
                    onChange={handleChangeTimeFilter}
                    IconComponent={() => (
                      <img
                        src='/static/images/arrow_down.svg'
                        alt='arrow_down'
                        className={styles.arrowDown}
                      />
                    )}
                    className={styles.activityFilterDropDown}
                  >
                    {timeFilterList.map((val: any) => {
                      return (
                        <MenuItem value={val}>
                          <Box className={styles.activityFilterDropDownText}>
                            {val}
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>

              </Box>

              {data?.map((shareHistoryItem, idx) => {
                const dateFormatted = moment
                  .utc(`${shareHistoryItem.created_at}`)
                  .local()
                  .format('MMM DD, YYYY  hh:mm a');
                return (
                  <><Card
                    ref={
                      idx + 1 === data?.length
                        ? lastElementRef
                        : undefined
                    }
                    key={idx}
                    sx={{
                      width: "100%",
                      boxShadow: 'unset !important',
                      background: AppColors.grayZeroColor,
                      p: 0,
                      mt: 1,
                      border: `${AppColors.strokeColor} 2px solid`,
                      borderRadius: '10px'
                    }}

                  >
                    <ListItem
                      alignItems="flex-start"
                      secondaryAction={
                        <Stack>
                          <Typography
                            sx={{ display: 'inline', fontSize: 'small' }}
                            component="span"
                            variant="body2"
                            color={AppColors.grayTwoColor}
                          >
                            Type
                          </Typography>
                          <Box component={'img'}
                            src={`/static/images/${shareHistoryItem.activity_type == "landing_page_share" ? "share_history_landing_page.png" : ((shareHistoryItem.post == null) && (shareHistoryItem.company_feed == null)) ? "share_history_landing_page.png" : (((shareHistoryItem.post?.contents.length ?? 0) > 1) || (shareHistoryItem.company_feed != null)) ? "share_history_stack.png" : "share_history_video.png"}`}
                            sx={{
                              width: { sm: '35px', xs: '30px' },
                              height: { sm: '35px', xs: '30px' },
                              mt: 1
                            }}
                          />
                        </Stack>
                      }
                    >
                      <ListItemAvatar>
                        <Box
                          component={'img'}
                          loading="lazy"
                          borderRadius={'10px'}
                          src={`${getShareHistoryItemImage(shareHistoryItem)}`}
                          alt=""
                          sx={{
                            mr: 1.5, width: { sm: '60px', xs: '50px' },
                            height: { sm: '60px', xs: '50px' },
                            objectFit: 'cover',
                            borderRadius: '12px'
                          }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Fragment>
                            <Typography
                              sx={{ display: 'inline', fontSize: 'medium', fontWeight: 500 }}
                              component="span"
                              color={AppColors.blackColor}
                            >
                              {getShareUserName(shareHistoryItem)}
                            </Typography>
                          </Fragment>
                        }
                        secondary={
                          <Stack flexDirection={'column'} sx={{ pr: 0.5 }}>
                            <Typography
                              sx={{ display: 'inline', fontSize: 'small' }}
                              component="span"
                              variant="body2"
                              color={AppColors.grayTwoColor}
                            >
                              {getShareDetail(shareHistoryItem)}
                            </Typography>
                            <Typography
                              sx={{ display: 'inline', fontSize: 'small' }}
                              component="span"
                              variant="body2"
                              color={AppColors.grayTwoColor}
                            >
                              {`Share at: ${dateFormatted}`}
                            </Typography>
                          </Stack>
                        }
                      />

                    </ListItem>
                  </Card></>
                );
              })}

              {isLoading && (
                <Box display='flex' justifyContent='center' mt={2}>
                  <CircularProgress />
                </Box>
              )
              }


              {(!isLoading && data.length == 0) && (
                <Box display='flex' justifyContent='center' mt={'20vh'}>
                  <Typography>Share History not found.</Typography>
                </Box>
              )
              }
            </Grid>

          </Grid>

        </Container >
        <BlockOverlay isBlock={!isProUser} />
      </Page >
    </>
  );
};
export default ShareHistory;
