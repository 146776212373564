import { YouTubeThumnailQualityType } from "../enum"


export const getYoutubeLinkThumb = (qualityType: YouTubeThumnailQualityType, videoId: string) => {
    switch (qualityType) {
        case YouTubeThumnailQualityType.low:
            return `https://img.youtube.com/vi/${videoId}/sddefault.jpg`;
        case YouTubeThumnailQualityType.medium:
            return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
        case YouTubeThumnailQualityType.high:
            return `http://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
        case YouTubeThumnailQualityType.maximum:
            return `http://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    }
}


export const getVimeoThumbnailUrl = (videoId: string) => {
    return `https://vumbnail.com/${videoId}.jpg`;

}

export const getVimeoUrl = (videoId: string) => {
    return `https://vimeo.com/${videoId}`;
}
