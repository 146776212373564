import {
  Box,
  DialogContent,
  IconButton,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import '@splidejs/react-splide/css';

import { TutorialVideoItem } from '../../store/tutorialVideo/api/interface';
import ReactPlayer from 'react-player';
import YouTube, { YouTubeProps, YouTubePlayer } from 'react-youtube';
import { useEffect, useRef, useState } from 'react';

type IProps = {
  youtubeVideoId: string;
  toggle: boolean;
  onVideoPlay?: (videoId: string, time: number, duration: number) => void;
  onVideoPause?: (videoId: string, time: number, duration: number) => void;
  onClose: () => void;
};

const youtubeOpts = {
  height: '100%',
  width: '100%',
  playerVars: {
    // Hide related videos after the video finishes
    rel: 0,
    // Hide the video annotations
    iv_load_policy: 3,
    // Hide the YouTube logo
    modestbranding: 1,
  },
};

const YoutubeVideoTutorialModel: React.FC<IProps> = ({
  toggle, onClose, youtubeVideoId, onVideoPlay, onVideoPause
}) => {
  const youtubeRef = useRef<YouTubePlayer | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!toggle) {
      if (youtubeRef.current && isPlaying) {
        if (onVideoPause && youtubeVideoId) {
          onVideoPause(youtubeVideoId, youtubeRef.current?.getCurrentTime() ?? 0, youtubeRef.current.getDuration() || 0);
        }
      }
    }
  }, [toggle]);

  const handleOnReady = (event: any) => {
    youtubeRef.current = event.target;
  };

  const onStateChange: YouTubeProps['onStateChange'] = (event: any) => {
    switch (event.data) {
      case 1:
        setIsPlaying(true);
        break;
      case 2: // Paused
      case 0: // Ended
      //case 3: // Buffering
      case 5: // Video cued
        setIsPlaying(false);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Dialog
        open={toggle}
        onClose={onClose}
        maxWidth='sm'
        fullWidth
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: 3000
        }}
      >
        <DialogContent sx={{ position: "relative", p: 0 }}>
          <IconButton sx={{ position: "absolute", right: 0, top: 0 }} onClick={onClose}><CloseIcon /></IconButton>
          <Box sx={{
            textAlign: 'center',
            width: "100%",
            height: { xs: 'auto', md: '400px' },
          }}>
            <Box
              sx={{
                p: { xs: 1, md: 1.5 },
                maxWidth: '100%',
                height: { xs: 'auto', md: '392px' },
              }}
            >
              <YouTube

                videoId={youtubeVideoId}
                opts={{ youtubeOpts }}
                className='object-fit-contain'
                style={{
                  height: '100%',
                  width: '100%'
                }}
                onStateChange={onStateChange}
                iframeClassName="full-height-width"
                onReady={handleOnReady}
                onPlay={(props: any) => {
                  if (props.target.getCurrentTime() < 1 && onVideoPlay) {
                    onVideoPlay(youtubeVideoId, 0, props.currentTarget.getDuration() || 0)
                  }
                }}
                onEnd={(props: any) => onVideoPause && onVideoPause(youtubeVideoId, props.target.getCurrentTime(), props.target.getDuration() || 0)}
                onPause={(props: any) => onVideoPause && onVideoPause(youtubeVideoId, props.target.getCurrentTime(), props.target.getDuration() || 0)}
              />
            </Box>
          </Box>
        </DialogContent>

      </Dialog>
    </>
  );
};

export default YoutubeVideoTutorialModel;
