import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/system";
import { Transition } from "../Transition";
import { useAppDispatch, useAppSelector } from "../../store";
import React, { useEffect, useState } from "react";
import { FormikProvider, useFormik } from "formik";


import { getCapitalizedString, showErrorMessage, showSuccessMessage } from "../../utils/utilMethods";
import { isNotEmpty } from "../../utils/valid";
import { createUpdateContact, deleteContact } from "../../store/prospects/api";
import { ContactModel, CreateContactRequest } from "../../store/prospects/api/interface";
import { profileShareActionReport } from "../../store/auth/api";
import { EngageActivityTarget } from "../../store/engagePipeline/api/interface";

interface IProps {
    engageActivityTarget?: EngageActivityTarget;
    toggle: boolean;
    onClose: () => void;
    onProspectCreateOrUpdated: (contact?: ContactModel) => void;
}


interface IInitialState {
    first_name: string;
    last_name: string;
    phone_number: string;
    is_share: number;
    email: string;
    occupation: string;
    where_i_met_you: string;
    notes: string;
}

const AddProspectModal: React.FC<IProps> = ({ toggle, onClose, onProspectCreateOrUpdated, engageActivityTarget }) => {

    const dispatch = useAppDispatch();

    const userId = useAppSelector((state) => state?.auth?.user?.id);

    const [isContactDeleting, setIsContactDeleting] = useState<boolean>(false);


    const formik = useFormik<IInitialState>({
        initialValues: {
            first_name: "",
            last_name: "",
            phone_number: "",
            is_share: 0,
            email: "",
            occupation: "",
            where_i_met_you: "",
            notes: ""
        },
        onSubmit: async (values) => {
            try {

                if (!isNotEmpty(values.first_name.trim())) {
                    showErrorMessage("First name is required.");
                    return;
                }

                const createContactRequest: CreateContactRequest = {
                    contact_id: engageActivityTarget?.id,
                    user_id: userId ?? 0,
                    is_saved_using_app: 1,
                    contact: [{
                        first_name: values.first_name.trim(),
                        last_name: values.last_name.trim(),
                        phones: isNotEmpty(values.phone_number.trim()) ? [values.phone_number.trim()] : [],
                        phone_number: values.phone_number.trim(),
                        is_share: 0,
                        email: values.email.trim(),
                        occupation: values.occupation.trim(),
                        where_i_met_you: values.where_i_met_you.trim(),
                        notes: values.notes
                    }]
                };
                const result = await dispatch(createUpdateContact(createContactRequest)).unwrap();
                if (result?.status == 200 && result?.data?.status == "success") {

                    if (engageActivityTarget != undefined) {
                        showSuccessMessage("Prospect updated successfully.");
                        onProspectCreateOrUpdated(result?.data.data?.contact);
                    } else {
                        showSuccessMessage("Prospect created successfully.");
                        dispatch(profileShareActionReport({
                            user_id: userId ?? 0,
                            contact_id: result.data.data.contact?.id ?? 0,
                            share_code: result.data.data.contact?.contact_code ?? ""
                        })).unwrap().then((activityResult) => {
                            if (activityResult.status == 200) {
                                onProspectCreateOrUpdated(result?.data.data?.contact);
                            }
                        });
                    }

                    onCancel();
                }

            } catch (error: any) { }
        },
    });


    useEffect(() => {
        if (engageActivityTarget != undefined) {

            formik.values.first_name = engageActivityTarget.first_name ?? "";
            formik.values.last_name = engageActivityTarget.last_name ?? "";
            formik.values.phone_number = engageActivityTarget.phone_number ?? "";
            formik.values.is_share = engageActivityTarget.is_share ?? 0;
            formik.values.email = engageActivityTarget.email ?? "";
            formik.values.occupation = engageActivityTarget.occupation ?? "";
            formik.values.where_i_met_you = engageActivityTarget.where_i_met_you ?? "";
            formik.values.notes = engageActivityTarget.notes ?? "";
        }
    }, [engageActivityTarget]);


    const onCancel = () => {
        formik.resetForm({});
        onClose();
    };

    const handleDeleteContact = async () => {
        setIsContactDeleting(true);
        if (engageActivityTarget?.id != undefined) {
            const result = await dispatch(deleteContact({ contactId: engageActivityTarget?.id })).unwrap();
            showSuccessMessage("Prospect deleted successfully.");
            onProspectCreateOrUpdated();
            onCancel();
        }
    }


    return (
        <>
            <Dialog
                fullWidth
                open={toggle}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    onCancel();
                }}
                aria-describedby="alert-dialog-slide-description"
                sx={{

                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 3000
                }}
            >

                <form onSubmit={formik.handleSubmit}>
                    <FormikProvider value={formik}>

                        <DialogContent sx={{ padding: '20px', background: "linear-gradient(0deg, rgba(5,134,192,1) 0%, rgba(2,37,56,1) 100%)", }}>
                            <DialogTitle
                                sx={{
                                    pl: 2,
                                    pr: 0,
                                    pt: 0,
                                    pb: 0,
                                    color: "white",
                                }}
                            >
                                {engageActivityTarget == undefined ? "Add Prospect" : "Edit Prospect"}
                            </DialogTitle>
                            <Stack>
                                <Box
                                    sx={{
                                        padding: { md: "15px", sm: "0px", xs: "0px" },
                                    }}
                                >
                                    <Grid container>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                        >
                                            <Grid container gap={1} pt={2}>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={5.9}
                                                    lg={5.9}
                                                    sx={{ padding: "0 0 10px 0" }}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        id='first_name'
                                                        name='first_name'
                                                        label="First Name"
                                                        fullWidth
                                                        onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.first_name ?? ''}
                                                        sx={{
                                                            "& label": {
                                                                color: '#ffffff'
                                                            },
                                                            "& .MuiOutlinedInput-root input": {
                                                                color: '#ffffff'
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },

                                                            },
                                                        }}

                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={5.9}
                                                    lg={5.9}
                                                    sx={{ padding: "0 0 10px 0" }}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        id='last_name'
                                                        name='last_name'
                                                        label="Last Name"
                                                        fullWidth
                                                        onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.last_name ?? ''}
                                                        sx={{
                                                            "& label": {
                                                                color: '#ffffff'
                                                            },
                                                            "& .MuiOutlinedInput-root input": {
                                                                color: '#ffffff'
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },

                                                            },
                                                        }}

                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    sx={{ padding: "0 0 10px 0" }}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        id='phone_number'
                                                        name='phone_number'
                                                        label="Phone Number"
                                                        fullWidth
                                                        onChange={formik.handleChange}
                                                        value={formik.values.phone_number ?? ''}
                                                        sx={{
                                                            "& label": {
                                                                color: '#ffffff'
                                                            },
                                                            "& .MuiOutlinedInput-root input": {
                                                                color: '#ffffff'
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },

                                                            },
                                                        }}

                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    sx={{ padding: "0 0 10px 0" }}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        id='email'
                                                        name='email'
                                                        label="Email"
                                                        fullWidth
                                                        onChange={formik.handleChange}
                                                        value={formik.values.email ?? ''}
                                                        sx={{
                                                            "& label": {
                                                                color: '#ffffff'
                                                            },
                                                            "& .MuiOutlinedInput-root input": {
                                                                color: '#ffffff'
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },

                                                            },
                                                        }}

                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    sx={{ padding: "0 0 10px 0" }}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        id='where_i_met_you'
                                                        name='where_i_met_you'
                                                        label="Where I met you?"
                                                        fullWidth
                                                        onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.where_i_met_you ?? ''}
                                                        sx={{
                                                            "& label": {
                                                                color: '#ffffff'
                                                            },
                                                            "& .MuiOutlinedInput-root input": {
                                                                color: '#ffffff'
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },

                                                            },
                                                        }}

                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    sx={{ padding: "0 0 10px 0" }}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        id='occupation'
                                                        name='occupation'
                                                        label="Occupation"
                                                        fullWidth
                                                        onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.occupation ?? ''}
                                                        sx={{
                                                            "& label": {
                                                                color: '#ffffff'
                                                            },
                                                            "& .MuiOutlinedInput-root input": {
                                                                color: '#ffffff'
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },

                                                            },
                                                        }}

                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    sx={{ padding: "0 0 10px 0" }}
                                                >

                                                    <TextField
                                                        id="notes"
                                                        name='notes'

                                                        sx={{
                                                            "& label": {
                                                                color: '#ffffff'
                                                            },
                                                            "& .MuiOutlinedInput-root textarea": {
                                                                color: '#ffffff'
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: '#ffffff',
                                                                },

                                                            },
                                                        }}
                                                        label="Notes"
                                                        onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.notes}
                                                        multiline
                                                        rows={4}
                                                        maxRows={4}
                                                        fullWidth
                                                    />

                                                </Grid>




                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Box>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    sx={{ padding: { md: "15px", sm: "0px", xs: "0px" } }}
                                >

                                    <Box
                                        sx={{
                                            pt: 2,
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >

                                        {(engageActivityTarget == undefined) && <Button
                                            sx={{
                                                width: "30%",
                                                borderRadius: "15px",
                                                color: "#ffffff",
                                                fontSize: "1rem",
                                                bgcolor: "rgba(255,255,255,0)",
                                                mr: 2
                                            }}
                                            onClick={onCancel}
                                        >
                                            Cancel
                                        </Button>}

                                        {(engageActivityTarget != undefined) && <LoadingButton
                                            type="button"
                                            onClick={handleDeleteContact}
                                            loading={isContactDeleting}
                                            sx={{
                                                width: "30%",
                                                border: "1px solid #FFFFFF",
                                                borderWidth: "1px",
                                                mr: 2,
                                                borderRadius: "15px",
                                                color: "#fff",
                                                fontSize: "1rem",
                                                background: "linear-gradient(0deg, rgba(5,134,192,1) 0%, rgba(2,37,56,1) 100%)",

                                            }}
                                        >
                                            Delete
                                        </LoadingButton>}


                                        <LoadingButton
                                            type="submit"
                                            loading={formik.isSubmitting}
                                            sx={{
                                                width: "30%",
                                                border: "1px solid #FFFFFF",
                                                borderWidth: "1px",

                                                borderRadius: "15px",
                                                color: "#fff",
                                                fontSize: "1rem",
                                                background: "linear-gradient(0deg, rgba(5,134,192,1) 0%, rgba(2,37,56,1) 100%)",

                                            }}
                                        >
                                            Save
                                        </LoadingButton>
                                    </Box>

                                </Grid>
                            </Stack>

                        </DialogContent>
                    </FormikProvider>
                </form>
            </Dialog>
        </>
    );
};

export default AddProspectModal;
