export const splitString = (str: string) => {
  const array = str.split('|');

  if (array?.length === 0) return;

  const type = array[1];

  if (type === 'upload') {
    return { type: 'upload', value: array[array?.length - 1] };
  }

  if (type === 'pattern') {
    const id = array[0];
    return pattern(id);
  }

  if (type === 'solid') {
    const id = array[0];
    return solid(id);
  }

  if (type === 'gradient') {
    const id = array[0];
    return gradient(id);
  }

  if (type === 'asset') {
    return { type: 'asset', value: array[array?.length - 1] };
  }
};

export function pattern(id: string): any {
  switch (id) {
    case '1':
      return { type: 'pattern', value: '/static/images/default.png' };
    case '2':
      return { type: 'pattern', value: '/static/images/star_shower_blue.png' };
    case '3':
      return {
        type: 'pattern',
        value: '/static/images/triangle_pattern_dots.png',
      };
    case '4':
      return {
        type: 'pattern',
        value: '/static/images/wave_grey_wood_pattern.png',
      };
    case '5':
      return {
        type: 'pattern',
        value: '/static/images/colorful_polka_dots.png',
      };
    case '6':
      return {
        type: 'pattern',
        value: '/static/images/wave_grey_diagonal.png',
      };
    case '7':
      return { type: 'pattern', value: '/static/images/white_polka_dots.png' };
    case '8':
      return { type: 'pattern', value: '/static/images/wave_grey.png' };
    case '9':
      return { type: 'pattern', value: '/static/images/green_polka_dots.png' };
    case '10':
      return { type: 'pattern', value: '/static/images/stripe_blue.png' };
    default:
      break;
  }
}

export function solid(id: string): any {
  switch (id) {
    case '1':
      return { type: 'solid', value: '#088AC7' };
    case '2':
      return { type: 'solid', value: '#24DCF1' };
    case '3':
      return { type: 'solid', value: '#F58227' };
    case '4':
      return { type: 'solid', value: '#EBA3E4' };
    case '5':
      return { type: 'solid', value: '#535353' };
    case '6':
      return { type: 'solid', value: '#FF9B9B' };
    case '7':
      return { type: 'solid', value: '#7B7B7B' };
    case '8':
      return { type: 'solid', value: '#F4F9A3' };
    case '9':
      return { type: 'solid', value: '#C6C5C5' };
    case '10':
      return { type: 'solid', value: '#FFFCE5' };
    default:
      break;
  }
}

export function gradient(id: string): any {
  switch (id) {
    case '1':
      return { type: 'gradient', value: '#EEBD89 #D13ABD' };
    case '2':
      return { type: 'gradient', value: '#FEA858 #ED765E' };
    case '3':
      return { type: 'gradient', value: '#BB73E0 #FF8DBB' };
    case '4':
      return { type: 'gradient', value: '#0CCDA3 #C1FCD3' };
    case '5':
      return { type: 'gradient', value: '#F9957F #F2F5D0' };
    case '6':
      return { type: 'gradient', value: '#FF9B9B #EBDFDF' };
    case '7':
      return { type: 'gradient', value: '#F6A09A #8A1F1D' };
    case '8':
      return { type: 'gradient', value: '#F4F9A3 #E9E9E9' };
    case '9':
      return { type: 'gradient', value: '#feae5e #636363' };
    case '10':
      return { type: 'gradient', value: '#FFFCE5 #F2E59D' };
    default:
      break;
  }
}
