import {
  Stack,
  Typography,
  Box,
  Grid,
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  CircularProgress,
  Avatar,
  Fab,
  IconButton,
} from '@mui/material';
import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import { useCallback, FC, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import useToggle from '../hooks/useToggle';
import { useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import '@splidejs/react-splide/css';
import { UserInfo } from '../store/userInfo/api/interface';
import SelectKnocardLinkUserModal from '../components/modal/SelectKnocardLinkUserModal';
import {
  getSentKnoCardLinkRequest,
  sendKnoCardLinkRequest,
} from '../store/knocardLink/api';

import moment from 'moment';
import { KnoCardLinkRequestStatus, NotificationType } from '../enum';
import LockIcon from '@mui/icons-material/Lock';
import { sendKnoCardLinkRequestMessage } from '../client/FireStoreChat';
import { sendChatMessageNotification } from '../store/chat/api';
import { ChatMessage } from '../store/chat/api/interface';
import { setKnoCardLinkViewReportUserInfo } from '../store/knocardLink/knocardLinkSlice';
import { EngageCountItem } from '../store/engage/api/interface';
import { getEngageCount } from '../store/engage/api';
import { getAllReportTotal } from '../store/reports/api';
import DashboardTab from '../components/DashboardTab';
import BlockOverlay from '../components/BlockOverlay';
import { checkIsKnoCardLinkPurchased } from '../utils/checkPro';
import { getUserListKnoCardLinkSessionRequest } from '../store/knocardLinkSession/api';
import EditIcon from '@mui/icons-material/Edit';
import SessionNoteTab from '../components/SessionNoteTab';
import { reset as resetSessionNote } from '../store/knocardLinkSessionNote/knocardLinkSessionNoteSlice';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { KnoCardLinkSessionRequestItem } from '../store/knocardLinkSession/api/interface';
import AddSessionDatesModal from '../components/AddSessionDatesModal';
import { formatReportCount, showSuccessMessage } from '../utils/utilMethods';
import { AppColors } from '../utils/utils';
import { ReportCountItem } from '../components/Reports/ReportContItem';

const KnoCardLink = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userInfo = useAppSelector((state) => state?.auth?.user);
  const [engageCount, setEngageCount] = useState<EngageCountItem | undefined>(
    undefined
  );
  const [isEngageCountLoading, setEngageCountLoading] =
    useState<boolean>(false);
  const { allReportSummary } = useAppSelector((state) => state?.reports);
  const [selectedReportUser, setSelectedReportUser] =
    useState<UserInfo | null>();
  const [showReportTab, setShowReportTab] = useState<boolean>(false);
  const [selectedSessionUser, setSelectedSessionUser] = useState<UserInfo>();
  const [showSessionNotesTab, setShowSessionNotesTab] =
    useState<boolean>(false);



  const {
    data: knoCardRequestList,
    isLoading,
    page,
    total: totalKnoCardLinkRequets,
    viewReportUserInfo,
  } = useAppSelector((state) => state?.knoCardLink);

  const { toggle, onOpen, onClose } = useToggle();

  useEffect(() => {
    if (checkIsKnoCardLinkPurchased(userInfo)) {
      if (knoCardRequestList.length == 0) {
        loadSentKnoCardLinkRequest(1);
      }
    } else {
      navigate('/dashboard');
    }
  }, []);

  useEffect(() => {
    setEngageCount(undefined);
    if (viewReportUserInfo && checkIsKnoCardLinkPurchased(userInfo)) {
      setEngageCountLoading(true);
      dispatch(
        getAllReportTotal({
          userId: viewReportUserInfo.id ?? 0,
          isFromKnoCardLink: true,
        })
      ).unwrap();
      dispatch(
        getEngageCount({
          filter: 'my_engage',
          isFromKnoCardLink: true,
          userId: viewReportUserInfo.id,
        })
      )
        .unwrap()
        .then((result) => {
          setEngageCountLoading(false);
          setEngageCount(result.data.data);
        })
        .catch((e) => {
          setEngageCountLoading(false);
        });
    }
  }, [viewReportUserInfo]);

  const loadSentKnoCardLinkRequest = (page: number) => {
    if (checkIsKnoCardLinkPurchased(userInfo)) {
      dispatch(getSentKnoCardLinkRequest({ page: page }));
    }
  };

  const onSelectUser = (selectedUser: UserInfo) => {
    if (checkIsKnoCardLinkPurchased(userInfo)) {
      dispatch(sendKnoCardLinkRequest({ toUserId: selectedUser.id }))
        .unwrap()
        .then((result) => {
          if (result.status == 200 && userInfo != undefined) {
            let messageText =
              `${selectedUser.first_name}, please tap here to give me access to view your KnoCard activity. Looking forward to our coaching session, and to helping you succeed.`.trim();
            sendKnoCardLinkRequestMessage(
              userInfo.id,
              selectedUser.id,
              messageText
            ).then((result: ChatMessage) => {
              dispatch(
                sendChatMessageNotification({
                  message: messageText,
                  notification_type: NotificationType.community,
                  receiver_id: [selectedUser.id],
                  sender_id: userInfo.id,
                })
              );
            });
          }

          if (result?.data?.message) {
            showSuccessMessage(result.data.message);
          }
          loadSentKnoCardLinkRequest(1);
        })
        .catch((e) => { });
    }
  };

  const handleViewReport = (viewReportUserInfo?: UserInfo) => {
    if (viewReportUserInfo && checkIsKnoCardLinkPurchased(userInfo)) {
      dispatch(setKnoCardLinkViewReportUserInfo(viewReportUserInfo));
    }
  };

  const handleOpenReport = () => {
    setShowReportTab(true);
  };

  const getEngaegCountReport = () => {
    let totalShare =
      engageCount != undefined
        ? (engageCount?.knocard ?? 0) +
        (engageCount?.mediaShare ?? 0) +
        (engageCount?.shareAffiliateLandingPage ?? 0) +
        (engageCount?.socialShare ?? 0)
        : 0;
    let totalGoal =
      engageCount != undefined && engageCount?.engageSetting != undefined
        ? (engageCount?.engageSetting?.share_goal ?? 0) *
        (engageCount?.engageSetting?.duration ?? 0)
        : 0;

    let goalSetDate = engageCount?.engageSetting?.date
      ? moment.utc(`${engageCount?.engageSetting?.date}`).local()
      : null;

    let totalTodayShare = engageCount?.totalTodayShares || 0;

    let completedDays = 0;
    let daysLeft = 0;
    let averageSharePerDay = 0;
    let isGoalMeet = false;


    if (goalSetDate != null) {
      goalSetDate.add(engageCount?.engageSetting?.duration ?? 0, 'days');

      daysLeft = goalSetDate.diff(moment(new Date()), 'days');

      if (daysLeft < 0) {
        daysLeft = 0;
      }

      completedDays = (engageCount?.engageSetting?.duration ?? 0) - daysLeft;

      if (completedDays > (engageCount?.engageSetting?.duration ?? 0)) {
        completedDays = engageCount?.engageSetting?.duration ?? 0;
      }

      averageSharePerDay = totalShare > 0 ? totalShare / completedDays : 0;

      //isGoalMeet = totalShare >=completedDays * (engageCount?.engageSetting?.share_goal ?? 0);
      isGoalMeet = totalTodayShare >= (engageCount?.engageSetting?.share_goal ?? 0);
    }

    return (
      <>
        <Stack
          flexDirection={'row'}
          sx={{ mt: 1, mb: 1 }}
        >
          <Stack
            sx={{ width: '50%' }}
            alignContent={'center'}
            alignItems={'center'}
          >
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '80px',
                width: '80px',
                background: isGoalMeet ? AppColors.greenColor : AppColors.redColor,
                color: isGoalMeet ? AppColors.blackColor : AppColors.whiteColor,
                border: `solid 2px ${AppColors.strokeColor}`,
                borderRadius: '50px',
                fontWeight: '500',
              }}
            >
              {formatReportCount(totalTodayShare)}
            </Typography>
            <Typography sx={{ color: '#ffffff', pt: 0.5 }}>Today's Shares</Typography>
          </Stack>
          <Stack
            sx={{ width: '50%' }}
            alignContent={'center'}
            alignItems={'center'}
          >
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '80px',
                width: '80px',
                background: AppColors.yellowColor,
                border: `solid 2px ${AppColors.strokeColor}`,
                borderRadius: '50px',
                textAlign: 'center',
                fontWeight: '500',
              }}
            >
              {engageCount?.engageSetting?.share_goal || 0}
            </Typography>
            <Typography sx={{ color: AppColors.whiteColor, pt: 0.5 }}>Today's Goal</Typography>
          </Stack>
        </Stack>

        <Stack
          flexDirection={'row'}
          spacing={2}
          justifyContent='space-between'
          alignItems={'center'}
          sx={{ width: '100%' }}
        >
          <Typography
            sx={{ color: AppColors.whiteColor, fontWeight: '500', fontSize: '0.8rem' }}
          >
            Goal Set Date
          </Typography>
          <Typography
            sx={{
              color: AppColors.whiteColor,
              marginTop: '0px !important',
              fontSize: '0.8rem',
            }}
          >
            {engageCount?.engageSetting?.date &&
              moment
                .utc(`${engageCount?.engageSetting?.date}`)
                .local()
                .format('MMM DD, hh:mm a')}
          </Typography>
        </Stack>
        <Stack
          flexDirection={'row'}
          spacing={2}
          justifyContent='space-between'
          alignItems={'center'}
          sx={{ width: '100%' }}
        >
          <Typography
            sx={{ color: AppColors.whiteColor, fontWeight: '500', fontSize: '0.8rem' }}
          >
            Completed Days
          </Typography>
          <Typography
            sx={{
              color: AppColors.whiteColor,
              marginTop: '0px !important',
              fontSize: '0.8rem',
            }}
          >
            {completedDays} Days
          </Typography>
        </Stack>
        <Stack
          flexDirection={'row'}
          spacing={2}
          justifyContent='space-between'
          alignItems={'center'}
          sx={{ width: '100%' }}
        >
          <Typography
            sx={{ color: AppColors.whiteColor, fontWeight: '500', fontSize: '0.8rem' }}
          >
            Days Left
          </Typography>
          <Typography
            sx={{
              color: AppColors.whiteColor,
              marginTop: '0px !important',
              fontSize: '0.8rem',
            }}
          >
            {daysLeft} Days
          </Typography>
        </Stack>
        <Stack
          flexDirection={'row'}
          spacing={2}
          justifyContent='space-between'
          alignItems={'center'}
          sx={{ width: '100%' }}
        >
          <Typography
            sx={{ color: AppColors.whiteColor, fontWeight: '500', fontSize: '0.8rem' }}
          >
            Avg Shares Per/Day
          </Typography>
          <Typography
            sx={{
              color: AppColors.whiteColor,
              marginTop: '0px !important',
              fontSize: '0.8rem',
            }}
          >
            {averageSharePerDay.toFixed(1)} per day
          </Typography>
        </Stack>
        <Stack
          flexDirection={'row'}
          spacing={2}
          justifyContent='space-between'
          alignItems={'center'}
          sx={{ width: '100%', mt: 2 }}
        >
          <Typography
            sx={{ color: AppColors.whiteColor, fontWeight: '500', fontSize: '0.8rem' }}
          >
            On track to meet goal
          </Typography>
          <Typography
            sx={{
              color: AppColors.whiteColor,
              marginTop: '0px !important',
              fontSize: '0.8rem',
            }}
          >
            {isGoalMeet ? 'YES' : 'NO'}
          </Typography>
        </Stack>
      </>
    );
  };

  useEffect(() => {
    setSelectedReportUser(viewReportUserInfo);
  }, [viewReportUserInfo]);

  const {
    data: knoCardLinkSessionList,
    isLoading: isLoadingKnoCardLinkSessionList,
    page: pageKnoCardLinkSessionList,
    total: totalKnoCardLinkSessionList,
  } = useAppSelector((state) => state?.knoCardLinkSession);

  useEffect(() => {
    if (knoCardLinkSessionList.length == 0) {
      loadKnoCardLinkSessionRequest(1);
    }
  }, []);

  const loadKnoCardLinkSessionRequest = (page: number) => {
    if (checkIsKnoCardLinkPurchased(userInfo)) {
      dispatch(getUserListKnoCardLinkSessionRequest({ page: page }));
    }
  };

  const handleOpenSessionNotes = (user: UserInfo) => {
    if (checkIsKnoCardLinkPurchased(userInfo)) {
      dispatch(resetSessionNote());
      if (user) {
        setSelectedSessionUser(user);
        setShowSessionNotesTab(true);
      }
    }
  };

  const {
    toggle: toggleUpDateDates,
    onOpen: onOpenUpDateDates,
    onClose: onCloseUpDateDates,
  } = useToggle();

  const [selectedSessionItem, setSelectedSessionItem] =
    useState<KnoCardLinkSessionRequestItem>();

  const handleUpdateDates = (kcSRequest: KnoCardLinkSessionRequestItem) => {
    setSelectedSessionItem(kcSRequest);
    onOpenUpDateDates();
  };


  const handleNavigateToTutorial = () => {
    navigate('/dashboard/how-to');
  }

  return (
    <>
      <Page title='Business Social | KnoCard' showAppBar={true}>
        {showReportTab ? (
          <DashboardTab
            selectedReportUser={selectedReportUser}
            setShowReportTab={setShowReportTab}
          />
        ) : showSessionNotesTab ? (
          <SessionNoteTab
            selectedSessionUser={selectedSessionUser}
            setShowSessionNotesTab={setShowSessionNotesTab}
          />
        ) : (
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            gap={3}
            sx={{ marginTop: 5, mb: 1 }}
          >
            <Grid xs={12} md={12} container>
              <Grid
                xs={12}
                md={12}
                container
                direction='row'
                sx={{
                  width: '100%',
                }}
              >
                <Grid
                  xs={12}
                  md={6}
                  item
                  direction='column'
                  sx={{
                    width: '100%',
                    p: 2
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '1.3rem',
                      fontWeight: '500',
                      mb: 2,
                      color: AppColors.blackColor,
                    }}
                  >
                    {' '}
                    The Ultimate Coach
                  </Typography>
                  <Typography
                    sx={{ color: AppColors.grayTwoColor, }}
                  >
                    KnoCard Link is the premier platform for coaching and
                    mentoring, offering KnoCard reports that empower you to lead
                    and assist effectively through leadership training.
                  </Typography>
                  <Grid
                    xs={12}
                    md={12}
                    container
                    direction='row'
                    justifyContent={'center'}

                    sx={{
                      width: '100%',
                    }}
                  >
                    <Grid
                      sm={12}
                      md={3}
                      container
                      direction='column'
                      alignItems='center'
                      sx={{
                        width: '100%',
                        mt: 3.5,
                      }}
                    >
                      <Box
                        component={'img'}
                        src='/static/images/search_request.svg'
                        sx={{
                        }}
                      />
                      <Typography
                        sx={{
                          fontWeight: '500',
                          color: AppColors.blackColor,
                          mt: 2,
                          fontSize: '0.9rem',
                          textAlign: 'center'
                        }}
                      >
                        Search / Send Request
                      </Typography>
                    </Grid>
                    <Grid
                      sm={12}
                      md={1}
                      container
                      direction='column'
                      alignItems='center'
                      sx={{
                        mt: { md: 6.5, xs: 0 },
                      }}
                    >
                      <Box
                        component={'img'}
                        src='/static/images/link_arrow.svg'
                        sx={{
                          display: { md: 'unset', xs: 'none' }
                        }}
                      />

                    </Grid>
                    <Grid
                      sm={12}
                      md={3}
                      container
                      direction='column'
                      alignItems='center'
                      sx={{
                        width: '100%',
                        mt: 3.5,
                      }}
                    >
                      <Box
                        component={'img'}
                        src='/static/images/request_permission.svg'
                        sx={{

                        }}
                      />
                      <Typography
                        sx={{
                          fontWeight: '500',
                          color: AppColors.blackColor,
                          mt: 2,
                          fontSize: '0.9rem',
                          textAlign: 'center'
                        }}
                      >
                        Request Permission
                      </Typography>
                    </Grid>
                    <Grid
                      sm={12}
                      md={1}
                      container
                      direction='column'
                      alignItems='center'
                      sx={{
                        mt: { md: 6.5, xs: 0 },
                      }}
                    >
                      <Box
                        component={'img'}
                        src='/static/images/link_arrow.svg'
                        sx={{
                          display: { md: 'unset', xs: 'none' }
                        }}
                      />

                    </Grid>
                    <Grid
                      sm={12}
                      md={3}
                      container
                      direction='column'
                      alignItems='center'
                      sx={{
                        width: '100%',
                        mt: 3.5,
                      }}
                    >
                      <Box
                        component={'img'}
                        src='/static/images/get_linked.svg'
                        sx={{

                        }}
                      />
                      <Typography
                        sx={{
                          fontWeight: '500',
                          color: AppColors.blackColor,
                          mt: 2,
                          fontSize: '0.9rem',
                          textAlign: 'center'
                        }}
                      >
                        Get Linked
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  md={6}
                  item
                  direction='column'
                  justifyContent={'center'}
                  alignItems='center'
                  sx={{
                    width: '100%',
                    mt: { xs: 5, lg: 0 },
                    p: 2
                  }}
                >
                  <Box sx={{
                    p: 2, backgroundColor: AppColors.whiteColor, borderRadius: "10px", position: "relative",
                    backgroundImage: 'url("/static/images/tutorial_image.png")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    minHeight: "250px"
                  }}
                    onClick={handleNavigateToTutorial}
                  >
                  </Box>
                </Grid>
              </Grid>


              <Grid
                xs={12}
                md={12}
                container
                direction='row'
                justifyContent={'center'}
                alignItems='center'
                sx={{
                  width: '100%',
                  mt: 3.5,
                  pl: 2,
                  pr: 2,
                }}
              >
                <Grid
                  sm={12}
                  md={7}
                  container
                  direction='column'
                  justifyContent={'start'}
                  alignItems='start'
                  sx={{
                    width: '100%',
                    textAlign: 'start',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: '500',
                      color: AppColors.blackColor,
                      mt: 2,
                      fontSize: '1.3rem',
                    }}
                  >
                    Activity
                  </Typography>
                </Grid>
                <Grid
                  sm={12}
                  md={5}
                  container
                  direction='row'
                  sx={{
                    width: '100%',
                    mt: 3.5,
                    background:
                      `linear-gradient(90deg, ${AppColors.secondryColor} 0%, ${AppColors.primaryColor} 50%)`,
                    borderRadius: '100px',
                  }}
                  onClick={() => {
                    if (checkIsKnoCardLinkPurchased(userInfo)) {
                      onOpen();
                    }
                  }}
                >
                  <Stack
                    alignContent={'center'}
                    alignItems={'center'}
                    sx={{ flexDirection: 'row', width: '100%' }}
                  >
                    <AddCircleIcon
                      sx={{ color: '#ffffff', fontSize: '35px' }}
                    ></AddCircleIcon>{' '}
                    <Typography
                      sx={{
                        fontWeight: '500',
                        color: '#ffffff',
                        width: '100%',
                        textAlign: 'end',
                        pr: 2,
                      }}
                    >
                      Send Request
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                xs={12}
                md={12}
                container
                direction='row'
                justifyContent={'center'}
                alignItems='center'
                sx={{
                  width: '100%',
                  mt: 2,
                  backgroundColor: AppColors.whiteColor,
                }}
              >
                <TableContainer>
                  <Table stickyHeader aria-label='sticky table'>
                    <TableHead sx={{ background: AppColors.tableHeader }}>
                      <TableRow>
                        <TableCell
                          align={'left'}
                          sx={{
                            color: AppColors.blackColor,
                            fontWeight: '500',
                            background: AppColors.tableHeader
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          align={'center'}
                          sx={{
                            color: AppColors.blackColor,
                            fontWeight: '500',
                            background: AppColors.tableHeader
                          }}
                        >
                          Requested
                        </TableCell>
                        <TableCell
                          align={'center'}
                          sx={{
                            color: AppColors.blackColor,
                            fontWeight: '500',
                            background: AppColors.tableHeader
                          }}
                        >
                          Accepted At
                        </TableCell>
                        <TableCell
                          align={'center'}
                          sx={{
                            color: AppColors.blackColor,
                            fontWeight: '500',
                            background: AppColors.tableHeader
                          }}
                        >
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!isLoading &&
                        knoCardRequestList.map((kcRequest, idx) => {
                          return (
                            <TableRow
                              hover
                              role='checkbox'
                              tabIndex={-1}
                              key={`kcRequest-${idx}`}
                            >
                              <TableCell
                                align={'center'}
                                sx={{
                                  color: AppColors.blackColor,
                                  boxShadow: 'none !important',
                                }}
                              >
                                <Stack
                                  direction={'row'}
                                  spacing={2}
                                  alignContent={'center'}
                                  alignItems={'center'}
                                >
                                  <Avatar
                                    src={
                                      kcRequest.to_user?.profile_picture ??
                                      '/static/images/placeholder_profile.png'
                                    }
                                    sx={{ height: '40px', width: '40px' }}
                                  />
                                  <Typography>
                                    {kcRequest.to_user?.first_name ?? ''}{' '}
                                    {kcRequest.to_user?.last_name ?? ''}
                                  </Typography>
                                </Stack>{' '}
                              </TableCell>
                              <TableCell
                                align={'center'}
                                sx={{ color: AppColors.blackColor }}
                              >
                                {moment
                                  .utc(`${kcRequest.created_at}`)
                                  .local()
                                  .format('MMM DD, YYYY  hh:mm a')}
                              </TableCell>
                              <TableCell
                                align={'center'}
                                sx={{ color: AppColors.blackColor }}
                              >
                                {kcRequest.status ==
                                  KnoCardLinkRequestStatus.ACCEPTED &&
                                  moment
                                    .utc(`${kcRequest.created_at}`)
                                    .local()
                                    .format('MMM DD, YYYY  hh:mm a')}
                              </TableCell>
                              <TableCell
                                align={'center'}
                                sx={{
                                  color: AppColors.blackColor,
                                  boxShadow: 'none !important',
                                  textAlign: 'end',
                                }}
                              >
                                <Stack
                                  direction={'row'}
                                  spacing={2}
                                  alignContent={'center'}
                                  alignItems={'center'}
                                  sx={{ width: '100%', justifyContent: 'end' }}
                                >
                                  {kcRequest.status ==
                                    KnoCardLinkRequestStatus.ACCEPTED ? (
                                    <>
                                      <Box
                                        sx={{
                                          color: AppColors.yellowColor,
                                          border: `1px solid ${AppColors.yellowColor}`,
                                          borderRadius: '100px',
                                          pl: 2,
                                          pr: 2,
                                          pt: 0.5,
                                          pb: 0.5,
                                          cursor: 'pointer',
                                          textAlign: 'center',
                                        }}
                                        onClick={() =>
                                          handleViewReport(kcRequest.to_user)
                                        }
                                      >
                                        View Report
                                      </Box>
                                      <Box
                                        sx={{
                                          color: AppColors.greenColor,
                                          borderRadius: '100px',
                                          pl: 2,
                                          pr: 2,
                                          pt: 0.5,
                                          pb: 0.5,
                                          textTransform: 'uppercase',
                                        }}
                                      >
                                        Approved
                                      </Box>
                                      <LockIcon
                                        sx={{ color: '#46FC05' }}
                                      ></LockIcon>
                                    </>
                                  ) : kcRequest.status ==
                                    KnoCardLinkRequestStatus.PENDING ? (
                                    <>
                                      <Box
                                        sx={{
                                          color: AppColors.yellowColor,
                                          borderRadius: '100px',
                                          pl: 2,
                                          pr: 2,
                                          pt: 0.5,
                                          pb: 0.5,
                                          textTransform: 'uppercase',
                                        }}
                                      >
                                        Pending
                                      </Box>
                                      <LockIcon
                                        sx={{ color: AppColors.yellowColor }}
                                      ></LockIcon>
                                    </>
                                  ) : kcRequest.status ==
                                    KnoCardLinkRequestStatus.REVOKED ? (
                                    <>
                                      <Box
                                        sx={{
                                          color: AppColors.redColor,
                                          borderRadius: '100px',
                                          pl: 2,
                                          pr: 2,
                                          pt: 0.5,
                                          pb: 0.5,
                                          textTransform: 'uppercase',
                                        }}
                                      >
                                        Revoked
                                      </Box>
                                      <LockIcon
                                        sx={{ color: 'red' }}
                                      ></LockIcon>
                                    </>
                                  ) : (
                                    <>
                                      <Box
                                        sx={{
                                          color: '#ffffff',
                                          background:
                                            'linear-gradient(0deg, red 0%, maroon 50%, red 100%)',
                                          borderRadius: '100px',
                                          pl: 2,
                                          pr: 2,
                                          pt: 0.5,
                                          pb: 0.5,
                                          textTransform: 'uppercase',
                                        }}
                                      >
                                        Rejected
                                      </Box>
                                      <LockIcon
                                        sx={{ color: 'red' }}
                                      ></LockIcon>
                                    </>
                                  )}
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {isLoading && (
                        <TableRow>
                          {' '}
                          <TableCell
                            colSpan={4}
                            align={'center'}
                            sx={{ boxShadow: 'none !important' }}
                          >
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      )}
                      {!isLoading && knoCardRequestList.length == 0 && (
                        <TableRow>
                          {' '}
                          <TableCell
                            colSpan={4}
                            align={'center'}
                            sx={{ boxShadow: 'none !important' }}
                          >
                            <Typography sx={{ color: AppColors.blackColor }}>
                              No data found.{' '}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component='div'
                  sx={{ width: '100%', color: '#ffffff' }}
                  align={'right'}
                  count={totalKnoCardLinkRequets}
                  rowsPerPage={10}
                  page={page - 1}
                  onPageChange={(event: unknown, newPage: number) =>
                    loadSentKnoCardLinkRequest(newPage + 1)
                  }
                />
              </Grid>
              <Grid
                sm={12}
                md={12}
                container
                direction='column'
                justifyContent={'start'}
                alignItems='start'
                sx={{
                  mt: 2,
                  width: '100%',
                  textAlign: 'start',
                  pr: 0.5,
                  pl: 0.5,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: '500',
                    color: AppColors.blackColor,
                    mt: 2,
                    fontSize: '1.3rem',
                  }}
                >
                  Activity
                </Typography>
                <Box
                  sx={{
                    mt: 2,
                    backgroundColor: AppColors.whiteColor,
                    width: '100%'
                  }}
                >
                  <TableContainer>
                    <Table stickyHeader aria-label='sticky table'>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align={'left'}
                            sx={{
                              color: AppColors.blackColor,
                              fontWeight: '500',
                              background: AppColors.tableHeader
                            }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            align={'center'}
                            sx={{
                              color: AppColors.blackColor,
                              fontWeight: '500',
                              background: AppColors.tableHeader
                            }}
                          >
                            Next Coaching
                          </TableCell>
                          <TableCell
                            align={'center'}
                            sx={{
                              color: AppColors.blackColor,
                              fontWeight: '500',
                              background: AppColors.tableHeader
                            }}
                          >
                            Next Follow-up
                          </TableCell>
                          <TableCell
                            align={'right'}
                            sx={{
                              color: AppColors.blackColor,
                              fontWeight: '500',
                              background: AppColors.tableHeader
                            }}
                          >
                            Notes
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!isLoadingKnoCardLinkSessionList &&
                          knoCardLinkSessionList.map((kcSRequest, idx) => {
                            return (
                              <TableRow
                                hover
                                role='checkbox'
                                tabIndex={-1}
                                key={`kcSRequest-${idx}`}
                              >
                                <TableCell
                                  align={'center'}
                                  sx={{
                                    color: AppColors.blackColor,
                                    boxShadow: 'none !important',
                                  }}
                                >
                                  <Stack
                                    direction={'row'}
                                    spacing={2}
                                    alignContent={'center'}
                                    alignItems={'center'}
                                  >
                                    <Avatar
                                      src={
                                        kcSRequest.to_user?.profile_picture ??
                                        '/static/images/placeholder_profile.png'
                                      }
                                      sx={{ height: '40px', width: '40px' }}
                                    />
                                    <Typography>
                                      {kcSRequest.to_user?.first_name ?? ''}{' '}
                                      {kcSRequest.to_user?.last_name ?? ''}
                                    </Typography>
                                  </Stack>{' '}
                                </TableCell>
                                <TableCell
                                  align={'center'}
                                  sx={{ color: AppColors.blackColor }}
                                >
                                  {kcSRequest?.next_coaching &&
                                    moment(kcSRequest?.next_coaching).format(
                                      'MMM DD, YYYY'
                                    )}
                                  <IconButton
                                    aria-label='edit'
                                    sx={{ color: AppColors.blackColor }}
                                    onClick={() =>
                                      handleUpdateDates(kcSRequest)
                                    }
                                  >
                                    <CalendarMonthIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell
                                  align={'center'}
                                  sx={{ color: AppColors.blackColor }}
                                >
                                  {kcSRequest?.next_followup &&
                                    moment(kcSRequest?.next_followup).format(
                                      'MMM DD, YYYY'
                                    )}
                                  <IconButton
                                    aria-label='edit'
                                    sx={{ color: AppColors.blackColor }}
                                    onClick={() =>
                                      handleUpdateDates(kcSRequest)
                                    }
                                  >
                                    <CalendarMonthIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell
                                  align={'center'}
                                  sx={{
                                    color: AppColors.blackColor,
                                    boxShadow: 'none !important',
                                    textAlign: 'end',
                                  }}
                                >
                                  <IconButton
                                    aria-label='edit'
                                    sx={{ color: AppColors.blackColor }}
                                    onClick={() =>
                                      handleOpenSessionNotes(
                                        kcSRequest.to_user
                                      )
                                    }
                                  >
                                    <EditIcon sx={{ color: AppColors.blackColor }} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {isLoadingKnoCardLinkSessionList && (
                          <TableRow>
                            {' '}
                            <TableCell
                              colSpan={4}
                              align={'center'}
                              sx={{ boxShadow: 'none !important' }}
                            >
                              <CircularProgress />
                            </TableCell>
                          </TableRow>
                        )}
                        {!isLoadingKnoCardLinkSessionList &&
                          knoCardLinkSessionList.length == 0 && (
                            <TableRow>
                              {' '}
                              <TableCell
                                colSpan={4}
                                align={'center'}
                                sx={{ boxShadow: 'none !important' }}
                              >
                                <Typography sx={{ color: AppColors.blackColor }}>
                                  No data found.{' '}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component='div'
                    sx={{ width: '100%', color: '#ffffff' }}
                    align={'right'}
                    count={totalKnoCardLinkSessionList}
                    rowsPerPage={10}
                    page={pageKnoCardLinkSessionList - 1}
                    onPageChange={(event: unknown, newPage: number) =>
                      loadKnoCardLinkSessionRequest(newPage + 1)
                    }
                  />
                </Box>
              </Grid>

              <Grid
                xs={12}
                md={12}
                container
                direction='row'
                sx={{
                  width: '100%',
                  pl: 2,
                  pr: 2,
                }}
              >
                {viewReportUserInfo ? (
                  <Grid
                    sm={12}
                    md={12}
                    container
                    direction='column'
                    justifyContent={'start'}
                    alignItems='start'
                    sx={{
                      mt: 2,
                      width: '100%',
                      textAlign: 'start',
                      pr: 0.5,
                      pl: 0.5,
                    }}
                  >
                    <Stack
                      flexDirection={'row'}
                      spacing={2}
                      alignItems={'center'}
                      justifyContent='space-between'
                      sx={{ width: '100%' }}
                    >
                      <Typography
                        sx={{
                          fontWeight: '500',
                          color: AppColors.blackColor,
                          mt: 2,
                          fontSize: '1.3rem',
                        }}
                      >
                        Reports
                      </Typography>
                      <Stack
                        flexDirection={'row'}
                        alignSelf={'flex-end'}
                        sx={{
                          background: `linear-gradient(90deg, ${AppColors.primaryColor} 0%, ${AppColors.secondryColor} 100%)`,
                          borderTopLeftRadius: '10px',
                          borderTopRightRadius: '10px',
                          ml: 1,
                          mr: 1,
                          p: 1,
                        }}
                      >
                        <Avatar
                          src={
                            viewReportUserInfo.profile_picture ??
                            '/static/images/placeholder_profile.png'
                          }
                          sx={{ height: '40px', width: '40px' }}
                        />
                        <Box sx={{ pl: 1, pt: 0.6 }}>
                          <Typography
                            sx={{
                              color: AppColors.whiteColor,
                              maxHeight: 'calc(1.2em * 1)',
                              overflow: 'hidden',
                              lineHeight: '1.2em',
                            }}
                          >
                            {viewReportUserInfo?.first_name ?? ''}{' '}
                            {viewReportUserInfo?.last_name ?? ''}
                          </Typography>
                          <Typography
                            sx={{
                              color: AppColors.whiteColor,
                              maxHeight: 'calc(1.2em * 1)',
                              overflow: 'hidden',
                              lineHeight: '1.2em',
                            }}
                          >
                            {viewReportUserInfo?.occupation ?? ''}
                          </Typography>
                        </Box>
                      </Stack>
                    </Stack>
                    <Box sx={{ width: '100%' }}>
                      <Grid
                        xs={12}
                        md={12}
                        container
                        direction={'row'}
                        sx={{
                          width: '100%',
                          borderRadius: '5px',
                          backgroundColor: AppColors.whiteColor
                        }}
                      >
                        <Grid
                          xs={12}
                          md={
                            (engageCount?.engageSetting?.status ?? 0) === 1
                              ? 8
                              : 12
                          }
                          container
                          direction='row'
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Grid
                            xs={12}
                            sm={4}
                            md={6}
                            lg={4}
                            item
                            direction={'column'}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              p: 1,
                            }}
                          >
                            <ReportCountItem title="Landing Page" previousReport={allReportSummary?.contactPreviousMonth || 0} currentReport={(allReportSummary?.contactMonth || 0)} isProUser={true} reportType={"Month"} />

                          </Grid>

                          <Grid
                            xs={12}
                            sm={4}
                            md={6}
                            lg={4}
                            item
                            direction={'column'}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              p: 1,
                            }}
                          >
                            <ReportCountItem title="Page Views" previousReport={allReportSummary?.viewsPreviousMonth || 0} currentReport={(allReportSummary?.viewsMonth || 0)} isProUser={true} reportType={"Month"} />
                          </Grid>

                          <Grid
                            xs={12}
                            sm={4}
                            md={6}
                            lg={4}
                            item
                            direction={'column'}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',

                              p: 1,
                            }}
                          >
                            <ReportCountItem title="Video Views" previousReport={allReportSummary?.viewVideoMediaPostPreviousMonth || 0} currentReport={(allReportSummary?.viewVideoMediaPostMonth || 0)} isProUser={true} reportType={"Month"} />
                          </Grid>

                          <Grid
                            xs={12}
                            sm={4}
                            md={6}
                            lg={4}
                            item
                            direction={'column'}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',

                              p: 1,
                            }}
                          >
                            <ReportCountItem title="Landing Page" previousReport={allReportSummary?.landingPageVideoPreviousMonth || 0} currentReport={(allReportSummary?.landingPageVideoMonth || 0)} isProUser={true} reportType={"Month"} />
                          </Grid>

                          <Grid
                            xs={12}
                            sm={4}
                            md={6}
                            lg={4}
                            item
                            direction={'column'}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              p: 1,
                            }}
                          >
                            <ReportCountItem title="Media Shares" previousReport={allReportSummary?.shareMediaPreviousMonth || 0} currentReport={(allReportSummary?.shareMediaMonth || 0)} isProUser={true} reportType={"Month"} />
                          </Grid>

                          <Grid
                            xs={12}
                            sm={4}
                            md={6}
                            lg={4}
                            item
                            direction={'column'}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              p: 1,
                            }}
                          >
                            <ReportCountItem title="Personal Shares" previousReport={allReportSummary?.webAppSharePreviousMonth || 0} currentReport={(allReportSummary?.webAppShareMonth || 0)} isProUser={true} reportType={"Month"} />

                          </Grid>

                          <Grid
                            xs={12}
                            sm={4}
                            md={6}
                            lg={4}
                            item
                            direction={'column'}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              p: 1,
                            }}
                          >
                            <ReportCountItem title="Social Shares" previousReport={allReportSummary?.shareSocialPostsPreviousMonth || 0} currentReport={(allReportSummary?.shareSocialPostsMonth || 0)} isProUser={true} reportType={"Month"} />
                          </Grid>

                          <Grid
                            xs={12}
                            sm={4}
                            md={6}
                            lg={4}
                            item
                            direction={'column'}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              p: 1,
                            }}
                          >

                            <ReportCountItem title="Referrals" previousReport={allReportSummary?.referralsPreviousMonth || 0} currentReport={(allReportSummary?.referralsMonth || 0)} isProUser={true} reportType={"Month"} />

                          </Grid>

                          <Grid
                            xs={12}
                            lg={12}
                            item
                            direction={'column'}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              p: 1,
                            }}
                          >
                            <Fab
                              variant='extended'
                              sx={{
                                backgroundColor: AppColors.primaryColor,
                                color: '#ffffff',
                                marginRight: 3,
                                marginBottom: { sm: 0, xs: 2 },
                                zIndex: 'unset',
                                '&:hover': {
                                  backgroundColor: AppColors.primaryColor,
                                },
                              }}
                              onClick={() => handleOpenReport()}
                            >
                              Open Report
                            </Fab>
                          </Grid>
                        </Grid>
                        {(engageCount?.engageSetting?.status ?? 0) === 1 && (
                          <Grid
                            xs={12}
                            md={4}
                            container
                            direction='row'
                            sx={{
                              width: '100%',
                              p: 0.5,
                            }}
                          >
                            <Box
                              sx={{
                                borderRadius: '5px',
                                background: `linear-gradient(90deg, ${AppColors.primaryColor} 0%, ${AppColors.secondryColor} 100%)`,
                                width: '100%',
                                p: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  textAlign: 'center',
                                  color: AppColors.whiteColor,
                                  fontWeight: '500',
                                }}
                              >
                                KnoCard Engage
                              </Typography>
                              {isEngageCountLoading ? (
                                <Box
                                  sx={{
                                    width: '100%',
                                    textAlign: 'center',
                                    mt: 3,
                                  }}
                                >
                                  <CircularProgress />
                                </Box>
                              ) : (
                                getEngaegCountReport()
                              )}
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                ) : (
                  <Grid
                    sm={12}
                    md={6}
                    container
                    direction='column'
                    justifyContent={'start'}
                    alignItems='start'
                    sx={{
                      mt: 2,
                      width: '100%',
                      textAlign: 'start',
                      pr: 0.5,
                      pl: 0.5,
                    }}
                  ></Grid>
                )}

              </Grid>
            </Grid>
          </Box>
        )}
        <BlockOverlay isBlock={!checkIsKnoCardLinkPurchased(userInfo)} />
      </Page>
      <SelectKnocardLinkUserModal
        onSelect={onSelectUser}
        onClose={onClose}
        toggle={toggle}
      />
      <AddSessionDatesModal
        onClose={onCloseUpDateDates}
        toggle={toggleUpDateDates}
        selectedSessionItem={selectedSessionItem}
      />
    </>
  );
};

export default KnoCardLink;
