import { LoadingButton } from '@mui/lab';
import { Backdrop, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
interface IProps {
    isBlock: boolean;
    onCloseProView?: () => void;
    planMessage?: string;
}

const BlockOverlay: React.FC<IProps> = ({ isBlock, onCloseProView, planMessage }) => {

    const navigate = useNavigate();

    const handleOpenSubscription = () => {
        navigate('/subscription');
    };


    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: 900 }}
            open={isBlock}
            onClick={() => onCloseProView && onCloseProView()}
        >
            <Stack direction={'column'}
                justifyContent={'center'}
                alignContent={'center'}
                alignItems={'center'}
            >
                <Box component={'img'} src='/static/images/pro.png' sx={{ width: { md: '15vw', xs: '24vw' }, marginBottom: '10px' }} />
                <Box component={'a'}
                    href='/subscription'
                    sx={{
                        textAlign: 'center',
                        color: '#ffffff',
                        lineHeight: 1.2
                    }}
                ><Typography sx={{ fontSize: { md: '2rem', xs: '1rem' }, }}> See the benefits, unlock this feature<br /> {planMessage ? planMessage : "Upgrade Required"}</Typography>
                </Box>
                <LoadingButton
                    loading={false}

                    color='primary'
                    variant="contained"
                    sx={{
                        mt: 2,
                        mb: 1.5,
                    }}
                    onClick={() => handleOpenSubscription()}
                >
                    Upgrade now
                </LoadingButton>
            </Stack>
        </Backdrop >
    );
};

export default BlockOverlay;
