import { createSlice } from '@reduxjs/toolkit';
import { RejectionFreePageVideoItem, initialState } from './api/interface';
import { getCapitalizedString } from '../../utils/utilMethods';
import { getRejectionFreePageVideos } from './api';
import _ from 'lodash';

const rejectionFreePageVideoSlice = createSlice({
  name: 'rejectionFreePageVideo',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getRejectionFreePageVideos.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getRejectionFreePageVideos.fulfilled, (state, action) => {
      state.data = action.payload.data.data;
      let overviewPosition: number = state.data.findIndex(item => item.type == 'intro');
      let mbxOverviewPosition: number = state.data.findIndex(item => item.type == 'mbx_intro');
      let fixingTheProblemPosition: number = state.data.findIndex(item => item.type == 'problem');

      if (overviewPosition >= 0) {
        state.overview = state.data[overviewPosition];
      }

      if (mbxOverviewPosition >= 0) {
        state.mbxOverview = state.data[mbxOverviewPosition];
      }

      if (fixingTheProblemPosition >= 0) {
        state.fixingTheProblem = state.data[fixingTheProblemPosition];
      }
      state.searchResult = filterVideos(state.searchText, state.data);
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getRejectionFreePageVideos.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset } = rejectionFreePageVideoSlice.actions;
export default rejectionFreePageVideoSlice.reducer;

const filterVideos = (searchText: string, videoList: RejectionFreePageVideoItem[]) => {

  let videoItems: RejectionFreePageVideoItem[] = [...videoList];

  return videoItems.filter((item) => (item.type !== 'intro' && item.type !== 'mbx_intro' && item.type !== 'problem'))

}