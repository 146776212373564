import {
  Typography,
  Box,
  Grid,
  Card,
  Stack,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import Page from '../components/Page';
import { AppDispatch } from '../store';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import _ from 'lodash';

import '@splidejs/react-splide/css';
import styles from "../styles/HamburgerMenuModal.module.css";
import { LoadingButton } from '@mui/lab';
import { FormikProvider, Form, useFormik } from 'formik';
import { supportTicket } from '../store/auth/api';
import { checkIsProUser } from '../utils/checkPro';
import SupportSchema from '../validation/supportSchema';
import { showErrorMessage, showSuccessMessage } from '../utils/utilMethods';



const Support = () => {
  const dispatch = useDispatch<AppDispatch>();


  const titleList = [
    'Please Select',
    'Technical Support',
    'Billing',
    'Suggestions',
    'Contact Corporate',
    'Report a Bug'
  ];

  const pageList = [
    'Please Select',
    'Login',
    'Setup',
    'Social Post',
    'Media',
    'Business Page',
    'Sharing',
    'Reporting',
    'Other'
  ];

  const formik = useFormik({
    initialValues: {
      issueType: '0',
      issuePage: '0',
      email: '',
      description: '',
    },
    validationSchema: SupportSchema,
    onSubmit: async () => {
      try {

        const type = titleList[Number(values.issueType)];
        const response = await dispatch(
          supportTicket({
            description: values.description,
            subject: `${type}${(values.issueType == "1" || values.issueType == "5") ? ` / Page - ${pageList[Number(values.issuePage)]}` : ""}`,
            email: values.email,
            priority: 1,
            status: 2,
            type: type,
          })
        ).unwrap();
        if (response.status == 200 || response.status == 201) {
          showSuccessMessage('Your request has been received and will be answered via Email.');
          formik.resetForm();
        } else {
          response?.data?.status?.description && showErrorMessage(response?.data?.status?.description ?? "");
        }
        setSubmitting(false);
      } catch (error: any) {
        showErrorMessage(error);
      }
    },
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
  } = formik;

  return (
    <>
      <Page
        title='Customer Support | KnoCard'
        showAppBar={true}
      >


        <Grid container sx={{ mt: 5 }} >
          <Grid item md={6} xs={12}>
            <Card sx={{ m: 2 }}>
              <Box sx={{ p: 2 }}>
                <Typography
                  variant='h6'
                  color={'primary'}
                  sx={{ textAlign: 'left', mb: 1 }}
                >
                  Your Feedback is Crucial
                </Typography>

                <FormikProvider value={formik}>
                  <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
                    <Stack
                      direction='column'

                      justifyContent='space-between'
                      spacing={2}

                    >
                      <Box>
                        <Typography
                          sx={{ textAlign: 'left', mb: 1 }}
                        >
                          How may we help?
                        </Typography>
                        <Select
                          id='issue-type-select'
                          fullWidth
                          {...getFieldProps('issueType')}
                          className={styles.activityFilterDropDown}
                          error={Boolean(
                            touched.issueType && errors.issueType
                          )}

                        >
                          {titleList.map((item: any, index: number) => {
                            return (
                              <MenuItem value={index}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Box>


                      {(formik.values.issueType == "1" || formik.values.issueType == "5") && <Box>
                        <Typography
                          sx={{ textAlign: 'left', mb: 1 }}
                        >
                          Page
                        </Typography>
                        <Select
                          id='page-select'
                          fullWidth
                          {...getFieldProps('issuePage')}
                          className={styles.activityFilterDropDown}
                          error={Boolean(
                            touched.issuePage && errors.issuePage
                          )}

                        >
                          {pageList.map((item: any, index: number) => {
                            return (
                              <MenuItem value={index}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Box>}


                      <Box>
                        <Typography
                          sx={{ textAlign: 'left', mb: 1 }}
                        >
                          Email
                        </Typography>
                        <TextField
                          fullWidth
                          type={'text'}
                          {...getFieldProps('email')}
                          placeholder='Email'

                          error={Boolean(
                            touched.email && errors.email
                          )}
                          helperText={touched.email && errors.email}
                        /></Box>

                      <Box>
                        <Typography
                          sx={{ textAlign: 'left', mb: 1 }}
                        >
                          Description
                        </Typography>
                        <TextField
                          fullWidth
                          type={'text'}
                          {...getFieldProps('description')}
                          placeholder='Description'
                          multiline={true}
                          minRows={4}
                          maxRows={4}
                          error={Boolean(
                            touched.description && errors.description
                          )}
                          helperText={touched.description && errors.description}
                        /></Box>



                      <Stack
                        width={'100%'}
                        direction={'row'}
                        justifyContent={'end'}

                      >
                        <LoadingButton
                          fullWidth
                          size='large'
                          type='submit'
                          variant='contained'
                          loading={isSubmitting}
                          sx={{
                            borderRadius: 30,
                            width: "auto",
                            mt: 1
                          }}
                        >
                          Submit
                        </LoadingButton>
                      </Stack>
                    </Stack>
                  </Form>
                </FormikProvider>
              </Box>
            </Card>
          </Grid>

        </Grid>


      </Page >
    </>
  );
};


export default Support;
