import { styled, Tab, TabProps } from "@mui/material";
import { AppColors } from "../../utils/utils";

interface CustomTabProps extends TabProps {
    isSmallScreen: boolean;
}

const HeaderTabItem = styled(Tab)<CustomTabProps>(({ theme, isSmallScreen }) => ({
    padding: '10px',

    ...(!isSmallScreen ? { background: AppColors.grayZeroColor, borderRadius: '10px' } : {}),
    color: AppColors.grayTwoColor,
    textTransform: "capitalize",
    transition: 'border-color 0.3s',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: '500',
    justifyContent: 'center',
    '&.Mui-selected': {

        ...(isSmallScreen ? { color: AppColors.primaryColor } : { borderRadius: '10px', color: AppColors.whiteColor, background: `linear-gradient(0deg, ${AppColors.secondryColor}, ${AppColors.primaryColor})` }),

        fontWeight: '500',
    },
    [theme.breakpoints.up('md')]: {
        minHeight: 0,
        marginTop: '10px',
        marginLeft: 10,
        marginRight: 10,
    },
}));

export default HeaderTabItem;