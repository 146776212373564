import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { approveContactPiplineLeadConsent, getContactPiplineLead, getEngagePipeline, updateEngageUserContact } from './api';
import { initialState } from './api/interface';
import _ from 'lodash';

const engagePipelineSlice = createSlice({
  name: 'engage',
  initialState,
  reducers: {
    reset: () => initialState,
    clearContactPiplineLead: (state, action: PayloadAction<{}>) => {
      state.contactPiplineLead = null;
    }, clearPipelineActivityCount: (state, action) => {
      if (action.payload !== undefined) {
        state.data = state.data.map((item) => {
          if (item.id === action.payload) {
            item.activities = 0;
          }
          return item;
        });
      }
    },


  },
  extraReducers: (builder) => {
    builder.addCase(getEngagePipeline.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getEngagePipeline.fulfilled, (state, action) => {
      state.data = (action.payload.data.data.current_page != 1) ? [...(state.data ?? []), ...(action.payload.data.data.data ?? [])] : action.payload.data.data.data ?? [];
      state.plans = action.payload.data.plans;
      state.isLoadMore = action.payload.data.data.data.length >= 10;
      state.page = action.payload.data.data.current_page + 1;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getEngagePipeline.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
    builder.addCase(updateEngageUserContact.fulfilled, (state, action) => {
      let data = state.data;
      if (data) {
        let position = -1;
        data.forEach((element, index) => {
          if (element.id == action.payload.data.data.id) {
            position = index;
          }
        });

        if (position >= 0) {
          let engageactivity = data[position];
          engageactivity.updated_at = action.payload.data.data.updated_at;
          data[position] = engageactivity;
          state.data = data;
        }

      }

    });

    builder.addCase(getContactPiplineLead.fulfilled, (state, action) => {
      if (action.payload.data.data && action.payload.data.data.consent_status == "pending") {
        state.contactPiplineLead = action.payload.data.data;
      }
    });

    builder.addCase(approveContactPiplineLeadConsent.fulfilled, (state, action) => {
      state.contactPiplineLead = null;
    });
    builder.addCase(approveContactPiplineLeadConsent.rejected, (state, action) => {
      state.contactPiplineLead = null;
    });

  },
});

export const { reset, clearContactPiplineLead, clearPipelineActivityCount } = engagePipelineSlice.actions;
export default engagePipelineSlice.reducer;
