import {
  Checkbox,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import '@splidejs/react-splide/css';

import { PaypalInitialOptions, config } from '../../utils/utils';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import PaypalOrderButton from '../Paypal/PaypalOrderButton';
import PaypalSubscriptionButton from '../Paypal/PaypalSubscriptionButton';
import { pubscriptionPurchaseSuccess } from '../../store/purchase/api';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../../store';
import { useNavigate } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { CompanyFeed } from '../../store/social/api/interface';
import WarningIcon from '@mui/icons-material/Warning';
import { Fragment, useEffect, useState } from 'react';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import BugReportIcon from '@mui/icons-material/BugReport';
import { getSocialFeeds, reportSocialFeed } from '../../store/social/api';
import { unFollowUserRequest } from '../../store/communityUsers/api';
import { showErrorMessage, showSuccessMessage } from '../../utils/utilMethods';

type IProps = {
  reportSocialPost: CompanyFeed,
  toggle: boolean;
  onClose: () => void;
};


interface OtherSteps {
  title: string;
  description: string;
  icon: any;
};

const opts = {
  height: '100%',
  width: '100%',
};

const ReportSocialPostDailog: React.FC<IProps> = ({ toggle, onClose, reportSocialPost }) => {

  const dispatch = useDispatch<AppDispatch>();
  const userInfo = useAppSelector((state) => state?.auth?.user);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedReportType, setSelectedReportType] = useState<string | undefined>(undefined);
  const [selectedOtherReportType, setSelectedOtherReportType] = useState<string | undefined>(undefined);

  const reportTypes = [
    'Hate Speech',
    'Nudity or Sexual Content',
    'Violence',
    'Harassment',
    'Scams',
    'Illegal Stuff'
  ];

  var otherStepHeaders: Array<OtherSteps> = [
    { title: 'Block this person', description: 'You won\'t be able to see or contact each other', icon: PersonOffOutlinedIcon },
    { title: 'Unfollow this person', description: 'Stop seeing his post\'s', icon: RemoveCircleOutlineOutlinedIcon },
    { title: 'Report profile', description: 'Submit to Knocard for review', icon: BugReportIcon }
  ];

  const handleReportDone = () => {

    if (selectedReportType == undefined) {
      showErrorMessage("Please select appropriate report reason.");
      return;
    }

    setIsLoading(true);
    dispatch(reportSocialFeed({
      user_id: userInfo?.id ?? 0,
      reportable_id: reportSocialPost.id,
      report_code: (selectedReportType ?? "").toLocaleLowerCase().replaceAll(" ", "_"),
      message: selectedOtherReportType ? (selectedOtherReportType ?? "").toLocaleLowerCase().replaceAll(" ", "_") : undefined,
      report_type: 'report_company_feed'
    })).unwrap().then((response) => {
      if (response.status == 200) {
        if (response.data.status?.code == "success") {
          showSuccessMessage("Reported successfully.");

          if (selectedOtherReportType != otherStepHeaders[2].title) {
            dispatch(unFollowUserRequest({ user_id: reportSocialPost.company?.owner?.id ?? 0 })).unwrap().then((result) => {
              dispatch(getSocialFeeds({ page: 1, user_id: userInfo?.id }));
            });
          }
          onClose();
        } else {
          showErrorMessage(response.data.status?.description ?? "");
        }

      } else {
        showErrorMessage("Failed to report.");
      }
      setIsLoading(false);

    });

  };

  useEffect(() => {
    if (toggle) {
      setSelectedReportType(undefined);
      setSelectedOtherReportType(undefined);
    }
  }, [toggle]);


  return (
    <>
      <Dialog
        open={toggle}
        onClose={() => {

        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='sm'
        fullWidth
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: 3000
        }}

      >
        <DialogTitle
          sx={{
            backgroundColor: '#088ac6',
            color: 'white',
          }}
        >
          Report post
        </DialogTitle>

        <DialogContent sx={{ padding: 0 }}>

          <Stack
            justifyContent="center"
            alignItems="center"
            flexDirection={'row'}
            sx={{ ml: 2 }}
          ><WarningIcon sx={{ color: "#FF9800" }} />
            <Box
              margin={2}
              alignItems='center'
              justifyContent='center'
              mb={window?.innerWidth > 500 ? 2 : 1}
            ><Typography>Help us to understand what's happening.</Typography>
            </Box></Stack>

          <Box sx={{ ml: 1, mr: 1 }}>
            {reportTypes.map((reportType, idx) => {
              return (<Chip
                key={`report-type-${idx}`}
                label={reportType}
                variant={selectedReportType == reportType ? undefined : "outlined"}
                color={selectedReportType == reportType ? 'primary' : undefined}
                onClick={() => setSelectedReportType(selectedReportType == reportType ? undefined : reportType)}
                sx={{ m: 0.5 }}
              />)
            })}

          </Box>
          <Box
            margin={2}
            alignItems='center'
            justifyContent='center'
            sx={{ textAlign: 'center' }}
          ><Typography>We take your feedback to help our systems learn when somethin isn't right</Typography>
          </Box>
          <Divider sx={{ ml: 1, mr: 1 }} />

          <Stack
            margin={2}
            justifyContent="center"
            alignItems="center"
            flexDirection={'row'}
            sx={{ textAlign: 'center' }}
          ><WarningIcon sx={{ color: "#8f8f8f", ml: 2 }} />
            <Box
              alignItems='center'
              justifyContent='center'
            ><Typography sx={{ color: '#8f8f8f' }} >If someone is in immediate danger, call local emmergency services. Don't wait.</Typography>
            </Box></Stack>
          <Divider sx={{ ml: 1, mr: 1 }} />
          <Box
            margin={2}
            alignItems='center'
            justifyContent='center'
            sx={{ textAlign: 'center' }}
          ><Typography>Other steps you can take</Typography>
          </Box>
          <Divider sx={{ ml: 1, mr: 1 }} />



          <List sx={{ width: '100%' }}>
            {otherStepHeaders.map((otherStepHeader, idx) => {
              return (<ListItem
                alignItems="flex-start"
                key={`other-step-${idx}`}
                secondaryAction={
                  <IconButton edge="end" onClick={() => setSelectedOtherReportType(selectedOtherReportType == otherStepHeader.title ? undefined : otherStepHeader.title)}>
                    <Checkbox
                      edge="start"
                      checked={selectedOtherReportType == otherStepHeader.title ? true : false}
                      tabIndex={-1}
                      disableRipple
                    />
                  </IconButton>
                }
              >

                <ListItemIcon>
                  <otherStepHeader.icon />
                </ListItemIcon>

                <ListItemText
                  primary={otherStepHeader.title}
                  secondary={
                    <Fragment>
                      {otherStepHeader.description}
                    </Fragment>
                  }
                />
              </ListItem>);
            })}
          </List>

        </DialogContent>
        <DialogActions >
          <LoadingButton
            type="button"
            variant="contained"
            onClick={onClose}
            sx={{ mr: 1 }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            type="submit"
            onClick={handleReportDone}
            loading={isLoading}
            variant="contained"
          >
            Done
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReportSocialPostDailog;
