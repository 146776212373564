export const checkPhoneNumber = (
    phone_number: string | undefined,
    phone_country_code: string | undefined,
    show_phone_number: number | undefined,
    business_phone: string | null | undefined
) => {
    if ((phone_number && show_phone_number === 1) || business_phone) {
        return phone_number && show_phone_number === 1
            ? `${phone_country_code} ${phone_number}`
            : `${phone_country_code} ${business_phone}`;
    }

    return null;
};
