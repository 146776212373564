import { LoadingButton } from "@mui/lab";
import { Avatar, Box, CircularProgress, DialogContentText, Grid, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField } from "@mui/material";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/system";
import { Transition } from "../Transition";

import { useAppDispatch, useAppSelector } from "../../store";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { UserInfo } from "../../store/userInfo/api/interface";
import debounce from 'lodash/debounce';
import SearchIcon from '@mui/icons-material/Search';
import { getCommunityUsers } from "../../store/communityUsers/api";
import { reset as resetCommunityUsers } from "../../store/communityUsers/communityUsersSlice";
import { AppColors } from "../../utils/utils";

interface IProps {
    toggle: boolean;
    onClose: () => void;
    onSelect: (userInfo: UserInfo) => void;
}


const SelectContactModal: React.FC<IProps> = ({ toggle, onClose, onSelect }) => {

    const dispatch = useAppDispatch();
    const [filterKeyWord, setFilterKeyWord] = useState<string>("");
    const onCancel = () => {
        onClose();
    };
    const [scroll] = useState<DialogProps['scroll']>('paper');
    const observer = useRef<IntersectionObserver>();
    const descriptionElementRef = useRef<HTMLElement>(null);
    const { isLoading, isLoadMore, page } = useAppSelector((state) => state?.communityUsers);
    const communityUsers = useAppSelector((state) => state?.communityUsers.data) ?? [];

    useEffect(() => {
        if (toggle) {
            handleLoadMore(true, undefined);
        }
    }, [toggle])


    const searchUserInfoInModal = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterKeyWord(e.target.value ?? "");
        handleLoadMore(true, e.target.value ?? "");
    };

    const debounceWithSearch = debounce(searchUserInfoInModal, 700);

    const lastElementRef = useCallback(
        // (*)
        (node: any) => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && isLoadMore) {
                    handleLoadMore(false, undefined);
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLoading, isLoadMore]
    );

    const handleLoadMore = (isReload: boolean, searchKeyWord: string | undefined) => {

        if ((searchKeyWord ?? filterKeyWord).length <= 0) {
            dispatch(resetCommunityUsers());
            return;
        }

        dispatch(getCommunityUsers({
            from_lat: '0.0',
            from_lng: '0.0',
            filter_distance: "100000",
            sort_by: 'rating',
            page: isReload ? 1 : page,
            filter_keyword: searchKeyWord ?? filterKeyWord
        }));
    };


    return (
        <>
            <Dialog
                fullWidth
                open={toggle}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    onCancel();
                }}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        maxHeight: '60%'
                    }
                }}
            >
                <DialogTitle
                    sx={{
                        backgroundColor: AppColors.primaryColor,
                        color: "white",
                    }}
                >
                    Select Contact
                </DialogTitle>


                <Grid item xs={12} sm={12} pl={2} pr={2} pb={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" marginTop={2}>
                        <TextField
                            fullWidth
                            size="small"
                            placeholder={'Search contact'}
                            onChange={debounceWithSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon
                                            sx={{
                                                color: 'text.disabled',
                                                width: 20,
                                                height: 20,
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Stack>
                </Grid>
                <DialogContent dividers={scroll === 'paper'} sx={{ width: '100%', padding: 0 }}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                        component="span"
                    >
                        <nav>
                            <List>

                                {(communityUsers?.length ?? 0) === 0 ? (
                                    <Stack direction="row" alignItems="center" justifyContent="center">
                                        {!isLoading && 'No Contacts found.'}
                                    </Stack>
                                ) : (
                                    communityUsers?.map((userInfoItem: UserInfo, idx: number) => {
                                        return <ListItemButton ref={idx + 1 === communityUsers.length ? lastElementRef : undefined} key={userInfoItem.id} onClick={() => {
                                            onSelect(userInfoItem);
                                            onClose();

                                        }}>
                                            <ListItem
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <Box component={"img"} src={userInfoItem?.profile_picture ?? "/static/images/placeholder_profile.png"} sx={{ width: "40px", height: "40px" }} />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={userInfoItem.name ?? ""}
                                                    secondary={userInfoItem.occupation ?? ""}
                                                />
                                            </ListItem>
                                        </ListItemButton>

                                    })
                                )}
                                {isLoading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress />
                                </Box>}
                            </List>
                        </nav>
                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ paddingX: 3 }}>
                    <LoadingButton
                        type="button"
                        loading={false}
                        variant="contained"
                        onClick={onCancel}
                    >
                        Cancel
                    </LoadingButton>
                </DialogActions>

            </Dialog>
        </>
    );
};

export default SelectContactModal;
