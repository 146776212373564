import { Avatar, Button, CircularProgress, DialogContent, Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, Stack } from "@mui/system";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import {
  PostObject,
  ShareFormInfoResponse,
} from "../../store/post/api/interface";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../store";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { FC, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { analytics } from "../../store/shareRefer/api";
import YouTube from "react-youtube";
import CheckIcon from "@mui/icons-material/Check";

import {
  AnswerItem,
} from "../../constant";
import { getShareFormAnswer } from "../../store/post/api";
import ReactReadMoreReadLess from '../ReactReadMoreReadLess';
import Iconify from "../Iconify";
import { AppColors, config } from "../../utils/utils";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { checkIsProProOrProOneOrProPlusUser } from "../../utils/checkPro";
import { showSuccessMessage } from "../../utils/utilMethods";
import Vimeo from '@u-wave/react-vimeo';
import MediaVideoComponent from "../MediaVideoComponent";
import EyeIcon from '../../assets/media_post/eye.svg';
import ShareIcon from '../../assets/media_post/Share.svg';
import CancelIcon from '@mui/icons-material/Cancel';

type IProps = {
  toggle: boolean;
  onClose: () => void;
  activePost: PostObject | undefined;
  shareFormInfoResponse?: ShareFormInfoResponse;
  setShareFormInfoResponse?: (shareFormInfoResponse?: ShareFormInfoResponse) => void;
  manuallyClicked: boolean;
};

const opts = {
  height: "100%",
  width: "100%",
  playerVars: {
    // Hide related videos after the video finishes
    rel: 0,
    // Hide the video annotations
    iv_load_policy: 3,
    // Hide the YouTube logo
    modestbranding: 1,
  },
};

const FeedBackForm: FC<{
  setPositiveAnswer: (answer: AnswerItem | null) => void;
  setOpen: React.Dispatch<
    React.SetStateAction<{
      toggle: boolean;
      message: string;
    }>
  >;
  shareFormInfoResponse: ShareFormInfoResponse;
}> = ({ setOpen, shareFormInfoResponse, setPositiveAnswer }) => {
  const dispatch = useAppDispatch();
  const matches = useMediaQuery("(max-width:745px)");
  const profile_picture = useAppSelector((state) => state.userInfo.data?.profile_picture ?? state?.auth?.user?.profile_picture) ?? "";

  const calendar_link = useAppSelector((state) => state.userInfo.data?.calendar_link);

  const [selectedAnswer, setSelectedAnswer] = useState<null | string>(null);
  const [isAnswered, setAnswered] = useState<{
    toggle: boolean;
    answerIdx: number | null;
  }>({
    toggle: false,
    answerIdx: null,
  });

  const handleAnswer = (value: string) => {
    setSelectedAnswer(value);
    /*if (value.key != "not_at_this_time" && calendar_link != undefined && calendar_link.trim().length > 0) {
      setPositiveAnswer(value);
    }*/
  };

  const handleSubmitAnswer = async () => {
    if (!selectedAnswer) {
      setOpen((curr) => ({
        ...curr,
        toggle: true,
        message: "Please select answer.",
      }));
      return;
    }
    try {
      await dispatch(
        getShareFormAnswer({
          shareFormId: shareFormInfoResponse.data.id,
          postby_id: shareFormInfoResponse.data.user_id,
          form_code: shareFormInfoResponse.data.form_code,
          post_id: shareFormInfoResponse.data.post_id,
          answer: selectedAnswer
        })
      ).unwrap();
      setAnswered((curr) => ({
        ...curr,
        toggle: true,
        answer: selectedAnswer
      }));

      showSuccessMessage("Thanks for your feedback; I'll be in touch soon!");

    } catch (error) { }
  };

  const handleBookACall = () => {
    window.open(`${calendar_link}`) || window.location.replace(`${calendar_link}`);
  };


  if (isAnswered.toggle) {
    return (
      <SplideSlide>
        <Box height="100%" width="100%" sx={{ background: AppColors.whiteColor }}>
          <Box
            width="90%"
            marginX="auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={3}
            paddingY="18px"
            borderRadius="12px"
          >

            <Typography textAlign="center" sx={{ mt: 6, color: AppColors.blackColor, fontSize: { sm: '1.5rem', xs: '1.3rem' } }}>
              Thanks for your interest. I would love to chat and answer any questions. Click on my calendar to schedule.
            </Typography>


            {(calendar_link && calendar_link.length > 0) && <Stack flexDirection={'row'} sx={{ backgroundColor: AppColors.primaryColor, border: `1.5px solid ${AppColors.strokeColor}`, pl: 2, pr: 2, pt: 1, pb: 1, mt: 3, borderRadius: "50px" }} onClick={handleBookACall}>
              <CalendarMonthIcon sx={{ color: AppColors.whiteColor, fontSize: "30px" }} />
              <Typography textAlign="center" sx={{ color: AppColors.whiteColor, fontSize: '1.3rem', ml: 2, mr: 2 }}>Book a Call</Typography>
            </Stack>}

          </Box>
        </Box>
      </SplideSlide>
    );
  }
  return (
    <SplideSlide>
      <Box height="100%" width="100%" position="relative" sx={{ background: AppColors.whiteColor, pt: 2 }}>
        <Stack
          display="flex"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          width="95%"
          marginX="auto"
          paddingTop="12px"
          paddingBottom="18px"
        >
          <Typography
            color="white"
            textAlign="center"
            sx={{ fontWeight: "400", fontSize: "1.3rem", color: AppColors.blackColor }}
          >
            {shareFormInfoResponse.data.feedback_form?.question ?? ""}
          </Typography>
        </Stack>
        <Box
          width="90%"
          margin="auto"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={1.5}
          paddingY="18px"
          borderRadius="12px"
        >
          {[
            shareFormInfoResponse.data.feedback_form?.answer_one ?? "",
            shareFormInfoResponse.data.feedback_form?.answer_two ?? "",
            shareFormInfoResponse.data.feedback_form?.answer_three ?? "",
            shareFormInfoResponse.data.feedback_form?.answer_four ?? "",
          ].map((item, idx) => (
            item.length > 0 ? (<Stack
              key={`answer-${idx}`}
              bgcolor={AppColors.grayZeroColor}
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              paddingY="5px"
              paddingX="12px"
              borderRadius="18px"
              width="80%"
              margin="auto"
              sx={{ cursor: "pointer", border: `1.5px solid ${AppColors.strokeColor}` }}
              onClick={() => handleAnswer(item)}
            >
              <Box width="45px">
                {selectedAnswer === item && (
                  <Box sx={{ borderRadius: "100px", backgroundColor: AppColors.primaryColor, height: '30px', width: "30px", justifyContent: 'center', display: 'flex', p: 0.5 }}><CheckIcon sx={{ color: AppColors.whiteColor }} /></Box>
                )}
              </Box>

              <Typography color="white" sx={{ fontWeight: "400", fontSize: "1.1rem", color: AppColors.grayThreeColor }}>
                {item}
              </Typography>
            </Stack>) : <></>
          ))}
        </Box>
        <Stack
          display="flex"
          direction="row"
          justifyContent="center"
          alignItems="center"
          marginY="12px"
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: AppColors.primaryColor,
              borderRadius: "30px",
              fontWeight: "500",
              "&:hover": {
                backgroundColor: AppColors.primaryColor,
              }
            }}
            onClick={handleSubmitAnswer}
          >
            Submit
          </Button>
        </Stack>

      </Box>
    </SplideSlide>
  );
};


const RenderFeedBackForm: FC<{
  shareFormInfoResponse?: ShareFormInfoResponse;
  setPositiveAnswer: (answer: AnswerItem | null) => void;
  setOpen: React.Dispatch<
    React.SetStateAction<{
      toggle: boolean;
      message: string;
    }>
  >;
}> = ({ shareFormInfoResponse, setOpen, setPositiveAnswer }) => {

  if (!shareFormInfoResponse) return <></>;

  if (shareFormInfoResponse.data.form_answer) return <></>;

  if (shareFormInfoResponse.data.feedback_form_id == null || shareFormInfoResponse.data.feedback_form_id == undefined) return <></>;

  if (shareFormInfoResponse.data.feedback_form_id && shareFormInfoResponse.data.feedback_form?.question)

    return (
      <FeedBackForm
        setOpen={setOpen}
        shareFormInfoResponse={shareFormInfoResponse}
        setPositiveAnswer={setPositiveAnswer}
      />
    );


  return <></>;
};

const ViewMediaPostModal: React.FC<IProps> = ({
  toggle,
  onClose,
  activePost,
  shareFormInfoResponse,
  setShareFormInfoResponse,
  manuallyClicked,
}) => {
  const dispatch = useAppDispatch();
  const profile_picture = useAppSelector((state) => state?.userInfo?.data?.profile_picture ?? state?.auth?.user?.profile_picture);
  const name = useAppSelector((state) => state?.userInfo?.data?.name ?? state?.auth?.user?.name);

  const userInfo = useAppSelector(
    (state) => state?.userInfo.data ?? state?.auth?.user
  );


  const [selectedAnswer, setSelectedAnswer] = useState<null | AnswerItem>(null);



  const sbUserInfo = useAppSelector((state) => state.userInfo.sbUser);

  const occupation = useAppSelector(
    (state) => state?.userInfo?.data?.occupation ?? state?.auth?.user?.occupation
  );
  const [open, setOpen] = useState({
    toggle: false,
    message: '',
  });

  const calendar_link = useAppSelector((state) => state.userInfo.data?.calendar_link);
  const authUserInfo = useAppSelector((state) => state?.auth?.user);
  const isLoading = useAppSelector((state) => state?.post?.isLoading);
  const [searchParams] = useSearchParams();
  const [activeSlide, setActiveSlide] = useState<number>(0)
  const [totalSlide, setTotalSlide] = useState<number>(0)
  const [isFirstTimeVideoPlay, setIsFirstTimeVideoPlay] = useState<{
    id: number | null;
    flag: boolean;
  }>({
    id: null,
    flag: true,
  });

  const hasMedia = activePost?.contents != undefined && activePost?.contents?.length > 0;


  useEffect(() => {
    if (toggle) {
      setActiveSlide(0);
    }
  }, [toggle]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(() => ({
      toggle: false,
      message: '',
    }));
  };

  const handleOnplayVideo = (contentId: number, second: number, duration: number) => {
    if (!isFirstTimeVideoPlay.flag && contentId === isFirstTimeVideoPlay.id)
      return;
    const sc = searchParams.get('sc');
    let elid = searchParams.get('elid');
    let loginUserId = authUserInfo?.id;

    dispatch(
      analytics({
        route: '/post/view',
        data: {
          post_id: activePost?.id,
          postby_id: activePost?.user_id,
          view_type: 'video',
          content_id: contentId,
          video_watch_seconds: second,
          video_duration_seconds: duration,
          ...(loginUserId && { user_id: loginUserId }),
          ...((!loginUserId && sc) && { viewer_code: sc }),
          ...(!loginUserId && elid && { elid: elid })
        },
      })
    );
    setIsFirstTimeVideoPlay({
      id: contentId,
      flag: false,
    });
  };


  const handleOnPauseOrCompleteVideo = (contentId: number, second: number, duration: number) => {
    const sc = searchParams.get('sc');
    let elid = searchParams.get('elid');
    let loginUserId = authUserInfo?.id;

    dispatch(
      analytics({
        route: '/post/view/video-time',
        data: {
          post_id: activePost?.id,
          postby_id: activePost?.user_id,
          view_type: 'video',
          video_watch_seconds: second,
          video_duration_seconds: duration,
          content_id: contentId,
          ...(loginUserId && { user_id: loginUserId }),
          ...((!loginUserId && sc) && { viewer_code: sc }),
          ...(!loginUserId && elid && { elid: elid })
        },
      })
    );
  };





  const action = (
    <IconButton
      size='small'
      aria-label='close'
      color='inherit'
      onClick={handleClose}
    >
      <CloseIcon fontSize='small' />
    </IconButton>
  );


  const handleCloseDialog = () => {
    onClose();
  };

  const openShareByKnoCard = () => {
    if (sbUserInfo?.username) {
      let userVbcLink = `${config.VBC_URL}/${sbUserInfo?.username ?? ''}`;
      window.open(userVbcLink) || window.location.replace(userVbcLink);
    }
  };



  const handleSubmitAnswer = async (userAnswer: string) => {
    if (!selectedAnswer) {
      return;
    }
    try {
      if (shareFormInfoResponse) {
        await dispatch(
          getShareFormAnswer({
            shareFormId: shareFormInfoResponse.data.id,
            postby_id: shareFormInfoResponse.data.user_id,
            form_code: shareFormInfoResponse.data.form_code,
            post_id: shareFormInfoResponse.data.post_id,
            answer: userAnswer,
          })
        ).unwrap();

        showSuccessMessage(selectedAnswer?.key == "not_at_this_time"
          ? "I appreciate your feedback, let's stay in touch"
          : "Thanks for your feedback; I'll be in touch soon!");

        setSelectedAnswer(null);
        setShareFormInfoResponse?.call(undefined);
      }
    } catch (error) { }
  };

  const handleOpenCalendarLink = () => {

    if (selectedAnswer) {
      handleSubmitAnswer(selectedAnswer?.key);
    }

    if (calendar_link) {
      window.open(`${calendar_link}`) || window.location.replace(`${calendar_link}`);
    }
  };


  const getShareAndViewCount = () => {
    return (<Box
      alignItems='center'
      justifyContent='space-between'
      display={'flex'}
      sx={{ width: "100%" }}
    >
      <Stack
        direction='row'
        color='grey'
        alignItems='center'
        gap={1}
      >
        <Box component={'img'} src={EyeIcon} />
        <Typography
          variant='body2'
          component='div'
          color='inherit'
          sx={{ color: AppColors.grayThreeColor }}
        >
          {activePost?.views_count} Views
        </Typography>
      </Stack>
      <Stack
        direction='row'
        color='grey'
        alignItems='center'
        gap={1}
      >
        <Box component={'img'} src={ShareIcon} />
        <Typography
          variant='body2'
          component='div'
          color='inherit'
          sx={{ color: AppColors.grayThreeColor }}
        >
          {activePost?.shares_count ?? 0} Shares
        </Typography>
      </Stack>


    </Box>);
  };


  return (
    <>
      <Dialog
        open={toggle}
        onClose={() => {
          setIsFirstTimeVideoPlay({
            id: null,
            flag: true,
          });
          handleCloseDialog();
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='lg'
        fullWidth

      >
        {(!sbUserInfo && !selectedAnswer) && <Button
          variant='contained'
          sx={{
            backgroundColor: AppColors.primaryColor,
            textTransform: 'none',
            fontWeight: 'bold',
            position: 'absolute',
            right: 10,
            top: 10,
            zIndex: 1000,
            lineHeight: 'normal',
          }}
          onClick={handleCloseDialog}
        >
          View KnoCard
        </Button>}

        {/* <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{ position: 'absolute', right: -10, top: 0, zIndex: 100000 }}
        >
          <CancelIcon sx={{ color: AppColors.grayZeroColor, height: '30px', width: '30px' }} />
        </IconButton> */}

        <DialogContent sx={{ p: selectedAnswer ? 0 : 1, background: AppColors.backgroundColor }}>
          {isLoading ? (
            <Box width='100%' display='flex' justifyContent='center'>
              <CircularProgress />
            </Box>
          ) : (
            <>{(selectedAnswer && calendar_link) ? <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ background: AppColors.whiteColor, textAlign: 'center' }}>
                <Box sx={{ background: AppColors.primaryColor, height: "5px", width: "100%", mt: 0.8 }} />
                <Box sx={{ background: AppColors.orangeColor, height: "5px", width: "100%" }} />
                <Box
                  component={'img'}
                  src="/static/images/knocard_logo.png"
                  alt="logo"
                  height="50px"
                  sx={{ cursor: 'pointer', mt: 1, mb: 1 }}
                />
                <Typography sx={{ color: AppColors.blackColor, textAlign: "center", pl: 1, pr: 1 }}>Thanks for your interest. Please click below to schedule a time to chat.
                  <Box
                    component={'img'}
                    src="/static/images/knocard.png"
                    alt="logo"
                    height="20px"
                  /></Typography>

                <Box sx={{ backgroundImage: 'url("/static/images/feedback_form_calendar_bg.png")', backgroundRepeat: "repeat", "-webkit-background-size": "cover", "-moz-background-size": "cover", "-o-background-size": "cover", backgroundSize: "cover", p: 1, ml: 3, mr: 3, borderRadius: 3, mt: 2, mb: 2 }}>
                  <Stack
                    bgcolor={AppColors.orangeColor}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    paddingY="5px"

                    borderRadius="18px"
                    width="90%"
                    margin="auto"
                    sx={{ cursor: "pointer", pr: 1, mt: 2 }}
                    onClick={() => { }}
                  >
                    <Box width="40px">
                      <IconButton
                        aria-label="delete"
                        disabled
                        color="primary"
                        sx={{
                          backgroundColor: `${AppColors.greenColor} !important`,
                          color: "white !important",
                          padding: "4px !important",
                        }}
                      >
                        <CheckIcon />
                      </IconButton>

                    </Box>

                    <Typography variant={"body1"} color="white">
                      Yes, I will book another time
                    </Typography>
                  </Stack>
                  <Box onClick={handleOpenCalendarLink}>
                    <CalendarMonthIcon sx={{ mt: 2, mb: 1, color: "#ffffff", width: "100px", height: "100px" }} />
                    <Typography variant={"body1"} sx={{ color: "#072B43", fontWeight: "bold" }}>
                      Click my calendar link
                    </Typography>
                  </Box>
                </Box>

                <Stack
                  bgcolor={AppColors.orangeColor}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  paddingY="5px"
                  borderRadius="18px"
                  margin="auto"
                  sx={{ cursor: "pointer", pr: 1, pl: 3, width: { xs: "72%", md: "85%" }, mt: 4, mb: 4 }}
                  onClick={() => handleSubmitAnswer("not_at_this_time")}
                >
                  <Typography variant={"body1"} color="white">
                    Not at this time
                  </Typography>
                </Stack>
                <Stack
                  display="flex"
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  marginY="12px"
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: AppColors.greenColor,
                      borderRadius: "30px",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: AppColors.greenColor,
                      },
                    }}
                    onClick={() => handleSubmitAnswer(selectedAnswer?.key)}
                  >
                    Submit
                  </Button>
                </Stack>


                <Stack

                  direction="row"
                  alignItems="end"
                  justifyContent="flex-end"
                  paddingY="5px"
                  borderRadius="18px"
                  margin="auto"
                  sx={{ cursor: "pointer", pr: 3, pl: 3, mt: 4, mb: 3 }}
                  onClick={() => { }}
                >
                  <Typography variant={"body1"} color="white" sx={{ textAlign: "center" }}>
                    Thank you again and<br />look forward to<br /> connecting
                  </Typography>
                  <Box sx={{ borderRadius: "100%", background: AppColors.orangeColor, pr: 0.5, pt: 0.3, ml: 2 }}>
                    <Avatar alt='pic' src={profile_picture ?? '/static/images/placeholder_profile.png'} sx={{ height: "100px", width: "100px" }} />
                  </Box>
                </Stack>

              </Grid>
            </Grid> : <Grid container spacing={2}>

              {hasMedia && <Grid item xs={12} md={6}
                alignSelf={'center'}
                sx={{
                  display: { xs: 'none', md: 'block', position: 'relative' }
                }}
              >
                <Splide
                  key={`media-splide-${activePost?.id}`}
                  aria-label='Media Images'
                  options={{
                    perPage: 1,
                    fixedHeight: 425,
                    autoHeight: true
                  }}
                  onMove={(event) => {
                    setActiveSlide(event.index);
                  }}
                  onReady={(event) => {
                    setTotalSlide(event.length)
                  }}
                  style={{ borderRadius: "12px", overflow: 'hidden' }}
                >
                  {activePost?.contents?.map((content, sIdx) => {
                    return content?.type === 'image' ? (
                      <SplideSlide key={content.id}>
                        <Box
                          component={"img"}
                          src={content?.link}
                          alt={content?.filename}
                          height='100%'
                          width='100%'

                          className='object-fit-contain'
                        />
                      </SplideSlide>
                    ) : (content?.type === "pdf") ?

                      (<SplideSlide key={content.id}>
                        {activeSlide == sIdx && <iframe key={`iframe-${content.id}`} src={`https://drive.google.com/viewerng/viewer?pid=explorer&efh=false&a=v&chrome=false&embedded=true&url=${content?.link}`}
                          itemType="application/pdf"
                          width="100%" height="100%" style={{ overflowY: "scroll" }}>
                        </iframe>}
                      </SplideSlide>) : (
                        <SplideSlide key={content.id}>
                          {content?.platform === 'youtube' ? (
                            <YouTube
                              videoId={content?.link}
                              opts={opts}
                              className='object-fit-contain'
                              style={{
                                height: '100%',
                                width: '100%',
                              }}
                              onPlay={(props: any) => {
                                if (props.target.getCurrentTime() < 1) {
                                  handleOnplayVideo(content.id, 0, props.target.getDuration() || 0)
                                }
                              }}
                              onEnd={(props: any) => handleOnPauseOrCompleteVideo(content.id, props.target.getCurrentTime(), props.target.getDuration() || 0)}
                              onPause={(props: any) => handleOnPauseOrCompleteVideo(content.id, props.target.getCurrentTime(), props.target.getDuration() || 0)}
                            />
                          ) : content?.platform === "vimeo" ? (
                            <Vimeo
                              video={content?.link}
                              className="object-fit-contain vimeo-video-contain"
                              height={"100%"}
                              width={"100%"}
                              style={{
                                height: "100%",
                                width: "100%",
                                maxHeight: '50vh'
                              }}
                              onPlay={(props) => handleOnplayVideo(content.id, 0, props.duration || 0)}
                              onEnd={(props) => handleOnPauseOrCompleteVideo(content.id, props.seconds, props.duration || 0)}
                              onPause={(props) => handleOnPauseOrCompleteVideo(content.id, props.seconds, props.duration || 0)}
                            // onPlay={() => handleOnplayVideo(content.id)}
                            />
                          ) : (
                            <MediaVideoComponent
                              isDialogOpen={toggle}
                              content={content}
                              style={{}}
                              width='100%'
                              height='100%'
                              className='object-fit-contain'
                              handleOnplayVideo={handleOnplayVideo}
                              handleOnPauseOrCompleteVideo={handleOnPauseOrCompleteVideo}
                              onVisibilityChange={(visibility: boolean, videoId: number, time: number, duration: number) => {
                                if (!visibility) {
                                  handleOnPauseOrCompleteVideo(videoId, time, duration)
                                }

                              }}
                            />
                          )}
                        </SplideSlide>
                      );
                  })}


                  {userInfo && checkIsProProOrProOneOrProPlusUser(userInfo) &&
                    !manuallyClicked && (
                      <RenderFeedBackForm
                        shareFormInfoResponse={shareFormInfoResponse}
                        setOpen={setOpen}
                        setPositiveAnswer={setSelectedAnswer}
                      />
                    )}
                </Splide>
                {activePost?.contents && activePost?.contents?.length > 0 && <Box sx={{ display: 'flex', flexDirection: 'row-reverse', position: 'absolute', top: 20, right: 8 }}>
                  <Button
                    variant='contained'
                    sx={{
                      backgroundColor: AppColors.primaryColor,
                      textTransform: 'none',
                      fontSize: "1rem",
                      zIndex: 1000,
                      lineHeight: 'normal',
                      fontWeight: "400",
                      mt: 0.3
                    }}
                  >

                    {`${activeSlide + 1}`} of {totalSlide}
                  </Button>
                </Box>}
              </Grid>}
              <Grid item xs={12} md={hasMedia ? 6 : 12}>

                <Stack direction='column' gap={1}>
                  {sbUserInfo && <Box
                    display='flex'
                    alignItems='center'
                    minHeight='60px'
                    sx={{
                      backgroundImage: 'url("/static/images/header_bg.svg")',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      m: -1,
                      pl: 1,
                      pr: 1,
                      pt: 0.5,
                      pb: 0.5
                    }}
                  >
                    <Box sx={{ borderRadius: "100%" }}>
                      <Avatar alt='pic' src={sbUserInfo.profile_picture ?? '/static/images/placeholder_profile.png'} />
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='flex-start'
                      justifyContent='center'
                      flex={1}
                    >
                      <Typography
                        component='div'
                        color='white'
                        sx={{ ml: 1, mr: 1, lineHeight: 1.2, fontSize: { xs: '1rem', md: "1.2rem" } }}
                      >
                        {sbUserInfo?.first_name ?? ""}'s KnoCard
                      </Typography>
                    </Box>
                    <Box
                      flexDirection='row'
                    >
                      <Button
                        onClick={openShareByKnoCard}
                        variant="text"
                        sx={{
                          color: AppColors.whiteColor,
                          textTransform: 'none'
                        }}
                      >
                        View
                      </Button>
                    </Box>
                  </Box>}
                  <Box display='flex' alignItems='center' height='60px' sx={{ mt: { xs: 1, sm: '0' }, mb: { xs: 1, sm: '0' } }}>
                    <Box width='50px'>
                      <Avatar alt='pic' src={profile_picture ?? '/static/images/placeholder_profile.png'} />
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='flex-start'
                      justifyContent='center'
                      flex={1}
                    >
                      <Typography
                        variant='h6'
                        component='div'
                        color='black'
                        className='post-main-title'
                        sx={{ color: AppColors.blackColor }}
                      >
                        {name}
                      </Typography>
                      <Box flexDirection={'row'} display={'flex'} sx={{ width: "100%" }}>
                        <Typography
                          component='div'
                          color='primary'
                          fontSize='10px'
                          className='post-main-sub-title'
                          sx={{ color: AppColors.grayTwoColor, mt: 0.4, flexGrow: 1, mr: 0.4 }}
                        >
                          {occupation}
                        </Typography>
                        <Typography
                          component='div'
                          color='primary'
                          fontSize='10px'
                          className='post-main-sub-title'
                          sx={{ color: AppColors.grayTwoColor, mt: 0.4 }}
                        >
                          {moment(activePost?.created_at).format('DD MMMM YYYY')}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ display: { md: 'flex', xs: 'none' }, width: "100%" }} flexDirection={'row'} alignItems='center'
                    justifyContent='space-between'>
                    {getShareAndViewCount()}
                  </Box>
                </Stack>

                {hasMedia && <Grid item xs={12} md={6}
                  alignSelf={'center'}
                  sx={{ display: { xs: 'block', md: 'none', position: 'relative' } }}
                >
                  <Splide
                    key={`media-splide-${activePost?.id}`}
                    aria-label='Media Images'
                    options={{
                      perPage: 1,
                      fixedHeight: 425,
                      autoHeight: true
                    }}
                    onMove={(event) => {
                      setActiveSlide(event.index);
                    }}
                    onReady={(event) => {
                      setTotalSlide(event.length)
                    }}
                    style={{ borderRadius: "12px", overflow: 'hidden' }}
                  >
                    {activePost?.contents?.map((content, sIdx) => {
                      return content?.type === 'image' ? (
                        <SplideSlide key={content.id}>
                          <img
                            src={content?.link}
                            alt={content?.filename}
                            height='100%'
                            width='100%'
                            className='object-fit-contain'
                          />
                        </SplideSlide>
                      ) : (content?.type === "pdf") ?

                        (<SplideSlide key={content.id}>
                          {activeSlide == sIdx && <iframe key={`iframe-${content.id}`} src={`https://drive.google.com/viewerng/viewer?pid=explorer&efh=false&a=v&chrome=false&embedded=true&url=${content?.link}`}
                            itemType="application/pdf"
                            width="100%" height="100%" style={{ overflowY: "scroll" }}>
                          </iframe>}
                        </SplideSlide>) : (
                          <SplideSlide key={content.id}>
                            {content?.platform === 'youtube' ? (
                              <YouTube
                                videoId={content?.link}
                                opts={opts}
                                className='object-fit-contain'
                                style={{
                                  height: '100%',
                                  width: '100%',
                                }}
                                onPlay={(props: any) => {
                                  if (props.target.getCurrentTime() < 1) {
                                    handleOnplayVideo(content.id, 0, props.target.getDuration() || 0)
                                  }
                                }}
                                onEnd={(props: any) => handleOnPauseOrCompleteVideo(content.id, props.target.getCurrentTime(), props.target.getDuration() || 0)}
                                onPause={(props: any) => handleOnPauseOrCompleteVideo(content.id, props.target.getCurrentTime(), props.target.getDuration() || 0)}
                              />
                            ) : content?.platform === "vimeo" ? (
                              <Vimeo
                                video={content?.link}
                                className="object-fit-contain vimeo-video-contain"
                                height={"100%"}
                                width={"100%"}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  maxHeight: '50vh'
                                }}
                                onPlay={(props) => handleOnplayVideo(content.id, 0, props.duration)}
                                onEnd={(props) => handleOnPauseOrCompleteVideo(content.id, props.seconds, props.duration)}
                                onPause={(props) => handleOnPauseOrCompleteVideo(content.id, props.seconds, props.duration)}
                              // onPlay={() => handleOnplayVideo(content.id)}
                              />
                            ) : (
                              <MediaVideoComponent
                                isDialogOpen={toggle}
                                content={content}
                                style={{}}
                                width='100%'
                                height='100%'
                                className='object-fit-contain'
                                handleOnplayVideo={handleOnplayVideo}
                                handleOnPauseOrCompleteVideo={handleOnPauseOrCompleteVideo}
                                onVisibilityChange={(visibility: boolean, videoId: number, time: number, duration: number) => {
                                  if (!visibility) {
                                    handleOnPauseOrCompleteVideo(videoId, time, duration)
                                  }
                                }}
                              />
                            )}
                          </SplideSlide>
                        );
                    })}
                    {userInfo && checkIsProProOrProOneOrProPlusUser(userInfo) &&
                      !manuallyClicked && (
                        <RenderFeedBackForm
                          shareFormInfoResponse={shareFormInfoResponse}
                          setOpen={setOpen}
                          setPositiveAnswer={setSelectedAnswer}
                        />
                      )}
                  </Splide>
                  {activePost?.contents && activePost?.contents?.length > 0 && <Box sx={{ display: 'flex', flexDirection: 'row-reverse', position: 'absolute', top: 8, right: 10 }}>
                    <Button
                      variant='contained'
                      sx={{
                        backgroundColor: AppColors.primaryColor,
                        textTransform: 'none',
                        fontSize: "1rem",
                        zIndex: 1000,
                        lineHeight: 'normal',
                        fontWeight: "400",
                        mt: 0.3
                      }}
                    >

                      {`${activeSlide + 1}`} of {totalSlide}
                    </Button>
                  </Box>}
                </Grid>}

                <Box sx={{ display: { xs: 'flex', md: 'none' }, mt: 1.5, mb: 1.5, width: '100%' }} flexDirection={'row'} alignItems='center'
                  justifyContent='space-between'>
                  {getShareAndViewCount()}
                </Box>

                <Box
                  display='flex'
                  alignItems='flex-start'
                  justifyContent='flex-start'
                  sx={{ mt: 1 }}
                >
                  <Typography variant='body1' component='div' color='inherit' sx={{ color: AppColors.grayThreeColor, whiteSpace: 'pre-line' }}>
                    <ReactReadMoreReadLess
                      charLimit={350}
                      readMoreText={'Read more'}
                      readLessText={'Read less'}
                      readMoreClassName='read-more-less-more'
                      readLessClassName='read-more-less-less'
                    >{activePost?.description ?? ""}</ReactReadMoreReadLess>
                  </Typography>
                </Box>
              </Grid>
            </Grid>}</>
          )}
        </DialogContent>
      </Dialog >
      <Snackbar
        open={open.toggle}
        autoHideDuration={3000}
        onClose={handleClose}
        message={open.message}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </>
  );
};

export default ViewMediaPostModal;
