import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { IContactFormState } from './interface';

export const postContactForm = createAsyncThunk(
  'contactForm/postContactForm',
  async (reqBody: IContactFormState, { rejectWithValue }) => {
    try {
      const finalReqBody = Object.assign(
        {
          user_id: reqBody.sender_id,
          is_saved_using_app: 1,
          ...(reqBody.source && { source: reqBody.source })
        },
        { contact: [reqBody] }
      );
      const response = await axiosInstance.post(
        `api/prospect/save`,
        finalReqBody
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
