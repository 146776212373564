import { LoadingButton } from '@mui/lab';
import { TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/system';
import { Transition } from './Transition';
import { useFormik } from 'formik';
import shareReferSchema from '../validation/shareReferSchema';
import { IShareReferState } from '../store/shareRefer/api/interface';
import { postShareRefer } from '../store/shareRefer/api';
import { useAppDispatch, useAppSelector } from '../store';
import { useEffect } from 'react';

interface IProps {
    toggle: boolean;
    onClose: () => void;
}

const ShareRefer: React.FC<IProps> = ({ toggle, onClose }) => {
    const dispatch = useAppDispatch();
    const senderId = useAppSelector((state) => state.userInfo.data?.id) ?? '';

    const { handleSubmit, values, resetForm, isSubmitting, errors, touched, handleChange, setFieldValue } =
        useFormik<IShareReferState>({
            initialValues: {
                sender_id: '',
                sender_first_name: '',
                sender_last_name: '',
                sender_email: '',
                sender_phone_number: '',
                recipient_first_name: '',
                recipient_last_name: '',
                recipient_email: '',
                recipient_phone_number: '',
            },
            validationSchema: shareReferSchema,
            onSubmit: async (values) => {
                try {
                    await dispatch(postShareRefer(values)).unwrap();
                    onClose();
                    resetForm();
                    setterForSenderId();
                } catch (error: any) {}
            },
        });

    function setterForSenderId() {
        setFieldValue('sender_id', senderId);
    }

    useEffect(() => {
        if (senderId) {
            setterForSenderId();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [senderId]);
    return (
        <Dialog
            fullWidth
            open={toggle}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
                resetForm();
                setterForSenderId();
                onClose();
            }}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle
                sx={{
                    backgroundColor: '#088ac6',
                    color: 'white',
                }}
            >
                Share this KnoCard
            </DialogTitle>
            <form onSubmit={handleSubmit} autoComplete="off">
                <DialogContent>
                    <Stack direction="column" spacing={2}>
                        <Typography variant="h6" color="primary">
                            Share With
                        </Typography>
                        <TextField
                            id="recipient_first_name"
                            name="recipient_first_name"
                            label="First Name*"
                            fullWidth
                            onChange={handleChange}
                            value={values.recipient_first_name}
                            error={Boolean(errors.recipient_first_name && touched.recipient_first_name)}
                            helperText={
                                Boolean(errors.recipient_first_name && touched.recipient_first_name) &&
                                errors.recipient_first_name
                            }
                        />
                        <TextField
                            id="recipient_last_name"
                            name="recipient_last_name"
                            label="Last Name*"
                            fullWidth
                            onChange={handleChange}
                            value={values.recipient_last_name}
                            error={Boolean(errors.recipient_last_name && touched.recipient_last_name)}
                            helperText={
                                Boolean(errors.recipient_last_name && touched.recipient_last_name) &&
                                errors.recipient_last_name
                            }
                        />
                        <TextField
                            name="recipient_phone_number"
                            id="recipient_phone_number"
                            label="Mobile Number*"
                            fullWidth
                            onChange={handleChange}
                            value={values.recipient_phone_number}
                            error={Boolean(errors.recipient_phone_number && touched.recipient_phone_number)}
                            helperText={
                                Boolean(errors.recipient_phone_number && touched.recipient_phone_number) &&
                                errors.recipient_phone_number
                            }
                        />
                        <TextField
                            type="email"
                            name="recipient_email"
                            id="recipient_email"
                            label="Email*"
                            fullWidth
                            value={values.recipient_email}
                            onChange={handleChange}
                            error={Boolean(errors.recipient_email && touched.recipient_email)}
                            helperText={
                                Boolean(errors.recipient_email && touched.recipient_email) && errors.recipient_email
                            }
                        />
                        <Typography variant="h6" color="primary">
                            Please Add Your Information
                        </Typography>
                        <TextField
                            id="sender_first_name"
                            name="sender_first_name"
                            label="First Name*"
                            fullWidth
                            value={values.sender_first_name}
                            onChange={handleChange}
                            error={Boolean(errors.sender_first_name && touched.sender_first_name)}
                            helperText={
                                Boolean(errors.sender_first_name && touched.sender_first_name) &&
                                errors.sender_first_name
                            }
                        />
                        <TextField
                            id="sender_last_name"
                            name="sender_last_name"
                            label="Last Name*"
                            fullWidth
                            value={values.sender_last_name}
                            onChange={handleChange}
                            error={Boolean(errors.sender_last_name && touched.sender_last_name)}
                            helperText={
                                Boolean(errors.sender_last_name && touched.sender_last_name) && errors.sender_last_name
                            }
                        />
                        <TextField
                            name="sender_phone_number"
                            id="sender_phone_number"
                            label="Mobile Number*"
                            fullWidth
                            value={values.sender_phone_number}
                            onChange={handleChange}
                            error={Boolean(errors.sender_phone_number && touched.sender_phone_number)}
                            helperText={
                                Boolean(errors.sender_phone_number && touched.sender_phone_number) &&
                                errors.sender_phone_number
                            }
                        />
                        <TextField
                            type="email"
                            name="sender_email"
                            id="sender_email"
                            label="Email*"
                            fullWidth
                            value={values.sender_email}
                            onChange={handleChange}
                            error={Boolean(errors.sender_email && touched.sender_email)}
                            helperText={Boolean(errors.sender_email && touched.sender_email) && errors.sender_email}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ paddingX: 3 }}>
                    <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                        Submit
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ShareRefer;
