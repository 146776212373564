import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, useMediaQuery } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  PreferredPartnerContents,
  Partner,
  PreferredPartner,
} from '../../store/preferredPartner/api/interface';

import CallCallingIcon from '../../assets/call_calling.svg';

import { Grid, IconButton, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useEffect, useState } from 'react';

import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';

import ReactReadMoreReadLess from '../ReactReadMoreReadLess';

import RenderProfilePhoto from '../RenderProfilePhoto';
import { checkPhoneNumber } from '../../utils/checkPhoneNumber';
import { isNotEmpty } from '../../utils/valid';

import { useCollapse } from 'react-collapsed';
import useWindowSize from '../../utils/useWindowSize';
import { AppColors, config } from '../../utils/utils';

const opts = {
  height: '100%',
  width: '100%',
};

const PreferredPartnerMedia: FC<{
  preferredPartner: PreferredPartner;
  onMediaSelect: (contents: PreferredPartnerContents[], index: number) => void;
  showEditMenu: boolean;
}> = ({ preferredPartner: companyFeed, onMediaSelect, showEditMenu }) => {
  if (companyFeed?.contents?.length > 0) {
    return (
      <Grid container direction='row' spacing={1} mt={1}>
        {companyFeed?.contents?.map((content, index) => {
          return (
            <Grid item key={content.id} xs={3} md={3}>
              <Box
                component={'img'}
                onClick={() => onMediaSelect(companyFeed?.contents, index)}
                src={content?.link}
                alt={content?.filename ?? ''}
                height='100%'
                width='100%'
                className='preferred-partner-image-border'
                sx={{ border: `2px solid ${AppColors.primaryColor}` }}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return <></>;
};

interface IProps {
  partner: PreferredPartner;
  index: number;
  showEditMenu: boolean;
  handleDelete?: (preferredPartner: PreferredPartner) => void;
  handleEdit?: (preferredPartner: PreferredPartner) => void;
  onMediaSelect: (contents: PreferredPartnerContents[], index: number) => void;
  isHomePageItem?: boolean;
  titleColor?: string;
  cardColor?: string;
  cardWidth?: string;
}

const PreferredPartnerItem: React.FC<IProps> = ({
  partner,
  index,
  onMediaSelect,
  showEditMenu,
  handleDelete,
  handleEdit,
  isHomePageItem,
  titleColor,
  cardColor,
  cardWidth
}) => {
  const matches = useMediaQuery('(max-width:745px)');

  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const windowSize = useWindowSize();
  const [cardViewWidth, setCardViewWidth] = useState<any>(300);



  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditMenu = () => {
    if (handleEdit != undefined) {
      handleEdit(partner);
    }

    setAnchorEl(null);
  }

  const handleDeleteMenu = () => {
    if (handleDelete != undefined) {
      handleDelete(partner);
    }
    setAnchorEl(null);
  }



  useEffect(() => {
    const newWidth = matches ? windowSize.width > 350 ? windowSize.width * 0.90 : windowSize.width * 0.90 : windowSize.width > 890 ? 745 : windowSize.width * 0.80;
    setCardViewWidth(isHomePageItem ? (cardWidth ? cardWidth : 'auto') : newWidth);
  }, [windowSize]);

  const getUserAddress = (partner: Partner): String => {
    const _isNotEmpty = isNotEmpty(
      partner.street1 ?? '' + partner.street2 ?? ''
    );

    const strt =
      isNotEmpty(partner.street1 ?? '') && isNotEmpty(partner.street2 ?? '')
        ? partner.street1 + ', ' + partner.street2
        : partner.street1 ?? '' + partner.street2 ?? '';
    const address: string[] = [
      _isNotEmpty ? strt : '',
      partner.city,
      partner.state,
      (partner.zip_code ?? '').toString(),
    ];

    return address
      .filter(
        (element: any) =>
          isNotEmpty(element) && element != '0' && element != 'null'
      )
      .join(', ');
  };

  return (
    partner.partner && (
      <Box>
        <Typography
          gutterBottom
          variant='body1'
          fontWeight='400'
          color={titleColor || 'white'}
          sx={{
            mt: 2,
            mb: 0,
            fontSize: "0.9rem",
            background: AppColors.primaryColor, width: "fit-content", pl: 1, pt: 0.2, pr: 1, borderRadius: "10px 10px 0 0"
          }}
        >
          {partner.title}
        </Typography>
        <Card
          key={index}

          sx={{
            ...(isHomePageItem ? { boxShadow: 'unset !important' } : {}),
            width: { xs: cardViewWidth, md: "100%" },
            minWidth: "100%",
            ...(cardColor ? { backgroundColor: cardColor } : {})
          }}
          className='we-are-live-post-card'
        >
          <CardContent
            sx={{
              paddingTop: '0px',
              paddingLeft: '0px',
              paddingRight: '0px',
              paddingBottom: '0px !important',
              '&:last-child': { pb: '16px' },
            }}
          >
            <Grid
              container
              display={'flex'}
              direction='row'
            >
              <ListItem
                sx={{ pl: 0 }}
                secondaryAction={
                  (<Box sx={{ flexDirection: 'column-reverse', display: 'flex', }}><IconButton
                    href={`tel:${checkPhoneNumber(
                      partner.partner?.phone_number,
                      partner.partner?.phone_country_code,
                      partner.partner?.show_phone_number,
                      null
                    )}`}
                  >
                    <Box
                      component={'img'}
                      src={CallCallingIcon}
                    />
                  </IconButton>
                    {showEditMenu && (
                      <>
                        <IconButton
                          aria-label='more'
                          id='long-button'
                          sx={{ color: AppColors.blackColor }}
                          aria-controls={menuOpen ? 'long-menu' : undefined}
                          aria-expanded={menuOpen ? 'true' : undefined}
                          aria-haspopup='true'
                          onClick={handleMenuClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id='long-menu'
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={menuOpen}
                          onClose={handleMenuClose}
                          PaperProps={{
                            style: {
                              maxHeight: 48 * 4.5,
                              width: '20ch',
                            },
                          }}
                        >
                          <MenuItem
                            key={`edit-preferred-partner-${index}`}
                            onClick={() => handleEditMenu()}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            key={`delete-preferred-partner-${index}`}
                            onClick={() => handleDeleteMenu()}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </>
                    )}</Box>)
                }
              >
                <ListItemAvatar>
                  <Box
                    component={'a'}
                    href={`${config.VBC_URL}/${partner.partner?.username}`}
                    target={'_blank'}
                  >
                    <RenderProfilePhoto
                      profile_picture={partner.partner?.profile_picture}
                      show_profile_picture={partner.partner?.show_profile_picture}
                      sx={{
                        backgroundColor: 'white',
                        width: 60,
                        height: 60,
                        zIndex: 50,
                      }}
                    />
                  </Box>
                </ListItemAvatar>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  height='100%'
                  sx={{ ml: 1, mr: { xs: 1, md: showEditMenu ? 10 : 1 } }}
                >
                  <Typography variant='body1' fontWeight='500' sx={{ color: AppColors.blackColor }}>
                    {partner.partner?.name}
                  </Typography>
                  <Typography
                    variant='caption'
                    sx={{ color: AppColors.grayThreeColor, fontSize: "0.8rem" }}
                  >
                    {partner.partner && getUserAddress(partner.partner)}
                  </Typography>
                  <Stack
                    direction='row'
                    color='grey'
                    alignItems='center'
                    gap='4px'
                    {...getToggleProps({
                      onClick: () => {
                        setExpanded(!isExpanded);
                      },
                    })}
                  >
                    <Typography variant='body2' component='div' sx={{ fontSize: "0.8rem", color: AppColors.grayThreeColor }}>
                      {isExpanded ? 'Less' : 'Learn More'}
                    </Typography>
                    {isExpanded ? (
                      <ArrowDropUp
                        style={{
                          // height: '1.5rem',
                          // width: '1.5rem',
                          color: AppColors.primaryColor,
                          border: `1px solid ${AppColors.primaryColor}`,
                          borderRadius: '30px',
                        }}
                      />
                    ) : (
                      <ArrowDropDown
                        style={{
                          // height: '1.5rem',
                          // width: '1.5rem',
                          color: AppColors.primaryColor,
                          border: `1px solid ${AppColors.primaryColor}`,
                          borderRadius: '30px',
                        }}
                      />
                    )}
                  </Stack>
                </Box>
              </ListItem>
              {/* <Grid item>
                <Box
                  component={'a'}
                  href={`${config.VBC_URL}/${partner.partner?.username}`}
                  target={'_blank'}
                >
                  <RenderProfilePhoto
                    profile_picture={partner.partner?.profile_picture}
                    show_profile_picture={partner.partner?.show_profile_picture}
                    sx={{
                      backgroundColor: 'white',
                      width: 60,
                      height: 60,
                      zIndex: 50,
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={showEditMenu ? false : 6} sx={{ ml: 1, mr: 1 }}>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  height='100%'
                >
                  <Typography variant='body1' fontWeight='500' sx={{ color: AppColors.blackColor }}>
                    {partner.partner?.name}
                  </Typography>
                  <Typography
                    variant='caption'
                    sx={{ color: AppColors.grayThreeColor, fontSize: "0.8rem" }}
                  >
                    {partner.partner && getUserAddress(partner.partner)}
                  </Typography>
                  <Stack
                    direction='row'
                    color='grey'
                    alignItems='center'
                    gap='4px'
                    {...getToggleProps({
                      onClick: () => {
                        setExpanded(!isExpanded);
                      },
                    })}
                  >
                    <Typography variant='body2' component='div' sx={{ fontSize: "0.8rem", color: AppColors.grayThreeColor }}>
                      {isExpanded ? 'Less' : 'Learn More'}
                    </Typography>
                    {isExpanded ? (
                      <ArrowDropUp
                        style={{
                          // height: '1.5rem',
                          // width: '1.5rem',
                          color: AppColors.primaryColor,
                          border: `1px solid ${AppColors.primaryColor}`,
                          borderRadius: '30px',
                        }}
                      />
                    ) : (
                      <ArrowDropDown
                        style={{
                          // height: '1.5rem',
                          // width: '1.5rem',
                          color: AppColors.primaryColor,
                          border: `1px solid ${AppColors.primaryColor}`,
                          borderRadius: '30px',
                        }}
                      />
                    )}
                  </Stack>
                </Box>
              </Grid>
              <Grid
                item
                xs={isHomePageItem ? 2 : undefined}
                md={isHomePageItem ? undefined : 4}
                display={'flex'}
                justifyContent={'end'}

              >
                <IconButton
                  href={`tel:${checkPhoneNumber(
                    partner.partner?.phone_number,
                    partner.partner?.phone_country_code,
                    partner.partner?.show_phone_number,
                    null
                  )}`}
                >
                  <Box
                    component={'img'}
                    src={CallCallingIcon}
                  />
                </IconButton>
                {showEditMenu && (
                  <Box width='50px'>
                    <IconButton
                      aria-label='more'
                      id='long-button'
                      sx={{ color: AppColors.blackColor }}
                      aria-controls={menuOpen ? 'long-menu' : undefined}
                      aria-expanded={menuOpen ? 'true' : undefined}
                      aria-haspopup='true'
                      onClick={handleMenuClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id='long-menu'
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorEl}
                      open={menuOpen}
                      onClose={handleMenuClose}
                      PaperProps={{
                        style: {
                          maxHeight: 48 * 4.5,
                          width: '20ch',
                        },
                      }}
                    >
                      <MenuItem
                        key={`edit-preferred-partner-${index}`}
                        onClick={() => handleEditMenu()}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        key={`delete-preferred-partner-${index}`}
                        onClick={() => handleDeleteMenu()}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </Box>
                )}
              </Grid> */}
            </Grid>

            <Box {...getCollapseProps()}>
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid item sx={{ mt: 2 }}>
                  <Typography
                    gutterBottom
                    variant='body1'
                    component='div'
                    color='primary'
                    sx={{ m: 0 }}

                  >
                    Services
                  </Typography>
                  <Typography
                    variant='body1'
                    color='text.secondary'
                    className='link'
                    sx={{ whiteSpace: 'pre-line', color: AppColors.grayThreeColor, fontSize: "0.9rem" }}
                  >
                    <ReactReadMoreReadLess
                      charLimit={200}
                      readMoreText={'Read more'}
                      readLessText={'Read less'}
                      readMoreClassName='read-more-less-more'
                      readLessClassName='read-more-less-less'
                    >
                      {partner.services}
                    </ReactReadMoreReadLess>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{ mt: 1 }}
              >
                <Grid item>
                  <Typography
                    gutterBottom
                    variant='body1'
                    component='div'
                    color='primary'
                    sx={{ m: 0 }}
                  >
                    Why I recommend
                  </Typography>
                  <Typography
                    variant='body1'
                    color='text.secondary'
                    className='link'
                    sx={{ whiteSpace: 'pre-line', color: AppColors.grayThreeColor, fontSize: "0.9rem" }}
                  >
                    <ReactReadMoreReadLess
                      charLimit={200}
                      readMoreText={'Read more'}
                      readLessText={'Read less'}
                      readMoreClassName='read-more-less-more'
                      readLessClassName='read-more-less-less'
                    >
                      {partner.description}
                    </ReactReadMoreReadLess>
                  </Typography>
                </Grid>
              </Grid>

              <PreferredPartnerMedia
                preferredPartner={partner}
                onMediaSelect={onMediaSelect}
                showEditMenu={showEditMenu}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    )
  );
};

export default PreferredPartnerItem;
