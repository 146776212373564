import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { EngageActivityResponse, EngagePipelineResponse, EngageUserActivityResponse, GetEngageUserActivityReportRequest, EngageUserActivityReportResponse, EngageCountResponse } from './interface';
import { EngageActivityFilter } from '../../../enum';


export const getEngageActivityCount = createAsyncThunk(
  'Engage/getEngageActivityCount',
  async (
    data: { filter: EngageActivityFilter },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<EngageActivityResponse>(
        `/api/engage/count?filter=${data.filter}`

      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



export const getEngageActivity = createAsyncThunk(
  'Engage/getEngageActivity',
  async (
    { type, filter }: { type: string, filter: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<EngagePipelineResponse>(
        `/api/engage/users?type=${type}&filter=${filter}`
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const getEngageUserActivity = createAsyncThunk(
  'Engage/getEngageUserActivity',
  async (
    { sortMarker }: { sortMarker: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<EngageUserActivityResponse>(
        `api/engage/user-activity-report/${sortMarker}`
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



export const getEngageUserActivityReport = createAsyncThunk(
  'reports/getEngageUserActivityReport',
  async (data: GetEngageUserActivityReportRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<EngageUserActivityReportResponse>(
        `/api/reports/knocard-view/user-details-count`,
        data
      );

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



export const getEngageCount = createAsyncThunk(
  'Engage/getEngageCount',
  async (
    data: { filter: string, isFromKnoCardLink?: boolean, userId?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<EngageCountResponse>(
        `api/engage/count?filter=${data.filter}${data.isFromKnoCardLink && `&isFromKnoCardLink=${data.isFromKnoCardLink}`}${data.userId && `&userId=${data.userId}`}`
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);