// component
import Iconify from '../../components/Iconify';
import { config } from '../../utils/utils';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <Iconify icon={name} width={22} height={22} />
);


export const knoCardSocialConnectSidebarConfig = [
  {
    title: 'Profile',
    path: '/dashboard/user-profile',
    icon: '/static/images/profile.svg',
    activeIcon: '/static/images/profile_active.svg',
  },
  {
    title: 'External Links',
    path: '/dashboard/external-links',
    icon: '/static/images/externalLinks.svg',
    activeIcon: '/static/images/externalLinks_active.svg',
  },
  {
    title: 'Preferred Partners',
    path: '/dashboard/preferred-partners',
    icon: '/static/images/preferredPartners.svg',
    activeIcon: '/static/images/preferredPartners_active.svg',
  },
  {
    title: 'KnoCard Social',
    path: '/dashboard/business-social',
    icon: '/static/images/business_solution.svg',
    activeIcon: '/static/images/business_solution_active.svg',
  },
  {
    title: 'Help & Support',
    path: '/dashboard/help-support-video',
    icon: '/static/images/help.svg',
    activeIcon: '/static/images/help_active.svg',
  },
];


const sidebarConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: '/static/images/dashboard.svg',
    activeIcon: '/static/images/dashboard_active.svg',
  },
  {
    title: 'Pipeline',
    path: '/dashboard/engage/pipeline',
    icon: '/static/images/pipeline.svg',
    activeIcon: '/static/images/pipeline_active.svg',
  },
  {
    title: 'Reports',
    path: '/dashboard/reports',
    icon: '/static/images/reports.svg',
    activeIcon: '/static/images/reports_active.svg',
  },
  {
    title: 'Profile',
    path: '/dashboard/user-profile',
    icon: '/static/images/profile.svg',
    activeIcon: '/static/images/profile_active.svg',
  },
  {
    title: 'Media',
    path: '/dashboard/media',
    icon: '/static/images/media.svg',
    activeIcon: '/static/images/media_active.svg',
  },
  {
    title: 'KnoCard Link',
    path: '/dashboard/knocard-link',
    icon: '/static/images/knocard_link.svg',
    activeIcon: '/static/images/knocard_link_active.svg',
  },
  {
    title: 'KnoCard Social',
    path: '/dashboard/business-social',
    icon: '/static/images/business_solution.svg',
    activeIcon: '/static/images/business_solution_active.svg',
  },
  {
    title: 'External Links',
    path: '/dashboard/external-links',
    icon: '/static/images/externalLinks.svg',
    activeIcon: '/static/images/externalLinks_active.svg',
  },
  {
    title: 'Preferred Partners',
    path: '/dashboard/preferred-partners',
    icon: '/static/images/preferredPartners.svg',
    activeIcon: '/static/images/preferredPartners_active.svg',
  },
  /*{
    title: 'New Prospects',
    path: '/dashboard/new-prospects',
    icon: '/static/images/prospect.svg',
    activeIcon: '/static/images/prospect_active.svg'
  },*/
  {
    title: 'Messaging',
    path: '/dashboard/messaging',
    icon: '/static/images/chat.svg',
    activeIcon: '/static/images/chat_active.svg',
  },
  // {
  //   title: 'Custom landing page',
  //   path: '/dashboard/custom-landing-page',
  //   icon: '/static/images/custom_landing_page.svg',
  //   activeIcon: '/static/images/custom_landing_page_active.svg',
  // },
  {
    title: 'Reviews',
    path: '/dashboard/reviews',
    icon: '/static/images/reviews.svg',
    activeIcon: '/static/images/reviews_active.svg',
  },
  // {
  //   title: 'How To',
  //   path: '/dashboard/how-to',
  //   icon: '/static/images/play.svg',
  //   activeIcon: '/static/images/play_active.svg',
  // },
  // {
  //   title: 'Training',
  //   path: '/dashboard/training',
  //   icon: '/static/images/play.svg',
  //   activeIcon: '/static/images/play_active.svg',
  // },
  {
    title: 'Help & Support',
    path: '/dashboard/help-support-video',
    icon: '/static/images/help.svg',
    activeIcon: '/static/images/help_active.svg',
  },
];

export default sidebarConfig;
