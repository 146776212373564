import PropTypes from "prop-types";
import { useEffect, forwardRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { m } from "framer-motion";
import { NAVBAR } from "../../config";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Drawer, Stack, IconButton } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import DrawerMenuListView from "../../components/DrawerMenuListView";
import { useAppDispatch, useAppSelector } from "../../store";
// import { logoutUser } from "../../store/auth/api";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";


import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import UserDashboardSidebar from "./UserDashboardSidebar";
import sidebarConfig from "./SidebarConfig";
import { checkIsProUser } from "../../utils/checkPro";

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

const IconButtonAnimate = forwardRef(
  ({ children, size = "medium", ...other }: any, ref) => (
    <AnimateWrap size={size}>
      <IconButton size={size} ref={ref} {...other}>
        {children}
      </IconButton>
    </AnimateWrap>
  )
);

IconButtonAnimate.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    "inherit",
    "default",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
};
const varSmall = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
};

const varMedium = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 },
};

const varLarge = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 },
};

AnimateWrap.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

function AnimateWrap({ size, children }: { size: any; children: any }) {
  const isSmall = size === "small";
  const isLarge = size === "large";

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={(isSmall && varSmall) || (isLarge && varLarge) || varMedium}
      sx={{
        display: "inline-flex",
      }}
    >
      {children}
    </Box>
  );
}

function CollapseButton({
  onToggleCollapse,
  collapseClick,
}: {
  onToggleCollapse?: any;
  collapseClick?: any;
}) {
  return (
    <IconButtonAnimate onClick={onToggleCollapse}>
      <Box
        sx={{
          lineHeight: 0,
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shorter,
            }),
          ...(collapseClick && {
            transform: "rotate(180deg)",
          }),
        }}
      >
        <KeyboardDoubleArrowLeftIcon />
      </Box>
    </IconButtonAnimate>
  );
}

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
}: {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
}) {
  // const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const theme = useTheme();
  // const userInfo = useAppSelector((state) => state.auth.user.userRole);
  const isDesktop = useResponsive("up", "lg", "as", "as");
  // const userRole = useAppSelector(
  //   (state) =>
  //     state.active.selectedItem.organizationAccess.user_access_profile.role
  // );
  // const organizationId = getItem(config.ORGANIZATION_ID);

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  }: any = useCollapseDrawer();
  const [sidebarItemState, setSidebarItemState] = useState<any>();


  const userInfo = useAppSelector((state) => state?.auth?.user);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname, isDesktop]);


  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: "center" }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Logo />

          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>
      </Stack>

      <DrawerMenuListView menuConfig={sidebarConfig} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{ pt: 5, borderRadius: 2, position: "relative" }}
        ></Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: (location.pathname.startsWith('/dashboard/vbc')) ? 0 : (isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_NORMAL_WIDTH)
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && !(location.pathname.startsWith('/dashboard/vbc')) && (
        <Box
          display={{
            xs: "none",
            sm: "none",
            md: "none",
            lg: "flex",
            xl: "flex",
          }}
        >
          <UserDashboardSidebar />
        </Box>
      )}
    </RootStyle>
  );
}
