import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';



import { checkIsProProOrProOneOrProPlusUser, checkIsProUser } from '../utils/checkPro';
import GrapesJSEditor from '../components/grapesjs/GrapesJSEditor';
import BlockOverlay from '../components/BlockOverlay';
import { useEffect, useState } from 'react';
import { getCustomLandingPage, saveCustomLandingPage } from '../store/customLandingpage/api';
import { showErrorMessage, showSuccessMessage } from '../utils/utilMethods';

const CustomLandingPageEditor = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useAppSelector((state) => state?.auth);

  const customLandingPageData = useAppSelector((state) => state?.customLandingPage.data?.page_data ?? "");

  const navigate = useNavigate();

  const isProOneOrProPlusUser = checkIsProProOrProOneOrProPlusUser(user);

  useEffect(() => {

    try {
      if (!isProOneOrProPlusUser) {
        navigate(`/dashboard`);
        return;
      } else {
        dispatch(getCustomLandingPage({ user_id: user?.id ?? 0 }));
      }
    } catch (error) {
    }
  }, []);



  const handleOnPageSave = (pageData: string) => {
    dispatch(saveCustomLandingPage({ page_data: pageData })).unwrap().then((result) => {
      if (result.data.status.code == 'success') {
        showSuccessMessage(result.data.status.description);
      } else {
        if (result.data.status.description) {
          showErrorMessage(result.data.status.description);
        }
      }
    });
  };

  return (
    <>
      <Page
        title='Custom Landing Page | KnoCard'
        showAppBar={true}
        sx={{ minHeight: '100vh', pt: 3 }}
      >
        {isProOneOrProPlusUser && <GrapesJSEditor onPageSave={handleOnPageSave} pageData={customLandingPageData} />}
        <BlockOverlay isBlock={!isProOneOrProPlusUser} />
      </Page>
    </>
  );
};
export default CustomLandingPageEditor;
