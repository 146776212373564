import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { PricingVideoRespose } from './interface';

export const getPricingVideos = createAsyncThunk(
  'pricingVideoVideos/getPricingVideoVideos',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<PricingVideoRespose>(`/api/pricing-videos`);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
