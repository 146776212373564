import { STATUS_TYPE } from '../../../enum';
import { EngageActivity } from '../../engagePipeline/api/interface';


export interface RejectionFreePageVideoResponse {
  status: string;
  message: string;
  data: RejectionFreePageVideoItem[];
}

export interface RejectionFreePageVideoItem {
  id: number;
  title: string;
  position: number;
  type: string;
  link: string;
  created_at: string;
  updated_at: string;
  user_id: number;
  filename: string;
  thumb_name: string;
  thumb_link: string;
}


interface InitialState {
  overview?: RejectionFreePageVideoItem;
  mbxOverview?: RejectionFreePageVideoItem;
  fixingTheProblem?: RejectionFreePageVideoItem;
  data: RejectionFreePageVideoItem[];
  searchResult: RejectionFreePageVideoItem[];
  searchText: string,
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}


export const initialState: InitialState = {
  overview: undefined,
  mbxOverview: undefined,
  fixingTheProblem: undefined,
  data: [],
  searchResult: [],
  searchText: '',
  status: 'idle',
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
};
