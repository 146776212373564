import React, { useState } from 'react';

import { Avatar, Box, CircularProgress, Grid, ImageList, ImageListItem, Stack, Typography } from '@mui/material';
import { ChatUser, SelectedChatMessageMedia } from '../../../store/chat/api/interface';
import moment from 'moment';
import Linkify from 'react-linkify';
import { UserInfo } from '../../../store/userInfo/api/interface';
import { AppColors } from '../../../utils/utils';


interface IProps {
    chatUser?: UserInfo;
    chatMessageMedia: SelectedChatMessageMedia[];
    message: string;
}

const MessageSending: React.FC<IProps> = ({ chatUser, chatMessageMedia, message }) => {

    return (
        <>
            <Box component={'div'} sx={{ padding: '10px', width: "100%" }} justifyContent={'end'} alignContent={'flex-end'}>
                <Stack direction={'row-reverse'}>

                    <Stack direction={'column'} sx={{ maxWidth: '80%', alignItems: 'flex-end' }}>
                        <Typography
                            sx={{ textAlign: 'end', fontSize: "0.7rem", color: AppColors.grayThreeColor }}
                        >
                            {moment(new Date()).format("DD MMM, YYYY HH:mm")}
                        </Typography>
                        <Box
                            component={'div'}
                            display={'flex'}
                            alignItems={'start'}
                            justifyContent={'flex-start'}
                            sx={{
                                backgroundColor: AppColors.primaryColor,

                                borderRadius: '10px 0px 10px 10px',
                                width: "fit-content"
                            }}
                        >
                            <Typography variant='body2' sx={{ p: 2, wordBreak: 'break-word', whiteSpace: 'pre-wrap', color: AppColors.whiteColor }} className='chatMessage white-link'>
                                <Linkify >{message ?? ''}</Linkify>
                            </Typography>
                        </Box>
                        <Stack alignItems={'flex-end'}>
                            {chatMessageMedia.length > 0 && <ImageList sx={{ width: (chatMessageMedia.length > 1 ? 210 : 105), pt: 1 }} cols={chatMessageMedia.length > 1 ? 2 : 1}  >
                                {chatMessageMedia.map((mediaItem, index) => (

                                    index < 4 ? <ImageListItem key={`media-${index}`} >
                                        <Box
                                            component={'img'}
                                            src={(mediaItem?.thumbnail != undefined || mediaItem?.image != undefined) ? URL.createObjectURL(mediaItem?.thumbnail ?? (mediaItem.image ?? new Blob())) : ""}
                                            alt={''}
                                            loading="lazy"
                                            sx={{ height: 100, width: 100, objectFit: 'cover' }}
                                        />
                                        <Stack justifyContent="center" alignContent={'center'} alignItems={'center'} sx={{ height: '100%', width: '100%', position: 'absolute' }}>
                                            <Box sx={{ p: 0, borderRadius: '50%', textAlign: 'center', backgroundColor: '#0000009e', color: '#ffffff', height: '40px', width: '40px' }}><CircularProgress /></Box></Stack>
                                    </ImageListItem> : <></>

                                ))}

                            </ImageList>}</Stack>
                    </Stack>
                </Stack>
            </Box >

        </>
    );
};

export default MessageSending;
