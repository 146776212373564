import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './api/interface';
import {
  getReviewRequestDetail,
  getUserReviews,
  updateReviewStatus,
} from './api';

const reviewFormSlice = createSlice({
  name: 'reviewForm',
  initialState,
  reducers: {
    reset: () => initialState,
    newReviewCreated: (state, action) => {
      state.data.unshift(action.payload);
    },
    reviewStatusUpdated: (state, action) => {
      state.data.forEach((item, index) => {
        if (action.payload.id == item.id) {
          state.data[index] = action.payload;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserReviews.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getUserReviews.fulfilled, (state, action) => {
      state.data =
        action?.payload?.data?.data?.current_page != 1
          ? [
              ...(state?.data ?? []),
              ...(action?.payload?.data?.data?.data ?? []),
            ]
          : action?.payload?.data?.data?.data ?? [];
      state.hasMore = action?.payload?.data?.data?.data?.length >= 10;
      state.page = action?.payload?.data?.data?.current_page ?? 0;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getUserReviews.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });

    builder.addCase(getReviewRequestDetail.fulfilled, (state, action) => {
      if (action?.payload?.data) {
        state.reviewRequestDetail = action?.payload?.data;
      }
    });
  },
});

export const { reset, newReviewCreated, reviewStatusUpdated } =
  reviewFormSlice.actions;
export default reviewFormSlice.reducer;
