import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Link,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText,
} from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import { LoadingButton } from '@mui/lab';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

// component
import Iconify from '../Iconify';
import AffiliateGroupFilter from '../AffiliateGroupFilter';
import RegisterSchema from '../../validation/registerSchema';
import { AppDispatch, useAppSelector } from '../../store';
import { useDispatch } from 'react-redux';
import {
  checkEmailAddress,
  checkUsername,
  registerUser,
} from '../../store/register/api';
import { AffiliateGroup } from '../../store/AffiliateGroup/api/interface';
import { AffiliateGroupState } from '../../store/selectAffiliateGroupState/api/interface';
import { INotFoundParams } from '../../enum';
import { getUserInfo } from '../../store/userInfo/api';
import RegisterSuccessModel from '../modal/RegisterSuccessModel';
import useToggle from '../../hooks/useToggle';
import { registrationSuccess } from '../../store/auth/authSlice';
import { isNotEmpty } from '../../utils/valid';
import { AppColors, config } from '../../utils/utils';
import { UserInfo } from '../../store/userInfo/api/interface';
import { getItem, removeItem, setItem } from '../../utils/localStorage';
import { getRandomIntNumber, showErrorMessage } from '../../utils/utilMethods';


// import { login } from '../../store/auth/authSlice';

// ----------------------------------------------------------------------

export default function RegisterForm({ setSelectedAffiliateGroup, isSocialConnect }: { setSelectedAffiliateGroup: (affiliateGroup: AffiliateGroup | undefined) => void, isSocialConnect: boolean }) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [countryCode, setCountryCode] = useState('+1');
  const [affiliateGroup, setAffiliateGroup] = useState<AffiliateGroup | undefined>();

  const [isReferralConfirm, setReferralConfirm] = useState(false);

  const [isReferralReject, setReferralReject] = useState(false);

  const [confirmReferredWarning, setConfirmReferredWarning] = useState(false);


  const [affiliateGroupState, selectAffiliateGroupState] =
    useState<AffiliateGroupState | null>(null);

  const { toggle, onOpen, onClose } = useToggle();

  const captchaRef = useRef<HCaptcha>(null);

  const params = useParams<INotFoundParams>();

  const [refferredUser, setRefferredUser] = useState<UserInfo | undefined>(undefined);

  const { data: affiliateGroupList } = useAppSelector((state) => state.affiliateGroup);



  useEffect(() => {
    loadSelectedGroupInfo();
    const _username = params?.username;

    if (_username) {
      dispatch(getUserInfo({ username: _username }))
        .unwrap()
        .then((res) => {
          try {
            setRefferredUser(res.data.data.user[0]);
            // if (res.data.data.user[0]?.affiliate_group) {
            //   setAffiliateGroup(res.data.data.user[0].affiliate_group)
            //   setFieldValue('associatedWithGroupOrNetwork', 'yes');
            //   saveSelectedGroup(res.data.data.user[0].affiliate_group)
            //   }
          } catch (ex) {
          }
        });
    }
  }, []);

  useEffect(() => {
    loadSelectedGroupInfo();
  }, [affiliateGroupList]);


  const loadSelectedGroupInfo = () => {
    if (affiliateGroupList.length > 0) {
      let selectedId = getItem(config.GROUP_NETWORK_ID_KEY);
      if (selectedId != null) {
        affiliateGroupList.forEach((item) => {
          if (`${item.id}` == selectedId) {
            setAffiliateGroup(item);
            formik.setFieldValue("associatedWithGroupOrNetwork", "yes");
          }
        });
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      mobileNumber: '',
      password: '',
      confirmPassword: '',
      agree: false,
      captchaCode: '',
      associatedWithGroupOrNetwork: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {

      if (values.associatedWithGroupOrNetwork == "yes" && affiliateGroup == null) {
        showErrorMessage('Please select associated Group or Network.');
        setSubmitting(false);
        return;
      }

      if (!values.agree) {
        showErrorMessage('Please check agree before submitting.');
        setSubmitting(false);
        return;
      }

      if (!isNotEmpty(values.captchaCode)) {
        showErrorMessage('Please verify CAPTCHA.');
        setSubmitting(false);
        return;
      }

      let validateUserNameResponse = await validateUserName(values.username);
      if (validateUserNameResponse) {
        showErrorMessage('Username already taken.');
        setSubmitting(false);
        return;
      }

      let response = await dispatch(
        checkEmailAddress({ email: values.email })
      ).unwrap();
      if (response.data && response.data.status !== 'success') {
        showErrorMessage('Email already taken.');
        setSubmitting(false);
        return;
      }


      let backgroundImage = undefined;

      if (affiliateGroup?.background_link) {
        backgroundImage = `0|asset|${affiliateGroup?.background_link}`;
      } else {
        backgroundImage = `0|asset|${config.VBC_URL}/static/images/group_bg/knocard.jpg`;
      }

      response = await dispatch(
        registerUser({
          first_name: values.firstName,
          last_name: values.lastName,
          username: values.username,
          email: values.email,
          password: values.password,
          fcm_token: '',
          referral: refferredUser?.referece_code,
          affiliate_group_id: affiliateGroup?.id,
          // leader_name: ,
          group_state_id: affiliateGroupState?.id,
          phone_number: values.mobileNumber,
          phone_country_code: countryCode,
          firebaseUid: '',
          is_preferred_partner: false,
          captcha_code: values.captchaCode,
          background_image: backgroundImage,
          selected_plan: getItem(config.SLECTED_PLAN_KEY),
          is_social_connect: isSocialConnect
        })
      ).unwrap();
      if (response.data && response.data.status === 'success') {
        setItem(config.USER_PROFILE_PAGE_KEY, true);
        setItem(config.REQUIRED_SUBSCRIPTION_KEY, true);
        setItem(config.OPEN_DASHBOARD_KEY, true);
        dispatch(
          registrationSuccess({
            accessToken: response?.data?.data?.api_token,
            user: response.data?.data,
          })
        );
        resetForm();
        removeItem(config.GROUP_NETWORK_ID_KEY);
      } else if (response.data.status === 'failed' && response.data.message) {
        showErrorMessage(response.data.message);
        setSubmitting(false);
        return;
      } else {
        showErrorMessage('User registration failed.');
        setSubmitting(false);
        return;
      }

      captchaRef.current?.resetCaptcha();
    },
  });


  const handleReferralConfirm = () => {
    setReferralConfirm(true);
    setReferralReject(false);
    setConfirmReferredWarning(false);
  };

  const handleReferralReject = () => {
    setReferralConfirm(false);
    setReferralReject(true);
    setConfirmReferredWarning(true);
  };


  const onExpire = () => {
    setFieldValue('captchaCode', '');
  };

  const {
    errors,
    touched,
    handleSubmit,
    values,
    isSubmitting,
    setSubmitting,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;

  const inputStyle = {
    borderRadius: 1,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  };


  const generateUserName = () => {
    if (values.firstName.trim().length > 0 && values.lastName.trim().length > 0) {
      let newUserName = (`${values.firstName.trim()[0]}${values.lastName.trim()}`).toLocaleLowerCase().replaceAll(" ", "");
      if (!values.username.startsWith(newUserName)) {
        formik.setFieldValue('username', newUserName);
        checkGeneratedUserName(newUserName);
      }
    }
  }

  const validateUserName = async (userName: string): Promise<boolean> => {
    let response = await dispatch(
      checkUsername({ username: userName })
    ).unwrap();
    if (response.data && response.data.status !== 'success') {
      return true;
    } else {
      return false;
    }
  }

  const checkGeneratedUserName = async (userName: string) => {
    if (userName.trim().length > 0 && await validateUserName(userName)) {
      setFieldValue('username', (`${userName}${getRandomIntNumber(100)}`).toLocaleLowerCase());
    }
  }



  useEffect(() => {
    if (formik.values.associatedWithGroupOrNetwork == "no") {
      setAffiliateGroup(undefined);
      saveSelectedGroup(undefined);
    }
  }, [formik.values.associatedWithGroupOrNetwork]);

  const handleSubmitButtonClick = (event: any) => {
    if (!isReferralConfirm) {
      setConfirmReferredWarning(true)
    }
  };


  const handleOnselectAffiliateGroup = (selectedAffiliateGroup: AffiliateGroup | undefined) => {

    if (formik.values.associatedWithGroupOrNetwork == "yes") {
      setAffiliateGroup(selectedAffiliateGroup);
      saveSelectedGroup(selectedAffiliateGroup);
    }

  }

  const saveSelectedGroup = (selectedAffiliateGroup: AffiliateGroup | undefined) => {
    if (selectedAffiliateGroup != null) {
      setItem(config.GROUP_NETWORK_ID_KEY, selectedAffiliateGroup.id);
    } else {
      removeItem(config.GROUP_NETWORK_ID_KEY);
    }
    setSelectedAffiliateGroup(selectedAffiliateGroup);
  };


  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                autoComplete='firstName'
                placeholder='First name'
                onBlurCapture={generateUserName}
                InputProps={{
                  sx: inputStyle,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <PersonOutlineIcon color='primary' />
                    </InputAdornment>
                  ),
                }}
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />

              <TextField
                fullWidth
                autoComplete='lastName'
                placeholder='Last name'
                onBlurCapture={generateUserName}
                InputProps={{
                  sx: inputStyle,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <PersonOutlineIcon color='primary' />
                    </InputAdornment>
                  ),
                }}
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                placeholder='Username'
                InputProps={{
                  sx: inputStyle,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <PersonOutlineIcon color='primary' />
                    </InputAdornment>
                  ),
                }}
                {...getFieldProps('username')}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
              />
              <TextField
                fullWidth
                autoComplete='email'
                type='email'
                placeholder='Email address'
                InputProps={{
                  sx: inputStyle,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <EmailIcon color='primary' />
                    </InputAdornment>
                  ),
                }}
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Stack
                width={'100%'}
                direction={'row'}
                // sx={{ backgroundColor: 'red' }}
                spacing={1}
              >
                <PhoneInput
                  placeholder=''
                  value={countryCode}
                  onChange={(phone) => setCountryCode(`+${phone}`)}
                  country={'us'}
                  enableSearch={true}
                  containerStyle={{
                    ...inputStyle,
                    ...{
                      borderRadius: 10,
                      width: '40%',
                      maxHeight: Boolean(
                        touched.mobileNumber && errors.mobileNumber
                      )
                        ? '57px'
                        : 'auto',
                    },
                  }}
                  inputStyle={{
                    ...{ width: '100%', borderRadius: 10, cursor: 'default' },
                  }}
                  enableClickOutside={true}
                  countryCodeEditable={false}
                  disableCountryCode={true}
                />

                <TextField
                  // fullWidth
                  autoComplete='mobileNumber'
                  placeholder='Mobile Number'
                  InputProps={{
                    sx: inputStyle,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <PhoneIphoneIcon color='primary' />
                      </InputAdornment>
                    ),
                  }}
                  {...getFieldProps('mobileNumber')}
                  error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                  helperText={touched.mobileNumber && errors.mobileNumber}
                />
              </Stack>

              <Box width={'100%'} display={{ xs: 'none', sm: 'block' }} />
            </Stack>
            <Stack
              direction={{ xs: 'column', sm: 'row' }} spacing={2}
            >
              <Stack
                direction={'column'}
                spacing={1}
                width={'100%'}
              >
                <Typography
                  variant='subtitle2'
                  sx={{ color: "#088ac6" }}
                >
                  Are you associated with a Group/Network?
                </Typography>
                <Stack flexDirection={"row"}>
                  <FormControl sx={{ minWidth: 80, mr: 2 }}>
                    <InputLabel id="associated-label">*</InputLabel>
                    <Select
                      labelId="associated-label"
                      label="*"
                      {...getFieldProps('associatedWithGroupOrNetwork')}
                      error={Boolean(touched.associatedWithGroupOrNetwork && errors.associatedWithGroupOrNetwork)}
                    >
                      <MenuItem value={"yes"}>Yes</MenuItem>
                      <MenuItem value={"no"}>No</MenuItem>
                    </Select>
                    <FormHelperText sx={{ color: "#FF4842" }}>{touched.associatedWithGroupOrNetwork && errors.associatedWithGroupOrNetwork}</FormHelperText>
                  </FormControl>
                  <AffiliateGroupFilter
                    sx={inputStyle}
                    defaultValue={affiliateGroup}
                    onAffiliateGroupSelected={handleOnselectAffiliateGroup}
                    isDisable={formik.values.associatedWithGroupOrNetwork != "yes"}
                  />

                </Stack>
              </Stack>
              <Stack
                direction={'column'}
                spacing={1}
                width={'100%'}
              >
                {/* <Typography
                  variant='subtitle2'
                  align='center'
                >
                  &nbsp;
                </Typography>
                <AffiliateGroupStateFilter
                  sx={inputStyle}
                   defaultValue={affiliateGroup}
                  onAffiliateGroupStateSelected={selectAffiliateGroupState}
                /> */}
              </Stack>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                placeholder='Password'
                {...getFieldProps('password')}
                InputProps={{
                  sx: inputStyle,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LockOutlinedIcon color='primary' />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        edge='end'
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        <Iconify
                          icon={
                            showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <TextField
                fullWidth
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder='Confirm Password'
                {...getFieldProps('confirmPassword')}
                InputProps={{
                  sx: inputStyle,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LockOutlinedIcon color='primary' />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        edge='end'
                        onClick={() => setShowConfirmPassword((prev) => !prev)}
                      >
                        <Iconify
                          icon={
                            showConfirmPassword
                              ? 'eva:eye-fill'
                              : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                helperText={touched.confirmPassword && errors.confirmPassword}
              />
            </Stack>


            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Stack width={'100%'} direction={'column'}>
                <FormControlLabel
                  sx={{ width: '100%', mb: 2 }}
                  control={<Checkbox {...getFieldProps('agree')} />}
                  label={
                    <Typography
                      variant='body2'
                      // align='center'
                      sx={{ color: 'text.secondary', width: '100%' }}
                    >
                      I have read and accepted&nbsp;
                      <Link
                        underline='none'
                        color='primary'
                        href='https://knocard.com/termsofuse'
                        fontWeight={'fontWeightMedium'}
                        target='_blank'
                      >
                        Terms of Service
                      </Link>
                      &nbsp;and&nbsp;
                      <Link
                        underline='none'
                        color='primary'
                        href='https://knocard.com/privacypolicy'
                        fontWeight={'fontWeightMedium'}
                        target='_blank'
                      >
                        Privacy Policy
                      </Link>
                      ,and give consent to KnoCard to store any personal data that is included in my app on their servers
                    </Typography>
                  }
                />
                <Stack
                  width={'100%'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <HCaptcha
                    sitekey={config.H_CAPTCHA_SITEKEY}
                    onVerify={(captchaCode: string) => {
                      setFieldValue('captchaCode', captchaCode);
                    }}
                    onExpire={onExpire}
                    ref={captchaRef}
                  />
                </Stack>
              </Stack>


              <Stack width={'100%'} direction={'column'} alignItems={'center'} >
                <Stack width={'100%'} direction={'column'} alignItems={'center'} sx={{ ...((!isReferralConfirm && confirmReferredWarning) ? { border: "2px solid red", pt: 1, mt: 2, mb: 2 } : {}) }} >
                  <Typography
                    variant='body2'
                    align='center'
                    color='primary'
                    sx={{ width: '100%', mb: 1 }}
                  >
                    You were referred by
                  </Typography>

                  <Stack width={'80%'} direction={'row'} justifyContent={'center'}
                    alignItems={'center'} sx={{ backgroundColor: "#EBEBEB", borderRadius: "25px", border: "2px solid #088ac6" }}>
                    <Avatar
                      alt='Profile photo'
                      src={
                        refferredUser?.profile_picture ?? '/static/images/placeholder_profile.png'
                      }
                      sx={{}}
                    />
                    <Typography
                      variant='body2'
                      sx={{ width: '100%', color: "#7C7F7B", ml: 1, mr: 1 }}
                    >
                      {refferredUser ? `${refferredUser?.first_name ?? ""} ${refferredUser?.last_name ?? ""}` : "KnoCard Corporate"}
                    </Typography>

                    <IconButton
                      onClick={handleReferralConfirm}
                      edge='end'
                      sx={{
                        color: AppColors.greenColor,
                        mr: 0.5
                      }}
                    >
                      <Iconify
                        icon={
                          'game-icons:check-mark'
                        }
                      />
                    </IconButton>

                    <IconButton
                      onClick={handleReferralReject}
                      edge='end'
                      sx={{
                        color: AppColors.redColor,
                        mr: 0
                      }}
                    >
                      <Iconify
                        icon={
                          'entypo:cross'
                        }
                      />
                    </IconButton>


                  </Stack>

                  <Typography
                    variant='body2'
                    align='center'
                    color='primary'
                    sx={{
                      width: '100%', mb: 1.5, pt: 1,
                      ...((!isReferralConfirm && confirmReferredWarning) ? { color: "red" } : {})
                    }}
                  >
                    {isReferralReject ? "Please reach out to the person who referred you before proceeding" : !isReferralConfirm ? "Please confirm before proceeding" : ""}
                  </Typography>
                </Stack>
                <LoadingButton
                  fullWidth
                  size='large'
                  type={isReferralConfirm ? 'submit' : 'button'}

                  onClick={handleSubmitButtonClick}
                  variant='contained'
                  loading={isSubmitting}
                  sx={{
                    ...(isReferralConfirm ? {} : {
                      backgroundColor: 'gray',
                      '&:focus, &:hover': {
                        backgroundColor: 'gray'
                      },

                    })
                  }}
                >
                  <Typography textTransform={'none'} fontWeight={'600'}>
                    Submit
                  </Typography>
                </LoadingButton>
              </Stack>

            </Stack>



          </Stack>
        </Form>
      </FormikProvider >
      <RegisterSuccessModel onClose={onClose} toggle={toggle} />
    </>
  );
}
