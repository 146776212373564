import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../../store';
import styles from '../../styles/Reports.module.css';
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { getDaysFromFilter } from '../../utils/daysConvertor';
import { getMediaVideoCountView } from '../../store/reports/api';
import ShareSocialPostItem from '../Card/ShareSocialPostItem';
import VideoViewsItem from '../Card/VideoViewsItem';
import { getItem } from '../../utils/localStorage';
import { config } from '../../utils/utils';

interface IProps {
  selectedTimeFilter: string;
  selectedReportUser?: number;
}

const VideoViews: React.FC<IProps> = ({
  selectedTimeFilter,
  selectedReportUser,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);
  const accessToken = getItem(config.AUTH_KEY);
  const videoViewReportData =
    useAppSelector(
      (state) => state?.reports?.videoViewReportData?.videoViewReportData
    ) ?? [];
  // const page =
  //   useAppSelector(
  //     (state) => state?.reports?.shareSocialPostData?.current_page
  //   ) ?? 0;
  // const lastPage =
  //   useAppSelector((state) => state?.reports?.shareSocialPostData?.last_page) ??
  //   0;
  const isLoading = useAppSelector((state) => state?.reports?.isLoading) ?? 0;

  const [selectedSortFilter, setSelectedSortFilter] = useState('all');
  const reportSortList = [
    { label: 'All', value: 'all' },
    { label: 'Most Views', value: 'most_views' },
    { label: 'Most Recent', value: 'most_recent' },
  ];

  const handleShareSocialPosts = (isLoadMore: boolean) => {
    dispatch(
      getMediaVideoCountView({
        user_id: selectedReportUser ? selectedReportUser : user?.id ?? 0,
        api_token: accessToken ?? '',
        timeline: getSortAs(),
        days: getDaysFromFilter(selectedTimeFilter),
        type: 'video',
      })
    ).unwrap();
  };

  useEffect(() => {
    handleShareSocialPosts(false);
  }, [selectedTimeFilter, selectedSortFilter]);

  const handleChangeSortFilter = (event: SelectChangeEvent) => {
    setSelectedSortFilter(event.target.value as string);
  };

  const getSortAs = () => {
    var sortAs = 'most_recent';
    if (selectedSortFilter === 'all') {
      sortAs = 'most_recent';
    } else if (selectedSortFilter === 'most_recent') {
      sortAs = 'most_recent';
    } else if (selectedSortFilter === 'most_views') {
      sortAs = 'most_views';
    } else if (selectedSortFilter === 'most_share') {
      sortAs = 'most_share';
    } else if (selectedSortFilter === 'most_referral') {
      sortAs = 'most_referral';
    } else {
      sortAs = 'most_recent';
    }
    return sortAs;
  };

  return (
    <>
      <div className={styles.resultsContainerHeader}>
        <Typography className={styles.activityBtn}>Video views</Typography>
        <Select
          id='time-filter-select'
          value={selectedSortFilter}
          onChange={handleChangeSortFilter}
          IconComponent={() => (
            <img
              src='/static/images/arrow_down.svg'
              alt='arrow_down'
              className={styles.arrowDown}
            />
          )}
          className={styles.activityFilterDropDown}
        >
          {reportSortList.map((item: any) => {
            return (
              <MenuItem value={item.value}>
                <div className={styles.activityFilterDropDownText}>
                  {item.label}
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </div>

      <Box className={styles.resultsListContainer}>
        {videoViewReportData &&
          videoViewReportData.map((data, index: number) => (
            <VideoViewsItem
              key={index}
              shareSocialPostItemModel={data}
              index={index}
              selectedTimeFilter={selectedTimeFilter}
              sortAs={getSortAs()}
              selectedReportUser={selectedReportUser}
            />
          ))}

        {/* {page < lastPage && !isLoading && (
          <Box>
            <Button
              type='button'
              variant='contained'
              sx={{ m: 2, textTransform: 'initial' }}
              autoCapitalize={'none'}
              onClick={() => handleShareSocialPosts(true)}
            >
              Load More
            </Button>
          </Box>
        )} */}

        {isLoading && (
          <Box>
            {' '}
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default VideoViews;
