import React from 'react';

import {
    Avatar,
    Badge,
    Box,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
    Menu,
    MenuItem,
    Stack
} from '@mui/material';
import { ChatUser } from '../../../store/chat/api/interface';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { pinUnpinChat } from '../../../client/FireStoreChat';
import { AppColors } from '../../../utils/utils';

interface IProps {
    chatUser: ChatUser;
    loginUserId?: number;
    setChatUser: (chatUser: ChatUser) => void,
    lastElementRef?: any
    hidePinButton?: Boolean
}

const FavoriteUserItem: React.FC<IProps> = ({ chatUser, loginUserId, setChatUser, lastElementRef, hidePinButton }) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleSelectChat = () => {
        setChatUser(chatUser);
    }

    const handlePinUnpinChat = () => {
        pinUnpinChat(chatUser, Array.isArray(chatUser?.pined_by) && !chatUser?.pined_by?.includes(loginUserId ?? 0), loginUserId ?? 0);
        handleClose();
    }

    return (
        <Stack position='relative'
            sx={{

                ml: 1, mr: 1
            }}
        >
            <Box onClick={handleSelectChat} sx={{ textAlign: 'center' }}>
                <Avatar
                    alt={chatUser.name}
                    src={chatUser.profile_picture ?? '/static/images/placeholder_profile.png'}
                    sx={{ width: 50, height: 50 }}
                />
                <Typography
                    sx={{
                        fontSize: "0.8rem",
                        fontWeight: "500",
                        color: AppColors.blackColor,
                        textAlign: 'center'
                    }}
                    component='span'
                >
                    {chatUser.first_name ?? ''}
                </Typography>
            </Box>
        </Stack>
    );
};

export default FavoriteUserItem;
