import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../../store';
import styles from '../../styles/Reports.module.css';
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { getDaysFromFilter } from '../../utils/daysConvertor';

import { getAffiliateLandingPageVideoView, getShareAffiliateLandingPageData } from '../../store/reports/api';
import { getItem } from '../../utils/localStorage';
import { config } from '../../utils/utils';
import AffiliateLandingPageItem from '../Card/AffiliateLandingPageItem';



interface IProps {
  selectedTimeFilter: string;
  isFromKnoCardLink?: boolean;
  selectedReportUser?: number;
}

const AffiliateLandingPage: React.FC<IProps> = ({
  selectedTimeFilter,
  isFromKnoCardLink,
  selectedReportUser,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);
  const accessToken = getItem(config.AUTH_KEY);
  const shareAffiliateLandingPageReportData = useAppSelector((state) => state?.reports?.shareAffiliateLandingPageData?.data) ?? [];
  const page = useAppSelector((state) => state?.reports?.shareAffiliateLandingPageData?.current_page) ?? 0;
  const lastPage = useAppSelector((state) => state?.reports?.shareAffiliateLandingPageData?.last_page) ?? 0;
  const isLoading = useAppSelector((state) => state?.reports?.isLoading) ?? 0;

  const handleShareAffiliateLandingPage = (isLoadMore: boolean) => {
    dispatch(
      getShareAffiliateLandingPageData({
        data: {
          user_id: selectedReportUser ? selectedReportUser : user?.id ?? 0,
          api_token: accessToken ?? '',
          days: getDaysFromFilter(selectedTimeFilter),
          ...(isFromKnoCardLink && { isFromKnoCardLink: true }),
        }, page: isLoadMore ? page + 1 : 1,
      })
    ).unwrap();
  };

  useEffect(() => {
    handleShareAffiliateLandingPage(false);
  }, [selectedTimeFilter]);



  return (
    <>
      <Box className={styles.resultsContainerHeader}>
        <Typography className={styles.activityBtn}>Landing Page</Typography>
      </Box>

      <Box className={styles.resultsListContainer}>
        {shareAffiliateLandingPageReportData &&
          shareAffiliateLandingPageReportData.map((data, index: number) => (
            <AffiliateLandingPageItem
              key={index}
              shareAffiliateLandingPageItem={data}
              index={index}
              selectedTimeFilter={selectedTimeFilter}
              selectedReportUser={selectedReportUser}
            />
          ))}

        {page < lastPage && !isLoading && (
          <Box>
            <Button
              type='button'
              variant='contained'
              sx={{ m: 2, textTransform: 'initial' }}
              autoCapitalize={'none'}
              onClick={() => handleShareAffiliateLandingPage(true)}
            >
              Load More
            </Button>
          </Box>
        )}

        {isLoading && (
          <Box>
            {' '}
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default AffiliateLandingPage;
