import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/system";
import { Transition } from "../Transition";

import { useAppDispatch, useAppSelector } from "../../store";
import React, { useEffect, useRef, useState } from "react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { MediaPostContent } from "../../store/post/api/interface";
import Iconify from "../Iconify";

import { CameraAlt as CameraAltIcon, PlayCircleOutline as PlayCircleOutlineIcon } from "@mui/icons-material";
import AddYouTubeModal from "./AddYoutubeMediaModal";
import useToggle from "../../hooks/useToggle";
import { MediaType, PlatformType } from "../../enum";
import postContentSchema from "../../validation/postContentSchema";

import { createMediaPost } from "../../store/post/api";
import { newPostCreated } from "../../store/post/postSlice";
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import ReorderIcon from '@mui/icons-material/Reorder';
import { acceptImageFileTypes, acceptVideoFileTypes, pdfFileTypes } from "../../constant";
import { getCapitalizedString, inputTextFieldStyle, showErrorMessage, showSuccessMessage, urlToFileObject } from "../../utils/utilMethods";
import LandscapeIcon from '@mui/icons-material/Landscape';
import CancelIcon from '@mui/icons-material/Cancel';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MediaLibraryView from "../imagelibrary/MediaLibraryView";
import { MediaLibraryItem } from "../../store/mediaLibrary/api/interface";
import { color } from "framer-motion";
import AssignMediaTopicModal from './AssignMediaTopicModal';
import { TopicObject } from '../../store/mediaTopic/api/interface';
import AddVimeoModal from "./AddVimeoMediaModal";
import { AppColors } from "../../utils/utils";

interface IProps {
  toggle: boolean;
  onClose: () => void;
}

interface IInitialState {
  items: MediaPostContent[];
  description: string;
  mediaPostThumb: File | undefined;
}

const AddMediaModal: React.FC<IProps> = ({ toggle, onClose }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.auth?.user?.id);
  const [attachmentCount, setAttachmentCount] = useState<number>(1);

  const [isVideoProcessing, setVideoProcessing] = useState<boolean>(false);

  const [isMediaLibraryExpanded, setExpandMediaLibrary] =
    useState<boolean>(false);

  const fileUploader = useRef<any>(null);

  const mediaPostThumbfileUploader = useRef<any>(null);

  const pdfFileUploader = useRef<any>(null);

  const [changeThumnail, setChangeThumnail] = useState<
    MediaPostContent | undefined
  >(undefined);

  const [selectedTopic, setSelectedTopic] = useState<TopicObject | undefined>(
    undefined
  );

  const {
    toggle: toggleYouTubeForm,
    onOpen: onOpenYouTubeForm,
    onClose: onCloseYouTubeForm,
  } = useToggle();

  const {
    toggle: toggleVimeoForm,
    onOpen: onOpenVimeoForm,
    onClose: onCloseVimeoForm,
  } = useToggle();

  const {
    toggle: toggleTopicSelection,
    onOpen: onOpenTopicSelection,
    onClose: onCloseTopicSelection,
  } = useToggle();

  function onAddYoutubeLink(mediaPostContent: MediaPostContent) {
    addMediaObject(mediaPostContent);
  }

  function onAddVimeoLink(mediaPostContent: MediaPostContent) {
    addMediaObject(mediaPostContent);
  }

  function onTopicSelected(topic: TopicObject) {
    setSelectedTopic(topic);
  }

  const formik = useFormik<IInitialState>({
    initialValues: {
      items: [],
      mediaPostThumb: undefined,
      description: '',
    },
    onSubmit: async (values) => {
      try {
        if (values.items.length > 0) {
          var result = await dispatch(
            createMediaPost({
              user_id: userId,
              description: values.description,
              mediaData: values.items,
              mediaPostThumb: values.mediaPostThumb,
              topic_id: selectedTopic?.id,
            })
          ).unwrap();
          if (result.data.data != null) {
            dispatch(newPostCreated(result.data.data));
            showSuccessMessage('Media post created successfully.');
            onCancel();
          }
        } else {
          showErrorMessage('At least one media is required.');
        }
      } catch (error: any) { }
    },
    validationSchema: postContentSchema,
  });

  function selectMediaThumbnail() {
    mediaPostThumbfileUploader?.current?.click();
  }

  function selectMedia(isChangeThumNail?: boolean) {
    if (isChangeThumNail != true) {
      setChangeThumnail(undefined);
    }
    fileUploader?.current?.click();
  }

  const selectPdfMedia = () => {
    let isPdfSelected = false;

    formik.values.items.map((item) => {
      if (item.type == MediaType.pdf) {
        isPdfSelected = true;
      }
    });

    if (!isPdfSelected) {
      pdfFileUploader?.current?.click();
    } else {
      showErrorMessage('Maximum 1 pdf is allowed.');
    }
  };

  function addYouTubeMedia() {
    setChangeThumnail(undefined);
    onOpenYouTubeForm();
  }


  function addVimeoMedia() {
    setChangeThumnail(undefined);
    onOpenVimeoForm();
  }

  const onCancel = () => {
    formik.resetForm({
      values: { items: [], description: '', mediaPostThumb: undefined },
    });
    onClose();
  };

  const onFileChange = async (event: any) => {
    if (changeThumnail == undefined) {
      Array.from(event.target.files).forEach((file: any, index: number) => {
        const fileType = acceptVideoFileTypes.includes(file.type)
          ? MediaType.video
          : MediaType.image;
        if (fileType == MediaType.video) {
          setVideoProcessing(true);
          const videoURL = URL.createObjectURL(file);
          // Create a video element to capture a frame for the thumbnail
          const videoElement = document.createElement('video');
          videoElement.src = videoURL;

          // Wait for metadata to load, then capture a frame
          videoElement.onloadedmetadata = () => {
            videoElement.currentTime = 5; // Seek to 5 seconds (you can adjust this)
            videoElement.onseeked = () => {
              const canvas = document.createElement('canvas');
              canvas.width = videoElement.videoWidth;
              canvas.height = videoElement.videoHeight;
              const context = canvas.getContext('2d');
              context?.drawImage(
                videoElement,
                0,
                0,
                canvas.width,
                canvas.height
              );

              canvas.toBlob(
                (blob) => {
                  if (blob != null) {
                    const thumbnailBlob: any = new Blob([blob], {
                      type: 'image/jpeg',
                    }); // You can adjust the type as needed
                    thumbnailBlob.lastModifiedDate = new Date();
                    thumbnailBlob.name = `${Date.now()}.jpeg`;
                    addMediaObject({
                      file: file,
                      thumbnail: thumbnailBlob as File,
                      type: fileType,
                      title: '',
                      description: '',
                      platform: PlatformType.local,
                    });
                  }
                  // Clean up
                  URL.revokeObjectURL(videoURL);
                  videoElement.remove();
                  setVideoProcessing(false);
                },
                'image/jpeg',
                0.6
              );
            };
          };
        } else {
          addMediaObject({
            file: file,
            type: fileType,
            title: '',
            description: '',
            platform: PlatformType.local,
          });
        }
      });
    } else {
      let array = [...formik.values.items];
      let index = array.indexOf(changeThumnail!);
      if (index !== -1 && event.target.files.length > 0) {
        changeThumnail.thumbnail = event.target.files[0];
        formik.values.items[index] = changeThumnail;
      }
      setChangeThumnail(undefined);
    }
  };

  const onPdfFileChange = async (event: any) => {
    if (event.target.files.length > 0) {
      addMediaObject({
        file: event.target.files[0],
        type: MediaType.pdf,
        title: '',
        description: '',
        platform: PlatformType.local,
      });
    }
  };

  const onPostThumbFileChange = async (event: any) => {
    if (event.target.files.length > 0) {
      formik.setFieldValue('mediaPostThumb', event.target.files[0]);
    }
  };

  const addMediaObject = async (mediaPostContent: MediaPostContent) => {
    formik.values.items = [...formik.values.items, mediaPostContent];
    setAttachmentCount(Math.random() + Math.random());
  };

  const changeThumbnailClicked = (mediaPostContent: MediaPostContent) => {
    setChangeThumnail(mediaPostContent);
    selectMedia(true);
  };

  const removeMediaObject = (mediaPostContent: MediaPostContent) => {
    let array = [...formik.values.items];
    let index = array.indexOf(mediaPostContent);
    if (index !== -1) {
      array.splice(index, 1);
      formik.values.items = array;
    }
    setAttachmentCount(Math.random() + Math.random());
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      formik.values.items,
      result.source.index,
      result.destination.index
    );
    formik.values.items = items;
  };

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleOnMediaSelectFromMediaLibrary = async (
    mediaLibraryItem: MediaLibraryItem
  ) => {
    setExpandMediaLibrary(false);

    if (mediaLibraryItem.link != null && mediaLibraryItem.filename != null) {
      setVideoProcessing(true);

      addMediaObject({
        link: mediaLibraryItem.link,
        thumbnail_link: mediaLibraryItem.thumbnail,
        type: mediaLibraryItem.type ?? '',
        title: mediaLibraryItem.title ?? '',
        description: '',
        platform: PlatformType.asset,
      });

      setVideoProcessing(false);
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        open={toggle}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onCancel();
        }}
        aria-describedby='alert-dialog-slide-description'
        sx={{
          zIndex: '3000',
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: AppColors.primaryColor,
            color: 'white',
          }}
        >
          New Media
        </DialogTitle>
        <Divider
          sx={{
            mt: 0,
            borderColor: 'gray',
          }}
        />
        <Box
          sx={{
            background: AppColors.backgroundColor,
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
              <DialogContent sx={{ padding: '20px' }}>
                <Stack
                  divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />}
                  spacing={3}
                >
                  <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>Description</Typography>
                    <Stack direction={'row'} display={'flex'} spacing={1}>
                      <TextField
                        id='description'
                        name={`description`}
                        value={formik.values.description ?? ''}
                        onInput={(e: any) => {
                          e.target.value = getCapitalizedString(
                            e.target.value ?? ''
                          );
                        }}
                        onChange={formik.handleChange}
                        placeholder='Description'
                        //   fullWidth
                        multiline={true}
                        maxRows={4}
                        variant='outlined'
                        error={Boolean(
                          formik.errors.description &&
                          formik.touched.description
                        )}
                        helperText={
                          Boolean(
                            formik.errors.description &&
                            formik.touched.description
                          ) && formik.errors.description
                        }
                        InputProps={{
                          sx: inputTextFieldStyle()
                        }}
                        sx={{
                          width: '70%',
                        }}
                      />

                      {!selectedTopic && (
                        <Box
                          display={'flex'}
                          flexDirection={'row'}
                          alignContent={'center'}
                          alignItems={'center'}
                          border={`1px solid ${AppColors.strokeColor}`}

                          borderRadius={'4px'}
                          p={0.5}
                          sx={{ cursor: 'pointer', backgroundColor: AppColors.grayZeroColor }}
                          onClick={() => onOpenTopicSelection()}
                        >
                          <Iconify
                            icon={'flat-color-icons:plus'}
                            height={25}
                            width={25}
                            sx={{ color: AppColors.greenColor }}
                          />
                          <Typography variant='body2' ml={1} sx={{ color: AppColors.grayThreeColor }} >
                            Add to Topic
                          </Typography>
                          <Iconify
                            icon={'iconamoon:arrow-down-2'}
                            color='white'
                            height={25}
                            width={25}
                            sx={{ color: AppColors.grayThreeColor }}
                          />
                        </Box>
                      )}

                      {selectedTopic && (
                        <Box
                          display={'flex'}
                          flexDirection={'row'}
                          alignContent={'center'}
                          alignItems={'center'}
                          border={`1px solid ${AppColors.strokeColor}`}
                          borderRadius={'4px'}
                          p={0.5}
                          pr={1}
                          sx={{ cursor: 'pointer', backgroundColor: AppColors.grayZeroColor }}
                        >
                          <img
                            height='40px'
                            width='40px'
                            src={selectedTopic.link}
                            alt={selectedTopic.title}
                            style={{
                              borderRadius: 100,
                            }}
                            onClick={() => onOpenTopicSelection()}
                          />
                          <Typography
                            variant='body2'
                            sx={{ color: AppColors.grayThreeColor }}
                            ml={1}
                            mr={1}
                            onClick={() => onOpenTopicSelection()}
                          >
                            {selectedTopic.title}
                          </Typography>
                          <IconButton
                            onClick={() => setSelectedTopic(undefined)}
                            edge='end'
                          >
                            <Iconify
                              icon={'carbon:close-filled'}
                              height={25}
                              width={25}
                              sx={{ color: AppColors.redColor }}
                            />
                          </IconButton>
                        </Box>
                      )}
                    </Stack>

                    <input
                      type='file'
                      id='file'
                      ref={fileUploader}
                      onClick={(e: any) => (e.currentTarget.value = '')}
                      onChange={onFileChange}
                      style={{ display: 'none' }}
                      accept={[
                        ...acceptImageFileTypes,
                        ...(changeThumnail == undefined
                          ? acceptVideoFileTypes
                          : []),
                      ].join(',')}
                      multiple
                    />

                    <input
                      type='file'
                      id='pdfFile'
                      ref={pdfFileUploader}
                      onClick={(e: any) => (e.currentTarget.value = '')}
                      onChange={onPdfFileChange}
                      style={{ display: 'none' }}
                      accept={[...pdfFileTypes].join(',')}
                    />

                    <List>
                      <ListItem disablePadding>
                        <ListItemButton onClick={() => selectMedia()}>
                          <ListItemIcon>
                            <CameraAltIcon sx={{ color: AppColors.grayThreeColor }} />
                          </ListItemIcon>
                          <ListItemText
                            primary='Add photo/video'
                            sx={{ color: AppColors.grayThreeColor }}
                          />
                        </ListItemButton>
                      </ListItem>{' '}
                      <Divider
                        sx={{
                          mt: 0,
                          borderStyle: 'dashed',
                          borderColor: AppColors.grayThreeColor,
                        }}
                      />
                      <ListItem disablePadding>
                        <ListItemButton onClick={addYouTubeMedia}>
                          <ListItemIcon>
                            <PlayCircleOutlineIcon sx={{ color: AppColors.grayThreeColor }} />
                          </ListItemIcon>
                          <ListItemText
                            primary='YouTube Link'
                            sx={{ color: AppColors.grayThreeColor }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider
                        sx={{
                          mt: 0,
                          borderStyle: 'dashed',
                          borderColor: AppColors.grayThreeColor,
                        }}
                      />
                      <ListItem disablePadding>
                        <ListItemButton onClick={addVimeoMedia}>
                          <ListItemIcon>
                            <PlayCircleOutlineIcon sx={{ color: AppColors.grayThreeColor }} />
                          </ListItemIcon>
                          <ListItemText
                            primary='Vimeo Link'
                            sx={{ color: AppColors.grayThreeColor }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider
                        sx={{
                          mt: 0,
                          borderStyle: 'dashed',
                          borderColor: AppColors.grayThreeColor,
                        }}
                      />
                      <ListItem disablePadding>
                        <ListItemButton onClick={selectPdfMedia}>
                          <ListItemIcon>
                            <PictureAsPdfIcon sx={{ color: AppColors.grayThreeColor }} />
                          </ListItemIcon>
                          <ListItemText
                            primary='Pdf'
                            sx={{ color: AppColors.grayThreeColor }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider
                        sx={{
                          mt: 0,
                          borderStyle: 'dashed',
                          borderColor: AppColors.grayThreeColor,
                        }}
                      />
                    </List>
                  </Grid>
                </Stack>
                <input
                  type='file'
                  id='file'
                  ref={mediaPostThumbfileUploader}
                  onClick={(e: any) => (e.currentTarget.value = '')}
                  onChange={onPostThumbFileChange}
                  style={{ display: 'none' }}
                  accept={[...acceptImageFileTypes].join(',')}
                />

                <Box sx={{ mt: 2, mb: 2 }}>
                  <MediaLibraryView
                    isShowTitle={false}
                    isExpanded={isMediaLibraryExpanded}
                    setExpanded={setExpandMediaLibrary}
                    onSelectMedia={handleOnMediaSelectFromMediaLibrary}
                  />
                </Box>
                {!isMediaLibraryExpanded && formik.values.items.length > 0 && (
                  <Box sx={{}}>
                    <Typography
                      sx={{ fontWeight: '500', mt: 1, color: AppColors.grayThreeColor }}
                    >
                      Post Thumbnail
                    </Typography>
                  </Box>
                )}
                {!isMediaLibraryExpanded && formik.values.items.length > 0 && (
                  <Box
                    onClick={() =>
                      !formik.values.mediaPostThumb && selectMediaThumbnail()
                    }
                    sx={{
                      mt: 1,
                      mb: 2,
                      borderRadius: '12px',
                      backgroundColor: AppColors.primaryColor,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      cursor: 'pointer',
                      width: '100%',
                    }}
                  >
                    {formik.values.mediaPostThumb && (
                      <Box
                        sx={{
                          width: '100%',
                          position: 'relative',
                          top: '10px',
                          right: '10px',
                        }}
                      >
                        <IconButton
                          aria-label='remove'
                          id='btn-remove'
                          aria-haspopup='true'
                          onClick={(event) => {
                            formik.setFieldValue('mediaPostThumb', undefined);
                          }}
                          sx={{
                            right: '0px',
                            top: '0',
                            position: 'absolute',
                          }}
                        >
                          <CancelIcon sx={{ color: AppColors.redColor }} />
                        </IconButton>
                      </Box>
                    )}
                    {formik.values.mediaPostThumb ? (
                      <Box
                        component={'img'}
                        src={URL.createObjectURL(formik.values.mediaPostThumb)}
                        alt='addMedia'
                        sx={{
                          maxWidth: '100%',
                          maxHeight: '200px',
                          padding: '10px',
                        }}
                      />
                    ) : (
                      <Stack
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          pt: 1,
                          pb: 1,
                        }}
                      >
                        <LandscapeIcon
                          sx={{ fontSize: '50px', color: AppColors.whiteColor }}
                        />
                        <Typography sx={{ color: AppColors.whiteColor }}>
                          Upload thumbnail{' '}
                        </Typography>
                      </Stack>
                    )}
                  </Box>
                )}

                {!isMediaLibraryExpanded && (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='droppable'>
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {formik.values?.items.map((item, idx) => (
                            <Draggable
                              key={`item-${idx}`}
                              draggableId={`item-${idx}`}
                              index={idx}
                            >
                              {(provided, snapshot) => (
                                <div
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <React.Fragment key={idx}>
                                    <Stack
                                      alignItems='flex-end'
                                      spacing={1.5}
                                      sx={{}}
                                    >
                                      <Grid
                                        container
                                        spacing={3}
                                        paddingRight={'4px'}
                                      >
                                        <Grid
                                          xs={4}
                                          sm={2}
                                          item
                                          justifyContent='center'
                                          alignItems='center'
                                        >
                                          <ReorderIcon
                                            sx={{ color: AppColors.grayThreeColor }}
                                          />
                                        </Grid>
                                        <Grid
                                          xs={8}
                                          sm={2}
                                          item
                                          justifyContent='center'
                                          alignItems='center'
                                          sx={{
                                            paddingTop: '5px !important',
                                          }}
                                        >
                                          <img
                                            src={
                                              item.type == 'pdf'
                                                ? '/static/images/pdf.png'
                                                : item?.thumbnail !=
                                                  undefined ||
                                                  item?.file != undefined
                                                  ? URL.createObjectURL(
                                                    item?.thumbnail
                                                      ? item.thumbnail!
                                                      : item.file!
                                                  )
                                                  : item.thumbnail_link ??
                                                  item.link ??
                                                  ''
                                            }
                                            alt={item?.file?.name}
                                            height={'64px'}
                                            width={'100%'}
                                            style={{
                                              objectFit: 'contain',
                                              paddingTop: '8px',
                                              paddingBottom: '8px',
                                            }}
                                          />
                                        </Grid>
                                        {item.type == MediaType.video && (
                                          <Grid
                                            item
                                            xs={12}
                                            sm={8}
                                            paddingTop={'12px !important'}
                                            paddingBottom={'12px !important'}
                                            justifyContent='center'
                                            alignItems='center'
                                          >
                                            <TextField
                                              id='title'
                                              name={`items[${idx}].title`}
                                              value={
                                                formik.values.items[idx]
                                                  .title ?? ''
                                              }
                                              onInput={(e: any) => {
                                                e.target.value =
                                                  getCapitalizedString(
                                                    e.target.value ?? ''
                                                  );
                                              }}
                                              onChange={formik.handleChange}
                                              placeholder='Title'
                                              fullWidth
                                              variant='outlined'
                                              error={Boolean(
                                                (
                                                  formik.errors?.items?.[
                                                  idx
                                                  ] as any
                                                )?.title &&
                                                formik.touched.items?.[idx]
                                                  ?.title
                                              )}
                                              helperText={
                                                Boolean(
                                                  (
                                                    formik.errors.items?.[
                                                    idx
                                                    ] as any
                                                  )?.title &&
                                                  formik.touched.items?.[idx]
                                                    ?.title
                                                ) &&
                                                (
                                                  formik.errors.items?.[
                                                  idx
                                                  ] as any
                                                )?.title
                                              }

                                              InputProps={{
                                                sx: inputTextFieldStyle()
                                              }}
                                            />
                                          </Grid>
                                        )}

                                        {/* <Grid item xs={12} sm={4}
                                                                            paddingTop={'12px !important'}
                                                                            paddingBottom={'12px !important'}
                                                                            justifyContent="center"
                                                                            alignItems="center">
                                                                            <TextField
                                                                                id="description"
                                                                                name={`items[${idx}].description`}
                                                                                value={
                                                                                    formik.values.items[idx].description ?? ""
                                                                                }
                                                                                onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                                                                                onChange={formik.handleChange}
                                                                                label="Description"
                                                                                fullWidth
                                                                                variant="outlined"
                                                                                error={Boolean((formik.errors?.items?.[idx] as any)?.description && formik.touched?.items?.[idx]?.description)}
                                                                                helperText={
                                                                                    Boolean((formik.errors.items?.[idx] as any)?.description && formik.touched?.items?.[idx]?.description) &&
                                                                                    (formik.errors.items?.[idx] as any)?.description
                                                                                }
                                                                            />
                                                                        </Grid> */}
                                      </Grid>
                                      <Box
                                        justifyContent='flex-end'
                                        sx={{ mt: 0 }}
                                      >
                                        {item.type == MediaType.video &&
                                          item.platform !=
                                          PlatformType.asset && (
                                            <Button
                                              size='small'
                                              color='primary'
                                              sx={{
                                                marginTop: '0px !important',
                                                marginBottom: '12px !important',
                                                marginRight: '5px',
                                                color: AppColors.primaryColor
                                              }}
                                              startIcon={
                                                <Iconify
                                                  icon='eva:image-outline'
                                                  sx={{ color: AppColors.primaryColor }}
                                                />
                                              }
                                              onClick={() =>
                                                changeThumbnailClicked(item)
                                              }
                                            >
                                              Change Thumbnail
                                            </Button>
                                          )}

                                        <Button
                                          size='small'
                                          sx={{
                                            marginTop: '0px !important',
                                            marginBottom: '12px !important',
                                            color: AppColors.redColor
                                          }}
                                          startIcon={
                                            <Iconify icon='eva:trash-2-outline' />
                                          }
                                          onClick={() =>
                                            removeMediaObject(item)
                                          }
                                        >
                                          remove
                                        </Button>
                                      </Box>
                                    </Stack>
                                    <Divider
                                      sx={{
                                        mt: 0,
                                        borderStyle: 'dashed',
                                        color: AppColors.grayThreeColor
                                      }}
                                    />
                                  </React.Fragment>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}

                {isVideoProcessing && (
                  <Stack
                    direction={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ pt: 3, pb: 2 }}
                  >
                    <CircularProgress />
                    <Typography sx={{ color: AppColors.grayThreeColor, mt: 0.5 }}>
                      Please wait...
                    </Typography>
                  </Stack>
                )}
              </DialogContent>
            </FormikProvider>
            <DialogActions sx={{ paddingX: 3 }}>
              <LoadingButton
                type='button'
                loading={false}
                variant='contained'
                onClick={onCancel}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                type='submit'
                loading={formik.isSubmitting}
                variant='contained'
              >
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        </Box>
      </Dialog>

      <AddYouTubeModal
        onAddLink={onAddYoutubeLink}
        onClose={onCloseYouTubeForm}
        toggle={toggleYouTubeForm}
      />

      <AddVimeoModal
        onAddLink={onAddVimeoLink}
        onClose={onCloseVimeoForm}
        toggle={toggleVimeoForm}
      />

      <AssignMediaTopicModal
        onTopicSelected={onTopicSelected}
        onClose={onCloseTopicSelection}
        toggle={toggleTopicSelection}
      />
    </>
  );
};

export default AddMediaModal;
