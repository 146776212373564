import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { CustomLandingPageResponse } from './interface';

export const getCustomLandingPage = createAsyncThunk(
  'customLandingPage/getCustomLandingPage',
  async (values: { user_id: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<CustomLandingPageResponse>(
        `/api/custom-landing-page?user_id=${values.user_id}`

      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



export const saveCustomLandingPage = createAsyncThunk(
  'customLandingPage/saveCustomLandingPage',
  async (values: { page_data: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post<CustomLandingPageResponse>(
        `/api/custom-landing-page/save`,
        values
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


