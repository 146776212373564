import * as Yup from 'yup';

const SupportSchema = Yup.object().shape({
  email: Yup.string().email('Email is incorrect format. Please add the correct format of email.').required('Email is required. Please add your email.'),
  description: Yup.string().required('Please type your confirm password.'),
  issueType: Yup.string().notOneOf(["0"], 'Please select how may we help').required('Please select how may we help'),
  issuePage: Yup.string().when('issueType', ([issueType], schema) => {
    if ((issueType == "1" || issueType == "5")) {
      return schema.required('Select page.').notOneOf(["0"], 'Select page.')
    }
    return schema;
  }
  )
});
export default SupportSchema;
