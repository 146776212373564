import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import {
  Button,
  Box,
  Divider,
  Typography,
  Avatar,
  IconButton,
} from '@mui/material';
import MenuPopover from '../../components/MenuPopover';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store';
import { logoutUserFromLocal } from '../../store/auth/authSlice';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AppColors, config } from '../../utils/utils';
import { removeItem } from '../../utils/localStorage';

const AccountPopover = () => {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const userInfo = useAppSelector((state) => state?.auth?.user);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    //await dispatch(logoutUser());
    localStorage.clear();
    await dispatch(logoutUserFromLocal());
    removeItem(config.INFO);
    navigate(`/`);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 49,
          height: 49,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        {userInfo?.profile_picture ? (
          <Avatar
            alt={userInfo?.name || ''}
            src={userInfo?.profile_picture || '/static/images/knocard.png'}
            sx={{
              objectFit: 'cover',
              '& img': { objectFit: 'cover' },
            }}
          />
        ) : (
          <Avatar
            src='/static/images/knocard.png'
            alt={userInfo?.name || ""}
            sx={{ objectFit: 'cover' }}
          />
        )}

      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography
            variant='subtitle1'
            noWrap
          >
            {`${userInfo?.first_name || ''} ${userInfo?.last_name || ''}`}
          </Typography>
          <Typography
            variant='body2'
            sx={{ color: 'text.secondary' }}
            noWrap
          >
            {userInfo?.email || ''}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color='primary'
            variant='contained'
            onClick={handleLogout}
            sx={{ textTransform: 'unset' }}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
};
export default AccountPopover;
