import { createSlice } from '@reduxjs/toolkit';
import { getContacts } from './api';
import { initialState } from './api/interface';

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getContacts.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getContacts.fulfilled, (state, action) => {
      state.data = action.payload.data.data.contacts.data; //[...(action.payload.data.data.contacts.current_page == 1 ? [] : state.data ?? []), ...action.payload.data.data.contacts.data];
      state.isLoadMore = action.payload.data.data.contacts.last_page > action.payload.data.data.contacts.current_page;
      state.total = action.payload.data.data.contacts.total;
      state.page = action.payload.data.data.contacts.current_page;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getContacts.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const {
  reset
} = contactsSlice.actions;
export default contactsSlice.reducer;
