import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TabPanel from '../TabPanel';
import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CollectionsIcon from '@mui/icons-material/Collections';
import { styled } from '@mui/material/styles';
import TagIcon from '@mui/icons-material/Tag';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import useToggle from '../../hooks/useToggle';
import YouTube from 'react-youtube';
import ViewMediaPostModal from '../modal/ViewMediaPostModal';
import { useAppDispatch, useAppSelector } from '../../store';
import playIcon from "../../assets/play_circle.svg";
import {
  getPinedPosts,
  getPosts,
  getShareFormInfo,
  getSinglePost,
} from '../../store/post/api';
import {
  PostObject,
  ShareFormInfoResponse,
} from '../../store/post/api/interface';
import { IHomeParams } from '../../enum';
import { useParams, useSearchParams } from 'react-router-dom';
import { incrementCurrentPage } from '../../store/post/postSlice';
import { analytics } from '../../store/shareRefer/api';
import { Stack } from '@mui/system';
import moment, { duration } from 'moment';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import PlayCircleFilledTwoToneIcon from '@mui/icons-material/PlayCircleFilledTwoTone';
import useWindowSize from '../../utils/useWindowSize';
import { UserInfo } from '../../store/userInfo/api/interface';
import { Console } from 'console';
import Iconify from '../Iconify';
import { ArrowDropDown, ArrowDropUp, BorderRight, Height } from '@mui/icons-material';
import { isMobile } from '../../utils/getMobileOperatingSystem';
import { getTopicList } from '../../store/mediaTopic/api';
import Vimeo from '@u-wave/react-vimeo';
import MediaVideoComponent from '../MediaVideoComponent';
import { AppColors } from '../../utils/utils';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';

const GalleryTab = styled(Tab)<TabProps>(({ theme }) => ({
  color: 'white',
  fontWeight: 'bold',
  minHeight: '50px',
  '&.Mui-selected': {
    backgroundColor: AppColors.primaryColor,
    color: AppColors.whiteColor,
    borderRadius: "10px"
  },
}));

interface IProps {
  value: number;
  index: number;
}

const opts = {
  height: '100%',
  width: '100%',
  playerVars: {
    // Hide related videos after the video finishes
    rel: 0,
    // Hide the video annotations
    iv_load_policy: 3,
    // Hide the YouTube logo
    modestbranding: 1,
  },
};

const MediaTabPanel: React.FC<IProps> = ({ value, index }) => {


  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const params = useParams<IHomeParams>();
  const [postTab, setPostTab] = useState(0);
  const matches = useMediaQuery('(max-width:745px)');
  const { toggle, onOpen, onClose } = useToggle();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.userInfo?.data?.id);
  const userInfo = useAppSelector((state) => state?.userInfo?.data);
  const posts = useAppSelector((state) => state?.post.data?.data) ?? [];
  const isLoading = useAppSelector((state) => state?.post?.isLoading);
  const hasMore = useAppSelector((state) => state?.post?.hasMore);
  const currentPage = useAppSelector((state) => state?.post?.currentPage);
  const authUserInfo = useAppSelector((state) => state?.auth?.user);

  //const pinedData = useAppSelector((state) => state?.post.pinedData?.data) ?? [];

  const pinedDataCurrentPage = useAppSelector(
    (state) => state?.post?.pinedDataCurrentPage
  );

  const topicDataCurrentPage = useAppSelector(
    (state) => state?.mediaTopic?.currentPage
  );
  const topicData =
    useAppSelector((state) => state?.mediaTopic.data?.data) ?? [];


  //const [isPinedPostExpanded, setIsPinedPostExpanded] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState<number | undefined>(
    undefined
  );
  const [activePost, setActivePost] = useState<PostObject>();
  const [manuallyClicked, setManuallyClicked] = useState<boolean>(false);
  const [shareFormInfoResponse, setShareFormInfoResponse] =
    useState<ShareFormInfoResponse>();
  const observer = useRef<IntersectionObserver>();
  const [searchParams] = useSearchParams();


  const windowSize = useWindowSize();
  const [cardWidth, setCardWidth] = useState(300);

  useEffect(() => {
    const newWidth = matches
      ? windowSize.width > 350
        ? 300
        : windowSize.width * 0.8
      : windowSize.width * 0.5;

    setCardWidth(newWidth);
  }, [windowSize]);

  const [isFirstTimeVideoPlay, setIsFirstTimeVideoPlay] = useState<{
    id: number | null;
    flag: boolean;
  }>({
    id: null,
    flag: true,
  });

  useEffect(() => {
    if (userId) {
      dispatch(getPosts({ user_id: userId, page: currentPage, topic_id: selectedTopic }));
      //dispatch(getPinedPosts({ user_id: userId, page: pinedDataCurrentPage }));
      dispatch(getTopicList({ user_id: userId, page: topicDataCurrentPage }));
    }
  }, [userId, dispatch, currentPage, pinedDataCurrentPage, topicDataCurrentPage]);

  useEffect(() => {
    if (userId) {
      dispatch(incrementCurrentPage(0));
      dispatch(
        getPosts({
          user_id: userId,
          page: currentPage,
          topic_id: selectedTopic,
        })
      );
    }
  }, [selectedTopic]);

  useEffect(() => {
    const fetchSinglePost = async () => {
      const tab = params?.tab;
      if (tab === undefined || tab !== 'community') {
        const postId = params?.postId;
        if (!postId) return;
        try {
          const post = await dispatch(getSinglePost(postId)).unwrap();
          const form_code = searchParams.get('form_code') ?? '';

          if (form_code) {

            const shareFormInfo = await dispatch(
              getShareFormInfo({
                form_code,
                post_id: post?.data?.data?.id,
                postby_id: post?.data?.data?.user_id,
              })
            ).unwrap();

            if (shareFormInfo.data.data) {
              setShareFormInfoResponse(shareFormInfo.data);
            }
          }

          if (post.data.data) {
            setActivePost(post.data.data);
            onOpen();
          }
        } catch (error) { }
      }
    };
    fetchSinglePost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPostTab(newValue);
  };

  const handleActivePost = (post: PostObject) => {
    const sc = searchParams.get('sc');
    let elid = searchParams.get('elid');
    let loginUserId = authUserInfo?.id;

    onOpen();
    setManuallyClicked(true);
    dispatch(
      analytics({
        route: '/post/view',
        data: {
          post_id: post?.id,
          postby_id: post?.user_id,
          ...(loginUserId && { user_id: loginUserId }),
          ...((!loginUserId && sc) && { viewer_code: sc }),
          ...(!loginUserId && elid && { elid: elid }),
        },
      })
    );
    setActivePost(post);
  };

  const handleOnplayVideo = (contentId: number, videoPost: PostObject, second: number, duration: number) => {
    if (!isFirstTimeVideoPlay.flag && contentId === isFirstTimeVideoPlay.id)
      return;
    const sc = searchParams.get('sc');
    let elid = searchParams.get('elid');
    let loginUserId = authUserInfo?.id;

    dispatch(
      analytics({
        route: '/post/view',
        data: {
          post_id: videoPost?.id,
          postby_id: videoPost?.user_id,
          view_type: 'video',
          content_id: contentId,
          video_watch_seconds: second,
          video_duration_seconds: duration,
          ...(loginUserId && { user_id: loginUserId }),
          ...((!loginUserId && sc) && { viewer_code: sc }),
          ...(!loginUserId && elid && { elid: elid }),
        },
      })
    );
    setIsFirstTimeVideoPlay({
      id: contentId,
      flag: false,
    });
  };

  const handleOnPauseOrCompleteVideo = (
    contentId: number,
    second: number,
    duration: number,
    videoPost: PostObject,
  ) => {
    const sc = searchParams.get('sc');
    let elid = searchParams.get('elid');
    let loginUserId = authUserInfo?.id;

    dispatch(
      analytics({
        route: '/post/view/video-time',
        data: {
          post_id: videoPost?.id,
          postby_id: videoPost?.user_id,
          view_type: 'video',
          video_watch_seconds: second,
          video_duration_seconds: duration,
          content_id: contentId,
          ...(loginUserId && { user_id: loginUserId }),
          ...((!loginUserId && sc) && { viewer_code: sc }),
          ...(!loginUserId && elid && { elid: elid }),
        },
      })
    );
  };

  const lastElementRef = useCallback(
    // (*)
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          dispatch(incrementCurrentPage(currentPage + 1));
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  return (
    <>
      <TabPanel
        key={`tab-${index}`}
        value={value}
        index={index}
        sx={{ ml: 1, minHeight: "100vh" }}
        p={0}
      >

        {topicData && topicData.length > 0 && (
          <Box p={1}>
            <Grid
              container
              spacing={isMobile() ? 1 : 3}
              direction={'row'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              {topicData.map((item, index) => (
                <Grid
                  item
                  // xs
                  key={index}
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  onClick={() =>
                    setSelectedTopic(
                      selectedTopic !== topicData[index].id
                        ? topicData[index].id
                        : undefined
                    )
                  }
                  sx={{
                    borderRadius: "10px",
                    mt: 1,
                    p: 1,
                    ml: 1,
                    cursor: 'pointer', ...(selectedTopic === topicData[index].id ? { background: AppColors.grayZeroColor } : {})
                  }}
                >
                  <img
                    // component='img'
                    height='70px'
                    width='70px'
                    src={item.link}
                    alt={item.title}
                    style={{
                      borderRadius: 10,
                    }}
                  />

                  <Typography mt={1} variant='body2' textAlign={'center'} sx={{ color: AppColors.grayThreeColor }}>
                    {item.title}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
          sx={{ mb: 1 }}
        >
          <Tabs
            value={postTab}
            onChange={handleChange}
            variant='fullWidth'
            sx={{
              width: '100%',
              borderRadius: '10px',
              backgroundColor: AppColors.whiteColor,
              pl: isSmallScreen ? 1 : 0,
              pr: isSmallScreen ? 1 : 0
            }}

            TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
          >
            <GalleryTab
              icon={
                <GridViewOutlinedIcon
                  sx={{ color: postTab == 0 ? AppColors.whiteColor : AppColors.blackColor }}
                />
              }
            />
            <GalleryTab
              icon={
                <FormatListBulletedIcon
                  sx={{ color: postTab == 1 ? AppColors.whiteColor : AppColors.blackColor }}
                />
              }
            />
          </Tabs>
        </Box>
        <TabPanel
          value={postTab}
          index={0}
          p='10px 0 0 0'
        // backgroundColor="#f1f2f6"
        >
          <div
            className={`${matches ? 'post-responsive-container' : 'post-container'
              }`}
          >
            <Grid container>
              {posts?.map((post, idx) => {
                return (
                  <Grid
                    ref={idx + 1 === posts?.length ? lastElementRef : undefined}
                    key={`post-${idx}`}
                    item
                    xs={4}
                    md={4}
                    height={matches ? '120px' : '238px'}
                    width='100%'
                    sx={{ p: "1px" }}
                  >
                    <Box
                      position='relative'
                      width='100%'
                      height='100%'
                      className='cursor-pointer'
                      onClick={() => handleActivePost(post)}
                    >
                      <Box
                        component={'img'}
                        src={
                          post?.thumb_link
                            ? post?.thumb_link
                            : post?.contents?.[0]?.type === 'pdf'
                              ? '/static/images/pdf.png'
                              : post?.contents?.[0]?.type === 'video'
                                ? post?.contents?.[0]?.thumbnail ?? ''
                                : post?.contents?.[0]?.link ?? ''
                        }
                        alt=''
                        className='post-img'
                        sx={{
                          ...(!post?.thumb_link &&
                            post?.contents?.[0]?.type === 'pdf'
                            ? { objectFit: 'contain' }
                            : {}),
                          borderRadius: '10px',
                          objectFit: "cover"
                        }}
                      />
                      {post?.contents?.length > 1 && (
                        <CollectionsIcon
                          sx={{
                            color: 'grey',
                            position: 'absolute',
                            right: 5,
                            top: 5,
                          }}
                        />
                      )}
                      {post?.contents?.[0]?.type === 'video' && (
                        <Box
                          component={'img'}
                          src={playIcon}
                          sx={{
                            height: "30%",
                            width: "30%",
                            color: '#dfe2e4',
                            position: 'absolute',
                            top: '35%',
                            left: '35%',
                            fontSize: '4rem',
                          }}
                        />
                      )}
                      {/*post.is_pined === 1 && (
                        <Box
                          sx={{
                            height: '40px',
                            width: '40px',
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                          }}
                        >
                          <Iconify
                            icon={'game-icons:pin'}
                            color='white'
                            height={30}
                            width={30}
                          />
                        </Box>
                      )*/}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            {isLoading && (
              <Box display='flex' justifyContent='center' mt={2}>
                <CircularProgress />
              </Box>
            )}
          </div>
        </TabPanel>
        <TabPanel
          value={postTab}
          index={1}
          sx={{ p: 0 }}
        >
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            gap={1}
          >
            {posts?.map((post, idx) => {
              return (
                <FullMediaItem
                  key={`full-media-item-${idx}`}
                  index={idx}
                  isLastItem={idx + 1 === posts?.length}
                  post={post}
                  userInfo={userInfo}
                  lastElementRef={lastElementRef}
                  handleOnplayVideo={handleOnplayVideo}
                  handleOnPauseOrCompleteVideo={handleOnPauseOrCompleteVideo}
                />
              );
            })}
            {isLoading && (
              <Box display='flex' justifyContent='center' mt={2}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </TabPanel>

      </TabPanel >
      <ViewMediaPostModal
        toggle={toggle}
        onClose={onClose}
        activePost={activePost}
        shareFormInfoResponse={shareFormInfoResponse}
        setShareFormInfoResponse={setShareFormInfoResponse}
        manuallyClicked={manuallyClicked}
      />
    </>
  );
};

interface FullMediaItemProps {
  isLastItem: boolean;
  post: PostObject;
  index: number;
  userInfo?: UserInfo | null;
  handleOnplayVideo: (videoId: number, post: PostObject, second: number, duration: number) => void;
  handleOnPauseOrCompleteVideo: (
    videoId: number,
    seconds: number,
    duration: number,
    post: PostObject
  ) => void;
  lastElementRef: (node: HTMLDivElement | null) => void;
}

const FullMediaItem: React.FC<FullMediaItemProps> = ({
  userInfo,
  isLastItem,
  post,
  index,
  lastElementRef,
  handleOnplayVideo,
  handleOnPauseOrCompleteVideo,
}) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [totalSlide, setTotalSlide] = useState<number>(0);

  return (
    <Card
      ref={isLastItem ? lastElementRef : undefined}
      key={`fmi-${index}`}
      sx={{
        width: "100%",
        boxShadow: 'unset !important',
        p: 0
      }}
      className='we-are-live-post-card'
    >
      <Stack direction='column' gap={1}>
        <Box display='flex' alignItems='center' height='60px' sx={{ pl: 2, pr: 2, pt: 1 }}>
          <Box width='50px'>
            <Avatar alt='pic' src={userInfo?.profile_picture ?? '/static/images/placeholder_profile.png'} />
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
            justifyContent='center'
            flex={1}
          >
            <Typography
              variant='h6'
              component='div'
              className='post-main-title'
              sx={{ color: AppColors.blackColor }}
            >
              {userInfo?.name}
            </Typography>
            <Typography
              variant='h6'
              component='div'
              color='primary'
              fontSize='10px'
              className='post-main-sub-title'
              sx={{ color: AppColors.grayTwoColor }}
            >
              {userInfo?.occupation}
            </Typography>
          </Box>
        </Box>
        <Splide
          key={`media-splide-${post?.id}`}
          options={{
            perPage: 1,
            fixedHeight: 200,
          }}
          onMove={(event) => {
            setActiveSlide(event.index);
          }}
          onReady={(event) => {
            setTotalSlide(event.length);
          }}
        >
          {post?.contents?.map((content, sIdx) => {
            return content?.type === 'image' ? (
              <SplideSlide key={`media-splideslide-${content.id}`}>
                <Box
                  component={'img'}
                  src={content?.link}
                  alt={content?.filename}
                  height='100%'
                  width='100%'
                  sx={{ maxHeight: '50vh' }}
                  className='object-fit-contain'
                />
              </SplideSlide>
            ) : content?.type === 'pdf' ? (
              <SplideSlide key={`media-splideslide-${content.id}`}>
                {activeSlide == sIdx && (
                  <iframe
                    key={`iframe-${content.id}`}
                    src={`https://drive.google.com/viewerng/viewer?pid=explorer&efh=false&a=v&chrome=false&embedded=true&url=${content?.link}`}
                    itemType='application/pdf'
                    width='100%'
                    height='100%'
                    style={{ overflowY: 'scroll', borderRadius: '10px' }}
                  ></iframe>
                )}
              </SplideSlide>
            ) : (
              <SplideSlide key={`media-splideslide-${content.id}`}>
                {content?.platform === 'youtube' ? (
                  <YouTube
                    videoId={content?.link}
                    opts={opts}
                    className='object-fit-contain'
                    style={{
                      height: '100%',
                      width: '100%',
                      maxHeight: '50vh',
                      borderRadius: '10px'
                    }}
                    onPlay={(props: any) => {
                      if (props.target.getCurrentTime() < 1) {
                        handleOnplayVideo(content.id, post, 0, props.target.duration || 0)
                      }
                    }
                    }
                    onEnd={(prop: any) =>
                      handleOnPauseOrCompleteVideo(
                        content.id,
                        prop.target.getCurrentTime(),
                        prop.target.getDuration(),
                        post
                      )
                    }
                    onPause={(prop: any) =>
                      handleOnPauseOrCompleteVideo(
                        content.id,
                        prop.target.getCurrentTime(),
                        prop.target.getDuration(),
                        post
                      )
                    }
                  />
                ) : content?.platform === "vimeo" ? (
                  <Vimeo
                    video={content?.link}
                    className="object-fit-contain vimeo-video-contain"
                    height={"100%"}
                    width={"100%"}
                    style={{
                      height: "100%",
                      width: "100%",
                      maxHeight: '50vh',
                      borderRadius: '10px'
                    }}
                    onPlay={(prop) => handleOnplayVideo(content.id, post, 0, prop.duration)}
                    onEnd={(prop) =>
                      handleOnPauseOrCompleteVideo(
                        content.id,
                        prop?.seconds,
                        prop?.duration || 0,
                        post
                      )
                    }
                    onPause={(prop) =>
                      handleOnPauseOrCompleteVideo(
                        content.id,
                        prop.seconds,
                        prop?.duration || 0,
                        post
                      )
                    }
                  // onPlay={() => handleOnplayVideo(content.id)}
                  />
                ) : (
                  <MediaVideoComponent
                    isDialogOpen={false}
                    width={'100%'}
                    height={'100%'}
                    style={{ maxHeight: '50vh', borderRadius: '10px' }}
                    className={'object-fit-contain'}
                    handleOnplayVideo={(videoId: number, second: number, duration: number) => handleOnplayVideo(videoId, post, second, duration)}
                    handleOnPauseOrCompleteVideo={(videoId: number, time: number, duration: number) =>
                      handleOnPauseOrCompleteVideo(videoId, time, duration, post)
                    }
                    content={content}
                    onVisibilityChange={(visibility: boolean, videoId: number, time: number, duration: number) => {
                      if (!visibility) {
                        handleOnPauseOrCompleteVideo(videoId, time, duration, post)
                      }
                    }}
                  />
                )}
              </SplideSlide>
            );
          })}
        </Splide>
        {post?.contents && post?.contents?.length > 0 && (
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse', pl: 2, pr: 2 }}>
            <Button
              variant='contained'
              sx={{
                backgroundColor: AppColors.primaryColor,
                textTransform: 'none',
                fontSize: "0.8rem",
                fontWeight: 400,
                zIndex: 1000,
                lineHeight: 'normal',
                mt: 0.3,
              }}
            >
              {`${activeSlide + 1}`} of {totalSlide ?? 0}
            </Button>
          </Box>
        )}
        <Box display='flex' alignItems='center' justifyContent='space-between' sx={{ pl: 2, pr: 2 }}>
          <Stack direction='row' color='grey' alignItems='center' gap={1}>
            <RemoveRedEyeOutlinedIcon color='inherit' />
            <Typography variant='body2' component='div' color='inherit' sx={{ color: AppColors.grayTwoColor }}>
              {post?.views_count} Views
            </Typography>
          </Stack>
          <Typography variant='body2' component='div' color='grey' sx={{ color: AppColors.grayTwoColor }}>
            {moment(post?.created_at).format('DD MMMM YYYY')}
          </Typography>
        </Box>
        <Box display='flex' alignItems='flex-start' justifyContent='flex-start' sx={{ pl: 2, pr: 2, pb: 1 }}>
          <Typography variant='body1' component='div' sx={{ color: AppColors.grayThreeColor }}>
            {post?.description}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

export default MediaTabPanel;
