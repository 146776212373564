import React from 'react';

import {
    Avatar,
    Badge,
    Box,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
    Divider
} from '@mui/material';
import { ChatUser } from '../../../store/chat/api/interface';
import { pinUnpinChat } from '../../../client/FireStoreChat';
import { AppColors } from '../../../utils/utils';
import TimeAgo from 'timeago-react';

interface IProps {
    chatUser: ChatUser;
    loginUserId?: number;
    setChatUser: (chatUser: ChatUser) => void,
    lastElementRef?: any
    hidePinButton?: Boolean
}

const UserListForMessage: React.FC<IProps> = ({ chatUser, loginUserId, setChatUser, lastElementRef, hidePinButton }) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleSelectChat = () => {
        setChatUser(chatUser);
    }

    const handlePinUnpinChat = () => {
        pinUnpinChat(chatUser, Array.isArray(chatUser?.pined_by) && !chatUser?.pined_by?.includes(loginUserId ?? 0), loginUserId ?? 0);
        handleClose();
    }

    return (
        <>
            <ListItem alignItems='flex-start' sx={{ pl: 0, pb: 0.5, pt: 0.5, pr: 0, position: 'relative' }} ref={lastElementRef}
            >
                <ListItemButton onClick={handleSelectChat}>
                    <ListItemAvatar>
                        <Avatar
                            alt={chatUser.name}
                            src={chatUser.profile_picture ?? '/static/images/placeholder_profile.png'}
                            sx={{ width: 50, height: 50 }}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ ml: 2 }}
                        primary={
                            <Typography
                                sx={{
                                    fontWeight: '500',
                                    display: 'inline',
                                    color: AppColors.blackColor,
                                }}
                                component='span'
                            >
                                {chatUser.first_name ?? ''} {chatUser.last_name ?? ''}
                            </Typography>
                        }
                        secondary={
                            <React.Fragment>
                                <Box
                                    component={'div'}
                                    sx={{
                                        alignItems: 'start',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            display: '-webkit-box',
                                            color: AppColors.grayTwoColor,
                                            overflow: 'hidden',
                                            fontSize: "0.9rem",
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 1,
                                        }}
                                        component='span'
                                    >
                                        {chatUser.lastMessage?.message}
                                    </Typography>

                                    <Typography sx={{ fontSize: '0.8rem', textAlign: 'end', color: AppColors.grayTwoColor }}>
                                        {chatUser.lastMessage?.time && chatUser.lastMessage?.from != 0 && <TimeAgo datetime={chatUser.lastMessage?.time.toDate() ?? ''} />}
                                    </Typography>
                                </Box>
                            </React.Fragment>
                        }
                    />
                </ListItemButton>
                {(chatUser.lastMessage?.from != loginUserId ? (
                    <Box sx={{ position: 'absolute', right: 10, top: 10 }} >
                        <Badge
                            component={'span'}
                            badgeContent={chatUser.lastMessage?.unread}
                            color='error'
                            sx={{ mr: 1 }}
                        >
                        </Badge>
                    </Box>
                ) : <></>)}
            </ListItem >
            <Divider sx={{ background: AppColors.strokeColor }} />
        </>
    );
};

export default UserListForMessage;
