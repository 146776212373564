import { createSlice } from '@reduxjs/toolkit';
import { getCompanyExternalLinks } from './api';
import { initialState } from './api/interface';

const companyExternalLinksSlice = createSlice({
    name: 'companyExternalLinks',
    initialState,
    reducers: {
        reset: () => initialState,
        companyExternalLinkCreated: (state, action) => {
            state.data.push(action.payload);
        },
        companyExternalLinkDeleted: (state, action) => {
            let array = [...state.data];
            let position = -1;
            array.forEach((item, index) => {
                if (item.id == action.payload.id) {
                    position = index
                }
            });

            if (position !== -1) {
                array.splice(position, 1);
                state.data = array;
            }
        },
        companyExternalLinkUpdated: (state, action) => {
            state.data.forEach((item, index) => {
                if (action.payload.id == item.id) {
                    state.data[index] = action.payload;
                }
            })
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getCompanyExternalLinks.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getCompanyExternalLinks.fulfilled, (state, action) => {
            state.data = action?.payload?.data?.company_external_links ?? [];
            state.status.code = action?.payload?.data?.status?.code ?? '';
            state.status.description = action?.payload?.data?.status?.description ?? '';
            state.isError = false;
            state.isSuccess = true;
            state.isLoading = false;
        });
        builder.addCase(getCompanyExternalLinks.rejected, (state, _) => {
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = false;
        });
    },
});

export const { companyExternalLinkCreated, companyExternalLinkDeleted, companyExternalLinkUpdated, reset } = companyExternalLinksSlice.actions;
export default companyExternalLinksSlice.reducer;
