import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Transition } from './Transition';
import { useFormik } from 'formik';
import { useAppDispatch } from '../store';
import { UserInfo } from '../store/userInfo/api/interface';
import addSessionNoteSchema from '../validation/AddSessionNoteSchema';
import {
  patchKnoCardLinkSessionNoteRequest,
  postKnoCardLinkSessionNoteRequest,
} from '../store/knocardLinkSessionNote/api';
import {
  KnoCardLinkSessionNoteItem,
  PostKnoCardLinkSessionNote,
} from '../store/knocardLinkSessionNote/api/interface';
import { useEffect } from 'react';
import {
  newNoteCreated,
  noteUpdated,
} from '../store/knocardLinkSessionNote/knocardLinkSessionNoteSlice';
import { AppColors } from '../utils/utils';

interface IProps {
  toggle: boolean;
  onClose: () => void;
  selectedSessionUser: UserInfo | null | undefined;
  selectedNoteDetails?: KnoCardLinkSessionNoteItem | undefined;
}

const AddSessionNoteModal: React.FC<IProps> = ({
  toggle,
  onClose,
  selectedSessionUser,
  selectedNoteDetails,
}) => {
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    values,
    resetForm,
    isSubmitting,
    errors,
    touched,
    handleChange,
    setFieldValue,
  } = useFormik<PostKnoCardLinkSessionNote>({
    initialValues: {
      id: null,
      note: '',
      to_user_id: selectedSessionUser?.id ?? 0,
    },
    validationSchema: addSessionNoteSchema,
    onSubmit: async (values) => {
      try {
        if (!values?.id) {
          delete values?.id;
          var result = await dispatch(
            postKnoCardLinkSessionNoteRequest(values)
          ).unwrap();
          if (result.data.data != null) {
            dispatch(newNoteCreated(result.data.data));
          }
        } else {
          var result = await dispatch(
            patchKnoCardLinkSessionNoteRequest(values)
          ).unwrap();
          if (result.data.data != null) {
            dispatch(noteUpdated(result.data.data));
          }
        }
        onClose();
        resetForm();
      } catch (error: any) { }
    },
  });

  useEffect(() => {
    setFieldValue('note', selectedNoteDetails?.note);
    setFieldValue('id', selectedNoteDetails?.id);
  }, [selectedNoteDetails]);

  return (
    <Dialog
      fullWidth
      open={toggle}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        resetForm();
        onClose();
      }}
      aria-describedby='alert-dialog-slide-description'
      key={`note${selectedNoteDetails?.id ?? 0}`}
    >
      <DialogTitle
        sx={{
          backgroundColor: AppColors.primaryColor,
          color: 'white',
        }}
      >
        {selectedNoteDetails ? "Edit Session Note" : "Add Session Note"}
      </DialogTitle>
      <form
        onSubmit={handleSubmit}
        autoComplete='off'
      >
        <DialogContent>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={12}
            ></Grid>
          </Grid>

          <TextField
            id='note'
            name='note'
            label='Note'
            fullWidth
            onChange={handleChange}
            value={values.note}
            error={Boolean(errors.note && touched.note)}
            helperText={Boolean(errors.note && touched.note) && errors.note}
          />
        </DialogContent>
        <DialogActions sx={{ paddingX: 3 }}>
          <LoadingButton
            type='submit'
            loading={isSubmitting}
            variant='contained'
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddSessionNoteModal;
