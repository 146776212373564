import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './api/interface';
import { getNetworkLandingPageVideos } from './api';
import _ from 'lodash';

const networkLandingPageVideoSlice = createSlice({
  name: 'networkLandingPageVideo',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getNetworkLandingPageVideos.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getNetworkLandingPageVideos.fulfilled, (state, action) => {

      let overviewPosition: number = action.payload.data.data.findIndex(item => item.type == 'intro');


      if (overviewPosition >= 0) {
        state.overview = action.payload.data.data[overviewPosition];
      }

      state.data = action.payload.data.data.filter(obj => obj.type != "intro").sort((a, b) => a.position - b.position);;

      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getNetworkLandingPageVideos.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset } = networkLandingPageVideoSlice.actions;
export default networkLandingPageVideoSlice.reducer;
