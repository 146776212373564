import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, IconContainerProps, Rating, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/system';
import { Transition } from './Transition';
import { useFormik, ErrorMessage, Form } from 'formik';
import reviewFormSchema from '../validation/reviewFormSchema';
import { useAppDispatch, useAppSelector } from '../store';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IReviewFormState } from '../store/reviewForm/api/interface';
import { ContactInfo as IContactInfo } from '../store/contacts/api/interface';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { getActiveIndex } from '../utils/getActiveIndex';
import {
  getReviewRequestDetail,
  postReviewForm,
} from '../store/reviewForm/api';
import { AppColors, config } from '../utils/utils';

import { newReviewCreated } from '../store/reviewForm/reviewFormSlice';
import { cleanPhoneNumber, showErrorMessage } from '../utils/utilMethods';

interface IProps {
  toggle: boolean;
  onClose: () => void;
  onRatingSuccess: () => void;
}

const ReviewFormModal: React.FC<IProps> = ({
  toggle,
  onClose,
  onRatingSuccess,
}) => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.userInfo.data);
  const loginUser = useAppSelector((state) => state?.auth?.user);
  const reviewRequestDetail = useAppSelector(
    (state) => state?.reviewForm?.reviewRequestDetail
  );
  const [searchParams] = useSearchParams();

  ///const navigate = useNavigate();

  const textFieldStyle = {
    '& label': {
      color: AppColors.grayThreeColor,
    },
    '& .MuiOutlinedInput-root input': {
      color: AppColors.grayThreeColor,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: ` ${AppColors.strokeColor} !important`,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: `${AppColors.primaryColor} !important`,
    },
    '& .MuiInputLabel-shrink': {
      backgroundColor: AppColors.grayZeroColor,
      paddingLeft: '5px',
      paddingRight: '5px',
      borderRadius: '5px',
    },
    '& .MuiOutlinedInput-root': {
      background: AppColors.grayZeroColor,
      '& fieldset': {
        borderColor: AppColors.strokeColor,
      },
      '&:hover fieldset': {
        borderColor: AppColors.strokeColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: AppColors.strokeColor,
      },
    },
  };

  const {
    handleSubmit,
    values,
    resetForm,
    isSubmitting,
    errors,
    touched,
    handleChange,
    setFieldValue,
  } = useFormik<IReviewFormState>({
    initialValues: {
      user_id: userInfo?.id ?? 0,
      rating: null,
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      experience: null,
      recommend: null,
      message: '',
      agree_to_publish: null,
      rater_id: null,
      rater_code: null,
    },
    validationSchema: reviewFormSchema,
    onSubmit: async (values) => {
      try {
        const sc = searchParams.get('sc');
        const response = await dispatch(
          postReviewForm({
            ...values,
            rater_code:
              reviewRequestDetail?.data?.targetable_type == 'App\\Models\\Contact'
                ? (reviewRequestDetail.data.share_to as IContactInfo)
                  ?.contact_code ?? null
                : null,
            rater_id:
              reviewRequestDetail?.data?.targetable_type != 'App\\Models\\Contact'
                ? `${loginUser?.id ?? ''}`
                : null,
          })
        ).unwrap();
        if (response && response.data && response.data.data) {
          dispatch(newReviewCreated(response.data.data));
          onRatingSuccess();
          onClose();
          resetForm();
          setterForSenderId();
        } else {
          showErrorMessage('Something went wrong please try again.');
        }
      } catch (error: any) { }
    },
  });

  function setterForSenderId() {
    setFieldValue('user_id', userInfo?.id ?? 0);
  }

  useEffect(() => {
    if (userInfo?.id) {
      setterForSenderId();

      dispatch(
        getReviewRequestDetail({
          userId: userInfo.id,
          shareCode: searchParams.get('share_code') ?? '',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (reviewRequestDetail?.data?.share_to) {
      setFieldValue(
        'first_name',
        reviewRequestDetail.data.share_to?.first_name ?? ''
      );
      setFieldValue(
        'last_name',
        reviewRequestDetail.data.share_to?.last_name ?? ''
      );
      setFieldValue('phone_number', cleanPhoneNumber(reviewRequestDetail.data.share_to?.phone_number ?? ''));
    }
  }, [reviewRequestDetail]);

  return (
    <Dialog
      fullWidth
      open={toggle}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        resetForm();
        setterForSenderId();
        onClose();
      }}
      PaperProps={{
        sx: {
          borderRadius: '25px !important',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        },
      }}
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle
        sx={{
          backgroundImage: 'url("/static/images/header_bg.svg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          pt: 1,
          pb: 1,
        }}
      >
        <Stack flexDirection={'row'} alignItems={'center'}>
          <Box
            component={'img'}
            src={'/static/images/white_logo.svg'}
            sx={{ height: '30px' }}
          ></Box>
          <Divider
            orientation='vertical'
            flexItem
            sx={{ borderColor: AppColors.whiteColor, ml: 1, mr: 1 }}
          />
          <Typography sx={{ fontWeight: '500', color: AppColors.whiteColor }}>
            Reviews
          </Typography>
        </Stack>
      </DialogTitle>
      <form onSubmit={handleSubmit} autoComplete='off'>
        <DialogContent
          sx={{
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            p: 2,
          }}
        >
          <Typography sx={{ fontWeight: '500', color: AppColors.primaryColor }}>
            Customer Review Form
          </Typography>
          <Typography sx={{ fontSize: '0.9rem' }}>
            I value your feedback. Please take a moment to review your
            experience with me.
          </Typography>

          <Stack direction='column' spacing={2} sx={{ mt: 2 }}>
            <Grid container>
              <Grid
                item
                sm={6}
                xs={12}
                sx={{ p: 1, pl: { xs: 0, sm: 1 }, pr: { xs: 0, sm: 1 } }}
              >
                <TextField
                  id='first_name'
                  name='first_name'
                  label='First Name*'
                  fullWidth
                  onChange={handleChange}
                  value={values.first_name}
                  error={Boolean(errors.first_name && touched.first_name)}
                  helperText={
                    Boolean(errors.first_name && touched.first_name) &&
                    errors.first_name
                  }
                  sx={{ ...textFieldStyle }}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                sx={{ p: 1, pl: { xs: 0, sm: 1 }, pr: { xs: 0, sm: 1 } }}
              >
                <TextField
                  id='last_name'
                  name='last_name'
                  label='Last Name'
                  fullWidth
                  onChange={handleChange}
                  value={values.last_name}
                  error={Boolean(errors.last_name && touched.last_name)}
                  helperText={
                    Boolean(errors.last_name && touched.last_name) &&
                    errors.last_name
                  }
                  sx={{ ...textFieldStyle }}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                sx={{ p: 1, pl: { xs: 0, sm: 1 }, pr: { xs: 0, sm: 1 } }}
              >
                <TextField
                  type='tel'
                  name='phone_number'
                  id='phone_number'
                  label='Mobile Number*'
                  fullWidth
                  onChange={handleChange}
                  value={values.phone_number}
                  error={Boolean(errors.phone_number && touched.phone_number)}
                  helperText={
                    Boolean(errors.phone_number && touched.phone_number) &&
                    errors.phone_number
                  }
                  sx={{ ...textFieldStyle }}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                sx={{ p: 1, pl: { xs: 0, sm: 1 }, pr: { xs: 0, sm: 1 } }}
              >
                <TextField
                  type='email'
                  name='email'
                  id='email'
                  label='Email Address'
                  fullWidth
                  value={values.email}
                  onChange={handleChange}
                  error={Boolean(errors.email && touched.email)}
                  helperText={
                    Boolean(errors.email && touched.email) && errors.email
                  }
                  sx={{ ...textFieldStyle }}
                />
              </Grid>
            </Grid>
            <Typography sx={{ fontWeight: '500', color: AppColors.primaryColor }}>
              Overall Experience
            </Typography>
            <Box>
              <Rating
                name='experience'
                max={10}
                size='large'
                defaultValue={values.experience ?? undefined}
                onChange={(event, newValue) => {
                  setFieldValue('experience', newValue);
                }}
                IconContainerComponent={(props: IconContainerProps) => {
                  const { value, ...other } = props;
                  return (
                    <Box
                      sx={{
                        background: AppColors.grayZeroColor,
                        color: 'black',
                        p: { xs: 0.55, sm: 2 },
                        m: 0.2,
                      }}
                    >
                      <Typography {...other}>{value}</Typography>
                    </Box>
                  );
                }}
                getLabelText={(value: number) => `${value}`}
                precision={1}
                highlightSelectedOnly
              />
              <Stack
                direction='row'
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ color: AppColors.redColor }}>Poor</Typography>
                <Typography sx={{ color: AppColors.greenColor }}>Excellent</Typography>
              </Stack>
              {errors.experience && touched.experience && (
                <Typography sx={{ color: AppColors.redColor, fontSize: '0.75rem' }}>
                  {errors.experience}
                </Typography>
              )}
            </Box>

            <Typography sx={{ fontWeight: '500', color: AppColors.primaryColor }}>
              Quality
            </Typography>
            <Typography
              sx={{
                fontSize: '0.9rem',
                marginTop: '0px !important',
                color: AppColors.grayThreeColor,
              }}
            >
              Rate your quality of service provided.
            </Typography>
            <Box sx={{ marginTop: '5px !important' }}>
              <Rating
                name='rating-selection'
                size='large'
                defaultValue={values.rating ?? undefined}
                onChange={(event, newValue) => {
                  setFieldValue('rating', newValue);
                }}
                sx={{ color: AppColors.orangeColor }}
                precision={0.5}
              />
              {errors.rating && touched.rating && (
                <Typography sx={{ color: AppColors.redColor, fontSize: '0.75rem' }}>
                  {errors.rating}
                </Typography>
              )}
            </Box>

            <Typography sx={{ fontWeight: '500', color: AppColors.primaryColor }}>
              Would you recommend me to a friend?
            </Typography>
            <Box sx={{ marginTop: '0px !important' }}>
              <Stack
                direction={'row'}
                sx={{ marginTop: '10px !important', pb: 1 }}
              >
                <Button
                  variant='contained'
                  startIcon={<ThumbUpIcon sx={{ color: AppColors.greenColor }} />}
                  sx={{
                    mr: 1,
                    background: values.recommend == 1 ? 'primary' : AppColors.grayZeroColor,
                    color: values.recommend == 1 ? AppColors.whiteColor : AppColors.blackColor,
                    '&:hover': { background: AppColors.grayZeroColor, color: AppColors.blackColor },
                  }}
                  onClick={() => setFieldValue('recommend', 1)}
                >
                  Yes
                </Button>
                <Button
                  variant='contained'
                  startIcon={<ThumbDownIcon sx={{ color: AppColors.redColor }} />}
                  sx={{
                    background: values.recommend == 0 ? AppColors.primaryColor : AppColors.grayZeroColor,
                    color: values.recommend == 0 ? AppColors.whiteColor : AppColors.blackColor,
                    '&:hover': { background: AppColors.grayZeroColor, color: AppColors.blackColor },
                  }}
                  onClick={() => setFieldValue('recommend', 0)}
                >
                  No
                </Button>
              </Stack>
              {errors.recommend && touched.recommend && (
                <Typography sx={{ color: '#FF4842', fontSize: '0.75rem' }}>
                  {errors.recommend}
                </Typography>
              )}
            </Box>

            <TextField
              id='message'
              name='message'
              label='Comments'
              fullWidth
              multiline
              minRows={4}
              maxRows={6}
              onChange={handleChange}
              value={values.message}
              error={Boolean(errors.message && touched.message)}
              helperText={
                Boolean(errors.message && touched.message) && errors.message
              }
              sx={{ ...textFieldStyle }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={values.agree_to_publish == 1 ? true : false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(
                      'agree_to_publish',
                      event.target.checked ? 1 : 0
                    )
                  }
                />
              }
              sx={{
                color:
                  errors.agree_to_publish && touched.agree_to_publish
                    ? AppColors.redColor
                    : AppColors.grayThreeColor,
                '&:span': { fontSize: '0.9rem' },
              }}
              label='I agree that my review can be published on the KnoCard Web app and website'
            />

            <Stack direction='row' spacing={2} justifyContent={'center'}>
              <LoadingButton
                sx={{
                  borderRadius: '12px',
                  minWidth: '100%',
                  borderColor: '#ffffff',
                  backgroundColor: AppColors.primaryColor,
                  color: '#ffffff',
                  textTransform: 'unset',
                  fontWeight: '500',
                  '&:hover': {
                    backgroundColor: AppColors.primaryColor,
                    borderColor: '#ffffff',
                  },
                  '& span span': {
                    color: '#ffffff',
                  },
                }}
                type='submit'
                loading={isSubmitting}
                variant='outlined'
              >
                Submit
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            paddingX: 3,
            backgroundColor: AppColors.primaryColor,
            justifyContent: 'center',
          }}
        >
          <Box
            component={'img'}
            src={'/static/images/white_logo.svg'}
            sx={{ height: '30px' }}
          ></Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ReviewFormModal;
