import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../../store';
import styles from '../../styles/Reports.module.css';
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { getDaysFromFilter } from '../../utils/daysConvertor';
import { getWebAppShare } from '../../store/reports/api';
import WebAppShareItem from '../Card/WebAppShareItem';
import { getItem } from '../../utils/localStorage';
import { config } from '../../utils/utils';

interface IProps {
  selectedTimeFilter: string;
  isFromKnoCardLink?: boolean;
  selectedReportUser?: number;
}

const WebAppShare: React.FC<IProps> = ({
  selectedTimeFilter,
  isFromKnoCardLink,
  selectedReportUser,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);
  const accessToken = getItem(config.AUTH_KEY);
  const webAppShareData =
    useAppSelector((state) => state?.reports?.webAppShareData?.data) ?? [];
  const page =
    useAppSelector((state) => state?.reports?.webAppShareData?.current_page) ??
    0;
  const lastPage =
    useAppSelector((state) => state?.reports?.webAppShareData?.last_page) ?? 0;
  const isLoading = useAppSelector((state) => state?.reports?.isLoading) ?? 0;

  const [selectedSortFilter, setSelectedSortFilter] = useState('all');
  const reportSortList = [
    { label: 'All', value: 'all' },
    { label: 'Most Share', value: 'most_share' },
    { label: 'Most Recent', value: 'most_recent' },
  ];

  const handleLoadWebAppShare = (isLoadMore: boolean) => {
    dispatch(
      getWebAppShare({
        data: {
          user_id: selectedReportUser ? selectedReportUser : user?.id ?? 0,
          api_token: accessToken ?? '',
          timeline: getSortAs(),
          days: getDaysFromFilter(selectedTimeFilter),
          ...(isFromKnoCardLink && { isFromKnoCardLink: true }),
        },
        page: isLoadMore ? page + 1 : 1,
      })
    ).unwrap();
  };
  useEffect(() => {
    handleLoadWebAppShare(false);
  }, [selectedTimeFilter, selectedSortFilter]);

  const handleChangeSortFilter = (event: SelectChangeEvent) => {
    setSelectedSortFilter(event.target.value as string);
  };

  const getSortAs = () => {
    var sortAs = 'most_recent';
    if (selectedSortFilter === 'all') {
      sortAs = 'most_recent';
    } else if (selectedSortFilter === 'most_recent') {
      sortAs = 'most_recent';
    } else if (selectedSortFilter === 'most_views') {
      sortAs = 'most_views';
    } else if (selectedSortFilter === 'most_share') {
      sortAs = 'most_share';
    } else if (selectedSortFilter === 'most_referral') {
      sortAs = 'most_referral';
    } else {
      sortAs = 'most_recent';
    }
    return sortAs;
  };

  return (
    <>
      <Box className={styles.resultsContainerHeader}>
        <Typography className={styles.activityBtn}>Personal Shares</Typography>
        <Select
          id='time-filter-select'
          value={selectedSortFilter}
          onChange={handleChangeSortFilter}
          IconComponent={() => (
            <img
              src='/static/images/arrow_down.svg'
              alt='arrow_down'
              className={styles.arrowDown}
            />
          )}
          className={styles.activityFilterDropDown}
        >
          {reportSortList.map((item: any) => {
            return (
              <MenuItem value={item.value}>
                <Box className={styles.activityFilterDropDownText}>
                  {item.label}
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </Box>

      <Box className={styles.resultsListContainer}>
        {webAppShareData &&
          webAppShareData.map((data, index: number) => (
            <WebAppShareItem
              key={index}
              webAppShareItemModel={data}
              index={index}
            />
          ))}

        {page < lastPage && !isLoading && (
          <Box>
            <Button
              type='button'
              variant='contained'
              sx={{ m: 2, textTransform: 'initial' }}
              autoCapitalize={'none'}
              onClick={() => handleLoadWebAppShare(true)}
            >
              Load More
            </Button>
          </Box>
        )}

        {isLoading && (
          <Box>
            {' '}
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default WebAppShare;
