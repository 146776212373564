import React, { FC, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import { useCollapse } from 'react-collapsed';
import styles from '../../styles/Reports.module.css';
import { LeadView } from '../../store/reports/api/interface';
import { dateFormate } from '../../utils/format';
import { AppColors } from '../../utils/utils';
import TimeAgo from 'timeago-react';
import MailIcon from '../../assets/reports/mail.svg';
import CallIcon from '../../assets/reports/call.svg';
import PreferredPartnerIcon from '../../assets/reports/preferred_partner.svg';
import BusinessIcon from '../../assets/reports/business.svg';

interface IProps {
  leadView: LeadView;
  index: number;
}

const NewProspectsItem: React.FC<IProps> = ({ leadView, index }) => {
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <Box style={{ width: '100%' }}>
      <Box
        className={styles.itemContainer}
        sx={{
          background: AppColors.grayZeroColor,
          border: `solid 1px ${AppColors.strokeColor}`,
          mt: 0.6,
          borderRadius: "6px"
        }}
        {...getToggleProps({
          onClick: () => {
            setExpanded(!isExpanded);
          },
        })}
      >
        <Box
          component={"img"}
          src={
            leadView?.user?.profile_picture ??
            '/static/images/placeholder_profile.png'
          }
          alt='knoImage'
          className={styles.knoSmilie}

        />
        <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
          <Typography sx={{ fontSize: "1rem", fontWeight: 400 }}>
            {leadView.first_name ?? ''} {leadView.last_name ?? ''}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", color: AppColors.grayTwoColor }}>
            {dateFormate(leadView.created_at?.toString() ?? '')}
            {/* <TimeAgo datetime={data.created_at ?? ''} /> */}
          </Typography>
        </Box>

        {isExpanded ? (
          <img
            src='/static/images/arrow_down.svg'
            alt='chevron_down'
            className={styles.chevronDown}
            style={{ transform: `rotate(180deg)` }}
          />
        ) : (
          <img
            src='/static/images/arrow_down.svg'
            alt='chevron_down'
            className={styles.chevronDown}
          />
        )}
      </Box>
      <Box {...getCollapseProps()} sx={{ ml: 4 }}>
        <Box className={styles.subitemContainer} sx={{
          mt: 0.5,
          background: AppColors.grayZeroColor,
          border: `solid 1px ${AppColors.strokeColor}`
        }}
        >
          <Box component={'img'} src={CallIcon} className={styles.muiIconSize} />
          <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
            <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
              Phone Number
            </Typography>
            <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>
              {leadView.phone_number ?? ''}
            </Typography>
          </Box>
          &nbsp;
        </Box>
        <Box
          className={styles.subitemContainer}
          sx={{
            mt: 0.5,
            background: AppColors.grayZeroColor,
            border: `solid 1px ${AppColors.strokeColor}`
          }}
        >
          <Box component={'img'} src={MailIcon} className={styles.muiIconSize} />
          <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
            <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>Email</Typography>
            <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>
              {leadView.email ?? ''}
            </Typography>
          </Box>
          &nbsp;
        </Box>
        <Box className={styles.subitemContainer}
          sx={{
            mt: 0.5,
            background: AppColors.grayZeroColor,
            border: `solid 1px ${AppColors.strokeColor}`
          }}>
          <Box component={'img'} src={PreferredPartnerIcon} className={styles.muiIconSize} sx={{ height: "24px", width: "24px" }} />
          <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
            <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>
              Where I met you?
            </Typography>
            <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>
              {leadView.where_i_met_you ?? ''}
            </Typography>
          </Box>
          &nbsp;
        </Box>
        <Box
          className={styles.subitemContainer}
          sx={{
            mt: 0.5,
            background: AppColors.grayZeroColor,
            border: `solid 1px ${AppColors.strokeColor}`
          }}
        >
          <Box component={'img'} src={BusinessIcon} className={styles.muiIconSize} />
          <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
            <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>Occupation</Typography>
            <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>
              {leadView.occupation ?? ''}
            </Typography>
          </Box>
          &nbsp;
        </Box>
        <Box className={styles.subitemContainer}
          sx={{
            mt: 0.5,
            background: AppColors.grayZeroColor,
            border: `solid 1px ${AppColors.strokeColor}`
          }}>
          <EditIcon className={styles.muiIconSize} sx={{ color: AppColors.primaryColor, height: "1.5rem" }} />
          <Box className={styles.itemContainerContent} sx={{ flexGrow: 1, }}>
            <Typography sx={{ fontSize: "0.9rem", fontWeight: 400 }}>Notes</Typography>
            <Typography sx={{ fontSize: "0.8rem", fontWeight: 400, color: AppColors.grayTwoColor }}>
              {leadView.notes ?? ''}
            </Typography>
          </Box>
          &nbsp;
        </Box>
      </Box>
    </Box >
  );
};

export default NewProspectsItem;
