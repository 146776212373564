import { STATUS_TYPE } from '../../../enum';
import { UserInfo } from '../../userInfo/api/interface';

export interface CustomLandingPageResponse {
  status: {
    code: string;
    description: string;
  };
  data?: CustomLandingPageItem;
}

export interface CustomLandingPageItem {
  id: number;
  user_id: number;
  page_data?: string;
  created_at?: Date;
  updated_at?: Date;
  user?: UserInfo;
}



interface InitialState {
  data?: CustomLandingPageItem;
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}


export const initialState: InitialState = {
  data: undefined,
  status: 'idle',
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
};
