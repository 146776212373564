import { LoadingButton } from "@mui/lab";
import { Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/system";
import { Transition } from "../Transition";
import React, { useEffect, useRef, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import { MediaPostContent } from "../../store/post/api/interface";
import vimeoLinkPostSchema from "../../validation/VimeoLinkPostSchema";
import { vimeoVideoRegex } from "../../constant";
import { MediaType, PlatformType } from "../../enum";
import { getVimeoThumbnailUrl } from "../../utils/youtubeThumb";
import { getCapitalizedString, inputTextFieldStyle, showErrorMessage } from "../../utils/utilMethods";
import { AppColors } from "../../utils/utils";

interface IProps {
    mediaPostContent?: MediaPostContent;
    toggle: boolean;
    onClose: () => void;
    onAddLink: (mediaPostContent: MediaPostContent) => void
}



interface IInitialState {
    title: string;
    link: string;
}

const AddVimeoModal: React.FC<IProps> = ({ toggle, onClose, onAddLink }) => {

    const formik = useFormik<IInitialState>({
        initialValues: {
            title: '',
            link: ''
        },
        validationSchema: vimeoLinkPostSchema,
        onSubmit: async (values) => {
            try {
                const videoArray = values.link.match(vimeoVideoRegex) ?? [];

                if (videoArray.length >= 4) {
                    let videoId = videoArray[4];

                    onAddLink({
                        type: MediaType.video,
                        link: videoId,
                        title: values.title,
                        description: '',
                        platform: PlatformType.vimeo,
                        thumbnail_link: getVimeoThumbnailUrl(videoId)
                    });
                    onCancel();
                } else {
                    showErrorMessage("Invalid vimeo url")
                }
            } catch (error: any) { }
        },
    });

    const onCancel = () => {
        formik.resetForm();
        onClose();
    };


    return (
        <Dialog
            fullWidth
            open={toggle}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
                formik.resetForm();
            }}
            aria-describedby="alert-dialog-slide-description"
            sx={{
                zIndex: "5000"
            }}
        >
            <DialogTitle
                sx={{
                    backgroundColor: AppColors.primaryColor,
                    color: "white",
                }}
            >
                Add Vimeo video
            </DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <FormikProvider value={formik}>
                    <DialogContent>
                        <Stack
                            spacing={3}
                        >
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>Title</Typography>
                                <TextField
                                    id="title"
                                    name={`title`}
                                    value={
                                        formik.values.title ?? ""
                                    }
                                    onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                                    InputProps={{
                                        sx: inputTextFieldStyle()
                                    }}
                                    onChange={formik.handleChange}
                                    placeholder="Title"
                                    fullWidth
                                    variant="outlined"
                                    error={Boolean(formik.errors.title && formik.touched.title)}
                                    helperText={
                                        Boolean(formik.errors.title && formik.touched.title) &&
                                        formik.errors.title
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>Link</Typography>
                                <TextField
                                    id="link"
                                    name={`link`}
                                    value={
                                        formik.values.link ?? ""
                                    }
                                    onChange={formik.handleChange}
                                    InputProps={{
                                        sx: inputTextFieldStyle()
                                    }}
                                    placeholder="Link"
                                    fullWidth
                                    variant="outlined"
                                    error={Boolean(formik.errors.link && formik.touched.link)}
                                    helperText={
                                        Boolean(formik.errors.link && formik.touched.link) &&
                                        formik.errors.link
                                    }
                                />
                            </Grid>

                        </Stack>
                    </DialogContent>

                </FormikProvider>
                <DialogActions sx={{ paddingX: 3 }}>
                    <LoadingButton
                        type="button"
                        loading={false}
                        variant="contained"
                        onClick={onCancel}
                    >
                        Cancel
                    </LoadingButton>
                    <LoadingButton
                        type="submit"
                        loading={formik.isSubmitting}
                        variant="contained"
                    >
                        Add
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default AddVimeoModal;
