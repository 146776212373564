import { useEffect, useState } from 'react';
import { Grid, Chip, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store';
import { getCompanyExternalLinks, viewCompanyExternalLink } from '../../store/companyExternalLinks/api';
import ExternalLinkButtonIcon from '../ExternalLinkButtonIcon';
import ArrowRight from '../../assets/arrow_right.svg'
import { CompanyExternalLink } from '../../store/companyExternalLinks/api/interface';
import { useSearchParams } from 'react-router-dom';
import { AppColors } from '../../utils/utils';

const TabPanelThree = () => {
  const dispatch = useAppDispatch();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [searchParams] = useSearchParams();

  const loginUser = useAppSelector((state) => state?.auth?.user);
  //const [externalLink, setExternalLink] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /*const {
    toggle: toggleExternalLink,
    onOpen: onOpenExternalLink,
    onClose: onCloseExternalLink,
  } = useToggle();*/

  const {
    data: companyExternalLinks,
    isLoading: isLoadingCompanyExternalLinks,
  } = useAppSelector((state) => state?.companyExternalLinks);
  const companyId = useAppSelector((state) => state.userInfo.data?.company?.id);
  useEffect(() => {
    if (companyId && !isLoadingCompanyExternalLinks && companyExternalLinks.length == 0) {
      dispatch(getCompanyExternalLinks(companyId));
    }
  }, [dispatch, companyId]);

  /*const handleExternalLinkClick = (eLink: string) => {
    setExternalLink(eLink);
    onOpenExternalLink();
  };*/

  const openExternalLink = (companyExternalLink: CompanyExternalLink) => {
    try {
      const sc = searchParams.get('sc');
      dispatch(viewCompanyExternalLink({ company_external_link_id: companyExternalLink.id, viewer_code: sc, viewer_id: loginUser?.id }));
      window.open(`${companyExternalLink.link}`, "_blank") || window.location.replace(`${companyExternalLink.link}`);
    } catch (e) { }
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ mt: 0, mb: 2, ml: 0, width: '100%', minHeight: "100vh" }}
      direction={'column'}
    >
      <Stack spacing={3} textAlign={isLoadingCompanyExternalLinks ? "center" : "start"}>
        {isLoadingCompanyExternalLinks ? (
          <Box
            sx={{
              mt: 1, mb: 1, width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          ><CircularProgress /></Box>
        ) : (<Grid container justifyContent={'center'}>
          {companyExternalLinks?.map((companyExternalLink, idx) => {
            if (companyExternalLink.status === 'ACT') {
              return (
                <Grid
                  sm={4.8}
                  xs={12}
                  item
                  sx={{
                    background: AppColors.whiteColor,
                    width: 'auto',
                    margin: '16px !important',
                    p: 2,
                    borderRadius: '12px',
                    textAlign: isSmallScreen ? 'unset' : 'center',
                    position: 'relative'
                  }}
                  direction={'column'}
                  onClick={() => openExternalLink(companyExternalLink)}
                >
                  <Box display={isSmallScreen ? 'flex' : 'column'}    >
                    <ExternalLinkButtonIcon
                      companyExternalLink={companyExternalLink}
                      sx={isSmallScreen ? {
                        minHeight: "25px",
                        minWidth: "25px",
                        fontSize: "20px"
                      } : {
                        minHeight: "60px",
                        minWidth: "60px",
                        fontSize: "47px"
                      }}
                    />

                    <Typography sx={{ color: AppColors.blackColor, fontSize: "16px", mt: isSmallScreen ? 0 : 2, ml: isSmallScreen ? 2 : 0, mr: isSmallScreen ? 4 : 0 }}>{companyExternalLink.title}</Typography>
                  </Box>
                  <Typography sx={{ color: AppColors.grayThreeColor, fontSize: "14px", marginTop: 1.5, whiteSpace: 'pre-line', mr: isSmallScreen ? 4 : 0 }}>{companyExternalLink?.description ?? ''}</Typography>

                  {isSmallScreen && (<Box
                    component={'img'}
                    src={ArrowRight}
                    sx={{
                      position: 'absolute',
                      top: '35%',
                      right: 20,

                    }}
                  />)}
                </Grid>
              );
            } else {
              return <></>;
            }
          })}
        </Grid>
        )}
      </Stack>
      {/*externalLink.length > 0 && <ExternalLinkModel
        extyernalLink={externalLink}
        onClose={onCloseExternalLink}
        toggle={toggleExternalLink}
      />*/}
    </Grid>
  );
};

export default TabPanelThree;
