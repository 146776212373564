import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TabPanel from '../components/TabPanel';
import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import styles from '../styles/Media.module.css';
import { styled } from '@mui/material/styles';
import TagIcon from '@mui/icons-material/Tag';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import useToggle from '../hooks/useToggle';
import YouTube from 'react-youtube';
import ViewMediaPostModal from '../components/modal/ViewMediaPostModal';
import { useAppDispatch, useAppSelector } from '../store';
import {
  deleteMediaPost,
  getPinedPosts,
  getPosts,
  getShareFormInfo,
  getSinglePost,
  pinMediaPost,
} from '../store/post/api';
import { PostObject, ShareFormInfoResponse } from '../store/post/api/interface';
import { IHomeParams } from '../enum';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  incrementCurrentPage,
  newPinedPost,
  pinPostDeleted,
  postDeleted,
  postUpdated,
} from '../store/post/postSlice';
import { Stack } from '@mui/system';
import moment from 'moment';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import AddMediaModal from '../components/modal/AddMediaModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditMediaModal from '../components/modal/EditMediaModal';
import ConfirmationModel from '../components/modal/ConfirmationModel';

import { checkIsProUser } from '../utils/checkPro';
import BlockOverlay from '../components/BlockOverlay';
import Page from '../components/Page';
import Post from '../components/Card/Post';
import Iconify from '../components/Iconify';
import { isMobile } from '../utils/getMobileOperatingSystem';
import { getTopicList } from '../store/mediaTopic/api';
import { TopicObject } from '../store/mediaTopic/api/interface';
import AddMediaTopicModal from '../components/modal/AddMediaTopicModal';
import { showSuccessMessage } from '../utils/utilMethods';
import Vimeo from '@u-wave/react-vimeo';
import { AppColors } from '../utils/utils';
import AddTopicIcon from '../assets/media/add_topic.svg';
import AddMediaIcon from '../assets/media/add_media.svg';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import playIcon from "../assets/play_circle.svg";

const GalleryTab = styled(Tab)<TabProps>(({ theme }) => ({
  color: 'white',
  fontWeight: 'bold',
  minHeight: '50px',
  '&.Mui-selected': {
    backgroundColor: AppColors.primaryColor,
    color: AppColors.whiteColor,
    borderRadius: "10px"
  },
}));

const opts = {
  height: '100%',
  width: '100%',
  playerVars: {
    // Hide related videos after the video finishes
    rel: 0,
    // Hide the video annotations
    iv_load_policy: 3,
    // Hide the YouTube logo
    modestbranding: 1,
  },
};

const Media: React.FC = () => {
  const params = useParams<IHomeParams>();
  const [postTab, setPostTab] = useState(0);
  const matches = useMediaQuery('(max-width:745px)');
  const { toggle, onOpen, onClose } = useToggle();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.auth?.user?.id);
  const userInfo = useAppSelector((state) => state?.auth?.user);
  const isProUser = checkIsProUser(userInfo);
  const posts = useAppSelector((state) => state?.post.data?.data) ?? [];
  const isLoading = useAppSelector((state) => state?.post?.isLoading);
  const hasMore = useAppSelector((state) => state?.post?.hasMore);
  const currentPage = useAppSelector((state) => state?.post?.currentPage);
  // const pinedData =
  //   useAppSelector((state) => state?.post.pinedData?.data) ?? [];
  // const pinedDataIsLoading = useAppSelector(
  //   (state) => state?.post?.pinedDataIsLoading
  // );
  // const pinedDataHasMore = useAppSelector(
  //   (state) => state?.post?.pinedDataHasMore
  // );
  // const pinedDataCurrentPage = useAppSelector(
  //   (state) => state?.post?.pinedDataCurrentPage
  // );


  const topicData =
    useAppSelector((state) => state?.mediaTopic.data?.data) ?? [];
  const topicIsLoading = useAppSelector(
    (state) => state?.mediaTopic?.isLoading
  );
  const topicHasMore = useAppSelector((state) => state?.mediaTopic?.hasMore);
  const topicCurrentPage = useAppSelector(
    (state) => state?.mediaTopic?.currentPage
  );
  const [isPinedPostExpanded, setIsPinedPostExpanded] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState<number | undefined>(
    undefined
  );
  const [activePost, setActivePost] = useState<PostObject>();
  const [manuallyClicked, setManuallyClicked] = useState<boolean>(false);
  const [shareFormInfoResponse, setShareFormInfoResponse] =
    useState<ShareFormInfoResponse>();
  const observer = useRef<IntersectionObserver>();
  const [searchParams] = useSearchParams();

  const [editPost, setEditPost] = useState<PostObject | undefined>();
  const [deletePost, setDeletePost] = useState<PostObject | undefined>();
  const {
    toggle: toggleMediaForm,
    onOpen: onOpenMediaForm,
    onClose: onCloseMediaForm,
  } = useToggle();

  const {
    toggle: toggleEditMediaForm,
    onOpen: onOpenEditMediaForm,
    onClose: onCloseEditMediaForm,
  } = useToggle();

  const {
    toggle: togglenDeleteMedia,
    onOpen: onOpenDeleteMedia,
    onClose: onCloseDeleteMedia,
  } = useToggle();

  const {
    toggle: toggleAddTopic,
    onOpen: onOpenAddTopic,
    onClose: onCloseAddTopic,
  } = useToggle();

  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [totalSlide, setTotalSlide] = useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activeMenuIndex, setActiveMenuIndex] = React.useState<any>(0);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    idx: number
  ) => {
    setAnchorEl(event.currentTarget);
    setActiveMenuIndex(idx);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePinMenu = async (post: PostObject) => {
    setAnchorEl(null);
    var result = await dispatch(
      pinMediaPost({
        postId: post.id,
        isPin: post.is_pined === 1 ? false : true,
      })
    ).unwrap();
    if (result.data.data != null) {
      dispatch(postUpdated(result.data.data));

      if (post.is_pined === 1) {
        dispatch(pinPostDeleted(result.data.data));
      } else {
        dispatch(newPinedPost(result.data.data));
      }

      showSuccessMessage(
        `Media post ${post.is_pined === 1 ? 'unpinned' : 'pinned'
        } successfully.`
      );
    }
  };

  const handleEditMenu = (post: PostObject) => {
    setEditPost(post);
    onOpenEditMediaForm();
    setAnchorEl(null);
  };

  const handleDeleteMenu = (post: PostObject) => {
    setDeletePost(post);
    onOpenDeleteMedia();
    setAnchorEl(null);
  };

  useEffect(() => {
    if (userId) {
      dispatch(getPosts({ user_id: userId, page: currentPage, topic_id: selectedTopic }));
    }
  }, [currentPage]);


  useEffect(() => {
    if (userId) {
      dispatch(getTopicList({ user_id: userId, page: topicCurrentPage }));
    }
  }, [topicCurrentPage]);


  useEffect(() => {
    if (userId) {
      if (currentPage != 0) {
        dispatch(incrementCurrentPage(0));
      } else {
        dispatch(getPosts({ user_id: userId, page: currentPage, topic_id: selectedTopic }));
      }
    }
  }, [selectedTopic]);

  useEffect(() => {
    const fetchSinglePost = async () => {
      const tab = params?.tab;
      if (tab === undefined || tab !== 'community') {
        const postId = params?.postId;
        if (!postId) return;
        try {
          const post = await dispatch(getSinglePost(postId)).unwrap();
          const form_code = searchParams.get('form_code') ?? '';

          if (form_code) {

            const shareFormInfo = await dispatch(
              getShareFormInfo({
                form_code,
                post_id: post?.data?.data?.id,
                postby_id: post?.data?.data?.user_id,
              })
            ).unwrap();

            if (shareFormInfo.data.data) {
              setShareFormInfoResponse(shareFormInfo.data);
            }
          }

          if (post.data.data) {
            setActivePost(post.data.data);
            onOpen();
          }
        } catch (error) { }
      }
    };
    fetchSinglePost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPostTab(newValue);
  };

  const lastElementRef = useCallback(
    // (*)
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          dispatch(incrementCurrentPage(currentPage + 1));
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  const handleActivePost = (post: PostObject) => {
    const sc = searchParams.get('sc');
    onOpen();
    setManuallyClicked(true);
    setActivePost(post);
  };

  const onDeleteMediaConfirm = async () => {
    onCloseDeleteMedia();
    if (deletePost != undefined) {
      try {
        var result = await dispatch(
          deleteMediaPost({ user_id: userId, postId: deletePost.id })
        ).unwrap();
        if (result.data.status == 'success') {
          dispatch(postDeleted(deletePost));
          showSuccessMessage('Media post deleted successfully.');
          setDeletePost(undefined);
        }
      } catch (error: any) { }
    }
  };

  return (
    <>
      {' '}
      <Page title='Media | KnoCard' showAppBar={true}>
        <Stack>
          {/* <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            width='100%'
            bgcolor={'#00263A'}
            pt={isMobile() ? 1 : 0.5}
          >
            <Grid
              container
              direction={'row'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              spacing={2}
            >
              <Grid md={4} item>
                <hr style={{ height: 3 }} />
              </Grid>
              <Grid item md={4}>
                <Grid
                  container
                  direction={'row'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  spacing={5}
                  onClick={() => setIsPinedPostExpanded(!isPinedPostExpanded)}
                >
                  <Grid item>
                    <Iconify
                      icon={'game-icons:pin'}
                      color='white'
                      height={30}
                      width={30}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant='h6'
                      component='div'
                      color='#83D1D7'
                      fontWeight='bold'
                      flex={1}
                      display='flex'
                      alignItems='center'
                    >
                      Media
                    </Typography>
                  </Grid>
                  <Grid item>
                    {isPinedPostExpanded ? (
                      <ArrowDropUp
                        style={{
                          // height: '1.5rem',
                          // width: '1.5rem',
                          color: '#088ac6',
                          border: '1px solid #088ac6',
                          borderRadius: '30px',
                        }}
                      />
                    ) : (
                      <ArrowDropDown
                        style={{
                          // height: '1.5rem',
                          // width: '1.5rem',
                          color: '#088ac6',
                          border: '1px solid #088ac6',
                          borderRadius: '30px',
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4}>
                <hr style={{ height: 3 }} />
              </Grid>
            </Grid>
          </Box> */}
          {/* {pinedData && pinedData.length > 0 && isPinedPostExpanded && (
            <Box p={1} bgcolor={'#00263A'}>
              <ImageList cols={isMobile() ? 4 : 7} gap={isMobile() ? 5 : 15}>
                {pinedData.map((post: PostObject, index: number) => (
                  <ImageListItem
                    key={post.id}
                    className='preferred-partner-image-border'
                    onClick={() => handleActivePost(post)}
                  >
                    <img
                      src={
                        post?.thumb_link
                          ? post?.thumb_link
                          : post?.contents?.[0]?.type === 'pdf'
                            ? '/static/images/pdf.png'
                            : post?.contents?.[0]?.type === 'video'
                              ? post?.contents?.[0]?.thumbnail ?? ''
                              : post?.contents?.[0]?.link ?? ''
                      }
                      alt=''
                      // className='post-img'
                      loading='lazy'
                      style={{
                        ...(!post?.thumb_link &&
                          post?.contents?.[0]?.type === 'pdf'
                          ? { objectFit: 'contain' }
                          : {}),
                        height: '100%',
                        width: '100%',
                        borderRadius: '8px',
                        maxHeight: isMobile() ? '100px' : '200px',
                        maxWidth: isMobile() ? '100px' : '200px',
                      }}
                    />
                    <ImageListItemBar
                      title={
                        <Typography variant='caption'>
                          {post.description}
                        </Typography>
                      }
                      actionIcon={
                        <Tooltip title={'Unpin'}>
                          <IconButton
                            aria-label='close'
                            onClick={() => handlePinMenu(post)}
                            sx={{
                              color: 'white',
                            }}
                          >
                            <Close
                              sx={{ maxHeight: isMobile() ? '20px' : '100px' }}
                            />
                          </IconButton>
                        </Tooltip>
                      }
                      sx={{
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        maxHeight: isMobile() ? '20px' : '50px',
                      }}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          )} */}

          {(
            <Box
              p={1}
              sx={{
                backgroundColor: '#FDFCFD',
              }}
            >
              <Grid
                container
                spacing={isMobile() ? 1 : 3}
                direction={'row'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                {[
                  { id: -1, title: '', link: '' } as TopicObject,
                  ...topicData,
                ].map((item, index) =>
                  index === 0 ? (
                    <Grid item key={index} display={'flex'}>
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        textAlign={'center'}
                        p={1}
                        sx={{

                          cursor: 'pointer',
                        }}
                        onClick={() => onOpenAddTopic()}
                      >
                        <Box component={'img'} src={AddTopicIcon} sx={{
                          height: '50px',
                          width: '50px'
                        }} />
                        <Typography sx={{ fontSize: '0.8rem', color: AppColors.grayThreeColor, mt: 1 }}>
                          Add Topic
                        </Typography>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      // xs
                      key={index}
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      onClick={() =>
                        setSelectedTopic(
                          selectedTopic !== topicData[index - 1].id
                            ? topicData[index - 1].id
                            : undefined
                        )
                      }
                      sx={{ cursor: 'pointer' }}
                    >
                      <Stack alignItems={'center'}

                        sx={{ background: (selectedTopic === topicData[index - 1].id) ? AppColors.grayZeroColor : 'unset', p: 1, borderRadius: "10px" }}>
                        <img
                          // component='img'
                          height='50px'
                          width='50px'
                          src={item.link}
                          alt={item.title}
                          style={{
                            borderRadius: 100,
                          }}
                        />

                        <Typography sx={{ fontSize: '0.8rem', color: AppColors.grayThreeColor, mt: 1 }}>
                          {item.title}
                        </Typography>
                      </Stack>
                    </Grid>
                  )
                )}
              </Grid>
            </Box>
          )}
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            width='100%'

          //sx={{ backgroundColor: "#f1f2f6" }}
          >
            <Tabs
              value={postTab}
              onChange={handleChange}
              variant='fullWidth'
              sx={{
                width: '100%',
                borderRadius: '10px',
                backgroundColor: AppColors.whiteColor,
                pl: { xs: 1, md: 0 },
                pr: { xs: 1, md: 0 },
              }}

              TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
            >
              <GalleryTab
                icon={
                  <GridViewOutlinedIcon
                    sx={{ color: postTab == 0 ? AppColors.whiteColor : AppColors.blackColor }}
                  />
                }
              />
              <GalleryTab
                icon={
                  <FormatListBulletedIcon
                    sx={{ color: postTab == 1 ? AppColors.whiteColor : AppColors.blackColor }}
                  />
                }
              />
            </Tabs>
          </Box>

          <TabPanel
            value={postTab}
            index={0}
            p='18px 0 0 0'

          >
            <Grid className={styles.mediaContainerGrid}>
              <Box
                className={styles.mediaElementAdd}
                sx={{ backgroundColor: AppColors.whiteColor }}
                onClick={() => isProUser && onOpenMediaForm()}
              >
                <Box
                  component={'img'}
                  src={AddMediaIcon}
                  alt='addMedia'
                  sx={{ width: '40%' }}
                />
                <Typography sx={{ color: AppColors.grayThreeColor, fontSize: '1rem' }} >Add an Image</Typography>
              </Box>

              {posts?.map((post, idx) => {
                return (
                  <Box
                    ref={idx + 1 === posts?.length ? lastElementRef : undefined}
                    key={`post-${idx}`}
                    className={styles.mediaElement}
                    style={{
                      backgroundImage: `url(${post?.thumb_link
                        ? post?.thumb_link
                        : post?.contents?.[0]?.type === 'pdf'
                          ? '/static/images/pdf.png'
                          : post?.contents?.[0]?.type === 'video'
                            ? post?.contents?.[0]?.thumbnail ?? ''
                            : post?.contents?.[0]?.link ?? ''
                        })`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      position: 'relative',
                      ...(!post?.thumb_link &&
                        post?.contents?.[0]?.type === 'pdf'
                        ? {
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain',
                        }
                        : {}),
                      borderRadius: '10px',
                      objectFit: "cover"
                    }}
                  >
                    <Box
                      sx={{ width: '100%', height: '100%' }}
                      onClick={() => handleActivePost(post)}
                    ></Box>

                    {post?.contents?.[0]?.type === 'video' && (
                      <Box
                        component={'img'}
                        src={playIcon}
                        sx={{
                          height: "30%",
                          width: "30%",
                          color: '#dfe2e4',
                          position: 'absolute',
                          top: '35%',
                          left: '35%',
                          fontSize: '4rem',
                        }}
                      />
                    )}


                    {isProUser && (
                      <Box
                        sx={{
                          backgroundColor: '#000000ad',
                          borderRadius: '50%',
                          height: '40px',
                          width: '40px',
                          position: 'absolute',
                          top: '10px',
                          right: '10px',
                        }}
                      >
                        <IconButton
                          aria-label='more'
                          id='long-button'
                          aria-controls={
                            menuOpen && activeMenuIndex == idx
                              ? 'long-menu'
                              : undefined
                          }
                          aria-expanded={
                            menuOpen && activeMenuIndex == idx
                              ? 'true'
                              : undefined
                          }
                          aria-haspopup='true'
                          onClick={(event) => handleMenuClick(event, idx)}
                        >
                          <MoreVertIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <Menu
                          id='long-menu'
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={menuOpen && activeMenuIndex == idx}
                          onClose={handleMenuClose}
                          PaperProps={{
                            style: {
                              maxHeight: 48 * 4.5,
                              width: '20ch',
                            },
                          }}
                        >
                          {/* <MenuItem
                            key={`pin-${idx}`}
                            onClick={() => handlePinMenu(post)}
                          >
                            {post.is_pined == 1 ? 'Unpin' : 'Pin'}
                          </MenuItem> */}
                          <MenuItem
                            key={`edit-media-${idx}`}
                            onClick={() => handleEditMenu(post)}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            key={`delete-media-${idx}`}
                            onClick={() => handleDeleteMenu(post)}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </Box>
                    )}
                  </Box>
                );
              })}
              {posts?.length < 4 &&
                Array(3)
                  .fill(0)
                  .map((item, index) => {
                    return <Box key={`emptyitem-${index}`}></Box>;
                  })}
            </Grid>
            {isLoading && (
              <Box display='flex' justifyContent='center' mt={2}>
                <CircularProgress />
              </Box>
            )}
          </TabPanel>
          <TabPanel
            value={postTab}
            index={1}
            p='25px 0 0 0'
          // backgroundColor="#f1f2f6"
          >
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              gap={1}
            >
              <Box
                className={styles.mediaElementAdd}
                sx={{ backgroundColor: AppColors.whiteColor, width: '100%', pt: 10, pb: 10 }}
                onClick={() => isProUser && onOpenMediaForm()}
              >
                <Box
                  component={'img'}
                  src={AddMediaIcon}
                  alt='addMedia'
                  sx={{ width: '80px' }}
                />
                <Typography sx={{ color: AppColors.grayThreeColor, fontSize: '1rem' }} >Add an Image</Typography>
              </Box>
              {posts?.map((post, idx) => {
                return (
                  <Card
                    ref={idx + 1 === posts?.length ? lastElementRef : undefined}
                    key={idx}
                    sx={{
                      width: "100%",
                      boxShadow: 'unset !important',
                      p: 0
                    }}
                    className='we-are-live-post-card'
                  >
                    <Stack direction='column' gap={1}>
                      <Box display='flex' alignItems='center' height='60px' sx={{ pl: 2, pr: 2, pt: 1 }}>
                        <Box width='50px'>
                          <Avatar alt='pic' src={userInfo?.profile_picture ?? '/static/images/placeholder_profile.png'} />
                        </Box>
                        <Box
                          display='flex'
                          flexDirection='column'
                          alignItems='flex-start'
                          justifyContent='center'
                          flex={1}
                        >
                          <Typography
                            variant='h6'
                            component='div'
                            color='black'
                            className='post-main-title'
                            sx={{ color: AppColors.blackColor }}
                          >
                            {userInfo?.name}
                          </Typography>
                          <Typography
                            variant='h6'
                            component='div'
                            color='primary'
                            fontSize='10px'
                            className='post-main-sub-title'
                            sx={{ color: AppColors.grayTwoColor }}
                          >
                            {userInfo?.occupation}
                          </Typography>
                        </Box>
                        {isProUser && (
                          <Box width='50px'>
                            <IconButton
                              aria-label='more'
                              id='long-button'
                              aria-controls={
                                menuOpen && activeMenuIndex == idx
                                  ? 'long-menu'
                                  : undefined
                              }
                              aria-expanded={
                                menuOpen && activeMenuIndex == idx
                                  ? 'true'
                                  : undefined
                              }
                              aria-haspopup='true'
                              onClick={(event) => handleMenuClick(event, idx)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id='long-menu'
                              MenuListProps={{
                                'aria-labelledby': 'long-button',
                              }}
                              anchorEl={anchorEl}
                              open={menuOpen && activeMenuIndex == idx}
                              onClose={handleMenuClose}
                              PaperProps={{
                                style: {
                                  maxHeight: 48 * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >
                              {/* <MenuItem
                                key={`pin-${idx}`}
                                onClick={() => handlePinMenu(post)}
                              >
                                {post.is_pined == 1 ? 'Unpin' : 'Pin'}
                              </MenuItem> */}
                              <MenuItem
                                key={`edit-media-${idx}`}
                                onClick={() => handleEditMenu(post)}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                key={`delete-media-${idx}`}
                                onClick={() => handleDeleteMenu(post)}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </Box>
                        )}
                      </Box>
                      <Splide
                        key={`media-splide-${post?.id}`}
                        aria-label='Images'
                        options={{
                          perPage: 1,
                          fixedHeight: 390,
                        }}
                        onMove={(event) => {
                          setActiveSlide(event.index);
                        }}
                        onReady={(event) => {
                          setTotalSlide(event.length);
                        }}
                      >
                        {post?.contents?.map((content, sIdx) => {
                          return content?.type === 'image' ? (
                            <SplideSlide key={content.id}>
                              <Box
                                component={'img'}
                                src={content?.link}
                                alt={content?.filename}
                                style={{ maxHeight: '50vh' }}
                                height='100%'
                                width='100%'
                                className='object-fit-contain'
                              />
                            </SplideSlide>
                          ) : content?.type === 'pdf' ? (
                            <SplideSlide key={content.id}>
                              {activeSlide == sIdx && (
                                <iframe
                                  key={`iframe-${content.id}`}
                                  src={`https://drive.google.com/viewerng/viewer?pid=explorer&efh=false&a=v&chrome=false&embedded=true&url=${content?.link}`}
                                  itemType='application/pdf'
                                  width='100%'
                                  height='100%'
                                  style={{ overflowY: 'scroll' }}
                                ></iframe>
                              )}
                            </SplideSlide>
                          ) : (
                            <SplideSlide key={content.id}>
                              {content?.platform === 'youtube' ? (
                                <YouTube
                                  videoId={content?.link}
                                  opts={opts}
                                  className='object-fit-contain'
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    maxHeight: '50vh',
                                  }}
                                />
                              ) : content?.platform === "vimeo" ? (
                                <Vimeo
                                  video={content?.link}
                                  className="object-fit-contain vimeo-video-contain"
                                  height={"100%"}
                                  width={"100%"}
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    maxHeight: '50vh'
                                  }}
                                // onPlay={() => handleOnplayVideo(content.id)}
                                />
                              ) : (
                                <video
                                  controls
                                  width='100%'
                                  height='100%'
                                  style={{ maxHeight: '50vh' }}
                                  className='object-fit-contain'
                                  poster={content?.thumbnail ?? ''}
                                >
                                  <source
                                    src={content?.link}
                                    type='video/mp4'
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                            </SplideSlide>
                          );
                        })}
                      </Splide>
                      {post?.contents && post?.contents?.length > 0 && (
                        <Box
                          sx={{ display: 'flex', flexDirection: 'row-reverse', pl: 2, pr: 2 }}
                        >
                          <Button
                            variant='contained'
                            sx={{
                              backgroundColor: AppColors.primaryColor,
                              textTransform: 'none',
                              fontSize: "0.8rem",
                              fontWeight: 400,
                              zIndex: 1000,
                              lineHeight: 'normal',
                              mt: 0.3,
                            }}
                          >
                            {`${activeSlide + 1}`} of {totalSlide ?? 0}
                          </Button>
                        </Box>
                      )}
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Stack
                          direction='row'
                          color='grey'
                          alignItems='center'
                          gap={1}
                        >
                          <RemoveRedEyeOutlinedIcon color='inherit' />
                          <Typography
                            variant='body2'
                            component='div'
                            color='inherit'
                            sx={{ color: AppColors.grayTwoColor }}
                          >
                            {post?.views_count} Views
                          </Typography>
                        </Stack>
                        <Typography
                          variant='body2'
                          component='div'
                          sx={{ color: AppColors.grayTwoColor }}
                        >
                          {moment(post?.created_at).format('DD MMMM YYYY')}
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        alignItems='flex-start'
                        justifyContent='flex-start'
                        sx={{ pl: 2, pr: 2, pb: 1 }}
                      >
                        <Typography
                          variant='body1'
                          component='div'
                          sx={{ color: AppColors.grayThreeColor }}
                        >
                          {post?.description}
                        </Typography>
                      </Box>
                    </Stack>
                  </Card>
                );
              })}
              {isLoading && (
                <Box display='flex' justifyContent='center' mt={2}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </TabPanel>
          <BlockOverlay isBlock={!isProUser} />
        </Stack>
      </Page >
      <ViewMediaPostModal
        toggle={toggle}
        onClose={onClose}
        activePost={activePost}
        shareFormInfoResponse={shareFormInfoResponse}
        setShareFormInfoResponse={setShareFormInfoResponse}
        manuallyClicked={manuallyClicked}
      />
      {
        editPost && isProUser && toggleEditMediaForm && (
          <EditMediaModal
            toggle={toggleEditMediaForm}
            onClose={onCloseEditMediaForm}
            postObject={editPost}
          />
        )
      }
      {
        isProUser && toggleMediaForm && (
          <AddMediaModal onClose={onCloseMediaForm} toggle={toggleMediaForm} />
        )
      }
      <ConfirmationModel
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        title='Delete Media'
        message='Are you sure! Do you want to delete this media?'
        onConfirm={onDeleteMediaConfirm}
        onClose={onCloseDeleteMedia}
        toggle={togglenDeleteMedia}
      />
      <AddMediaTopicModal onClose={onCloseAddTopic} toggle={toggleAddTopic} />
    </>
  );
};

export default Media;
