import { STATUS_TYPE } from '../../../enum';

export interface CompanyFeed {
    status: {
        code: string;
        description: string;
    };
    company_feed: CompanyFeed[];
}

export interface CompanyFeed {
    id: number;
    company_id: number;
    title: string;
    image: null | string;
    description: string;
    created_at: string;
    updated_at: string;
    video: null;
    shares_count: number;
    reactions_count: number;
    comments_count: number;
    deep_link: null | string;
    contents: CompanyFeedContents[];
}

export interface CompanyFeedContents {
    id: number;
    company_feed_id: number;
    user_id: number;
    type: string;
    link: string;
    thumbnail: null | string;
    status: number,
    created_at: string;
    updated_at: string;
    filename: null | string;
    thumb_filename: null | string;
    title: string;
    platform: string;
    position: number,
    description: string;
}

interface InitialState {
    data: CompanyFeed | null;
    status: STATUS_TYPE;
    isError: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    errorMessage: string;
}

export const initialState: InitialState = {
    data: null,
    status: 'idle',
    isError: false,
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
};
