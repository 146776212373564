import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { ContactPiplineLeadResponse, CreateEngageUserFollowupNote, CreateUpdateEngageUserFollowupNoteResponse, EngageLastContactedUpdateResponse, EngagePipelineResponse, EngageUserFollowupNoteResponse, UpdateEngageUserFollowupNote } from './interface';
import { values } from 'lodash';

export const getEngagePipeline = createAsyncThunk(
  'Engage/getEngagePipeline',
  async (
    { pageNo, order_by, search_name, followUpScheduleFilter }: { pageNo: number, order_by: string, search_name: string, followUpScheduleFilter: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<EngagePipelineResponse>(
        `/api/engage/pipeline?page=${pageNo}&order_by=${order_by}&search_name=${search_name}&follow_up_schedule_filter=${followUpScheduleFilter}`
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



export const createEngageUserFollowupNote = createAsyncThunk(
  'Engage/createEngageUserFollowupNote',
  async (
    values: CreateEngageUserFollowupNote,
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post<CreateUpdateEngageUserFollowupNoteResponse>(
        `/api/engage/user-followup-note`,
        values
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const updateEngageUserFollowupNote = createAsyncThunk(
  'Engage/updateEngageUserFollowupNote',
  async (
    values: UpdateEngageUserFollowupNote,
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put<CreateUpdateEngageUserFollowupNoteResponse>(
        `/api/engage/user-followup-note/${values.id}`,
        values
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const getEngageUserFollowupNote = createAsyncThunk(
  'Engage/getEngageUserFollowupNote',
  async (
    { page, contactUserId, contactUserType, engageReportId }: { page?: number, contactUserId: number, contactUserType: string, engageReportId?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<EngageUserFollowupNoteResponse>(
        `api/engage/user-followup-note/${contactUserId}?page=${page ?? 1}&type=${contactUserType}${engageReportId != undefined ? `&engage_report_id=${engageReportId}` : ''}`,
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const updateEngageUserContact = createAsyncThunk(
  'Engage/updateEngageUserContact',
  async (
    { engageLeadId }: { engageLeadId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<EngageLastContactedUpdateResponse>(
        `api/engage/contact-lead/${engageLeadId}`,
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const deleteUserFollowupNote = createAsyncThunk(
  'Engage/deleteUserFollowupNote',
  async (
    values: { id: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.delete<any>(`/api/engage/user-followup-note/${values.id}`);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getContactPiplineLead = createAsyncThunk(
  'userInfo/getContactPiplineLead',
  async ({ contact_code, elid }: { elid: string, contact_code: string }, { rejectWithValue }) => {
    try {
      const response =
        await axiosInstance.get<ContactPiplineLeadResponse>(
          `/api/pipline/${elid}?contact_code=${contact_code}`
        );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const approveContactPiplineLeadConsent = createAsyncThunk(
  'userInfo/approveContactPiplineLeadConsent',
  async (values: { elid?: number, contact_id?: number }, { rejectWithValue }) => {
    try {
      const response =
        await axiosInstance.post<ContactPiplineLeadResponse>(
          `/api/pipline/approve-consent`,
          values
        );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);