import React, { useCallback, useEffect, useRef, useState } from 'react';
import Card from '@mui/material/Card';
import { Box, Button, Chip, CircularProgress, Grid, ListItemButton, ListItemIcon, ListItemText, Paper, Rating, Stack, Typography } from '@mui/material';
import InfoComponent from './InfoComponent';
import { getterPost } from '../../utils/getterPost';
import CollectionsIcon from '@mui/icons-material/Collections';
import PlayCircleFilledTwoToneIcon from '@mui/icons-material/PlayCircleFilledTwoTone';
import { useAppDispatch, useAppSelector } from '../../store';
import useToggle from '../../hooks/useToggle';
import ViewMediaPostModal from '../modal/ViewMediaPostModal';
import { PostObject } from '../../store/post/api/interface';
import { isNotEmpty } from '../../utils/valid';
import { splitString } from '../../utils/splitString';
import RenderCovor from '../VbcComponents/RenderCover';
import { getCompanyExternalLinks, viewCompanyExternalLink } from '../../store/companyExternalLinks/api';
import ExternalLinkButtonIcon from '../ExternalLinkButtonIcon';
import { getPreferredPartners } from '../../store/preferredPartner/api';
import PreferredPartnerItem from '../Card/PreferredPartnerItem';
import { PreferredPartnerContents } from '../../store/preferredPartner/api/interface';
import Linkify from 'react-linkify';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import moment from 'moment';
import ReactReadMoreReadLess from '../ReactReadMoreReadLess';
import { CompanyExternalLink } from '../../store/companyExternalLinks/api/interface';
import { useSearchParams } from 'react-router-dom';
import { checkIsProProOrProOneOrProPlusUser } from '../../utils/checkPro';
import ArrowRight from '../../assets/arrow_right.svg'
import UserReviewView from '../review/UserReviewView';
import { AppColors } from '../../utils/utils';
import VbcHeadLine from '../VbcComponents/VbxHeadLine';
import playIcon from "../../assets/play_circle.svg";
import { getterSocialPost } from '../../utils/getterSocalPost';
import { ExpandMore } from '@mui/icons-material';
import { ProgressView } from '../VbcComponents/ProgressView';

interface IProps {
  setActiveTab: (position: number) => void
}

const ContactTabPanel: React.FC<IProps> = ({ setActiveTab }) => {
  const dispatch = useAppDispatch();
  const { data: userInfo } = useAppSelector((state) => state.userInfo);
  const posts = useAppSelector((state) => state?.post?.data?.data);
  const isPostLoading = useAppSelector((state) => state?.post?.isLoading);

  const socialPosts = useAppSelector((state) => state?.socialPost.data);
  const isSocialPostLoading = useAppSelector((state) => state?.socialPost.isLoading);

  const [searchParams] = useSearchParams();
  const loginUser = useAppSelector((state) => state?.auth?.user);


  const companyId = useAppSelector((state) => state.userInfo.data?.company?.id);

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [manuallyClicked, setManuallyClicked] = useState<boolean>(false);
  const [activePost, setActivePost] = useState<PostObject>();
  const { toggle, onOpen, onClose } = useToggle();
  const background_image = useAppSelector((state) => state.userInfo.data?.knocard?.background_image) ?? '';
  const coverType = splitString(background_image);

  const { isLoading: isPreferredPartnersLoading, page } = useAppSelector((state) => state?.preferredPartner);
  const preferredPartners = useAppSelector((state) => state?.preferredPartner?.data?.preferredPartners) ?? [];

  /*const {
    toggle: toggleExternalLink,
    onOpen: onOpenExternalLink,
    onClose: onCloseExternalLink,
  } = useToggle();*/

  useEffect(() => {
    if (userInfo?.id && !isPreferredPartnersLoading && preferredPartners.length == 0) {
      dispatch(getPreferredPartners({ userId: userInfo.id, pageNo: page }));
    }
  }, [dispatch]);

  const {
    data: companyExternalLinks,
    isLoading: isLoadingCompanyExternalLinks,
  } = useAppSelector((state) => state?.companyExternalLinks);

  useEffect(() => {
    if (companyId && !isLoadingCompanyExternalLinks) {
      dispatch(getCompanyExternalLinks(companyId));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleActivePost = (post: PostObject) => {
    onOpen();
    setManuallyClicked(true);
    setActivePost(post);
  };

  /*const handleExternalLinkClick = (eLink: string) => {
    setExternalLink(eLink);
    onOpenExternalLink();
  };*/

  const openExternalLink = (companyExternalLink: CompanyExternalLink) => {
    try {
      const sc = searchParams.get('sc');
      dispatch(viewCompanyExternalLink({ company_external_link_id: companyExternalLink.id, viewer_code: sc, viewer_id: loginUser?.id }));
      window.open(`${companyExternalLink.link}`, "_blank") || window.location.replace(`${companyExternalLink.link}`);
    } catch (e) { }
  };





  return (
    <>
      {!isSmallScreen && (
        <Paper
          sx={{
            height: '200px',
            position: 'relative',
            padding: '0 10px 10px 10px',
            borderRadius: '0 0 10px 10px',
            boxShadow: 'none',
            backgroundColor: "unset"
          }}
        >
          <RenderCovor props={coverType} />
        </Paper>
      )}
      <Grid
        container
        spacing={2}
        sx={{
          minHeight: '100vh',
          ml: 0,
          width: '100%',
          p: 2
        }}
      >

        <Grid
          container
          xs={12}
          md={5}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            marginTop: '10px',
          }}
        >
          <Paper
            sx={{
              ...(isSmallScreen ? {
                background: 'transparent',
                boxShadow: 'none'
              } : { textAlign: 'center' }),
              width: "100%",
              borderRadius: "12px",
              pl: 1,
              pr: 1,
              pb: 1
            }}
          >
            <VbcHeadLine headLine={"Media Library"}
              buttonLabel={isSmallScreen ? "See all" : null}
              onButtonClick={() => setActiveTab(1)}
              sx={!isSmallScreen ? { display: "block !important", pt: 1 } : {}}
            />


            {(posts.length == 0 && isPostLoading) && (<ProgressView />)}
            {isSmallScreen ? (<Box sx={{ maxWidth: "calc(100vw - 32px)", overflow: "hidden", mt: 1 }}>
              <Stack flexDirection={'row'} sx={{ overflow: "auto" }}>
                {getterPost(posts)?.map((post, idx) => (
                  <Stack position='relative'>
                    <Box
                      component={'img'}
                      key={`post-${idx}`}
                      onClick={() => handleActivePost(post)}
                      src={
                        post?.thumb_link
                          ? post?.thumb_link
                          : post?.contents?.[0]?.type === 'pdf'
                            ? '/static/images/pdf.png'
                            : post?.contents?.[0]?.type === 'video'
                              ? post?.contents?.[0]?.thumbnail ?? ''
                              : post?.contents?.[0]?.link ?? ''
                      }

                      alt=''

                      sx={{
                        minWidth: '150px',
                        minHeight: '150px',
                        width: '150px',
                        height: '150px',
                        borderRadius: "12px",
                        ...(!post?.thumb_link &&
                          post?.contents?.[0]?.type === 'pdf'
                          ? { objectFit: 'contain' }
                          : { objectFit: 'cover' }),
                        ml: 0.5,
                        mr: 0.5
                      }}
                    />
                    {post?.contents?.length > 1 && (
                      <CollectionsIcon
                        sx={{
                          color: AppColors.grayThreeColor,
                          position: 'absolute',
                          right: 10,
                          top: 10,
                        }}
                      />
                    )}

                    {post?.contents?.length > 0 &&
                      post?.contents?.[0]?.type === 'video' && (
                        <Box
                          component={'img'}
                          src={playIcon}
                          sx={{
                            height: "30%",
                            width: "30%",
                            color: '#dfe2e4',
                            position: 'absolute',
                            top: '35%',
                            left: '35%',
                            fontSize: '4rem',
                          }}
                        />
                      )}
                  </Stack>
                ))}
              </Stack>
            </Box>) : (<Grid container spacing={2} sx={{ pt: 1 }} >
              {getterPost(posts)?.map((post, idx) => (
                <Grid
                  item
                  xs={6}
                  md={6}
                  key={`post-${idx}`}
                  onClick={() => handleActivePost(post)}
                  position='relative'
                  sx={{
                    minHeight: '150px',
                    height: '150px',
                    p: 0
                  }}

                >
                  <Box
                    component={'img'}
                    src={
                      post?.thumb_link
                        ? post?.thumb_link
                        : post?.contents?.[0]?.type === 'pdf'
                          ? '/static/images/pdf.png'
                          : post?.contents?.[0]?.type === 'video'
                            ? post?.contents?.[0]?.thumbnail ?? ''
                            : post?.contents?.[0]?.link ?? ''
                    }
                    className='object-fit-covor'
                    height='100%'
                    width='100%'
                    alt=''
                    style={{
                      ...(!post?.thumb_link &&
                        post?.contents?.[0]?.type === 'pdf'
                        ? { objectFit: 'contain' }
                        : {}),
                    }}
                    sx={{
                      borderRadius: "12px"
                    }}
                  />
                  {post?.contents?.length > 1 && (
                    <CollectionsIcon
                      sx={{
                        color: 'grey',
                        position: 'absolute',
                        right: 10,
                        top: 20,
                      }}
                    />
                  )}
                  {post?.contents?.length > 0 &&
                    post?.contents?.[0]?.type === 'video' && (
                      <Box
                        component={'img'}
                        src={playIcon}
                        sx={{
                          height: "30%",
                          width: "30%",
                          color: '#dfe2e4',
                          position: 'absolute',
                          top: '40%',
                          left: '40%',
                          fontSize: '4rem',
                        }}
                      />
                    )}
                </Grid>
              ))}
            </Grid>)}
          </Paper>
        </Grid>
        <Grid
          container
          xs={12}
          md={7}
          sx={{
            justifyContent: 'center',
            position: 'relative',
            marginTop: '10px',
            borderRadius: "12px",
            pl: 1,

          }}
        >
          <Paper
            sx={{
              ...(isSmallScreen ? {
                background: 'transparent',
                boxShadow: 'none'
              } : { textAlign: 'center' }),
              width: "100%",
              borderRadius: "12px",
            }}
          >
            <VbcHeadLine headLine={"Social"}
              buttonLabel={isSmallScreen ? "See all" : null}
              onButtonClick={() => setActiveTab(2)}
              sx={!isSmallScreen ? { display: "block !important", pt: 1 } : {}}
            />

            {(socialPosts.length == 0 && isSocialPostLoading) && (<ProgressView />)}

            {isSmallScreen ? (<Box sx={{ maxWidth: "calc(100vw - 32px)", overflow: "hidden", mt: 1 }}>
              <Stack flexDirection={'row'} sx={{ overflow: "auto" }}>
                {getterSocialPost(socialPosts)?.map((socialPost, idx) => (
                  <Stack position='relative'
                    sx={{
                      backgroundColor: AppColors.whiteColor,
                      borderRadius: "12px",
                      p: 1,
                      mr: 1
                    }}
                  >
                    <Box
                      component={'img'}
                      key={`post-${idx}`}
                      src={
                        ((socialPost?.contents?.length) == 0) ?
                          '/static/images/placeholder_profile.png'
                          : socialPost?.contents?.[0]?.type === 'pdf'
                            ? '/static/images/pdf.png'
                            : socialPost?.contents?.[0]?.type === 'video'
                              ? socialPost?.contents?.[0]?.thumbnail ?? '/static/images/placeholder_profile.png'
                              : socialPost?.contents?.[0]?.link ?? '/static/images/placeholder_profile.png'
                      }
                      alt=''
                      sx={{
                        minWidth: '142px',
                        minHeight: '142px',
                        width: '142px',
                        height: '142px',
                        borderRadius: "12px",
                        objectFit: 'cover',
                        ml: 0.5,
                        mr: 0.5
                      }}
                    />
                    <Typography
                      variant='subtitle2'
                      sx={{
                        color: AppColors.blackColor,
                        fontSize: "16px",
                        wordBreak: "break-word",
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        maxHeight: '3em',
                        lineHeight: '1.2em',
                        pt: 1
                      }}
                      className='text-decoration'
                    >
                      {socialPost.title || ""}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Box>) : (<Box
              className={isSmallScreen ? 'middle-column' : ''}
              // variant='outlined'
              sx={{
                borderRadius: '10px',
                position: 'relative',
                backgroundColor: isSmallScreen ? 'transparent' : '',
              }}
            >
              {isNotEmpty(userInfo?.company) && (
                <>

                  <Stack
                    direction='column'
                    gap={2}
                    sx={{
                      justifyContent: 'center',
                      pt: isSmallScreen ? 2 : 0,
                    }}
                  >

                    {getterSocialPost(socialPosts, 1).map((socialPostItem, index) => {
                      return (<Stack direction='column' gap={1} sx={{ p: 1 }} >

                        {socialPostItem?.contents != null &&
                          socialPostItem?.contents?.length > 0 && (
                            <Splide
                              options={{
                                perPage: 1,
                              }}
                              style={{
                                borderRadius: "12px"
                              }}
                            >
                              {socialPostItem?.contents?.map((content) => {
                                return content?.type === 'image' ? (
                                  <SplideSlide
                                    key={`home-spItem-${content.id}`}
                                  >
                                    <Box
                                      component={'img'}
                                      src={content?.link ?? ''}
                                      alt={content?.filename ?? ''}
                                      height='100%'
                                      width='100%'
                                      sx={{ maxHeight: '50vh', borderRadius: "12px", objectFit: 'cover' }}

                                    />
                                  </SplideSlide>
                                ) : (
                                  <SplideSlide
                                    key={`home-spItem-${content.id}`}
                                  >
                                    <video
                                      controls
                                      width='100%'
                                      height='100%'
                                      style={{ maxHeight: '50vh', borderRadius: "12px", }}
                                      className='object-fit-contain'
                                      poster={content?.thumbnail ?? ''}
                                    >
                                      <source
                                        src={content?.link}
                                        type='video/mp4'
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  </SplideSlide>
                                );
                              })}
                            </Splide>
                          )}

                        <Box
                          display='flex'
                          flexDirection={'column'}
                          alignItems='flex-start'
                          justifyContent='flex-start'
                        >
                          <Typography
                            variant='subtitle1'
                            component='div'
                            sx={{ color: AppColors.blackColor, textAlign: 'left', fontWeight: 500 }}
                            className='link'
                          >
                            <Linkify>{socialPostItem?.title}</Linkify>
                          </Typography>
                          <Typography
                            variant='body1'
                            component='div'
                            color='inherit'
                            className='link'
                            sx={{ color: AppColors.grayTwoColor, textAlign: 'left' }}
                          >
                            <ReactReadMoreReadLess
                              charLimit={200}
                              readMoreText={'Read more'}
                              readLessText={'Read less'}
                              readMoreClassName='read-more-less-more'
                              readLessClassName='read-more-less-less'
                            >
                              {socialPostItem?.description}
                            </ReactReadMoreReadLess>
                          </Typography>
                          <Typography
                            variant='body2'
                            component='div'
                            textAlign={'end'}
                            width={'100%'}
                            sx={{ color: AppColors.grayTwoColor, pt: 1 }}
                          >
                            {moment(socialPostItem?.created_at).format(
                              'DD MMMM YYYY'
                            )}
                          </Typography>
                        </Box>
                      </Stack>);
                    })}
                  </Stack>
                </>
              )}
            </Box>)}
          </Paper>
        </Grid>

        {/* Link and Partner Section */}
        <Grid item xs={12} md={4}
          sx={{
            justifyContent: 'center',
            position: 'relative',
            marginTop: '0px',
            borderRadius: "12px",
            paddingLeft: "0px !important",
          }}>
          <Grid container sx={{ p: 0 }}>

            {isSmallScreen && (
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                  marginTop: '10px',
                  paddingLeft: "0px !important",
                  pr: 1
                }}
              >


                <Paper
                  className='right-row'
                  sx={{
                    ...(isSmallScreen ? {
                      background: 'transparent',
                      boxShadow: 'none'
                    } : { textAlign: 'center' }),
                    width: "100%",
                    borderRadius: "12px",
                    pl: 1,
                    pr: 1
                  }}
                >
                  <VbcHeadLine headLine={"My Links"}
                    buttonLabel={isSmallScreen ? "See all" : null}
                    onButtonClick={() => setActiveTab(3)}
                    sx={!isSmallScreen ? { display: "block !important", pt: 1 } : {}}
                  />

                  <Box mt={1} textAlign={'center'}>
                    {isLoadingCompanyExternalLinks ? (<ProgressView />) : (
                      <Box sx={{ maxWidth: "calc(100vw - 32px)", overflow: "hidden", mt: 1 }}>
                        <Stack flexDirection={'row'} sx={{ overflow: "auto" }}>
                          {companyExternalLinks?.map((companyExternalLink, idx) => {
                            if (companyExternalLink.status === 'ACT') {
                              return (<Box sx={{ backgroundColor: AppColors.whiteColor, borderRadius: "12px", mr: 1.5 }}>
                                <ListItemButton onClick={() =>
                                  openExternalLink(companyExternalLink)
                                }>
                                  <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <ExternalLinkButtonIcon
                                      companyExternalLink={companyExternalLink}
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={companyExternalLink.title} sx={{ width: "max-content", mr: 2, color: AppColors.blackColor }} />
                                  <Box component={'img'} src={ArrowRight}></Box>
                                </ListItemButton>
                              </Box>
                              );
                            } else {
                              return <></>;
                            }
                          })}</Stack></Box>

                    )}
                  </Box>
                </Paper>
              </Grid>
            )}
            {/*)}*/}
            {isSmallScreen && (
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                  marginTop: '10px',
                }}
              >
                {/*</Grid>{isSelected(userInfo?.user_features, 12) && (*/}
                <Paper
                  className='right-row'
                  sx={{
                    ...(isSmallScreen ? {
                      background: 'transparent',
                      boxShadow: 'none'
                    } : { textAlign: 'center' }),
                    width: "100%",
                    borderRadius: "12px",
                  }}
                >
                  <VbcHeadLine headLine={"Preferred Partners"}
                    buttonLabel={isSmallScreen ? "See all" : null}
                    onButtonClick={() => setActiveTab(4)}
                    sx={!isSmallScreen ? { display: "block !important", pt: 1 } : {}}
                  />

                  <Box sx={{ maxWidth: "calc(100vw - 32px)", overflow: "hidden", mt: 1 }}>
                    <Stack flexDirection={'row'} sx={{ overflow: "auto" }}>
                      {isPreferredPartnersLoading &&
                        preferredPartners.length == 0 ? (
                        <ProgressView />
                      ) : (
                        preferredPartners?.map((partner, index) =>
                          index < 2 ? (<Box sx={{ mr: 1.5, p: 0.5 }}>
                            <PreferredPartnerItem
                              key={`pp-${index}`}
                              partner={partner}
                              index={index}
                              showEditMenu={false}
                              isHomePageItem={true}
                              onMediaSelect={(
                                contents: PreferredPartnerContents[],
                                index: number
                              ) => { }}
                              cardWidth={'max-content'}
                            /></Box>
                          ) : (
                            <></>
                          )
                        )
                      )}
                    </Stack>
                  </Box>
                </Paper>

              </Grid>
            )}


            {isSmallScreen && userInfo && checkIsProProOrProOneOrProPlusUser(userInfo) && (
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                  marginTop: isSmallScreen ? '10px' : '30px',
                }}
              >
                <Paper
                  className='right-row'
                  sx={{
                    background: 'transparent',
                    boxShadow: 'none',
                    width: "100%",
                    borderRadius: "12px",

                  }}
                >
                  <VbcHeadLine headLine={"Testimonials"} />
                  <UserReviewView userInfo={userInfo} isSmallScreen={isSmallScreen} reviewColumns={1} backgrounColor={AppColors.whiteColor} isHoRizontal={true} />
                </Paper>
              </Grid>
            )}

          </Grid>

        </Grid>
      </Grid >

      <ViewMediaPostModal
        toggle={toggle}
        onClose={onClose}
        activePost={activePost}
        manuallyClicked={manuallyClicked}
      />

    </>
  );
}

export default ContactTabPanel;