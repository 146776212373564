import { createSlice } from '@reduxjs/toolkit';
import { getTrainingVideos } from './api';
import { TrainingVideoItem, TrainingVideoSearchResult, initialState } from './api/interface';
import { getCapitalizedString } from '../../utils/utilMethods';
import { stat } from 'fs/promises';
import { trainingTabs } from '../../utils/utils';

const trainingVideoSlice = createSlice({
  name: 'trainingVideo',
  initialState,
  reducers: {
    reset: () => initialState,
    updateSearchText: (state, action) => {
      state.searchText = action.payload;
      state.searchResult = filterVideos(state.searchText, state.data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTrainingVideos.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getTrainingVideos.fulfilled, (state, action) => {
      state.data = action.payload.data.data;
      state.searchResult = filterVideos(state.searchText, state.data);
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getTrainingVideos.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset, updateSearchText } = trainingVideoSlice.actions;
export default trainingVideoSlice.reducer;

const filterVideos = (searchText: string, videoList: TrainingVideoItem[]) => {

  let videoItems: TrainingVideoItem[] = [];
  //state.videoList.where((element) => ((element.title??"").toLowerCase().contains(keyword.toString()) || (element.tag??"").toLowerCase().contains(keyword.toString()))).toList());
  let searchContent = searchText.trim().toLocaleLowerCase();
  if (searchContent.trim().length > 0) {
    videoItems = videoList.filter(obj => obj.title.toLocaleLowerCase().includes(searchContent) || obj.tag.toLocaleLowerCase().includes(searchContent));
  } else {
    videoItems = videoList;
  }

  let sectionList = [...new Set(videoItems.map((item) => item.type))].sort()
  let videoTrainingSectionList: TrainingVideoSearchResult[] = [];

  sectionList.forEach((item) => {
    const sectionItemList: TrainingVideoItem[] = videoItems.filter(obj => obj.type == item).sort((a, b) => a.position - b.position);

    videoTrainingSectionList.push({ title: getCapitalizedString(item.replaceAll("_", " ")), code: item, data: [...sectionItemList] });
  });



  const customComparator = (sectionOne: TrainingVideoSearchResult, sectionTwo: TrainingVideoSearchResult) => {
    const indexOne = trainingTabs.findIndex((element) => element.code === sectionOne.code);
    const indexTwo = trainingTabs.findIndex((element) => element.code === sectionTwo.code);
    return indexOne - indexTwo;
  };

  return videoTrainingSectionList.sort(customComparator);




}