import { STATUS_TYPE } from '../../../enum';
import { Company, UserInfo } from '../../userInfo/api/interface';

export interface ContactRequest {
  page: number;
  userId: number;
}




export interface ContactResponse {
  status: string;
  data: {
    contacts: {
      current_page: number;
      per_page: number;
      last_page: number;
      total: number;
      data: ContactInfo[];
    }
  }
}

export interface ContactInfo {
  id: number;
  user_id?: number;
  contact_user_id?: number;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  email?: string;
  where_i_met_you?: string;
  occupation?: string;
  notes?: string;
  contact_code?: string;
  created_at?: string;
  updated_at?: string;
  is_share?: number;
  is_favorite?: number;
  is_saved_using_app?: string;
  full_name?: string;
  share?: ContactShare;
  phone_numbers?: ContactPhoneNumbers[];
  user?: UserInfo;
  profile_picture?: string;
}

export interface ContactShare {
  id?: number;
  user_id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
  company?: string;
  occupation?: string;
  share_code?: string;
  created_at?: string;
  updated_at?: string;
  contact_id?: number;
  visited?: number;
  share_count?: number;
}

export interface ContactPhoneNumbers {
  id?: number;
  contact_id?: number;
  number?: string;
  created_at?: string;
  updated_at?: string;
}


interface InitialState {
  data: ContactInfo[];
  status: STATUS_TYPE;
  page: number;
  total: number;
  isLoadMore: boolean;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

export const initialState: InitialState = {
  data: [],
  status: 'idle',
  isError: false,
  isLoading: false,
  page: 1,
  total: 0,
  isLoadMore: false,
  isSuccess: false,
  errorMessage: '',
};
