import { CircularProgress, Stack } from "@mui/material";

export const ProgressView = () => {
    return (<Stack
        direction={'row'}
        justifyContent={'center'}
        sx={{ mt: 3, mb: 3 }}
    >
        <CircularProgress />
    </Stack>);
}