import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import NotFound from './pages/NotFound';
import VBCHomePage from './pages/VBCHome';
// import NotFound from './pages/Page404';
import Register from './components/Register';
import Login from './components/Login';
import DashboardLayout from './layouts/dashboard';
import { useAppSelector } from './store';
import Reports from './pages/Reports';
import Dashboard from './pages/Dashboard';
import UserProfile from './pages/UserProfile';
import Media from './pages/Media';

import Subscription from './pages/Subscription';
import KnocardCart from './pages/KnoCardCart';
import { getItem } from './utils/localStorage';
import { config } from './utils/utils';
import PreferredPartners from './pages/PreferredPartners';
import Reviews from './pages/Reviews';
import AddEditPreferredPartner from './pages/AddEditPreferredPartner';
import BusinessSocial from './pages/BusinessSocial';
import AddEditBusinessSocial from './pages/AddEditBusinessSocial';
import ExternalLinks from './pages/ExternalLinks';
import NewProspects from './pages/NewProspects';
import Messaging from './pages/Messaging';
import ReferLandingPageOne from './pages/referral/ReferLandingPageOne';
import Engage from './pages/Engage';
import EngagePipline from './pages/EngagePipline';
import MbxReferLandingPage from './pages/referral/MbxReferLandingPage';
import ReferLandingSectionTwo from './pages/referral/ReferLandingPageTwo';
import HandleAffiliateLink from './pages/HandleAffiliateLink';
import MemberVbc from './pages/MemberVbc';
import VideoTutorial from './pages/VideoTutorial';
import VideoTraining from './pages/VideoTraining';
import AffiliateLanding from './pages/referral/AffiliateLanding';
import CustomAffiliateLandingPage from './pages/referral/CustomAffiliateLandingPage';
import RejectionFree from './pages/referral/RejectionFree';
import Settings from './pages/Settings';
import Support from './pages/Support';
import HelpSupportVideo from './pages/HelpSupportVideo';
import KnoCardLink from './pages/KnoCardLink';
import MBXRejectionFree from './pages/referral/MBXRejectionFree';
import NetworkLandingPage from './pages/referral/NetworkLandingPage';
import LimitlessLandingPage from './pages/referral/LimitlessLandingPage';
import StopLightGoLandingPage from './pages/referral/StopLightGoLandingPage';
import DriveLandingPage from './pages/referral/DriveLandingPage';
import PromoterDash from './pages/PromoterDash';
import CustomLandingPageEditor from './pages/CustomLandingPageEditor';
import CorporateReport from './pages/CorporateReport';
import ShareHistory from './pages/ShareHistory';
import ProspectActivity from './pages/ProspectActivity';

const App = () => {
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const selectedPurchasePlan = getItem(config.SLECTED_PLAN_KEY);
  return (
    <Routes>
      <Route path='/user-name-not-found-page' element={<NotFound />} />
      {/* <Route path='/:username/:tab?/:postId?/:form_code?' element={<VBCHomePage />} /> */}
      <Route
        path='/:username/:tab?/:postId?/:form_code?'
        element={<VBCHomePage />}
      />
      {/* <Route path="/:username/:tab/:postId" element={<VBCHomePage />} /> */}
      <Route path='/affiliate/:username' element={<HandleAffiliateLink />} />

      <Route path='/referral/mbx' element={<MbxReferLandingPage />} />
      <Route path='/referral/1' element={<ReferLandingPageOne />} />
      <Route path='/referral/2' element={<ReferLandingSectionTwo />} />

      <Route path='/what-is-knocard' element={<AffiliateLanding />} />
      <Route path='/rejection-free' element={<RejectionFree />} />
      <Route path='/mbx-rejection-free' element={<MBXRejectionFree />} />
      <Route path='/landing-page/:username' element={<CustomAffiliateLandingPage />} />
      <Route path='/network-landing-page' element={<NetworkLandingPage />} />
      <Route path='/limitless-landing-page' element={<LimitlessLandingPage />} />

      <Route path='/drive-landing-page' element={<DriveLandingPage />} />
      <Route path='/stop-light-go-landing-page' element={<StopLightGoLandingPage />} />


      <Route
        path='/dashboard'
        element={accessToken ? <DashboardLayout /> : <Navigate to='/login' />}
        children={[
          <Route path='' element={<Dashboard />} />,
          <Route path='reports' element={<Reports />} />,
          <Route path='user-profile' element={<UserProfile />} />,
          <Route path='media' element={<Media />} />,
          <Route path='preferred-partners' element={<PreferredPartners />} />,

          <Route
            path='add-preferred-partner'
            element={<AddEditPreferredPartner />}
          />,
          <Route
            path='edit-preferred-partner/:ppid?'
            element={<AddEditPreferredPartner />}
          />,
          <Route path='business-social' element={<BusinessSocial />} />,
          <Route
            path='add-business-social'
            element={<AddEditBusinessSocial />}
          />,
          <Route
            path='edit-business-social/:bsid?'
            element={<AddEditBusinessSocial />}
          />,
          <Route path='knocard-link' element={<KnoCardLink />} />,
          <Route path='external-links' element={<ExternalLinks />} />,
          /*<Route
            path='new-prospects'
            element={<NewProspects />}
          />,*/
          <Route path='messaging' element={<Messaging />} />,
          <Route path='engage/pipeline' element={<EngagePipline />} />,
          /*<Route
           path='engage'
           element={<Engage />}
           />,*/
          <Route path='vbc' element={<MemberVbc />} />,
          <Route path='how-to' element={<VideoTutorial />} />,
          <Route path='training' element={<VideoTraining />} />,
          <Route path='settings' element={<Settings />} />,

          <Route path='help-support-video' element={<HelpSupportVideo />} />,
          <Route path='support' element={<Support />} />,
          <Route path='reviews' element={<Reviews />} />,
          <Route path='promoter-dash' element={<PromoterDash />} />,
          <Route path='custom-landing-page' element={<CustomLandingPageEditor />} />,
          <Route path='corporate-report' element={<CorporateReport />} />,
          <Route path='share-history' element={<ShareHistory />} />,
          <Route path='prospect-activity' element={<ProspectActivity />} />

        ]}
      />

      <Route
        path='/'
        children={[
          <Route
            path='/'
            element={
              accessToken ? (
                <Navigate to='/dashboard' />
              ) : (
                <Navigate to='/login' />
              )
            }
          />,
          <Route
            path='login'
            element={
              accessToken ? (
                <Navigate to='/dashboard' />
              ) : (
                <Login />
              )
            }
          />,
          <Route
            path='register/:username?'
            element={
              accessToken ? (
                [
                  config.PRO_1,
                  config.PRO_PRO,
                  // config.PRO_BASIC,
                  config.PRO_PLUS,
                  config.PRO_FREE_TRIAL,
                ].includes(selectedPurchasePlan) ? (
                  <Navigate to='/knocard-cart' />
                ) : (
                  <Navigate to='/' />
                )
              ) : (
                <Register />
              )
            }
          />,
          <Route
            path='subscription/:username?'
            element={<Subscription />}
          />,
          <Route
            path='knocard-cart/:username?'
            element={<KnocardCart />}
          />,

          <Route path='pricing/:username?' element={<Subscription />} />,
          <Route path='404' element={<NotFound />} />,
          <Route path='*' element={<Navigate to='/404' />} />,
        ]}
      />

      {/* <Route path='/register/:username?' element={<Register />} />
      <Route path='/login' element={<Login />} /> */}
      <Route path='*' element={<Navigate to='/user-name-not-found-page' />} />
    </Routes>
  );
};

export default App;
