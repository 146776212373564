import {
  Typography,
  Box,
  Button,
  Grid,
  Divider,
  CircularProgress,
} from '@mui/material';
import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import useToggle from '../hooks/useToggle';
import { useNavigate } from 'react-router-dom';
import ConfirmationModel from '../components/modal/ConfirmationModel';

import '@splidejs/react-splide/css';
import { CompanyExternalLink } from "../store/companyExternalLinks/api/interface";
import { createUpdateCompanyExternalLinks, getCompanyExternalLinks, removeCompanyExternalLinks } from "../store/companyExternalLinks/api";

import { companyExternalLinkDeleted, companyExternalLinkUpdated } from '../store/companyExternalLinks/companyExternalLinksSlice';
import AddEditExternalLink from '../components/AddEditExternalLink';
import { checkIsProProOrProOneOrProPlusUser } from '../utils/checkPro';
import { showSuccessMessage } from '../utils/utilMethods';
import { AppColors } from '../utils/utils';
import ExternalLinkItem from '../components/ExternalLinkItem';

const opts = {
  height: '100%',
  width: '100%',
};

const ExternalLinks = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userInfo = useAppSelector((state) => state?.auth?.user);
  const editLinkRef = useRef<null | HTMLDivElement>(null);
  const [editExternalLink, setEditExternalLink] = useState<CompanyExternalLink | undefined>();
  const [isCreateEditExternalLink, setIsCreateEditExternalLink] = useState(false);
  const [deleteExternalLink, setDeleteExternalLink] = useState<CompanyExternalLink | undefined>();
  const companyExternalLinks = useAppSelector((state) => state?.companyExternalLinks.data);
  const isCompanyExternalLinkLoading = useAppSelector((state) => state?.companyExternalLinks.isLoading);
  const isProOneOrProPlus = checkIsProProOrProOneOrProPlusUser(userInfo);

  useEffect(() => {
    if (userInfo != null && !isCompanyExternalLinkLoading) {
      dispatch(getCompanyExternalLinks(userInfo.company.id));
    }
  }, []);

  const {
    toggle: togglenDeleteExternalLink,
    onOpen: onOpenDeleteExternalLink,
    onClose: onCloseDeleteExternalLink,
  } = useToggle();



  const handleDeleteExternalLink = (companyExternalLink: CompanyExternalLink) => {
    if (companyExternalLink.id != null) {
      setDeleteExternalLink(companyExternalLink);
      onOpenDeleteExternalLink();
    }
  }
  const onDeleteExternalLinkConfirm = async () => {
    onCloseDeleteExternalLink();
    if (deleteExternalLink?.id != null) {
      var result = await dispatch(removeCompanyExternalLinks(deleteExternalLink.id)).unwrap();
      if (result?.data?.status?.code == "success") {
        dispatch(companyExternalLinkDeleted(deleteExternalLink));
        showSuccessMessage("External link deleted successfully.");
      }
    }
  }

  const handleEditExternalLink = (companyExternalLink: CompanyExternalLink) => {
    setEditExternalLink(companyExternalLink);
    setIsCreateEditExternalLink(true);
    scrollToCreateEditLink();
  }

  const scrollToCreateEditLink = () => {
    editLinkRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };


  const handleAddExternalLink = () => {
    if (!isProOneOrProPlus && companyExternalLinks.length >= 3) {
      return;
    }

    setEditExternalLink(undefined);
    setIsCreateEditExternalLink(true);
    scrollToCreateEditLink();
  }
  const handleExternalLinkVisibility = async (companyExternalLink: CompanyExternalLink) => {

    let updateCompanyExternalLink = { ...companyExternalLink, status: (companyExternalLink.status == 'ACT' ? 'INA' : 'ACT') };
    const result = await dispatch(createUpdateCompanyExternalLinks(updateCompanyExternalLink)).unwrap();
    if (result?.data?.status == "success") {
      dispatch(companyExternalLinkUpdated(result.data.data.company_external_link));
    }
  }

  return (
    <>
      <Page
        title='Exterbal Links | KnoCard'
        showAppBar={true}
      >
        <Box sx={{ mt: 5 }} >
          <Typography
            variant='h6'

            sx={{ textAlign: 'left', pt: 0, ml: 2, mr: 2, color: AppColors.blackColor }}
          >
            Your Links
          </Typography>
        </Box>
        <Grid container >

        </Grid>
        <Grid container sx={{
          mt: 2, p: 2,
          width: '100%',
        }} gap={2}  >

          {
            companyExternalLinks.map((element: CompanyExternalLink, index: number) => {
              return (<>
                <Grid item sm={5.8} xs={12}>
                  <ExternalLinkItem companyExternalLink={element} handleDeleteExternalLink={handleDeleteExternalLink} handleEditExternalLink={handleEditExternalLink} handleExternalLinkVisibility={handleExternalLinkVisibility} />
                </Grid>
              </>);



              // return (<Grid sm={6} xs={12}><Button
              //   key={`el-${index}`}
              //   variant='contained'
              //   startIcon={
              //     <ExternalLinkButtonIcon companyExternalLink={element} />
              //   }
              //   disableRipple
              //   endIcon={
              //     <>
              //       <IconButton aria-label="delete"
              //         onClick={() => handleDeleteExternalLink(element)}>
              //         <CancelIcon sx={{ color: 'red' }} />
              //       </IconButton>
              //     </>
              //   }
              //   sx={{
              //     textAlign: 'center',
              //     color: 'black',
              //     width: '65%',
              //     borderRadius: '30px',
              //     border: '1px solid #c3c3c3',
              //     backgroundColor: 'white',
              //     ':hover': {
              //       bgcolor: 'white',
              //     },
              //     lineHeight: '1.3rem',
              //     m: 1,
              //     textTransform: 'inherit',
              //     pr: 0.5,
              //     pl: 1.5,
              //     pt: 0,
              //     pb: 0
              //   }}><Typography sx={{ width: '100%' }}>{element.title}</Typography></Button>
              //   <IconButton aria-label="edit" onClick={() => handleExternalLinkVisibility(element)}>
              //     {element.status == "ACT" ? <RemoveRedEyeIcon sx={{ color: '#088ac7' }} /> : <VisibilityOffIcon sx={{ color: '#088ac7' }} />}
              //   </IconButton>
              //   <IconButton aria-label="edit" onClick={() => handleEditExternalLink(element)}>
              //     <EditIcon sx={{ color: '#088ac7' }} />
              //   </IconButton>
              // </Grid>);
            })
          }

          {isCompanyExternalLinkLoading && (<Grid xs={12}>
            <Box display='flex' justifyContent='center' mt={2}>
              <CircularProgress />
            </Box>
          </Grid>)}

        </Grid>
        <Grid container xs={12} ref={editLinkRef}>
          {(!isCreateEditExternalLink && companyExternalLinks.length < 6) && (
            <Grid item sm={6} xs={12} sx={{ p: 3 }} >
              <Button
                variant='contained'
                sx={{ width: "100%" }}
                onClick={handleAddExternalLink}
              >
                Add new Link
              </Button>
            </Grid>
          )}


          {isCreateEditExternalLink &&
            (
              <><Grid item xs={12}><Divider sx={{
                mt: 2,
                borderStyle: 'solid',
                borderColor: AppColors.strokeColor,
              }}></Divider></Grid>
                <Grid item xs={12} sm={6} sx={{ m: 2, pb: 3 }}>
                  <AddEditExternalLink onCancel={() => setIsCreateEditExternalLink(false)} companyExternalLink={editExternalLink} />
                </Grid></>

            )
          }</Grid>

      </Page >


      <ConfirmationModel
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        title='Delete External Link'
        message='Are you sure! Do you want to delete this external link?'
        onConfirm={onDeleteExternalLinkConfirm}
        onClose={onCloseDeleteExternalLink}
        toggle={togglenDeleteExternalLink} />
    </>
  );
};


export default ExternalLinks;
