import { PayPalButtons } from "@paypal/react-paypal-js";
import { AppDispatch, useAppSelector } from '../../store';
import { useDispatch } from 'react-redux';
import { createProOneOrder } from '../../store/purchase/api';
import { config } from "../../utils/utils";
import { getItem } from "../../utils/localStorage";
interface IProps {
    onApprove: (data: any, actions: any) => Promise<void>;
}

const PaypalOrderButton: React.FC<IProps> = ({ onApprove }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { user } = useAppSelector((state) => state?.auth);

    const createOrder = async (data: any, action: any) => {
        let authToken = getItem(config.AUTH_KEY);
        var result: any = await dispatch(createProOneOrder({ api_token: authToken ?? '', purchase_code: config.PRO_1 }))
        return new Promise<string>((resolve, reject) => { resolve(`${result.payload.data.id}`) });
    }

    return (
        <PayPalButtons createOrder={createOrder} onApprove={onApprove} onError={(error) => { console.log(error); }} />
    );

}

export default PaypalOrderButton;