
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import { AppColors } from '../../utils/utils';
import { MediaFeedbackFormCountItem } from '../../store/corporateReporting/api/interface';
import { formatReportCount } from '../../utils/utilMethods';



interface IProps {
    title: string;
    question: string;
    index: number;
    answers: MediaFeedbackFormCountItem[];
    isLoading: boolean;
}

const FeedbackFormReportItem: React.FC<IProps> = ({
    title,
    question,
    index,
    answers,
    isLoading
}) => {






    return (<Grid key={`feedback-form-${index}`} item sm={12} xs={12}>
        <Grid container spacing={1} justifyContent="center" >

            <Grid item xs={6}>
                <Box sx={{ background: `${AppColors.primaryColor}`, borderRadius: "10px", }}>
                    <Typography sx={{ color: "#ffffff", textAlign: "center", pl: 0.5, pr: 0.5, pt: 1, pb: 1 }}>{title}</Typography>
                    <Box sx={{ borderRadius: "10px", padding: "2px", display: "flex", justifyContent: 'center', flexDirection: "column" }}>
                        <Box sx={{ background: `${AppColors.backgroundColor}`, borderRadius: 2, mr: 0.2, ml: 0.2, mb: 0.2 }}>
                            <Typography sx={{ color: `${AppColors.primaryColor}`, pl: 0.5, pr: 0.5, pt: 1, }}>Question: </Typography>
                            <Typography sx={{ color: `${AppColors.primaryColor}`, pl: 0.5, pr: 0.5, pt: 1, pb: 1 }}>{question}</Typography>

                        </Box>
                    </Box>
                </Box>
            </Grid>

            <Grid key={`feedback-form-${index}`} item xs={6}>
                <Box sx={{ background: `${AppColors.primaryColor}`, borderRadius: "10px", }}>
                    <Typography sx={{ color: "#ffffff", textAlign: "center", pl: 0.5, pr: 0.5, pt: 1, pb: 1 }}>Responses</Typography>
                    <Box sx={{ borderRadius: "10px", padding: "2px", display: "flex", justifyContent: 'center', flexDirection: "column" }}>
                        <Grid container justifyContent={(answers || []).length > 1 ? "center" : "left"}>
                            {isLoading ? <Grid item xs={12} sx={{ background: `${AppColors.backgroundColor}`, borderRadius: 2, mr: 0.2, ml: 0.2, mb: 0.2, p: 1 }}><Box flex={1} textAlign={'center'}><CircularProgress color='inherit' size={25} /></Box></Grid> : answers.map((item, idx) => {
                                return (<Grid item key={`form-${index}-answer-${idx}`} sm={5.9} xs={12} sx={{ background: `${AppColors.backgroundColor}`, borderRadius: 2, mr: 0.2, ml: 0.2, mb: 0.2 }}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box sx={{ width: "100%" }}><Typography sx={{ width: "100%", color: `${AppColors.primaryColor}`, pl: 0.5, pr: 0.5, pt: 1, pb: 1 }}>{item.form_answer || ""}</Typography></Box>
                                        <Box sx={{ flexShrink: 0 }}><Typography sx={{ pl: 0.5, pr: 1, pt: 1, pb: 1 }}>{formatReportCount(item.total_answer || 0)}</Typography></Box>
                                    </Box>
                                </Grid>);
                            })}



                        </Grid>

                    </Box>
                </Box>
            </Grid>

        </Grid>
    </Grid>);
};

export default FeedbackFormReportItem;
