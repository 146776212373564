import * as Yup from 'yup';

const contactFormSchema = Yup.object().shape({
  first_name: Yup.string().trim().required('First name is required.'),
  // last_name: Yup.string().required('Last name is required.'),
  phone_number: Yup.string().trim().matches(/^\+?[0-9\s]+$/, 'Mobile number must contain only digits')
    .min(8, 'Mobile number is too short')
    .max(15, 'Mobile number is too long').required('Mobile number is required.'),
  // email: Yup.string().required('Email is required.'),
});
export default contactFormSchema;
