import { STATUS_TYPE } from '../../../enum';

export interface HelpSupportVideoCategoryResponse {
  status: string;
  message: string;
  data: HelpSupportVideoCategory[];
}

export interface HelpSupportVideoCategory {
  id?: number;
  name?: string;
  status?: number;
  created_at?: string;
  updated_at?: string;
  user_id?: number;
}


export interface HelpSupportVideoReponse {
  status: {
    code: string,
  },
  data?: {
    current_page?: number,
    data: HelpSupportVideoItem[];
    first_page_url?: string;
    from?: number;
    last_page?: number;
    last_page_url?: string;
    next_page_url?: string;
    path?: string;
    per_page?: number;
    prev_page_url?: string;
    to?: number;
    total?: number;
  }
}


export interface HelpSupportVideoItem {
  id?: number;
  title?: string;
  description?: string;
  category_id?: number;
  position?: number;
  link?: string;
  filename?: string;
  user_id?: number;
  created_at?: string;
  updated_at?: string;
  file_type?: string;
  thumb_name?: string;
  thumb_link?: string;
  migrate_s3?: number;
}


interface InitialState {
  categoryList: HelpSupportVideoCategory[];
  videoList: HelpSupportVideoItem[];
  searchText: string,
  status: STATUS_TYPE;
  page: number;
  hasMore: boolean;
  isError: boolean;
  isLoading: boolean;
  isCategoryLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}


export const initialState: InitialState = {
  categoryList: [],
  videoList: [],
  searchText: '',
  status: 'idle',
  page: 0,
  hasMore: true,
  isError: false,
  isLoading: false,
  isCategoryLoading: false,
  isSuccess: false,
  errorMessage: '',
};
