import { createSlice } from '@reduxjs/toolkit';
import { registerUser, checkUsername, checkEmailAddress } from './api';
import { initialState } from './api/interface';
import { setItem } from '../../utils/localStorage';
import { config } from '../../utils/utils';

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {

      if (action.payload.data.status === 'success') {
        setItem(config.AUTH_KEY, action.payload?.data?.data?.api_token);
        setItem(config.INFO, action.payload.data.data);
        state.user = action.payload.data.data;

        if (action.payload?.data?.data?.api_token) {
          setItem(config.AUTH_KEY, action.payload?.data?.data?.api_token);
        }
        setItem(config.INFO, action.payload.data.data);
        state.isError = false;
        state.status = 'succeeded';
      } else {
        state.isError = true;
        state.errorMessage = action.payload.data.message;
      }
      state.isLoading = false;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.status = 'failed';
    });
    builder.addCase(checkUsername.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(checkUsername.fulfilled, (state, action) => {
      if (action.payload.data.status === 'success') {
        state.isError = false;
        state.status = 'succeeded';
      } else {
        state.isError = true;
        state.errorMessage = action.payload.data.message;
        state.status = 'failed';
      }
      state.isLoading = false;
    });
    builder.addCase(checkUsername.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.status = 'failed';
    });
    builder.addCase(checkEmailAddress.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(checkEmailAddress.fulfilled, (state, action) => {
      if (action.payload.data.status === 'success') {
        state.isError = false;
        state.status = 'succeeded';
      } else {
        state.isError = true;
        state.errorMessage = action.payload.data.message;
      }
      state.isLoading = false;
    });
    builder.addCase(checkEmailAddress.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.status = 'failed';
    });
  },
});

export default registerSlice.reducer;
