import { Box, CircularProgress, Grid, Paper, Rating, Stack, Typography, useMediaQuery } from "@mui/material";
import RenderProfilePhoto from "../RenderProfilePhoto";
import { UserInfo } from "../../store/userInfo/api/interface";
import moment from "moment";
import { UserReviewItem } from "../../store/reviewForm/api/interface";
import ReviewItemView from "./ReviewItemView";
import { useCallback, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { getUserReviews } from "../../store/reviewForm/api";
import { checkIsProProOrProOneOrProPlusUser } from "../../utils/checkPro";


interface IProps {
    isSmallScreen: boolean,
    userInfo: UserInfo,
    reviewColumns: number,
    backgrounColor?: string;
    isHoRizontal?: boolean;
}

const UserReviewView: React.FC<IProps> = ({ isSmallScreen, userInfo, reviewColumns, backgrounColor, isHoRizontal }) => {
    const dispatch = useAppDispatch();
    const { data: userReviewList, isLoading: isReviewLoading, hasMore: hasMoreReview, page: reviewPage } = useAppSelector((state) => state?.reviewForm);
    const userReviewObserver = useRef<IntersectionObserver>();

    const userReviewLastElementRef = useCallback(
        // (*)
        (node: HTMLDivElement | null) => {
            if (isReviewLoading) return;
            if (userReviewObserver.current) userReviewObserver.current.disconnect();
            userReviewObserver.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMoreReview) {
                    dispatch(
                        getUserReviews({
                            userId: userInfo?.id ?? 0,
                            pageNo: reviewPage + 1,
                        })
                    );
                }
            });
            if (node) userReviewObserver.current.observe(node);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isReviewLoading, hasMoreReview]
    );




    useEffect(() => {
        if (userInfo?.id && userReviewList.length == 0 && checkIsProProOrProOneOrProPlusUser(userInfo)) {
            dispatch(
                getUserReviews({
                    userId: userInfo?.id ?? 0,
                    pageNo: 1,
                })
            );
        }
    }, [userInfo?.id]);


    return (
        <Paper
            variant='outlined'
            sx={{
                borderRadius: '10px',
                minWidth: '100%',
                position: 'relative',
                p: 0,
                border: 'unset',
                ...(isHoRizontal ? { background: 'transparent' } : {})
            }}
        >

            {(isHoRizontal == true) ? (<Box sx={{ maxWidth: "calc(100vw - 32px)", overflow: "hidden", mt: 1 }}>
                <Stack flexDirection={'row'} sx={{ overflow: "auto" }}>

                    {userReviewList &&
                        userReviewList.map((item: UserReviewItem, index: number) => (
                            <Grid
                                item
                                xs={12}
                                md={(reviewColumns == 1) ? 12 : 6}
                                key={`user-review-${index}`}
                                ref={
                                    index + 1 === userReviewList?.length
                                        ? userReviewLastElementRef
                                        : undefined
                                }
                                sx={{ p: 1, minWidth: "100%" }}
                            >
                                <ReviewItemView userReview={item} backgrounColor={backgrounColor} />
                            </Grid>
                        ))}


                    {isReviewLoading && (

                        <CircularProgress sx={{ m: 1 }} />

                    )}
                </Stack></Box>) : (<Grid xs={12} container sx={{ m: 0, p: 0, minWidth: '100%' }}>
                    {userReviewList &&
                        userReviewList.map((item: UserReviewItem, index: number) => (
                            <Grid
                                item
                                xs={12}
                                md={(reviewColumns == 1) ? 12 : 6}
                                key={`user-review-${index}`}
                                ref={
                                    index + 1 === userReviewList?.length
                                        ? userReviewLastElementRef
                                        : undefined
                                }
                                sx={{ p: 1 }}
                            >
                                <ReviewItemView userReview={item} backgrounColor={backgrounColor} />
                            </Grid>
                        ))}
                    {isReviewLoading && (
                        <Stack
                            flexDirection={'row'}
                            display={'flex'}
                            justifyContent={'center'}
                            sx={{ width: '100%', mt: 1, mb: 1 }}
                        >
                            <CircularProgress />
                        </Stack>
                    )}
                </Grid>)}
        </Paper>
    );
};

export default UserReviewView;
