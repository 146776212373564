
import { STATUS_TYPE } from '../../../enum';

export interface MediaLibraryCategoryReponse {
  status: {
    code: string,
  },
  data: {
    current_page?: number,
    data: MediaLibraryCategoryItem[];
    first_page_url?: string;
    from?: number;
    last_page?: number;
    last_page_url?: string;
    next_page_url?: string;
    path?: string;
    per_page?: number;
    prev_page_url?: string;
    to?: number;
    total?: number;
  }
}

export interface MediaLibraryCategoryItem {
  id?: number;
  name?: string;
  status?: number;
  created_at?: string;
  updated_at?: string;
  user_id?: number;
}



export interface MediaLibraryReponse {
  status: {
    code: string,
  },
  data: {
    current_page?: number,
    data: MediaLibraryItem[];
    first_page_url?: string;
    from?: number;
    last_page?: number;
    last_page_url?: string;
    next_page_url?: string;
    path?: string;
    per_page?: number;
    prev_page_url?: string;
    to?: number;
    total?: number;
  }
}

export interface MediaLibraryItem {
  id?: number;
  position?: number;
  user_id?: number;
  tags?: string;
  title?: string;
  filename?: string;
  link?: string;
  thumbnail?: string;
  thumb_filename?: string;
  type?: string;
  status?: number;
  created_at?: string;
  updated_at?: string;
  migrate_s3?: number;
}


interface InitialState {
  mediaLibraryCategoryList: MediaLibraryCategoryItem[];
  mediaLibraryMediaModelList: MediaLibraryItem[];
  categoryPage: number;
  mediaPage: number;
  isCategoryLoading: boolean;
  isMediaLoading: boolean;
  mediaTag: string;
  isLoadMoreCategory: boolean;
  isLoadMoreMedia: boolean;
  mediaLibraryType?: string;
}

export const initialState: InitialState = {
  mediaLibraryCategoryList: [],
  mediaLibraryMediaModelList: [],
  categoryPage: 0,
  mediaPage: 0,
  isCategoryLoading: false,
  isMediaLoading: false,
  mediaTag: '',
  isLoadMoreCategory: true,
  isLoadMoreMedia: true,
  mediaLibraryType: undefined
};
