import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import {
  CompanyFeedResponse,
  CompanyFeedRequest,
  CreateUpdateCompanyFeedRequest,
  PinCompanyFeedResponse,
  ReportSocialFeedResponse,
} from './interface';
import { MediaType } from '../../../enum';
import { Platform } from '../../userInfo/api/interface';
import { toastHandler } from '../../../utils/toastHandler';
import { getItem } from '../../../utils/localStorage';
import { config } from '../../../utils/utils';

export const getSocialFeeds = createAsyncThunk(
  'socialFeed/getSocialFeeds',
  async (data: CompanyFeedRequest, { rejectWithValue }) => {
    try {
      let endPoint = '/api/company-feeds/get-company-feeds';



      /*
      let userToken = getItem(config.AUTH_KEY);
            if (userToken.length > 0) {
              endPoint = '/api/company-feeds/get-user-company-feeds';
            }*/

      const response = await axiosInstance.get<CompanyFeedResponse>(`${endPoint}${data.companyId != null ? `/${data.companyId}` : ''}`, { params: data });
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const createUpdateSocialFeed = createAsyncThunk(
  'socialFeed/createUpdateSocialFeed',
  async (data: CreateUpdateCompanyFeedRequest, { rejectWithValue }) => {
    try {
      const request = new FormData();
      request.append('user_id', `${data.userId}`);
      request.append('company_id', `${data.companyId ?? ''}`);
      request.append('title', data.title);
      request.append('description', data.description);

      data.deleteContents.forEach((item, index) => {
        request.append(`delete_contents[${item}]`, `${item}`);
      });

      let videoIndex = 0;
      let imageIndex = 0;
      let youtubeIndex = 0;
      let assetIndex = 0;

      data.mediaData.forEach((contentFile, index) => {
        if (contentFile.contents != null) {
          if (contentFile.title.length > 0) {
            request.append(
              `update_content_title[${contentFile.contents?.id}]`,
              `${contentFile.title ?? ''}`
            );
          }
          if (contentFile.description.length > 0) {
            request.append(
              `media_description[${contentFile.contents?.id}]`,
              `${contentFile.description ?? ''}`
            );
          }

          if (contentFile.type == MediaType.image && contentFile.file != null) {
            var imageName = contentFile.file.name;
            request.append(
              `update_content_file[${contentFile.contents?.id}]`,
              contentFile.file
            );
          } else if (
            contentFile.type == MediaType.video &&
            contentFile.thumbnail != null
          ) {
            var thumbnailName = contentFile.thumbnail.name;
            request.append(
              `update_content_file[${contentFile.contents?.id}]`,
              contentFile.thumbnail
            );
          }

          request.append(
            `media_positions[${index}]`,
            contentFile.contents?.id.toString() ?? ''
          );
        } else {
          if (contentFile.platform == Platform.Youtube) {
            if ((contentFile?.thumbnail_link?.trim().length ?? 0) > 0) {
              request.append(
                `youtube_thumbnails_link[${youtubeIndex}]`,
                `${contentFile.thumbnail_link ?? ''}`
              );
            } else if (contentFile.thumbnail != null) {
              request.append(
                `youtube_thumbnails[${youtubeIndex}]`,
                contentFile.thumbnail
              );
            }
            request.append(`youtube[${youtubeIndex}]`, `${contentFile.link ?? ''}`);
            youtubeIndex++;
            request.append(`media_positions[$i]`, `${contentFile.link ?? ''}`);
            request.append(`titles[${contentFile.link}]`, `${contentFile.title ?? ''}`);
            request.append(`media_description[${contentFile.link}]`, `${contentFile.description ?? ''}`);
          }


          if (contentFile.platform == Platform.asset) {
            if ((contentFile?.thumbnail_link?.trim().length ?? 0) > 0) {

              request.append(`asset_thumbnails_link[${assetIndex}]`, contentFile.thumbnail_link ?? "");

            }
            request.append(`asset[${assetIndex}]`, `${contentFile.link}`);
            assetIndex++;
            request.append(`media_positions[${index}]`, `${contentFile.link ?? ''}`);
            request.append(`titles[${contentFile.link}]`, `${contentFile.title}`);
            request.append(`asset_types[${contentFile.link}]`, `${contentFile.type}`);
            request.append(`media_description[${contentFile.link}]`, `${contentFile.description}`);
          }

          if (contentFile.type == MediaType.image && contentFile.file != null) {
            var imageName = contentFile.file.name;
            request.append(`images[${imageIndex}]`, contentFile.file);
            imageIndex++;
            request.append(`media_positions[${index}]`, imageName);
            request.append(
              `titles[${imageName}]`,
              `${contentFile.title ?? ''}`
            );
            request.append(
              `media_description[${imageName}]`,
              `${contentFile.description ?? ''}`
            );
          } else if (
            contentFile.file != null &&
            contentFile.thumbnail != null
          ) {
            var videoName = contentFile.file.name;
            request.append(`videos[${videoIndex}]`, contentFile.file);
            request.append(`thumbnails[${videoIndex}]`, contentFile.thumbnail);
            // request.fields.addEntries([MapEntry("titles[$videoIndex]", contentFile.title)]);
            videoIndex++;
            request.append(`media_positions[${index}]`, videoName);
            request.append(
              `titles[${videoName}]`,
              `${contentFile.title ?? ''}`
            );
            request.append(
              `media_description[${videoName}]`,
              `${contentFile.description ?? ''}`
            );
          }
        }
      });
      const response = await axiosInstance.post(
        `/api/company-feeds${data.feedId != null ? `/${data.feedId}` : ''}`,
        request,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSocialFeed = createAsyncThunk(
  'socialFeed/deleteSocialFeed',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `/api/company-feeds/${data.feedId}`
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getSocialFeed = createAsyncThunk(
  'get/getSocialFeed',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/api/company-feeds/${data.feedId}`
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getCompanyPinSocialFeeds = createAsyncThunk(
  'socialFeed/getCompanyPinSocialFeeds',
  async (data: CompanyFeedRequest, { rejectWithValue }) => {
    let endPoint = '/api/company-feeds/get-company-pin-feeds';
    let userToken = getItem(config.AUTH_KEY);

    if (userToken.length > 0) {
      endPoint = '/api/company-feeds/get-user-company-pin-feeds';
    }

    try {
      const response = await axiosInstance.get<PinCompanyFeedResponse>(
        `${endPoint}${data.companyId != null ? `/${data.companyId}` : ''
        }`,
        { params: data }
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const pinSocialFeed = createAsyncThunk(
  'socialFeed/pinSocialFeed',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/api/company-feeds/pin/${data.feedId}`
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const unpinSocialFeed = createAsyncThunk(
  'socialFeed/unpinSocialFeed',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/api/company-feeds/unpin/${data.feedId}`
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);



export const createUpdateUserCompanyFeedReaction = createAsyncThunk(
  'socialFeed/createUpdateUserCompanyFeedReaction',
  async (data: { companyFeedId: number, reactionId?: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        '/api/company-feeds/reaction',
        {
          'company_feed_id': data.companyFeedId,
          'reaction_id': data.reactionId
        }
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);



export const addCompanyFeedComment = createAsyncThunk(
  'socialFeed/addCompanyFeedComment',
  async (data: { companyFeedId: number, comment: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        '/api/company-feeds/comment',
        {
          'company_feed_id': data.companyFeedId,
          'comment': data.comment
        }
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);


export const addCompanyFeedShare = createAsyncThunk(
  'socialFeed/addCompanyFeedShare',
  async (data: { postById: number, companyFeedId: number, actionType: string, targetId?: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        '/api/company-feeds/share',
        {
          'postby_id': data.postById,
          'company_feed_id': data.companyFeedId,
          'action_type': data.actionType,
          'target_id': data.targetId,
        }
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);



export const reportSocialFeed = createAsyncThunk(
  'socialFeed/reportSocialFeed',
  async (data: {
    user_id: number,
    reportable_id: number,
    report_code: string,
    message?: string,
    report_type: string
  }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<ReportSocialFeedResponse>(
        '/api/user-report/save',
        data
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

