import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button, { ButtonProps } from "@mui/material/Button";
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Stack, Tab, Tabs, useMediaQuery } from "@mui/material";
import Nav from "../../assets/blue_smile.png";
import { RiStarSFill } from "react-icons/ri";
import { AppColors, config } from "../../utils/utils";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../store";
import Loader from '../../components/Loader';

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import VideoTutorialModel from "../../components/modal/VideoTutorialModel";
import useToggle from "../../hooks/useToggle";
import { AffiliateLandingPageVideoItem } from "../../store/affiliateLandingPageVideo/api/interface";
import { getUserInfo } from "../../store/userInfo/api";
import { UserInfo } from "../../store/userInfo/api/interface";
import PersonIcon from '@mui/icons-material/Person';
import PreferredPartnersBlueIcon from '../../assets/preferredPartners_blue.svg';
import MediaBlueIcon from '../../assets/media_blue.svg';
import useWindowSize from "../../utils/useWindowSize";
import { analytics } from "../../store/shareRefer/api";
import ContactFormModal from '../../components/ContactFormModal';
import { setUserInfo } from "../../store/userInfo/userInfoSlice";
import { ContactFormSourceType } from "../../enum";
import { getRejectionFreePageVideos } from "../../store/rejectionFreePageVideo/api";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { checkIsProProOrProOneOrProPlusUser } from "../../utils/checkPro";
import GroupNetworkModal from "../../components/GroupNetworkModal";
import { LandingPageNewNavBar } from "../../components/LandingPageNewNavBar";
import HeaderTabItem from "../../components/referral/HeaderTabItem";
import link from '../../assets/link.svg';
import media from '../../assets/media.svg';
import partners from '../../assets/partners.svg';
import profile from '../../assets/profile.svg';
import social from '../../assets/social.svg';

const OpenButton = styled(Button)<ButtonProps>(({ theme }) => ({
  // backgroundColor: "#00cc00",
  backgroundImage: 'linear-gradient(0deg, #46FC05 0%, #31B103 50%, #46FC05 100%)',
  color: "#ffffff",
  borderRadius: "30px",
  fontSize: "small",
  fontWeight: "bold",
  "&:hover": {
    background: "white",
    color: "#00cc00",
  },
  minWidth: "122px"
}));

const MBXRejectionFree = () => {
  const toolbarMatches = useMediaQuery("(max-width:745px)");
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();
  const rejectionFreePageVideoList = useAppSelector((state) => state.rejectionFreePageVideo.data);
  const rejectionFreePageVideoResult = useAppSelector((state) => state.rejectionFreePageVideo.searchResult);
  const mbxOverviewVideo = useAppSelector((state) => state.rejectionFreePageVideo.mbxOverview);
  const isLoading = useAppSelector((state) => state.tutorialVideo.isLoading);
  const authUserInfo = useAppSelector((state) => state?.auth?.user);

  const [isOverviewVideoPlayed, setOverviewVideoPlayed] = useState(false);


  const [lastPlatVideoId, setLastPlatVideoId] = useState<number | undefined>(undefined);

  const [searchParams] = useSearchParams();
  const [referredByLink, setReferredByLink] = useState('');
  const [referrUserInfo, setReferrUserInfo] = useState<UserInfo | undefined>(undefined);


  const windowSize = useWindowSize();
  const [tabMinWidth, setTabMinWidth] = useState(90);
  const matches = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    const newWidth = matches ? windowSize.width / 5 : 90;
    setTabMinWidth(newWidth);
  }, [windowSize]);


  const {
    toggle: toggleContactForm,
    onOpen: onOpenContactForm,
    onClose: onCloseContactForm,
  } = useToggle();

  const {
    toggle: toggleGroup,
    onOpen: onOpenGroup,
    onClose: onCloseGroup,
  } = useToggle();

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleContactFormSubmitSuccess = (contactCode: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete(config.PROSPECT_KEY);
    queryParams.set('sc', contactCode);
    navigate(`/rejection-free?${queryParams.toString()}`);
  };


  useEffect(() => {
    let refferLink = `${config.VBC_URL}`;
    let userName = "";

    if (searchParams.has(config.REFER_KEY)) {
      userName = searchParams.get(config.REFER_KEY) ?? '';
      refferLink += `/affiliate/${userName}`;
    } else {
      refferLink += "/register";
    }
    setReferredByLink(refferLink);

    if (!isLoading) {
      dispatch(getRejectionFreePageVideos());
    }

    if (userName.length > 0) {
      dispatch(getUserInfo({ username: userName })).unwrap().then((res) => {
        if (res.data.data.user.length > 0) {
          setReferrUserInfo(res.data.data.user[0]);
          dispatch(setUserInfo(res.data));
        }
      });
    }
  }, []);


  useEffect(() => {
    if (referrUserInfo) {
      if (searchParams.has(config.PROSPECT_KEY) && searchParams.get(config.PROSPECT_KEY) && checkIsProProOrProOneOrProPlusUser(referrUserInfo)) {
        onOpenContactForm();
      }
      saveLadingPageViewActivity();
    }
  }, [referrUserInfo]);

  const saveLadingPageViewActivity = () => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let elid = searchParams.get('elid');
      let loginUserId = authUserInfo?.id;

      dispatch(
        analytics({
          route: '/activity/save',
          data: {
            actionType: 'view',
            sourceType: 'link_share',
            module: 'group_landing_page',
            targetId: referrUserInfo?.id,
            identifiableId: referrUserInfo?.id,
            ...(loginUserId && { viewableId: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
            ...((!loginUserId && elid) && { elid: elid })
          },
        })
      );
    }
  }

  const handleGetKnoCard = () => {
    onOpenGroup();
  };

  const [openTutorialVideo, setOpenTutorialVideo] = useState<AffiliateLandingPageVideoItem | undefined>(undefined);

  const {
    toggle: togglenTutorialVideo,
    onOpen: onOpenTutorialVideo,
    onClose: onCloseTutorialVideo,
  } = useToggle();


  useEffect(() => {
    if (openTutorialVideo != undefined) {
      onOpenTutorialVideo();
    }
  }, [openTutorialVideo]);

  useEffect(() => {
    if (!togglenTutorialVideo) {
      setOpenTutorialVideo(undefined);
    }
  }, [togglenTutorialVideo]);

  const featurs = [
    {
      id: 0.1,
      icon: '/static/rejection_free/video_value_1.png',
      title: 'Messaging',
      video: 'https://storage-prod.knocard.net/rejection_free/KnoCard_Messaging.mp4'
    },
    {
      id: 0.2,
      icon: '/static/rejection_free/video_value_2.png',
      title: 'Search',
      video: 'https://storage-prod.knocard.net/rejection_free/Search.mp4'
    },
    {
      id: 0.3,
      icon: '/static/rejection_free/video_value_3.png',
      title: 'Affiliate',
      video: 'https://storage-prod.knocard.net/rejection_free/BrandPartnerAffiliate.mp4'
    },
    {
      id: 0.4,
      icon: '/static/rejection_free/video_value_4.png',
      title: 'Reporting',
      video: 'https://storage-prod.knocard.net/rejection_free/Reporting.mp4'
    },
    {
      id: 0.5,
      icon: '/static/rejection_free/video_value_5.png',
      title: 'Web Page',
      video: 'https://storage-prod.knocard.net/rejection_free/WebPage.mp4'
    },
    {
      id: 0.6,
      icon: '/static/rejection_free/video_value_6.png',
      title: 'QR',
      video: 'https://storage-prod.knocard.net/rejection_free/QRCodes.mp4'
    },
    {
      id: 0.7,
      icon: '/static/rejection_free/video_value_7.png',
      title: 'Card Scanner',
      video: 'https://storage-prod.knocard.net/rejection_free/CardScanner.mp4'
    },
    {
      id: 0.8,
      icon: '/static/rejection_free/video_value_8.png',
      title: 'External Link',
      video: 'https://storage-prod.knocard.net/rejection_free/ExternalLinks.mp4'
    },
    {
      id: 0.9,
      icon: '/static/rejection_free/video_value_9.png',
      title: 'Partners',
      video: 'https://storage-prod.knocard.net/rejection_free/PrefPatners.mp4'
    },
    {
      id: 0.10,
      icon: '/static/rejection_free/video_value_10.png',
      title: 'Filter',
      video: 'https://storage-prod.knocard.net/rejection_free/Filters.mp4'
    },
    {
      id: 0.11,
      icon: '/static/rejection_free/video_value_11.png',
      title: 'Notifications',
      video: 'https://storage-prod.knocard.net/rejection_free/Notifications.mp4'
    },
    {
      id: 0.12,
      icon: '/static/rejection_free/video_value_12.png',
      title: 'Playlists',
      video: 'https://storage-prod.knocard.net/rejection_free/KnoCardPlaylist.mp4'
    }
  ];



  const getParamDelimiter = (params: string) => {
    return (params.trim().length > 0) ? "&" : "?";
  };

  const tabHandleChange = (event: any, newValue: number) => {
    let path = `/${referrUserInfo?.username ?? ""}`
    switch (newValue) {
      case 0:
        path += "/";
        break;
      case 1:
        path += "/media";
        break;
      case 2:
        path += "/company";
        break;
      case 3:
        path += "/external-link";
        break;
      case 4:
        path += "/preferred-partners";
        break;
    }

    let params = "";
    const sc = searchParams.get('sc');
    const sb = searchParams.get('sb');
    const elid = searchParams.get('elid');

    if (sc != undefined) {
      params += `${getParamDelimiter(params)}sc=${encodeURIComponent(sc)}`;
    }

    if (sb != undefined) {
      params += `${getParamDelimiter(params)}sb=${encodeURIComponent(sb)}`;
    }
    if (elid != undefined) {
      params += `${getParamDelimiter(params)}elid=${encodeURIComponent(elid)}`;
    }

    path += params;

    navigate(path);

  };





  const handleOnVideoPlayActivity = (videoTitle: string, second: number, duration: number) => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let loginUserId = authUserInfo?.id;

      dispatch(
        analytics({
          route: '/landing-page/video-watch',
          data: {
            target_id: referrUserInfo?.id ?? 0,
            module: "group_landing_page",
            title: videoTitle,
            target_page: "mbx-rejection-free",
            video_watch_seconds: second,
            video_duration_seconds: duration,
            ...(loginUserId && { viewable_id: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
          },
        })
      );
    }
  };


  const handleOnPauseOrCompleteVideoActivity = (videoTitle: string, second: number, duration: number) => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let loginUserId = authUserInfo?.id;

      dispatch(
        analytics({
          route: '/landing-page/video-watch-time',
          data: {
            target_id: referrUserInfo?.id ?? 0,
            module: "group_landing_page",
            title: videoTitle,
            target_page: "mbx-rejection-free",
            video_watch_seconds: second,
            video_duration_seconds: duration,
            ...(loginUserId && { viewable_id: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
          },
        })
      );
    }
  };


  const handleOnVideoPlay = (videoId: number, time: number, duration: number) => {

    var videoTitle = getVideoTitleFromVideoId(videoId)

    if (lastPlatVideoId !== videoId) {
      setLastPlatVideoId(videoId);
      handleOnVideoPlayActivity(videoTitle, time, duration);
    } else if (time !== 0) {
      handleOnPauseOrCompleteVideoActivity(videoTitle, time, duration);
    }
  }

  const getVideoTitleFromVideoId = (videoId: number) => {

    var videoTitle = "";
    if (videoId < 1) {
      featurs.map((item) => {
        if (item.id == videoId) {
          videoTitle = item.title;
        }
      });
    } else {
      rejectionFreePageVideoList.map((item) => {
        if (item.id == videoId) {
          videoTitle = item.title;
        }
      });
    }

    return videoTitle;

  }



  if (isLoading) return <Loader />;

  return (
    <React.Fragment>
      <LandingPageNewNavBar userInfo={referrUserInfo} affiliateUserName={referrUserInfo?.username} />
      {/* LINE SECTION */}
      <Box

        sx={{
          backgroundColor: '#349fce',
        }}
      ></Box>
      <Box
        sx={{
          backgroundColor: '#f8a048',
        }}
      ></Box>



      <section>
        <Box
          sx={{
            backgroundImage: "url(/static/rejection_free/rejection_free_bg.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            pt: 3,
            pb: 2
          }}

        >
          <Stack flexDirection={"column"} sx={{ mb: 1, alignContent: { xs: "flex-start", sm: "center" }, alignItems: { xs: "start", sm: "center" } }}>
            <Box sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FEFF09",
              width: "fit-content",
              mr: 1,
              pb: "10px",
              pt: "10px",

              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
              borderTopLeftRadius: { xs: "0px", sm: "10px" },
              borderBottomLeftRadius: { xs: "0px", sm: "10px" }
            }}>
              <span
                className={`icon-18`}
                style={{
                  fontSize: '40px',
                  height: '40px',

                  marginRight: '1rem',
                  marginLeft: '10px',

                  color: '#000000',
                }}
              ></span>
              <Typography
                component='p'
                sx={{
                  fontSize: { xs: '5vw', sm: '1.8rem' },
                  color: '#0E2C22',
                  textAlign: 'center',
                  pl: 0,
                  pr: 2,
                  pt: 0.5,
                  lineHeight: 1,
                  pb: 0.5,
                  borderRadius: "10px",
                  fontWeight: "bold",
                  textShadow: "-2px -1px #8FA524",
                }}
              >
                SBVE EVENT PROMOTION
              </Typography>
            </Box>
          </Stack>
          <Stack flexDirection={"column"} sx={{ mb: 1, alignContent: { xs: "flex-end", sm: "center" }, alignItems: "center" }}>
            <Box sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "fit-content",
              pr: 2,
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              borderTopRightRadius: { xs: "0px", sm: "10px" },
              borderBottomRightRadius: { xs: "0px", sm: "10px" }

            }}>
              <Typography
                component='p'
                sx={{
                  fontSize: { xs: '5vw', sm: '1.8rem' },
                  color: '#000000',
                  textShadow: "-1px -1px gray",
                  textAlign: 'center',
                  pl: 2,
                  pr: 2,
                  pt: 0.5,
                  pb: 0.5,
                  borderRadius: "10px",
                  fontWeight: "bold",
                  '& span': {
                    color: '#FEFF09',
                    textShadow: "-1px -1px #3C936C",
                  }
                }}
              >
                A <Box component={'span'} sx={{
                  borderBottom: "10px solid transparent",
                  borderImage: "url('/static/images/black_line.png') 42 stretch"
                }}>MUST HAVE</Box> TOOL

              </Typography>
            </Box>
            <Typography
              component='p'
              sx={{
                fontSize: { xs: '5vw', sm: '1.6rem' },
                textShadow: "-1px -1px #419463",
                color: '#FEFF09',
                textAlign: 'center',
                pl: 2,
                pr: 2,
                pt: 0.5,
                pb: 0.5,
                borderRadius: "10px",
                fontWeight: "bold",
              }}
            >
              CONNECT, MESSAGE AND SHARE
            </Typography>
          </Stack>


          <Box sx={{
            textAlign: "center", width: "100%", display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <Typography
              component='p'
              sx={{
                fontSize: { xs: '1.0rem', md: '1.3rem' },
                color: '#fff',
                backgroundColor: "#000",
                textAlign: 'center',
                mt: 1,
                mb: 2,
                p: 2,
                marginLeft: "10px",
                borderRadius: "10px",
                '& span': {
                  fontWeight: "bold",
                  color: "#1DD6FF"
                }
              }}
            >
              CONNECT WITH <span>ALL</span> THE ATTENDEES
            </Typography>
            <Box component={"img"} src={"/static/rejection_free/white_line.png"}></Box>
          </Box>

          {mbxOverviewVideo && (
            <Stack flexDirection={"row"} justifyContent={'center'} sx={{
              mt: 2, mb: 2,
              ml: 1, mr: 1
            }}>
              <Box sx={{
                textAlign: 'center', borderStyle: "solid", background: 'linear-gradient(to bottom, #22F7EF, #ffffff00)', width: 'fit-content',

                borderWidth: "2px",
                borderImage: "linear-gradient(to bottom,  #ffffff00, #ffffff)",
                borderImageSlice: 1
              }}>
                <Box
                  component={'video'}
                  autoPlay={false}
                  id='intro_video'
                  controls
                  poster={mbxOverviewVideo.thumb_link}
                  sx={{
                    pt: { xs: 1, md: 3 },
                    pl: { xs: 1, md: 5 },
                    pr: { xs: 1, md: 5 },
                    mb: -6,
                    maxWidth: '100%',
                    height: { xs: 'auto', md: '400px' },
                  }}
                  onPlay={(props: any) => {
                    if (!isOverviewVideoPlayed && props.currentTarget.currentTime < 1) {
                      handleOnVideoPlay(mbxOverviewVideo.id, 0, props.currentTarget.duration || 0);
                      setOverviewVideoPlayed(true);
                    }
                  }}
                  onPause={(props: any) => {
                    handleOnVideoPlay(mbxOverviewVideo.id, props.currentTarget.currentTime, props.currentTarget.duration || 0);
                  }}
                >
                  <source src={mbxOverviewVideo.link} type='video/mp4' />
                </Box>
              </Box>
            </Stack>
          )}

          <Typography
            component='h1'
            sx={{
              lineHeight: '1.2',
              fontSize: { xs: "1.2rem", md: '1.5rem' },
              textAlign: "center",
              mt: 7,
              pb: 1,
              pl: 1.5,
              pr: 1.5,
              color: "#ffffff"
            }}
          >
            Breakthrough Tools
          </Typography>
          <Typography
            component='h1'
            sx={{
              lineHeight: '1.2',
              fontSize: { xs: "1.2rem", md: '1.5rem' },
              textAlign: "center",
              pb: 1,
              pl: 1.5,
              pr: 1.5,
              color: "#ffffff",
              "& span": {
                color: "#000000",
                fontWeight: "bold"
              }
            }}
          >
            The market <span>DOESN'T</span> have!
          </Typography>
          <Box sx={{ textAlign: 'center', mt: 2, pb: 1 }}>
            <OpenButton
              sx={{
                paddingLeft: '20px',
                paddingRight: '20px',
                width: 'fit-content',
                textTransform: 'unset',
              }}
              onClick={handleGetKnoCard}
            >
              Get KnoCard
            </OpenButton>
          </Box>
        </Box>
      </section>

      <section>
        <Stack
          sx={{
            pt: 2,
            pb: 2,
            width: "100%",
            backgroundColor: "black",
            position: "relative",
            backgroundImage: "url(/static/rejection_free/discover_knocard.png)",
            backgroundSize: "cover"
          }}
          flexDirection={'column'}
        >

          <Box flexDirection={'row'} sx={{ position: 'relative', mt: 2 }}>

            <Stack flexDirection={"row"} justifyContent={'center'} alignItems={"center"} sx={{ overflowX: "hidden" }} >
              <Box component={"img"} src={"/static/rejection_free/discover_knocard_left.png"} sx={{ height: "100%" }}></Box>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: '#ffffff',
                  fontSize: { xs: '2.0rem', md: '3.0rem' },
                  textAlign: 'center',
                  "& span": {
                    color: "#1DD6FF"
                  }
                }}
              >
                Discover <span>KNOCARD</span>
              </Typography>
              <Box component={"img"} src={"/static/rejection_free/discover_knocard_right.png"} sx={{ height: "100%" }}></Box>
            </Stack>

            <Typography
              sx={{
                pl: 2,
                pr: 2,
                fontWeight: 'bold',
                color: "#1DD6FF",
                textAlign: 'center',
                fontSize: '1.3rem',
                '& span': {
                  fontWeight: '500',
                  color: '#ffffff',
                }
              }}
            >
              Unprecedented innovation <span>at the forefront</span>
            </Typography>

            <Typography
              sx={{
                pl: 2,
                pr: 2,
                fontWeight: '500',
                color: '#ffffff',
                textAlign: 'center',

                '& span': {
                  fontWeight: 'bold',
                  color: "#1DD6FF",
                }
              }}
            >
              Click to witness how we <span>REDEFINE</span> the <span>GAME</span> through <span>2024</span> technology
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 4,
              p: 3,
              borderRadius: '20px',
            }}
          >

            <Grid container spacing={1}>
              {rejectionFreePageVideoResult.map((tutorialItem, idx) => {
                return (<Grid item xs={6} md={3} mt={2}>
                  <Card
                    key={`tutorial-item-${idx}`}
                    sx={{ maxWidth: 345, backgroundColor: "unset", position: "relative" }}
                  >
                    <CardMedia
                      component='img'
                      height='140'
                      image={tutorialItem.thumb_link}
                      alt={tutorialItem.thumb_name}
                      onClick={() =>
                        setOpenTutorialVideo(tutorialItem)
                      }
                      sx={{
                        border: "2px solid #17B5D2",
                        p: 0.8,
                        borderRadius: "10px"

                      }}
                    />

                    <PlayCircleIcon
                      sx={{
                        position: 'absolute',
                        height: "50%",
                        width: "50%",
                        top: "40%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "#30b0a7e3"
                      }}
                      onClick={() => setOpenTutorialVideo(tutorialItem)}
                    />

                    <Box component={'div'}>
                      <CardActionArea
                        onClick={() =>
                          setOpenTutorialVideo(tutorialItem)
                        }
                      >
                        <CardContent
                          sx={{
                            p: 0.5,
                            '&:last-child': { p: 1 },
                            minHeight: '36px',
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            width: "100%"
                          }}
                        >
                          <Typography
                            variant='body2'
                            color='text.secondary'
                            textAlign={'center'}
                            sx={{
                              overflow: 'hidden',
                              display: '-webkit-box',
                              '-webkit-line-clamp': '1',
                              '-webkit-box-orient': 'vertical',
                              color: '#ffffff',
                              width: "100%"
                            }}
                          >
                            {tutorialItem.title}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Box>
                  </Card> </Grid>
                );
              })}




            </Grid>
          </Box>
        </Stack>
      </section >
      <section>
        <Box
          sx={{
            width: '100%',
            backgroundImage: 'url("/static/rejection_free/video_vault_bg.jpg")',
            backgroundSize: "cover",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pt: 5,
            pb: 5,
          }}
        >
          <Box
            sx={{
              padding: {
                xs: '0 5%',
                sm: '0 7%',
                md: '0 10%',
                lg: '0 15%',
                xl: '0 15%',
              },
            }}
          >
            <Typography
              component='p'
              sx={{
                fontSize: '1.3rem',
                color: '#fff',
                backgroundColor: "#000",
                textAlign: 'center',
                mt: 1,
                mb: 2,
                p: 2,
                borderRadius: "10px",
                '& span': {
                  color: "#1DD6FF"
                }
              }}
            >
              KnoCard is packed with <span>POWER FEATURES</span>, that have been created with purpose

            </Typography>


            <Typography
              component='p'
              sx={{
                color: '#000',
                textAlign: 'center',
                fontSize: "1.5rem",
                mt: 2,
              }}
            >
              Want More?
            </Typography>
            <Typography
              component='p'
              sx={{
                color: '#000',
                fontSize: "1.5rem",
                textAlign: 'center',
                mb: 3,
              }}
            >
              Check out our
            </Typography>

            <Box>
              <Box sx={{ backgroundColor: "#ffffff", mr: 4, ml: 4, borderTopRightRadius: "10px", borderTopLeftRadius: "10px", pt: 1, pb: 1 }}> <Typography textAlign={"center"} sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>Video Vault</Typography> </Box>
              <Grid container sx={{ gridAutoFlow: 'row', backgroundColor: "#000000", borderRadius: "15px", pb: 3, pt: 1 }}>
                {featurs.map((elm, index) => {
                  return (
                    <Grid
                      key={`icon-${index}`}
                      sx={{ mt: 3 }}
                      item
                      xs={6}
                      sm={6}
                      md={4}
                      lg={4}
                      display='flex'
                      justifyContent='center'
                      flexDirection='column'
                      alignItems='center'
                      onClick={() => setOpenTutorialVideo({ id: elm.id, title: elm.title, position: 0, type: "", link: elm.video, created_at: "", updated_at: "", user_id: 0, filename: "", thumb_link: "", thumb_name: "" })}
                    >


                      <Box
                        component={'img'}
                        src={elm.icon}
                        alt=''
                        sx={{
                          width: { xs: '30%', md: '20%' },
                          maxWidth: '90%',
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: '1.0rem',
                          color: '#ffffff',
                          mt: 1,
                        }}
                      >
                        {elm.title}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
      </section>
      <section>
        <Stack flexDirection={'row'} justifyContent={'center'} sx={{ backgroundColor: "#ffffff", position: "relative", textAlign: 'center', pt: 1, pb: 1 }}>
          <Box sx={{ position: "absolute", width: "100%", backgroundColor: "#000000", height: "3px", top: "50%" }}></Box>
          <Typography sx={{ fontSize: "1.3rem", fontWeight: "bold", textAlign: "center", backgroundColor: "#ffffff", zIndex: 5, pl: 2, pr: 2, width: "fit-content" }}>So What's next?</Typography>
        </Stack>
      </section>
      <section>
        <Box
          sx={{
            width: '100%',
            background: 'linear-gradient(to right, #041C27, #1174B6)',
            pt: 5,
            pb: 10,
            position: 'relative',

          }}
        >

          <Typography
            sx={{
              fontSize: {
                xs: '1.3rem',
                sm: '1.4rem',
                md: '2rem',
                lg: '2.3rem',
                xl: '2.3rem',
              },
              color: '#ffffff',
              textAlign: 'center',
              fontFamily: "'Lato', sans-serif",
              fontWeight: 'bold',
              pl: 1,
              pr: 1,
              "& span": {
                color: "#1DD6FF"
              }

            }}
          >
            Click below and get your <span>KnoCard</span>
          </Typography>
          <Box sx={{ textAlign: 'center', mt: 4, pb: 2 }}>
            <OpenButton
              sx={{
                paddingLeft: '20px',
                paddingRight: '20px',
                width: 'fit-content',
                textTransform: 'unset',
              }}
              onClick={handleGetKnoCard}
            >
              Get KnoCard
            </OpenButton>
          </Box>

        </Box>
      </section>


      {
        referrUserInfo && (
          <AppBar
            position='fixed'

            sx={{ top: 'auto', bottom: 0, backgroundColor: AppColors.backgroundColor, }}
          >
            <Toolbar
              disableGutters
              sx={{
                backgroundColor: AppColors.backgroundColor,
              }}
            >
              <Box
                gap={1}
                sx={{
                  width: '100%',
                  '@media (max-width: 900px)': {
                    marginTop: '0px',
                    maxWidth: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    '& > *': {
                      flex: '0',
                    },
                  },
                }}
              >

                <Tabs
                  onChange={tabHandleChange}
                  sx={{
                    overflow: 'inherit',
                    alignContent: 'center',
                    width: '100%',
                    color: 'white',
                  }}
                  orientation={'horizontal'}
                  TabIndicatorProps={{ sx: { display: 'none' } }}
                  variant={isSmallScreen ? 'standard' : 'fullWidth'}
                >
                  <HeaderTabItem
                    isSmallScreen={true}
                    icon={
                      <Box
                        component={'img'}
                        src={profile}
                        sx={{
                          marginRight: '1rem',
                          marginLeft: '10px',
                          height: '25px',
                          width: '25px',
                        }}
                      />
                    }
                    label='Profile'
                    className={'hover-div'}
                    value={0}
                    sx={{
                      ...(isSmallScreen
                        ? { minWidth: tabMinWidth, maxWidth: tabMinWidth }
                        : {}),
                      flexDirection: isSmallScreen ? 'column' : 'row',
                    }}
                  />

                  <HeaderTabItem
                    isSmallScreen={true}
                    icon={
                      <Box
                        component={'img'}
                        src={media}
                        sx={{
                          marginRight: '1rem',
                          marginLeft: '10px',
                          height: '25px',
                          width: '25px',
                        }}
                      />
                    }
                    label='Media'
                    className='hover-div'
                    value={1}
                    sx={{
                      ...(isSmallScreen
                        ? { minWidth: tabMinWidth, maxWidth: tabMinWidth }
                        : {}),
                      flexDirection: isSmallScreen ? 'column' : 'row',
                    }}
                  />

                  <HeaderTabItem
                    isSmallScreen={true}
                    icon={
                      <Box
                        component={'img'}
                        src={social}
                        sx={{
                          marginRight: '1rem',
                          marginLeft: '10px',
                          height: '25px',
                          width: '25px',
                        }}
                      />
                    }
                    label='Social'
                    className='hover-div'
                    value={2}
                    sx={{
                      ...(isSmallScreen
                        ? { minWidth: tabMinWidth, maxWidth: tabMinWidth }
                        : {}),
                      flexDirection: isSmallScreen ? 'column' : 'row',
                    }}
                  />

                  <HeaderTabItem
                    isSmallScreen={true}
                    icon={
                      <Box
                        component={'img'}
                        src={link}
                        sx={{
                          marginRight: '1rem',
                          marginLeft: '10px',
                          height: '25px',
                          width: '25px',
                        }}
                      />
                    }
                    label='Links'
                    className='hover-div'
                    value={3}
                    sx={{
                      ...(isSmallScreen
                        ? { minWidth: tabMinWidth, maxWidth: tabMinWidth }
                        : {}),
                      flexDirection: isSmallScreen ? 'column' : 'row',
                    }}
                  />

                  <HeaderTabItem
                    isSmallScreen={true}
                    icon={
                      <Box
                        component={'img'}
                        src={partners}
                        sx={{
                          marginRight: '1rem',
                          marginLeft: '10px',
                          height: '25px',
                          width: '25px',
                        }}
                      />
                    }
                    label='Partners'
                    className='hover-div'
                    value={4}
                    sx={{
                      ...(isSmallScreen
                        ? { minWidth: tabMinWidth, maxWidth: tabMinWidth }
                        : {}),
                      flexDirection: isSmallScreen ? 'column' : 'row',
                    }}
                  />

                </Tabs>
              </Box>
            </Toolbar>
          </AppBar>
        )
      }
      {
        openTutorialVideo && (
          <VideoTutorialModel
            key={`open-tutorial-video-model-${openTutorialVideo.id}`}
            onClose={onCloseTutorialVideo}
            toggle={togglenTutorialVideo}
            tutorialVideoItem={{
              ...openTutorialVideo,
              description: '',
              tag: '',
              status: 1,
              migrate_s3: 1,
            }}
            onVideoPlay={handleOnVideoPlay}
            onVideoPause={handleOnVideoPlay}
          />
        )
      }

      <ContactFormModal
        onClose={onCloseContactForm}
        toggle={toggleContactForm}
        onSubmitSuccessRedirect={false}
        onSubmitSuccess={handleContactFormSubmitSuccess}
        source={ContactFormSourceType.REJECTION_FREE}
      />

      <GroupNetworkModal
        onClose={onCloseGroup}
        toggle={toggleGroup}
        affiliateLink={referredByLink}
        userInfo={referrUserInfo}
      />
    </React.Fragment >
  );
};

export default MBXRejectionFree;
