import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { CompanyFeedResponse } from './interface';
import { toastHandler } from '../../../utils/toastHandler';

export const getCommunityFeed = createAsyncThunk(
  'companyFeed/getCommunityFeed',
  async (companyId: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<CompanyFeedResponse>(`/api/company-feeds/${companyId}`);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);
