
import { STATUS_TYPE } from '../../../enum';

export interface KnoCardLinkSessionNotePerUserRequestResponse {
  status: string;
  message?: string;
  data: {
    current_page?: number;
    data: KnoCardLinkSessionNoteItem[];
    first_page_url?: string;
    from?: number;
    last_page?: number;
    last_page_url?: string;
    next_page_url?: string;
    path?: string;
    per_page?: number;
    prev_page_url?: string;
    to?: number;
    total?: number;
  };
}

export interface KnoCardLinkSessionNoteItem {
  id: number;
  user_id: number;
  to_user_id: number;
  note: string;
  created_at: Date;
  updated_at: Date;
}

export interface PostKnoCardLinkSessionNote {
  id?: number | null;
  to_user_id: number;
  note: string;
}


interface InitialState {
  data: KnoCardLinkSessionNoteItem[];
  page: number;
  total: number;
  status: STATUS_TYPE;
  hasMore: boolean;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

export const initialState: InitialState = {
  data: [],
  page: 0,
  total: 0,
  status: 'idle',
  isError: false,
  hasMore: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
};
