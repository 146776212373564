import { STATUS_TYPE } from '../../../enum';
import { ContactInfo } from '../../contacts/api/interface';
import { EngageActivity } from '../../engagePipeline/api/interface';



export interface ProspectActivityReponse {
  status: {
    code: string,
  },
  data?: {
    current_page?: number,
    data: ProspectActivityItem[];
    first_page_url?: string;
    from?: number;
    last_page?: number;
    last_page_url?: string;
    next_page_url?: string;
    path?: string;
    per_page?: number;
    prev_page_url?: string;
    to?: number;
    total?: number;
  }
}


export interface ProspectActivityItem {
  id?: number;
  user?: ContactInfo;
  contact?: ContactInfo;
  share_to?: ContactInfo;
  share_to_profile?: ContactInfo;
  form_answer?: string;
  activity_type?: string;
  targetable_type?: string;
  target_id?: number;
  targetable_id?: number;
  contact_id?: number;
  engage?: EngageActivity;
  created_at?: string;
  updated_at?: string;
}


interface InitialState {
  data: ProspectActivityItem[];
  searchText: string,
  status: STATUS_TYPE;
  page: number;
  hasMore: boolean;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}


export const initialState: InitialState = {
  data: [],
  searchText: '',
  status: 'idle',
  page: 0,
  hasMore: true,
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
};
