import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box, Tab, TabProps, Tabs, useMediaQuery } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PreferredPartnersBlueIcon from '../assets/preferredPartners_blue.svg';

import link_active from '../assets/link_active.svg';
import link from '../assets/link.svg';
import media_active from '../assets/media_active.svg';
import media from '../assets/media.svg';
import partners_active from '../assets/partners_active.svg';
import partners from '../assets/partners.svg';
import profile_active from '../assets/profile_active.svg';
import profile from '../assets/profile.svg';
import social_active from '../assets/social_active.svg';
import social from '../assets/social.svg';

import social_blue_active from '../assets/social_blue_active.svg';
import profile_blue_active from '../assets/profile_blue_active.svg';
import partners_blue_active from '../assets/partners_blue_active.svg';
import media_blue_active from '../assets/media_blue_active.svg';
import link_blue_active from '../assets/link_blue_active.svg';



import useWindowSize from '../utils/useWindowSize';
import { UserInfo } from '../store/userInfo/api/interface';
import { styled } from '@mui/material/styles';
import { AppColors } from '../utils/utils';

interface CustomTabProps extends TabProps {
  isSmallScreen: boolean;
}
const HeaderTab = styled(Tab)<CustomTabProps>(({ theme, isSmallScreen }) => ({
  padding: '10px',

  ...(!isSmallScreen ? { background: AppColors.grayZeroColor, borderRadius: '10px' } : {}),
  color: AppColors.grayTwoColor,
  textTransform: "capitalize",
  transition: 'border-color 0.3s',
  display: 'flex',
  fontWeight: '500',
  justifyContent: 'flex-start',
  '&.Mui-selected': {

    ...(isSmallScreen ? { color: AppColors.primaryColor } : { borderRadius: '10px', color: AppColors.whiteColor, background: `linear-gradient(0deg, ${AppColors.secondryColor}, ${AppColors.primaryColor})` }),

    fontWeight: '500',
  },
  [theme.breakpoints.up('md')]: {
    minHeight: 0,
    marginTop: '10px',
    marginLeft: 10,
    marginRight: 10,
  },
}));

interface IProps {
  isSmallScreen: boolean;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  value: number;
  userInfo: UserInfo | null;
}

export const MenuTabBar: React.FC<IProps> = ({
  isSmallScreen,
  handleChange,
  value,
  userInfo,
}) => {
  const windowSize = useWindowSize();
  const [tabMinWidth, setTabMinWidth] = useState(90);
  const matches = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    const newWidth = matches ? windowSize.width / 5 : 90;
    setTabMinWidth(newWidth);
  }, [windowSize]);

  return (
    <AppBar
      position={isSmallScreen ? 'fixed' : 'static'}
      sx={{
        boxShadow: 0,
        backgroundColor: isSmallScreen
          ? AppColors.backgroundColor
          : 'transparent',
        ...(isSmallScreen ? { top: 'auto', bottom: 0 } : {})
      }}
    >
      <Toolbar
        disableGutters
      >
        <Box
          gap={1}
          sx={{

            width: '100%',
            '@media (max-width: 900px)': {
              marginTop: '0px',
              maxWidth: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              flexWrap: 'wrap',
              '& > *': {
                flex: '0',
              },
            },
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              overflow: 'inherit',
              alignContent: 'center',
              width: '100%',
              color: 'white',
            }}
            orientation={isSmallScreen ? 'horizontal' : 'vertical'}
            TabIndicatorProps={{ sx: { display: 'none' } }}
            variant='standard'
          >
            <HeaderTab
              isSmallScreen={isSmallScreen}
              icon={
                <Box
                  component={'img'}
                  src={(value === 0) ? isSmallScreen ? profile_blue_active : profile_active : profile}
                  sx={{
                    marginRight: '1rem',
                    marginLeft: '10px',
                    height: '25px',
                    width: '25px',
                  }}
                />
              }
              label='Profile'
              className={'hover-div'}
              value={0}
              sx={{
                ...(isSmallScreen
                  ? { minWidth: tabMinWidth, maxWidth: tabMinWidth }
                  : {}),
                flexDirection: isSmallScreen ? 'column' : 'row',
              }}
            />

            <HeaderTab
              isSmallScreen={isSmallScreen}
              icon={
                <Box
                  component={'img'}
                  src={(value === 1) ? isSmallScreen ? media_blue_active : media_active : media}
                  sx={{
                    marginRight: '1rem',
                    marginLeft: '10px',
                    height: '25px',
                    width: '25px',
                  }}
                />
              }
              label='Media'
              className='hover-div'
              value={1}
              sx={{
                ...(isSmallScreen
                  ? { minWidth: tabMinWidth, maxWidth: tabMinWidth }
                  : {}),
                flexDirection: isSmallScreen ? 'column' : 'row',
              }}
            />

            <HeaderTab
              isSmallScreen={isSmallScreen}
              icon={
                <Box
                  component={'img'}
                  src={(value === 2) ? isSmallScreen ? social_blue_active : social_active : social}
                  sx={{
                    marginRight: '1rem',
                    marginLeft: '10px',
                    height: '25px',
                    width: '25px',
                  }}
                />
              }
              label='Social'
              className='hover-div'
              value={2}
              sx={{
                ...(isSmallScreen
                  ? { minWidth: tabMinWidth, maxWidth: tabMinWidth }
                  : {}),
                flexDirection: isSmallScreen ? 'column' : 'row',
              }}
            />

            <HeaderTab
              isSmallScreen={isSmallScreen}
              icon={
                <Box
                  component={'img'}
                  src={(value === 3) ? isSmallScreen ? link_blue_active : link_active : link}
                  sx={{
                    marginRight: '1rem',
                    marginLeft: '10px',
                    height: '25px',
                    width: '25px',
                  }}
                />
              }
              label='Links'
              className='hover-div'
              value={3}
              sx={{
                ...(isSmallScreen
                  ? { minWidth: tabMinWidth, maxWidth: tabMinWidth }
                  : {}),
                flexDirection: isSmallScreen ? 'column' : 'row',
              }}
            />

            <HeaderTab
              isSmallScreen={isSmallScreen}
              icon={
                <Box
                  component={'img'}
                  src={(value === 4) ? isSmallScreen ? partners_blue_active : partners_active : partners}
                  sx={{
                    marginRight: '1rem',
                    marginLeft: '10px',
                    height: '25px',
                    width: '25px',
                  }}
                />
              }
              label='Partners'
              className='hover-div'
              value={4}
              sx={{
                ...(isSmallScreen
                  ? { minWidth: tabMinWidth, maxWidth: tabMinWidth }
                  : {}),
                flexDirection: isSmallScreen ? 'column' : 'row',
              }}
            />

          </Tabs>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
