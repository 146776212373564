import { createSlice } from '@reduxjs/toolkit';
import { AffiliateLandingPageVideoItem, AffiliateLandingPageVideoResult, initialState } from './api/interface';
import { getCapitalizedString } from '../../utils/utilMethods';
import { stat } from 'fs/promises';
import { affiliateLandingPageVideoTabs } from '../../utils/utils';
import { getAffiliateLandingPageVideos } from './api';
import _ from 'lodash';

const affiliateLandingPageVideoSlice = createSlice({
  name: 'affiliateLandingPageVideo',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAffiliateLandingPageVideos.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getAffiliateLandingPageVideos.fulfilled, (state, action) => {
      state.data = action.payload.data.data;
      let overviewPosition: number = state.data.findIndex(item => item.type == 'intro');
      let fixingTheProblemPosition: number = state.data.findIndex(item => item.type == 'problem');

      if (overviewPosition >= 0) {
        state.overview = state.data[overviewPosition];
      }
      if (fixingTheProblemPosition >= 0) {
        state.fixingTheProblem = state.data[fixingTheProblemPosition];
      }
      state.searchResult = filterVideos(state.searchText, state.data);
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getAffiliateLandingPageVideos.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset } = affiliateLandingPageVideoSlice.actions;
export default affiliateLandingPageVideoSlice.reducer;

const filterVideos = (searchText: string, videoList: AffiliateLandingPageVideoItem[]) => {

  let videoItems: AffiliateLandingPageVideoItem[] = [...videoList];


  let sectionList = [...new Set(videoItems.map((item) => item.type))].filter((item) => (item != 'intro' && item != 'problem')).sort()




  let affiliateLandingPageVideoSectionList: AffiliateLandingPageVideoResult[] = [];

  sectionList.forEach((item) => {
    const sectionItemList: AffiliateLandingPageVideoItem[] = videoItems.filter(obj => obj.type == item).sort((a, b) => a.position - b.position);

    let newTitle = getCapitalizedString(item.replaceAll('_', ' '));
    const newTitleData = _.find(affiliateLandingPageVideoTabs, { code: item });
    if (newTitleData) {
      newTitle = newTitleData.title;
    }

    affiliateLandingPageVideoSectionList.push({ title: newTitle, code: item, data: [...sectionItemList] });

  });



  const customComparator = (sectionOne: AffiliateLandingPageVideoResult, sectionTwo: AffiliateLandingPageVideoResult) => {
    const indexOne = affiliateLandingPageVideoTabs.findIndex((element) => element.code === sectionOne.code);
    const indexTwo = affiliateLandingPageVideoTabs.findIndex((element) => element.code === sectionTwo.code);
    return indexOne - indexTwo;
  };

  return affiliateLandingPageVideoSectionList.sort(customComparator);




}