import { STATUS_TYPE } from '../../../enum';

export interface AffiliateGroup {
  id: number;
  name: string;
  owner_name: string;
  status: number;
  price: number;
  background_link?: string;
  created_at: Date;
  updated_at: Date;
  group_pricing?: AffiliateGroupPricing;
  brand_logo_link?: string;
  brand_square_logo_link?: string;
  meta_tags?: string;
  team_name?: string;
}

export interface AffiliateGroupPricing {
  id: number;
  name: string;
  basic_monthly_price: string;
  pro_monthly_price: string;
  knocard_link_monthly_price: string;
  pro_plus_monthly_price: string;
  basic_annual_price: string;
  pro_annual_price: string;
  pro_plus_annual_price: string;
  pro_one_price: string;
  is_default: number;
}

interface InitialState {
  data: AffiliateGroup[];
  affiliateGroupPricing: AffiliateGroupPricing[];
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
  isPricingLoading: boolean;
}

export const initialState: InitialState = {
  data: [],
  affiliateGroupPricing: [],
  status: 'idle',
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
  isPricingLoading: false
};
