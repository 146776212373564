import {
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import '@splidejs/react-splide/css';


type IProps = {
  toggle: boolean;
  onClose: () => void;
  videoUrl: string;
  title: string;
};

const opts = {
  height: '100%',
  width: '100%',
};

const VideoModel: React.FC<IProps> = ({ toggle, onClose, title, videoUrl }) => {

  return (
    <>
      <Dialog
        open={toggle}
        onClose={() => {

        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='sm'
        fullWidth
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        }}
      >
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 0,
            zIndex: 100000,
            color: 'white',
            alignSelf: 'center',
            alignItems: 'center',
            '-webkit-text-stroke-width': '1px',
            '-webkit-text-stroke-color': 'black'
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ padding: 0, m: 0, marginBottom: '-4px' }}>
          <Box
            alignItems='center'
            justifyContent='center'
          >
            <video
              controls
              width="100%"
              height="100%"
              className="object-fit-contain"
            >
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>

        </DialogContent>
      </Dialog>
    </>
  );
};

export default VideoModel;
