import { createSlice } from '@reduxjs/toolkit';
import {
  createProOneOrder,
  pubscriptionPurchaseSuccess
} from './api';
import { initialState } from './api/interface';

const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createProOneOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createProOneOrder.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createProOneOrder.rejected, (state, action) => {

      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(pubscriptionPurchaseSuccess.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(pubscriptionPurchaseSuccess.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(pubscriptionPurchaseSuccess.rejected, (state, action) => {

      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default purchaseSlice.reducer;
