import axios from 'axios';
import { config } from '../utils/utils';
import { getItem } from '../utils/localStorage';

export let axiosInstance = axios.create({
    baseURL: config.BASE_URL,
});

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);



axiosInstance.interceptors.request.use(
    function (configs) {
        let userToken = getItem(config.AUTH_KEY);
        let BEARER_TOKEN = userToken.length > 0 ? userToken : config.BEARER_TOKEN;

        configs.headers.Authorization = `Bearer ${BEARER_TOKEN}`;
        if (!configs.headers?.['Content-Type'])
            configs.headers['Content-Type'] = 'application/json';
        return configs;
    },
    function (error) {
        return Promise.reject(error);
    }
);

