import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//

import { Box, Drawer, Stack } from '@mui/material';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// import useResponsive from "../../hooks/useResponsive";
// import Scrollbar from "../../components/Scrollbar";
// import DrawerMenuListView from "../../components/DrawerMenuListView";
// import sidebarConfig from "./SidebarConfig";
import { HEADER, NAVBAR } from '../../config';
import DashboardSidebar from './DashboardSidebar';
import DashboardNavbar from './DashboardNavBar';
import BlockOverlay from '../../components/BlockOverlay';
import { AppDispatch, useAppSelector } from '../../store';
import { checkIsKnoCardSocialConnectUserPlan, checkIsProUser } from '../../utils/checkPro';
import { getProfile } from '../../store/auth/api';
import { useDispatch } from 'react-redux';

import { getItem, removeItem, setItem } from '../../utils/localStorage';
import { AppColors, config } from '../../utils/utils';
import { getAllReportTotal } from '../../store/reports/api';
import { showErrorMessage, showSuccessMessage } from '../../utils/utilMethods';
// import { NAVBAR } from "../../utils/utils";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 58;
// const APP_BAR_DESKTOP = 92;

// const RootStyle = styled("div")({
//   display: "flex",
//   minHeight: "100%",
//   overflow: "hidden",
// });

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  // backgroundColor: 'rgb(238 238 238 / 80%)',
});

// const MainStyle = styled("div")(({ theme }) => ({
//   flexGrow: 1,
//   overflow: "auto",
//   minHeight: "100%",
//   paddingTop: APP_BAR_MOBILE + 24,
//   paddingBottom: theme.spacing(10),
//   [theme.breakpoints.up("lg")]: {
//     paddingTop: APP_BAR_DESKTOP + 24,
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(2),
//   },
// }));

const MainStyle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<any>(({ theme, collapseClick }: any) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: HEADER.MOBILE_HEIGHT * 1.2,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.MAIN_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const [paymentStatus, setPaymentStatus] = useState<{ isSuccess: boolean, message: string } | undefined>();
  const navigate = useNavigate();

  const {
    collapseClick,
    isCollapse = false,
  }: {
    collapseClick: any;
    isCollapse?: boolean;
  } = useCollapseDrawer();

  const userInfo = useAppSelector((state) => state?.auth?.user);
  const isProUser = checkIsProUser(userInfo);
  const isKnoCardSocialConnectUser = checkIsKnoCardSocialConnectUserPlan(userInfo);
  const isEngageView = () => {
    return (
      location.pathname.startsWith('/dashboard/how-to') ||
      location.pathname.startsWith('/dashboard/training')

    );
  }

  const isPreferredPartnersView = () => {
    return (
      location.pathname.startsWith('/dashboard/preferred-partners')
    );
  }

  const isPromoterDashView = () => {
    return (
      location.pathname.startsWith('/dashboard/promoter-dash')
    );
  }

  const reloadUserProfile = () => {
    dispatch(getProfile({
      user_id: userInfo?.id ?? 0,
      viewer_id: userInfo?.id ?? 0,
    })).unwrap();
  };


  useEffect(() => {
    if (userInfo) {
      if (searchParams.has('fail')) {
        reloadUserProfile();
        setPaymentStatus({ isSuccess: false, message: searchParams.get('fail') ?? '' });
      } else if (searchParams.has('success')) {
        reloadUserProfile();
        setPaymentStatus({ isSuccess: true, message: searchParams.get('success') ?? '' });
      } if (!isProUser && !isKnoCardSocialConnectUser) {
        reloadUserProfile();
      }


      if (getItem(config.REQUIRED_SUBSCRIPTION_KEY) === true && !isKnoCardSocialConnectUser && !isProUser) {
        navigate(`/knocard-cart`);
      } else if (getItem(config.USER_PROFILE_PAGE_KEY) === true || isKnoCardSocialConnectUser) {
        navigate(`/dashboard/user-profile`);
      } else {
        if (!isProUser || isKnoCardSocialConnectUser) {
          navigate(`/dashboard`);
        }
      }

    }

    let redirectUrl = getItem(config.SIGNUP_REDIRECT_KEY);
    if (redirectUrl.length > 0) {
      removeItem(config.SIGNUP_REDIRECT_KEY);
      navigate(`/${redirectUrl}`)
    }

  }, []);

  useEffect(() => {
    if (paymentStatus) {
      reloadUserProfile();
      if (paymentStatus.isSuccess) {
        showSuccessMessage(paymentStatus?.message);
      } else {
        showErrorMessage(paymentStatus?.message);
      }
      setPaymentStatus(undefined);
    }
  }, [paymentStatus]);

  return (
    <RootStyle sx={{ background: AppColors.backgroundColor }}>
      <DashboardNavbar
        onOpenSidebar={() => open ? setOpen(false) : setOpen(true)}
        isCollapse={isCollapse}
      />

      {(isProUser || isKnoCardSocialConnectUser) && (<><DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
        <MainStyle collapseClick={collapseClick}>
          <Outlet />
        </MainStyle></>)}
      <BlockOverlay isBlock={(!isProUser && !isKnoCardSocialConnectUser)} planMessage='Upgrade to KnoCard Basic or above required' />
    </RootStyle>
  );
}
