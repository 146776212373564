import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Avatar, Badge, IconButton, Stack } from '@mui/material';

import { Box } from '@mui/system';
import React, { useState } from 'react';
import { EngageActivity } from '../../store/engagePipeline/api/interface';
import moment from 'moment';

import { getEngageLeadSource } from '../../utils/utilMethods';
import { AppColors } from '../../utils/utils';
import CallingIcon from '../../assets/call_calling.svg'
import SmsIcon from '../../assets/sms.svg'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';


interface IProps {
    engageActivity: EngageActivity;
    index: number;
}

const DashboardEngagePiplineItem: React.FC<IProps> = ({
    engageActivity,
    index
}) => {

    const [isConsentPending, setIsConsentPending] = useState<boolean>(engageActivity.consent_status == "pending");

    const getcontactProfile = () => {
        let imagePath = "/static/images/placeholder_profile.png"
        if (engageActivity.target?.profile_picture != null) {
            imagePath = engageActivity.target?.profile_picture ?? "";
        } else if (engageActivity.target?.user?.profile_picture != null && engageActivity.target?.user?.profile_picture.length > 0) {
            imagePath = engageActivity.target?.user?.profile_picture;
        }

        return imagePath;
    }


    const getReferByProfile = () => {
        let imagePath = "/static/images/placeholder_profile.png"

        if (engageActivity.refer_by?.profile_picture != null) {
            imagePath = engageActivity.refer_by?.profile_picture ?? imagePath;
        }

        return imagePath;
    }



    const getFullName = () => {
        return (engageActivity.target?.user) ? (`${engageActivity.target?.user?.first_name ?? ""} ${engageActivity.target?.user?.last_name ?? ""}`) : (`${engageActivity.target?.first_name ?? ""} ${engageActivity.target?.last_name ?? ""}`);
    };


    return (
        <div className='w-100'>
            <Card
                key={index}
                sx={{
                    background: AppColors.backgroundColor,
                    mb: 2,
                    p: 1,
                    borderRadius: '10px'
                }}
                elevation={0}
                className='w-100'
            >
                <CardContent
                    sx={{
                        paddingTop: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        paddingBottom: '0px !important',
                        '&:last-child': { pb: '10px' },
                    }}
                >

                    <Stack direction={'row'} sx={{ minHeight: '60px' }}>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",

                            }}
                        >
                            <Box sx={{
                                position: 'relative',
                                cursor: 'pointer',
                                borderRadius: "100%"
                            }}
                            >
                                <Avatar src={getcontactProfile()} sx={{ height: "50px", width: "50px", '& img': { borderRadius: '100%' }, }} />
                            </Box>
                        </Box>



                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "start",
                                paddingRight: "10px",
                                paddingLeft: "10px",
                                flexDirection: 'column',
                                ml: 0.5,
                                mt: 2,
                                flexGrow: 1,
                            }}
                        >
                            <Badge
                                badgeContent={engageActivity.activities || 0}
                                color='error'
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                sx={{ pr: 1 }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        color: AppColors.grayThreeColor,
                                        mt: 0.5,
                                        cursor: 'pointer',
                                        fontWeight: '500'
                                    }}
                                >
                                    {getFullName()}
                                </Typography>
                            </Badge>
                            {
                                !isConsentPending && (<Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "start",
                                        flexDirection: 'column',
                                        ml: 0.5,
                                        width: '100%',
                                    }} >
                                    <Box flexDirection={'row'} display={'flex'}>
                                        <Stack flexDirection={'row'} alignItems="center" mt={0.5} mr={2}>
                                            <Box sx={{ backgroundColor: AppColors.greenColor, width: '10px', height: '10px', borderRadius: '10px', marginRight: '5px' }}></Box>
                                            <Typography
                                                sx={{
                                                    color: AppColors.grayThreeColor,
                                                    cursor: 'pointer',
                                                    fontSize: "10px",

                                                }}
                                            >{engageActivity.totalActivities ?? "0"}
                                            </Typography>
                                        </Stack>
                                        <Stack flexDirection={'row'} alignItems="center" mt={0.5}>
                                            <Box sx={{ backgroundColor: AppColors.orangeColor, width: '10px', height: '10px', borderRadius: '10px', marginRight: '5px' }}></Box>
                                            <Typography
                                                sx={{
                                                    color: AppColors.grayThreeColor,

                                                    cursor: 'pointer',
                                                    fontSize: "10px",

                                                }}
                                            >{engageActivity.totalNoActivity ?? "0"}
                                            </Typography>
                                        </Stack>
                                    </Box>

                                    <Stack flexDirection={'row'} alignItems="center" mt={0.5}>
                                        <Box sx={{ backgroundColor: AppColors.redColor, width: '10px', height: '10px', borderRadius: '10px', marginRight: '5px' }}></Box>
                                        <Typography
                                            sx={{
                                                color: AppColors.grayThreeColor,

                                                cursor: 'pointer',
                                                fontSize: "10px",

                                            }}
                                        > {getEngageLeadSource(engageActivity)}
                                        </Typography>
                                    </Stack>
                                </Box>)
                            }
                        </Box>


                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "start",
                                flexDirection: 'column',
                            }} >

                            {isConsentPending && <Box sx={{ flexDirection: 'column', flexGrow: 1 }}><Typography sx={{ color: "#45E72B", fontSize: '0.8rem', mt: 0.5, textAlign: 'center' }}> Referral sent by</Typography>
                                <Stack flexDirection={'row'}>
                                    <Box component={'img'} src={getReferByProfile()} sx={{ height: "20px", width: "20px", borderRadius: "20px" }} />
                                    <Typography sx={{ color: AppColors.grayThreeColor, fontSize: '0.8rem', textAlign: 'center', ml: 0.5 }}>{engageActivity.refer_by.name ?? ""}</Typography>
                                </Stack>
                            </Box>}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "end",
                                justifyContent: "center",
                                mr: 1,
                                ml: 1,
                            }}
                        >
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                {isConsentPending && <><Typography sx={{ borderRadius: "20px", color: AppColors.whiteColor, backgroundColor: AppColors.orangeColor, pt: 0.5, pb: 0.5, pl: 1, pr: 1, fontSize: '0.8rem', mt: 0.5, textAlign: 'center' }}>Pending Consent</Typography>


                                    <Typography
                                        sx={{
                                            color: AppColors.grayThreeColor,
                                            fontSize: '0.8rem',
                                            textAlign: 'end',
                                            mt: 1
                                        }}
                                    >{engageActivity.updated_at && moment.utc(engageActivity.updated_at).local().format("MMM DD, YYYY  hh:mm a")} </Typography></>}
                                {!isConsentPending && <>

                                    <Box>
                                        <IconButton
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Box>
                                </>}

                            </Box>
                        </Box>

                    </Stack>



                    {!isConsentPending && <><Stack direction={'row'} >
                        <Typography sx={{ color: AppColors.grayThreeColor, fontSize: '0.8rem', mt: 1, textAlign: 'center' }}> Last contact at {engageActivity.updated_at && moment.utc(engageActivity.updated_at).local().format("MMM DD, YYYY  hh:mm a")}</Typography>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: 'column',
                                ml: 1,
                                mr: 1,
                                flexGrow: 1
                            }} >

                            {engageActivity.refer_by && <><Typography sx={{ color: AppColors.primaryColor, fontSize: '0.8rem', mt: 0.5, textAlign: 'center' }}> Referral sent by</Typography>
                                <Stack flexDirection={'row'}>
                                    <Box component={'img'} src={getReferByProfile()} sx={{ height: "20px", width: "20px", borderRadius: "20px" }} />
                                    <Typography sx={{ color: AppColors.grayThreeColor, fontSize: '0.8rem', textAlign: 'center', ml: 0.5 }}>{engageActivity.refer_by.name ?? ""}</Typography>
                                </Stack>
                            </>}


                        </Box>
                        {!isConsentPending && <Box sx={{ flexDirection: 'row', }}>
                            {engageActivity?.target?.phone_number && <Box component={'img'} src={SmsIcon} sx={{ m: 1 }} />}
                            {engageActivity?.target?.phone_number && <Box component={'img'} src={CallingIcon} sx={{ m: 1 }} />}
                        </Box>}
                    </Stack></>}
                </CardContent>
            </Card>

        </div >
    );
};

export default DashboardEngagePiplineItem;
