import React, { useEffect, useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button, { ButtonProps } from "@mui/material/Button";
import { Box, Card, CardActionArea, CardContent, CardMedia, ClickAwayListener, Grid, Stack, Tab, Tabs, useMediaQuery } from "@mui/material";
import Nav from "../../assets/blue_smile.png";
import { RiStarSFill } from "react-icons/ri";
import { AppColors, config } from "../../utils/utils";
import styled from "@emotion/styled";
import styles from "../../styles/AffiliateLanding.module.css";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../store";
import Loader from '../../components/Loader';
import { getAffiliateLandingPageVideos } from "../../store/affiliateLandingPageVideo/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import VideoTutorialModel from "../../components/modal/VideoTutorialModel";
import useToggle from "../../hooks/useToggle";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AffiliateLandingPageVideoItem } from "../../store/affiliateLandingPageVideo/api/interface";
import { getUserInfo } from "../../store/userInfo/api";
import { UserInfo } from "../../store/userInfo/api/interface";
import PersonIcon from '@mui/icons-material/Person';
import PreferredPartnersBlueIcon from '../../assets/preferredPartners_blue.svg';
import MediaBlueIcon from '../../assets/media_blue.svg';
import useWindowSize from "../../utils/useWindowSize";
import { analytics } from "../../store/shareRefer/api";
import ContactFormModal from '../../components/ContactFormModal';
import { setUserInfo } from "../../store/userInfo/userInfoSlice";
import { ContactFormSourceType } from "../../enum";
import { checkIsProProOrProOneOrProPlusUser } from "../../utils/checkPro";
import GroupNetworkModal from "../../components/GroupNetworkModal";
import { LandingPageNewNavBar } from "../../components/LandingPageNewNavBar";
import HeaderTabItem from "../../components/referral/HeaderTabItem";

import link from '../../assets/link.svg';
import media from '../../assets/media.svg';
import partners from '../../assets/partners.svg';
import profile from '../../assets/profile.svg';
import social from '../../assets/social.svg';


const OpenButton = styled(Button)<ButtonProps>(({ theme }) => ({
  // backgroundColor: "#00cc00",
  backgroundImage: 'linear-gradient(0deg, #46FC05 0%, #31B103 50%, #46FC05 100%)',
  color: "#ffffff",
  borderRadius: "30px",
  fontSize: "small",
  fontWeight: "bold",
  "&:hover": {
    background: "white",
    color: "#00cc00",
  },
  minWidth: "122px"
}));

const AffiliateLanding = () => {
  const toolbarMatches = useMediaQuery("(max-width:745px)");
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();
  const affiliateLandingPageVideoList = useAppSelector((state) => state.affiliateLandingPageVideo.data);
  const affiliateLandingPageVideoResult = useAppSelector((state) => state.affiliateLandingPageVideo.searchResult);
  const overviewVideo = useAppSelector((state) => state.affiliateLandingPageVideo.overview);
  const fixingTheProblemVideo = useAppSelector((state) => state.affiliateLandingPageVideo.fixingTheProblem);
  const isLoading = useAppSelector((state) => state.tutorialVideo.isLoading);
  const authUserInfo = useAppSelector((state) => state?.auth?.user);

  const [isOverviewVideoPlayed, setOverviewVideoPlayed] = useState(false);
  const [isFixingTheProblemVideoPlayed, setFixingTheProblemVideoPlayed] = useState(false);


  const [lastPlatVideoId, setLastPlatVideoId] = useState<number | undefined>(undefined);

  const [searchParams] = useSearchParams();
  const [referredByLink, setReferredByLink] = useState('');
  const [referrUserInfo, setReferrUserInfo] = useState<UserInfo | undefined>(undefined);

  const [calendarLink, setCalendarLink] = useState('https://calendly.com/knocard/connect-with-me');
  const [calendarLinkLabel, setCalendarLinkLabel] = useState('https://calendly.com/knocard');

  const windowSize = useWindowSize();
  const [tabMinWidth, setTabMinWidth] = useState(90);
  const matches = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    const newWidth = matches ? windowSize.width / 5 : 90;
    setTabMinWidth(newWidth);
  }, [windowSize]);


  const {
    toggle: toggleContactForm,
    onOpen: onOpenContactForm,
    onClose: onCloseContactForm,
  } = useToggle();

  const {
    toggle: toggleGroup,
    onOpen: onOpenGroup,
    onClose: onCloseGroup,
  } = useToggle();

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleContactFormSubmitSuccess = (contactCode: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete(config.PROSPECT_KEY);
    queryParams.set('sc', contactCode);
    navigate(`/what-is-knocard?${queryParams.toString()}`);
  };


  useEffect(() => {
    let refferLink = `${config.VBC_URL}`;
    let userName = "";

    if (searchParams.has(config.REFER_KEY)) {
      userName = searchParams.get(config.REFER_KEY) ?? '';
      refferLink += `/affiliate/${userName}`;
    } else {
      refferLink += "/register";
    }
    setReferredByLink(refferLink);

    if (!isLoading) {
      dispatch(getAffiliateLandingPageVideos());
    }

    if (userName.length > 0) {
      dispatch(getUserInfo({ username: userName })).unwrap().then((res) => {
        if (res.data.data.user.length > 0) {
          setReferrUserInfo(res.data.data.user[0]);
          dispatch(setUserInfo(res.data));
        }
      });
    }
  }, []);


  useEffect(() => {
    if (referrUserInfo) {
      if (searchParams.has(config.PROSPECT_KEY) && searchParams.get(config.PROSPECT_KEY) && checkIsProProOrProOneOrProPlusUser(referrUserInfo)) {
        onOpenContactForm();
      }
      saveLadingPageViewActivity();
    }
  }, [referrUserInfo]);

  const saveLadingPageViewActivity = () => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let elid = searchParams.get('elid');
      let loginUserId = authUserInfo?.id;
      dispatch(
        analytics({
          route: '/activity/save',
          data: {
            actionType: 'view',
            sourceType: 'link_share',
            module: 'affiliate_landing_page',
            targetId: referrUserInfo?.id,
            identifiableId: referrUserInfo?.id,
            ...(loginUserId && { viewableId: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
            ...((!loginUserId && elid) && { elid: elid })
          },
        })
      );
    }
  }


  useEffect(() => {

    if (referrUserInfo) {
      if ((referrUserInfo.calendar_link ?? "").trim().length > 0) {
        setCalendarLink(referrUserInfo.calendar_link ?? "");
        setCalendarLinkLabel(referrUserInfo.calendar_link ?? "");
      } else {
        setCalendarLink(`tel:${referrUserInfo.phone_country_code ?? ""}${referrUserInfo.phone_number}`);
        setCalendarLinkLabel(`${referrUserInfo.phone_country_code ?? ""} ${referrUserInfo.phone_number}`);
      }
    }

  }, [referrUserInfo])


  const handleGetKnoCard = () => {
    onOpenGroup();
    //window.open(`${referredByLink}`) || window.location.replace(`${referredByLink}`);

  };

  const [openTutorialVideo, setOpenTutorialVideo] = useState<AffiliateLandingPageVideoItem | undefined>(undefined);
  const [showAllVideo, setShowAllVideo] = useState<string | undefined>(undefined);

  const {
    toggle: togglenTutorialVideo,
    onOpen: onOpenTutorialVideo,
    onClose: onCloseTutorialVideo,
  } = useToggle();


  useEffect(() => {
    if (openTutorialVideo != undefined) {
      onOpenTutorialVideo();
    }
  }, [openTutorialVideo]);

  useEffect(() => {
    if (!togglenTutorialVideo) {
      setOpenTutorialVideo(undefined);
    }
  }, [togglenTutorialVideo]);

  const featurs = [
    {
      icon: '/static/Affiliate_landing/Messaging.png',
      title: 'Messaging'
    },
    {
      icon: '/static/Affiliate_landing/search.png',
      title: 'Search'
    },
    {
      icon: '/static/Affiliate_landing/Affiliate.png',
      title: 'Affiliate'
    },
    {
      icon: '/static/Affiliate_landing/Reporting.png',
      title: 'Reporting'
    },
    {
      icon: '/static/Affiliate_landing/web_page.png',
      title: 'Webpage'
    },
    {
      icon: '/static/Affiliate_landing/qr.png',
      title: 'QR'
    },
    {
      icon: '/static/Affiliate_landing/card_scanner.png',
      title: 'Card Scanner'
    },
    {
      icon: '/static/Affiliate_landing/External_Link.png',
      title: 'External Link'
    },
    {
      icon: '/static/Affiliate_landing/Partners.png',
      title: 'Partners'
    },
    {
      icon: '/static/Affiliate_landing/Pipeline.png',
      title: 'Pipeline'
    },
    {
      icon: '/static/Affiliate_landing/Notifications.png',
      title: 'Notifications'
    },
    {
      icon: '/static/Affiliate_landing/Media.png',
      title: 'Media'
    }
  ];




  const getParamDelimiter = (params: string) => {
    return (params.trim().length > 0) ? "&" : "?";
  };

  const tabHandleChange = (event: any, newValue: number) => {
    let path = `/${referrUserInfo?.username ?? ""}`
    switch (newValue) {
      case 0:
        path += "/";
        break;
      case 1:
        path += "/media";
        break;
      case 2:
        path += "/company";
        break;
      case 3:
        path += "/external-link";
        break;
      case 4:
        path += "/preferred-partners";
        break;
    }

    let params = "";
    const sc = searchParams.get('sc');
    const sb = searchParams.get('sb');
    const elid = searchParams.get('elid');

    if (sc != undefined) {
      params += `${getParamDelimiter(params)}sc=${encodeURIComponent(sc)}`;
    }

    if (sb != undefined) {
      params += `${getParamDelimiter(params)}sb=${encodeURIComponent(sb)}`;
    }
    if (elid != undefined) {
      params += `${getParamDelimiter(params)}elid=${encodeURIComponent(elid)}`;
    }

    path += params;

    navigate(path);

  };



  const handleOnVideoPlayActivity = (videoTitle: string, second: number, duration: number) => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let loginUserId = authUserInfo?.id;

      dispatch(
        analytics({
          route: '/landing-page/video-watch',
          data: {
            target_id: referrUserInfo?.id ?? 0,
            module: "affiliate_landing_page",
            title: videoTitle,
            target_page: "what-is-knocard",
            video_duration_seconds: duration,
            video_watch_seconds: second,
            ...(loginUserId && { viewable_id: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
          },
        })
      );
    }
  };


  const handleOnPauseOrCompleteVideoActivity = (videoTitle: string, second: number, duration: number) => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let loginUserId = authUserInfo?.id;

      dispatch(
        analytics({
          route: '/landing-page/video-watch-time',
          data: {
            target_id: referrUserInfo?.id ?? 0,
            module: "affiliate_landing_page",
            title: videoTitle,
            target_page: "what-is-knocard",
            video_watch_seconds: second,
            video_duration_seconds: duration,
            ...(loginUserId && { viewable_id: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
          },
        })
      );
    }
  };

  const handleOnVideoPlay = (videoId: number, time: number, duration: number) => {
    var videoTitle = getVideoTitleFromVideoId(videoId)

    if (lastPlatVideoId !== videoId) {
      setLastPlatVideoId(videoId);
      handleOnVideoPlayActivity(videoTitle, time, duration);
    } else if (time !== 0) {
      handleOnPauseOrCompleteVideoActivity(videoTitle, time, duration);
    }
  }


  const getVideoTitleFromVideoId = (videoId: number) => {

    var videoTitle = "";
    affiliateLandingPageVideoList.map((item) => {
      if (item.id == videoId) {
        videoTitle = item.title;
      }
    });
    return videoTitle;

  }



  if (isLoading) return <Loader />;

  return (
    <React.Fragment>
      <LandingPageNewNavBar userInfo={referrUserInfo} affiliateUserName={referrUserInfo?.username} />

      {/* LINE SECTION */}
      <Box
        className={styles.line}
        sx={{
          backgroundColor: '#349fce',
        }}
      ></Box>
      <Box
        className={styles.line}
        sx={{
          backgroundColor: '#f8a048',
        }}
      ></Box>

      {/* SECTION 1  */}
      <section className={styles.section_1}>
        <Box
          className={styles.section_1_part_1}
        // sx={{
        //   minHeight: { xs: 'calc(100vh - 77px)', md: "unset" }
        // }}
        // flexDirection={'column'}
        // display='flex'
        // justifyContent='center'
        >
          <Typography
            component='h1'
            className={styles.section_1_title}
            sx={{
              lineHeight: '1.2',
              fontSize: {
                xs: '1.5rem',
                sm: '1.5rem',
                md: '3.5rem',
                lg: '3.5rem',
                xl: '3.5rem',
              },
              pt: 5,
              pb: 5,
              pl: 1.5,
              pr: 1.5,
            }}
          >
            Join the
            <span> {new Date().getFullYear()} TECH </span>
            movement Marketing your business just got
            <span> EASIER </span>
          </Typography>

          {overviewVideo && (
            <Box sx={{ textAlign: 'center', pb: 2 }}>
              <Box
                component={'video'}
                autoPlay={false}
                id='intro_video'
                controls
                poster={overviewVideo.thumb_link}
                sx={{
                  maxWidth: '95%',
                  border: ' 5px solid #4c9ec9',
                  height: { xs: 'auto', md: '400px' },
                  mb: { xs: '-120px', md: '-200px' },
                }}
                onPlay={(props) => {
                  if (!isOverviewVideoPlayed && props.currentTarget.currentTime < 1) {
                    handleOnVideoPlay(overviewVideo.id, 0, props.currentTarget.duration || 0);
                    setOverviewVideoPlayed(true);
                  }
                }}

                onPause={(props: any) => {
                  handleOnVideoPlay(overviewVideo.id, props.currentTarget.currentTime, props.currentTarget.duration || 0);
                }}

              >
                <source src={overviewVideo.link} type='video/mp4' />
              </Box>
            </Box>
          )}
        </Box>
      </section>
      <Box
        sx={{
          width: '100%',
          p: 5,
          textAlign: 'center',
          mt: {
            xs: overviewVideo ? '100px' : '0px',
            md: overviewVideo ? '230px' : '0px',
          },
        }}
      >
        <Box
          component={'img'}
          src='/static/Affiliate_landing/phone.png'
          alt=''
          sx={{ width: { md: '50%', sm: '100%', xs: '100%' } }}
        />
      </Box>

      <section className={styles.section_2}>
        <Box className={styles.section_2_division1}>
          <Grid container>
            <Grid
              item
              xs={0}
              sm={0}
              md={5}
              lg={5}
              xl={5}
              sx={{ opacity: 0.8 }}
              className={styles.section_2_title_img}
            >
              <img src='/static/Affiliate_landing/dots.png' alt='' />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              display='flex'
              justifyContent='center'
              flexDirection='column'
              alignContent='center'
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: '1.6rem',
                    sm: '1.6rem',
                    md: '1.8rem',
                    lg: '2rem',
                    xl: '2rem',
                  },
                  padding: '0',
                  fontWeight: 'bold',
                  paddingLeft: {
                    xs: '0',
                    sm: '0',
                    md: '0',
                    lg: '70px',
                    xl: '75px',
                  },
                  textAlign: {
                    xs: 'center',
                    sm: 'center',
                    md: 'center',
                    lg: 'left',
                    xl: 'left',
                  },
                  color: '#fff',
                  fontFamily: "'Lato', sans-serif",
                }}
              >
                The
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: '2rem',
                    sm: '2rem',
                    md: '2.5rem',
                    lg: '3.5rem',
                    xl: '3.5rem',
                  },
                  textAlign: {
                    xs: 'center',
                    sm: 'center',
                    md: 'center',
                    lg: 'left',
                    xl: 'left',
                  },
                  color: '#44B0D5',
                  fontFamily: "'Lato', sans-serif",
                }}
              >
                Problem
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              padding: {
                xs: '0 0px',
                sm: '0 0px',
                md: '0 30px',
                lg: '0 70px',
                xl: '0 70px',
              },
            }}
          >
            <Grid item xs={12} sm={12} md={8} lg={8} xl={7}>
              <Typography
                sx={{
                  color: '#fff',
                  fontSize: {
                    xs: '1.2rem',
                    sm: '1.2rem',
                    md: '2rem',
                    lg: '2rem',
                    xl: '2rem',
                  },
                  textAlign: {
                    xs: 'center',
                    sm: 'center',
                    md: 'start',
                  },
                  fontFamily: "'Lato', sans-serif",
                  p: 3,
                }}
              >
                Professionals
                <span style={{ color: '#20E3F1' }}> struggle </span>
                with managing a multitude of platforms, including websites,
                social media, business cards, CRMs, videos, pipeline management
                systems, lead generators, and messaging services.
              </Typography>

              <Box sx={{ textAlign: 'center', mb: 2 }}>
                <Box
                  component={'img'}
                  src='/static/Affiliate_landing/the_problem_media.png'
                  sx={{ width: '70%' }}
                ></Box>
              </Box>

              <Stack alignItems='center' sx={{ mb: { xs: 3, md: 5 } }}>
                <OpenButton
                  onClick={handleGetKnoCard}
                  sx={{
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    textTransform: 'unset',
                  }}
                >
                  Join KnoCard
                </OpenButton>
              </Stack>
            </Grid>
            {fixingTheProblemVideo && (
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className={styles.section_2_video_wrapper}
                sx={{ textAlign: 'center', pb: 2 }}
              >
                <Box
                  component={'video'}
                  autoPlay={false}
                  id='intro_video'
                  controls
                  poster={fixingTheProblemVideo.thumb_link}
                  sx={{
                    width: {
                      xs: '90%',
                      sm: '90%',
                      md: '100%',
                      lg: '100%',
                      xl: '100%',
                    },
                    border: ' 5px solid #4c9ec9',
                  }}
                  onPlay={(props: any) => {
                    if (!isFixingTheProblemVideoPlayed && props.currentTarget.currentTime < 1) {
                      handleOnVideoPlay(fixingTheProblemVideo.id, 0, props.currentTarget.duration || 0);
                      setFixingTheProblemVideoPlayed(true);
                    }
                  }}
                  onPause={(props: any) => {
                    handleOnVideoPlay(fixingTheProblemVideo.id, props.currentTarget.currentTime, props.currentTarget.duration || 0);
                  }}
                >
                  <source src={fixingTheProblemVideo.link} type='video/mp4' />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box className={styles.section_2_division2}>
          <Box className={styles.section_2_division2_overlay}>
            <Box sx={{ pl: 3, pr: 3, pt: 5, pb: 5 }}>
              <Box
                component={'img'}
                src='/static/images/joinknocard/knocard.png'
                alt='knocard logo'
                sx={{ maxWidth: { xs: '60%', md: '35%' } }}
              />
              <Typography
                sx={{
                  fontSize: {
                    xs: '1rem',
                    sm: '1.2rem',
                    md: '2rem',
                    lg: '2.2rem',
                    xl: '2.5rem',
                  },
                  color: '#fff',
                  fontFamily: "'Lato', sans-serif",
                }}
              >
                is the
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: '1.5rem',
                    sm: '1.7rem',
                    md: '2.5rem',
                    lg: '3.2rem',
                    xl: '3.8rem',
                  },
                  color: '#fff',
                  fontFamily: "'Lato', sans-serif",
                  fontWeight: 'bold',
                  textShadow: '#f9a048 -2px 2px',
                }}
              >
                Ultimate Solution
              </Typography>
            </Box>
          </Box>
        </Box>
      </section>

      <section className={styles.pvs}>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{
            pt: { md: 4, sm: 2, xs: 2 },
          }}
        >
          <Box
            sx={{
              bgcolor: '#003B53',
              pl: 4,
              pr: 4,
              m: 2,
              borderRadius: '30px',
            }}
          >
            <Typography
              sx={{
                color: '#62b2d0',
                fontSize: {
                  xs: '1.5rem',
                  sm: '1.5rem',
                  md: '1.8rem',
                  lg: '1.8rem',
                  xl: '1.8rem',
                },
                textAlign: 'center',
              }}
            >
              Problem <span style={{ color: '#fff' }}> VS </span> Solution
            </Typography>
          </Box>
        </Box>
        <Box sx={{ pt: 2, pb: 2, pr: 4, pl: 4 }} flexDirection={'column'}>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#eda359',
              fontSize: '1rem',
            }}
          >
            Your WebSite
          </Typography>
          <Box
            sx={{
              backgroundColor: '#adafaf',
              mt: 1,
              p: 1,
              mr: { xs: 5, sm: 5, md: 10 },
              borderRadius: 2,
            }}
          >
            <Typography>
              Most personal websites have become redundant, often serving merely
              as digital brochures. A significant issue is the lack of traffic
              to these sites, leaving them largely unvisited and underutilized.
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: '#62aed1',
              mt: 1,
              p: 1,
              ml: { xs: 5, sm: 5, md: 10 },
              borderRadius: 2,
              color: '#ffffff',
            }}
          >
            <Typography>
              Knocard resloves this issue by adding all of our members to a
              global community. This community gives you the ability to connect
              and be connected with others. We connect by our Exclusive Business
              Social and our members referring one another.
            </Typography>
          </Box>
        </Box>

        <Box sx={{ pt: 2, pb: 2, pr: 4, pl: 4 }} flexDirection={'column'}>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#eda359',
              fontSize: '1rem',
            }}
          >
            Social Media
          </Typography>
          <Box
            sx={{
              backgroundColor: '#adafaf',
              mt: 1,
              p: 1,
              mr: { xs: 5, sm: 5, md: 10 },
              borderRadius: 2,
            }}
          >
            <Typography>
              Social media platforms were never intended to be used for business
              purposes, but have been adapted for marketing for lack of a better
              alternative. The algorithms do not work in your favor, and are
              constantly changing.
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: '#62aed1',
              mt: 1,
              p: 1,
              ml: { xs: 5, sm: 5, md: 10 },
              borderRadius: 2,
              color: '#ffffff',
            }}
          >
            <Typography>
              KnoCard’s social platform is intended exclusively for business
              purposes. The audience is engaging solely because they are seeking
              business information, providing you the opportunity for highly
              targeted marketing without distractions.
            </Typography>
          </Box>
        </Box>

        <Box sx={{ pt: 2, pb: 2, pr: 4, pl: 4 }} flexDirection={'column'}>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#eda359',
              fontSize: '1rem',
            }}
          >
            Referring
          </Typography>
          <Box
            sx={{
              backgroundColor: '#adafaf',
              mt: 1,
              p: 1,
              mr: { xs: 5, sm: 5, md: 10 },
              borderRadius: 2,
            }}
          >
            <Typography>
              The majority of business owners, sales people and networkers will
              tell you that they obtain business primarily through word of mouth
              marketing. With no definitive method in place for others to share
              their information though, many referrals die on the spot.
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: '#62aed1',
              mt: 1,
              p: 1,
              ml: { xs: 5, sm: 5, md: 10 },
              borderRadius: 2,
              color: '#ffffff',
            }}
          >
            <Typography>
              We’ve solved this issue by including a variety of referral
              options. Viewers can quickly share with anyone, and a robust
              referral system sends notifications & reporting to the recipient.
              You can even spotlight another user through our Preferred Partners
              screen.
            </Typography>
          </Box>
        </Box>

        <Box sx={{ pt: 2, pb: 2, pr: 4, pl: 4 }} flexDirection={'column'}>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#eda359',
              fontSize: '1rem',
            }}
          >
            Real-time Analytics
          </Typography>
          <Box
            sx={{
              backgroundColor: '#adafaf',
              mt: 1,
              p: 1,
              mr: { xs: 5, sm: 5, md: 10 },
              borderRadius: 2,
            }}
          >
            <Typography>
              Marketing without analytics creates a host of problems, including
              fear of rejection, difficulty in determining the best time to
              follow up with a lead, time wasted on prospects who are not
              interested, and the potential of losing a sale due to poor timing.
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: '#62aed1',
              mt: 1,
              p: 1,
              ml: { xs: 5, sm: 5, md: 10 },
              borderRadius: 2,
              color: '#ffffff',
            }}
          >
            <Typography>
              KnoCard tracks sharing activity and provides reporting that show
              you exactly which of your prospects have viewed your information,
              when they viewed, and how many times they viewed. This valuable
              data assists you in spending your time with interested leads.
            </Typography>
          </Box>
        </Box>

        <Box sx={{ pt: 2, pb: 2, pr: 4, pl: 4 }} flexDirection={'column'}>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#eda359',
              fontSize: '1rem',
            }}
          >
            Connecting with one another
          </Typography>
          <Box
            sx={{
              backgroundColor: '#adafaf',
              mt: 1,
              p: 1,
              mr: { xs: 5, sm: 5, md: 10 },
              borderRadius: 2,
            }}
          >
            <Typography>
              Many professionals use their personal text messaging platforms to
              communicate with others for business purposes. This makes it
              difficult to separate personal from business and increases the
              probability that important business strings will be lost in the
              shuffle.
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: '#62aed1',
              mt: 1,
              p: 1,
              ml: { xs: 5, sm: 5, md: 10 },
              borderRadius: 2,
              color: '#ffffff',
            }}
          >
            <Typography>
              KnoCard’s in-app messaging and group messaging enable you to send
              text, images and videos without moving to another app. The
              intuitive interface offers several areas from within the app to
              reach out and message another user.
            </Typography>
          </Box>
        </Box>

        <Box sx={{ pt: 2, pb: 2, pr: 4, pl: 4 }} flexDirection={'column'}>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#eda359',
              fontSize: '1rem',
            }}
          >
            Ease of sharing
          </Typography>
          <Box
            sx={{
              backgroundColor: '#adafaf',
              mt: 1,
              p: 1,
              mr: { xs: 5, sm: 5, md: 10 },
              borderRadius: 2,
            }}
          >
            <Typography>
              Until now there’s been no standard or simple way to expose your
              information to others. With a multitude of disjointed platforms,
              websites, and methods (some are even still using printed business
              cards...) sharing is inefficient and leads often fall through the
              cracks.
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: '#62aed1',
              mt: 1,
              p: 1,
              ml: { xs: 5, sm: 5, md: 10 },
              borderRadius: 2,
              color: '#ffffff',
            }}
          >
            <Typography>
              KnoCard provides several robust sharing methods, all delivered
              through text message without the use of short codes. Your KnoCard
              is a central hub that delivers ALL of your information, even
              allowing you to share specific targeted files and videos.
            </Typography>
          </Box>
        </Box>

        <Box sx={{ pt: 2, pb: 2, pr: 4, pl: 4 }} flexDirection={'column'}>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#eda359',
              fontSize: '1rem',
            }}
          >
            Keep the conversation going
          </Typography>
          <Box
            sx={{
              backgroundColor: '#adafaf',
              mt: 1,
              p: 1,
              mr: { xs: 5, sm: 5, md: 10 },
              borderRadius: 2,
            }}
          >
            <Typography>
              2024 will surely be a big year for in person and virtual
              networking. The problem that many networkers face is that once the
              meeting is over, the conversation comes to a close and potential
              business collaboration is less likely.
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: '#62aed1',
              mt: 1,
              p: 1,
              ml: { xs: 5, sm: 5, md: 10 },
              borderRadius: 2,
              color: '#ffffff',
            }}
          >
            <Typography>
              KnoCard is the ideal networking tool, offering many ways to keep
              the conversation going. Add new contacts directly into your
              pipeline, include meta tags to identify your group, quickly locate
              your connections through the search, & connect through in app
              messaging.
            </Typography>
          </Box>
        </Box>
      </section>
      <section className={styles.section_2}>
        <Stack
          sx={{
            width: '100%',
            backgroundImage:
              "url('./static/Affiliate_landing/run_my_busineess_bg.png')",
            minHeight: { sm: 'unset', md: '50vh' },
          }}
          direction='column'
          justifyContent='center'
        >
          <Box
            sx={{
              p: 5,
            }}
          >
            <Box
              sx={{
                width: { xs: '100%', sm: '100%', md: '60%' },
                background: 'linear-gradient(to bottom, #073346, #2baeeb)',
              }}
            >
              <Typography
                sx={{
                  color: '#1e9bcd',
                  fontSize: '2rem',
                  fontFamily: "'Lato', sans-serif",
                  pt: 3,
                  pl: 3,
                  pr: 3,
                  fontWeight: 'bold',
                }}
              >
                I personally use KnoCard to run my business.
              </Typography>
              <Typography
                sx={{
                  color: '#ffffff',
                  fontSize: '1.5rem',
                  fontFamily: "'Lato', sans-serif",
                  pt: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                {new Date().getFullYear()} is poised to exceed previous years'
                networking activity levels, both virtually and in person. I'm
                thrilled to provide you with the perfect tool to capitalize on
                this growth.
              </Typography>
              <Typography
                sx={{
                  color: '#000000',
                  fontSize: '1.5rem',
                  fontFamily: "'Lato', sans-serif",
                  pt: 2,
                  pl: 3,
                  pr: 3,
                  pb: 2,
                  textAlign: 'end',
                }}
              >
                -Rob Pickering
              </Typography>
            </Box>
          </Box>
        </Stack>
      </section>

      <section>
        <Box sx={{ p: 2 }}>
          <Grid
            container
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
          >
            <Grid
              item
              xs={0}
              sm={0}
              md={2}
              lg={2}
              xl={2}
              sx={{
                opacity: 0.8,
                display: { xs: 'none', sm: 'none', md: 'unset' },
              }}
            >
              <Box
                component={'img'}
                sx={{ width: '100%' }}
                src='/static/Affiliate_landing/dots_left.png'
                alt=''
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              sx={{ textAlign: 'center' }}
            >
              <Box
                component={'img'}
                src='/static/Affiliate_landing/get_your_knocard_logo.png'
                alt='get_your_knocard_logo'
                sx={{ p: 1 }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              xl={7}
              display='flex'
              justifyContent='center'
              flexDirection='column'
              alignContent='center'
            >
              <Box flexDirection={'column'} sx={{ textAlign: 'center' }}>
                <Typography
                  sx={{
                    color: '#3b89c2',
                    fontSize: '1.3rem',
                    fontWeight: 'bold',
                  }}
                >
                  Get Your KnoCard Today.
                </Typography>
                <Typography
                  sx={{
                    mb: 1,
                    fontWeight: 'bold',
                    color: '#eda359',
                    fontSize: '1.8rem',
                  }}
                >
                  We are excited to refer you
                </Typography>
                <OpenButton
                  onClick={handleGetKnoCard}
                  sx={{
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    width: 'fit-content',
                    textTransform: 'unset',
                  }}
                >
                  Get KnoCard
                </OpenButton>
              </Box>
            </Grid>

            <Grid
              item
              xs={0}
              sm={0}
              md={2}
              lg={2}
              xl={2}
              sx={{
                opacity: 0.8,
                display: { xs: 'none', sm: 'none', md: 'unset' },
              }}
            >
              <Box
                component={'img'}
                sx={{ width: '100%' }}
                src='/static/Affiliate_landing/dots_right.png'
                alt=''
              />
            </Grid>
          </Grid>
        </Box>
      </section>

      <section>
        <Stack
          sx={{ p: 2 }}
          className={styles.section_1_part_1}
          flexDirection={'column'}
        >
          <Box flexDirection={'row'} sx={{ position: 'relative' }}>
            <Box
              component={'img'}
              src='/static/Affiliate_landing/what_more_logo.png'
              sx={{
                display: { xs: 'none', sm: 'none', md: 'unset' },
                width: {
                  xs: '35%',
                  sm: '35%',
                  md: '20%',
                  lg: '10%',
                  xl: '10%',
                },
                height: 'fit-content',
                position: 'absolute',
                left: 5,
                top: 5,
              }}
            />
            <Box
              flexDirection={'column'}
              sx={{ width: '100%', textAlign: 'center' }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: '#eda359',
                  fontSize: '2rem',
                }}
              >
                Want more?
              </Typography>
              <Typography
                sx={{
                  color: '#3b89c2',
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  pb: 1,
                }}
              >
                Check out our
              </Typography>
              <Box
                component={'img'}
                src='/static/Affiliate_landing/video_vault.png'
                sx={{
                  pt: 1,
                  width: {
                    xs: '60%',
                    sm: '60%',
                    md: '20%',
                    lg: '18%',
                    xl: '18%',
                  },
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              mt: 4,
              p: 3,
              borderRadius: '20px',
            }}
          >
            {affiliateLandingPageVideoResult.map((tutorialItem, idx) => {
              return (
                <>
                  <Grid key={`tutorialItem-title-${idx}`} container spacing={1}>
                    <Grid
                      item
                      id={tutorialItem.code}
                      xs={12}
                      sx={{ position: 'relative', mt: 1, mb: 1 }}
                    >
                      <Typography
                        sx={{
                          mt: 1,
                          mb: 0.5,
                          fontWeight: 'bold',
                          color: '#12364a',
                          fontSize: '1.5rem',
                          mr: 10,
                        }}
                      >
                        {tutorialItem.title}
                      </Typography>
                      <Typography
                        sx={{
                          position: 'absolute',
                          right: 0,
                          top: 16,
                          color: '#6dbee4',
                          fontWeight: 'bold',
                        }}
                        onClick={() =>
                          tutorialItem.code != showAllVideo
                            ? setShowAllVideo(tutorialItem.code)
                            : setShowAllVideo(undefined)
                        }
                      >
                        {tutorialItem.code == showAllVideo
                          ? 'Show Less'
                          : 'Show All'}{' '}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    {(tutorialItem.data.length > 6
                      ? tutorialItem.data.slice(
                        0,
                        tutorialItem.code == showAllVideo
                          ? tutorialItem.data.length
                          : 4
                      )
                      : tutorialItem.data
                    ).map((tutorialVideo, index) => {
                      return (
                        <Grid item xs={6} md={3}>
                          <Card
                            key={`tutorial-item-${index}`}
                            sx={{ maxWidth: 345 }}
                          >
                            <CardMedia
                              component='img'
                              height='140'
                              image={tutorialVideo.thumb_link}
                              alt={tutorialVideo.thumb_name}
                              onClick={() =>
                                setOpenTutorialVideo(tutorialVideo)
                              }
                            />
                            <Box component={'div'}>
                              <CardActionArea
                                onClick={() =>
                                  setOpenTutorialVideo(tutorialVideo)
                                }
                              >
                                <CardContent
                                  sx={{
                                    p: 0.5,
                                    '&:last-child': { p: 1 },
                                    minHeight: '36px',
                                    backgroundColor: '#4fc1e8',
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography
                                    variant='body2'
                                    color='text.secondary'
                                    sx={{
                                      overflow: 'hidden',
                                      display: '-webkit-box',
                                      '-webkit-line-clamp': '1',
                                      '-webkit-box-orient': 'vertical',
                                      color: '#000000',
                                      mr: 2.2,
                                    }}
                                  >
                                    {tutorialVideo.title}
                                  </Typography>
                                  <ArrowDropDownIcon
                                    sx={{
                                      color: '#F58227',
                                      position: 'absolute',
                                      right: 5,
                                      top: 5,
                                    }}
                                  />
                                </CardContent>
                              </CardActionArea>
                            </Box>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              );
            })}
          </Box>
        </Stack>
      </section>
      <section>
        <Box
          sx={{
            minHeight: '100vh',
            width: '100%',
            background: 'linear-gradient(to bottom, #073346, #007bb9)',
            pt: 5,
            pb: 5,
          }}
        >
          <Box
            sx={{
              padding: {
                xs: '0 5%',
                sm: '0 7%',
                md: '0 10%',
                lg: '0 15%',
                xl: '0 15%',
              },
            }}
          >
            <Typography
              component='p'
              sx={{
                fontSize: {
                  xs: '2rem',
                  sm: '2rem',
                  md: '2rem',
                  lg: '3rem',
                  xl: '3rem',
                },
                fontFamily: "'Lato', sans-serif",
                color: '#fff',
                textAlign: 'center',
                mt: 2,
                mb: 3,
              }}
            >
              <span style={{ color: '#239FCE' }}>There is </span>
              SOO Much More
            </Typography>
            <Box>
              <Grid container sx={{ gridAutoFlow: 'row' }}>
                {featurs.map((elm, index) => {
                  return (
                    <Grid
                      key={`icon-${index}`}
                      sx={{ mt: 2 }}
                      item
                      xs={6}
                      sm={6}
                      md={4}
                      lg={4}
                      display='flex'
                      justifyContent='center'
                      flexDirection='column'
                      alignItems='center'
                    >
                      <Box
                        component={'img'}
                        src={elm.icon}
                        alt=''
                        sx={{
                          width: { xs: '30%', md: '20%' },
                          maxWidth: '90%',
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: {
                            xs: '1.0rem',
                            sm: '1.4rem',
                            md: '1.5rem',
                          },
                          color: '#F9A048',
                          fontFamily: "'Lato', sans-serif",
                          fontWeight: 'bold',
                          mt: 1,
                        }}
                      >
                        {elm.title}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
      </section>

      <section>
        <Box
          sx={{
            maxHeight: '100vh',
            width: '100%',
            background: 'linear-gradient(to bottom, #073346, #06567e)',
            pt: 5,
            position: 'relative',
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Box
              component={'img'}
              src='/static/Affiliate_landing/so_what_next.png'
              sx={{ width: { xs: '80%', md: '22%', lg: '18%', xl: '18%' } }}
            />
          </Box>

          <Typography
            sx={{
              fontSize: {
                xs: '1.3rem',
                sm: '1.4rem',
                md: '2rem',
                lg: '2.3rem',
                xl: '2.3rem',
              },
              color: '#F9A048',
              textAlign: 'center',
              fontFamily: "'Lato', sans-serif",
              fontWeight: 'bold',
              pl: 1,
              pr: 1,
            }}
          >
            Click below and get your KNOCARD today for{' '}
            <span style={{ color: '#fff' }}>FREE</span>
          </Typography>
          <Box sx={{ textAlign: 'center', mt: 4, pb: { xs: 4, md: 10 } }}>
            <OpenButton
              sx={{
                paddingLeft: '20px',
                paddingRight: '20px',
                width: 'fit-content',
                textTransform: 'unset',
              }}
              onClick={handleGetKnoCard}
            >
              Get KnoCard
            </OpenButton>
          </Box>

          <Box
            component={'img'}
            src='/static/Affiliate_landing/dots_right.png'
            sx={{
              position: 'absolute',
              bottom: 26,
              left: 5,
              opacity: 0.8,
              display: {
                xs: 'none',
                sm: 'none',
                md: 'unset',
              },
            }}
          />

          {referrUserInfo && (
            <Box
              sx={{
                position: { xs: 'unset', sm: 'unset', md: 'absolute' },
                right: 5,
                bottom: -130,
                textAlign: 'center',
                pb: { xs: 2, sm: 2, md: 0 },
              }}
              flexDirection={'column'}
            >
              <Box
                component={'img'}
                src={
                  referrUserInfo?.profile_picture
                    ? referrUserInfo?.profile_picture
                    : '/static/images/placeholder_profile.png'
                }
                sx={{
                  height: '150px',
                  width: '150px',
                  borderRadius: '75px',
                  border: '3px solid #0095c4',
                }}
              />
              <Typography
                sx={{
                  mt: 1,
                  mb: 0.5,
                  fontWeight: 'bold',
                  color: { xs: '#ffffff', md: '#12364a' },
                  fontSize: '1.5rem',
                }}
              >
                {referrUserInfo ? referrUserInfo.name : ''}
              </Typography>
            </Box>
          )}
        </Box>
      </section>

      <section>
        <Stack
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
          sx={{ p: { sm: 2, xs: 2, md: 5 }, mb: 10 }}
        >
          <Box
            component={'img'}
            src='/static/Affiliate_landing/calender.png'
            sx={{
              height: '100px',
              objectFit: 'contain',
              mr: { sm: 0, xs: 0, md: '-5px' },
              zIndex: 1,
            }}
          />
          <Box flexDirection={'column'} sx={{ pb: referrUserInfo ? 10 : 2 }}>
            <Typography
              sx={{
                color: '#3b89c2',
                fontSize: '1.5rem',
                fontWeight: 'bold',
                pt: 1,
                textAlign: 'center',
              }}
            >
              Lets’s Chat
            </Typography>
            <Box
              sx={{
                backgroundColor: '#143b51',
                borderRadius: { xs: '15px', sm: '15px', md: '0 15px 15px 0' },
                p: 2,
              }}
            >
              <Box
                component={'a'}
                href={calendarLink}
                sx={{
                  color: '#F9A048',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: 'bold',
                  wordBreak: 'break-word',
                  width: 'fit-content',
                }}
              >
                {calendarLinkLabel}
              </Box>
            </Box>
          </Box>
        </Stack>
      </section>
      {referrUserInfo && (
        <AppBar
          position='fixed'

          sx={{ top: 'auto', bottom: 0, backgroundColor: AppColors.backgroundColor, }}
        >
          <Toolbar
            disableGutters
            sx={{
              backgroundColor: AppColors.backgroundColor,
            }}
          >
            <Box
              gap={1}
              sx={{
                width: '100%',
                '@media (max-width: 900px)': {
                  marginTop: '0px',
                  maxWidth: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  '& > *': {
                    flex: '0',
                  },
                },
              }}
            >

              <Tabs
                onChange={tabHandleChange}
                sx={{
                  overflow: 'inherit',
                  alignContent: 'center',
                  width: '100%',
                  color: 'white',
                }}
                orientation={'horizontal'}
                TabIndicatorProps={{ sx: { display: 'none' } }}
                variant={isSmallScreen ? 'standard' : 'fullWidth'}
              >
                <HeaderTabItem
                  isSmallScreen={true}
                  icon={
                    <Box
                      component={'img'}
                      src={profile}
                      sx={{
                        marginRight: '1rem',
                        marginLeft: '10px',
                        height: '25px',
                        width: '25px',
                      }}
                    />
                  }
                  label='Profile'
                  className={'hover-div'}
                  value={0}
                  sx={{
                    ...(isSmallScreen
                      ? { minWidth: tabMinWidth, maxWidth: tabMinWidth }
                      : {}),
                    flexDirection: isSmallScreen ? 'column' : 'row',
                  }}
                />

                <HeaderTabItem
                  isSmallScreen={true}
                  icon={
                    <Box
                      component={'img'}
                      src={media}
                      sx={{
                        marginRight: '1rem',
                        marginLeft: '10px',
                        height: '25px',
                        width: '25px',
                      }}
                    />
                  }
                  label='Media'
                  className='hover-div'
                  value={1}
                  sx={{
                    ...(isSmallScreen
                      ? { minWidth: tabMinWidth, maxWidth: tabMinWidth }
                      : {}),
                    flexDirection: isSmallScreen ? 'column' : 'row',
                  }}
                />

                <HeaderTabItem
                  isSmallScreen={true}
                  icon={
                    <Box
                      component={'img'}
                      src={social}
                      sx={{
                        marginRight: '1rem',
                        marginLeft: '10px',
                        height: '25px',
                        width: '25px',
                      }}
                    />
                  }
                  label='Social'
                  className='hover-div'
                  value={2}
                  sx={{
                    ...(isSmallScreen
                      ? { minWidth: tabMinWidth, maxWidth: tabMinWidth }
                      : {}),
                    flexDirection: isSmallScreen ? 'column' : 'row',
                  }}
                />

                <HeaderTabItem
                  isSmallScreen={true}
                  icon={
                    <Box
                      component={'img'}
                      src={link}
                      sx={{
                        marginRight: '1rem',
                        marginLeft: '10px',
                        height: '25px',
                        width: '25px',
                      }}
                    />
                  }
                  label='Links'
                  className='hover-div'
                  value={3}
                  sx={{
                    ...(isSmallScreen
                      ? { minWidth: tabMinWidth, maxWidth: tabMinWidth }
                      : {}),
                    flexDirection: isSmallScreen ? 'column' : 'row',
                  }}
                />

                <HeaderTabItem
                  isSmallScreen={true}
                  icon={
                    <Box
                      component={'img'}
                      src={partners}
                      sx={{
                        marginRight: '1rem',
                        marginLeft: '10px',
                        height: '25px',
                        width: '25px',
                      }}
                    />
                  }
                  label='Partners'
                  className='hover-div'
                  value={4}
                  sx={{
                    ...(isSmallScreen
                      ? { minWidth: tabMinWidth, maxWidth: tabMinWidth }
                      : {}),
                    flexDirection: isSmallScreen ? 'column' : 'row',
                  }}
                />

              </Tabs>
            </Box>
          </Toolbar>
        </AppBar>
      )
      }
      {
        openTutorialVideo && (
          <VideoTutorialModel
            key={`open-tutorial-video-model-${openTutorialVideo.id}`}
            onClose={onCloseTutorialVideo}
            toggle={togglenTutorialVideo}
            tutorialVideoItem={{
              ...openTutorialVideo,
              description: '',
              tag: '',
              status: 1,
              migrate_s3: 1,
            }}
            onVideoPlay={handleOnVideoPlay}
            onVideoPause={handleOnVideoPlay}
          />
        )
      }

      <ContactFormModal
        onClose={onCloseContactForm}
        toggle={toggleContactForm}
        onSubmitSuccessRedirect={false}
        onSubmitSuccess={handleContactFormSubmitSuccess}
        source={ContactFormSourceType.WHAT_IS_KNOCARD}
      />

      <GroupNetworkModal
        onClose={onCloseGroup}
        toggle={toggleGroup}
        affiliateLink={referredByLink}
        userInfo={referrUserInfo}
      />
    </React.Fragment >
  );
};

export default AffiliateLanding;
