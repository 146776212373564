import { createSlice } from '@reduxjs/toolkit';
import { getCommunityUsers } from './api';
import { initialState } from './api/interface';
import { UserInfo } from '../userInfo/api/interface';

const communityUsersSlice = createSlice({
  name: 'CommunityUsers',
  initialState,
  reducers: {
    reset: () => initialState,
    selectMember: (state, action) => {
      state.selectedMember = action.payload;
    },
    setSearchUserKeyWord: (state, action) => {
      state.searchKeyWord = action.payload ?? "";
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCommunityUsers.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getCommunityUsers.fulfilled, (state, action) => {
      state.data = [...(action.payload.data.data.current_page == 1 ? [] : state.data), ...action.payload.data.data.data];
      state.isLoadMore = action.payload.data.data.data.length >= 10;
      state.page = action.payload.data.data.current_page + 1;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getCommunityUsers.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset, selectMember, setSearchUserKeyWord } = communityUsersSlice.actions;
export default communityUsersSlice.reducer;
