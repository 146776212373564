import { STATUS_TYPE } from '../../../enum';
import { ContactInfo } from '../../contacts/api/interface';
import { UserInfo } from '../../userInfo/api/interface';

export interface UserReviewResponse {
  status: string;
  message?: string;
  data: {
    current_page?: number;
    data: UserReviewItem[];
    first_page_url?: string;
    from?: number;
    last_page?: number;
    last_page_url?: string;
    next_page_url?: string;
    path?: string;
    per_page?: number;
    prev_page_url?: string;
    to?: number;
    total?: number;
  };
}

export interface ReviewRequestDetailResponse {
  status: string;
  data: {
    id?: number;
    user_id: number;
    share_code?: string;
    action_type?: string;
    targetable_id?: number;
    targetable_type?: string;
    created_at?: string;
    updated_at?: string;
    share_to?: UserInfo | ContactInfo;
  };
}

export interface NewReviewResponse {
  status: {
    code?: string;
    description?: string;
  };
  data?: UserReviewItem;
}

export interface UpdateReviewStatusResponse {
  status?: string;
  data?: UserReviewItem;
}

export interface UserReviewItem {
  agree_to_publish?: number | null;
  created_at?: Date | null;
  email?: string | null;
  experience?: number | null;
  first_name?: string | null;
  id?: number | null;
  last_name?: string | null;
  message?: string | null;
  phone_number?: string | null;
  rater_id?: number | null;
  rater_type?: string | null;
  rating?: string | null;
  recommend?: number | null;
  updated_at?: Date | null;
  user_id?: number | null;
  status?: number | null;
  rater?: UserInfo | ContactInfo | null;
}

export interface IReviewFormState {
  user_id: number;
  rating: number | null;
  first_name: string;
  last_name: string;
  email?: string | null;
  phone_number: string;
  experience: number | null;
  recommend: number | null;
  message: string;
  agree_to_publish: number | null;
  rater_id: string | null;
  rater_code: string | null;
}

interface InitialState {
  data: UserReviewItem[];
  page: number;
  total: number;
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  hasMore: boolean;
  errorMessage: string;
  reviewRequestDetail: ReviewRequestDetailResponse | undefined;
}

export const initialState: InitialState = {
  data: [],
  page: 0,
  total: 0,
  status: 'idle',
  isError: false,
  hasMore: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
  reviewRequestDetail: undefined,
};
