import { createAsyncThunk } from '@reduxjs/toolkit';
import { toastHandler } from '../../../utils/toastHandler';
import { axiosInstance } from '../../../client';

export const findSelectAffiliateGroupState = createAsyncThunk(
  'selectAffiliateGroupState/findSelectAffiliateGroupState',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/api/user/group-state');

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);
