import { getItem, removeItem } from './localStorage';
import { showErrorMessage } from './utilMethods';
import { config } from './utils';

export const toastHandler = (error: any) => {
  if (error?.response?.status == 401 && getItem(config.AUTH_KEY)) {
    removeItem(config.AUTH_KEY);
    removeItem(config.INFO);
    removeItem(config.NEW_TO_KNOCARD_KEY);
    window.location.reload();
    return;
  }
  const keys = Object.keys(error?.errors);
  if (keys?.length > 0) {
    showErrorMessage(error?.errors[keys[0]]);
  } else {
    showErrorMessage(error?.message);
  }

};
