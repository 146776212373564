import { Navbar } from '../components/NewNavBar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Avatar, Box, Button, CircularProgress, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Rating, Stack } from '@mui/material';
import Phone from '../assets/phone.svg';
import Mail from '../assets/mail.svg';
import Location from '../assets/location.svg';
import Brief from '../assets/briefcase.svg';
import ContactInfoIcon from '../assets/contact_info.svg';
import ArrowDownIcon from '../assets/arrow_down.svg'
import '../App.css';
import { useState, useEffect, useRef } from 'react';
import ContactTabPanel from '../components/NewTabPanels/ContactTabPanel';
import NewTabPanelThree from '../components/NewTabPanels/TabPanelThree';
import { checkPhoneNumber } from '../utils/checkPhoneNumber';
import ArrowRight from '../assets/arrow_right.svg'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store';
import useToggle from '../hooks/useToggle';
import { ContactFormSourceType, IHomeParams } from '../enum';
import { getCommunityFeed } from '../store/communityFeed/api';
import { analytics } from '../store/shareRefer/api';
import {
  getUserInfo,
  getUserInfoFromReferenceCode,
} from '../store/userInfo/api';
import {
  reset,
  setSbAffiliateLink,
  setUserInfo,
} from '../store/userInfo/userInfoSlice';
import ShareReferModal from '../components/ShareReferModal';
import ContactFormModal from '../components/ContactFormModal';
import ReferModel from '../components/ReferModel';
import ViewCommunityModal from '../components/modal/ViewCommunityModal';

import WeAreLiveTabPanel from '../components/TabPanels/WeAreLiveTabPanel';
import PreferredPartnersTabPanel from '../components/TabPanels/PreferredPartnersTabPanel';
import MediaTabPanel from '../components/TabPanels/MediaTabPanel';
import RenderCovor from '../components/VbcComponents/RenderCover';
import { splitString } from '../utils/splitString';
import Loader from '../components/Loader';
import { MenuTabBar } from '../components/NewTabBar';
import { AppColors, config } from '../utils/utils';
import Page from '../components/Page';
import { isNotEmpty } from '../utils/valid';
import { getContactPiplineLead } from '../store/engagePipeline/api';
import { ConsentNavBar } from '../components/ConsentNavBar';
import { getPreferredPartners } from '../store/preferredPartner/api';
import {
  getCompanyExternalLinks,
  viewCompanyExternalLink,
} from '../store/companyExternalLinks/api';
import ExternalLinkButtonIcon from '../components/ExternalLinkButtonIcon';
import { PreferredPartnerContents } from '../store/preferredPartner/api/interface';
import { CompanyExternalLink } from '../store/companyExternalLinks/api/interface';
import PreferredPartnerItemNew from '../components/Card/PreferredPartnerItemNew';
import ReviewFormModal from '../components/ReviewFormModal';
import ReviewSuccessModal from '../components/ReviewSuccessModal';
import { checkIsProProOrProOneOrProPlusUser } from '../utils/checkPro';
import UserReviewView from '../components/review/UserReviewView';
import { RiStarSFill } from 'react-icons/ri';
import { openLinkOnNewTab } from '../utils/utilMethods';
import ContactInfoModal from '../components/VbcComponents/ContactInfoModal';
import VbcHeadLine from '../components/VbcComponents/VbxHeadLine';
import { ProgressView } from '../components/VbcComponents/ProgressView';
import PreferredPartnerItem from '../components/Card/PreferredPartnerItem';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Grid>{children}</Grid>}
    </Box>
  );
}

function VBCHomePage() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const paperRef = useRef(null);


  const [isOnIframe] = useState(window.frameElement);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const location = useLocation();
  const params = useParams<IHomeParams>();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { toggle, onOpen, onClose } = useToggle();
  const [activeTab, setActiveTab] = useState(0);
  const show_profile_picture = useAppSelector((state) => state.userInfo.data?.show_profile_picture) ?? 0;

  const authUserInfo = useAppSelector((state) => state?.auth?.user);
  const companyId = useAppSelector((state) => state.userInfo.data?.company?.id);

  const { isLoading: isPreferredPartnersLoading, page } = useAppSelector(
    (state) => state?.preferredPartner
  );
  const preferredPartners =
    useAppSelector(
      (state) => state?.preferredPartner?.data?.preferredPartners
    ) ?? [];

  /*const {
    toggle: toggleExternalLink,
    onOpen: onOpenExternalLink,
    onClose: onCloseExternalLink,
  } = useToggle();*/

  useEffect(() => {
    scrollToTop();

  }, [activeTab]);

  useEffect(() => {
    if (
      userInfo?.id &&
      !isPreferredPartnersLoading &&
      preferredPartners.length == 0
    ) {
      dispatch(getPreferredPartners({ userId: userInfo.id, pageNo: page }));
    }
  }, [dispatch]);

  const {
    data: companyExternalLinks,
    isLoading: isLoadingCompanyExternalLinks,
  } = useAppSelector((state) => state?.companyExternalLinks);

  useEffect(() => {
    if (companyId && !isLoadingCompanyExternalLinks) {
      dispatch(getCompanyExternalLinks(companyId));
    }
  }, [dispatch, companyId]);

  // const [defaultHomePage, setDefaultHomePage] = useState(0);
  const {
    toggle: toggleCommunity,
    onOpen: onOpenCommunity,
    onClose: onCloseCommunity,
  } = useToggle();
  const {
    toggle: toggleRefer,
    onOpen: onOpenRefer,
    onClose: onCloseRefer,
  } = useToggle();
  const {
    data: userInfo,
    isLoading,
    // sc: scCode,
  } = useAppSelector((state) => state.userInfo);

  const {
    toggle: toggleContactForm,
    onOpen: onOpenContactForm,
    onClose: onCloseContactForm,
  } = useToggle();


  const {
    toggle: toggleReviewForm,
    onOpen: onOpenReviewForm,
    onClose: onCloseReviewForm,
  } = useToggle();

  const {
    toggle: toggleReviewSuccessForm,
    onOpen: onOpenReviewSuccessForm,
    onClose: onCloseReviewSuccessForm,
  } = useToggle();


  const {
    toggle: toggleContactInfo,
    onOpen: onOpenContactInfo,
    onClose: onCloseContactInfo,
  } = useToggle();


  useEffect(() => {

    if (toggleReviewSuccessForm == false && userInfo) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete(config.REVIEW_KEY);
      navigate(`/${userInfo?.username}?${queryParams.toString()}`);
    }

  }, [toggleReviewSuccessForm])

  const handleRatingSuccess = () => {
    onOpenReviewSuccessForm();
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (userInfo && !toggleContactForm) {
      const sc = searchParams.get('sc');
      let loginUserId = authUserInfo?.id;
      let elid = searchParams.get('elid');

      dispatch(
        analytics({
          route: '/activity/save',
          data: {
            actionType: 'view',
            sourceType: 'link_share',
            module: 'profile',
            targetId: userInfo?.id,
            identifiableId: userInfo?.id,
            ...(loginUserId && { viewableId: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
            ...((!loginUserId && elid) && { elid: elid })
          },
        })
      );
    }
  }, [toggleContactForm]);

  const handleChange = (event: any, newValue: number) => {
    const sc = searchParams.get('sc');
    let elid = searchParams.get('elid');
    let loginUserId = authUserInfo?.id;

    setActiveTab(newValue);
    if (newValue === 0) {
      navigate(
        `/${userInfo?.username}/contact${sc != null ? `?sc=${sc}${elid ? `&elid=${elid}` : ''}` : ''
        }`
      );

      dispatch(
        analytics({
          route: '/activity/save',
          data: {
            actionType: 'view',
            sourceType: 'link_share',
            module: 'profile',
            targetId: userInfo?.id,
            identifiableId: userInfo?.id,
            ...(loginUserId && { viewableId: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
            ...((!loginUserId && elid) && { elid: elid })
          },
        })
      );
    }
    if (newValue === 1) {
      navigate(
        `/${userInfo?.username}/media${sc != null ? `?sc=${sc}${elid ? `&elid=${elid}` : ''}` : ''
        }`
      );

      dispatch(
        analytics({
          route: '/activity/save',
          data: {
            actionType: 'view',
            sourceType: 'link_share',
            module: 'photo_gallery',
            targetId: userInfo?.id,
            identifiableId: userInfo?.id,
            ...(loginUserId && { viewableId: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
            ...((!loginUserId && elid) && { elid: elid })
          },
        })
      );
    }
    if (newValue === 2) {
      navigate(
        `/${userInfo?.username}/company${sc != null ? `?sc=${sc}${elid ? `&elid=${elid}` : ''}` : ''
        }`
      );

      dispatch(
        analytics({
          route: '/activity/save',
          data: {
            actionType: 'view',
            sourceType: 'link_share',
            module: 'business_page',
            targetId: userInfo?.id,
            identifiableId: userInfo?.company?.id,
            ...(loginUserId && { viewableId: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
            ...((!loginUserId && elid) && { elid: elid })
          },
        })
      );
    }
    if (newValue === 3) {
      navigate(
        `/${userInfo?.username}/external-link${sc != null ? `?sc=${sc}${elid ? `&elid=${elid}` : ''}` : ''
        }`
      );
      dispatch(
        analytics({
          route: '/activity/save',
          data: {
            actionType: 'view',
            sourceType: 'link_share',
            module: 'external_link',
            targetId: userInfo?.id,
            identifiableId: userInfo?.id,
            ...(loginUserId && { viewableId: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
            ...((!loginUserId && elid) && { elid: elid })
          },
        })
      );
    }
    if (newValue === 4) {
      navigate(
        `/${userInfo?.username}/preferred-partners${sc != null ? `?sc=${sc}${elid ? `&elid=${elid}` : ''}` : ''
        }`
      );
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      const sc = searchParams.get('sc');
      const sb = searchParams.get('sb');
      let elid = searchParams.get('elid');
      let loginUserId = authUserInfo?.id;
      const showProspectForm = searchParams.get(config.PROSPECT_KEY);
      const username = params?.username;
      const activeTab = params?.tab;
      if (!username) return;

      if (!isLoading) {
        if (activeTab === 'media') {
          setActiveTab(1);
        } else if (activeTab === 'company') {
          setActiveTab(2);
        } else if (activeTab === 'external-link') {
          setActiveTab(3);
        } else if (activeTab === 'preferred-partners') {
          setActiveTab(4);
        }
        scrollToTop();
      }

      if (userInfo || isLoading) return;
      try {
        if (sc != null && elid != null) {
          dispatch(getContactPiplineLead({ contact_code: sc, elid: elid }));
        }
        const res = await dispatch(getUserInfo({ username })).unwrap();
        if (res.data.data.user.length > 0) {
          dispatch(setUserInfo(res.data));
        } else {
          dispatch(reset());
          navigate(`/user-name-not-found-page`);
        }
        if (sb) {
          const res = await dispatch(
            getUserInfoFromReferenceCode({ referenceCode: sb })
          ).unwrap();
          if (res.data?.data?.user != undefined) {
            dispatch(
              setSbAffiliateLink({
                sbUser: res.data.data.user,
              })
            );
          }
        }
        const userId = res.data.data.user[0].id;
        /* dispatch(
           analytics({
             route: '/activity/save',
             data: {
               actionType: 'view',
               sourceType: 'link_share',
               module: 'profile',
               targetId: id,
               identifiableId: id,
               ...(sc && { viewer_code: sc }),
               ...(elid && { elid: elid })
             },
           })
         );*/

        if (activeTab === undefined) {
          if (!showProspectForm) {
            dispatch(
              analytics({
                route: '/activity/save',
                data: {
                  actionType: 'view',
                  sourceType: 'link_share',
                  module: 'profile',
                  targetId: userId,
                  identifiableId: userId,
                  ...(loginUserId && { viewableId: loginUserId }),
                  ...((!loginUserId && sc) && { viewer_code: sc }),
                  ...((!loginUserId && elid) && { elid: elid })
                },
              })
            );
          }
          /* dispatch(
             analytics({
               route: '/activity/save',
               data: {
                 actionType: 'view',
                 sourceType: 'link_share',
                 module: 'business_page',
                 targetId: userId,
                 identifiableId: userId,
                 ...(sc && { viewer_code: sc }),
                 ...(elid && { elid: elid })
               },
             })
           );*/
        }
        if (activeTab === 'media') {
          if (!showProspectForm) {
            dispatch(
              analytics({
                route: '/activity/save',
                data: {
                  actionType: 'view',
                  sourceType: 'link_share',
                  module: 'photo_gallery',
                  targetId: userId,
                  identifiableId: userId,
                  ...(loginUserId && { viewableId: loginUserId }),
                  ...((!loginUserId && sc) && { viewer_code: sc }),
                  ...((!loginUserId && elid) && { elid: elid })
                },
              })
            );
          }
          setActiveTab(1);
          //setValue(1);
        } else if (activeTab === 'community' && params?.postId) {
          const result = await dispatch(
            getCommunityFeed(Number(params?.postId))
          ).unwrap();
          if (result?.data?.status?.code === 'success') {
            if (isNotEmpty(result?.data?.company_feed?.contents)) {
              setMediaImage(
                result?.data?.company_feed?.contents[0].thumbnail ?? ''
              );
            }

            onOpenCommunity();
            if (!showProspectForm) {
              dispatch(
                analytics({
                  route: '/activity/save',
                  data: {
                    actionType: 'view',
                    sourceType: 'link_share',
                    module: 'business_page',
                    targetId: userId,
                    identifiableId: userId,
                    ...(loginUserId && { viewableId: loginUserId }),
                    ...((!loginUserId && sc) && { viewer_code: sc }),
                    ...((!loginUserId && elid) && { elid: elid })
                  },
                })
              );
            }
          }
        } else if (activeTab === 'refer') {
          onOpenRefer();
        }
      } catch (error) {
        console.log(error);
        navigate(`/user-name-not-found-page`);
      }
    };
    fetchUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //userInfo?.knocard?.homepage
    if (userInfo) {
      /* const result = getActiveIndex(
         parseInt(userInfo?.knocard?.homepage ?? '0'),
         userInfo.user_features
       );

       setValue(result);*/
      // setDefaultHomePage(result);
      //let sc = searchParams.get('sc');
      const showProspectForm = searchParams.get(config.PROSPECT_KEY);
      const reviewForm = searchParams.get(config.REVIEW_KEY);
      const activeTab = params?.tab;
      if (activeTab === 'refer') {
        return;
      }

      if (reviewForm && checkIsProProOrProOneOrProPlusUser(userInfo)) {
        onOpenReviewForm();
      } else if (showProspectForm && checkIsProProOrProOneOrProPlusUser(userInfo)) {
        onOpenContactForm();
      }
      /*
             if (result === 0 && activeTab === 'community' && params?.postId) {
             navigate(`/${userInfo?.username}/community/${params?.postId}${sc != null ? `?sc=${sc}` : ''}`);
              } else {
                handleChange(null, result);
              }*/
      /*
      if (result === 0) {
        if (activeTab === 'community' && params?.postId) {
          navigate(`/${userInfo?.username}/community/${params?.postId}${sc != null ? `?sc=${sc}` : ''}`);
        } else {
          navigate(`/${userInfo?.username}/contact${sc != null ? `?sc=${sc}` : ''}`);
        }
      } else if (result === 1) {
        navigate(`/${userInfo?.username}/media${sc != null ? `?sc=${sc}` : ''}`);
      } else if (result === 2) {
        navigate(`/${userInfo?.username}/company${sc != null ? `?sc=${sc}` : ''}`);
      } else if (result === 3) {
        navigate(`/${userInfo?.username}/external-link${sc != null ? `?sc=${sc}` : ''}`);
      } else if (result === 4) {
        navigate(`/${userInfo?.username}/preferred-partners${sc != null ? `?sc=${sc}` : ''}`);
      }*/
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.knocard?.homepage]);

  // const { data: userInfo } = useAppSelector((state) => state.userInfo);
  const contactNumber = checkPhoneNumber(
    userInfo?.phone_number,
    userInfo?.phone_country_code,
    userInfo?.show_phone_number,
    userInfo?.company?.business_phone
  );
  const mergedAddress = `${(userInfo?.street1 ?? '').length > 0 ? userInfo?.street1 + ', ' : ''
    }${(userInfo?.street2 ?? '').length > 0 ? userInfo?.street2 + ', ' : ''}${userInfo?.city ?? ''
    }, ${userInfo?.state ?? ''}, ${userInfo?.zip_code ?? ''}`;
  const occupation =
    useAppSelector((state) => state.userInfo.data?.occupation) ?? '';
  const name =
    useAppSelector(
      (state) =>
        `${state.userInfo.data?.first_name ?? ''} ${state.userInfo.data?.last_name ?? ''
        }`
    ) ?? '';
  const sbUserName =
    useAppSelector(
      (state) =>
        state.userInfo.sbUser?.username ?? state.userInfo.data?.username
    ) ?? '';

  const profile_picture =
    useAppSelector((state) => state.userInfo.data?.profile_picture) ?? '';

  const background_image =
    useAppSelector((state) => state.userInfo.data?.knocard?.background_image) ??
    '';
  const coverType = splitString(background_image);
  const [mediaImage, setMediaImage] = useState<string>();

  const contactPiplineLead = useAppSelector(
    (state) => state.engagePipline.contactPiplineLead
  );

  const openExternalLink = (companyExternalLink: CompanyExternalLink) => {
    try {
      const sc = searchParams.get('sc');
      dispatch(
        viewCompanyExternalLink({
          company_external_link_id: companyExternalLink.id,
          viewer_code: sc,
          viewer_id: authUserInfo?.id,
        })
      );
      openLinkOnNewTab(companyExternalLink.link);
    } catch (e) { }
  };






  if (isLoading) return <Loader />;

  return (
    <Page title={`${name} | ${userInfo?.affiliate_group?.team_name || 'KnoCard'}`} image={mediaImage} sx={{ backgroundColor: AppColors.backgroundColor }}>
      <Box sx={{ position: 'relative', pb: isSmallScreen ? 10 : 0 }}>
        {contactPiplineLead &&
          userInfo &&
          contactPiplineLead.consent_status == 'pending' ? (
          <ConsentNavBar
            userInfo={userInfo}
            contactPiplineLead={contactPiplineLead}
          />
        ) : (
          !isOnIframe && <Navbar affiliateUserName={sbUserName} userInfo={userInfo} isSmallScreen={isSmallScreen} />
        )}


        <Grid container spacing={1} sx={{ mt: 0, pt: 2 }}>

          <Grid
            item
            xs={12}
            md={2.5}
            sx={{
              paddingTop: '0px !important',
            }}
          >
            {/* Desktop Side Bar */}
            <Paper
              ref={paperRef}
              className={`left-section}`}
              elevation={isSmallScreen ? 0 : 4}
              sx={{

                pb: 2,
                ...(isSmallScreen ? { background: "transparent" } : { ml: 1, mr: 1, borderRadius: "24px", boxShadow: "unset", background: AppColors.whiteColor })
              }}
            >
              {isSmallScreen && (activeTab == 0) && (
                <Box height={'200px'} position='relative' sx={{
                  pl: 1.3, pr: 1.3
                }}>
                  <RenderCovor props={coverType} />
                </Box>
              )}


              {!(isSmallScreen && (activeTab != 0)) && (<Box
                className={` ${isSmallScreen ? 'avatar-container' : ''}`}
                sx={
                  !isSmallScreen
                    ? {
                      display: 'flex',
                      justifyContent: 'center',
                    }
                    : {}
                }
              >
                {show_profile_picture !== 0 ? (
                  <Avatar
                    alt='profile picture'
                    sx={{
                      width: '120px',
                      height: '120px',
                      '@media (max-width: 900px)': {
                        width: '85px',
                        height: '85px',
                      },
                      background: '#c4cdd500',
                      margin: '10px',
                      ...(isSmallScreen ? { marginLeft: '30px', marginTop: '0px' } : {}),
                    }}
                    className={`${isSmallScreen ? 'avatar' : ''}`}
                  >
                    <img
                      src={
                        profile_picture.length > 0
                          ? profile_picture
                          : '/static/images/placeholder_profile.png'
                      }
                      alt='profile pic'
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '50%',
                        border: '2px solid white',
                      }}
                    />
                  </Avatar>
                ) : (
                  <Box
                    sx={{
                      width: '120px',
                      height: '120px',
                      '@media (max-width: 900px)': {
                        width: '70px',
                        height: '70px',
                      },
                    }}
                  ></Box>
                )}
              </Box>)}
              {!isSmallScreen && (
                <Box sx={{ textAlign: "center" }}>
                  <Rating name="read-only-rating" value={Number(userInfo?.rating ?? "0")} readOnly sx={{
                    mt: 0.5,
                    mr: 0.5,
                    '& .MuiRating-iconFilled': {
                      color: AppColors.yellowColor,
                    },
                    '& .MuiRating-iconEmpty': {
                      color: AppColors.grayTwoColor,
                    }
                  }} />
                </Box>
              )}
              {!isSmallScreen && (
                <Typography
                  variant='body1'
                  sx={{
                    textAlign: 'center',
                    color: AppColors.blackColor,
                    fontWeight: '500',
                  }}
                >
                  {name}
                </Typography>
              )}
              {!isSmallScreen && occupation && (
                <Typography
                  variant='body2'
                  sx={{
                    textAlign: 'center',
                    color: AppColors.grayThreeColor,
                    fontWeight: 'medium'
                  }}
                >
                  {occupation}
                </Typography>
              )}


              {isSmallScreen && (
                <Box
                  sx={{
                    mt: (activeTab == 0) ? 4 : 0,
                    ml: '16px',
                    mr: '16px',
                    width: 'auto',
                    height: 'auto',
                    position: 'relative',
                  }}
                >
                  <Stack direction="row" sx={{ display: "flex", alignItems: "start" }}>
                    {(activeTab != 0) && (<Avatar
                      alt='profile picture'
                      sx={{
                        width: '70px',
                        height: '70px',
                        ml: 1
                      }}
                      className={`${isSmallScreen ? 'avatar' : ''}`}
                    >
                      <img
                        src={
                          profile_picture.length > 0
                            ? profile_picture
                            : '/static/images/placeholder_profile.png'
                        }
                        alt='profile pic'
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: '50%',
                          border: '2px solid white',
                        }}
                      />
                    </Avatar>)}
                    <Stack direction="column" sx={{ display: "flex", alignItems: "start", width: "100%", mt: (activeTab != 0) ? 0.5 : 0 }}>
                      <Typography
                        variant='body1'
                        sx={{
                          textAlign: 'center',
                          color: (activeTab == 0) ? AppColors.blackColor : AppColors.primaryColor,
                          fontWeight: '500',
                        }}
                      >
                        {name}
                      </Typography>
                      <Stack
                        direction="row"
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "start" // Changed from "flex-start" to "center"
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <Typography
                            variant='body2'
                            sx={{
                              textAlign: 'left',
                              color: AppColors.grayThreeColor,
                              fontWeight: 'medium'
                            }}
                          >
                            {occupation}
                          </Typography>
                          <Stack
                            direction="row"
                            sx={{
                              display: "flex",
                              justifyContent: "left",
                              alignItems: "left" // Changed from "flex-start" to "center"
                            }}
                          >
                            <RiStarSFill style={{ color: AppColors.yellowColor, height: "20px", width: "20px" }} />
                            <Typography
                              variant='body2'
                              sx={{
                                ml: 0.5,
                                textAlign: 'center',
                                color: AppColors.grayThreeColor,
                                fontWeight: 'medium'
                              }}
                            >
                              {Number(userInfo?.rating ?? "0").toFixed(1)}
                            </Typography>
                          </Stack>
                        </Box>
                        {activeTab == 0 && (<Button
                          variant="contained"
                          onClick={() => onOpenContactInfo()}
                          startIcon={<Box component={'img'} src={ContactInfoIcon} />}
                          sx={{ minWidth: "180px" }}
                        >
                          Contact Me
                          <Box component={'img'} src={ArrowDownIcon} />
                        </Button>)}
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              )}

              {!isSmallScreen && (
                <Box sx={{ marginLeft: '5px', marginRight: '5px' }}>
                  <Divider sx={{ mt: 1, mb: 1, backgroundColor: AppColors.strokeColor }} />
                  {contactNumber && (<ListItem onClick={() => openLinkOnNewTab(`tel:${contactNumber}`)}>
                    <ListItemIcon sx={{ minWidth: "35px" }}>
                      <img
                        src={Phone}
                        alt='Phone'
                        height='25px'
                        width='25px'
                      />
                    </ListItemIcon>
                    <ListItemText primary={contactNumber} sx={{
                      color: AppColors.grayThreeColor,
                      marginLeft: '3px',
                      marginTop: '3px',
                      fontSize: 'medium',
                      wordWrap: 'break-word'
                    }}
                    />
                  </ListItem>)}


                  <ListItem
                    onClick={() => openLinkOnNewTab(`mailto: ${userInfo?.email}`)}
                  >
                    <ListItemIcon sx={{ minWidth: "35px" }}>
                      <img
                        src={Mail}
                        alt='Mail'
                        height='25px'
                        width='25px'
                      />
                    </ListItemIcon>
                    <ListItemText primary={userInfo?.email} sx={{
                      color: AppColors.grayThreeColor,
                      marginLeft: '3px',
                      marginTop: '3px',
                      fontSize: 'medium',
                      wordWrap: 'break-word'
                    }}
                    />
                  </ListItem>


                  <ListItem
                    onClick={() => openLinkOnNewTab(userInfo?.latitude != null &&
                      userInfo?.longitude != null &&
                      Number(userInfo?.latitude) !== 0 &&
                      Number(userInfo?.longitude) !== 0
                      ? `https://www.google.com/maps/@${userInfo?.latitude ?? ''
                      },${userInfo?.longitude}`
                      : `https://maps.google.com/?q=${mergedAddress}`)}
                  >
                    <ListItemIcon sx={{ minWidth: "35px" }}>
                      <img
                        src={Location}
                        alt='Location'
                        height='25px'
                        width='25px'
                      />
                    </ListItemIcon>
                    <ListItemText primary={mergedAddress} sx={{
                      color: AppColors.grayThreeColor,
                      marginLeft: '3px',
                      marginTop: '3px',
                      fontSize: 'medium',
                      wordWrap: 'break-word'
                    }}
                    />
                  </ListItem>


                  <ListItem>
                    <ListItemIcon sx={{ minWidth: "35px" }}>
                      <img
                        src={Brief}
                        alt='Brief'
                        height='25px'
                        width='25px'
                      />
                    </ListItemIcon>
                    <ListItemText primary={occupation} sx={{
                      color: AppColors.grayThreeColor,
                      marginLeft: '3px',
                      marginTop: '3px',
                      fontSize: 'medium',
                      wordWrap: 'break-word'
                    }}
                    />
                  </ListItem>

                  <Divider sx={{ mt: 1, mb: 1, backgroundColor: AppColors.strokeColor }} />
                </Box>
              )}

              {!isSmallScreen && (
                <MenuTabBar
                  isSmallScreen={isSmallScreen}
                  handleChange={handleChange}
                  userInfo={userInfo}
                  value={activeTab}
                />
              )}
            </Paper>
          </Grid>
          {isSmallScreen && (
            <MenuTabBar
              isSmallScreen={isSmallScreen}
              handleChange={handleChange}
              userInfo={userInfo}
              value={activeTab}
            />
          )}
          {/* </div> */}
          <Grid
            item
            xs={12}
            md={6.5}
            sx={{
              padding: '0px !important',
            }}
          >
            <TabPanel value={activeTab} index={0}>
              <ContactTabPanel setActiveTab={setActiveTab} />
            </TabPanel>
            <MediaTabPanel value={activeTab} index={1} />

            <WeAreLiveTabPanel value={activeTab} index={2} />

            <TabPanel value={activeTab} index={3}>
              <NewTabPanelThree />
            </TabPanel>
            <PreferredPartnersTabPanel value={activeTab} index={4} />
          </Grid>

          {!isSmallScreen && (
            <Grid
              item
              xs={3}

            >

              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  {/*{isSelected(userInfo?.user_features, 10) && (*/}
                  {!isSmallScreen && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        ml: 1,
                        mr: 1,
                        justifyContent: 'center',
                        position: 'relative',
                        pt: 1,
                        pl: 0
                      }}
                    >
                      <Paper sx={{ pt: 1, pb: 1, boxShadow: 'unset', width: "100%" }}>
                        <VbcHeadLine headLine={"My Links"}
                          buttonLabel={isSmallScreen ? "See all" : null}
                          sx={{ pl: 1 }}
                        />

                        <Box mt={1} textAlign={'center'}>
                          {isLoadingCompanyExternalLinks ? (<ProgressView />) : (
                            companyExternalLinks?.map((companyExternalLink, idx) => {
                              if (companyExternalLink.status === 'ACT') {
                                return (<Box sx={{ backgroundColor: AppColors.grayZeroColor, borderRadius: "12px", m: 1 }}>
                                  <ListItemButton onClick={() =>
                                    openExternalLink(companyExternalLink)
                                  }>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                      <ExternalLinkButtonIcon
                                        companyExternalLink={companyExternalLink}
                                      />
                                    </ListItemIcon>
                                    <ListItemText primary={companyExternalLink.title} sx={{ width: "max-content", mr: 2, color: AppColors.blackColor }} />
                                    <Box component={'img'} src={ArrowRight}></Box>
                                  </ListItemButton>
                                </Box>
                                );
                              } else {
                                return <></>;
                              }
                            })

                          )}
                        </Box>
                      </Paper>
                    </Grid>
                  )}
                  {/*)}*/}
                  {!isSmallScreen && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        ml: 1,
                        mr: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'relative',
                        mt: 3,
                      }}
                    >

                      <Paper
                        className='right-row'
                        variant='outlined'
                        sx={{
                          borderColor: 'transparent',
                          borderRadius: '10px',
                          width: "100%",
                        }}
                      >
                        <VbcHeadLine headLine={"Preferred Partners"} sx={{ pl: 1 }} />

                        <Stack
                          direction='column'
                          style={{
                            justifyContent: 'center',
                          }}
                        >
                          {isPreferredPartnersLoading &&
                            preferredPartners.length == 0 ? (
                            <ProgressView />
                          ) : (
                            preferredPartners?.map((partner, index) =>
                              index < 2 ? (
                                <Box>
                                  <PreferredPartnerItem
                                    key={`pp-${index}`}
                                    partner={partner}
                                    index={index}
                                    showEditMenu={false}
                                    isHomePageItem={true}
                                    onMediaSelect={(
                                      contents: PreferredPartnerContents[],
                                      index: number
                                    ) => { }}
                                    cardColor={AppColors.grayZeroColor}
                                  /></Box>
                              ) : (
                                <></>
                              )
                            )
                          )}
                        </Stack>
                      </Paper>

                      {/*)}*/}
                    </Grid>
                  )}

                  {!isSmallScreen && userInfo && checkIsProProOrProOneOrProPlusUser(userInfo) && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        ml: 1,
                        mr: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'relative',
                        mt: 3,
                      }}
                    >

                      <Paper
                        className='right-row'
                        variant='outlined'
                        sx={{
                          borderColor: 'transparent',
                          borderRadius: '10px',
                          width: "100%",
                        }}
                      >
                        <VbcHeadLine headLine={"Testimonials"} sx={{ pl: 1 }} />
                        <Box sx={{ mt: 1 }}>
                          <UserReviewView userInfo={userInfo} isSmallScreen={isSmallScreen} reviewColumns={1} backgrounColor={AppColors.grayZeroColor} />
                        </Box>
                      </Paper>

                    </Grid>
                  )}



                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        <ShareReferModal onClose={onClose} toggle={toggle} />

        <ContactFormModal
          onClose={onCloseContactForm}
          toggle={toggleContactForm}
          onSubmitSuccessRedirect={true}
          source={ContactFormSourceType.QR_FORM}
        />

        <ViewCommunityModal
          toggle={toggleCommunity}
          onClose={onCloseCommunity}
          redirectToContact={true}
        />

        <ReferModel onClose={onCloseRefer} toggle={toggleRefer} />

        <ReviewFormModal
          onClose={onCloseReviewForm}
          toggle={toggleReviewForm}
          onRatingSuccess={handleRatingSuccess}
        />

        <ReviewSuccessModal
          onClose={onCloseReviewSuccessForm}
          toggle={toggleReviewSuccessForm}
        />

        <ContactInfoModal
          onClose={onCloseContactInfo}
          toggle={toggleContactInfo}
        />

      </Box>
    </Page >
  );
}

export default VBCHomePage;
