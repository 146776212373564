import {
  AppBar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import styles from '../styles/Subscription.module.css';
import Page from '../components/Page';
import { getItem, removeItem, setItem } from '../utils/localStorage';

import { INotFoundParams } from '../enum';
import { AppColors, config } from '../utils/utils';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getPricingVideos } from '../store/pricingVideo/api';
import useToggle from '../hooks/useToggle';
import VideoModel from '../components/modal/VideoModel';
import { useCollapse } from 'react-collapsed';
import { isMobile } from '../utils/getMobileOperatingSystem';
import Toggle from '../components/Toggle';
import { getSubscriptionPrice } from '../utils/utilMethods';
import {
  findAffiliateGroup,
  findAffiliateGroupPricing,
} from '../store/AffiliateGroup/api';
import {
  AffiliateGroupPricing,
  AffiliateGroup,
} from '../store/AffiliateGroup/api/interface';
import { getUserInfo } from '../store/userInfo/api';
import GradeIcon from '@mui/icons-material/Grade';

const Subscription = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const params = useParams<INotFoundParams>();

  const [isProAnnually, setProAnnually] = useState(false);

  const { data: pricingVideos, isLoading } = useAppSelector(
    (state) => state.pricingVideo
  );

  const {
    data: affiliateGroupList,
    isLoading: isAffiliateGroupLoading,
    affiliateGroupPricing,
    isPricingLoading,
  } = useAppSelector((state) => state?.affiliateGroup);

  const [videoUrl, setVideoUrl] = useState<string | undefined>();
  const { toggle, onOpen, onClose } = useToggle();

  const [defaultAffiliateGroupPricing, setDefaultAffiliateGroupPricing] =
    useState<AffiliateGroupPricing | undefined>();
  const [selectedAffiliateGroupPricing, setSelectedAffiliateGroupPricing] = useState<AffiliateGroupPricing | undefined>();

  const [selectedAffiliateGroup, setSelectedAffiliateGroup] = useState<
    AffiliateGroup | undefined
  >();
  const [isFreePlanExpanded, setFreePlanExpanded] = useState(
    isMobile() ? false : true
  );
  const {
    getCollapseProps: getCollapsePropsFreePlan,
    getToggleProps: getTogglePropsFreePlan,
  } = useCollapse({
    isExpanded: isFreePlanExpanded,
  });

  const [isBasicPlanExpanded, setBasicPlanExpanded] = useState(false);
  const {
    getCollapseProps: getCollapsePropsBasicPlan,
    getToggleProps: getTogglePropsBasicPlan,
  } = useCollapse({
    isExpanded: isBasicPlanExpanded,
  });

  const [isProPlanExpanded, setProPlanExpanded] = useState(false);

  const [isProOnePlanExpanded, setProOnePlanExpanded] = useState(false);

  const {
    getCollapseProps: getCollapsePropsProPlan,
    getToggleProps: getTogglePropsProPlan,
  } = useCollapse({
    isExpanded: isProPlanExpanded,
  });

  const [isProPlusPlanExpanded, setProPlusPlanExpanded] = useState(isMobile() ? false : true);
  const {
    getCollapseProps: getCollapsePropsProPlusPlan,
    getToggleProps: getTogglePropsProPlusPlan,
  } = useCollapse({
    isExpanded: isProPlusPlanExpanded,
  });

  useEffect(() => {
    if (isPricingLoading === false && affiliateGroupPricing.length === 0) {
      dispatch(findAffiliateGroupPricing());
    }

    if (isAffiliateGroupLoading === false && affiliateGroupList.length === 0) {
      dispatch(findAffiliateGroup());
    }

    const _username = params?.username;

    if (_username) {
      dispatch(getUserInfo({ username: _username }))
        .unwrap()
        .then((res) => {
          try {

            // if (res.data.data.user[0]?.affiliate_group && !getItem(config.GROUP_NETWORK_ID_KEY)) {
            //   setItem(
            //     config.GROUP_NETWORK_ID_KEY,
            //     res.data.data.user[0].affiliate_group.id
            //   );
            // }
          } catch (ex) { }
        });
    }
    removeItem(config.IS_PURCHASE_SELECTED_PLAN_KEY)
  }, []);

  useEffect(() => {
    if (toggle == false) {
      setVideoUrl(undefined);
    }
  }, [toggle]);

  useEffect(() => {
    if (!isLoading) {
      dispatch(getPricingVideos());
    }
  }, []);

  function onPlanSelect(planType: string) {
    setItem(config.SLECTED_PLAN_KEY, planType);
    setItem(config.SLECTED_PLAN_IS_ANNUALLY_KEY, isProAnnually);
    removeItem(config.IS_KNOCARD_LINK_SELECTED_KEY);
    navigate(`/knocard-cart/${params?.username ?? ''}`);
  }

  const hadlePlayVideo = (videoUrl: string | undefined) => {
    if (videoUrl != undefined) {
      setVideoUrl(videoUrl);
      onOpen();
    }
  };

  useEffect(() => {
    let isPricingSet = false;
    if (affiliateGroupList.length > 0) {
      let selectedId = getItem(config.GROUP_NETWORK_ID_KEY);

      if (selectedId != null) {
        affiliateGroupList.forEach((item) => {
          if (`${item.id}` == selectedId) {
            if (item.group_pricing != undefined) {
              setSelectedAffiliateGroup(item);
              setSelectedAffiliateGroupPricing(item.group_pricing);
              isPricingSet = true;
            }
          }
        });
      }
    }

    affiliateGroupPricing.forEach((item) => {
      if (item.is_default == 1) {
        if (!isPricingSet) {
          setSelectedAffiliateGroupPricing(item);
          isPricingSet = true;
        }
        setDefaultAffiliateGroupPricing(item);
      }
    });

    if (!isPricingSet) {
      setSelectedAffiliateGroupPricing(undefined);
    }
  }, [affiliateGroupList, affiliateGroupPricing]);


  const getGetSubscribeButton = (getStartPlan: string) => {
    return (
      <Box
        sx={{
          background: AppColors.primaryColor,
          textAlign: 'center',
          borderRadius: '100px',
          width: 'fit-content',
          pl: 1.5,
          pr: 1.5,
          pt: 1,
          pb: 1
        }}
        onClick={() => onPlanSelect(getStartPlan)}
      >
        <Typography sx={{ color: '#ffffff' }}> Subscribe</Typography>
      </Box>
    );
  };

  return (
    <>
      <Page
        title='Subscription | KnoCard'
        showAppBar={true}
        sx={{ minHeight: "100vh", backgroundColor: AppColors.backgroundColor }}
      >
        <AppBar
          position='sticky'
          sx={{
            backgroundImage: 'url("/static/images/header_bg.svg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            minHeight: '60px'
          }}
        >
          <Stack
            flexDirection={'row'}
            display={'flex'}
            alignItems={'center'}
            sx={{ position: 'relative', minHeight: "60px" }}
          >
            <Box
              component={'img'}
              src={selectedAffiliateGroup?.brand_logo_link ?? '/static/images/white_logo.svg'}
              sx={{
                position: 'absolute',
                left: 10,
                maxHeight: '60px',
                ml: 2,

                display: { md: 'block', xs: 'none' },
              }}
            ></Box>
            <Typography
              sx={{
                width: '100%',
                textAlign: 'center',
                fontWeight: '500',
                fontSize: '1.5rem',
              }}
            >
              Select Your Plan
            </Typography>
          </Stack>
        </AppBar>


        <Box sx={{ background: `${AppColors.backgroundColor}` }}>

          <Box
            className={styles.toggleBtnRow}
            sx={{ mt: 0, pt: 2, pr: 2 }}
          >
            <Box className={styles.toggleLabel}>Monthly</Box>
            <Box className={styles.toggleBtn}>
              <Toggle
                toggled={isProAnnually}
                onClick={(isActive) => setProAnnually(isActive)}
              />
            </Box>
            <Box className={styles.toggleLabel}>Annually</Box>
          </Box>

          <Box>
            <Grid container
              gap={{ xs: 5, md: 0 }}
              pb={10} pt={5} justifyContent={'center'}>
              <Grid
                item
                xs={11}
                sm={11}
                md={3}
              >
                <Box
                  sx={{
                    borderTopRightRadius: { md: "0px", xs: '35px' },
                    borderTopLeftRadius: '35px',
                    borderBottomLeftRadius: '35px',
                    borderBottomRightRadius: '35px',
                    backgroundColor: '#ffffff',
                    filter: "drop-shadow(0px 0px 2px #E8ECF4)",
                    textAlign: 'center',
                    mt: { md: 8, xs: 2 }
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '2rem',
                      fontWeight: 500,
                      color: AppColors.primaryColor,
                      width: '100%',
                      pt: 2
                    }}
                  >Basic</Typography>

                  <Typography
                    sx={{
                      fontSize: '1.5rem',
                      fontWeight: 500,
                      color: AppColors.blackColor,
                      width: '100%'
                    }}
                  >${getSubscriptionPrice(
                    config.PRO_BASIC,
                    isProAnnually,
                    defaultAffiliateGroupPricing
                  )}</Typography>
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      color: AppColors.blackColor,
                      width: '100%'
                    }}
                  >{isProAnnually ? "Annually" : "Monthly"}</Typography>
                  <Box sx={{ mt: 2, mb: 2, width: "100%", display: "flex", justifyContent: 'center' }}>{getGetSubscribeButton(config.PRO_BASIC)}</Box>

                  <Box>
                    <Box component={'ul'} sx={{
                      display: 'inline-block',
                      listStyle: 'disc',
                      listStylePosition: 'inside',
                      color: AppColors.blackColor,
                      textAlign: 'justify',
                      mb: 1
                    }}>
                      <Box component={'li'}><Typography component={'span'}>Add profile picture</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Add photos/videos</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Add background/ brand</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Add external links</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Add new prospects</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>In app messaging</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Group messaging</Typography></Box>
                      {/* {isBasicPlanExpanded && (<> */}
                      <Box component={'li'}><Typography component={'span'}>Sync contacts</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Receive notifications</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Simple sharing</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>QR code</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>KnoCard Social</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>2GB Storage</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Receive referrals</Typography></Box>
                      {/* </>)} */}
                    </Box>
                    {/* <Box sx={{ pt: 2, pb: 2, width: "100%", display: "flex", justifyContent: 'center' }}>
                      <Box
                        sx={{
                          background: `linear-gradient(180deg, ${AppColors.primaryColor}, ${AppColors.secondryColor})`,
                          textAlign: 'center',
                          borderRadius: '100px',
                          width: 'fit-content',
                          pl: 1.5,
                          pr: 1.5,
                          pt: 0.5,
                          pb: 0.5
                        }}

                        onClick={() => {
                          setBasicPlanExpanded(!isBasicPlanExpanded);
                        }}><Typography sx={{ color: "#ffffff", fontSize: "0.8rem" }}>{isBasicPlanExpanded ? "Show Less" : "Show More"}</Typography></Box>
                    </Box> */}
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={11}
                sm={11}
                md={3}
              >
                <Box sx={{
                  borderRadius: '35px',
                  backgroundColor: AppColors.primaryColor,
                }}>
                  <Typography
                    sx={{
                      fontSize: '1.3rem',
                      fontWeight: 500,
                      color: AppColors.whiteColor,
                      width: '100%',
                      textAlign: 'center',
                      pt: 3,
                      pb: 3
                    }}
                  >Best value</Typography>

                  <Box
                    sx={{
                      borderRadius: '35px',
                      backgroundColor: '#ffffff',
                      filter: "drop-shadow(0px 0px 2px #E8ECF4)",
                      textAlign: 'center',
                      pb: 4
                    }}
                  >

                    <Typography
                      sx={{
                        fontSize: '2rem',
                        fontWeight: 500,
                        color: AppColors.primaryColor,
                        width: '100%',
                        pt: 2
                      }}
                    >Pro1</Typography>

                    <Typography
                      sx={{
                        fontSize: '1.5rem',
                        fontWeight: 500,
                        color: AppColors.blackColor,
                        width: '100%'
                      }}
                    >${getSubscriptionPrice(
                      config.PRO_1,
                      isProAnnually,
                      defaultAffiliateGroupPricing
                    )}</Typography>
                    <Typography
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 400,
                        color: AppColors.blackColor,
                        width: '100%'
                      }}
                    >1 Time Payment</Typography>
                    <Box sx={{
                      mt: 2, mb: 2, width: "100%", display: "flex", justifyContent: 'center'
                    }}><Box>{getGetSubscribeButton(config.PRO_1)}</Box></Box>

                    <Box>
                      <Box component={'ul'} sx={{
                        display: 'inline-block',
                        listStyle: 'disc',
                        listStylePosition: 'inside',
                        color: AppColors.blackColor,
                        textAlign: 'justify'
                      }}>
                        <Box component={'li'}><Typography component={'span'}>Add profile picture</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Add photos/videos</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Add background/ brand</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Add external links</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Add new prospects</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>In app messaging</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Group messaging</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Sync contacts</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Receive notifications</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Simple sharing</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>QR code</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>KnoCard Social</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>6GB Storage</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Receive referrals</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Pipeline/CRM</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Import from business card</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Landing page</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Lead generation forms</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Goal setting & tracking</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Track video views</Typography></Box>
                        {isProOnePlanExpanded && (<>
                          <Box component={'li'}><Typography component={'span'}>Track page views</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>Track media views</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>Track social views</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>Track external link views</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>Track new prospects</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>Track referrals</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>Track landing page views</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>Feedback forms</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>Calendar integration</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>Added to community</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>10 keywords</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>Export Data</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>Reviews</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>Preferred Partners</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>Added to community</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>Affiliate program (after 1st 3 sales)</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>Marketing back office</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>Training videos</Typography></Box>
                          <Box component={'li'}><Typography component={'span'}>KnoCard Link</Typography></Box>
                        </>)}
                      </Box>
                      <Box sx={{ pt: 2, pb: 2, width: "100%", display: "flex", justifyContent: 'center' }}>
                        <Box
                          sx={{
                            background: `linear-gradient(180deg, ${AppColors.primaryColor}, ${AppColors.secondryColor})`,
                            textAlign: 'center',
                            borderRadius: '100px',
                            width: 'fit-content',
                            pl: 1.5,
                            pr: 1.5,
                            pt: 0.5,
                            pb: 0.5
                          }}

                          onClick={() => {
                            setProOnePlanExpanded(!isProOnePlanExpanded);
                          }}><Typography sx={{ color: "#ffffff", fontSize: "0.8rem" }}>{isProOnePlanExpanded ? "Show Less" : "Show More"}</Typography></Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>


              </Grid>
              <Grid
                item
                xs={11}
                sm={11}
                md={3}
              >
                <Box
                  sx={{
                    borderTopRightRadius: '35px',
                    borderTopLeftRadius: { md: "0px", xs: '35px' },
                    borderBottomLeftRadius: '35px',
                    borderBottomRightRadius: '35px',
                    backgroundColor: '#ffffff',
                    filter: "drop-shadow(0px 0px 2px #E8ECF4)",
                    textAlign: 'center',
                    mt: { md: 8, xs: 0 }
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '2rem',
                      fontWeight: 500,
                      color: AppColors.primaryColor,
                      width: '100%',
                      pt: 2
                    }}
                  >Pro</Typography>

                  <Typography
                    sx={{
                      fontSize: '1.5rem',
                      fontWeight: 500,
                      color: AppColors.blackColor,
                      width: '100%'
                    }}
                  >${getSubscriptionPrice(
                    config.PRO_PRO,
                    isProAnnually,
                    defaultAffiliateGroupPricing
                  )}</Typography>
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      color: AppColors.blackColor,
                      width: '100%'
                    }}
                  >{isProAnnually ? "Annually" : "Monthly"}</Typography>
                  <Box sx={{ mt: 2, mb: 2, width: "100%", display: "flex", justifyContent: 'center' }}>{getGetSubscribeButton(config.PRO_PRO)}</Box>

                  <Box>
                    <Box component={'ul'} sx={{
                      display: 'inline-block',
                      listStyle: 'disc',
                      listStylePosition: 'inside',
                      color: AppColors.blackColor,
                      textAlign: 'justify'
                    }}>
                      <Box component={'li'}><Typography component={'span'}>All basic features plus</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Pipeline/CRM</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Import from business card</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Landing page</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Lead generation forms</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Goal setting & tracking</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Track video views</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Track page views</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Track media views</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Track social views</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Track external link views</Typography></Box>
                      <Box component={'li'}><Typography component={'span'}>Track new prospects</Typography></Box>
                      {isProPlanExpanded && (<>
                        <Box component={'li'}><Typography component={'span'}>Track referrals</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Track landing page views</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Feedback forms</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Calendar integration</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Added to community</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>10 keywords</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Export Data</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Reviews</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Preferred Partners</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Added to community</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Affiliate program (after 1st 3 sales)</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Marketing back office</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Training videos</Typography></Box>
                        <Box component={'li'}><Typography component={'span'}>Ability to add optional KnoCard Link</Typography></Box></>)}

                    </Box>
                    <Box sx={{ pt: 2, pb: 2, width: "100%", display: "flex", justifyContent: 'center' }}>
                      <Box
                        sx={{
                          background: `linear-gradient(180deg, ${AppColors.primaryColor}, ${AppColors.secondryColor})`,
                          textAlign: 'center',
                          borderRadius: '100px',
                          width: 'fit-content',
                          pl: 1.5,
                          pr: 1.5,
                          pt: 0.5,
                          pb: 0.5
                        }}

                        onClick={() => {
                          setProPlanExpanded(!isProPlanExpanded);
                        }}><Typography sx={{ color: "#ffffff", fontSize: "0.8rem" }}>{isProPlanExpanded ? "Show Less" : "Show More"}</Typography></Box>
                    </Box>
                  </Box>
                </Box>

              </Grid>
            </Grid>
          </Box>


        </Box>
      </Page >
      {videoUrl && (
        <VideoModel
          onClose={onClose}
          toggle={toggle}
          videoUrl={videoUrl ?? ''}
          title='Lear More'
        ></VideoModel>
      )
      }
    </>
  );
};
export default Subscription;
