import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { HelpSupportVideoCategoryResponse, HelpSupportVideoReponse } from './interface';

export const getHelpSupportVideoCategory = createAsyncThunk(
  'helpSupportVideo/getHelpSupportVideoCategory',
  async (_,
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<HelpSupportVideoCategoryResponse>(
        `/api/help-support/category`

      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const getHelpSupportVideo = createAsyncThunk(
  'helpSupportVideo/getHelpSupportVideo',
  async (values: { categortyId: number, searchKeyword?: string, page: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<HelpSupportVideoReponse>(
        `/api/help-support/video/${values.categortyId}?page=${values.page}&keyword=${values.searchKeyword ?? ""}`

      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


