import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { config } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../store";
import Loader from '../../components/Loader';

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { getUserInfo } from "../../store/userInfo/api";
import { UserInfo } from "../../store/userInfo/api/interface";
import { setUserInfo } from "../../store/userInfo/userInfoSlice";
import { IUserLandingPageParams } from "../../enum";
import { LandingPageNewNavBar } from "../../components/LandingPageNewNavBar";
import { checkIsProProOrProOneOrProPlusUser } from "../../utils/checkPro";
import { getCustomLandingPage } from "../../store/customLandingpage/api";
import grapesjs, { Editor } from "grapesjs";


const CustomAffiliateLandingPage = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const params = useParams<IUserLandingPageParams>();
  const [referrUserInfo, setReferrUserInfo] = useState<UserInfo | undefined>(undefined);

  const dispatch = useDispatch<AppDispatch>();

  const [isLoading, setIsLoading] = useState(true);

  const customLandingPageData = useAppSelector((state) => state?.customLandingPage.data?.page_data ?? "");

  const [htmlContent, setHtmlContent] = useState('');
  const editorRef = useRef<Editor | null>(null);

  useEffect(() => {
    const userName = params?.username;
    if (!userName || userName?.trim().length === 0) {
      openLoginPage();
      return;
    }
    if (userName.length > 0) {
      dispatch(getUserInfo({ username: userName })).unwrap().then((res) => {
        if (res.data.data.user.length > 0) {
          setReferrUserInfo(res.data.data.user[0]);
          dispatch(setUserInfo(res.data));
        } else {
          openLoginPage();
        }
      }).catch((error) => {
        setIsLoading(false);
      });
    }

  }, []);

  const openLoginPage = () => {
    window.open(`${config.VBC_URL}`) || window.location.replace(`${config.VBC_URL}`);
  }

  const openVbcPage = (userName: string) => {
    window.open(`${config.VBC_URL}/${userName}`) || window.location.replace(`${config.VBC_URL}/${userName}`);
  }


  useEffect(() => {


    if (!editorRef.current) {
      editorRef.current = grapesjs.init({
        container: '#gjs',
        height: '0%',
        width: 'auto',
        fromElement: false,
        storageManager: false,
      });
    }

    if (customLandingPageData.length > 0) {

      try {
        editorRef.current?.loadProjectData(JSON.parse(customLandingPageData))

        const html = editorRef.current.getHtml();
        const cssContent = editorRef.current.getCss() ?? "";
        const jsContent = editorRef.current.getJs() ?? "";


        const styleElement = document.createElement('style');
        styleElement.textContent = cssContent;
        document.head.appendChild(styleElement);

        // Apply JS
        const scriptElement = document.createElement('script');
        scriptElement.textContent = jsContent;
        document.body.appendChild(scriptElement);

        setHtmlContent(html);

      } catch (e) { }
    }

  }, [customLandingPageData]);

  useEffect(() => {
    if (referrUserInfo) {
      if (checkIsProProOrProOneOrProPlusUser(referrUserInfo)) {
        dispatch(getCustomLandingPage({ user_id: referrUserInfo.id })).unwrap().then((result) => {
          if (result.data?.status?.code == "success") {
            setIsLoading(false);
          } else {
            openVbcPage(referrUserInfo.username);
          }
        });
      } else {
        openVbcPage(referrUserInfo.username);
      }
    }
  }, [referrUserInfo]);




  if (isLoading) return <><Loader /> <Box id='gjs' sx={{ display: "none" }}></Box></>;

  return (
    <React.Fragment>
      <LandingPageNewNavBar userInfo={referrUserInfo} affiliateUserName={referrUserInfo?.username} />

      <Box dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </React.Fragment >
  );
};

export default CustomAffiliateLandingPage;
