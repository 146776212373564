
import { Stack, Typography, Box, CircularProgress, Grid, useMediaQuery } from '@mui/material';

import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import style from '../styles/PreferredPartner.module.css';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import useToggle from '../hooks/useToggle';
import { deletePreferredPartner, getPreferredPartners } from '../store/preferredPartner/api';
import { isNotEmpty } from '../utils/valid';
import InfiniteScroll from 'react-infinite-scroller';
import PreferredPartnerItem from '../components/Card/PreferredPartnerItem';
import { PreferredPartner, PreferredPartnerContents } from '../store/preferredPartner/api/interface';
import FullscreenImageViewerDialog from '../components/modal/FullscreenImageViewerDialog';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import useWindowSize from '../utils/useWindowSize';
import { useNavigate } from 'react-router-dom';
import ConfirmationModel from '../components/modal/ConfirmationModel';

import { preferredPartnerDeleted } from '../store/preferredPartner/preferredPartnerSlice';
import { encryptString } from '../utils/url';
import { checkIsKnoCardSocialConnectUserPlan, checkIsProProOrProOneOrProPlusUser } from '../utils/checkPro';
import BlockOverlay from '../components/BlockOverlay';
import { showSuccessMessage } from '../utils/utilMethods';
import { AppColors } from '../utils/utils';
import SearchIcon from '../assets/preferred_partners/search.svg';


const PreferredPartners = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);

  const isProOrProOneOrProPlusUser = checkIsProProOrProOneOrProPlusUser(user);
  const isKnoCardSocialConnectUser = checkIsKnoCardSocialConnectUserPlan(user);
  const navigate = useNavigate();

  const [selectMediaContents, setSelectMediaContents] = useState<any[]>([]);
  const [selectMediaIndex, setSelectMediaIndex] = useState(0);
  const [deletePreferredPartnerItem, setDeletePreferredPartnerItem] = useState<PreferredPartner | undefined>();
  const {
    toggle: toggleFullscreenImageViewerDialog,
    onOpen: onOpenFullscreenImageViewerDialog,
    onClose: onCloseFullscreenImageViewerDialog,
  } = useToggle();

  const { isLoading, isLoadMore, page } = useAppSelector(
    (state) => state?.preferredPartner
  );
  const preferredPartners = useAppSelector((state) => state?.preferredPartner?.data?.preferredPartners) ?? [];

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (isProOrProOneOrProPlusUser || isKnoCardSocialConnectUser) {
      if (user?.id) {
        dispatch(getPreferredPartners({ userId: user.id, pageNo: page }));
      }
    }
  }, [dispatch]);

  const handleLoadMore = (_page: number) => {
    if (isProOrProOneOrProPlusUser || isKnoCardSocialConnectUser) {
      if (user?.id && !isLoading) {
        dispatch(getPreferredPartners({ userId: user.id, pageNo: page }));
      }
    }
  };

  const matches = useMediaQuery('(max-width:745px)');
  const windowSize = useWindowSize();
  const [cardWidth, setCardWidth] = useState(300);

  useEffect(() => {
    const newWidth = matches ? windowSize.width > 350 ? 300 : windowSize.width * 0.80 : windowSize.width > 890 ? 745 : windowSize.width * 0.80;

    setCardWidth(newWidth);
  }, [windowSize]);

  const addPreferedPartener = () => {
    navigate(`/dashboard/add-preferred-partner`);
  }

  const {
    toggle: togglenDeletePreferredPartner,
    onOpen: onOpenDeletePreferredPartner,
    onClose: onCloseDeletePreferredPartner,
  } = useToggle();


  const onDeletePreferredPartnerConfirm = async () => {
    if (isProOrProOneOrProPlusUser || isKnoCardSocialConnectUser) {
      onCloseDeletePreferredPartner();
      if (deletePreferredPartnerItem != undefined) {
        try {

          var result = await dispatch(deletePreferredPartner({ preferredPartnerId: deletePreferredPartnerItem.id })).unwrap();
          if (result?.data?.status?.code == "success") {
            dispatch(preferredPartnerDeleted(deletePreferredPartnerItem));
            showSuccessMessage("Preferred partner deleted successfully.");
            setDeletePreferredPartnerItem(undefined);
          }

        } catch (error: any) { }
      }
    }
  }


  const handleDeletePreferredPartner = (item: PreferredPartner) => {
    if (isProOrProOneOrProPlusUser || isKnoCardSocialConnectUser) {
      setDeletePreferredPartnerItem(item);
      onOpenDeletePreferredPartner();
    }
  };
  const handleEditPreferredPartner = (item: PreferredPartner) => {
    if (isProOrProOneOrProPlusUser || isKnoCardSocialConnectUser) {
      navigate(`/dashboard/edit-preferred-partner/${encryptString(`${item.id}`)}`);
    }
  };

  return (
    <>
      <Page title='Preferred Partners | KnoCard' showAppBar={true}>

        <Stack>
          <Grid item marginTop={6} onClick={(isProOrProOneOrProPlusUser || isKnoCardSocialConnectUser) ? addPreferedPartener : undefined}>
            <Stack direction={'row'} className={`${style.add}`}
              sx={{
                background: AppColors.whiteColor,
                border: `2px solid ${AppColors.strokeColor}`,
                p: 1,
                borderRadius: "10px",
                ml: 2,
                mr: 2,
                mb: 2
              }}
            >
              <Box component={"img"} src={SearchIcon} sx={{ marginRight: 1 }} />
              <Typography sx={{ color: AppColors.grayThreeColor, fontWeight: "300" }}>Search and Add Preferred Partner</Typography>
            </Stack>
          </Grid>

          <Box>
            {isLoading && page === 1 ? (
              <Box
                display='flex'
                justifyContent='center'
                pt={2}
              // height={'85vh'}
              >
                <Stack>
                  <CircularProgress />
                </Stack>
              </Box>
            ) : preferredPartners && isNotEmpty(preferredPartners) ? (
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                gap={3}
                paddingBottom={4}
                sx={{
                  '& > div:first-of-type': {
                    width: { md: '80%', xs: 'unset' }
                  }
                }}
              >
                <InfiniteScroll
                  pageStart={1}
                  initialLoad={false}
                  loadMore={handleLoadMore}
                  hasMore={isLoadMore}
                  loader={
                    <Box
                      display='flex'
                      justifyContent='center'
                      mt={2}
                    >
                      <CircularProgress />
                    </Box>
                  }
                  className="w-95"

                >
                  {preferredPartners?.map((partner, feedIdx) => (
                    <PreferredPartnerItem
                      key={feedIdx}
                      partner={partner}
                      index={feedIdx}
                      showEditMenu={true}
                      cardWidth={'max-content'}
                      handleDelete={handleDeletePreferredPartner}
                      handleEdit={handleEditPreferredPartner}
                      onMediaSelect={(
                        contents: PreferredPartnerContents[],
                        index: number
                      ) => {
                        setSelectMediaContents(contents);
                        setSelectMediaIndex(index);
                        onOpenFullscreenImageViewerDialog();
                      }}
                    />
                  ))}
                </InfiniteScroll>
                <FullscreenImageViewerDialog
                  onClose={onCloseFullscreenImageViewerDialog}
                  toggle={toggleFullscreenImageViewerDialog}
                  index={selectMediaIndex}
                  contents={selectMediaContents}
                />
              </Box>
            ) : (
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
              >
                <Typography mt={'20vh'} sx={{ color: AppColors.blackColor }}>No preferred partners.</Typography>
              </Box>
            )}
          </Box>

          <BlockOverlay isBlock={(!isProOrProOneOrProPlusUser && !isKnoCardSocialConnectUser)} />
        </Stack>

      </Page >
      <ConfirmationModel
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        title='Delete Preferred Partner'
        message='Are you sure! Do you want to delete this Preferred Partner?'
        onConfirm={onDeletePreferredPartnerConfirm}
        onClose={onCloseDeletePreferredPartner}
        toggle={togglenDeletePreferredPartner} />
    </>
  );
};
export default PreferredPartners;
